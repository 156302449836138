import { memo, useEffect, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import styled from 'styled-components';

import { ChangeLogModel } from 'models';

import { DataResult } from 'core/api/types';
import { useEvent } from 'core/hooks';
import { ComponentSizes, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, DataTableVariants, DateCell, HeaderCellSecondary, Modal, TextCell } from 'core/ui';

import { apiClient } from 'features/api';

import { ActivityLogModalProps } from '../types';

const EMPTY_GRID_DATA: DataResult<ChangeLogModel> = {
  data: [],
  total: 0,
};

export const ActivityLogModal = memo<ActivityLogModalProps>(({ show, entityType, id, onClose }) => {
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);
  const [gridData, setGridData] = useState<DataResult<ChangeLogModel>>(EMPTY_GRID_DATA);
  const [isLoading, setIsLoading] = useState(false);

  const refreshData = useEvent(async (newDataState?: State) => {
    if (id && show) {
      try {
        setIsLoading(true);
        setGridData(await apiClient.changeLogsClient.getLogs(newDataState ?? dataState, entityType, id));
      } finally {
        setIsLoading(false);
      }
    } else {
      setGridData(EMPTY_GRID_DATA);
    }
  });

  const handleDataStateChange = useEvent((event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
    refreshData(event.dataState);
  });

  useEffect(() => {
    refreshData();
  }, [refreshData, entityType, id, show]);

  return (
    <StyledModal show={show} onHide={onClose} title={`Activity Log (${id})`}>
      <StyledDataTable
        {...dataState}
        data={gridData.data}
        total={gridData.total}
        pageable
        reorderable
        size={ComponentSizes.SMALL}
        sortable
        variant={DataTableVariants.SECONDARY}
        onDataStateChange={handleDataStateChange}
        isLoading={isLoading}
      >
        <GridColumn cell={TextCell} field="propertyName" filterable={false} title="Property Name" width="200px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="oldValue" filterable={false} title="Old Value" width="200px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="newValue" filterable={false} title="New Value" width="200px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={TextCell} field="userModified" filterable={false} title="User" width="200px" headerCell={HeaderCellSecondary} />
        <GridColumn cell={DateCell} field="dateModified" filterable={false} title="Date" width="150px" headerCell={HeaderCellSecondary} />
      </StyledDataTable>
    </StyledModal>
  );
});

ActivityLogModal.displayName = 'ActivityLogModal';

const StyledModal = styled(Modal)`
  .modal-content {
    row-gap: 24px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledDataTable = styled(DataTable)`
  margin: 0;
  min-height: 150px;
  border-left: none;
  border-right: none;
`;
