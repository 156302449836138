import {
  ContactTabFormValues,
  GeneralTabFormValues,
  IntegrationTabFormValues,
} from './types';

export const GroupStatus = {
  PENDING: 'group-pending',
  ADDED: 'group-created',
  UPDATED: 'group-updated',
  FETCHED: 'group-fetched',
  ERROR: 'group-error',
};

export const SignInMethods = [
  {
    name: 'Any',
    value: '',
  },
  {
    name: 'Microsoft',
    value: 'Microsoft',
  },
  {
    name: 'Google',
    value: 'Google',
  },
  {
    name: 'Okta',
    value: 'Okta',
  },
];

export const InitialValues = {
  nonFormFields: {
    id: 0,
  },
  generalForm: {
    name: '',
    locations: [],
    domains: [],
    approveAutomatically: false,
    active: true,
    services: [],
    pendingApprovalMessage: '',
  } as GeneralTabFormValues,
  contactForm: {
    approvalEmail: '',
  } as ContactTabFormValues,
  integrationForm: {
    uploadURL: '',
    signinMethod: '',
  } as IntegrationTabFormValues,
};
