import { createSelector } from '@reduxjs/toolkit';

import { LocationTypeModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { locationTypeAdapter } from './reducer';

const rootSelector = (state: RootState) => state.locationTypes;

const rootGridSelector = (state: RootState) => state.locationTypesGrid;

const { selectAll: getAll } = locationTypeAdapter.getSelectors(rootSelector);

const { selectAll: getAllGrid } =
  locationTypeAdapter.getSelectors(rootGridSelector);

const getTotal = (state: RootState) => rootSelector(state).total;

const getTotalGrid = (state: RootState) => rootGridSelector(state).total;

const getByIdOutput = (
  locationTypes: LocationTypeModel[],
  id: number | undefined,
) => locationTypes.find((locationType) => locationType.id === id);

const getById = (
  id: number | undefined,
): AppSelector<LocationTypeModel | undefined> =>
  createSelector([getAll, () => id], getByIdOutput);

export const LocationTypeSelectors = {
  getById,
  getAll,
  getAllGrid,
  getTotal,
  getTotalGrid,
};
