import { createSelector } from '@reduxjs/toolkit';

import { PhysicianModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { physicianAdapter } from './reducer';

const rootSelector = (state: RootState) => state.physicians;

const rootGridSelector = (state: RootState) => state.physiciansGrid;

const { selectAll: getAll } = physicianAdapter.getSelectors(rootSelector);

const { selectAll: getAllGrid } =
  physicianAdapter.getSelectors(rootGridSelector);

const getByIdOutput = (
  physicians: PhysicianModel[],
  physicianId: number | undefined,
) => physicians.find((phys) => phys.id === physicianId);

const getById = (
  physicianId: number | undefined,
): AppSelector<PhysicianModel | undefined> =>
  createSelector([getAll, () => physicianId], getByIdOutput);

const getTotal: AppSelector<number> = (state) => rootSelector(state).total;

const getTotalGrid: AppSelector<number> = (state) =>
  rootGridSelector(state).total;

export const PhysicianSelectors = {
  getAll,
  getAllGrid,
  getById,
  getTotal,
  getTotalGrid,
};
