import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { UnitModel } from 'models';

import { UnitStatus } from '../constants';
import { UnitActions } from './actions';

export const unitAdapter = createEntityAdapter<UnitModel>({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...unitAdapter.getInitialState(),
  total: 0,
  status: UnitStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(UnitActions.getById.fulfilled, (draft, action) => {
    const payload = action.payload as UnitModel;
    unitAdapter.upsertOne(draft, payload);
    draft.status = UnitStatus.FETCHED;
  });
  builder.addCase(UnitActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { units: UnitModel };
    unitAdapter.addOne(draft, payload.units);
    draft.status = UnitStatus.ADDED;
  });
  builder.addCase(UnitActions.edit.fulfilled, (draft, action) => {
    const payload = action.payload as { units: Record<number, UnitModel> };

    Object.values(payload.units).forEach((unit) => {
      unitAdapter.setOne(draft, unit);
    });

    draft.status = UnitStatus.UPDATED;
  });
});

export const reducerGrid = createReducer(
  getReducerInitialState(),
  (builder) => {
    builder.addCase(
      UnitActions.getAllForKendoGrid.fulfilled,
      (draft, action) => {
        unitAdapter.removeAll(draft);
        const payload = action.payload as {
          entities: { units: UnitModel[] };
          total: number;
          result: EntityId[];
        };

        if (payload.entities.units) {
          unitAdapter.addMany(draft, payload.entities.units);
        }

        draft.total = payload.total;
        draft.ids = payload.result;
        draft.status = UnitStatus.FETCHED;
      },
    );
  },
);
