import { useEffect, useRef } from 'react';

import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Field, FormValidatorsService } from 'core/forms';
import { Accordion, DropdownWithValuesField, Input, TextArea } from 'core/ui';

import { HistologyQualities, ReadingTypes } from '../../exam/constants';
import WriteIcon from '../assets/write.svg?react';
import {
  AcuteTubularInjuryNecrosisOptions,
  ArterialIntimalFibrosisOptions,
  ArteriolarHyalinosisOptions,
  CorticalNecrosisOptions,
  FibrinThrombiOptions,
  InterstitialFibrosisandTubularAtrophyOptions,
  InterstitialInflammationOptions,
  KidneyVascularChangesOptions,
  NodularMesangialGlomerulosclerosisOptions,
  SATISFACTORY_SAMPLE_SIZE_OPTIONS,
} from '../constants';
import { useInterpretableState } from '../hooks/useInterpretableState';
import { PathologyTargetedAccordionBody } from './PathologyTargetedAccordionBody';

// has to be wrapped in <Form /> to work
const PathologyKidneyResultsAccordion = ({ eventKey, isFormVisible, valueGetter, valueSetter }) => {
  const numberSclerosed = valueGetter('Results.numberSclerosed');
  const glomeruliSeen = valueGetter('Results.glomeruliSeen');
  const readingType = valueGetter('Results.readingType');
  const histologyQuality = valueGetter('Results.histologyQuality');
  const satisfactorySampleSize = SATISFACTORY_SAMPLE_SIZE_OPTIONS.find((option) => option.value === valueGetter('Results.satisfactorySampleSize'));

  const accordionBodyRef = useRef(null);
  const isUninterpretable = useInterpretableState(valueGetter, valueSetter, accordionBodyRef);
  useEffect(() => {
    if (valueGetter('Results.histologyQuality') !== HistologyQualities.UNINTERPRETABLE.value) {
      valueSetter('Results.percentageSclerosed', {
        value: ((numberSclerosed * 100) / glomeruliSeen).toFixed(2),
      });
    }
  }, [numberSclerosed, glomeruliSeen, valueSetter, valueGetter]);

  let accordionBody;
  if (isFormVisible && readingType === ReadingTypes.GENERAL.value) {
    accordionBody = (
      <Accordion.Body>
        <StyledRow>
          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={SATISFACTORY_SAMPLE_SIZE_OPTIONS}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Satisfactory Sample"
              name="Results.satisfactorySampleSize"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-6">
            <Field
              component={Input}
              label="Glomeruli Seen (min 25)"
              name="Results.glomeruliSeen"
              required={histologyQuality === HistologyQualities.GOOD.value}
              validator={
                histologyQuality === HistologyQualities.GOOD.value &&
                FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.greaterThanOrEqualTo(0)])
              }
              type="number"
            />
          </Col>
          <Col className="col-6">
            <Field
              component={Input}
              label="Number Sclerosed"
              name="Results.numberSclerosed"
              required={histologyQuality === HistologyQualities.GOOD.value}
              validator={
                histologyQuality === HistologyQualities.GOOD.value &&
                FormValidatorsService.combineValidators([
                  FormValidatorsService.required,
                  FormValidatorsService.greaterThanOrEqualTo(0),
                  FormValidatorsService.lowerValueThanField('Results.glomeruliSeen', 'Total Glomeruli Seen must not be lower than Number Sclerosed.'),
                ])
              }
              type="number"
            />
          </Col>

          <Col className="col-6">
            <Field
              component={Input}
              label="% Sclerosed"
              name="Results.percentageSclerosed"
              validator={histologyQuality === HistologyQualities.GOOD.value && FormValidatorsService.required}
              disabled
            />
          </Col>

          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={FibrinThrombiOptions}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Fibrin Thrombi"
              name="Results.fibrinThrombi"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(AcuteTubularInjuryNecrosisOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Acute Tubular Injury/Necrosis"
              name="Results.acuteTubularInjuryNecrosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={InterstitialFibrosisandTubularAtrophyOptions}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Interstitial Fibrosis and Tubular Atrophy (IFTA)"
              name="Results.interstitialFibrosisandTubularAtrophy"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(InterstitialInflammationOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Interstitial Inflammation"
              name="Results.interstitialInflammation"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(ArterialIntimalFibrosisOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Arterial Sclerosis (Intimal Fibrosis)"
              name="Results.arterialIntimalFibrosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(ArteriolarHyalinosisOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Arteriolar Hyalinosis"
              name="Results.arteriolarHyalinosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
          <Col className="col-6">
            <Field
              component={DropdownWithValuesField}
              data={_map(KidneyVascularChangesOptions)}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Vascular (Arterial) Disease"
              name="Results.kidneyVascularChanges"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={NodularMesangialGlomerulosclerosisOptions}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Nodular Mesangial Glomerulosclerosis"
              name="Results.nodularMesangialGlomerulosclerosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>

          <Col className="col-12">
            <Field
              component={DropdownWithValuesField}
              data={CorticalNecrosisOptions}
              dataItemKey="value"
              filterable={false}
              isForPrimitiveValues
              label="Cortical Necrosis (Parenchymal Necrosis)"
              name="Results.corticalNecrosis"
              required
              validator={FormValidatorsService.required}
              valueField="value"
            />
          </Col>
          <Col className="col-12">
            <Field
              component={TextArea}
              label="Comments"
              name="Results.comments"
              warning={satisfactorySampleSize?.requiresCommentsMessage ?? undefined}
              required={isUninterpretable || satisfactorySampleSize?.requiresComments}
              validator={isUninterpretable || satisfactorySampleSize?.requiresComments ? FormValidatorsService.required : undefined}
            />
          </Col>
        </StyledRow>
      </Accordion.Body>
    );
  } else if (isFormVisible && readingType === ReadingTypes.TARGETED.value) {
    accordionBody = <PathologyTargetedAccordionBody isUninterpretable={isUninterpretable} valueGetter={valueGetter} />;
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={WriteIcon} title="Results" />
      <div ref={accordionBodyRef}>{accordionBody}</div>
    </Accordion.Item>
  );
};

const StyledRow = styled(Row)`
  .col:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.spacing20};
  }

  label.k-label {
    margin-bottom: 0;
  }
`;

PathologyKidneyResultsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
};

export { PathologyKidneyResultsAccordion };
