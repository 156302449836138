import { FunctionComponent } from 'react';

import { RadioButton as KendoRadioButton } from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { RadioButtonProps } from './RadioButtonProps';

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
  checked,
  disabled,
  id,
  label,
  name,
  onChange,
  value,
}) => (
  <StyledRadioButtonBase
    checked={checked}
    disabled={disabled}
    id={id}
    label={label}
    name={name}
    onChange={onChange}
    value={value}
  />
);

RadioButton.displayName = 'RadioButton';

type StyledElementProps = {
  theme: DefaultTheme;
  checked?: boolean;
  disabled?: boolean;
};

const resolvePalette = ({ theme, checked, disabled }: StyledElementProps) => {
  if (disabled) {
    return {
      background: theme.colors.backgroundDisabled,
      border: theme.colors.borderDisabled,
      label: theme.colors.palette.grayscale[6],
      content: theme.colors.palette.grayscale[6],
    };
  }

  if (checked) {
    return {
      background: theme.colors.palette.white,
      border: theme.colors.palette.aquas[5],
      content: theme.colors.palette.aquas[5],
      label: theme.colors.textPrimary,
    };
  }

  return {
    background: theme.colors.palette.white,
    border: theme.colors.borderBase,
    label: theme.colors.textPrimary,
  };
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveLabelColor = (props: StyledElementProps) => {
  const { label } = resolvePalette(props);

  return label;
};

const resolveContentColor = (props: StyledElementProps) => {
  const { content } = resolvePalette(props);

  return content;
};

const StyledRadioButtonBase = styled(KendoRadioButton)`
  &.k-radio {
    border-color: ${resolveBorderColor};
    background-color: ${resolveBackgroundColor};
    color: ${resolveContentColor};
    margin: 0 ${({ theme }) => theme.space.spacing20} 0 0;
    min-width: ${({ theme }) => theme.sizes.xSmall};
    min-height: ${({ theme }) => theme.sizes.xSmall};

    &:before {
      background-color: ${resolveContentColor};
      background-image: none;
      border-radius: 50%;
      content: '';
      left: 2px;
      min-height: calc(${({ theme }) => theme.sizes.xSmall} / 2);
      min-width: calc(${({ theme }) => theme.sizes.xSmall} / 2);
      position: absolute;
      top: 2px;
    }
  }

  &.k-radio:disabled,
  &.k-radio:disabled + .k-radio-label {
    opacity: 1;
  }

  &.k-radio + .k-radio-label {
    color: ${resolveLabelColor};
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
    margin: 0 ${({ theme }) => theme.space.spacing40} 0 0;
  }

  &.k-radio:focus {
    border-color: ${resolveBorderColor};
    box-shadow: none;
  }

  &.k-radio:checked:disabled {
    background-color: ${resolveBackgroundColor};
  }
`;
