import { forwardRef } from 'react';

import {
  Button as KendoButton,
  ButtonProps as KendoButtonProps,
} from '@progress/kendo-react-buttons';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { ButtonProps } from './ButtonProps';
import { ButtonLooks, ButtonVariants } from './constants';

export const Button = forwardRef<KendoButton, ButtonProps>(
  (
    {
      look = ButtonLooks.STANDARD,
      size = ComponentSizes.MEDIUM,
      type = 'button',
      variant = ButtonVariants.PRIMARY,
      ...restProps
    },
    ref,
  ) => (
    <StyledButtonBase
      ref={ref}
      type={type}
      size={size}
      $look={look}
      $variant={variant}
      {...restProps}
    />
  ),
);

Button.displayName = 'Button';

type StyledButtonBaseProps = {
  $look: ButtonLooks;
  $variant: ButtonVariants;
  disabled?: boolean;
  size?: KendoButtonProps['size'];
  theme: DefaultTheme;
};

const resolveBackgroundColor = ({
  theme,
  $variant,
  $look,
  disabled,
}: StyledButtonBaseProps) => {
  if (disabled) return theme.colors.backgroundDisabled;

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.STANDARD)
    return theme.colors.primary;

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.AQUA)
    return theme.colors.secondary;

  if ($variant === ButtonVariants.SECONDARY) return theme.colors.palette.white;

  return undefined;
};

const resolveActiveBackgroundColor = ({
  theme,
  $variant,
  $look,
  disabled,
}: StyledButtonBaseProps) => {
  if (disabled) return theme.colors.backgroundDisabled;

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.STANDARD)
    return theme.colors.palette.blues[3];

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.AQUA)
    return theme.colors.palette.aquas[3];

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.STANDARD)
    return theme.colors.primary;

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.AQUA)
    return theme.colors.secondary;

  if ($look === ButtonLooks.WHITE) return theme.colors.palette.white;

  return undefined;
};

const resolveBorderColor = ({
  theme,
  disabled,
  $variant,
  $look,
}: StyledButtonBaseProps) => {
  if (disabled) return theme.colors.borderDisabled;

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.STANDARD)
    return 'transparent';

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.AQUA)
    return theme.colors.secondary;

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.STANDARD)
    return theme.colors.primary;

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.AQUA)
    return theme.colors.secondary;

  return undefined;
};

const resolveActiveBorderColor = ({
  theme,
  disabled,
  $variant,
  $look,
}: StyledButtonBaseProps) => {
  if (disabled) return theme.colors.borderDisabled;

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.STANDARD)
    return 'transparent';

  if ($variant === ButtonVariants.PRIMARY && $look === ButtonLooks.AQUA)
    return theme.colors.secondary;

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.STANDARD)
    return theme.colors.primary;

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.AQUA)
    return theme.colors.secondary;

  return undefined;
};

const resolveColor = ({
  theme,
  disabled,
  $variant,
  $look,
}: StyledButtonBaseProps) => {
  if (disabled) {
    return theme.colors.textDisabled;
  }

  if ($look === ButtonLooks.WHITE) {
    return theme.colors.palette.blues[8];
  }

  if ($variant === ButtonVariants.PRIMARY) {
    return theme.colors.palette.white;
  }

  if ($variant === ButtonVariants.SECONDARY && $look === ButtonLooks.AQUA) {
    return theme.colors.secondary;
  }

  if ($variant === ButtonVariants.SECONDARY) {
    return theme.colors.primary;
  }

  return undefined;
};

const resolveActiveColor = ({
  theme,
  disabled,
  $variant,
}: StyledButtonBaseProps) => {
  if (disabled) {
    return theme.colors.textDisabled;
  }

  if ($variant === ButtonVariants.SECONDARY) {
    return theme.colors.palette.white;
  }

  return undefined;
};

const resolveBoxShadow = ({ theme, $variant }: StyledButtonBaseProps) => {
  if ($variant === ButtonVariants.PRIMARY) {
    return theme.shadows.primary;
  }

  if ($variant === ButtonVariants.SECONDARY) {
    return theme.shadows.secondary;
  }

  return undefined;
};

const resolveBorderWidth = ({ theme }: StyledButtonBaseProps) =>
  theme.borderWidths.base;

const resolvePadding = ({ theme, size }: StyledButtonBaseProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `0rem ${theme.space.spacing20}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.spacing10} ${theme.space.spacing40}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing50} ${theme.space.spacing40}`;
    default:
      return `${theme.space.spacing10} ${theme.space.spacing40}`;
  }
};

const resolveFontSize = ({ theme, size }: StyledButtonBaseProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledButtonBaseProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveBorderRadius = ({ theme }: StyledButtonBaseProps) => {
  return theme.radii.base;
};

const resolveHeight = ({ theme, size }: StyledButtonBaseProps) => {
  switch (size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${size}".`);
  }
};

const StyledButtonBase = styled(KendoButton)<{
  $look: ButtonLooks;
  $variant: ButtonVariants;
  // size: ComponentSizes;
}>`
  && {
    height: ${resolveHeight};
    padding: ${resolvePadding};
    width: auto;
  }

  color: ${resolveColor};
  background-color: ${resolveBackgroundColor};
  font-size: ${resolveFontSize};
  line-height: ${resolveLineHeight};
  border-radius: ${resolveBorderRadius};
  border: ${resolveBorderWidth} solid ${resolveBorderColor};
  box-shadow: ${resolveBoxShadow};

  &&:hover,
  &&:focus {
    color: ${resolveActiveColor};
    background-color: ${resolveActiveBackgroundColor};
    border-color: ${resolveActiveBorderColor};
    box-shadow: ${resolveBoxShadow};
  }

  & span {
    font-size: ${resolveFontSize};
    line-height: ${resolveLineHeight};
  }

  &:disabled {
    opacity: 1;
    filter: none;
    box-shadow: ${resolveBoxShadow};
  }
`;
