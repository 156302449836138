import { forwardRef, memo } from 'react';

import cn from 'classnames';
import { areEqual } from 'react-window';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { DropdownNgItemProps } from './DropdownNgItemProps';

export const DropdownNgItem = memo(
  forwardRef<HTMLButtonElement, DropdownNgItemProps>(({ className, style, dataItem, dataItemKey, dataItemIndex, selected, textResolver, onClick }, ref) => {
    const handleClick = useEvent(() => {
      onClick(dataItemIndex);
    });

    return (
      <StyledItemButton
        ref={ref}
        className={cn(className, { selected })}
        tabIndex={-1}
        style={style}
        data-item-key={dataItem[dataItemKey]}
        onClick={handleClick}
      >
        <StyledTextSpan>{textResolver(dataItem)}</StyledTextSpan>
      </StyledItemButton>
    );
  }),
  areEqual,
);

DropdownNgItem.displayName = 'DropdownNgItem';

const StyledItemButton = styled.button`
  all: unset;
  display: flex;
  overflow: hidden;
  align-items: center;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.palette.blues[3]};
    color: ${({ theme }) => theme.colors.palette.white};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.palette.blues[5]};
    color: ${({ theme }) => theme.colors.palette.white};
  }
`;

const StyledTextSpan = styled.span`
  flex: 1 1 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${({ theme }) => `0 ${theme.space.spacing30}`};
`;
