import { FunctionComponent } from 'react';

import {
  TimePicker as KendoTimePicker,
  TimePickerProps as KendoTimePickerProps,
} from '@progress/kendo-react-dateinputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { TimePickerProps } from './TimePickerProps';

export const TimePicker: FunctionComponent<TimePickerProps> = ({
  description,
  disabled,
  hint,
  isOptionalLabelShown = false,
  label,
  name,
  required,
  valid,
  validationMessage,
  defaultValue,
  value,
  visited,
  placeholder = 'Select date',
  format = 'HH:mm:ss',
  size = ComponentSizes.MEDIUM,
  ...rest
}) => {
  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = Boolean(!isValidationMessageShown && hint);
  const hintId = isHintShown ? `${name}_hint` : '';
  const errorId = isValidationMessageShown ? `${name}_error` : '';
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

  return (
    <>
      {label && (
        <Label
          description={description}
          editorId={name}
          editorValid={valid}
          editorDisabled={disabled}
          required={required}
          optional={isLabeledAsOptional}
        >
          {label}
        </Label>
      )}
      <StyledTimePicker
        defaultValue={defaultValue ? new Date(defaultValue) : defaultValue}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        required={required}
        valid={valid}
        validationMessage={validationMessage}
        value={value ? new Date(value) : value}
        format={format}
        size={size}
        {...rest}
      />
      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && (
        <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>
      )}
    </>
  );
};

TimePicker.displayName = 'TimePicker';

type StyledElementProps = {
  theme: DefaultTheme;
  disabled?: boolean;
  valid?: boolean;
  value?: KendoTimePickerProps['value'];
  size?: KendoTimePickerProps['size'];
};

const resolvePalette = ({
  theme,
  disabled,
  valid,
  value,
}: StyledElementProps) => {
  if (disabled) {
    return {
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      borderActive: theme.colors.borderDisabled,
      border: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
    };
  }

  const result = {
    borderActive: theme.colors.palette.aquas[4],
    background: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[6],
    border: theme.colors.borderBase,
    text: value ? theme.colors.textPrimary : theme.colors.textSecondary,
  };

  if (!valid) {
    result.borderActive = theme.colors.error;
    result.border = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveIconColor = (props: StyledElementProps) => {
  const { icon } = resolvePalette(props);

  return icon;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${size}".`);
  }
};

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing30}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing30}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
  }
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({
  theme,
  valid,
  disabled,
}: StyledElementProps) => {
  if (disabled) {
    return 'none';
  }

  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const StyledTimePicker = styled(KendoTimePicker)`
  border: none;
  display: block;
  width: 100%;

  && .k-input {
    border: none;
    color: ${resolveColor};
    font-size: ${resolveFontSize};
    height: ${resolveHeight};
    line-height: ${resolveLineHeight};
    padding: ${resolvePadding};
  }

  && .k-select {
    background-color: ${resolveBackgroundColor};
    border: none;
    color: transparent;

    & .k-i-clock {
      color: ${resolveIconColor};
    }
  }

  && .k-picker-wrap {
    border: ${({ theme }) => theme.borderWidths.base} solid
      ${resolveBorderColor};
    background-color: ${resolveBackgroundColor};

    & .k-dateinput-wrap {
      background-color: ${resolveBackgroundColor};
    }

    &:hover {
      border-color: ${resolveActiveBorderColor};
    }

    &:active,
    &:focus,
    &.k-focus {
      border-color: ${resolveActiveBorderColor};
      box-shadow: ${resolveActiveBoxShadow};
    }
  }
`;
