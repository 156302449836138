export const PropsSource = {
  KENDO: 'Kendo',
  COMPUMED: 'CompuMed',
};

// these need to be in sync with KENDO's
export enum ComponentSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const ComponentTypes = {
  DEFAULT: 'default',
  PROCESSING: 'processing',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};
