import { forwardRef } from 'react';

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { ProgressSpinnerProps } from './ProgressSpinnerProps';

export const ProgressSpinner = forwardRef<SVGSVGElement, ProgressSpinnerProps>(
  ({ className }, ref) => (
    <StyledSpinner
      ref={ref}
      className={cn(className, 'progress-spinner')}
      icon={faSpinnerThird}
      size="3x"
      block
      spin
    />
  ),
);

ProgressSpinner.displayName = 'ProgressSpinner';

const StyledSpinner = styled(Icon)`
  color: ${({ theme }) => theme.colors.palette.grayscale[6]};

  & .fa-spin {
    --fa-animation-duration: 1s;
  }
`;
