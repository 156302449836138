import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LinkButton } from 'core/ui';

const UndoAddReadMessage = ({ closeToast, onUndo }) => {
  const handleClick = () => {
    onUndo();
    closeToast();
  };

  return (
    <div>
      Your exam has been submitted.
      <StyledLinkButton onClick={handleClick}>Undo and Edit</StyledLinkButton>
    </div>
  );
};

const StyledLinkButton = styled(LinkButton)`
  &,
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: black;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-decoration: underline;
  }
`;

UndoAddReadMessage.propTypes = {
  closeToast: PropTypes.func,
  onUndo: PropTypes.func.isRequired,
};

UndoAddReadMessage.defaultProps = {
  closeToast: () => {},
};

export { UndoAddReadMessage };
