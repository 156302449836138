import { useCallback, useMemo } from 'react';

import { SelectCallback } from '@restart/ui/types';
import { useSearchParams } from 'react-router-dom';

import { equalsInsensitive } from 'core/utils';

export const useTabLocationPersistence = (allTabKeys: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTabKey: string | undefined = useMemo(() => {
    let paramValue: string | undefined | null;

    if (searchParams.has('activeTab')) {
      paramValue = searchParams.get('activeTab');

      // Need to normalize the activeTab parameter so that the specified key matches exactly (case-sensitive) what we internally use for identifying tabs.
      const eventKeyIndex =
        paramValue == null
          ? -1
          : allTabKeys.findIndex((tabKey) =>
              equalsInsensitive(tabKey, paramValue),
            );

      return eventKeyIndex < 0 ? undefined : allTabKeys[eventKeyIndex];
    }

    return allTabKeys.length === 0 ? undefined : allTabKeys[0];
  }, [allTabKeys, searchParams]);

  const onTabSelect: SelectCallback = useCallback(
    (eventKey) => {
      if (eventKey == null) return;

      const eventKeyIndex = allTabKeys.findIndex(
        (tabKey) =>
          eventKey.localeCompare(tabKey, 'en-US', { sensitivity: 'base' }) ===
          0,
      );

      if (eventKeyIndex < 0) {
        throw new Error(`Unable to validate selected key: "${eventKey}."`);
      } else if (eventKeyIndex === 0) {
        searchParams.delete('activeTab');
      } else {
        searchParams.set('activeTab', eventKey);
      }

      setSearchParams(searchParams);
    },
    [allTabKeys, searchParams, setSearchParams],
  );

  return { initialTabKey, onTabSelect };
};
