import { forwardRef } from 'react';

import { faCaretLeft, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { animated } from '@react-spring/web';
import cn from 'classnames';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { ItemCollapseIconDivProps } from './ItemCollapseIconDivProps';

/** Provides the expand/collapse icon.  Functionality to rotate according to the expand/collapse state must be provided by a parent component that calls the `useAccordionItemView()` hook. */
export const ItemCollapseIconDiv = forwardRef<SVGSVGElement, ItemCollapseIconDivProps>(
  ({ className, block = true, mode = 'vertical', size = 'lg', style }, ref) => {
    // TODO: Need to make an equivalent version of this that renders a button instead of a div.  This will be necessary
    // when there are buttons nested inside the accordion header that have onClick behaviors that do more than simply
    // toggling the accordion expanded/collapsed state.

    return (
      <StyledCollapseIcon
        ref={ref}
        className={cn(className, ItemCollapseIconDiv.displayName)}
        block={block}
        icon={mode === 'vertical' ? faCaretUp : faCaretLeft}
        size={size}
        style={style}
      />
    );
  },
);

ItemCollapseIconDiv.displayName = 'ItemCollapseIconDiv';

const StyledCollapseIcon = styled(animated(Icon))`
  flex: 0 0 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;
