import { useMemo } from 'react';

import { usePopper } from 'react-popper';
import useMeasure from 'react-use-measure';

/** Internal hook that is really only useful inside the DropdownNg component definition. */
export function usePopperDropdownNg(buttonEle: HTMLButtonElement | null, popupEle: HTMLElement | null) {
  const [buttonMeasureRef, { width: buttonWidth }] = useMeasure();

  const popperConfig: Parameters<typeof usePopper>[2] = useMemo(
    () => ({
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            state.styles.popper.width = `${buttonWidth}px`;

            // Fade in/out animations on a dropdown are not desired - at least for desktop.  Mobile users tend to expect more animations.
            state.styles.popper.transition = 'none';
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${buttonWidth}px`;

            // Fade in/out animations on a dropdown are not desired - at least for desktop.  Mobile users tend to expect more animations.
            state.elements.popper.style.transition = 'none';
            state.elements.popper.classList.remove('fade');
          },
        },
      ],
    }),
    [buttonWidth],
  );

  usePopper(buttonEle, popupEle, popperConfig);

  return { buttonMeasureRef, buttonWidth };
}
