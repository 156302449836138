import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { DynamicFormStatus } from '../constants';
import { DynamicFormActions } from './actions';

export const dynamicFormAdapter = createEntityAdapter({
  selectId: (dynForm) => {
    return `${dynForm.entityType}-${dynForm.entityId}`;
  },
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...dynamicFormAdapter.getInitialState(),
  total: 0,
  status: DynamicFormStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(DynamicFormActions.getById.fulfilled, (draft, action) => {
    dynamicFormAdapter.upsertOne(draft, action.payload);
    draft.status = DynamicFormStatus.FETCHED;
  });
  builder.addCase(DynamicFormActions.edit.fulfilled, (draft, action) => {
    dynamicFormAdapter.setOne(draft, action.payload);
    draft.status = DynamicFormStatus.UPDATED;
  });
});
