import { FunctionComponent, useMemo } from 'react';

import { findOrThrow } from 'core/utils';

import { LookupCellProps } from './LookupCellProps';

export const LookupCell: FunctionComponent<LookupCellProps> = ({ className, dataItem, field, lookups, lookupItemKey, textField }) => {
  const lookupTextField = useMemo(
    () => (field == null ? undefined : findOrThrow(lookups, (l) => l[lookupItemKey] === dataItem[field])[textField]),
    [dataItem, field, lookupItemKey, lookups, textField],
  );

  const content = useMemo(() => <td className={className}>{lookupTextField}</td>, [className, lookupTextField]);

  return content;
};

LookupCell.displayName = 'LookupCell';
