import { hasText } from './hasText';

/** Trim a string to remove leading/trailing whitespace and to convert empty strings into `null`.
 * @param [type='text'] Specifying a type of "text" will remove leading and trailing whitespace.  Values that are empty, whitespace, or `null` will all be converted into `null`.  Specifying a type of "password" will retain leading and trailing whitespace, but the empty string and `null` will both return `null`.  Default type is "text".
 */
export function trimForStorage(
  value: string | null,
  type: 'text' | 'password' = 'text',
): string | null {
  if (type === 'password') return value === '' ? null : value;

  return hasText(value) ? value.trim() : null;
}
