import { forwardRef } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { ItemBodyDivProps } from './ItemBodyDivProps';

/** Element that contains the accordion item body.  This should be placed as the direct descendant of the ItemBodyAnimationDiv element. */
export const ItemBodyDiv = forwardRef<HTMLDivElement, ItemBodyDivProps>(
  ({ className, mode = 'vertical', ...rest }, ref) => (
    <StyledBodyDiv
      ref={ref}
      className={cn(className, ItemBodyDiv.displayName, {
        horizontal: mode === 'horizontal',
        vertical: mode === 'vertical',
      })}
      {...rest}
    />
  ),
);

ItemBodyDiv.displayName = 'ItemBodyDiv';

const StyledBodyDiv = styled.div`
  flex: 0 0 min-content;
`;
