import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card } from 'core/ui';

import TimeIcon from '../assets/time.svg?react';

const OverreadHistoryCard = ({ overreadHistoryEntry }) => {
  return (
    <StyledCard>
      <Card.Header>
        <TimeIcon />{' '}
        {dayjs(overreadHistoryEntry.dateCreated).format('MM/DD/YYYY HH:mm:ss')}
      </Card.Header>
      <Card.Body>
        <StyledUserDiv>{overreadHistoryEntry.userCreated}</StyledUserDiv>
        <StyledDescriptionDiv
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(overreadHistoryEntry.overRead),
          }}
        />
      </Card.Body>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.space.spacing30};
`;

const StyledUserDiv = styled.div`
  color: ${({ theme }) => theme.colors.palette.aquas[9]};
`;

const StyledDescriptionDiv = styled.div`
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  white-space: pre;
`;

OverreadHistoryCard.propTypes = {
  overreadHistoryEntry: PropTypes.shape({
    overRead: PropTypes.string,
    userCreated: PropTypes.string,
    dateCreated: PropTypes.string,
  }),
};

OverreadHistoryCard.defaultProps = {
  overreadHistoryEntry: {
    overRead: 'Normal sinus rhythm, premature ventricular complexes',
    userCreated: 'testphysician@compumedinc.com',
    dateCreated: '12/2/2021 9:26:33 AM',
  },
};

export { OverreadHistoryCard };
