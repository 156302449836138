import { FunctionComponent } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  useInitializationEffect,
  useQueryParam,
  useValidatedParam,
} from 'core/hooks';
import { hasText } from 'core/utils';

import { AuthenticationScheme, useAuthentication } from 'features/auth';

export const ShareLandingAuthorizationChallenge: FunctionComponent = () => {
  const { activeScheme } = useAuthentication();
  const navigate = useNavigate();
  const returnRoute = useQueryParam('return', true);
  const linkId = useValidatedParam('linkId', 'guid', true);

  useInitializationEffect(() => {
    // If the user is already authenticated for the share then just navigate to the viewer (or the returnRoute if specified).
    if (activeScheme === AuthenticationScheme.SHARE) {
      navigate(hasText(returnRoute) ? returnRoute : `/share/${linkId}/viewer`);
    }
  });

  return null;
};

ShareLandingAuthorizationChallenge.displayName =
  'ShareLandingAuthorizationChallenge';
