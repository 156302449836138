import { createContext } from 'react';

import { NavigationBlockerProviderContextType } from './types';

export const NavigationBlockerProviderContext =
  createContext<NavigationBlockerProviderContextType>({
    setBlockPredicate: () => {
      throw new Error(
        'Cannot call setIsBlocked() because <NavigationBlockerProvider /> is not a parent in the React dom tree.',
      );
    },
  });

NavigationBlockerProviderContext.displayName =
  'NavigationBlockerProviderContext';
