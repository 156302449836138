import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions } from 'core/api';
// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from 'core/api/services';

import { FieldCategoryRouteService } from '../services';
import { fieldCategoryEntity } from './normalizr-schema';

const getById = createAsyncThunk(
  'fieldCategories/get-by-id',
  async (fieldCategoryId, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(FieldCategoryRouteService.getByIdRoute(fieldCategoryId)),
    );

    return result;
  },
);

const getAll = createAsyncThunk(
  'fieldCategories/get-all',
  async (_, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(FieldCategoryRouteService.getAllRoute()),
    );

    const normalized = normalize(result, [fieldCategoryEntity]);

    return normalized.entities;
  },
);

export const FieldCategoryActions = {
  getById,
  getAll,
};
