import { ViewerModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class ViewerClient {
  constructor(private httpClient: HttpClient) {
    this.getAllViewers = this.getAllViewers.bind(this);
  }

  public async getAllViewers(authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode,
    });
    const viewers = await this.httpClient.fetchAndParse<ViewerModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Viewers`,
        options,
      ),
    );
    return viewers;
  }
}
