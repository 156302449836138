import { memo } from 'react';

import { GridHeaderCellProps } from '@progress/kendo-react-grid';

import { HeaderCell } from 'core/ui';

import { ExamsGridService } from '../services';

export const ExamGridHeaderCell = memo<GridHeaderCellProps>(({ field, ...restProps }) => {
  if (field == null) {
    throw new Error('Cannot render ExamGridHeaderCell because the field property is null or undefined.');
  }

  const description = ExamsGridService.getColumnDefinition(field).headerCellDescription;

  return <HeaderCell {...restProps} description={description} />;
});

ExamGridHeaderCell.displayName = 'ExamGridHeaderCell';
