import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { GroupModel } from 'models';

import { useTabLocationPersistence } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, TabContainer, TabContent, TabNav, TabNavContainer, TabNavItem, TabNavLink, TabPane } from 'core/ui';

import { LocationActions } from 'features/location';
import { useAppDispatch, useAppSelector } from 'features/main/hooks';
import { ServiceActions } from 'features/service';

import { InitialValues } from '../constants';
import { GroupActions, GroupSelectors } from '../redux';
import { ContactTabFormValues, GeneralTabFormValues, IntegrationTabFormValues } from '../types';
import { ContactTabContent } from './ContactTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { IntegrationTabContent } from './IntegrationTabContent';

const allTabKeys = ['general', 'contact', 'integration'];

function copyFormsToModel(
  initialState: Partial<GroupModel> | null | undefined,
  generalForm: GeneralTabFormValues,
  contactForm: ContactTabFormValues,
  integrationForm: IntegrationTabFormValues,
): GroupModel {
  if (initialState == null) {
    return {
      ...InitialValues.nonFormFields,
      ...generalForm,
      ...contactForm,
      ...integrationForm,
      signinMethod:
        integrationForm.signinMethod === 'Any' // API expects null if any is selected
          ? null
          : integrationForm.signinMethod,
    };
  }

  return {
    ...InitialValues.nonFormFields,
    ...initialState,
    ...generalForm,
    ...contactForm,
    ...integrationForm,
    signinMethod:
      integrationForm.signinMethod === 'Any' // API expects null if any is selected
        ? null
        : integrationForm.signinMethod,
  };
}

function copyModelToForms(group: GroupModel): {
  generalForm: GeneralTabFormValues;
  contactForm: ContactTabFormValues;
  integrationForm: IntegrationTabFormValues;
} {
  return {
    generalForm: {
      name: group.name ?? '',
      locations: group.locations,
      domains: group.domains,
      approveAutomatically: group.approveAutomatically,
      active: group.active,
      services: group.services,
      pendingApprovalMessage: group.pendingApprovalMessage ?? '',
    },
    contactForm: {
      approvalEmail: group.approvalEmail ?? '',
    },
    integrationForm: {
      uploadURL: group.uploadURL ?? '',
      signinMethod: group.signinMethod ?? '',
      externalUid: group.externalUid ?? '',
    },
  };
}

export const GroupForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: idParam } = useParams();
  const [generalForm, setGeneralForm] = useState(InitialValues.generalForm);
  const [contactForm, setContactForm] = useState(InitialValues.contactForm);
  const [integrationForm, setIntegrationForm] = useState(InitialValues.integrationForm);
  const [isFormReady, setIsFormReady] = useState(false);

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const isFormInEditMode = id !== undefined;

  const group = useAppSelector(GroupSelectors.getById(id));

  useEffect(() => {
    if (isFormInEditMode) {
      dispatch(GroupActions.getById(id));
    }
  }, [dispatch, id, isFormInEditMode]);

  useEffect(() => {
    dispatch(LocationActions.getAll(undefined));
    dispatch(ServiceActions.getAll(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (isFormInEditMode && group != null) {
      const newForms = copyModelToForms(group);

      setGeneralForm(newForms.generalForm);
      setContactForm(newForms.contactForm);
      setIntegrationForm(newForms.integrationForm);
      setIsFormReady(true);
    }
  }, [group, isFormInEditMode]);

  useEffect(() => {
    if (!isFormInEditMode) {
      setIsFormReady(true);
    }
  }, [isFormInEditMode]);

  const handleSubmit = useCallback(async () => {
    const newGroup = copyFormsToModel(group, generalForm, contactForm, integrationForm);

    if (isFormInEditMode) {
      await dispatch(GroupActions.edit(newGroup));
      NotificationsService.displaySuccess(`Saved ${newGroup.name}.`);
    } else {
      await dispatch(GroupActions.add(newGroup));
      NotificationsService.displaySuccess(`Created ${newGroup.name}.`);
    }
    navigate('/group');
  }, [group, generalForm, contactForm, integrationForm, isFormInEditMode, dispatch, navigate]);

  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);

  if (!isFormReady) return <></>;

  // @TODO remove services filter once it is implemented in backend
  return (
    <Page>
      <PageHeader title={isFormInEditMode ? 'Edit Group' : 'New Group'} />
      <StyledTabWrapper>
        <TabContainer id="location-type-tabs" onSelect={onTabSelect} defaultActiveKey={initialTabKey}>
          <TabNavContainer>
            <TabNav>
              <TabNavItem>
                <TabNavLink eventKey="general">General</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="contact">Contact Info</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="integration">Integration</TabNavLink>
              </TabNavItem>
            </TabNav>
          </TabNavContainer>
          <TabContent>
            <TabPane eventKey="general">
              <GeneralTabContent initialValues={generalForm} onChange={setGeneralForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="contact">
              <ContactTabContent initialValues={contactForm} onChange={setContactForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="integration">
              <IntegrationTabContent initialValues={integrationForm} onChange={setIntegrationForm} onSubmit={handleSubmit} />
            </TabPane>
          </TabContent>
        </TabContainer>
      </StyledTabWrapper>
    </Page>
  );
};

GroupForm.displayName = 'GroupForm';

const StyledTabWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;

  & .tab-content {
    overflow: auto;
  }
`;
