import { FunctionComponent } from 'react';

import { Badge as KendoBadge } from '@progress/kendo-react-indicators';
import styled, { DefaultTheme } from 'styled-components';

import { StatusProps } from './StatusProps';
import { StatusTypes } from './constants';

export const Status: FunctionComponent<StatusProps> = ({
  label,
  type = StatusTypes.DEFAULT,
}) => (
  <StyledDivContainer>
    <StyledBadgeStatus $type={type} rounded="full" />
    {label && <StyledSpanLabel $type={type}>{label}</StyledSpanLabel>}
  </StyledDivContainer>
);

Status.displayName = 'Status';

type StyledElementProps = {
  theme: DefaultTheme;
  $type?: StatusTypes;
};

const resolvePalette = ({ theme, $type }: StyledElementProps) => {
  switch ($type) {
    case StatusTypes.DEFAULT: {
      return {
        background: theme.colors.palette.grayscale[3],
        label: theme.colors.textPrimary,
      };
    }
    case StatusTypes.ERROR: {
      return {
        background: theme.colors.error,
        label: theme.colors.textPrimary,
      };
    }
    case StatusTypes.WARNING: {
      return {
        background: theme.colors.warning,
        label: theme.colors.textPrimary,
      };
    }
    case StatusTypes.SUCCESS: {
      return {
        background: theme.colors.success,
        label: theme.colors.textPrimary,
      };
    }
    case StatusTypes.PROCESSING: {
      return {
        background: theme.colors.secondary,
        label: theme.colors.textPrimary,
      };
    }
    default:
      return {
        background: theme.colors.palette.grayscale[3],
        label: theme.colors.textPrimary,
      };
  }
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveLabelColor = (props: StyledElementProps) => {
  const { label } = resolvePalette(props);

  return label;
};

const StyledBadgeStatus = styled(KendoBadge)<{ $type?: StatusTypes }>`
  &.k-badge {
    background-color: ${resolveBackgroundColor};
    border: none;
    height: ${({ theme }) => theme.sizes.statusHeight};
    position: relative;
    transform: none;
    width: ${({ theme }) => theme.sizes.statusWidth};
    z-index: 1;
  }
`;

const StyledDivContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSpanLabel = styled.span<{ $type?: StatusTypes }>`
  color: ${resolveLabelColor};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  line-height: ${({ theme }) => theme.lineHeights.footnote};
  margin-left: ${({ theme }) => theme.space.spacing20};
`;
