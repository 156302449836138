import { FunctionComponent, useCallback, useMemo } from 'react';

import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';

import { EditorFieldProps } from './EditorFieldProps';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import styled from 'styled-components';
import { ErrorMessage } from '../ErrorMessage';

export const EditorField: FunctionComponent<EditorFieldProps> = ({ name, required, validator, onChange, ...rest }) => {
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<unknown, unknown>[] = [];

    if (required) newValidators.push(RhfValidators.required);
    if (validator) newValidators.push(validator);

    return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
  }, [required, validator]);

  const {
    field: { onChange: rhfOnChange, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({
    name,
    rules: {
      validate: validatorInternal, // Intentionally using a custom validator for "required" because the browser native version will allow string values with all whitespace.
    },
  });

  const handleChange = useCallback(
    (value: string | undefined, event: editor.IModelContentChangedEvent) => {
      rhfOnChange(value);
      onChange?.(value, event);
    },
    [onChange, rhfOnChange],
  );

  return (
    <StyledContainer>
      <StyledEditor {...fieldRest} {...rest} onChange={handleChange} invalid={invalid} />
      {invalid && <ErrorMessage>{error?.message}</ErrorMessage>}
    </StyledContainer>
  );
};

EditorField.displayName = 'EditorField';

const StyledEditor = styled(Editor)<{ invalid: boolean }>`
  border: 1px solid ${(props) => (props.invalid ? props.theme.colors.error : '#D9D9D9;')};
`;

const StyledContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space.spacing60};
`;
