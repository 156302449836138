import { memo, useMemo } from 'react';

import _sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import { ExamsGridService } from '../../exam/services';
import { WorklistColumnSelectionProps } from '../types';
import { WorklistColumnSelectionItem } from './WorklistColumnSelectionItem';

export const WorklistColumnSelection = memo<WorklistColumnSelectionProps>(({ columns, columnsState, onChange }) => {
  const orderedColumns = useMemo(
    () => _sortBy(columns, (column) => ExamsGridService.getColumnState(columnsState, column.field)?.orderIndex),
    [columns, columnsState],
  );

  return (
    <StyledWrapperDiv>
      <StyledLabelDiv>VISIBLE COLUMNS</StyledLabelDiv>
      {orderedColumns.map((column) => (
        <WorklistColumnSelectionItem column={column} columnsState={columnsState} key={`${column.field}-checkbox`} onChange={onChange} />
      ))}
    </StyledWrapperDiv>
  );
});

WorklistColumnSelection.displayName = 'WorklistColumnSelection';

const StyledWrapperDiv = styled.div`
  max-height: 20vh;
  overflow: auto;
`;

const StyledLabelDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;
