import { createEntityAdapter } from '@reduxjs/toolkit';

const rootSelector = (state) => state.fieldCategories;

const { selectById, selectAll: getAll } =
  createEntityAdapter().getSelectors(rootSelector);

const getStatus = (state) => rootSelector(state).status;

const getById = (id) => (state) => selectById(state, id);

const getTotal = (state) => rootSelector(state).total;

export const FieldCategorySelectors = {
  getAll,
  getById,
  getTotal,
  getStatus,
};
