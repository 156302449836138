import {
  SortDescriptor,
  State,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { createAction } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { Worklist } from 'models';

import { ApiActions, createAsyncThunk } from 'core/api';
import { ApiResponse } from 'core/api/types';

import { DefaultColumnsState } from 'features/exam/constants';

import { DEFAULT_FILTER_DATA_STATE } from '../../exam/services/exam-grid-service';
import { WorklistRouteService } from '../services';
import { worklistEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'worklists/get-all',
  async (_, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(WorklistRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<Worklist[]>;

    const normalized = normalize<
      Worklist,
      {
        // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
        worklists: { [key: string]: Worklist };
      }
    >(result, [worklistEntity]);

    return normalized.entities;
  },
);

const getCounts = createAsyncThunk(
  'worklists/get-counts',
  async (worklistIds: string[], { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(WorklistRouteService.getCountsRoute(worklistIds)),
    )) as unknown as ApiResponse<Record<string, number>>;

    return result;
  },
);

const add = createAsyncThunk<Worklist, Worklist>(
  'worklists/add',
  async (worklist, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: WorklistRouteService.getBaseRoute(),
        body: {
          ...worklist,
          columnState: JSON.stringify(DefaultColumnsState),
          dataState: JSON.stringify(
            DEFAULT_FILTER_DATA_STATE as { sort: SortDescriptor[] },
          ),
          oDataResourceString: toDataSourceRequestString(
            DEFAULT_FILTER_DATA_STATE as State,
          ),
        },
      }),
    )) as unknown as ApiResponse<Worklist>;

    const normalized = normalize<
      Worklist,
      {
        worklists: Worklist;
      }
    >({ ...worklist, id: result.id }, worklistEntity);

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'worklists/edit',
  async (worklist: Worklist, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.put({
        url: WorklistRouteService.getByIdRoute(worklist.id!),
        body: {
          ...worklist,
          dataState: JSON.stringify(worklist.dataState),
          columnState: JSON.stringify(worklist.columnState),
          oDataResourceString: toDataSourceRequestString(worklist.dataState!),
        },
      }),
    )) as unknown as ApiResponse<Worklist>;

    const normalized = normalize<
      Worklist,
      {
        worklists: Worklist;
      }
    >(result, worklistEntity);

    return normalized.entities;
  },
);

const remove = createAsyncThunk(
  'worklists/delete',
  async (worklist: Worklist, { dispatch }) => {
    await dispatch(
      ApiActions.remove(WorklistRouteService.getByIdRoute(worklist.id!)),
    );

    return worklist;
  },
);

const select = createAction<string>('worklists/select');

export const WorklistActions = {
  add,
  edit,
  getAll,
  getCounts,
  remove,
  select,
};
