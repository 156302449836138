import { FileRouteService } from '../../file';

// we are appending date_modified to the file url so that we can rerender iframe
// when the file is modified (rotated) but the file url stays the same
const getFileUrl = (fileId, fileUrl, dateModified, accessToken) => {
  const params = new URLSearchParams({
    url: fileUrl,
    access_token: accessToken,
    ...(dateModified == null ? {} : { date_modified: dateModified }),
  });
  return `${FileRouteService.getByIdRoute(fileId)}?${params.toString()}`;
};

const resolveFileUrl = (file, accessToken) => {
  if (!file.url) {
    return '';
  }

  if (file.fileType === 'LINK') {
    return file.url;
  }

  return getFileUrl(file.id, file.url, file.modified, accessToken);
};

export const FileUrlService = {
  getFileUrl,
  resolveFileUrl,
};
