import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DestinationModel, IntegrationModel, TemplateModel } from 'models';

import {
  FieldContainer,
  GridColumn,
  RhfValidators,
  createStyledRhfForm,
} from 'core/forms';
import { useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import {
  Button,
  DropdownField,
  InputField,
  Label,
  Page,
  PageHeader,
  SwitchField,
} from 'core/ui';

import { apiClient } from 'features/api';

import { DestinationEditService } from '../services';
import { EditFormValues } from '../types';

const StyledForm = createStyledRhfForm('min-content 400px');

const NullTemplateOption = { id: null, name: '' };
const NullIntegrationOption = { id: null, name: '' };

export const DestinationForm: FunctionComponent = () => {
  const destinationId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [destination, setDestination] = useState<DestinationModel | null>(null);
  const [templates, setTemplates] = useState<TemplateModel[]>([]);
  const [integrations, setIntegrations] = useState<IntegrationModel[]>([]);

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...DestinationEditService.EditFormDefaults },
  });
  const { reset } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (destination == null) {
        throw new Error(
          'Cannot proceed because destination is null or undefined.',
        );
      }

      const newDestination: DestinationModel =
        DestinationEditService.copyFormToModel(destination.id, values);

      if (typeof destinationId === 'number') {
        await apiClient.destinationClient.updateDestination(newDestination);
      } else {
        await apiClient.destinationClient.createDestination(newDestination);
      }

      reset(
        DestinationEditService.copyModelToForm(
          newDestination,
          templates,
          integrations,
        ),
        {
          keepValues: true,
          keepDefaultValues: false,
        },
      );
      NotificationsService.displaySuccess(
        destinationId == null ? 'Destination created.' : 'Destination saved.',
      );
      navigate('/destination');
    },
    [destination, destinationId, navigate, reset, templates, integrations],
  );

  useEffect(() => {
    (async () => {
      const [newDestination, newTemplates, newIntegrations] = await Promise.all(
        [
          destinationId == null
            ? new Promise<DestinationModel>((resolve) => {
                resolve(DestinationEditService.createDefaultDestination());
              })
            : apiClient.destinationClient.getDestinationById(destinationId),
          apiClient.templateClient.getTemplates('DESTINATION'),
          apiClient.integrationClient.getAllIntegrations(),
        ],
      );

      setDestination(newDestination);
      setTemplates(newTemplates);
      setIntegrations(newIntegrations);
      reset(
        DestinationEditService.copyModelToForm(
          newDestination,
          newTemplates,
          newIntegrations,
        ),
        {
          keepValues: false,
          keepDefaultValues: false,
        },
      );
    })();
  }, [reset, destinationId]);

  if (destination == null) return null;

  return (
    <Page>
      <PageHeader title={`${destinationId ? 'Edit' : 'New'} Destination`} />
      <FormProvider {...rhfContext}>
        <StyledForm
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(handleSubmit)}
        >
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="name" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Description</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Integration</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField
                name="integration"
                data={integrations}
                defaultItem={NullIntegrationOption}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Host</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="host" validator={RhfValidators.hostname} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Port</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField
                type="number"
                name="port"
                maxLength={5}
                validator={RhfValidators.port}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Template</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField
                name="template"
                data={templates}
                defaultItem={NullTemplateOption}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Source Directory</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="sourceDirectory" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Processing Directory</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="processingDirectory" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Archive Directory</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="archiveDirectory" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Error Directory</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="errorDirectory" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>User Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="userName" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Password</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="password" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>API Key</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="apiKey" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Email</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="email" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Title</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="title" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Connection String</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="connectionString" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Queue Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="queueName" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="2" columnEnd="span 1">
            <div>
              <Button type="submit">Save</Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
};

DestinationForm.displayName = 'DestinationForm';
