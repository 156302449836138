import { FunctionComponent, useCallback } from 'react';

import { faFile, faFilesMedical } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import styled from 'styled-components';

import { BreakpointSelectors, Icon } from 'core/ui';
import { hasText } from 'core/utils';

import { ImgProxy } from 'features/file/fragments/ImgProxy';

import { ViewConstants } from '../constants';
import { FileButtonProps } from '../types';

export const FileButton: FunctionComponent<FileButtonProps> = ({
  className,
  file,
  selected,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(file);
  }, [file, onClick]);

  return (
    <StyledThumbnailButton
      type="button"
      $selected={selected}
      onClick={handleClick}
      className={cn(
        className,
        `testing_${FileButton.displayName}_view-file-button`,
      )}
      data-file-id={file.id}
    >
      {hasText(file.thumbnailUrl) && (
        <StyledImgProxy
          fileId={file.id}
          thumbnailUrl={file.thumbnailUrl}
          dateModified={file.modified}
        />
      )}
      {!hasText(file.thumbnailUrl) && (
        <StyledIcon
          icon={resolveIconDefinition(file.fileType)}
          size="4x"
          fixedWidth
        />
      )}
    </StyledThumbnailButton>
  );
};

FileButton.displayName = 'FileButton';

function resolveIconDefinition(fileType: string | null) {
  switch (fileType?.toLowerCase()) {
    case 'link':
      return faFilesMedical;
    default:
      return faFile;
  }
}

const StyledThumbnailButton = styled.button<{ $selected: boolean }>`
  flex: 0 0 min-content;
  display: flex;
  border: ${ViewConstants.thumbnailBorderSize}px solid
    ${({ theme, $selected }) =>
      $selected ? theme.colors.palette.blues[1] : 'transparent'};
  background-color: transparent;
  border-radius: 6px;
  color: inherit;
  padding: 0;

  :hover {
    border-color: ${({ theme }) => theme.colors.palette.blues[3]};
  }
`;

const StyledImgProxy = styled(ImgProxy)`
  object-fit: contain;

  ${BreakpointSelectors.Desktop} & {
    height: ${ViewConstants.thumbnailMainAxisSize}px;
    min-width: ${ViewConstants.thumbnailCrossAxisMinSize}px;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationPortrait} & {
    height: ${ViewConstants.thumbnailMainAxisSize}px;
    min-width: ${ViewConstants.thumbnailCrossAxisMinSize}px;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationLandscape} & {
    min-height: ${ViewConstants.thumbnailCrossAxisMinSize}px;
    width: ${ViewConstants.thumbnailMainAxisSize}px;
  }
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${BreakpointSelectors.Desktop} & {
    height: ${ViewConstants.thumbnailMainAxisSize}px;
    min-width: ${ViewConstants.thumbnailCrossAxisMinSize}px;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationPortrait} & {
    height: ${ViewConstants.thumbnailMainAxisSize}px;
    min-width: ${ViewConstants.thumbnailCrossAxisMinSize}px;
  }

  ${BreakpointSelectors.Mobile + BreakpointSelectors.OrientationLandscape} & {
    min-height: ${ViewConstants.thumbnailCrossAxisMinSize}px;
    width: ${ViewConstants.thumbnailMainAxisSize}px;
  }
`;
