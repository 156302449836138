export const UploadTrackerStatus = {
  PENDING: 'upload-tracker-pending',
  ADDED: 'upload-tracker-created',
  UPDATED: 'upload-tracker-updated',
  FETCHED: 'upload-tracker-fetched',
  ERROR: 'upload-tracker-error',
};

export const ContextMenuItems = {
  GatewayLogs: {
    text: 'Gateway Logs',
  },
};
