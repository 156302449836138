import { imageQualityAdapter } from './reducer';

const rootSelector = (state) => state.imageQualities;

const {
  selectById: getById,
  selectAll: getAll,
  selectTotal: getCount,
} = imageQualityAdapter.getSelectors(rootSelector);

export const ImageQualitySelectors = {
  getById,
  getAll,
  getCount,
};
