import { createSelector } from '@reduxjs/toolkit';

import { TatModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { tatAdapter } from './reducer';

const rootSelector = (state: RootState) => state.tats;

const { selectAll: getAll, selectTotal: getCount } =
  tatAdapter.getSelectors(rootSelector);

const getByIdOutput = (tats: TatModel[], id: number) =>
  tats.find((tat) => tat.id === id);

const getById = (id: number): AppSelector<TatModel | undefined> =>
  createSelector([getAll, () => id], getByIdOutput);

export const TatSelectors = {
  getById,
  getAll,
  getCount,
};
