import { memo, useEffect } from 'react';

import { useAuthentication } from '../hooks';

export const OidcSignOut = memo(() => {
  const { activeScheme, frontChannelLogoutOidc } = useAuthentication();

  useEffect(() => {
    // Wait until after the authentication layer has initialized.
    if (activeScheme == null) return;

    frontChannelLogoutOidc();
  }, [activeScheme, frontChannelLogoutOidc]);

  return null;
});

OidcSignOut.displayName = 'OidcSignOut';
