import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { HL7SendRoutes } from '../routes';
import { HL7SendHome } from './HL7SendHome';

export const HL7Send: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={HL7SendRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <HL7SendHome />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

HL7Send.displayName = 'HL7Send';
