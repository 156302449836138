import { memo } from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Label } from '@progress/kendo-react-labels';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { WarningMessageProps } from './WarningMessageProps';

export const WarningMessage = memo<WarningMessageProps>(({ children, className, id }) => {
  return (
    <StyledWarningBase className={className} id={id}>
      <Icon icon={faTriangleExclamation} size="xs" />
      {children}
    </StyledWarningBase>
  );
});

WarningMessage.displayName = 'WarningMessage';

const StyledWarningBase = styled(Label)`
  flex: 1 1 auto;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.body};
  color: ${({ theme }) => theme.colors.warning};
  margin-top: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
`;
