import React from 'react';

import { Button } from '@progress/kendo-react-buttons';
import PropTypes from 'prop-types';

import { useApiClient } from 'features/api';

type AzureBlobLinkProps = {
  children: React.ReactNode;
  containerName: string;
  blobName: string;
};
const AzureBlobLink = ({ children, containerName, blobName }: AzureBlobLinkProps) => {
  const apiClient = useApiClient();

  const handleClick = async () => {
    const url = await apiClient.filesClient.generateAzureBlobDownloadSasUrl(containerName, blobName);
    window.open(url, '_blank');
  };

  return (
    <Button fillMode="link" onClick={handleClick}>
      {children}
    </Button>
  );
};

AzureBlobLink.propTypes = {
  children: PropTypes.node,
  containerName: PropTypes.string.isRequired,
  blobName: PropTypes.string.isRequired,
};

AzureBlobLink.defaultProps = {
  children: null,
};

export { AzureBlobLink };
