import { memo } from 'react';

import styled from 'styled-components';

import { useBoolean, useEvent } from 'core/hooks';
import { Accordion } from 'core/ui';

import CollapseSidebarIcon from '../assets/collapse-sidedbar.svg?react';
import PlusIcon from '../assets/plus.svg?react';
import RefreshIcon from '../assets/refresh.svg?react';
import { WorklistSidebarProps } from '../types';
import { WorklistAddEditModal } from './WorklistAddEditModal';
import { WorklistSidebarAccordionItem } from './WorklistSidebarAccordionItem';

export const WorklistSidebar = memo<WorklistSidebarProps>(
  ({
    allWorklists,
    allCounts,
    selectedWorklistId,
    showSidebar,
    currentDataState,
    currentColumnsState,
    onShowSidebarChange,
    onSelectedWorklistIdChange,
    onRefreshClick,
    onCreateWorklist,
    onUpdateWorklist,
    onDeleteWorklist,
  }) => {
    const [showEditModal, { setTrue: openEditModal, setFalse: closeEditModal }] = useBoolean(false);

    const handleCollapseClick = useEvent(() => {
      if (showSidebar) {
        onShowSidebarChange(false);
      }
    });

    if (!showSidebar) {
      return null;
    }

    return (
      <>
        <StyledWrapperDiv>
          <StyledHeaderDiv>
            <StyledTitleDiv>WORKLIST</StyledTitleDiv>
            <StyledHeaderButtonsDiv>
              <StyledRefreshIcon onClick={onRefreshClick} />
              <StyledPlusIcon onClick={openEditModal} />
              <StyledCollapseSidebarIcon onClick={handleCollapseClick} />
            </StyledHeaderButtonsDiv>
          </StyledHeaderDiv>
          <StyledAccordion alwaysOpen>
            {allWorklists
              ?.filter((w) => w.parentViewId == null)
              ?.map((worklist) => {
                return (
                  <WorklistSidebarAccordionItem
                    key={worklist.id}
                    selectedWorklistId={selectedWorklistId}
                    allWorklists={allWorklists}
                    allCounts={allCounts}
                    worklistId={worklist.id}
                    onWorklistClick={onSelectedWorklistIdChange}
                  />
                );
              })}
          </StyledAccordion>
        </StyledWrapperDiv>
        {allWorklists && (
          <WorklistAddEditModal
            show={showEditModal}
            allWorklists={allWorklists}
            currentDataState={currentDataState}
            currentColumnsState={currentColumnsState}
            onClose={closeEditModal}
            onCreateWorklist={onCreateWorklist}
            onUpdateWorklist={onUpdateWorklist}
            onDeleteWorklist={onDeleteWorklist}
          />
        )}
      </>
    );
  },
);

WorklistSidebar.displayName = 'WorklistSidebar';

const StyledWrapperDiv = styled.div`
  background: white;
  margin-right: ${({ theme }) => theme.space.spacing20};
  min-width: 200px;
  overflow: auto;
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;

const StyledTitleDiv = styled.div`
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledHeaderDiv = styled.div`
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.spacing30};
  padding-left: ${({ theme }) => theme.space.spacing30};
  padding-right: ${({ theme }) => theme.space.spacing30};
`;

const StyledHeaderButtonsDiv = styled.div`
  display: flex;
`;

const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledCollapseSidebarIcon = styled(CollapseSidebarIcon)`
  cursor: pointer;
`;

const StyledAccordion = styled(Accordion)`
  &&,
  && .accordion-title {
    font-size: ${({ theme }) => theme.fontSizes.subheading};
  }

  && .accordion-button,
  .accordion-body {
    padding: 0;
  }
`;
