import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { TemplateModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, InputField, Label, Page, PageHeader } from 'core/ui';
import { SwitchField } from 'core/ui/Switch';

import { apiClient } from 'features/api';

import { TemplateEditService } from '../services';
import { EditFormValues } from '../types';
import { EditorField } from 'core/ui/Editor/EditorField';

const StyledForm = createStyledRhfForm('min-content 400px 1fr');

export const TemplateForm: FunctionComponent = () => {
  const templateId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [template, setTemplate] = useState<TemplateModel | null>(null);

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...TemplateEditService.EditFormDefaults },
    mode: 'onChange',
  });
  const { reset } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (template == null) {
        throw new Error('Cannot proceed because template is null or undefined.');
      }

      const newTemplate: TemplateModel = TemplateEditService.copyFormToModel(template.id, values);

      if (typeof templateId === 'number') {
        await apiClient.templateClient.updateTemplate(newTemplate);
      } else {
        await apiClient.templateClient.createTemplate(newTemplate);
      }

      reset(TemplateEditService.copyModelToForm(newTemplate), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(templateId == null ? 'Template created.' : 'Template saved.');
      navigate('/template');
    },
    [navigate, reset, template, templateId],
  );

  useEffect(() => {
    (async () => {
      const newTemplate = templateId == null ? TemplateEditService.createDefaultTemplate() : await apiClient.templateClient.getTemplateById(templateId);
      setTemplate(newTemplate);
      reset(TemplateEditService.copyModelToForm(newTemplate), {
        keepValues: false,
        keepDefaultValues: false,
      });
    })();
  }, [reset, templateId]);

  if (template == null) return null;

  return (
    <Page>
      <PageHeader title={`${templateId ? 'Edit' : 'New'} Template`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Type</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="type" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Name</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="name" required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Description</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Content</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 2">
            <FieldContainer $hideLabel>
              <EditorField required name="content" height="50vh" defaultLanguage="json" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="2" columnEnd="span 1">
            <div>
              <Button type="submit">Save</Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
};

TemplateForm.displayName = 'TemplateForm';
