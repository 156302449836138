import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field, FormValidatorsService } from 'core/forms';
import { Accordion, Icon, Input, MaskedInput } from 'core/ui';

import { InfoField } from './InfoField';
import { faUserNurse } from '@fortawesome/pro-solid-svg-icons';

const CoordinatorInfoAccordion = ({ eventKey, coordinator, isEditMode, isSidebarExpanded }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={CoordinatorIcon} title="Requestor">
        {(coordinator?.firstName || coordinator?.lastName) && (
          <StyledCoordinatorNameDiv>
            {coordinator?.firstName} {coordinator?.lastName}
          </StyledCoordinatorNameDiv>
        )}
      </Accordion.Header>
      <Accordion.Body>
        {!isEditMode && (
          <>
            <StyledInfoRow>
              <InfoField label="First Name" text={coordinator?.firstName} />
              <InfoField label="Last Name" text={coordinator?.lastName} />
            </StyledInfoRow>
            <StyledInfoRow>
              <InfoField label="Mobile Number" text={coordinator?.mobileNumber} />
              <InfoField label="Email" text={coordinator?.email} />
            </StyledInfoRow>
          </>
        )}
        {isEditMode &&
          (isSidebarExpanded ? (
            <>
              <StyledRow>
                <Col className="col-2">
                  <Field component={Input} label="First Name" name="coordinator.firstName" textField="firstName" />
                </Col>
                <Col className="col-2">
                  <Field component={Input} label="Last Name" name="coordinator.lastName" textField="lastName" />
                </Col>
                <Col className="col-2">
                  <Field component={Input} label="Mobile Number" name="coordinator.mobileNumber" textField="mobileNumber" />
                </Col>
                <Col className="col-2">
                  <Field component={Input} label="Email" name="coordinator.email" textField="email" />
                </Col>
              </StyledRow>
            </>
          ) : (
            <>
              <StyledRow>
                <Col className="col-6">
                  <Field component={Input} label="First Name" name="coordinator.firstName" textField="firstName" />
                </Col>
                <Col className="col-6">
                  <Field component={Input} label="Last Name" name="coordinator.lastName" textField="lastName" />
                </Col>
              </StyledRow>
              <StyledRow>
                <Col className="col-6">
                  <Field component={MaskedInput} label="Mobile Number" mask="(999) 000-0000" name="coordinator.mobileNumber" textField="mobileNumber" />
                </Col>
                <Col className="col-6">
                  <Field component={Input} label="Email" name="coordinator.email" textField="email" />
                </Col>
              </StyledRow>
            </>
          ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const CoordinatorIcon = () => {
  return <StyledIcon fixedWidth={false} icon={faUserNurse} />;
};

const StyledCoordinatorNameDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
`;

const StyledInfoRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledRow = styled(Row)`
  .col:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.spacing20};
  }

  label.k-label {
    margin-bottom: 0;
  }
`;

CoordinatorInfoAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  coordinator: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    mobileNumber: PropTypes.string,
  }),
  isEditMode: PropTypes.bool,
  isSidebarExpanded: PropTypes.bool,
};

CoordinatorInfoAccordion.defaultProps = {
  coordinator: null,
  isEditMode: false,
  isSidebarExpanded: false,
};

export { CoordinatorInfoAccordion };
