import { FunctionComponent, memo } from 'react';

import { hasText } from 'core/utils';

import { HighlightTextProps } from './HighlightTextProps';
import { RecursiveHighlight } from './RecursiveHighlight';

export const HighlightText: FunctionComponent<HighlightTextProps> = memo(
  ({ children, searchValue }) => {
    if (!hasText(children) || !hasText(searchValue)) return <>{children}</>;

    return (
      <RecursiveHighlight
        value={children}
        filter={searchValue.toLocaleLowerCase()}
      />
    );
  },
);

HighlightText.displayName = 'HighlightText';
