// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/dynamic-forms`;

const getByIdRoute = (entityType, entityId) =>
  `${getBaseRoute()}/${encodeURIComponent(entityType)}/${entityId}`;

const editRoute = (entityType, entityId) =>
  `${getBaseRoute()}/${encodeURIComponent(entityType)}/${entityId}`;

export const DynamicFormRouteService = {
  getBaseRoute,
  getByIdRoute,
  editRoute,
};
