import { DragEventHandler, FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import ReorderIcon from '../../assets/reorder-icon.svg?react';
import { ReorderCellProps } from './ReorderCellProps';

export const ReorderCell: FunctionComponent<ReorderCellProps> = ({ onDragStart, onDragOver, dataItem }) => {
  const handleDragStart: DragEventHandler = useEvent((event) => {
    onDragStart?.(dataItem);
    event.dataTransfer.setData('dragging', '');
  });

  const handleDragOver: DragEventHandler = useEvent((event) => {
    onDragOver?.(dataItem);
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'copy';
  });

  const content = useMemo(
    () => (
      <StyledTdTextIcon onDragOver={handleDragOver}>
        <StyledSpanIconWrapper draggable onDragStart={handleDragStart}>
          <ReorderIcon />
        </StyledSpanIconWrapper>
      </StyledTdTextIcon>
    ),
    [handleDragOver, handleDragStart],
  );

  return content;
};

ReorderCell.displayName = 'ReorderCell';

const StyledTdTextIcon = styled.td`
  color: ${({ theme }) => theme.colors.palette.grayscale[6]};
`;

const StyledSpanIconWrapper = styled.span`
  cursor: move;
`;
