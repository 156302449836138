import { useEffect, useMemo, useState } from 'react';

import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { NotificationsService } from 'core/notifications';
import { Breadcrumb, CollapsibleSidebar } from 'core/ui';

import { useApiClient } from 'features/api';
import { useUserSettings } from 'features/settings';
import { getServiceDetailsFromForm } from 'features/dynamic-forms/services/service-details-service';

import { FileSelectSidebar, FileViewer } from '../../file';
import { PatientActions, PatientSelectors, SelectPatientModal, usePatientSelectSearch } from '../../patient';
import { ExamApiStatus } from '../constants';
import { useExamFiles } from '../hooks';
import { ExamActions, ExamSelectors } from '../redux';
import { ExamProcessingHeader } from './ExamProcessingHeader';
import { ExamProcessingSidebarBody } from './ExamProcessingSidebarBody';
import { homeIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';

const BREADCRUMBS_DATA = [
  {
    id: 'exams',
    text: 'Exams',
    navigateTo: '/exam/',
  },
];

export const ExamProcessing = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const exam = useSelector(ExamSelectors.getById(id));
  const patient = useSelector(PatientSelectors.getById(exam?.patient_Id));
  const totalSearchPatients = useSelector(PatientSelectors.getTotal);
  const status = useSelector(ExamSelectors.getStatus);
  const [accessToken, setAccessToken] = useState('');
  const [locationId, setLocationId] = useState(null);

  useEffect(() => {
    (async () => {
      setAccessToken(await apiClient.httpClient.getAccessToken());
    })();
  }, [dispatch, apiClient.httpClient, apiClient.httpClient.getAccessToken]);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const { files, currentFile, setCurrentFile, getFilesByExamId } = useExamFiles(id, exam?.statusType?.name, accessToken);

  const {
    currentPatient,
    handleCreateNewPatientClick,
    handlePatientSelect,
    handlePatientsSearchDataStateChange,
    patientFormState,
    patientSearchDataState,
    patientSearchText,
    searchPatients,
    setCurrentPatient,
    setPatientFormState,
    setPatientSearchText,
    setShowPatientsDialog,
    showPatientsDialog,
  } = usePatientSelectSearch(undefined, locationId);

  const isFormInEditMode = id !== undefined;

  const { homePageUrl } = useUserSettings(true);

  const breadcrumbs = useMemo(() => {
    return [
      {
        id: 'home',
        text: 'Home',
        icon: <StyledIcon icon={homeIcon} />,
        navigateToExternal: homePageUrl,
      },
      ...BREADCRUMBS_DATA,
    ];
  }, [homePageUrl]);

  const handleBreadcrumbClick = (event) => {
    const selectedItem = breadcrumbs.find((item) => item.id === event.id);

    if (selectedItem.navigateTo) {
      navigate(selectedItem.navigateTo);

      return;
    }

    window.location = selectedItem.navigateToExternal;
  };

  const mapDataToNameValuePair = (data) => _map(data, (value, key) => ({ name: key, value }));

  const handleSubmit = ({ values, isValid }) => {
    if (!isValid) {
      return;
    }
    const examData = {
      ...values,
      files,
      location_Id: values.location?.id,
      patient_id: currentPatient?.id,
      physician_Id: values.physician?.id,
      serviceId: values.service?.id,
      sla: values.sla ? values.sla.value : values.sla, // no overread option has values.sla === null
    };

    //map forms by ID to dynamic form submission - allows for adding/editing data from multiple forms
    const formData = _map(examData.forms, (value, key) => {
      return {
        form: { Id: key },
        fields: mapDataToNameValuePair(value),
      };
    });

    const serviceDetails = getServiceDetailsFromForm(values);
    if (Object.keys(serviceDetails).length > 0) {
      formData.push({
        form: { Id: exam.id },
        fields: mapDataToNameValuePair(serviceDetails),
        entityType: 'ServiceDetails',
      });
    }
    examData.dynamicForms = formData;
    examData.services = Object.keys(values)
      .filter((key) => key.startsWith('services')) // Filter keys that start with "services"
      // concatenate the values of the keys that start with "services" into an array and flatten using flatmap
      .flatMap((key) => values[key]);

    if (isFormInEditMode) {
      dispatch(ExamActions.edit(examData));
    } else {
      dispatch(ExamActions.add(examData));
    }
  };

  useEffect(() => {
    setLocationId(exam?.location?.id ?? null);
  }, [exam?.location?.id]);

  useEffect(() => {
    setCurrentPatient(patient || exam?.patient);
  }, [exam, patient, setCurrentPatient]);

  useEffect(() => {
    if (exam?.patient_Id) {
      dispatch(PatientActions.getById(exam.patient_Id));
    }
  }, [dispatch, exam]);

  useEffect(() => {
    if (isFormInEditMode) {
      dispatch(ExamActions.getById(id));
    }
  }, [dispatch, id, isFormInEditMode]);

  useEffect(() => {
    if (status === ExamApiStatus.ADDED) {
      // HACK: close window, ideally would get ID from save and refresh data
      window.close();
    } else if (status === ExamApiStatus.UPDATED) {
      getFilesByExamId(id);
      // we re-fetch exam with the latest data because we cannot construct it from api response
      dispatch(ExamActions.getById(id));
      NotificationsService.displaySuccess('Exam updated successfully');
    }

    return () => {
      dispatch(ExamActions.clearExamSaveStatus());
    };
  }, [dispatch, getFilesByExamId, id, status]);

  return (
    <CollapsibleSidebar
      header={<Breadcrumb data={breadcrumbs} onItemSelect={handleBreadcrumbClick} onKeyDown={handleBreadcrumbClick} />}
      body={
        <ExamProcessingSidebarBody
          currentPatient={currentPatient}
          exam={exam}
          handleSubmit={handleSubmit}
          isFormInEditMode={isFormInEditMode}
          patientFormState={patientFormState}
          patientSearchText={patientSearchText}
          setCurrentPatient={setCurrentPatient}
          setPatientFormState={setPatientFormState}
          setPatientSearchText={setPatientSearchText}
          locationId={locationId}
          onLocationIdChange={setLocationId}
        />
      }
      expanded={sidebarExpanded}
      onExpandChange={setSidebarExpanded}
    >
      {showPatientsDialog && (
        <SelectPatientModal
          dataState={patientSearchDataState}
          patients={searchPatients}
          onClose={() => setShowPatientsDialog(false)}
          onCreateNew={() => handleCreateNewPatientClick(null)}
          onDataStateChange={handlePatientsSearchDataStateChange}
          onPatientSelect={handlePatientSelect}
          total={totalSearchPatients}
        />
      )}
      <StyledDivContent>
        <ExamProcessingHeader
          integrations={exam?.location?.integrations}
          email={exam?.location?.email}
          examId={id}
          fax={exam?.location?.fax}
          file={currentFile}
          hasReadHistory={!_isEmpty(exam?.reads)}
          locationHasIntegrations={!!exam?.location?.integrations?.length}
          locationHasDicomIntegration={exam?.location?.integrations?.some((s) => s.name === 'Dicom')}
          hasSuid={!!exam?.suid}
          physicianEmail={exam?.physician?.email}
          sidebarExpanded={sidebarExpanded}
        />
        {currentFile && <FileViewer file={currentFile} />}
      </StyledDivContent>
      <FileSelectSidebar
        files={files}
        handleFileSelect={(file) => setCurrentFile(file)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleAddNewFileClick={() => {}}
        selectedFile={currentFile}
        showDeleteBtn
        showEditBtn
        examType={exam?.service?.description}
        examId={id}
      />
    </CollapsibleSidebar>
  );
};

const StyledDivContent = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  contain: strict;
`;

const StyledIcon = styled(SvgIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
