import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  combineValidators,
  createStyledFormElement,
  greaterThanOrEqualTo,
  maxLength,
  required,
} from 'core/forms';
import {
  Button,
  DatePicker,
  Input,
  Label,
  NumericInput,
  Switch,
} from 'core/ui';

import { LocationSelection, LocationSelectors } from 'features/location';
import { useAppSelector } from 'features/main/hooks';

import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  serialNumber: combineValidators([required, maxLength(255)]),
  unitPCN: combineValidators([required, greaterThanOrEqualTo(0)]),
  modelType: combineValidators([required, maxLength(255)]),
  firmwareRevision: maxLength(255),
  user: maxLength(50),
  pfwAssetNumber: maxLength(255),
};

const GeneralTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof GeneralTabFormValues) => any;
  onChange: (values: GeneralTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const locations = useAppSelector(LocationSelectors.getAll);

  const handleFormChange = useCallback(() => {
    onChange({
      serialNumber: valueGetter('serialNumber'),
      unitPCN: valueGetter('unitPCN'),
      location: valueGetter('location'),
      modelType: valueGetter('modelType'),
      firmwareRevision: valueGetter('firmwareRevision'),
      user: valueGetter('user'),
      lastInDate: valueGetter('lastInDate'),
      lastOutDate: valueGetter('lastOutDate'),
      expectedInDate: valueGetter('expectedInDate'),
      updated: valueGetter('updated'),
      pfwAssetNumber: valueGetter('pfwAssetNumber'),
      active: valueGetter('active'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="serialNumber">Serial Number</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            name="serialNumber"
            editorId="serialNumber"
            component={Input}
            validator={validators.serialNumber}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="unitPCN">Unit PCN</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            name="unitPCN"
            editorId="unitPCN"
            component={NumericInput}
            validator={validators.unitPCN}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label>Location</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <LocationSelection
            data={locations}
            selectedLocation={null}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="modelType">Model Type</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            name="modelType"
            editorId="modelType"
            component={Input}
            validator={validators.modelType}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="firmwareRevision">Firmware Revision</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="firmwareRevision"
            editorId="firmwareRevision"
            component={Input}
            validator={validators.firmwareRevision}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="user">User</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="user"
            editorId="user"
            component={Input}
            validator={validators.user}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="lastInDate">Last In Date</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="lastInDate"
            editorId="lastInDate"
            component={DatePicker}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="lastOutDate">Last Out Date</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="lastOutDate"
            editorId="lastOutDate"
            component={DatePicker}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="expectedInDate">Expected In Date</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="expectedInDate"
            editorId="expectedInDate"
            component={DatePicker}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="updated">Updated</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="updated"
            editorId="updated"
            component={DatePicker}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="pfwAssetNumber">PFW Asset Number</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="pfwAssetNumber"
            editorId="pfwAssetNumber"
            component={Input}
            validator={validators.pfwAssetNumber}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="active">Active</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="active"
            editorId="active"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

GeneralTabContentInner.displayName = 'GeneralTabContentInner';

export const GeneralTabContent: FunctionComponent<GeneralTabContentProps> = ({
  initialValues,
  onChange,
  onSubmit,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <GeneralTabContentInner valueGetter={valueGetter} onChange={onChange} />
      )}
    />
  );
};

GeneralTabContent.displayName = 'GeneralTabContent';
