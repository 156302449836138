import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { FormModel } from 'models';
import { FormGridModel } from 'models/FormGridModel';

import { ApiRouteService, DataResult } from 'core/api';

import { HttpClient } from './HttpClient';

export class FormClient {
  constructor(private httpClient: HttpClient) {
    this.find = this.find.bind(this);
    this.getAllFormsForKendoGrid = this.getAllFormsForKendoGrid.bind(this);
    this.createForm = this.createForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.getFormById = this.getFormById.bind(this);
  }

  public async find(examId: number, serviceId: number, locationId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const forms = await this.httpClient.fetchAndParse<FormModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/form/find?Exam.Id=${examId}&Service.Id=${serviceId}&Location.Id=${locationId}`, options),
    );
    return forms;
  }

  public async findServices(serviceIds: number[] = []) {
    const options = await this.httpClient.createStandardOptions('GET', null);

    const queryStr = serviceIds.map((id) => `ServiceIds=${id}`).join('&');

    const forms = await this.httpClient.fetchAndParse<FormModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/form/find?${queryStr}`, options));
    return forms;
  }

  public async getAllFormsForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<FormGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/form/grid?${queryStr}`, options),
    );
    return result;
  }

  public async createForm(model: FormModel) {
    const options = await this.httpClient.createStandardOptions('POST', model);
    const result = await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/form`, options));
    return result;
  }

  public async updateForm(model: FormModel) {
    const options = await this.httpClient.createStandardOptions('PUT', model);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/form`, options));
    return result;
  }

  public async getFormById(formId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const form = await this.httpClient.fetchAndParse<FormModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/form/${encodeURIComponent(formId)}`, options),
    );
    return form;
  }
}
