import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions, createAsyncThunk } from 'core/api';

import { PatientRouteService } from '../services';
import { patientEntity } from './normalizr-schema';

const getAllForKendoGrid = createAsyncThunk(
  'patients/get-all-for-kendo-grid',
  async ({ dataState, keyword }, { dispatch }) => {
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    const baseUrl = PatientRouteService.getForGridRoute();
    const url = !keyword
      ? `${baseUrl}?${queryStr}`
      : `${baseUrl}?${queryStr}&keyword=${keyword}`;

    const response = await dispatch(ApiActions.get(`${url}`));

    const normalized = normalize(response.result.data, [patientEntity]);

    return { ...normalized, total: response.result.total };
  },
);

const add = createAsyncThunk('patients/add', async (patient, { dispatch }) => {
  const { result } = await dispatch(
    ApiActions.post({
      url: PatientRouteService.getBaseRoute(),
      body: patient,
    }),
  );

  const normalized = normalize({ ...patient, id: result.id }, patientEntity);

  return normalized.entities;
});

const edit = createAsyncThunk(
  'patients/edit',
  async (patient, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: PatientRouteService.getBaseRoute(),
        body: patient,
      }),
    );

    const normalized = normalize({ ...patient }, patientEntity);

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'patients/get-by-id',
  async (patientId, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(`${PatientRouteService.getByIdRoute(patientId)}`),
    );

    return result;
  },
);

export const PatientActions = {
  add,
  edit,
  getById,
  getAllForKendoGrid,
};
