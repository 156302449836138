import { createContext } from 'react';

import { UserSettingsContextType } from './types';

export const UserSettingsContext = createContext<UserSettingsContextType>({
  isInitialized: false,
  userSettings: null,
  refreshSettings: () => {
    throw new Error(
      'Cannot refresh settings because there is not a <UserSettingsProvider /> component in the tree.',
    );
  },
});

UserSettingsContext.displayName = 'UserSettingsContext';
