import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions } from 'core/api';
// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from 'core/api/services';

import { ItemRouteService } from '../services';
import { itemEntity } from './normalizr-schema';

const getById = createAsyncThunk(
  'items/get-by-id',
  async (id, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(ItemRouteService.getByIdRoute(id)),
    );

    return result;
  },
);

const getAll = createAsyncThunk('items/get-all', async (_, { dispatch }) => {
  const { result } = await dispatch(
    ApiActions.get(ItemRouteService.getAllRoute()),
  );

  const normalized = normalize(result, [itemEntity]);

  return normalized.entities;
});

export const ItemActions = {
  getById,
  getAll,
};
