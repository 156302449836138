import { memo } from 'react';

import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Icon } from 'core/ui';
import { formatFileSize } from 'core/utils';

import { AttachmentItemProps } from '../types';

export const AttachmentItem = memo<AttachmentItemProps>(({ className, attachment, onRemoveAttachmentFile }) => {
  const handleDeleteClick = useEvent(() => {
    onRemoveAttachmentFile(attachment.fileId);
  });

  return (
    <StyledContainerDiv className={className}>
      <StyledOverlayDiv className="attachment-overlay">
        <StyledOverlayActionsDiv>
          <OverlayTrigger overlay={DeleteTooltip}>
            <StyledOverlayButton onClick={handleDeleteClick}>
              <Icon icon={faTrash} />
            </StyledOverlayButton>
          </OverlayTrigger>
        </StyledOverlayActionsDiv>
      </StyledOverlayDiv>
      <StyledFileName>{attachment.file.name}</StyledFileName>
      <StyledFileSize>{formatFileSize(attachment.file.size)}</StyledFileSize>
    </StyledContainerDiv>
  );
});

AttachmentItem.displayName = 'AttachmentItem';

const DeleteTooltip = <Tooltip>Remove from upload</Tooltip>;

const StyledContainerDiv = styled.div`
  background: #f5f5f5;
  border-radius: ${({ theme }) => theme.radii.scale[3]};
  display: flex;
  overflow: visible;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border: 3px solid #7a7a7a;

  &:hover .attachment-overlay {
    visibility: visible;
  }
`;

const StyledOverlayDiv = styled.div`
  position: absolute;
  top: -3px;
  bottom: 0;
  left: 0;
  right: -3px;
  background-color: transparent;
  visibility: hidden;
  z-index: 1;
  overflow: hidden;
  border-radius: 6px;
`;

const StyledOverlayActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledOverlayButton = styled.button`
  // Remove global and browser default styles.
  border: none;
  padding: 0;
  background: inherit;
  font: inherit;
  appearance: none;
  color: inherit;

  background-color: #7a7a7a;
  color: white;
  padding: ${({ theme }) => theme.space.spacing10};
`;

const StyledFileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ theme }) => theme.space.spacing10};
`;

const StyledFileSize = styled.div`
  overflow: hidden;
  padding: ${({ theme }) => theme.space.spacing10};
`;
