import { memo, useEffect, useMemo, useState } from 'react';

import { SettingModel } from 'models';

import { useEvent } from 'core/hooks';

import { apiClient } from 'features/api';
import { AuthenticationScheme, useAuthentication } from 'features/auth';

import { UserSettingsContext } from '../contexts';
import { UserSettingsContextType, UserSettingsProviderProps } from '../types';

export const UserSettingsProvider = memo<UserSettingsProviderProps>(
  ({ children }) => {
    const [userSettings, setUserSettings] = useState<SettingModel[] | null>(
      null,
    );

    const { activeScheme } = useAuthentication();

    const refreshSettings = useEvent(async () => {
      // Don't try and fetch settings until after the authentication layer has had a chance to initialize.
      if (activeScheme == null) {
        setUserSettings(null);
        return;
      }

      const newSettings = await apiClient.settingsClient.getSettings(
        activeScheme === AuthenticationScheme.OIDC,
        null,
        null,
        null,
      );

      setUserSettings(newSettings);
    });

    const context: UserSettingsContextType = useMemo(
      () =>
        userSettings == null
          ? {
              isInitialized: false,
              userSettings: null,
              refreshSettings,
            }
          : {
              isInitialized: true,
              userSettings,
              refreshSettings,
            },
      [refreshSettings, userSettings],
    );

    useEffect(() => {
      refreshSettings();
    }, [refreshSettings, activeScheme]);

    return (
      <UserSettingsContext.Provider value={context}>
        {children}
      </UserSettingsContext.Provider>
    );
  },
);

UserSettingsProvider.displayName = 'UserSettingsProvider';
