import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { filterBy } from '@progress/kendo-data-query';
import { DropDownListChangeEvent, DropDownListFilterChangeEvent, DropDownList as KendoDropDownList } from '@progress/kendo-react-dropdowns';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { DropdownProps } from './DropdownProps';
import { chevronDownIcon } from '@progress/kendo-svg-icons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Dropdown = forwardRef<any, DropdownProps>(
  (
    {
      dataItemKey = 'id',
      isForPrimitiveValues = false,
      isOptionalLabelShown = false,
      noBorder = false,
      size = ComponentSizes.MEDIUM,
      textField = 'name',
      filterable = true,
      className,
      data,
      description,
      disabled,
      filterFields,
      hint,
      label,
      name,
      id,
      required,
      valid = true,
      validationMessage,
      valueField,
      visited,
      onChange,
      ...rest
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [dropdownData, setDropdownData] = useState<any[] | undefined>([]);
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    const validDropdownData = useMemo(
      () => dropdownData?.filter((item) => item[dataItemKey] != null && item[textField] != null) ?? [],
      [dropdownData, dataItemKey, textField],
    );

    const handleOnChange = useCallback(
      (event: DropDownListChangeEvent) => {
        if (isForPrimitiveValues) {
          if (valueField == null)
            throw new Error('Unuable to emit onChange event when operating in primitive value mode because the valueField property is null or undefined.');

          onChange?.({
            ...event,
            value: valueField == null ? undefined : event.value[valueField],
          });
        } else {
          onChange?.(event);
        }
      },
      [isForPrimitiveValues, onChange, valueField],
    );

    const handleFilterChange = useCallback(
      (event: DropDownListFilterChangeEvent) => {
        // if custom filter fields are set create filters for them
        if (filterFields && filterFields.length > 0) {
          const filters = filterFields.map((fieldName) => ({
            ...event.filter,
            field: fieldName,
          }));

          setDropdownData(filterBy(data == null ? [] : [...data], { logic: 'or', filters }));
        } else {
          setDropdownData(filterBy(data == null ? [] : [...data], event.filter));
        }
      },
      [data, filterFields],
    );

    useEffect(() => {
      setDropdownData(data);
    }, [data]);

    return (
      <>
        {label && (
          <Label
            description={description}
            editorId={id || name}
            editorValid={valid}
            editorDisabled={disabled}
            required={required}
            optional={isLabeledAsOptional}
          >
            {label}
          </Label>
        )}

        <StyledDropDownListBase
          ref={ref}
          className={className}
          data={validDropdownData}
          dataItemKey={dataItemKey}
          disabled={disabled}
          svgIcon={chevronDownIcon}
          name={name}
          id={id || name}
          $noBorder={noBorder}
          onChange={onChange == null ? undefined : handleOnChange}
          onFilterChange={handleFilterChange}
          size={size}
          textField={textField}
          valid={valid}
          required={required}
          filterable={filterable}
          {...rest}
        />

        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
      </>
    );
  },
);

Dropdown.displayName = 'Dropdown';

type StyledElementProps = {
  theme: DefaultTheme;
  $noBorder: boolean;
  disabled?: boolean;
  valid?: boolean;
  size?: 'small' | 'medium' | 'large' | null;
};

const resolvePalette = ({ theme, disabled, $noBorder, valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: $noBorder ? 'transparent' : theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      borderActive: theme.colors.borderDisabled,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    text: theme.colors.palette.grayscale[4],
    background: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[4],
  };

  result.text = theme.colors.textPrimary;

  if (!valid) {
    result.border = theme.colors.error;
    result.borderActive = theme.colors.error;
  }

  if ($noBorder) {
    result.border = 'transparent';
    result.borderActive = 'transparent';
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveIconColor = (props: StyledElementProps) => {
  const { icon } = resolvePalette(props);

  return icon;
};

const resolveActiveBoxShadow = ({ $noBorder, theme, valid }: StyledElementProps) => {
  if ($noBorder) {
    return 'none';
  }

  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveBorderWidth = ({ theme }: StyledElementProps) => theme.borderWidths.base;

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveTextPadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `0rem 0rem 0rem ${theme.space.spacing20}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.spacing10} 0rem ${theme.space.spacing10} ${theme.space.spacing30}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} 0rem ${theme.space.spacing20} ${theme.space.spacing30}`;
    default:
      return `${theme.space.spacing10} 0rem ${theme.space.spacing10} ${theme.space.spacing30}`;
  }
};

const resolveIconPadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `0rem ${theme.space.spacing20} 0rem 0rem`;
    case ComponentSizes.MEDIUM:
      return `0rem ${theme.space.spacing30} 0rem 0rem`;
    case ComponentSizes.LARGE:
      return `0rem ${theme.space.spacing30} 0rem 0rem`;
    default:
      return `0rem ${theme.space.spacing30} 0rem 0rem`;
  }
};

const resolveBorderRadius = ({ theme }: StyledElementProps) => theme.radii.base;

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const StyledDropDownListBase = styled(KendoDropDownList)<{
  $noBorder: boolean;
  disabled?: boolean;
  valid?: boolean;
}>`
  background-color: ${resolveBackgroundColor} !important;
  border-color: ${resolveBorderColor} !important;
  height: ${resolveHeight};
  font-size: ${resolveFontSize};
  line-height: ${resolveLineHeight};
  width: 100%;

  &:hover {
    background-color: ${resolveBackgroundColor};
    border-color: ${resolveActiveBorderColor};
  }

  && .k-dropdown-wrap {
    background-color: ${resolveBackgroundColor};
    color: ${resolveColor};
    border-color: ${resolveBorderColor};
    border-width: ${resolveBorderWidth};
    border-radius: ${resolveBorderRadius};

    &:hover,
    &:focus,
    &:active {
      background-color: ${resolveBackgroundColor};
      border-color: ${resolveActiveBorderColor};
    }

    &:focus,
    &:active {
      box-shadow: ${resolveActiveBoxShadow};
    }
  }

  & .k-input {
    height: inherit;
    padding: ${resolveTextPadding};
  }

  && .k-select {
    color: ${resolveIconColor};
    padding: ${resolveIconPadding};
  }

  & .k-dropdown-wrap.k-disabled {
    opacity: 1;
    filter: none;
  }
`;
