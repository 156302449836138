import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions, createAsyncThunk } from 'core/api';

import { downloadBlobAsFile } from '../../../core/utils';
import { UploadTrackerRouteService } from '../services';
import { uploadEntity } from './normalizr-schema';

const getAllForKendoGrid = createAsyncThunk(
  'upload-tracker/get-all-for-kendo-grid',
  async (dataState, { dispatch }) => {
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    const baseUrl = UploadTrackerRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = await dispatch(ApiActions.get(url));

    const normalized = normalize(result.data, [uploadEntity]);

    return { ...normalized, total: result.total };
  },
);

const downloadFile = createAsyncThunk(
  'upload-tracker/download-file',
  async (studyInstanceUID, { dispatch }) => {
    const blob = await dispatch(
      ApiActions.get(
        UploadTrackerRouteService.getDownloadFileRoute(studyInstanceUID),
      ),
    );

    downloadBlobAsFile(blob, 'zip', 'exams');

    return {};
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'upload-tracker/get-all-for-export',
  async (dataState, { dispatch }) => {
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    const baseUrl = UploadTrackerRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = await dispatch(ApiActions.get(url));

    return result;
  },
);

export const UploadTrackerActions = {
  downloadFile,
  getAllForExport,
  getAllForKendoGrid,
};
