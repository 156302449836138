import _map from 'lodash/map';
import _reduce from 'lodash/reduce';

const mapDataToNameValuePair = (data) =>
  _map(data, (value, key) => ({ name: key, value }));

// formats data so that it fits API scheme
// check swagger: GET /api/Exams/{id} DynamicFormFields property
const mapApiDataToExam = (fields) => {
  return _reduce(
    fields,
    (result, field) => {
      const { name, value, entityType } = field;

      let transformedValue = value;

      if (value === 'true') {
        transformedValue = true;
      } else if (value === 'false') {
        transformedValue = false;
      }

      return {
        ...result,
        [entityType]: {
          ...result[entityType],
          [name]: transformedValue,
        },
      };
    },
    {},
  );
};

// formats data so that it fits API scheme
// check swagger: POST /api/Exams/{id}/read
const getSubmitData = (values) => {
  // we separate te exam model data and read (dynamic form fields) data
  const {
    biopsyType,
    id,
    imageQuality,
    internalNotes,
    laterality,
    organ,
    overRead,
    resultSets,
    service,
    slidePreparation,
    studyQuality,
    tags,
    ...readData
  } = values;

  const examData = {
    biopsyType,
    id,
    imageQualityId: imageQuality?.id,
    internalNotes,
    laterality,
    organ,
    overRead,
    resultSets,
    serviceId: service?.id,
    slidePreparation,
    studyQuality,
    tags,
  };

  const formattedReadData = _map(readData, (value, key) => {
    return {
      entityType: key,
      entityId: id,
      fields: mapDataToNameValuePair(value),
    };
  });

  return { examData, readData: formattedReadData };
};

export const ExamReadsDataService = {
  mapApiDataToExam,
  getSubmitData,
};
