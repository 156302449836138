export const AuthStatus = {
  AUTHENTICATING: 'auth-authenticating',
  AUTHENTICATED: 'auth-authenticated',
  ERROR: 'auth-error',
};

export const UserRoles = {
  ADMIN: 'Administrator',
  CUSTOMER: 'Customer',
  CUSTOMER_ADMIN: 'Customer Admin',
  PHYSICIAN: 'Physician',
  SUPPORT: 'Support',
  EXAM_SHARE: 'ExamShare',
};

export const ACCESS_TOKEN_SCOPES = ['cmpd-api'];

export const SHARE_TOKEN_SESSION_STORAGE_KEY = 'share_access_token';

export enum AuthenticationScheme {
  OIDC = 'oidc',
  SHARE = 'share',
  ANONYMOUS = 'anonymous',
}

export enum StandardClaimNames {
  /** Subject.  Value depends on the authentication scheme currently in use. */
  SUBJECT = 'sub',
  /** Not Before Time.  Time before which the JWT must not be accepted for processing.  Will be in seconds since 1970-01-01. */
  NOT_BEFORE_TIME = 'nbf',
  /** Expiration.  Time after which the JWT expires.  Will be in seconds since 1970-01-01 UTC. */
  EXPIRATION = 'exp',
  /** Issued At Time.  Time at which the JWT was issued; can be used to determine age of the JWT.  Will be in seconds since 1970-01-01. */
  ISSUED_AT_TIME = 'iat',
  /** Issuer.  Issuer of the JWT. */
  ISSUER = 'iss',
  /** Audience.  Recipient for which the JWT is intended. */
  AUDIENCE = 'aud',
  /** Scope.  List of allowed scopes. */
  SCOPE = 'scp',
  /** Contains the linkId for the StudyShare record. */
  SHARE_LINK_ID = 'share-link-id',
  /** Contains the patientId for the StudyShare record.  This is guaranteed to always exist for share JWTs no matter if the share is for an entire patient or individual exams. */
  SHARE_PATIENT_ID = 'share-patient-id',
  /** The type of share.  Usually this will be "LINK" or "EMAIL" but there are other types as well. */
  SHARE_TYPE = 'share-type',
  /** Array of roles assigned to the user. */
  ROLES = 'roles',
}

export enum StandardScopes {
  /** Indicates that the user has privileges to modify pricing information.  In the future this should be in "roles" but that will need to happen after we refactor how user roles are assigned (need to be able to assign to multiple roles at the same time). */
  IS_PRICING_ADMIN = 'is-pricing-admin',
  /** Indicates that the HIPAA consent for shares has been accepted. */
  SHARE_HIPAA_CONSENT_ACCEPTED = 'share-hipaa-consent-accepted',
  /** Indicates that the linkId route parameter matches the share JWT that is in use. */
  SHARE_LINK_ID_MATCH = 'share-link-id-match',
  /** Indicates that the splash/consent screen is not necessary. */
  SHARE_BYPASS_SPLASH = 'share-bypass-splash',
}
