import { SettingOverrideModel } from 'models';

import { ContactTabFormValues } from './types/ContactTabFormValues';
import { GeneralTabFormValues } from './types/GeneralTabFormValues';
import { IntegrationTabFormValues } from './types/IntegrationTabFormValues';
import { PermissionsTabFormValues } from './types/PermissionsTabFormValues';

export const UserStatus = {
  PENDING: 'user-pending',
  ADDED: 'user-created',
  UPDATED: 'user-updated',
  FETCHED: 'user-fetched',
  ERROR: 'user-error',
};

export const Roles = [
  {
    name: 'Administrator',
    value: 'Administrator',
  },
  {
    name: 'Customer',
    value: 'Customer',
  },
  {
    name: 'Customer Admin',
    value: 'Customer Admin',
  },
  {
    name: 'Support',
    value: 'Support',
  },
  {
    name: 'Physician',
    value: 'Physician',
  },
];

export const PreferMultiMonitorOptions = [
  { name: 'Automatic (Default)', value: null },
  { name: 'Single Monitor', value: false },
  { name: 'Multiple Monitors', value: true },
];

export const InitialValues = {
  nonFormFields: {
    id: 0,
    uid: '00000000-0000-0000-0000-000000000000',
    lastVersionNotified: null,
    brandingId: null,
    emailReport: false,
    emailReportPdf: false,
    locations: [],
  },
  contactForm: {
    email: '',
    phone: '',
  } as ContactTabFormValues,
  generalForm: {
    firstName: '',
    lastName: '',
    branding: null,
    groups: [],
    isApproved: false,
    active: true,
    verifyAccount: false,
    isRegistered: false,
    isPricingAdmin: false,
    isSystemAdmin: false,
    setPassword: '',
    preferMultiMonitor: null,
  } as GeneralTabFormValues,
  integrationForm: {
    supportUrl: '',
    britUser: '',
  } as IntegrationTabFormValues,
  permissionsForm: {
    role: '',
  } as PermissionsTabFormValues,
  settingOverrides: [] as SettingOverrideModel[],
};
