import { createReducer } from '@reduxjs/toolkit';

import { AuthStatus } from '../constants';
import { AuthActions } from './actions';

const getReducerInitialState = () => ({
  status: AuthStatus.AUTHENTICATING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(AuthActions.storeUserInfo, (state, action) => {
    return {
      ...state,
      user: action.payload.User,
      userRole: action.payload.UserRole,
      isPricingAdmin: action.payload.IsPricingAdmin,
      isSystemAdmin: action.payload.IsSystemAdmin,
    };
  });
});
