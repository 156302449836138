import PropTypes from 'prop-types';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { FormStates } from 'core/forms';
import { Button, LinkButton } from 'core/ui';

import PenIcon from '../assets/pen.svg?react';

const PatientFormHeader = ({ patient, patientFormState, onClick, isCollapsed }) => {
  if (!patient && patientFormState !== FormStates.ADD) {
    return null;
  }

  if (isCollapsed) {
    return (
      <StyledPatientNameDiv>
        {patient?.firstName} {patient?.lastName}
      </StyledPatientNameDiv>
    );
  }

  return (
    <>
      {patientFormState === FormStates.DISABLED ? (
        <StyledLinkButton form="patient-form" onClick={onClick} type="submit">
          <PenIcon />
        </StyledLinkButton>
      ) : (
        <Button form="patient-form" onClick={onClick} type="submit">
          {patientFormState === FormStates.EDIT && 'Save Patient'}
          {patientFormState === FormStates.ADD && 'Create New Patient'}
        </Button>
      )}
    </>
  );
};

const StyledPatientNameDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
`;

const StyledLinkButton = styled(LinkButton)`
  &,
  &:hover {
    background-color: transparent !important;
  }
`;

PatientFormHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  patientFormState: PropTypes.oneOf(Object.values(FormStates)),
};

PatientFormHeader.defaultProps = {
  isCollapsed: false,
  onClick: () => {},
  patient: null,
  patientFormState: FormStates.DISABLED,
};

export { PatientFormHeader };
