import { memo, useMemo } from 'react';

import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import cn from 'classnames';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Checkbox, InformationTable, InformationTableProps, Label, PrimaryCard, WarningMessage } from 'core/ui';

import { SelectFilesExamCardProps } from '../types';
import { SeriesThumbnail } from './SeriesThumbnail';

export const SelectFilesExamCard = memo<SelectFilesExamCardProps>(({ className, uploadGroup, onUploadGroupCheckedChange }) => {
  const handleCheckedChange = useEvent((event: CheckboxChangeEvent) => {
    onUploadGroupCheckedChange(uploadGroup.uploadGroupId, event.value);
  });

  const infoItems: InformationTableProps['items'] = useMemo(
    () => [
      { key: 'study-instance-uid', label: 'Study Instance UID', value: uploadGroup.dicomData?.StudyInstanceUID },
      { key: 'study-description', label: 'Study Description', value: uploadGroup.dicomData?.StudyDescription },
      { key: 'patient-name', label: 'Patient Name', value: uploadGroup.dicomData?.PatientName?.join?.(' ') },
      { key: 'patient-birth-date', label: 'Patient Birth Date', value: uploadGroup.dicomData?.PatientBirthDate },
      'collapse-button',
      { key: 'accession-number', label: 'Accession Number', value: uploadGroup.dicomData?.AccessionNumber },
      { key: 'body-part-examined', label: 'Body Part Examined', value: uploadGroup.dicomData?.BodyPartExamined },
      { key: 'modality', label: 'Modality', value: uploadGroup.dicomData?.Modality },
      { key: 'study-date', label: 'Study Date', value: uploadGroup.dicomData?.StudyDate },
      { key: 'study-time', label: 'Study Time', value: uploadGroup.dicomData?.StudyTime },
      { key: 'patient-id', label: 'Patient ID', value: uploadGroup.dicomData?.PatientID },
      { key: 'patient-sex', label: 'Patient Sex', value: uploadGroup.dicomData?.PatientSex },
    ],
    [uploadGroup.dicomData],
  );

  return (
    <StyledComponentDiv className={cn(className, { 'is-checked': uploadGroup.checked, disabled: !uploadGroup.checked })}>
      {uploadGroup.dicomData != null && (
        <StyledItemTypeDiv>
          <StyledItemTypeTextSpan>DICOM</StyledItemTypeTextSpan>
        </StyledItemTypeDiv>
      )}
      <StyledPrimaryCard>
        <StyledInformationDiv>
          <StyledCheckContainerDiv>
            <Label>
              <Checkbox value={uploadGroup.checked} onChange={handleCheckedChange} />
              Upload
            </Label>
          </StyledCheckContainerDiv>

          <InformationTable items={infoItems} disabled={!uploadGroup.checked} />
        </StyledInformationDiv>

        <StyledSeriesThumbnailList>
          <div>
            {uploadGroup.uncompressedDicomWarning && <WarningMessage>Uncompressed studies detected. Upload may take a long time to complete.</WarningMessage>}
          </div>
          <StyledSeriesThumbnailListInner>
            {uploadGroup.series.map((series, index) => (
              <SeriesThumbnail key={series.seriesId} uploadGroup={uploadGroup} seriesIndex={index} />
            ))}
          </StyledSeriesThumbnailListInner>
        </StyledSeriesThumbnailList>
      </StyledPrimaryCard>
    </StyledComponentDiv>
  );
});

SelectFilesExamCard.displayName = 'SelectFilesExamCard';

const StyledComponentDiv = styled.div`
  position: relative;
`;

const StyledItemTypeDiv = styled.div`
  position: absolute;
  display: grid;
  width: calc(300px + ${({ theme }) => theme.space.spacing40});
  grid-template-columns: min-content;
  justify-content: end;
`;

const StyledItemTypeTextSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  color: ${({ theme }) => theme.colors.palette.grayscale[4]};
  user-select: none;
  padding: 0 6px;
  background-color: ${({ theme }) => theme.colors.palette.blues[4]};
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const StyledPrimaryCard = styled(PrimaryCard)`
  display: grid;
  overflow: hidden;
  grid-template-columns: 300px 1fr;

  .disabled & {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[3]};
    border-color: ${({ theme }) => theme.colors.borderDisabled};
  }
`;

const StyledInformationDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
`;

const StyledCheckContainerDiv = styled.div`
  grid-column: 1 / -1;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr min-content;
  align-items: start;
  padding: 0 3px ${({ theme }) => theme.space.spacing40} 0;

  && > label {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.heading2};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: ${({ theme }) => theme.lineHeights.heading2};
  }

  .k-checkbox-wrap {
    margin-right: 8px;
  }
`;

const StyledSeriesThumbnailList = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  align-items: start;
  padding: 0 0 0 ${({ theme }) => theme.space.spacing40};
`;

const StyledSeriesThumbnailListInner = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(auto-fill, 120px);
  gap: 6px;
  filter: blur(0.5px) grayscale(1) opacity(60%);

  .is-checked & {
    filter: unset;
    opacity: unset;
  }
`;
