// eslint-disable-next-line import/no-unresolved
import { ApiActions, createAsyncThunk } from 'core/api';

import { ExamReadingRouteService } from '../services';

const addRead = createAsyncThunk(
  'exams-reading/add-read',
  async (examReadData, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.post({
        url: ExamReadingRouteService.getReadRoute(examReadData.id),
        body: examReadData,
      }),
    );

    return result;
  },
);

const saveRead = createAsyncThunk(
  'exams-reading/save-read',
  async (examReadData, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.post({
        url: ExamReadingRouteService.getSaveReadRoute(examReadData.id),
        body: examReadData,
      }),
    );

    return result;
  },
);

const approveRead = createAsyncThunk(
  'exams-reading/approve-read',
  async (examId, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.post({
        url: ExamReadingRouteService.getApproveReadRoute(examId),
      }),
    );

    return result;
  },
);

export const ExamReadingActions = {
  addRead,
  saveRead,
  approveRead,
};
