import { ContactModel, DestinationModel, ExamStatusTypeModel, IntegrationModel, LocationModel, PhysicianModel } from 'models';
import { DestinationRouteModel } from 'models/DestinationRouteModel';

import { findOrThrow } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  active: false,
  isEventTrigger: false,
  destination: null,
  integration: null,
  statusType: null,
  previousStatusType: null,
  physician: null,
  location: null,
  contact: null,
  filter: '',
};

function copyModelToForm(
  model: DestinationRouteModel,
  allDestination: DestinationModel[],
  allPhysician: PhysicianModel[],
  allLocations: LocationModel[],
  allContacts: ContactModel[],
  allIntegrations: IntegrationModel[],
  allStatusType: ExamStatusTypeModel[],
): EditFormValues {
  // find destination
  const destination =
    model.destinationId != null
      ? findOrThrow(allDestination, (d) => d.id === model.destinationId, `Could not find destination with id: ${model.destinationId}.`)
      : null;

  // find contact
  const contact =
    model.contactId != null ? findOrThrow(allContacts, (con) => con.id === model.contactId, `Could not find contact with id: ${model.contactId}.`) : null;

  // find physician
  const physician =
    model.physicianId != null
      ? findOrThrow(allPhysician, (phy) => phy.id === model.physicianId, `Could not find physician with id: ${model.physicianId}.`)
      : null;

  // find location
  const location =
    model.locationId != null ? findOrThrow(allLocations, (t) => t.id === model.locationId, `Could not find location with id: ${model.locationId}.`) : null;

  // find integration
  const integration =
    model.integrationId != null
      ? findOrThrow(allIntegrations, (i) => i.id === model.integrationId, `Could not find integration with id: ${model.integrationId}.`)
      : null;

  const statusType =
    model.statusTypeId != null
      ? findOrThrow(allStatusType, (stat) => stat.id === model.statusTypeId, `Could not find status with id: ${model.statusTypeId}.`)
      : null;

  const previousStatusType =
    model.previousStatusTypeId != null
      ? findOrThrow(allStatusType, (stat) => stat.id === model.previousStatusTypeId, `Could not find status with id: ${model.previousStatusTypeId}.`)
      : null;

  return {
    ...model,
    destination,
    integration,
    statusType,
    previousStatusType,
    physician,
    location,
    contact,
  };
}

function copyFormToModel(destinationRouteId: number, form: EditFormValues): DestinationRouteModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...rest } = form;
  return {
    ...rest,
    id: destinationRouteId,
    integrationId: form.integration == null ? null : form.integration.id,
    locationId: form.location == null ? null : form.location.id,
    physicianId: form.physician == null ? null : form.physician.id,
    destinationId: form.destination == null ? null : form.destination.id,
    statusTypeId: form.statusType == null ? null : form.statusType.id,
    previousStatusTypeId: form.previousStatusType == null ? null : form.previousStatusType.id,
    contactId: form.contact == null ? null : form.contact.id,
  };
}

function createDefaultSource(): DestinationRouteModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const DestinationRouteEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
