import { useEffect, useRef } from 'react';

import { FieldValues, UseFormReturn, useFormState } from 'react-hook-form';

import { useEvent } from 'core/hooks';

import { StandardFormState } from '../types';

export function useFormStateEmitter<TFieldValues extends FieldValues = FieldValues>(
  callbackFn: (formState: StandardFormState) => void,
  rhfContext: UseFormReturn<TFieldValues>,
) {
  const rhfFormState = useFormState({ control: rhfContext.control });

  const lastExecutedState = useRef<StandardFormState | null>(null);

  const stableCallbackFn = useEvent((formState: StandardFormState) => {
    callbackFn(formState);
  });

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      const lastState = lastExecutedState.current ?? { isValid: null, isValidating: null, isDirty: null };
      const newState = { isValid: rhfFormState.isValid, isValidating: rhfFormState.isValidating, isDirty: rhfFormState.isDirty };

      if (lastState.isValid !== newState.isValid || lastState.isValidating !== newState.isValidating || lastState.isDirty !== newState.isDirty) {
        lastExecutedState.current = newState;
        stableCallbackFn(newState);
      }
    });

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [stableCallbackFn, rhfFormState]);

  // This is required because RHF internally uses a proxy for the form state properties.  So we must access the properties to trigger the useEffect.  See: https://react-hook-form.com/docs/useform/formstate
  return {
    isValid: rhfFormState.isValid,
    isValidating: rhfFormState.isValidating,
    isDirty: rhfFormState.isDirty,
  };
}
