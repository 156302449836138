import { FunctionComponent } from 'react';

import { TextFilterProps } from '@progress/kendo-react-data-tools';

import { useLocationTypes } from '../../location-type';
import { DropdownFilter } from './DropdownFilter';

export const LocationTypeDropdownFilter: FunctionComponent<TextFilterProps> = ({
  filter,
  onFilterChange,
}) => {
  const [locationTypes] = useLocationTypes();

  return (
    <DropdownFilter
      data={locationTypes}
      filter={filter}
      onFilterChange={onFilterChange}
    />
  );
};
