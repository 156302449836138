import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { LocationModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { LocationRouteService } from '../services';
import {
  NormalizeLocationModelSchema,
  locationEntity,
} from './normalizr-schema';

const add = createAsyncThunk(
  'locations/add',
  async (location: LocationModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: LocationRouteService.getBaseRoute(),
        body: location,
      }),
    )) as unknown as ApiResponse<LocationModel>;

    const normalized = normalize<LocationModel, NormalizeLocationModelSchema>(
      { ...location, id: result.id },
      locationEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'locations/edit',
  async (location: LocationModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: LocationRouteService.getBaseRoute(),
        body: location,
      }),
    );

    const normalized = normalize<LocationModel, NormalizeLocationModelSchema>(
      location,
      locationEntity,
    );

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'locations/get-by-id',
  async (locationId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(LocationRouteService.getByIdRoute(locationId)),
    )) as unknown as ApiResponse<LocationModel>;

    return result;
  },
);

const getAll = createAsyncThunk(
  'locations/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(LocationRouteService.getAllRoute()),
    )) as unknown as ApiResponse<LocationModel>;

    const normalized = normalize<LocationModel, NormalizeLocationModelSchema>(
      result,
      [locationEntity],
    );

    return normalized.entities;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'locations-grid/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = LocationRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [locationEntity]);

    return { ...normalized, total: result.total };
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'locations/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = LocationRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<LocationModel>;

    return result;
  },
);

export const LocationActions = {
  add,
  edit,
  getAll,
  getAllForExport,
  getById,
  getAllForKendoGrid,
};
