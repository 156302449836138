import { RootState } from 'features/main/redux';

import { integrationAdapter } from './reducer';

const rootSelector = (state: RootState) => state.integrations;

const { selectAll: getAll } = integrationAdapter.getSelectors(rootSelector);

export const IntegrationSelectors = {
  getAll,
};
