// eslint-disable-next-line import/no-unresolved
import { ApiActions } from 'core/api';
// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from 'core/api/services';

import { DynamicFormRouteService } from '../services';

const getById = createAsyncThunk(
  'dynamic-forms/get-by-id',
  async (dynFormId, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(
        DynamicFormRouteService.getByIdRoute(
          dynFormId.entityType,
          dynFormId.entityId,
        ),
      ),
    );

    return result;
  },
);

const edit = createAsyncThunk(
  'dynamic-forms/edit',
  async (dynamicForm, { dispatch }) => {
    await dispatch(
      ApiActions.post({
        url: DynamicFormRouteService.editRoute(
          dynamicForm.entityType,
          dynamicForm.entityId,
        ),
        body: dynamicForm,
      }),
    );

    return dynamicForm;
  },
);

export const DynamicFormActions = {
  getById,
  edit,
};
