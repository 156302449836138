import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { GroupModel } from 'models';

import { GroupStatus } from '../constants';
import { GroupActions } from './actions';

export const groupAdapter = createEntityAdapter<GroupModel>();

const getReducerInitialState = () => ({
  ...groupAdapter.getInitialState(),
  total: 0,
  status: GroupStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(GroupActions.getAll.fulfilled, (state, action) => {
    const payload = action.payload as { groups: GroupModel[] };
    groupAdapter.upsertMany(state, payload.groups);
  });
  builder.addCase(GroupActions.getById.fulfilled, (draft, action) => {
    const payload = action.payload as GroupModel;
    groupAdapter.upsertOne(draft, payload);
    draft.status = GroupStatus.FETCHED;
  });
  builder.addCase(GroupActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { groups: GroupModel };
    groupAdapter.addOne(draft, payload.groups);
    draft.status = GroupStatus.ADDED;
  });
  builder.addCase(GroupActions.edit.fulfilled, (draft, action) => {
    const payload = action.payload as {
      groups: Record<number, GroupModel>;
    };

    Object.values(payload.groups).forEach((group) => {
      groupAdapter.setOne(draft, group);
    });

    draft.status = GroupStatus.UPDATED;
  });
});

export const reducerGrid = createReducer(
  getReducerInitialState(),
  (builder) => {
    builder.addCase(
      GroupActions.getAllForKendoGrid.fulfilled,
      (draft, action) => {
        const payload = action.payload as {
          entities: { groups: GroupModel[] };
          total: number;
          result: EntityId[];
        };

        groupAdapter.removeAll(draft);

        if (payload.entities.groups) {
          groupAdapter.addMany(draft, payload.entities.groups);
        }

        draft.total = payload.total;
        draft.ids = payload.result;
        draft.status = GroupStatus.FETCHED;
      },
    );
  },
);
