import { FunctionComponent, useCallback, useEffect } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  createStyledFormElement,
} from 'core/forms';
import {
  Button,
  DatePicker,
  Dropdown,
  DropdownWithValuesField,
  Input,
  Label,
  NumericInput,
  Switch,
  TextArea,
  Upload,
} from 'core/ui';

import { AccountSelectors } from 'features/account';
import { LocationTypeSelectors } from 'features/location-type';
import { useAppDispatch, useAppSelector } from 'features/main/hooks';

import { PhysicianActions, PhysicianSelectors } from '../../physician';
import { locationFormValidators as validators } from '../services';
import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const GeneralTabContentInner: FunctionComponent<{
  initialValues: GeneralTabFormValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof GeneralTabFormValues) => any;
  onChange: (values: GeneralTabFormValues) => void;
}> = ({ initialValues, valueGetter, onChange }) => {
  const physicians = useAppSelector(PhysicianSelectors.getAll);
  const locationTypes = useAppSelector(LocationTypeSelectors.getAll);
  const accountOptions = useAppSelector(
    AccountSelectors.getAllActiveAccounts(initialValues.accountId, null),
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(PhysicianActions.getAll(undefined));
  }, [dispatch]);

  const handleFormChange = useCallback(() => {
    onChange({
      locationType: valueGetter('locationType'),
      logo: valueGetter('logo'),
      name: valueGetter('name'),
      code: valueGetter('code'),
      user: valueGetter('user'),
      contractType: valueGetter('contractType'),
      contractStart: valueGetter('contractStart'),
      contractStop: valueGetter('contractStop'),
      serial: valueGetter('serial'),
      comments: valueGetter('comments'),
      overreadInstruction: valueGetter('overreadInstruction'),
      timeZone: valueGetter('timeZone'),
      accountId: valueGetter('accountId'),
      defaultPhysician_Id: valueGetter('defaultPhysician_Id'),
      active: valueGetter('active'),
      isTestLocation: valueGetter('isTestLocation'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="logo">Logo</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="logo"
            editorId="logo"
            component={Upload}
            multiple={false}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="">Location Type</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Dropdown}
            editorId="locationType"
            data={locationTypes}
            name="locationType"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="name" required>
          Name
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            name="name"
            editorId="name"
            required
            validator={validators.name}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="code" required>
          Code
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={NumericInput}
            editorId="code"
            name="code"
            required
            validator={validators.required}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="user">User</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="user"
            editorId="user"
            component={Input}
            validator={validators.user}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="contractType">Contract Type</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="contractType"
            name="contractType"
            validator={validators.contractType}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="contractStart">Contract Start</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DatePicker}
            editorId="contractStart"
            name="contractStart"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="contractStop">Contract Stop</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DatePicker}
            editorId="contractStop"
            name="contractStop"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="serial">Serial</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="serial"
            editorId="serial"
            component={Input}
            validator={validators.serial}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="comments">Comments</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={TextArea}
            editorId="comments"
            maxLength={100}
            name="comments"
            validator={validators.comments}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="overreadInstruction">Overread Instruction</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="overreadInstruction"
            name="overreadInstruction"
            validator={validators.overreadInstruction}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="timeZone">Time Zone</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="timeZone"
            editorId="timeZone"
            component={Input}
            validator={validators.timeZone}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="accountId">Account</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DropdownWithValuesField}
            data={accountOptions}
            isForPrimitiveValues
            editorId="accountId"
            name="accountId"
            valueField="id"
            defaultItem={{ id: null, name: '' }}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label
          editorId="active"
          description="Default Physician for Regular Exams"
        >
          Default Physician
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DropdownWithValuesField}
            data={physicians}
            defaultItem={{
              fullName: 'Unassigned',
              value: null,
            }}
            label="Physician"
            name="defaultPhysician_Id"
            textField="fullName"
            isForPrimitiveValues
            dataItemKey="id"
            valueField="id"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="active">Active</Label>
      </GridColumn>
      <GridColumn>
        <FieldContainer $hideLabel>
          <Field
            name="active"
            editorId="active"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="isTestLocation">Is Test Location</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="isTestLocation"
            editorId="isTestLocation"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn>
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

GeneralTabContentInner.displayName = 'GeneralTabContentInner';

export const GeneralTabContent: FunctionComponent<GeneralTabContentProps> = ({
  initialValues,
  onSubmit,
  onChange,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <GeneralTabContentInner
          initialValues={initialValues}
          valueGetter={valueGetter}
          onChange={onChange}
        />
      )}
    />
  );
};

GeneralTabContent.displayName = 'GeneralTabContent';
