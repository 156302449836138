import { memo, useMemo } from 'react';

import { faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ExamModel } from 'models';

import { FieldContainer, useFieldValueChangeHandler, useFormStateEmitter } from 'core/forms';
import { RHF_DEFAULTS_CHANGE, RHF_FULL_RESET } from 'core/forms/constants';
import { useBoolean, useEvent } from 'core/hooks';
import { Button, ErrorMessage, Icon, InputField, MASKED_DATETIME_INPUT_MODES, MaskedDateTimeInputField, PrimaryCard } from 'core/ui';
import { DropdownNgField } from 'core/ui/DropdownNg';
import { hasText, isPromise } from 'core/utils';

import { ExamUploadService } from '../services';
import { ExamFormProps, ExamFormValues } from '../types';
import { ExamSelectModal } from './ExamSelectModal';

export const ExamForm = memo<ExamFormProps>(
  ({ className, allServices, exam, patient, initialValues, showDirtyError, isFixedExam, onChange, onFormStateChange, onSave, onExamModelSelected }) => {
    const [isSelectExamModalOpen, { setTrue: openSelectExamModal, setFalse: closeSelectExamModal }] = useBoolean(false);

    const allServicesOptions = useMemo(() => allServices.filter((s) => hasText(s.description)), [allServices]);

    const rhfContext = useForm<ExamFormValues>({
      defaultValues: {
        ...initialValues,
      },
    });

    const handleFieldChange = useFieldValueChangeHandler(onChange, rhfContext);

    useFormStateEmitter(onFormStateChange, rhfContext);

    const handleCreateNewExamClick = useEvent(() => {
      onExamModelSelected(null);
    });

    const handleExamFormSelected = useEvent((newExam: ExamModel | null) => {
      if (newExam == null) {
        throw new Error('TODO: Implement this.');
      }

      const newFormValues = ExamUploadService.examModelToForm(newExam, allServices);

      rhfContext.reset(newFormValues, RHF_FULL_RESET);

      onChange(newFormValues);
      onExamModelSelected(newExam);
    });

    const handleSubmit: SubmitHandler<ExamFormValues> = useEvent(async (form) => {
      // Don't save if the patient is null.
      if (patient == null) return;

      const saveResult = onSave?.();

      if (isPromise(saveResult)) {
        await saveResult;
      }

      rhfContext.reset(form, RHF_DEFAULTS_CHANGE);
    });

    return (
      <>
        <ExamSelectModal
          show={isSelectExamModalOpen}
          selectedExamId={exam?.id ?? null}
          onClose={closeSelectExamModal}
          onExamFormSelected={handleExamFormSelected}
        />
        <FormProvider {...rhfContext}>
          <PrimaryCard>
            <PrimaryCard.Header>
              <Icon icon={faFileLines} block fixedWidth={false} />
              <PrimaryCard.HeaderText>Exam</PrimaryCard.HeaderText>
            </PrimaryCard.Header>

            <StyledForm
              className={className}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="none"
              spellCheck="false"
              noValidate
              onSubmit={rhfContext.handleSubmit(handleSubmit)}
            >
              {!isFixedExam && (
                <StyledExamSelectionDiv>
                  <Button id={`${ExamForm.displayName}_search-exams-button`} onClick={openSelectExamModal}>
                    Select Existing Exam
                  </Button>
                  <Button id={`${ExamForm.displayName}_create-new-exam-button`} onClick={handleCreateNewExamClick}>
                    Create New Exam
                  </Button>
                </StyledExamSelectionDiv>
              )}

              <FieldContainer>
                <InputField name="description" label="Description" onChange={handleFieldChange} />
              </FieldContainer>

              <FieldContainer>
                <DropdownNgField
                  name="service"
                  label="Study Type"
                  dataItemKey="id"
                  textField="description"
                  data={allServicesOptions}
                  onChange={handleFieldChange}
                />
              </FieldContainer>

              <FieldContainer>
                <InputField name="customerUID" label="Customer UID" onChange={handleFieldChange} />
              </FieldContainer>

              <FieldContainer>
                <MaskedDateTimeInputField name="studyDate" label="Study Date" required mode={MASKED_DATETIME_INPUT_MODES.DATE} onChange={handleFieldChange} />
              </FieldContainer>

              <FieldContainer>
                <MaskedDateTimeInputField name="studyTime" label="Study Time" required mode={MASKED_DATETIME_INPUT_MODES.TIME} onChange={handleFieldChange} />
              </FieldContainer>

              <FieldContainer>
                <InputField name="notes" label="Study Notes" onChange={handleFieldChange} />
              </FieldContainer>

              <StyledButtonsDiv>
                <Button type="submit" disabled={!(rhfContext.formState.isDirty || exam == null)}>
                  Save Exam
                </Button>
              </StyledButtonsDiv>

              <div>
                {rhfContext.formState.submitCount > 0 && patient == null && <ErrorMessage>Patient must be saved first.</ErrorMessage>}
                {showDirtyError && exam == null && <ErrorMessage>Exam has not been created.</ErrorMessage>}
                {showDirtyError && exam != null && rhfContext.formState.isDirty && <ErrorMessage>Unsaved changes.</ErrorMessage>}
              </div>
            </StyledForm>
          </PrimaryCard>
        </FormProvider>
      </>
    );
  },
);

ExamForm.displayName = 'ExamForm';

const StyledForm = styled.form`
  display: grid;
  column-gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledExamSelectionDiv = styled.div`
  padding-bottom: 8px;

  button + button {
    margin-left: 8px;
  }
`;

const StyledButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
