import { useCallback, useState } from 'react';

import { AccordionNgContextType } from './AccordionNgContextType';

export function useAccordionNg(defaultExpandedItemKeys: string[]): AccordionNgContextType {
  const [expandedItemKeys, setExpandedItemKeys] = useState(defaultExpandedItemKeys);

  const setExpanded = useCallback((itemKey: string) => {
    setExpandedItemKeys((prev) => {
      const itemKeyIndex = prev.indexOf(itemKey);

      if (itemKeyIndex >= 0) {
        return prev;
      }

      const newExpandedItemKeys = [...prev];
      newExpandedItemKeys.splice(itemKeyIndex, 1);
      return newExpandedItemKeys;
    });
  }, []);

  const setCollapsed = useCallback((itemKey: string) => {
    setExpandedItemKeys((prev) => {
      const itemKeyIndex = prev.indexOf(itemKey);

      if (itemKeyIndex < 0) {
        return prev;
      }

      const newExpandedItemKeys = [...prev, itemKey];
      return newExpandedItemKeys;
    });
  }, []);

  const toggleExpanded = useCallback((itemKey: string) => {
    setExpandedItemKeys((prev) => {
      const newExpandedItemKeys = [...prev];
      const itemKeyIndex = newExpandedItemKeys.indexOf(itemKey);

      if (itemKeyIndex >= 0) {
        newExpandedItemKeys.splice(itemKeyIndex, 1);
      } else {
        newExpandedItemKeys.push(itemKey);
      }

      return newExpandedItemKeys;
    });
  }, []);

  return {
    expandedItemKeys,
    setExpandedItemKeys,
    setExpanded,
    setCollapsed,
    toggleExpanded,
  };
}
