import { IntervalPair } from './IntervalPair';
import { IntervalPoint } from './IntervalPoint';

export type MergeDataCalculator<TData extends object | undefined = undefined> = (intervals: IntervalPair<TData>[]) => TData;

export type IntervalMergeCalculatorOptions = {
  initialIntervals: IntervalPoint[];
  /** The timeOrigin for the interval points.  Will default to window.performance.timeOrigin.  Required when the interval points are generated from within a Web Worker context. */
  timeOrigin: number;
};

export class IntervalMergeCalculator<TData extends object | undefined = undefined> {
  static [Symbol.toStringTag]() {
    return 'IntervalMergeCalculator';
  }

  private _intervals: IntervalPair<TData>[] = [];

  private _merged: IntervalPair<TData>[] | null = null;

  private _timeOrigin: number;

  constructor(options?: Partial<IntervalMergeCalculatorOptions>) {
    this.addInterval = this.addInterval.bind(this);
    this.calculate = this.calculate.bind(this);
    this.adjust = this.adjust.bind(this);
    this.mergeIntervals = this.mergeIntervals.bind(this);
    this.getIntervals = this.getIntervals.bind(this);

    this._timeOrigin = options?.timeOrigin ?? performance.timeOrigin;
  }

  public addInterval(start: number, end: number | null, data: TData) {
    if (typeof start === 'number' && typeof end === 'number' && start > end) {
      throw new Error('Start date cannot be after the end date.');
    }

    this._intervals.push({ start, end: end ?? null, data });

    this._merged = null;
  }

  public foo(...args: TData extends object ? [MergeDataCalculator<TData>] : []) {
    return args;
  }

  /** Merge intervals and calculate metrics. */
  public calculate(defaultEnd: number | 'timeOrigin' | 'now' | null = 'timeOrigin', mergeDataCalculator?: MergeDataCalculator<TData>) {
    const defaultEndValue = defaultEnd === 'timeOrigin' ? this._timeOrigin : defaultEnd === 'now' ? performance.now() : defaultEnd ?? performance.now();

    if (!this._merged) this._merged = this.mergeIntervals(mergeDataCalculator);

    let currentDuration = 0;

    for (const interval of this._merged) {
      if (interval.start == null) {
        // It's not out of the question to have an interval with no start date, but it's not something that should be happening in the original use-cases.
        // If in the future it becomes a requirement then we will have to determine how we want to handle this.
        throw new Error('TODO: Cannot calculate duration for an interval that has no start date.');
      }

      if (interval.end == null) {
        currentDuration += defaultEndValue - interval.start;
        break;
      }

      currentDuration += (interval.end ?? defaultEndValue) - interval.start;
    }

    return {
      intervals: this._merged,
      duration: currentDuration,
    };
  }

  /** Permanently modify or drop intervals.
   * @param adjustorCallback Executed once for every interval in the dataset.  The returned value will then replace the original value.  If the return value is `null` or `undefined` then the interval is removed. */
  public adjust(adjustorCallback: (interval: IntervalPair<TData>) => IntervalPair<TData> | null | undefined) {
    const removeIndexes: number[] = [];

    for (let i = 0; i < this._intervals.length; i++) {
      const interval = this._intervals[i];
      const newInterval = adjustorCallback(interval);

      if (newInterval == null) {
        removeIndexes.push(i);
      } else {
        this._intervals[i] = newInterval;
      }
    }

    removeIndexes.reverse();

    for (let i = removeIndexes.length - 1; i >= 0; i--) {
      this._intervals.splice(removeIndexes[i], 1);
    }

    this._merged = null;
  }

  public getIntervals() {
    return [...this._intervals];
  }

  private mergeIntervals(mergeDataCalculator: MergeDataCalculator<TData> | undefined): IntervalPair<TData>[] {
    if (this._merged) return this._merged;

    /* This is an algorithm to merge a list of datetime intervals that may contain any arbitrary amount of overlap.  It's widely used, but doesn't
     * have a fancy name like "quicksort" or "bubblesort".  Some additional information:
     *   https://stackoverflow.com/a/748538
     *   https://www.geeksforgeeks.org/merging-intervals/
     */

    const allPoints: IntervalPoint<TData>[] = [];

    for (const interval of this._intervals) {
      if (interval.start != null) {
        allPoints.push({
          date: interval.start,
          depthDirection: 1,
          interval,
        });
      }

      if (interval.end != null) {
        allPoints.push({
          date: interval.end,
          depthDirection: -1,
          interval,
        });
      }
    }

    const results: IntervalPair<TData>[] = [];
    const intervalsToMerge: IntervalPair<TData>[] = [];
    let currentInterval: IntervalPair<TData> | null = null;
    let depth = 0;

    allPoints.sort((lhs, rhs) => {
      if (lhs.date < rhs.date) {
        return -1;
      } else if (lhs.date > rhs.date) {
        return 1;
      } else if (lhs.depthDirection === rhs.depthDirection) {
        return 0;
      } else if (lhs.depthDirection === 1) {
        return -1; // We need start dates to be sorted FIRST in the array, so for the sort algorithm comparison we therefore have to return -1.
      } else {
        return 1;
      }
    });

    // Merge all overlapping intervals so that there are guaranteed to not be any overlaps.
    for (const point of allPoints) {
      depth += point.depthDirection;

      if (depth < 0) {
        // This shouldn't ever happen unless there is somehow an end date in the list that doesn't have a corresponding start date.
        throw new Error('Unable to merge intervals because a null end date was encountered.');
      }

      if (depth === 0 && currentInterval == null) {
        // This is the start of a new merged interval.
        currentInterval = {
          start: point.date,
          end: null,
          data: undefined as TData, // This will be populated when the end of the merged interval is encountered.
        };

        intervalsToMerge.push(point.interval);
        results.push(currentInterval);
      } else if (depth === 0 && currentInterval != null) {
        // This is the end of a merged interval.
        currentInterval.end = point.date;
        currentInterval.data = mergeDataCalculator?.(intervalsToMerge) as TData;

        // Reset for the next loop iteration.
        currentInterval = null;
      }
    }

    return results;
  }
}
