import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { UserModel } from 'models';

import { useEvent } from 'core/hooks';
import { hasText } from 'core/utils';

import { useApiClient } from 'features/api';

import { AuthenticationScheme } from '../constants';
import { CurrentUserContext } from '../contexts';
import { useAuthentication } from '../hooks';
import { CurrentUserContextType } from '../types/CurrentUserContextType';

export const CurrentUserProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<UserModel | 'anonymous' | undefined>(undefined);

  const apiClient = useApiClient();
  const { activeScheme } = useAuthentication();

  const fetchProfile = useEvent(async () => {
    let newUser: UserModel | 'anonymous' | undefined;

    if (activeScheme == null) throw new Error('Cannot fetch the currently logged in user profile because authentication is still initializing.');

    if (activeScheme === AuthenticationScheme.ANONYMOUS || activeScheme === AuthenticationScheme.SHARE) {
      newUser = 'anonymous';
    } else if (activeScheme === AuthenticationScheme.OIDC) {
      newUser = (await apiClient.users.getCurrentUser()) ?? undefined;

      if (newUser == null) throw new Error('Unable to fetch current user profile.  The request returned null or undefined.');

      const userDisplayName = [newUser.firstName, newUser.lastName].filter((s) => hasText(s)).join(' ');

      datadogRum.setUserProperty('userId', newUser.id);
      datadogLogs.setUserProperty('userId', newUser.id);
      datadogRum.setUserProperty('name', userDisplayName);
      datadogLogs.setUserProperty('name', userDisplayName);
    } else {
      throw new Error(`Cannot retrieve current user profile because the authentication scheme "${activeScheme as string}" is unknown.`);
    }

    setCurrentUser(newUser);

    return newUser;
  });

  // Initialization.
  useEffect(() => {
    if (activeScheme != null) fetchProfile();
  }, [activeScheme, fetchProfile]);

  const newContext: CurrentUserContextType = useMemo(() => {
    return {
      user: currentUser,
      setUser: setCurrentUser,
      fetchProfile,
    };
  }, [currentUser, fetchProfile]);

  return <CurrentUserContext.Provider value={newContext}>{children}</CurrentUserContext.Provider>;
};

CurrentUserProvider.displayName = 'CurrentUserProvider';
