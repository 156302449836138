import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { LocationModel } from 'models';

import { LocationStatus } from '../constants';
import { LocationActions } from './actions';

export const locationAdapter = createEntityAdapter<LocationModel>({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...locationAdapter.getInitialState(),
  total: 0,
  status: LocationStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(LocationActions.getById.fulfilled, (draft, action) => {
    const payload = action.payload as LocationModel;
    locationAdapter.upsertOne(draft, payload);
    draft.status = LocationStatus.FETCHED;
  });
  builder.addCase(LocationActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { locations: LocationModel };
    locationAdapter.addOne(draft, payload.locations);
    draft.status = LocationStatus.ADDED;
  });
  builder.addCase(LocationActions.edit.fulfilled, (draft, action) => {
    const payload = action.payload as {
      locations: Record<number, LocationModel>;
    };
    Object.values(payload.locations).forEach((location) => {
      locationAdapter.setOne(draft, location);
    });

    draft.status = LocationStatus.UPDATED;
  });
  builder.addCase(LocationActions.getAll.fulfilled, (draft, action) => {
    const payload = action.payload as { locations: LocationModel[] };
    locationAdapter.setMany(draft, payload.locations);
    draft.status = LocationStatus.FETCHED;
  });
});

export const reducerGrid = createReducer(
  getReducerInitialState(),
  (builder) => {
    builder.addCase(
      LocationActions.getAllForKendoGrid.fulfilled,
      (draft, action) => {
        const payload = action.payload as {
          entities: { locations: LocationModel[] };
          total: number;
          result: EntityId[];
        };

        locationAdapter.removeAll(draft);

        if (payload.entities.locations) {
          locationAdapter.addMany(draft, payload.entities.locations);
        }

        draft.total = payload.total;
        draft.ids = payload.result;
        draft.status = LocationStatus.FETCHED;
      },
    );
  },
);
