import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Button } from '../Button';
import { Status } from '../Status';
import { Trend } from '../Trend';
import { NumericalLayoutProps } from './NumericalLayoutProps';
import LikeIconSvg from './assets/like-icon.svg?react';

export const NumericalLayout: FunctionComponent<NumericalLayoutProps> = ({
  buttonLabel,
  onButtonClick,
  isLikeIconShown = false,
  statusType,
  title,
  trendLabel,
  trendChange,
  trendPercentage,
  value,
}) => {
  const shouldRenderTrend = !!trendLabel && !!trendChange && !!trendPercentage;

  return (
    <StyledDivWrapper>
      <StyledDivTitleWrapper>
        {statusType && <Status type={statusType} />}
        <StyledDivTitle>{title}</StyledDivTitle>
      </StyledDivTitleWrapper>
      <StyledDivValue>
        {isLikeIconShown && <StyledLikeIconSvg />}
        {value}
      </StyledDivValue>
      {shouldRenderTrend && (
        <Trend
          label={trendLabel}
          changeDirection={trendChange}
          percentage={trendPercentage}
        />
      )}
      {onButtonClick && <Button onClick={onButtonClick}>{buttonLabel}</Button>}
    </StyledDivWrapper>
  );
};

NumericalLayout.displayName = 'NumericalLayout';

const StyledDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.spacing20};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledDivTitle = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
`;

const StyledDivValue = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  line-height: ${({ theme }) => theme.lineHeights.heading1};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledLikeIconSvg = styled(LikeIconSvg)`
  margin-right: ${({ theme }) => theme.space.spacing10};
`;
