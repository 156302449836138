import { FunctionComponent, useMemo } from 'react';

import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { PatientAgeRangeModel } from 'models/PatientAgeRangeModel';

import { Field, FormValidatorsService, required } from 'core/forms';
import { Checkbox, DatePicker, Dropdown, DropdownWithValuesField, Input, NumericInput, Switch, TextArea } from 'core/ui';

import { PatientGenders } from '../constants';
import { PatientUtils } from '../services';
import { PatientFormValues } from '../types';

export const PatientFormFields: FunctionComponent<{
  isFormDisabled: boolean;
  displayValues: PatientFormValues;
  handleFormChange: (data: any) => void;
  isEditMode: boolean;
  ageRanges: PatientAgeRangeModel[] | undefined;
  age: string;
  isSubProperty: boolean;
  valueSetter: (key: string, value: any) => void;
  valueGetter: (key: string) => any;
  isOpo?: boolean;
}> = ({ isFormDisabled, isOpo, displayValues, handleFormChange, isEditMode, ageRanges, age, isSubProperty, valueGetter }) => {
  const isOpoLocation = isOpo || displayValues?.location?.locationType?.name === 'OPO' || displayValues?.locationType === 'OPO';
  const isUnosUnavailable = valueGetter('isUnosUnavailable');
  const caseId = valueGetter('caseID');
  const unosId = valueGetter('unosID');

  const unosValidations = useMemo(() => {
    return [FormValidatorsService.required, FormValidatorsService.unos];
  }, []);

  return (
    <>
      <Row xs={1} md={4} lg={6}>
        {isOpoLocation && (
          <Col>
            {isFormDisabled && (
              <>
                <StyledBoldText>Unos ID:</StyledBoldText>&nbsp;
                {displayValues.unosID}
              </>
            )}
            <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
              <Field
                component={Input}
                disabled={isFormDisabled}
                label="Unos ID"
                name={isSubProperty ? 'patient.unosID' : 'unosID'}
                required={isOpoLocation && !caseId}
                validator={!caseId ? unosValidations : undefined}
                onChange={handleFormChange}
              />
              <StyledUnosUnavailableField component={StyledCheckbox} label="UNOS ID Unavailable" name="isUnosUnavailable" onChange={handleFormChange} />
            </StyledFormFieldContainer>
          </Col>
        )}
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Case Id:</StyledBoldText>&nbsp;
              {displayValues.caseID}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="Case ID"
              name={isSubProperty ? 'patient.caseID' : 'caseID'}
              required={isOpoLocation && !unosId}
              validator={isOpoLocation && !unosId ? required : undefined}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>First Name:</StyledBoldText>&nbsp;
              {displayValues.firstName}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="First Name"
              name={isSubProperty ? 'patient.firstName' : 'firstName'}
              required
              validator={required}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Last Name:</StyledBoldText>&nbsp;
              {displayValues.lastName}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="Last Name"
              name={isSubProperty ? 'patient.lastName' : 'lastName'}
              required
              validator={isOpoLocation ? required : undefined}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>DOB:</StyledBoldText>&nbsp;
              {PatientUtils.formatDob(displayValues.dob)}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field component={DatePicker} disabled={isFormDisabled} label="DOB" name={isSubProperty ? 'patient.dob' : 'dob'} onChange={handleFormChange} />
          </StyledFormFieldContainer>
        </Col>
        <StyledBoldCol bold={isFormDisabled} lg={isOpo ? 2 : 4}>
          <Field
            component={Dropdown}
            data={ageRanges}
            disabled={isFormDisabled}
            label="Age Range"
            name={isSubProperty ? 'patient.ageRange' : 'ageRange'}
            textField="description"
            required
            validator={required}
            onChange={handleFormChange}
          />
        </StyledBoldCol>
      </Row>
      <Row xs={1} md={4} lg={6}>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Weight:</StyledBoldText>&nbsp;
              {displayValues.weight}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={NumericInput}
              disabled={isFormDisabled}
              label="Weight (kg)"
              name={isSubProperty ? 'patient.weight' : 'weight'}
              min="0"
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>MRN:</StyledBoldText>&nbsp;
              {displayValues.patientNumber}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="MRN"
              name={isSubProperty ? 'patient.patientNumber' : 'patientNumber'}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Height:</StyledBoldText>&nbsp;
              {displayValues.height}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={NumericInput}
              disabled={isFormDisabled}
              label="Height (cm)"
              name={isSubProperty ? 'patient.height' : 'height'}
              min="0"
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Gender:</StyledBoldText>&nbsp;
              {displayValues.gender}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={DropdownWithValuesField}
              data={PatientGenders}
              dataItemKey="value"
              disabled={isFormDisabled}
              isForPrimitiveValues
              label="Gender"
              name={isSubProperty ? 'patient.gender' : 'gender'}
              valueField="value"
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Cross Clamp Time:</StyledBoldText>&nbsp;
              {PatientUtils.formatCrossClampDateTime(displayValues.crossClampDateTime)}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={DateTimePicker}
              disabled={isFormDisabled}
              label="Cross Clamp Time"
              name={isSubProperty ? 'patient.crossClampDateTime' : 'crossClampDateTime'}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
        <Col>
          {isFormDisabled && (
            <>
              <StyledBoldText>Hospital:</StyledBoldText>&nbsp;
              {displayValues.hospital}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field
              component={Input}
              disabled={isFormDisabled}
              label="Hospital"
              name={isSubProperty ? 'patient.hospital' : 'hospital'}
              onChange={handleFormChange}
            />
          </StyledFormFieldContainer>
        </Col>
      </Row>
      <Row xs={1} md={4} lg={6}>
        {isEditMode && (
          <StyledBoldCol bold={isFormDisabled}>
            <Field component={Switch} disabled={isFormDisabled} label="Active" name={isSubProperty ? 'patient.active' : 'active'} />
          </StyledBoldCol>
        )}
        <Col lg={10}>
          {isFormDisabled && (
            <>
              <StyledBoldText>Notes:</StyledBoldText>&nbsp;
              {displayValues.notes}
            </>
          )}
          <StyledFormFieldContainer isFormDisabled={isFormDisabled}>
            <Field component={TextArea} disabled={isFormDisabled} label="Notes" name={isSubProperty ? 'patient.notes' : 'notes'} onChange={handleFormChange} />
          </StyledFormFieldContainer>
        </Col>
      </Row>
    </>
  );
};

const StyledBoldCol = styled(Col)<{ bold: boolean }>`
  .k-label {
    font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
    color: ${({ theme, bold }) => (bold ? theme.colors.primary : 'inherit')};
  }
`;

const StyledBoldText = styled.b`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledCheckbox = styled(Checkbox)`
  display: inline-flex;
  gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledUnosUnavailableField = styled(Field)`
  padding-bottom: ${({ theme }) => theme.space.spacing40};
`;

const StyledFormFieldContainer = styled.div<{ isFormDisabled: boolean }>`
  display: ${({ isFormDisabled }) => (isFormDisabled ? 'none' : 'block')};
`;
