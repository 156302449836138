import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValueGetterFn = <ValueGetterType>(fieldName: string) => ValueGetterType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidatorFn<T = any> = (fieldValue: T, valueGetter: ValueGetterFn) => string;

export type ValidatorBag = Record<string, ValidatorFn>;

const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const phoneRegex = new RegExp('^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$');

const zipRegex = new RegExp('^\\d{5}(-\\d{4})?$');

export const unosRegex = new RegExp('[aA][a-zA-Z]{2}[a-zA-Z\\d]{1}[\\d]{3}');

export const combineValidators: (validators: ValidatorFn[]) => ValidatorFn = (validators) => {
  return (fieldValue, valueGetter) => {
    const result = validators.map((validator) => validator(fieldValue, valueGetter)).filter((message) => message);

    if (result.length === 0) {
      return '';
    }

    return result[0];
  };
};

export const required: ValidatorFn = (fieldValue) => (fieldValue ? '' : 'This field is required.');

export const requiredValue: (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expectedValue: any,
  errorMessage: string,
) => ValidatorFn = (expectedValue, errorMessage) => {
  return (fieldValue) => (fieldValue === expectedValue ? '' : errorMessage);
};

export const richEditorRequired: ValidatorFn = (fieldValue) => (fieldValue !== '<p></p>' ? '' : 'This field is required.');

export const email: ValidatorFn = (fieldValue) => {
  if (fieldValue) {
    return emailRegex.test(fieldValue) ? '' : 'Please enter a valid email.';
  }

  return '';
};

export const unos: ValidatorFn = (fieldValue) => {
  if (fieldValue) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (fieldValue.toLowerCase() === 'unknown') {
      return '';
    }
    return unosRegex.test(fieldValue) ? '' : 'Please enter a valid UNOS ID.';
  }

  return '';
};

export const emailList: (delimiter: string) => ValidatorFn<string> = (delimiter) => {
  return (fieldValue) => {
    if (fieldValue != null) {
      const emails = fieldValue.replace(/\s/g, '').split(delimiter);
      // convert this to a for...of loop
      for (const email of emails) {
        if (email && !emailRegex.test(email)) {
          return 'Please enter valid emails.';
        }
      }
    }

    return '';
  };
};

export const phone: ValidatorFn = (fieldValue) => {
  if (fieldValue) {
    return phoneRegex.test(fieldValue) ? '' : 'Please enter a valid phone number.';
  }

  return '';
};

export const maxLength: (lengthToCompare: number) => ValidatorFn = (lengthToCompare) => {
  return (fieldValue) => {
    if (fieldValue != null && fieldValue.length > 0 && fieldValue.length > lengthToCompare) {
      return `This field cannot have more than ${lengthToCompare} characters.`;
    }

    return '';
  };
};

export const minLength: (lengthToCompare: number) => ValidatorFn = (lengthToCompare) => {
  return (fieldValue) => {
    if (fieldValue != null && fieldValue.length > 0 && fieldValue.length < lengthToCompare) {
      return `This field requires a minimum of ${lengthToCompare} characters.`;
    }

    return '';
  };
};

export const greaterThanOrEqualTo: (valueToCompare: number) => ValidatorFn = (valueToCompare) => {
  return (fieldValue) => {
    if (fieldValue != null && fieldValue < valueToCompare) {
      return `Value must be greater or equal to ${valueToCompare}.`;
    }

    return '';
  };
};

export const lowerValueThanField: (fieldNameToCompare: string, errorMessage: string) => ValidatorFn = (fieldNameToCompare, errorMessage) => {
  return (fieldValue, valueGetter) => {
    const valueToCompare = valueGetter<string>(fieldNameToCompare);
    if (fieldValue != null && parseInt(fieldValue, 10) >= parseInt(valueToCompare, 10)) {
      let finalMessage;
      if (errorMessage == null || errorMessage === '') {
        finalMessage = `Value must be lower than ${valueToCompare}.`;
      } else {
        finalMessage = errorMessage;
      }

      return finalMessage;
    }

    return '';
  };
};

export const zip: ValidatorFn = (fieldValue) => {
  if (fieldValue) {
    return zipRegex.test(fieldValue) ? '' : 'Please enter a zip.';
  }

  return '';
};

export const datetime: ValidatorFn = (fieldValue) => {
  if (fieldValue) {
    return dayjs(fieldValue).isValid() ? '' : 'Invalid date/time.';
  }

  return '';
};
