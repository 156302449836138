import { FunctionComponent, ReactElement, cloneElement, useState } from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  DropDownListChangeEvent,
  MultiSelect,
  TagData,
} from '@progress/kendo-react-dropdowns';
import _map from 'lodash/map';
import styled from 'styled-components';

import { TatModel } from 'models/TatModel';

import { DropdownWithValuesField } from 'core/ui';

import { Organs } from 'features/exam/constants';
import { useServices } from 'features/service';

export const TATMultiSelect: FunctionComponent<{
  data: TatModel[];
  value: TatModel[];
  onChange: (value: { value: TatModel[] }) => void;
}> = ({ value, onChange, data }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedTAT, setSelectedTAT] = useState<TatModel>({} as TatModel);
  const services = useServices();

  const handleDialogUpdate = () => {
    // apply new/changed TAT
    const newValue = value?.map((i) => {
      if (i.id === selectedTAT.id) {
        return selectedTAT;
      }
      return i;
    });
    onChange({ value: newValue });
    setShowDialog(false);
  };

  const onOpenDialog = (tagData: TagData) => {
    setSelectedTAT(tagData.data[0]);
    setShowDialog(true);
  };

  const handleOrganChange = (event: DropDownListChangeEvent) => {
    // set organ property in changed value object from the value array

    setSelectedTAT({
      ...selectedTAT,
      organ: event.value as number,
    });
  };

  const handleServiceChange = (event: DropDownListChangeEvent) => {
    setSelectedTAT({
      ...selectedTAT,
      serviceId: event.value as number,
    });
  };

  const tagRender = (tagData: TagData, li: ReactElement<HTMLLIElement>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cloneElement(li, li.props, [
      <span onClick={() => onOpenDialog(tagData)} key={tagData.data[0]?.id}>
        {tagData.text}
      </span>,
      li.props.children,
    ]);

  return (
    <StyledContainerDiv>
      {showDialog && (
        <StyledDialog
          title={`Update ${selectedTAT.name}`}
          onClose={() => setShowDialog(false)}
        >
          <DropdownWithValuesField
            data={_map(Organs)}
            dataItemKey="value"
            filterable={false}
            isForPrimitiveValues
            label="Organ"
            name="organ"
            valueField="value"
            value={selectedTAT.organ}
            onChange={handleOrganChange}
          />
          <DropdownWithValuesField
            data={_map(services)}
            label="Study Type"
            name="service"
            textField="description"
            valueField="id"
            value={selectedTAT.serviceId}
            onChange={handleServiceChange}
          />
          <DialogActionsBar>
            <Button onClick={handleDialogUpdate}>Apply</Button>
          </DialogActionsBar>
        </StyledDialog>
      )}
      <MultiSelect
        data={data}
        textField="name"
        dataItemKey="id"
        value={value}
        onChange={onChange}
        tagRender={tagRender}
      />
    </StyledContainerDiv>
  );
};

const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledDialog = styled(Dialog)`
  z-index: 999 !important;
`;
