import { ChangeEvent, MouseEvent, memo, useEffect } from 'react';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  SwitchChangeEvent,
  TextAreaChangeEvent,
} from '@progress/kendo-react-inputs';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { RHF_FULL_RESET } from 'core/forms/constants';
import { useEvent } from 'core/hooks';
import {
  Button,
  ButtonVariants,
  FlatIconButton,
  Icon,
  InputField,
  Label,
  SwitchField,
  TextAreaField,
} from 'core/ui';

import { useCurrentUser } from 'features/auth';

import { PortalSettingsService } from '../services';
import { EditDefinitionFormValues, EditSettingFormProps } from '../types';

export const EditDefinitionForm = memo<EditSettingFormProps>(
  ({ className, values, onClose, onChange, onSubmit, onDeleteClick }) => {
    const { currentUser } = useCurrentUser(true);

    const rhfContext = useForm<EditDefinitionFormValues>({
      defaultValues: PortalSettingsService.modelToFormValues(values),
    });

    const handleFieldChange = useEvent(
      (
        event:
          | ChangeEvent<HTMLInputElement>
          | SwitchChangeEvent
          | TextAreaChangeEvent,
      ) => {
        if (event.target.name == null) {
          throw new Error(
            'Unable to propagate change event because the triggering element does not have a name.',
          );
        }

        onChange({
          ...values,
          [event.target.name]: event.target.value,
        });
      },
    );

    const handleSetNullClick = useEvent(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (event.currentTarget.dataset.fieldName == null) {
          throw new Error(
            'Unable to set field to null because the attribute "data-field-name" is null or undefined.',
          );
        }

        onChange({
          ...values,
          [event.currentTarget.dataset.fieldName]: null,
        });

        rhfContext.setValue(
          event.currentTarget.dataset
            .fieldName as keyof EditDefinitionFormValues,
          '',
          {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          },
        );
      },
    );

    const handleSelectedSettingChange = useEvent(() => {
      rhfContext.reset(
        PortalSettingsService.modelToFormValues(values),
        RHF_FULL_RESET,
      );
    });

    useEffect(() => {
      handleSelectedSettingChange();
    }, [handleSelectedSettingChange, values.id]);

    return (
      <FormProvider {...rhfContext}>
        <StyledForm
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          className={className}
          onSubmit={rhfContext.handleSubmit(onSubmit)}
        >
          <StyledCloseContainer>
            <FlatIconButton onClick={onClose}>
              <Icon icon={faXmark} />
            </FlatIconButton>
          </StyledCloseContainer>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="name" required>
              Name
            </Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <InputField name="name" onChange={handleFieldChange} required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="description">Description</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <TextAreaField
                name="description"
                onChange={handleFieldChange}
                placeholder={values.description === null ? 'null' : undefined}
                fixedErrorHeight
              />
            </FieldContainer>
          </GridColumn>
          <StyledNullColumn columnStart="3">
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={handleSetNullClick}
              data-field-name="description"
            >
              Set null
            </Button>
          </StyledNullColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="value">Value</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <TextAreaField
                name="value"
                onChange={handleFieldChange}
                placeholder={values.value === null ? 'null' : undefined}
                fixedErrorHeight
                autoSize={false}
              />
            </FieldContainer>
          </GridColumn>
          <StyledNullColumn columnStart="3">
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={handleSetNullClick}
              data-field-name="value"
            >
              Set null
            </Button>
          </StyledNullColumn>

          {!currentUser.isSystemAdmin ? null : (
            <>
              <GridColumn columnStart="1" isLabelColumn>
                <Label editorId="system">System</Label>
              </GridColumn>
              <GridColumn columnStart="2">
                <FieldContainer $hideLabel>
                  <SwitchField name="system" onChange={handleFieldChange} />
                </FieldContainer>
              </GridColumn>
            </>
          )}

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="active">Active</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <SwitchField name="active" onChange={handleFieldChange} />
            </FieldContainer>
          </GridColumn>

          <StyledButtonsContainer>
            <Button variant={ButtonVariants.SECONDARY} onClick={onDeleteClick}>
              Delete
            </Button>
            <Button type="submit">Save</Button>
          </StyledButtonsContainer>
        </StyledForm>
      </FormProvider>
    );
  },
);

EditDefinitionForm.displayName = 'EditDefinitionForm';

const StyledForm = createStyledRhfForm('min-content 700px min-content');

const StyledCloseContainer = styled.div`
  grid-column: 1 / span 3;
  display: flex;
  overflow: hidden;
  justify-content: end;
`;

const StyledNullColumn = styled(GridColumn)`
  padding: 0 0 0 8px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  overflow: hidden;
  grid-column: 1 / span 3;
  justify-content: space-between;
`;
