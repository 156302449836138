import { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { Modifier, Options } from '@popperjs/core';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { chevronDownIcon } from '@progress/kendo-svg-icons';
import { createPortal } from 'react-dom';
import { mergeRefs } from 'react-merge-refs';
import { usePopper } from 'react-popper';
import useMeasure from 'react-use-measure';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { hasText } from 'core/utils';

import { Input } from '../Input';
import { AutoCompleteHandle } from './AutoCompleteHandle';
import { AutoCompleteProps } from './AutoCompleteProps';
import { Item } from './Item';

const CHEVRON_ICON_RAW_HTML = { __html: chevronDownIcon.content };
// eslint-disable-next-line @typescript-eslint/no-empty-function
const NO_OP_CALLBACK = () => {};

export const AutoComplete = memo(
  forwardRef<AutoCompleteHandle, AutoCompleteProps>(
    ({ className, value, name, autoCompleteData, valid, visited, onChange, onBlur, onFocus, onEnterKeyDown }, ref) => {
      const scrollListRef = useRef<FixedSizeList | null>(null);
      const itemButtonRefs = useRef<Map<number, HTMLButtonElement>>(new Map());
      const [inputEle, setInputEle] = useState<HTMLInputElement | null>(null);
      const [expandButtonEle, setExpandButtonEle] = useState<HTMLButtonElement | null>(null);
      const [popperEle, setPopperEle] = useState<HTMLDivElement | null>(null);
      const [isExpanded, setIsExpanded] = useState(false);
      const [inputValue, setInputValue] = useState(value ?? '');
      const [highlightId, setHighlightId] = useState<number | null>(null);
      const suppressNextFocusEvent = useRef(false);
      const stableOnEnterKeyDown = useEvent(onEnterKeyDown ?? NO_OP_CALLBACK);

      const [inputRef, { width: inputWidth }] = useMeasure();

      const sameWidth: Modifier<'sameWidth', Options> = useMemo(
        () => ({
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            state.styles.popper.width = `${inputWidth}px`;
          },
          effect: ({ state }) => {
            state.elements.popper.style.width = `${inputWidth}px`;
          },
        }),
        [inputWidth],
      );

      const { styles: popperStyles, attributes: popperAttributes } = usePopper(inputEle, popperEle, {
        placement: 'bottom-start',
        modifiers: [sameWidth],
      });

      const filterDescriptor: FilterDescriptor | null = useMemo(
        () =>
          !hasText(inputValue)
            ? null
            : {
                field: 'description',
                operator: 'contains',
                value: inputValue,
              },
        [inputValue],
      );

      const filteredData = useMemo(
        () => (filterDescriptor == null ? autoCompleteData : filterBy(autoCompleteData, filterDescriptor)),
        [autoCompleteData, filterDescriptor],
      );

      const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          if (hasText(event.currentTarget.value)) {
            setIsExpanded(true);
          } else {
            setIsExpanded(false);
          }

          setInputValue(event.currentTarget.value);
          onChange(event.currentTarget.value);
        },
        [onChange],
      );

      const handleInputKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'ArrowDown') {
            const currentIndex = highlightId == null ? null : filteredData.findIndex((d) => d.id === highlightId) ?? null;

            let nextIndex: number | null;

            if (currentIndex == null) {
              nextIndex = filteredData.length > 0 ? 0 : null;
            } else {
              nextIndex = filteredData.length - 1 === currentIndex ? currentIndex : currentIndex + 1;
            }

            event.preventDefault(); // Required to keep the edit caret position stationary.
            setIsExpanded(true);
            setHighlightId(nextIndex == null ? null : filteredData[nextIndex].id);

            setTimeout(() => {
              if (nextIndex != null) {
                scrollListRef.current?.scrollToItem(nextIndex, 'smart');
              }
            });
          } else if (event.key === 'ArrowUp') {
            const currentIndex = highlightId == null ? null : filteredData.findIndex((d) => d.id === highlightId) ?? null;

            let nextIndex: number | null;

            if (currentIndex == null) {
              nextIndex = filteredData.length > 0 ? 0 : null;
            } else {
              nextIndex = currentIndex === 0 ? currentIndex : currentIndex - 1;
            }

            event.preventDefault(); // Required to keep the edit caret position stationary.
            setIsExpanded(true);
            setHighlightId(nextIndex == null ? null : filteredData[nextIndex].id);

            setTimeout(() => {
              if (nextIndex != null) {
                scrollListRef.current?.scrollToItem(nextIndex, 'smart');
              }
            });
          } else if (event.key === 'Enter') {
            if (highlightId != null) {
              const item = filteredData.find((d) => d.id === highlightId);

              const newInputValue = item?.value ?? '';

              setInputValue(newInputValue);
              setIsExpanded(false);
              setHighlightId(null);
              onChange(newInputValue);
            }

            // Wait until after the parent components have had a chance to respond and propagate the latest value emitted by the onChange event before we emit the enter keydown event.
            const currentTargetEle = event.currentTarget;
            setTimeout(() => {
              // Use the end caret position when the user has highlighted a range of text.  This is an arbitrary decision and is open to re-evaluation.
              const caretIndex = currentTargetEle.selectionEnd;
              stableOnEnterKeyDown(caretIndex);
            });
          } else if (event.key === 'Escape') {
            setIsExpanded(false);
            setHighlightId(null);
          }
        },
        [filteredData, highlightId, onChange, stableOnEnterKeyDown],
      );

      const handleInputFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
          // The user experience for this control is to always keep the focus on the input text field until the user explicitly clicks or changes focus to
          // something outside the bounds of this component.  So anytime the user interacts with a sub-element (the expand icon, entries on the dropdown
          // list, etc.) we need to immediately re-focus the input text field at the same position as before.

          // Suppress focus events when it was programatically emitted (by calling HTMLInputElement.focus()) from within a handler for some different kind of event.
          if (suppressNextFocusEvent.current) {
            suppressNextFocusEvent.current = false;
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            return;
          }

          setIsExpanded(event.currentTarget.value.length > 0);
          onFocus?.(event);
        },
        [onFocus],
      );

      const handleInputBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
          if (popperEle?.contains(event.relatedTarget) ?? expandButtonEle?.contains(event.relatedTarget)) {
            // Focus-trap back to the input text field if the blur event was triggered by the user focusing on some child element within this component.
            suppressNextFocusEvent.current = true;
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            event.currentTarget.focus(); // Focusing an element programatically with focus() will always trigger an onFocus event.
          } else {
            // User clicked somewhere outside the component so we need to close the popup and propagate the blur event upwards.
            setIsExpanded(false);
            setHighlightId(null);
            onBlur?.(event);
          }
        },
        [expandButtonEle, onBlur, popperEle],
      );

      const handleExpandButtonClick = useCallback(() => {
        const newIsExpanded = !isExpanded;
        setIsExpanded(newIsExpanded);

        if (newIsExpanded) {
          inputEle?.focus();
        } else {
          setHighlightId(null);
        }
      }, [inputEle, isExpanded]);

      const handleItemClick = useCallback(
        (itemId: number) => {
          const item = autoCompleteData.find((d) => d.id === itemId);
          const newInputValue = item?.value ?? '';

          setInputValue(newInputValue);
          setIsExpanded(false);
          onChange(newInputValue);

          suppressNextFocusEvent.current = true;
          inputEle?.focus(); // Need to re-focus the input because the act of clicking on an element in the list will cause the input field to lose focus.
        },
        [autoCompleteData, inputEle, onChange],
      );

      useImperativeHandle(
        ref,
        () => ({
          get value() {
            return inputEle?.value ?? '';
          },
          focus: () => inputEle?.focus(),
        }),
        [inputEle],
      );

      useEffect(() => {
        // We don't need to update internal state at all when operating in "uncontrolled" mode.
        if (typeof value === 'undefined') {
          return;
        }

        setInputValue(value ?? '');
      }, [value]);

      return (
        <StyledContainerDiv className={className}>
          <Input
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={mergeRefs([inputRef as any, setInputEle])}
            name={name}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            valid={valid}
            visited={visited}
          />
          <StyledExpandButton ref={setExpandButtonEle} type="button" tabIndex={-1} onClick={handleExpandButtonClick}>
            <svg
              viewBox={chevronDownIcon.viewBox}
              width={16}
              height={16}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={CHEVRON_ICON_RAW_HTML}
            />
          </StyledExpandButton>
          {isExpanded &&
            createPortal(
              <StyledDropdownDiv
                ref={setPopperEle}
                style={popperStyles.popper}
                {...popperAttributes.popper}
                data-show={isExpanded}
                /*
                 * TODO: There may be a more intuitive way to do this, but it's late.
                 * The following tabIndex={-1} may seem like a mistake.  It allows the onBlur handler that is attached to the input text field to properly check if the user clicked
                 * directly on the popup div.  The ability to click anywhere on a dropdown/autocomplete popup without dismissing the popup is (probably) the generally expected behavior.
                 * More info: https://stackoverflow.com/a/42764495
                 */
                tabIndex={-1}
              >
                {filteredData.length > 0 && (
                  <FixedSizeList ref={scrollListRef} height={200} itemCount={filteredData.length} itemSize={24} width={inputWidth}>
                    {({ index, style }) => (
                      <Item
                        key={filteredData[index].id}
                        ref={(node) => {
                          if (node) {
                            itemButtonRefs.current.set(filteredData[index].id, node);
                          } else {
                            itemButtonRefs.current.delete(filteredData[index].id);
                          }
                        }}
                        style={style}
                        item={filteredData[index]}
                        onClick={handleItemClick}
                        highlightItemId={highlightId}
                      />
                    )}
                  </FixedSizeList>
                )}

                {filteredData.length === 0 && <StyledNoMatchesDiv>No matches</StyledNoMatchesDiv>}
              </StyledDropdownDiv>,
              document.body,
            )}
        </StyledContainerDiv>
      );
    },
  ),
);

AutoComplete.displayName = 'AutoComplete';

const StyledContainerDiv = styled.div`
  position: relative;
  flex: 1 1 0;

  & input {
    padding-right: 29px;
  }
`;

const StyledExpandButton = styled.button`
  appearance: none;
  border: none;
  background-color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  z-index: 1;
  top: 1px;
  bottom: 1px;
  right: 3px;
  width: 20px;

  &:focus,
  &:focus-visible,
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
    border: none;
  }
`;

const StyledDropdownDiv = styled.div`
  display: none;
  background-color: white;
  z-index: 100;
  min-height: 32px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.03),
    0 4px 5px 0 rgba(0, 0, 0, 0.04);

  &[data-show] {
    display: flex;
    flex-direction: column;
  }
`;

const StyledNoMatchesDiv = styled.div`
  flex: 1 1 0;
  color: #6c757d;
  align-self: center;
  display: flex;
  align-items: center;
  user-select: none;
`;
