import { FunctionComponent, useMemo } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { Checkbox } from '../../../Checkbox';
import { CheckboxCellProps } from './CheckboxCellProps';

export const CheckboxCell: FunctionComponent<CheckboxCellProps> = ({ className, dataItem, field }) => {
  const content = useMemo(
    () => (
      <StyledTdCheckboxCell className={className} $isChecked={field == null ? false : dataItem[field]} $dataItem={dataItem} $field={field}>
        <StyledSpanCheckboxWrapper $isChecked={field == null ? false : dataItem[field]} $dataItem={dataItem} $field={field}>
          <Checkbox value={field == null ? undefined : dataItem[field]} />
        </StyledSpanCheckboxWrapper>
      </StyledTdCheckboxCell>
    ),
    [className, dataItem, field],
  );

  return content;
};

CheckboxCell.displayName = 'CheckboxCell';

type StyledElementProps = {
  theme: DefaultTheme;
  $isChecked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $dataItem: any;
  $field?: string;
};

const resolveBackgroundColor = ({ $dataItem, $field, theme }: StyledElementProps) => {
  if ($field != null && $dataItem[$field]) {
    return theme.colors.palette.aquas[0];
  }

  return theme.colors.palette.white;
};

const resolveBoxShadow = ({ $isChecked }: StyledElementProps) => {
  if ($isChecked) {
    return '0px 2px 8px rgba(0, 0, 0, 0.15)';
  }

  return '0px 6px 8px rgba(0, 0, 0, 0.15)';
};

const StyledTdCheckboxCell = styled.td<{
  $isChecked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $dataItem: any;
  $field?: string;
}>`
  background-color: ${resolveBackgroundColor};

  tr.k-master-row &,
  tr.k-master-row.k-alt & {
    padding-bottom: 0rem;
    padding-top: 0rem;
  }
`;

const StyledSpanCheckboxWrapper = styled.span<{
  $isChecked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $dataItem: any;
  $field?: string;
}>`
  display: inline-flex;
  height: 100%;
  padding-left: ${({ theme }) => theme.space.spacing20};
  padding-right: ${({ theme }) => theme.space.spacing20};

  &:hover {
    box-shadow: ${resolveBoxShadow};
  }
`;
