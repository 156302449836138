// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/patients`;

const getByIdRoute = (id) => `${getBaseRoute()}/${id}`;

const getForGridRoute = () => `${getBaseRoute()}/grid`;

export const PatientRouteService = {
  getBaseRoute,
  getByIdRoute,
  getForGridRoute,
};
