import { memo, useMemo, useState } from 'react';

import styled from 'styled-components';

import { ExamModel, PatientModel } from 'models';

import { StandardFormState } from 'core/forms';
import { useEvent } from 'core/hooks';
import { Button, ButtonVariants, ComponentSizes, ErrorMessage } from 'core/ui';

import { apiClient } from 'features/api';
import { useSessionLocation } from 'features/location';

import { useUploadExamsPageContext } from '../hooks';
import { ExamUploadService } from '../services';
import { ExamFormValues, PatientFormValues, WizardStepKey } from '../types';
import { ExamForm } from './ExamForm';
import { PatientForm } from './PatientForm';
import { UploadGroupListItem } from './UploadGroupListItem';

export const ExamsStep = memo(() => {
  const { allServices, uploadGroups, fixedPatient, fixedExam, setUploadGroups, onNextStep, onPreviousStep, validateStep } = useUploadExamsPageContext();
  const { sessionLocation } = useSessionLocation(true);

  const [stepError, setStepError] = useState<string | null>(null);
  const [selectedUploadGroupId, setSelectedUploadGroupId] = useState(() => {
    const firstCheckedGroupIndex = uploadGroups.findIndex((group) => group.checked);
    return firstCheckedGroupIndex >= 0 ? uploadGroups[firstCheckedGroupIndex].uploadGroupId : null;
  });

  const selectedUploadGroup = useMemo(() => uploadGroups.find((u) => u.uploadGroupId === selectedUploadGroupId) ?? null, [selectedUploadGroupId, uploadGroups]);

  const handleUploadGroupClick = useEvent((uploadGroupId: string) => {
    setSelectedUploadGroupId(uploadGroupId);
  });

  const handlePatientFormChange = useEvent((values: PatientFormValues) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((u) => u.uploadGroupId === selectedUploadGroupId);

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        patientForm: values,
      };

      return newUploadGroups;
    });
  });

  const handlePatientModelSelected = useEvent((newPatient: PatientModel | null) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((u) => u.uploadGroupId === selectedUploadGroupId);

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        patient: newPatient,
      };

      return newUploadGroups;
    });

    setStepError(null);
  });

  const handlePatientFormStateChange = useEvent((newState: StandardFormState) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((u) => u.uploadGroupId === selectedUploadGroupId);

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        patientFormState: newState,
      };

      return newUploadGroups;
    });

    setStepError(null);
  });

  const handlePatientFormSave = useEvent(async () => {
    if (selectedUploadGroup == null) throw new Error('selectedUploadGroup cannot be null or undefined.');

    const newPatient = ExamUploadService.copyPatientFormToModel(selectedUploadGroup.patientForm, sessionLocation.id, selectedUploadGroup.patient);

    if (selectedUploadGroup.patient == null) {
      // Creating a new patient.
      newPatient.id = await apiClient.patientClient.createPatient(newPatient);
    } else {
      // Updating an existing patient.
      await apiClient.patientClient.updatePatient(newPatient);
    }

    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];

      for (let i = 0; i < newUploadGroups.length; i++) {
        // Update the currently selected upload item to use the new patient model.  Additionally we also need to update any other references to the same patient id.
        if (newUploadGroups[i].uploadGroupId === selectedUploadGroup.uploadGroupId || newUploadGroups[i].patient?.id === newPatient.id) {
          newUploadGroups[i] = {
            ...newUploadGroups[i],
            patient: newPatient,
            // Overwrite all of the form states so that they use the new values.
            patientForm: { ...selectedUploadGroup.patientForm },
            patientFormState: { isDirty: false, isValid: true, isValidating: false },
          };
        }
      }

      return newUploadGroups;
    });

    setStepError(null);
  });

  const handleExamFormChange = useEvent((values: ExamFormValues) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((u) => u.uploadGroupId === selectedUploadGroupId);

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        examForm: values,
      };

      return newUploadGroups;
    });
  });

  const handleExamModelSelected = useEvent((newExam: ExamModel | null) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((u) => u.uploadGroupId === selectedUploadGroupId);

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        exam: newExam,
      };

      return newUploadGroups;
    });

    setStepError(null);
  });

  const handleExamFormStateChange = useEvent((newState: StandardFormState) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((u) => u.uploadGroupId === selectedUploadGroupId);

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        examFormState: newState,
      };

      return newUploadGroups;
    });

    setStepError(null);
  });

  const handleExamFormSave = useEvent(async () => {
    if (selectedUploadGroup == null) throw new Error('selectedUploadGroup cannot be null or undefined.');
    if (selectedUploadGroup.patient == null) throw new Error('selectedUploadGroup.patient cannot be null or undefined.');

    const newExam = ExamUploadService.copyExamFormToModel(
      selectedUploadGroup.examForm,
      sessionLocation.id,
      selectedUploadGroup.patient.id,
      selectedUploadGroup.exam,
    );

    if (selectedUploadGroup.exam == null) {
      // Creating a new exam.
      newExam.id = await apiClient.exams.createExam(newExam);
    } else {
      // Updating an existing exam.
      await apiClient.exams.updateExam(newExam);
    }

    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];

      for (let i = 0; i < newUploadGroups.length; i++) {
        // Update the currently selected upload item to use the new exam model.  Additionally we also need to update any other references to the same exam id.
        if (newUploadGroups[i].uploadGroupId === selectedUploadGroup.uploadGroupId || newUploadGroups[i].exam?.id === newExam.id) {
          newUploadGroups[i] = {
            ...newUploadGroups[i],
            exam: newExam,
            // Overwrite all of the form states so that they use the new values.
            examForm: { ...selectedUploadGroup.examForm },
            examFormState: { isDirty: false, isValid: true, isValidating: false },
          };
        }
      }

      return newUploadGroups;
    });

    setStepError(null);
  });

  const handleNextClick = useEvent(() => {
    if (validateStep(uploadGroups, WizardStepKey.Exams)) {
      setStepError(null);
      onNextStep();
    } else {
      setStepError('Please resolve issues before proceeding.');
    }
  });

  return (
    <StyledComponentDiv>
      <StyledExamsListDiv>
        <StyledExamsListHeaderDiv>
          <StyledExamsListHeaderCellDiv>Exam</StyledExamsListHeaderCellDiv>
        </StyledExamsListHeaderDiv>
        <StyledExamsListScrollDiv>
          {uploadGroups
            .filter((group) => group.checked)
            .map((group) => (
              <UploadGroupListItem
                key={group.uploadGroupId}
                group={group}
                selected={group.uploadGroupId === selectedUploadGroupId}
                onClick={handleUploadGroupClick}
              />
            ))}
        </StyledExamsListScrollDiv>
      </StyledExamsListDiv>
      {selectedUploadGroup != null && (
        <StyledFormsContainerDiv key={selectedUploadGroupId}>
          <PatientForm
            patient={selectedUploadGroup.patient}
            initialValues={selectedUploadGroup.patientForm}
            showDirtyError={false} // TODO: To remove?
            isFixedPatient={fixedPatient != null}
            onChange={handlePatientFormChange}
            onFormStateChange={handlePatientFormStateChange}
            onSave={handlePatientFormSave}
            onPatientModelSelected={handlePatientModelSelected}
          />
          <ExamForm
            allServices={allServices}
            exam={selectedUploadGroup.exam}
            initialValues={selectedUploadGroup.examForm}
            showDirtyError={false} // TODO: To remove?
            patient={selectedUploadGroup.patient}
            isFixedExam={fixedExam != null}
            onChange={handleExamFormChange}
            onFormStateChange={handleExamFormStateChange}
            onSave={handleExamFormSave}
            onExamModelSelected={handleExamModelSelected}
          />
        </StyledFormsContainerDiv>
      )}
      <StyledFooter>
        <StyledBigButton size={ComponentSizes.LARGE} onClick={onPreviousStep} variant={ButtonVariants.SECONDARY}>
          Back
        </StyledBigButton>
        <StyledNextButtonContainer>
          {stepError != null && <ErrorMessage>{stepError}</ErrorMessage>}
          <StyledBigButton size={ComponentSizes.LARGE} onClick={handleNextClick}>
            Next
          </StyledBigButton>
        </StyledNextButtonContainer>
      </StyledFooter>
    </StyledComponentDiv>
  );
});

ExamsStep.displayName = 'ExamsStep';

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr min-content;
  column-gap: ${({ theme }) => theme.space.spacing20};
  padding-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledExamsListDiv = styled.div`
  grid-row: 1 / span 2;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 32px;
  user-select: none;
`;

const StyledExamsListHeaderDiv = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  overflow: hidden;
  grid-template-columns: subgrid;
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  color: ${({ theme }) => theme.colors.palette.white};
  background-color: ${({ theme }) => theme.colors.primary};
  //padding: 0 ${({ theme }) => theme.space.spacing40};
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const StyledExamsListHeaderCellDiv = styled.div`
  display: grid;
  overflow: hidden;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space.spacing40};
`;

const StyledExamsListScrollDiv = styled.div`
  grid-column: 1 / span 2;
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  grid-template-columns: subgrid;
  grid-auto-rows: min-content;
`;

const StyledFormsContainerDiv = styled.div`
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  column-gap: ${({ theme }) => theme.space.spacing40};
  row-gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledBigButton = styled(Button)`
  && {
    display: flex;
    width: initial;
    height: initial;
    padding: 5px 16px;
    line-height: 22px;
  }

  .k-button-text {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 22px;
  }
`;

const StyledFooter = styled.div`
  align-self: end;
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;

const StyledNextButtonContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;

  button {
    margin-left: ${({ theme }) => theme.space.spacing40};
  }
`;
