import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Button, ButtonProps } from '../Button';

export const TabSaveButton: FunctionComponent<ButtonProps> = (props) => {
  return <StyledButton type="submit" {...props} />;
};

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-right: 9px;
`;
