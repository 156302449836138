import { FunctionComponent, ReactNode, useMemo } from 'react';

import { MsalProvider } from '@azure/msal-react';

import { useDataStream, useInitializationEffect } from 'core/hooks';

import { apiClient } from 'features/api';

import { AuthenticationContext, ParsedShareAccessTokenContext } from '../contexts';
import { AuthenticationManager } from '../services';
import { AuthenticationContextType } from '../types/AuthenticationContextType';

const manager = new AuthenticationManager(apiClient);

export const AuthenticationProvider: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  const activeScheme = useDataStream(manager.hub.activeScheme);
  const parsedShareToken = useDataStream(manager.shareScheme.hub.parsedShareToken);

  useInitializationEffect(() => {
    manager.initialize();
  });

  const newContext: AuthenticationContextType = useMemo(
    () => ({
      pca: manager.oidcScheme.pca,
      loginShare: manager.shareScheme.login,
      logoutShare: manager.shareScheme.logout,
      loginOidc: manager.oidcScheme.login,
      logoutOidc: manager.oidcScheme.logout,
      frontChannelLogoutOidc: manager.frontChannelLogoutOidc,
      oidcScheme: manager.oidcScheme,
      activeScheme,
    }),
    [activeScheme],
  );

  return (
    <AuthenticationContext.Provider value={newContext}>
      <ParsedShareAccessTokenContext.Provider value={parsedShareToken}>
        <MsalProvider instance={manager.oidcScheme.pca}>{children}</MsalProvider>
      </ParsedShareAccessTokenContext.Provider>
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.displayName = 'AuthenticationProvider';
