import { useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useEvent } from 'core/hooks';
import { ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, HeaderCell, Page, PageHeader, TextCell, Toolbar } from 'core/ui';

import { PhysicianActions, PhysicianSelectors } from '../redux';

export const PhysicianHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const physicians = useSelector(PhysicianSelectors.getAllGrid);
  const total = useSelector(PhysicianSelectors.getTotalGrid);

  const gridPDFExport = useRef(null);
  const gridExcelExport = useRef(null);

  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const handleDataStateChange = (changeEvent) => {
    setDataState(changeEvent.dataState);
  };

  const debouncedChangeDataStateHandler = useMemo(() => debounce(handleDataStateChange, 500), []);

  useEffect(() => {
    return () => debouncedChangeDataStateHandler.cancel();
  }, [debouncedChangeDataStateHandler]);

  useEffect(() => {
    dispatch(PhysicianActions.getAllForKendoGrid(dataState));
  }, [dispatch, dataState]);

  const handleExportPDFClick = () => {
    if (gridPDFExport.current !== null) {
      gridPDFExport.current.save();
    }
  };

  const handleExportExcelClick = async () => {
    if (gridExcelExport.current !== null) {
      const res = await dispatch(
        PhysicianActions.getAllForExport({
          skip: 0,
          take: total,
          filter: dataState.filter,
        }),
      );

      gridExcelExport.current.save(res.payload?.data);
    }
  };

  const handleAddNewClick = () => {
    navigate('/physician/add');
  };

  const handleEditClick = useEvent((dataItem) => navigate(`/physician/edit/${dataItem.id}`));

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-physician',
        title: 'Edit Physician',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  const grid = (
    <DataTable
      filterable
      sortable
      reorderable
      pageable={{ pageSizes: true }}
      total={total}
      data={physicians}
      {...dataState}
      onDataStateChange={handleDataStateChange}
      actions={gridActions}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPDFClick} />
      <GridColumn title="Action" filterable={false} sortable={false} headerCell={HeaderCell} cell={ActionListCell} width="80px" />
      <GridColumn cell={TextCell} field="firstName" filter="text" headerCell={HeaderCell} title="First Name" width="350px" />
      <GridColumn cell={TextCell} field="lastName" filter="text" headerCell={HeaderCell} title="Last Name" width="350px" />
      <GridColumn cell={TextCell} field="address1" filter="text" headerCell={HeaderCell} title="Address1" width="350px" />
      <GridColumn cell={TextCell} field="address2" filter="text" headerCell={HeaderCell} title="Address2" width="350px" />
      <GridColumn cell={TextCell} field="city" filter="text" headerCell={HeaderCell} title="City" width="250px" />
      <GridColumn cell={TextCell} field="state" filter="text" headerCell={HeaderCell} title="State" width="200px" />
      <GridColumn cell={TextCell} field="zip" filter="text" headerCell={HeaderCell} title="Zip" width="200px" />
      <GridColumn cell={TextCell} field="contAmt" filter="numeric" headerCell={HeaderCell} title="Cont Amount" width="200px" />
      <GridColumn cell={TextCell} field="phone1" filter="text" headerCell={HeaderCell} title="Phone" width="200px" />
      <GridColumn cell={TextCell} field="email" filter="text" headerCell={HeaderCell} title="Email" width="200px" />
    </DataTable>
  );

  return (
    <Page>
      <PageHeader title="Physicians" />
      <GridPDFExport ref={gridPDFExport}>{grid}</GridPDFExport>
      <ExcelExport ref={gridExcelExport}>{grid}</ExcelExport>
    </Page>
  );
};
