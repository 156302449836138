import { useMemo, useRef, useState } from 'react';

import { faMobileAlt, faPlus } from '@fortawesome/pro-solid-svg-icons';
import _find from 'lodash/find';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonVariants, Card, Checkbox, Icon } from 'core/ui';

import { DocumentCategories } from '../../exam/constants';
import ShowSidebarIcon from '../assets/left-arrow.svg?react';
import CollapseSidebarIcon from '../assets/right-arrow.svg?react';
import { FileActions } from '../redux';
import { ImgProxy } from './ImgProxy';
import { ThumbnailPlaceholder } from './ThumbnailPlaceholder';

const resolveFileCategoryShorthand = (file, isBackupExam) => {
  let categoryMatch = _find(DocumentCategories, (category) => category.value === file?.categoryId);
  // HACK to prevent showing the Exam category for non LINK files. Have buggy backend code erroneously categorizing files as Exam.
  if (categoryMatch?.name === DocumentCategories.EXAM.name && file.fileType !== 'LINK') {
    categoryMatch = DocumentCategories.DOCUMENT;
  }
  if (isBackupExam && categoryMatch?.name === DocumentCategories.EXAM.name) {
    return `Backup ${categoryMatch?.shorthand}`;
  }

  return categoryMatch?.shorthand;
};

const FileSelectSidebar = ({
  className,
  defaultCollapsed,
  files,
  handleAddNewFileClick,
  handleFileSelect,
  selectedFile,
  showEditBtn,
  showDeleteBtn,
  onClickYesBtn,
  accessToken,
  examType,
  hideUploadButton,
  examId,
  handleQRButtonClick,
}) => {
  const dispatch = useDispatch();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(defaultCollapsed);
  const [shouldSplitFiles, setShouldSplitFiles] = useState(true);

  const filesInputRef = useRef(null);

  const onUploadFilesClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    filesInputRef.current?.click();
  };

  const onUploadFilesChange = (event) => {
    const selectedFiles = event.target.files;

    const formData = new FormData();

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < selectedFiles.length; i += 1) {
      formData.append('files', selectedFiles[i]);
    }

    dispatch(FileActions.add({ files: formData, shouldSplitFiles, examId: examId }));
  };

  const handleDeleteFile = (fileId) => {
    dispatch(FileActions.deleteFile(fileId));
  };

  const isBackupExam = useMemo(() => {
    return (file) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const examFiles = files.filter((f) => f.viewerId && f.viewerId > 0);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return examFiles.findIndex((f) => f.id === file.id) > 0;
    };
  }, [files]);

  if (isSidebarCollapsed) {
    return (
      <StyledHideSidebarDiv $isCollapsed={isSidebarCollapsed} onClick={() => setIsSidebarCollapsed(false)} id="sidebarArrowIcon">
        <ShowSidebarIcon />
      </StyledHideSidebarDiv>
    );
  }

  return (
    <StyledDivWrapper className={className}>
      <StyledHideSidebarDiv $isCollapsed={isSidebarCollapsed} onClick={() => setIsSidebarCollapsed(true)}>
        <CollapseSidebarIcon />
      </StyledHideSidebarDiv>
      <input
        accept=".pdf,.xml,.png,.jpg,.tiff,.szi,.sv"
        id="file"
        multiple
        onChange={onUploadFilesChange}
        ref={filesInputRef}
        style={{ display: 'none' }}
        type="file"
      />
      {handleAddNewFileClick && (
        <StyledCardContainer>
          {!hideUploadButton && (
            <StyledSplitFileCard>
              {handleQRButtonClick && (
                <StyledButtonQR variant={ButtonVariants.SECONDARY} onClick={handleQRButtonClick}>
                  <Icon icon={faMobileAlt}></Icon> Mobile Upload
                </StyledButtonQR>
              )}

              <StyledButtonUpload variant={ButtonVariants.SECONDARY} onClick={onUploadFilesClick}>
                <Icon icon={faPlus}></Icon> Upload Files
              </StyledButtonUpload>
              <Checkbox text="Split Files" onChange={() => setShouldSplitFiles((prevValue) => !prevValue)} value={shouldSplitFiles} />
            </StyledSplitFileCard>
          )}
        </StyledCardContainer>
      )}
      <StyledFileScrollContainer>
        {files.map((file) => (
          <StyledDivFile key={file?.id} onClick={() => handleFileSelect(file)} $selected={selectedFile?.id === file.id}>
            {file.thumbnailUrl ? (
              <StyledImgProxy
                fileId={file.id}
                onRemove={handleDeleteFile}
                thumbnailUrl={file.thumbnailUrl}
                dateModified={file.modified}
                accessToken={accessToken}
              />
            ) : (
              <StyledThumbnailPlaceholder
                showDeleteBtn={showDeleteBtn}
                showEditBtn={showEditBtn}
                fileItem={file}
                onClickYesBtn={onClickYesBtn}
                examType={examType}
                onRemove={handleDeleteFile}
              />
            )}
            <StyledDivCategoryBadge>{resolveFileCategoryShorthand(file, isBackupExam(file))}</StyledDivCategoryBadge>
          </StyledDivFile>
        ))}
      </StyledFileScrollContainer>
    </StyledDivWrapper>
  );
};
const mediaMaxWidth = '1024px';

const StyledDivWrapper = styled.div`
  flex-basis: 170px;
  background: #434343;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  position: relative;
`;

const StyledHideSidebarDiv = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;
  position: absolute;
  top: 50%;
  transition: opacity 2.6s linear;
  width: 1.2rem;
  z-index: 10;

  ${({ $isCollapsed }) => {
    if ($isCollapsed) {
      return 'right: 0;';
    }

    return 'left: 0';
  }}
`;

const StyledCardContainer = styled.div`
  flex: 0 0 min-content;
  padding: ${({ theme }) => theme.space.spacing30} ${({ theme }) => theme.space.spacing30};
`;

const StyledSplitFileCard = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledButtonUpload = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing20};

  @media (max-width: ${mediaMaxWidth}) {
    display: none; /* Hide the button on mobile devices */
  }
`;

const StyledButtonQR = styled(Button)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};

  @media (max-width: ${mediaMaxWidth}) {
    display: none; /* Hide the button on mobile devices */
  }
`;

const StyledFileScrollContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
`;

const StyledDivFile = styled.div`
  border-radius: 4px;
  border: ${({ $selected }) => ($selected ? '4px solid #70B8DB;' : '4px solid transparent;')};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.space.spacing20};
  margin-top: ${({ theme }) => theme.space.spacing20};
  position: relative;
`;

const StyledImgProxy = styled(ImgProxy)`
  object-fit: contain;
  min-height: ${({ theme }) => theme.sizes.examThumbnailMinHeight};
  width: ${({ theme }) => theme.sizes.examThumbnailMinWidth};
`;

const StyledThumbnailPlaceholder = styled(ThumbnailPlaceholder)`
  object-fit: contain;
  min-height: ${({ theme }) => theme.sizes.examThumbnailMinHeight};
  width: ${({ theme }) => theme.sizes.examThumbnailMinWidth};
`;

const StyledDivCategoryBadge = styled.div`
  background: ${({ theme }) => theme.colors.palette.aquas[5]};
  border-radius: 10px;
  bottom: 4px;
  color: ${({ theme }) => theme.colors.palette.white};
  left: 4px;
  padding: 0 8px;
  position: absolute;
`;

FileSelectSidebar.propTypes = {
  className: PropTypes.string,
  defaultCollapsed: PropTypes.bool,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.number,
      id: PropTypes.number,
      thumbnailUrl: PropTypes.string,
    }),
  ),
  handleAddNewFileClick: PropTypes.func,
  handleFileSelect: PropTypes.func,
  selectedFile: PropTypes.shape({
    id: PropTypes.number,
  }),
  showDeleteBtn: PropTypes.bool,
  showEditBtn: PropTypes.bool,
  onClickYesBtn: PropTypes.func,
  accessToken: PropTypes.string,
  examType: PropTypes.string,
  examId: PropTypes.string,
  hideUploadButton: PropTypes.bool,
  handleQRButtonClick: PropTypes.func,
};

FileSelectSidebar.defaultProps = {
  className: undefined,
  defaultCollapsed: false,
  files: [],
  handleAddNewFileClick: undefined,
  handleFileSelect: undefined,
  selectedFile: null,
  showDeleteBtn: false,
  showEditBtn: false,
  onClickYesBtn: () => {},
  accessToken: '',
  examType: '',
  examId: '',
  hideUploadButton: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleQRButtonClick: undefined,
};

export { FileSelectSidebar };
