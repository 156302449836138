import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { UploadTrackerRoutes } from '../routes';
import { UploadTrackerHome } from './UploadTrackerHome';

export const UploadTracker = () => {
  return (
    <Routes>
      <Route
        path={UploadTrackerRoutes.HOME}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN]}>
            <UploadTrackerHome />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
