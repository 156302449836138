import { FunctionComponent } from 'react';

import { Pager as KendoPager, PagerProps as KendoPagerProps } from '@progress/kendo-react-data-tools';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { PagerProps } from './PagerProps';
import { DEFAULT_PAGE_SIZES } from './constants';

type StyledPagerProps = {
  $disabled: boolean;
  $size?: KendoPagerProps['size'];
  theme: DefaultTheme;
};

export const Pager: FunctionComponent<PagerProps> = ({
  disabled = false,
  pageSizes = DEFAULT_PAGE_SIZES,
  size = ComponentSizes.MEDIUM,
  buttonCount = 7,
  previousNext = true,
  ...rest
}) => {
  return <StyledPager $disabled={disabled} $size={size} pageSizes={pageSizes} buttonCount={buttonCount} previousNext={previousNext} {...rest} />;
};

Pager.displayName = 'Pager';

const resolvePalette = ({ theme, $disabled, $size }: StyledPagerProps) => {
  if ($disabled) {
    return {
      border: $size === ComponentSizes.SMALL ? 'transparent' : theme.colors.borderDisabled,
      text: theme.colors.palette.grayscale[6],
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      iconBackground: theme.colors.palette.white,
      iconBorder: $size === ComponentSizes.SMALL ? 'transparent' : theme.colors.borderDisabled,
      label: theme.colors.textPrimary,
      sizesIcon: theme.colors.palette.grayscale[6],
    };
  }

  return {
    border: $size === ComponentSizes.SMALL ? 'transparent' : theme.colors.borderBase,
    borderActive: theme.colors.palette.grayscale[6],
    text: theme.colors.textPrimary,
    background: theme.colors.palette.white,
    icon: theme.colors.textPrimary,
    iconBackground: theme.colors.palette.white,
    iconBorder: $size === ComponentSizes.SMALL ? 'transparent' : theme.colors.borderBase,
    iconBorderActive: theme.colors.palette.grayscale[6],
    label: theme.colors.textPrimary,
    sizesIcon: theme.colors.palette.grayscale[6],
  };
};

const resolveSelectedPalette = ({ theme, $disabled }: StyledPagerProps) => {
  if ($disabled) {
    return {
      border: theme.colors.borderDisabled,
      text: theme.colors.palette.white,
      background: theme.colors.palette.grayscale[4],
    };
  }

  return {
    border: theme.colors.secondary,
    borderActive: theme.colors.palette.aquas[7],
    text: theme.colors.secondary,
    background: theme.colors.palette.white,
  };
};

function resolveBackgroundColor(props: StyledPagerProps) {
  const { background } = resolvePalette(props);

  return background;
}

function resolveSelectedBackgroundColor(props: StyledPagerProps) {
  const { background } = resolveSelectedPalette(props);

  return background;
}

function resolveColor(props: StyledPagerProps) {
  const { text } = resolvePalette(props);

  return text;
}

function resolveSelectedColor(props: StyledPagerProps) {
  const { text } = resolveSelectedPalette(props);

  return text;
}

function resolveBorderColor(props: StyledPagerProps) {
  const { border } = resolvePalette(props);

  return border;
}

function resolveSelectedBorderColor(props: StyledPagerProps) {
  const { border } = resolveSelectedPalette(props);

  return border;
}

function resolveActiveBorderColor(props: StyledPagerProps) {
  const { borderActive } = resolvePalette(props);

  return borderActive;
}

function resolveSelectedActiveBorderColor(props: StyledPagerProps) {
  const { borderActive } = resolveSelectedPalette(props);

  return borderActive;
}

function resolvePointerEvents({ $disabled }: StyledPagerProps) {
  if ($disabled) {
    return 'none';
  }

  return 'auto';
}

function resolveIconColor(props: StyledPagerProps) {
  const { icon } = resolvePalette(props);

  return icon;
}

function resolveDisabledIconColor(props: StyledPagerProps) {
  const { icon } = resolvePalette({ ...props, $disabled: true });

  return icon;
}

function resolveIconBackgroundColor(props: StyledPagerProps) {
  const { iconBackground } = resolvePalette(props);

  return iconBackground;
}

function resolveDisabledIconBackgroundColor(props: StyledPagerProps) {
  const { iconBackground } = resolvePalette({ ...props, $disabled: true });

  return iconBackground;
}

function resolveIconBorderColor(props: StyledPagerProps) {
  const { iconBorder } = resolvePalette(props);

  return iconBorder;
}

function resolveActiveIconBorderColor(props: StyledPagerProps) {
  const { iconBorderActive } = resolvePalette(props);

  return iconBorderActive;
}

function resolveDisabledIconBorderColor(props: StyledPagerProps) {
  const { iconBorder } = resolvePalette({ ...props, $disabled: true });

  return iconBorder;
}

function resolveLabelColor(props: StyledPagerProps) {
  const { label } = resolvePalette(props);

  return label;
}

function resolvePagerSizesIconColor(props: StyledPagerProps) {
  const { sizesIcon } = resolvePalette(props);

  return sizesIcon;
}

function resolveHeight({ theme, $size }: StyledPagerProps) {
  switch ($size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${$size}".`);
  }
}

function resolveMinWidth({ theme, $size }: StyledPagerProps) {
  switch ($size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve min-width for size "${$size}".`);
  }
}

function resolveMargin({ theme, $size }: StyledPagerProps) {
  switch ($size) {
    case ComponentSizes.SMALL:
      return '0rem';
    case ComponentSizes.MEDIUM:
      return theme.space.spacing10;
    default:
      return theme.space.spacing10;
  }
}

function resolvePagerNumbersPadding({ theme, $size }: StyledPagerProps) {
  switch ($size) {
    case ComponentSizes.SMALL:
      return '0rem';
    case ComponentSizes.MEDIUM:
      return theme.space.spacing10;
    default:
      return theme.space.spacing10;
  }
}

const StyledPager = styled(KendoPager)<{
  $size: ComponentSizes;
  $disabled: boolean;
}>`
  background-color: white;
  border: none;

  & .k-button-flat-primary {
    color: ${({ theme }) => theme.colors.primary};
  }

  & .k-picker {
    background-color: white;
    width: unset;
  }

  &.k-pager-md .k-pager-sizes .k-dropdownlist {
    width: 5.5em;
  }

  & .k-pager-numbers {
    padding: ${resolvePagerNumbersPadding};

    & li {
      margin: ${resolveMargin};

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      & .k-link {
        background-color: ${resolveBackgroundColor};
        border-color: ${resolveBorderColor};
        border-radius: ${({ theme }) => theme.radii.base};
        box-shadow: none;
        color: ${resolveColor};
        font-size: ${({ theme }) => theme.fontSizes.body};
        height: ${resolveHeight};
        line-height: ${({ theme }) => theme.lineHeights.body};
        min-width: ${resolveMinWidth};
        pointer-events: ${resolvePointerEvents};

        &:hover {
          border-color: ${resolveActiveBorderColor};
        }

        &.k-selected {
          background-color: ${resolveSelectedBackgroundColor};
          border-color: ${resolveSelectedBorderColor};
          color: ${resolveSelectedColor};

          &:hover {
            border-color: ${resolveSelectedActiveBorderColor};
          }
        }
      }
    }
  }

  & .k-link.k-pager-nav {
    background-color: ${resolveIconBackgroundColor};
    border-color: ${resolveIconBorderColor};
    border-radius: ${({ theme }) => theme.radii.base};
    box-shadow: none;
    color: ${resolveIconColor};
    font-size: ${({ theme }) => theme.fontSizes.body};
    height: ${resolveHeight};
    line-height: ${({ theme }) => theme.lineHeights.body};
    margin: ${resolveMargin};
    min-width: ${resolveMinWidth};
    pointer-events: ${resolvePointerEvents};

    &:hover {
      border-color: ${resolveActiveIconBorderColor};
    }

    &.k-disabled {
      background-color: ${resolveDisabledIconBackgroundColor};
      border-color: ${resolveDisabledIconBorderColor};
      color: ${resolveDisabledIconColor};
      cursor: default;
      opacity: 1;
    }
  }

  & .k-pager-info.k-label {
    color: ${resolveLabelColor};
  }

  & .k-pager-sizes {
    color: ${resolveLabelColor};
    margin-left: ${({ theme }) => theme.space.spacing30};
    pointer-events: ${resolvePointerEvents};
  }

  & .k-pager-sizes .k-dropdown-wrap {
    background-color: ${resolveBackgroundColor};
    border-color: ${resolveBorderColor};
    color: ${resolveColor};
    font-size: ${({ theme }) => theme.fontSizes.body};
    height: ${resolveHeight};
    line-height: ${({ theme }) => theme.lineHeights.body};

    &:hover {
      background-color: ${resolveBackgroundColor};
      border-color: ${resolveActiveBorderColor};
    }

    &:active,
    &:focus,
    &.k-focus {
      box-shadow: none;
    }

    & .k-input {
      font-size: ${({ theme }) => theme.fontSizes.body};
      height: ${resolveHeight};
      line-height: ${({ theme }) => theme.lineHeights.body};
      padding: ${({ theme }) => theme.space.paddingVerticalMedium} ${({ theme }) => theme.space.spacing30};
    }

    & .k-select {
      color: ${resolvePagerSizesIconColor};

      & .k-icon {
        font-size: ${({ theme }) => theme.fontSizes.body};
        line-height: ${({ theme }) => theme.lineHeights.body};
      }
    }
  }
`;
