import { useContext } from 'react';

import { UploadExamsPageContext } from '../contexts';

export function useUploadExamsPageContext() {
  const context = useContext(UploadExamsPageContext);

  if (context == null) throw new Error('useUploadExamsPageContext must be used within a <UploadExamsPageContext.Provider /> component.');

  return context;
}
