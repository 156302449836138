import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions, createAsyncThunk } from 'core/api';

import { ResultSetRouteService } from '../services';
import { resultSetEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'result-sets/get-all',
  async (_, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(ResultSetRouteService.getBaseRoute()),
    );

    const resultSetsWithName = result.data.map((resultSet) => ({
      ...resultSet,
      description: resultSet.description.trim(),
      value: `${resultSet.acronym}${
        resultSet.description.trim() !== '' ? ` | ${resultSet.description}` : ''
      }`,
    }));

    const normalized = normalize(resultSetsWithName, [resultSetEntity]);

    return { ...normalized, total: result.total };
  },
);

export const ResultSetActions = {
  getAll,
};
