import { ChangeEvent, FocusEvent, Key, KeyboardEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { chevronDownIcon } from '@progress/kendo-svg-icons';
import cn from 'classnames';
import { Overlay } from 'react-bootstrap';
import { mergeRefs } from 'react-merge-refs';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';

import { useBoolean, useConst, useEvent } from 'core/hooks';
import { hasText } from 'core/utils';

import { ErrorMessage } from '../ErrorMessage';
import { Icon } from '../Icon';
import { Input } from '../Input';
import { Label } from '../Label';
import { DropdownNgContextType } from './DropdownNgContextType';
import { DropdownNgHandle } from './DropdownNgHandle';
import { DropdownNgItem } from './DropdownNgItem';
import { DropdownNgProps } from './DropdownNgProps';
import { DropdownNgValue } from './DropdownNgValue';
import { DropdownNgVirtualItem } from './DropdownNgVirtualItem';
import { ItemAsType } from './ItemAsType';
import { DropdownNgContext } from './contexts';
import { usePopperDropdownNg } from './usePopperDropdownNg';

const CHEVRON_ICON_RAW_HTML = { __html: chevronDownIcon.content };
const VIRTUAL_SCROLL_CONTAINER_CLASSNAME = 'virtual-scroll-container';
const VIRTUAL_SCROLL_ITEM_HEIGHT = 24; // In pixels.

export const DropdownNg = forwardRef<DropdownNgHandle, DropdownNgProps>(
  (
    {
      id,
      className,
      name,
      tabIndex,
      disabled = false,
      valid = true,
      visited = false,
      label,
      description,
      required = false,
      validationMessage,
      filterable = true,
      data,
      dataItemKey = 'id',
      textField = 'name',
      value,
      valueAs = DropdownNgValue,
      itemAs = DropdownNgItem as ItemAsType,
      nullItem,
      filterDescriptorResolver,
      textResolver,
      onChange,
      onFocus,
      onBlur,
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);

    const scrollListRef = useRef<FixedSizeList | null>(null);
    const itemButtonRefs = useRef<Map<Key, HTMLElement>>(new Map());
    const suppressNextFocusEvent = useRef(false);
    const [buttonEle, setButtonEle] = useState<HTMLButtonElement | null>(null);
    const [popupContainerEle, setPopupContainerEle] = useState<HTMLElement | null>(null);
    const [popupEle, setPopupEle] = useState<HTMLDivElement | null>(null);
    const [filterEle, setFilterEle] = useState<HTMLInputElement | null>(null);
    const [showPopup, { setTrue: openPopupInternal, setFalse: closePopupInternal }] = useBoolean(false);
    const [filterText, setFilterText] = useState('');

    const filterResolverInternal = useCallback(
      (filterText: string): CompositeFilterDescriptor => {
        if (filterDescriptorResolver != null) return filterDescriptorResolver(filterText);

        return {
          logic: 'or',
          filters: [
            {
              field: textField,
              operator: 'contains',
              value: filterText.trim(),
            },
          ],
        };
      },
      [filterDescriptorResolver, textField],
    );

    const filteredData = useMemo(
      () => [...(nullItem == null ? [] : [nullItem]), ...(hasText(filterText) ? filterBy(data, filterResolverInternal(filterText)) : data)],
      [data, filterResolverInternal, filterText, nullItem],
    );

    const openPopup = useEvent(() => {
      openPopupInternal();
    });

    const closePopup = useEvent((resetPopupStates: boolean) => {
      closePopupInternal();

      if (resetPopupStates) {
        setFilterText('');
      }
    });

    const focusButtonEle = useEvent((suppress: boolean) => {
      if (suppress) {
        suppressNextFocusEvent.current = true;
      }
      buttonEle?.focus();
    });

    const focusFilterEle = useEvent(() => {
      filterEle?.focus();
    });

    const myRef: DropdownNgHandle = useConst(() => ({
      id: id,
      name: name,
      get value() {
        return value?.[dataItemKey] == null ? null : value;
      },
      focus: () => {
        focusButtonEle(false);
      },
    }));

    const { buttonMeasureRef, buttonWidth } = usePopperDropdownNg(buttonEle, popupEle);

    const handleButtonClick = useEvent(() => {
      if (showPopup) {
        closePopup(true);
        setTimeout(() => {
          focusButtonEle(true);
        }, 50);
      } else {
        openPopup();
        setTimeout(() => {
          focusFilterEle();
        });
      }
    });

    const handleButtonFocus = useEvent((event: FocusEvent<HTMLButtonElement>) => {
      // The user experience for this control is to always keep the focus on the filter input text field until the user explicitly clicks or changes focus to
      // something outside the bounds of this component.  So anytime the user interacts with a sub-element (the expand icon, container elements in the dropdown
      // list, etc.) we need to immediately re-focus the input text field at the same position as before.

      // Suppress focus events from propagating when it was programatically emitted (by calling HTMLButtonElement.focus()) from within a handler for some different kind of event.
      if (suppressNextFocusEvent.current) {
        suppressNextFocusEvent.current = false;
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        return;
      }

      onFocus?.(event);
    });

    const handleButtonBlur = useEvent((event: FocusEvent<HTMLButtonElement>) => {
      if (popupEle?.contains(event.relatedTarget) ?? buttonEle?.contains(event.relatedTarget)) {
        if (filterEle?.contains(event.relatedTarget)) {
          // User clicked on the filter input text field.  We only want to suppress the blur event for consumers of this component while
          // also allowing the user to interact with the filter input text field.

          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();

          return;
        }

        // Focus-trap back to the input text field if the blur event was triggered by the user focusing on some child element within this component.
        suppressNextFocusEvent.current = true;
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.currentTarget.focus(); // Focusing an element programatically with focus() will always trigger an onFocus event.
      } else {
        // User clicked somewhere outside the component so we need to close the popup and propagate the blur event upwards.
        closePopup(true);
        onBlur?.(event);
      }
    });

    const handleFilterBlur = useEvent((event: FocusEvent<HTMLInputElement>) => {
      const virtualScrollInnerEle = popupEle?.querySelector(`.${VIRTUAL_SCROLL_CONTAINER_CLASSNAME} > *`); // Get the react-window element that is the direct parent of the virtual scroll items.
      const isFromVirtualScrollItem = virtualScrollInnerEle != event.relatedTarget && (virtualScrollInnerEle?.contains(event.relatedTarget) ?? false); // Only consider the blur event as coming from the virtual scroll list if the related target is not the virtual scroll list itself, but the individual items.
      const isFromPopup = isFromVirtualScrollItem ? true : popupEle?.contains(event.relatedTarget) ?? false;
      const isFromButton = isFromPopup ? false : buttonEle?.contains(event.relatedTarget) ?? false;

      if (isFromVirtualScrollItem) {
        // User clicked on an item in the virtual scroll list.  We want to close the popup, but suprress propagation since we are also bringing focus back to the button.
        suppressNextFocusEvent.current = true;
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        buttonEle?.focus();
      } else if (isFromPopup) {
        // Focus changed to some other element within the popup.  We want to keep the popup open and bring the focus back to the filter input text field.
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        filterEle?.focus();
      } else if (isFromButton) {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        filterEle?.focus();
      } else if (!isFromButton) {
        // User clicked somewhere outside the component so we need to close the popup and propagate the blur event upwards.
        closePopup(true);
        onBlur?.(event);
      }
    });

    const handleItemClick = useEvent((dataItemIndex: number) => {
      const newValue = filteredData[dataItemIndex];

      onChange?.({ target: myRef, value: newValue?.[dataItemKey] == null ? null : newValue });
      closePopup(true);
    });

    const handleFilterChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
      setFilterText(event.currentTarget.value);
    });

    const handleFilterKeyDown = useEvent((event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'ArrowDown') {
        const currentIndex = value == null ? null : filteredData.findIndex((d) => d[dataItemKey] === value[dataItemKey]) ?? null;
        let nextIndex: number | null;

        if (currentIndex == null) {
          nextIndex = filteredData.length > 0 ? 0 : null;
        } else {
          nextIndex = filteredData.length - 1 === currentIndex ? currentIndex : currentIndex + 1;
        }

        event.preventDefault(); // Required to keep the edit caret position stationary.

        if (currentIndex !== nextIndex) {
          const newValue = nextIndex == null ? null : filteredData[nextIndex];
          onChange?.({ target: myRef, value: newValue?.[dataItemKey] == null ? null : newValue });

          setTimeout(() => {
            if (nextIndex != null) {
              scrollListRef.current?.scrollToItem(nextIndex, 'smart');
            }
          });
        }
      } else if (event.key === 'ArrowUp') {
        const currentIndex = value == null ? null : filteredData.findIndex((d) => d[dataItemKey] === value[dataItemKey]) ?? null;
        let nextIndex: number | null;

        if (currentIndex == null) {
          nextIndex = filteredData.length > 0 ? 0 : null;
        } else {
          nextIndex = currentIndex === 0 ? currentIndex : currentIndex - 1;
        }

        event.preventDefault(); // Required to keep the edit caret position stationary.

        if (currentIndex !== nextIndex) {
          const newValue = nextIndex == null ? null : filteredData[nextIndex];
          onChange?.({ target: myRef, value: newValue?.[dataItemKey] == null ? null : newValue });

          setTimeout(() => {
            if (nextIndex != null) {
              scrollListRef.current?.scrollToItem(nextIndex, 'smart');
            }
          });
        }
      } else if (event.key === 'Enter') {
        closePopup(true);
      } else if (event.key === 'Escape') {
        closePopup(true);
        setTimeout(() => {
          focusButtonEle(true);
        }, 50);
      }
    });

    useImperativeHandle(ref, () => myRef, [myRef]);

    useEffect(() => {
      const newContainerEle = document.querySelector<HTMLElement>('body > .modal') ?? document.body;
      setPopupContainerEle(newContainerEle);
    }, []);

    const dropdownContext: DropdownNgContextType = useMemo(
      () => ({
        dataItemKey,
        filteredData,
        value,
        textField,
        itemButtonRefs,
        textResolver: textResolver ?? ((dataItem) => dataItem[textField]?.toString?.()),
        valueAs,
        itemAs,
        onItemClick: handleItemClick,
      }),
      [dataItemKey, filteredData, handleItemClick, value, itemAs, textField, textResolver, valueAs],
    );

    const currentItemIndex = value == null ? null : filteredData.findIndex((d) => d[dataItemKey] === value[dataItemKey]) ?? null;

    return (
      <DropdownNgContext.Provider value={dropdownContext}>
        {label && (
          <Label required={required} editorDisabled={disabled} editorValid={valid} editorId={id} description={description}>
            {label}
          </Label>
        )}
        <StyledDropdownDiv>
          <StyledDropdownButton
            ref={mergeRefs([buttonMeasureRef as (element: HTMLButtonElement) => void, setButtonEle])}
            id={id}
            className={cn(className, { valid, invalid: !valid })}
            type="button"
            name={name}
            tabIndex={tabIndex}
            disabled={disabled}
            onFocus={handleButtonFocus}
            onBlur={handleButtonBlur}
            onClick={handleButtonClick}
          >
            <DropdownNgValue
              dataItem={value}
              dataItemIndex={-1}
              dataItemKey={dataItemKey}
              textField={textField}
              disabled={disabled}
              textResolver={dropdownContext.textResolver}
            />
            <StyledExpandDiv>
              <svg
                viewBox={chevronDownIcon.viewBox}
                width={16}
                height={16}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={CHEVRON_ICON_RAW_HTML}
              />
            </StyledExpandDiv>
          </StyledDropdownButton>
        </StyledDropdownDiv>

        {isValidationMessageShown && <ErrorMessage>{validationMessage}</ErrorMessage>}

        <Overlay target={buttonEle} show={showPopup} placement="bottom-start" container={popupContainerEle}>
          {({
            placement: _placement,
            arrowProps: _arrowProps,
            show: _show,
            popper: _popper,
            hasDoneInitialMeasure: _hasDoneInitialMeasure,
            ...popperDivProps
          }) => (
            <StyledPopupDiv
              {...popperDivProps}
              ref={mergeRefs([popperDivProps.ref, setPopupEle as (element: HTMLElement | null) => void])}
              className={filterable ? 'filter-visible' : undefined}
              data-show={showPopup}
              tabIndex={-1}
            >
              <StyledFilterDiv>
                <Icon icon={faSearch} fixedWidth size="xs" />
                <Input
                  ref={setFilterEle}
                  tabIndex={-1}
                  value={filterText}
                  onChange={handleFilterChange}
                  valid
                  onBlur={handleFilterBlur}
                  onKeyDown={handleFilterKeyDown}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                />
              </StyledFilterDiv>
              <FixedSizeList
                ref={scrollListRef}
                className={VIRTUAL_SCROLL_CONTAINER_CLASSNAME}
                height={VIRTUAL_SCROLL_ITEM_HEIGHT * Math.min(Math.max(5, data.length), 10)} // Intentionally referencing the original "data" array instead of "filteredData" to prevent the scroll list from constantly resizing as the user types in the filter input text field.
                itemCount={filteredData.length}
                itemSize={VIRTUAL_SCROLL_ITEM_HEIGHT}
                width={buttonWidth}
                initialScrollOffset={VIRTUAL_SCROLL_ITEM_HEIGHT * (currentItemIndex ?? 0)}
                // eslint-disable-next-line react/no-children-prop
                children={
                  /* This has to be an actual, stable component.  Otherwise the react-window virtual list will constantly be adding and removing dom elements instead of recycling.  This causes issues when dealing with mouseenter events if elements are actively being hovered while deleted and re-created. */
                  DropdownNgVirtualItem
                }
              />
            </StyledPopupDiv>
          )}
        </Overlay>
      </DropdownNgContext.Provider>
    );
  },
);

DropdownNg.displayName = 'DropdownNg';

const StyledDropdownDiv = styled.div`
  display: flex;
`;

const StyledDropdownButton = styled.button`
  && {
    appearance: none;
    flex: 1 1 0;
    box-sizing: border-box;
    height: 24px;
    display: block flex;
    overflow: hidden;
    align-items: normal;
    padding: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: ${({ theme }) => theme.fontSizes.body};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: ${({ theme }) => theme.lineHeights.body};
    background-color: ${({ theme }) => theme.colors.palette.white};
    border: 1px solid ${({ theme }) => theme.colors.borderBase};
    border-radius: ${({ theme }) => theme.radii.base};
    user-select: none;
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out,
      border-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
  }

  &&:active,
  &&:hover,
  &&:focus,
  &&:focus-visible {
    border-color: ${({ theme }) => theme.colors.palette.aquas[4]};
    outline: none;
  }

  &&:active,
  &&:focus,
  &&:focus-visible {
    box-shadow: ${({ theme }) => theme.shadows.formControlsActive};
  }

  &&[disabled],
  &&[disabled]:active,
  &&[disabled]:hover,
  &&[disabled]:focus,
  &&[disabled]:focus-visible {
    color: ${({ theme }) => theme.colors.textDisabled};
    border-color: ${({ theme }) => theme.colors.borderDisabled};
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    opacity: 0.65;
  }

  &&.invalid,
  &&.invalid:active,
  &&.invalid:hover,
  &&.invalid:focus,
  &&.invalid:focus-visible {
    border-color: ${({ theme }) => theme.colors.error};
  }

  &&.invalid:active,
  &&.invalid:focus,
  &&.invalid:focus-visible {
    box-shadow: ${({ theme }) => theme.shadows.formControlsActiveError};
  }
`;

const StyledExpandDiv = styled.div`
  flex: 0 0 min-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.space.spacing20};

  &:focus,
  &:focus-visible,
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
    border: none;
  }
`;

const StyledPopupDiv = styled.div`
  display: grid;
  background-color: white;
  z-index: 2000;
  overflow: hidden;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.03),
    0 4px 5px 0 rgba(0, 0, 0, 0.04);

  .${VIRTUAL_SCROLL_CONTAINER_CLASSNAME} {
    overscroll-behavior: contain;
  }
`;

const StyledFilterDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  overflow: hidden;
  padding: 12px;
  z-index: 1;

  .icon-container {
    position: absolute;
    top: 12px;
    left: 17px;
    cursor: text;
  }

  input {
    padding-left: 23px;
  }
`;
