import { FunctionComponent } from 'react';

import cn from 'classnames';
import styled, { DefaultTheme } from 'styled-components';

import { TrendProps } from './TrendProps';
import { TrendChanges } from './constants';

export const Trend: FunctionComponent<TrendProps> = ({ changeDirection, label, percentage }) => {
  const trendIconClassname = cn('fa', {
    'fa-arrow-trend-up': changeDirection === TrendChanges.UP,
    'fa-arrow-trend-down': changeDirection === TrendChanges.DOWN,
  });

  return (
    <StyledDivWrapper $changeDirection={changeDirection}>
      {label}
      <StyledDivContent $changeDirection={changeDirection}>
        {trendIconClassname && <StyledSpanIcon className={trendIconClassname} />}
        <div>{percentage}%</div>
      </StyledDivContent>
    </StyledDivWrapper>
  );
};

Trend.displayName = 'Trend';

type StyledElementProps = {
  theme: DefaultTheme;
  $changeDirection: TrendChanges;
};

const resolvePalette = ({ theme, $changeDirection }: StyledElementProps) => {
  if ($changeDirection === TrendChanges.UP) {
    return {
      text: theme.colors.textPrimary,
      trendColor: theme.colors.success,
    };
  }

  if ($changeDirection === TrendChanges.DOWN) {
    return {
      text: theme.colors.textPrimary,
      trendColor: theme.colors.error,
    };
  }

  return {
    text: theme.colors.textPrimary,
    trendColor: theme.colors.palette.grayscale[5],
  };
};

const resolveTrendColor = (props: StyledElementProps) => {
  const { trendColor } = resolvePalette(props);

  return trendColor;
};

const resolveTextColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const StyledDivWrapper = styled.div<{ $changeDirection: TrendChanges }>`
  color: ${resolveTextColor};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  line-height: ${({ theme }) => theme.lineHeights.footnote};
`;

const StyledDivContent = styled.div<{ $changeDirection: TrendChanges }>`
  align-items: center;
  color: ${resolveTrendColor};
  display: flex;
  margin-left: ${({ theme }) => theme.space.spacing20};
`;

const StyledSpanIcon = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.footnote};
`;
