// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/exams`;

const getByIdRoute = (id) => `${getBaseRoute()}/${id}`;

const getByIdAndLockRoute = (id) => `${getBaseRoute()}/${id}/get-and-lock`;

const getForGridRoute = () => `${getBaseRoute()}/grid`;

const getSendDocumentsRoute = () => `${getBaseRoute()}/send-documents`;

const getExamsCount = () => `${getBaseRoute()}/filter-count`;

const getExportToCSVRoute = () => `${getForGridRoute()}/export`;

const getGenerateReportRoute = (id) => `${getByIdRoute(id)}/generate-report`;

const getChangeStatusRoute = (id) => `${getByIdRoute(id)}/status`;

const bulkCancel = () => `${getBaseRoute()}/bulk-cancel`;

const getBulkAssignToPhysiciansRoute = () =>
  `${getBaseRoute()}/bulk-assign-doctors`;

const getNextExamIdRoute = () => `${getBaseRoute()}/next-id`;

const getValidTransitionsRoute = (id) => `${getByIdRoute(id)}/status`;

export const ExamRouteService = {
  getBaseRoute,
  getBulkAssignToPhysiciansRoute,
  getByIdRoute,
  getByIdAndLockRoute,
  getChangeStatusRoute,
  getExamsCount,
  getExportToCSVRoute,
  getForGridRoute,
  getGenerateReportRoute,
  getNextExamIdRoute,
  getSendDocumentsRoute,
  getValidTransitionsRoute,
  bulkCancel,
};
