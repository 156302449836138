import { ComponentType, FunctionComponent } from 'react';

import { Filter as KendoFilter } from '@progress/kendo-react-data-tools';
import styled, { DefaultTheme } from 'styled-components';

import { FilterProps } from './FilterProps';

export const Filter: FunctionComponent<FilterProps> = (props) => {
  return <StyledKendoFilter {...props} />;
};

Filter.displayName = 'Filter';

const resolveBackground = ({ theme }: { theme: DefaultTheme }) =>
  theme.colors.palette.white;

const StyledKendoFilter = styled(
  KendoFilter as unknown as ComponentType<FilterProps>, // TODO: This cast is a workaround for some typing issues in the Kendo Filter definition.
)`
  & .k-button-solid-base {
    background: ${resolveBackground};
    color: ${({ theme }) => theme.colors.palette.grayscale[9]};

    &.k-selected {
      background: ${({ theme }) => theme.colors.palette.white};
      color: ${({ theme }) => theme.colors.palette.aquas[5]};
      border-color: ${({ theme }) => theme.colors.palette.aquas[5]};
    }
  }

  & .k-picker {
    background: ${({ theme }) => theme.colors.palette.white};
    color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  }
`;
