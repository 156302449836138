import { IntegrationModel, LocationModel } from 'models';
import { SourceModel } from 'models/SourceModel';

import { findOrThrow, trimForStorage } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  name: '',
  description: '',
  location: null,
  integration: null,
  hostName: '',
  hostIP: '',
  localIP: '',
  localIPRange: '',
  port: null,
  notes: '',
  sendingAE: '',
  destinationAE: '',
  active: true,
};

function copyModelToForm(model: SourceModel, allLocations: LocationModel[], allIntegrations: IntegrationModel[]): EditFormValues {
  const location =
    model.locationId != null ? findOrThrow(allLocations, (t) => t.id === model.locationId, `Could not find location with id: ${model.locationId}.`) : null;

  const integration =
    model.integrationId != null
      ? findOrThrow(allIntegrations, (i) => i.id === model.integrationId, `Could not find integration with id: ${model.integrationId}.`)
      : null;

  return {
    ...model,
    location,
    integration,
  };
}

function copyFormToModel(sourceId: number, form: EditFormValues): SourceModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { integration, location, ...rest } = form;
  return {
    ...rest,
    id: sourceId,
    integrationId: form.integration == null ? null : form.integration.id,
    locationId: form.location == null ? null : form.location.id,
    port: typeof form.port === 'number' && Number.isNaN(form.port) ? null : form.port,
    name: form.name.trim(),
  };
}

function createDefaultSource(): SourceModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const SourceEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
