import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useBlocker } from 'react-router-dom';

import { useEvent } from 'core/hooks';
import { ConfirmationModal } from 'core/ui';

import { NavigationBlockerProviderContext } from '../contexts';
import { NavigationBlockerProviderContextType } from '../types';

export const NavigationBlockerProvider: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  const [blockPredicate, setBlockPredicate] = useState(false);
  const blocker = useBlocker(blockPredicate);

  const handleDialogConfirm = useEvent(() => {
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  });

  const handleDialogCancel = useEvent(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  });

  const handleWindowBeforeUnload = useEvent((ev: BeforeUnloadEvent) => {
    if (blockPredicate === true) {
      // eslint-disable-next-line no-param-reassign
      ev.returnValue = 'Changes you made will not be saved.';
      return ev.returnValue;
    }

    return undefined;
  });

  useEffect(() => {
    // MDN recommends only listening to this event when we know for sure that we want to block navigation.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    // In short: It may break back/forward browser buttons and negatively impacts performance.
    if (blockPredicate === true) {
      window.addEventListener('beforeunload', handleWindowBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleWindowBeforeUnload);
      };
    }
    return undefined;
  }, [blockPredicate, handleWindowBeforeUnload]);

  const newContext: NavigationBlockerProviderContextType = useMemo(
    () => ({
      setBlockPredicate,
    }),
    [],
  );

  return (
    <NavigationBlockerProviderContext.Provider value={newContext}>
      {children}
      {blocker.state === 'blocked' && (
        <ConfirmationModal
          title="Unsaved Changes"
          onConfirm={handleDialogConfirm}
          onCancel={handleDialogCancel}
        />
      )}
    </NavigationBlockerProviderContext.Provider>
  );
};

NavigationBlockerProvider.displayName = 'NavigationBlockerProvider';
