import { FunctionComponent } from 'react';

import { Field } from 'core/forms';
import { Accordion, DelimitedTextInput, Dropdown } from 'core/ui';

import { AliveCorClinicalCorrelations } from 'features/exam/constants';

import WriteIcon from '../assets/write.svg?react';
import { AliveCorResultsAccordionProps } from '../types';

export const AliveCorResultsAccordion: FunctionComponent<
  AliveCorResultsAccordionProps
> = ({ eventKey }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={WriteIcon} title="Results" />
      <Accordion.Body>
        <Field
          name="ClinicalCorrelation"
          label="Clinical Correlation"
          component={Dropdown}
          data={AliveCorClinicalCorrelations}
          dataItemKey="value"
          filterable={false}
        />
        <Field
          name="overRead"
          component={DelimitedTextInput}
          label="Over-read"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

AliveCorResultsAccordion.displayName = 'AliveCorResultsAccordion';
