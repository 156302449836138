import { FunctionComponent, memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { DestinationRouteRoutes } from '../routes';
import { DestinationRouteForm } from './DestinationRouteForm';
import { DestinationRouteHome } from './DestinationRouteHome';

export const DestinationRoute: FunctionComponent = memo(() => {
  return (
    <Routes>
      <Route
        path={DestinationRouteRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <DestinationRouteHome />
          </RouteGuard>
        }
      />
      <Route
        path={DestinationRouteRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={DestinationRouteHome}>
            <DestinationRouteForm />
          </RouteGuard>
        }
      />
      <Route
        path={DestinationRouteRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={DestinationRouteHome}>
            <DestinationRouteForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

DestinationRoute.displayName = 'DestinationRoute';
