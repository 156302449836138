// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/exams`;

const getByIdRoute = (id) => `${getBaseRoute()}/${id}`;

const getReadRoute = (id) => `${getByIdRoute(id)}/read`;

const getSaveReadRoute = (id) => `${getByIdRoute(id)}/save-read`;

const getApproveReadRoute = (id) => `${getByIdRoute(id)}/approve-read`;

export const ExamReadingRouteService = {
  getByIdRoute,
  getReadRoute,
  getBaseRoute,
  getSaveReadRoute,
  getApproveReadRoute,
};
