import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { TatModel } from 'models';

import { TatActions } from './actions';
import { NormalizedTatModelSchema } from './normalizr-schema';

export const tatAdapter = createEntityAdapter<TatModel>();

export const reducer = createReducer(
  tatAdapter.getInitialState(),
  (builder) => {
    builder.addCase(TatActions.getAll.fulfilled, (state, action) => {
      const payload = action.payload as NormalizedTatModelSchema;
      tatAdapter.upsertMany(state, payload.tats);
    });
  },
);
