import { useContext } from 'react';

import { UploaderContext } from '../contexts';

export function useUploadManager() {
  const context = useContext(UploaderContext);

  if (context == null) throw new Error('<UploadManagerProvider /> has not been defined as an ancestor.');

  return context;
}
