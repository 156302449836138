import { createSelector } from '@reduxjs/toolkit';

import { UnitModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { unitAdapter } from './reducer';

const rootSelector = (state: RootState) => state.units;

const rootGridSelector = (state: RootState) => state.unitsGrid;

const { selectAll: getAll } = unitAdapter.getSelectors(rootSelector);

const { selectAll: getAllGrid } = unitAdapter.getSelectors(rootGridSelector);

const getByIdOutput = (units: UnitModel[], unitId: number | undefined) =>
  units.find((unit) => unit.id === unitId);

const getById = (
  unitId: number | undefined,
): AppSelector<UnitModel | undefined> =>
  createSelector([getAll, () => unitId], getByIdOutput);

const getTotal: AppSelector<number> = (state) => rootSelector(state).total;

const getTotalGrid: AppSelector<number> = (state) =>
  rootGridSelector(state).total;

export const UnitSelectors = {
  getAll,
  getAllGrid,
  getById,
  getTotal,
  getTotalGrid,
};
