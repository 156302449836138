import { FunctionComponent, cloneElement } from 'react';

import { Tooltip as KendoTooltip } from '@progress/kendo-react-tooltip';
import { isElement } from 'react-is';
import styled from 'styled-components';

import { TooltipProps } from './TooltipProps';

export const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  position,
  text,
}) => (
  <StyledTooltipBase
    anchorElement="target"
    position={position}
    setCalloutOnPositionAuto="10px"
  >
    {isElement(children) ? cloneElement(children, { title: text }) : children}
  </StyledTooltipBase>
);

Tooltip.displayName = 'Tooltip';

const StyledTooltipBase = styled(KendoTooltip)`
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));

  && .k-tooltip {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[8]};
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
    color: ${({ theme }) => theme.colors.palette.grayscale[0]};
  }

  && .k-callout {
    color: ${({ theme }) => theme.colors.palette.grayscale[8]};
  }
`;
