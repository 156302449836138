import { createContext } from 'react';

import { Action } from './Action';

export const DataTableExtrasContext = createContext<{
  search: string | null | undefined;
  dataItemKey: string;
}>({
  search: null,
  dataItemKey: '',
});

DataTableExtrasContext.displayName = 'DataTableExtrasContext';

export const DataTableActionsContext = createContext<Action[] | null>(null);

DataTableActionsContext.displayName = 'DataTableActionsContext';
