import { Key, forwardRef } from 'react';

import { mergeRefs } from 'react-merge-refs';
import { ListChildComponentProps } from 'react-window';

import { useDropdownNgItem } from './useDropdownNgItem';

export const DropdownNgVirtualItem = forwardRef<HTMLElement, ListChildComponentProps>(({ index, style }, ref) => {
  const { filteredData, dataItemKey, textField, value, itemButtonRefs, itemAs: ItemAs, textResolver, onItemClick } = useDropdownNgItem();

  return (
    <ItemAs
      ref={mergeRefs([
        ref,
        (node) => {
          if (node) {
            itemButtonRefs.current.set(filteredData[index][dataItemKey] as Key, node);
          } else {
            itemButtonRefs.current.delete(filteredData[index][dataItemKey] as Key);
          }
        },
      ])}
      key={filteredData[index][dataItemKey] as Key}
      style={style}
      dataItem={filteredData[index]}
      dataItemIndex={index}
      dataItemKey={dataItemKey}
      textField={textField}
      selected={value?.[dataItemKey] === filteredData[index][dataItemKey]}
      textResolver={textResolver}
      onClick={onItemClick}
    />
  );
});

DropdownNgVirtualItem.displayName = 'DropdownNgVirtualItem';
