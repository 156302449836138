import { DependencyList, FunctionComponent, memo, useCallback } from 'react';

/** Memoizes dynamic component definitions and properly hydrates the displayName property.  Returns a component that is wrapped in `React.memo()`. */
export function useMemoizedComponent<TProps extends object>(Component: FunctionComponent<TProps>, deps: DependencyList, displayName?: string) {
  const intermediateComponent = memo(Component);

  intermediateComponent.displayName = displayName ?? 'useMemoizedComponent';

  // Disable exhaustive-deps lint warning here because the deps are passed in as a parameter.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const finalComponent = useCallback(intermediateComponent, deps);

  return finalComponent;
}
