import { FunctionComponent, memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { ServiceRoutes } from '../routes';
import { ServiceForm } from './ServiceForm';
import { ServiceHome } from './ServiceHome';

export const Service: FunctionComponent = memo(() => {
  return (
    <Routes>
      <Route
        path={ServiceRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <ServiceHome />
          </RouteGuard>
        }
      />
      <Route
        path={ServiceRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={ServiceHome}>
            <ServiceForm />
          </RouteGuard>
        }
      />
      <Route
        path={ServiceRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={ServiceHome}>
            <ServiceForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

Service.displayName = 'Service';
