import { schema } from 'normalizr';

import { IntegrationModel } from 'models';

export const integrationEntity = new schema.Entity<IntegrationModel>(
  'integrations',
);

export type NormalizedIntegrationModelSchema = {
  integrations: Record<string, IntegrationModel>;
};
