import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  combineValidators,
  createStyledFormElement,
  email,
  maxLength,
  minLength,
  required,
  zip,
} from 'core/forms';
import { Button, Input, Label, MaskedInput } from 'core/ui';

import { ContactTabContentProps, ContactTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  address1: maxLength(255),
  address2: maxLength(255),
  city: maxLength(50),
  state: maxLength(10),
  zip: combineValidators([minLength(5), maxLength(10), zip]),
  email: combineValidators([required, email]),
  smsEmail: email,
};

const ContactTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof ContactTabFormValues) => any;
  onChange: (values: ContactTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      address1: valueGetter('address1'),
      address2: valueGetter('address2'),
      city: valueGetter('city'),
      state: valueGetter('state'),
      zip: valueGetter('zip'),
      phone1: valueGetter('phone1'),
      phone2: valueGetter('phone2'),
      email: valueGetter('email'),
      smsEmail: valueGetter('smsEmail'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="address1">Address 1</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="address1"
            editorId="address1"
            component={Input}
            validator={validators.maxLength}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="address2">Address 2</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="address2"
            editorId="address2"
            component={Input}
            validator={validators.address2}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="city">City</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="city"
            editorId="city"
            component={Input}
            validator={validators.city}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="state">State</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="state"
            editorId="state"
            component={Input}
            validator={validators.state}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="zip">Zip</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="zip"
            editorId="zip"
            component={Input}
            validator={validators.zip}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="phone1">Phone 1</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MaskedInput}
            editorId="phone1"
            mask="(999) 000-0000"
            name="phone1"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="phone2">Phone 2</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MaskedInput}
            editorId="phone2"
            mask="(999) 000-0000"
            name="phone2"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="email">Email</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="email"
            name="email"
            required
            validator={validators.email}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="smsEmail">SMS/Email</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="smsEmail"
            editorId="smsEmail"
            component={Input}
            validator={validators.email}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

ContactTabContentInner.displayName = 'ContactTabContentInner';

export const ContactTabContent: FunctionComponent<ContactTabContentProps> = ({
  initialValues,
  onChange,
  onSubmit,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <ContactTabContentInner valueGetter={valueGetter} onChange={onChange} />
      )}
    />
  );
};

ContactTabContent.displayName = 'ContactTabContent';
