import { forwardRef, memo } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import { hasText } from 'core/utils';

import { FlatIconButtonProps } from './FlatIconButtonProps';

export const FlatIconButton = memo(
  forwardRef<HTMLButtonElement, FlatIconButtonProps>(
    ({ type = 'button', title, children, ...rest }, ref) => {
      return hasText(title) ? (
        <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
          <StyledButton ref={ref} type={type} {...rest}>
            {children}
          </StyledButton>
        </OverlayTrigger>
      ) : (
        <StyledButton ref={ref} type={type} {...rest}>
          {children}
        </StyledButton>
      );
    },
  ),
);

FlatIconButton.displayName = 'FlatIconButton';

const StyledButton = styled.button`
  appearance: none;
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.palette.blues[5]};
`;
