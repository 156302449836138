import {
  FocusEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Tooltip } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Icon, Input } from 'core/ui';

import { ShareLinkSummaryProps } from '../types';

export const ShareLinkSummary: FunctionComponent<ShareLinkSummaryProps> = ({
  className,
  shareUrl,
  onClose,
}) => {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  // const copyButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleShareUrlFocus: FocusEventHandler<HTMLInputElement> = useEvent(
    (event) => {
      event.target.select();
    },
  );

  const handleCopyClick = useEvent(async () => {
    await navigator.clipboard.writeText(shareUrl);
    setShowCopiedTooltip(true);
  });

  // Have to manually close the "Copied!" tooltip when the user clicks somewhere on the screen.  Ideally Kendo would do this for us, but it doesn't have that capability.
  useEffect(() => {
    if (showCopiedTooltip) {
      const closeCallback = () => {
        setShowCopiedTooltip(false);
      };
      // Use a setTimeout because we have to manually close this tooltip when the user clicks somewhere on the document.  That event listener can only be attached
      // to the dom after react has rendered it.
      window.setTimeout(async () => {
        document.addEventListener('click', closeCallback);
      }, 0);

      return () => {
        document.removeEventListener('click', closeCallback);
      };
    }

    return () => {};
  }, [showCopiedTooltip]);

  return (
    <div className={className}>
      <StyledUrlDiv>
        <Input
          name="shareUrl"
          value={shareUrl}
          readOnly
          onFocus={handleShareUrlFocus}
          valid
        />
        <Tooltip
          position="bottom"
          open={showCopiedTooltip}
          anchorElement="target"
          filter={(ele) => ele.id === 'share-link-summary--copy-button'}
          openDelay={0}
        >
          <Button
            id="share-link-summary--copy-button"
            type="button"
            title="Copied!"
            onClick={handleCopyClick}
          >
            <Icon icon={faCopy} />
            Copy
          </Button>
        </Tooltip>
      </StyledUrlDiv>

      <StyledQRCode value={shareUrl} />

      <StyledButtonsContainer>
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </StyledButtonsContainer>
    </div>
  );
};

ShareLinkSummary.displayName = 'ShareLinkSummary';

const StyledUrlDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 8px;
`;

const StyledQRCode = styled(QRCode)`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .icon-container {
    margin-right: 8px;
  }
`;
