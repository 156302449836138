import { FieldCategoryModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class FieldCategoryClient {
  constructor(private httpClient: HttpClient) {
    this.getAllFieldCategories = this.getAllFieldCategories.bind(this);
  }

  public async getAllFieldCategories() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const categories = await this.httpClient.fetchAndParse<
      FieldCategoryModel[]
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/field-category/GetAllFieldCategories`,
        options,
      ),
    );
    return categories;
  }
}
