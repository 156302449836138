import { FunctionComponent } from 'react';

import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import styled from 'styled-components';

import { BodyProps } from './BodyProps';

export const Body: FunctionComponent<BodyProps> = ({ children, className }) => {
  return (
    <StyledAccordionBody className={className}>{children}</StyledAccordionBody>
  );
};

Body.displayName = 'Accordion.Body';

const StyledAccordionBody = styled(BootstrapAccordion.Body)`
  padding: ${({ theme }) => theme.space.spacing10}
    ${({ theme }) => theme.space.spacing40};
`;
