import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { ImageQualityActions } from './actions';

export const imageQualityAdapter = createEntityAdapter();

export const reducer = createReducer(
  imageQualityAdapter.getInitialState(),
  (builder) => {
    builder.addCase(ImageQualityActions.getAll.fulfilled, (state, action) => {
      imageQualityAdapter.upsertMany(state, action.payload.imageQuality);
    });
  },
);
