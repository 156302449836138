const primary = '0px 2px 0px rgba(0, 0, 0, 0.043)';
const secondary = '0px 2px 0px rgba(0, 0, 0, 0.016)';

const formControlsActive = '0px 0px 0px 2px rgba(24, 144, 255, 0.2)';
const formControlsActiveError = '0 0 0 0.2rem rgb(205 10 57 / 25%)';

export const shadows = {
  primary,
  secondary,
  formControlsActive,
  formControlsActiveError,
};

export type Shadows = typeof shadows;
