import { memo } from 'react';

import styled from 'styled-components';

import { ExamGrid } from 'features/exam';
import { DefaultColumnsState } from 'features/exam/constants';

import { MergeStepExamMigrateProps } from '../types';

export const MergeStepExamMigrate = memo<MergeStepExamMigrateProps>(
  ({ gridSrcData, srcDataState, dataStateChangeSrc, gridDestData, destDataState, dataStateChangeDest }) => {
    return (
      <div>
        <StyledExamListTitle>First Donor Exam List:</StyledExamListTitle>
        <ExamGrid
          selectable={false}
          showActionColumn={false}
          featureView="Merge-Donor"
          showExamToolbar={false}
          data={gridSrcData}
          onDataStateChange={dataStateChangeSrc}
          dataState={srcDataState}
          columnsState={DefaultColumnsState}
        />
        <StyledExamListTitle>Second Donor Exam List:</StyledExamListTitle>
        <ExamGrid
          selectable={false}
          showActionColumn={false}
          featureView="Merge-Donor"
          showExamToolbar={false}
          data={gridDestData}
          onDataStateChange={dataStateChangeDest}
          dataState={destDataState}
          columnsState={DefaultColumnsState}
        />
      </div>
    );
  },
);

MergeStepExamMigrate.displayName = 'MergeStepExamMigrate';

const StyledExamListTitle = styled.h3`
  padding-bottom: ${(props) => props.theme.space.spacing50};
  padding-top: ${(props) => props.theme.space.spacing50};
`;
