import { cloneElement } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field, FormValidatorsService } from 'core/forms';
// eslint-disable-next-line import/no-unresolved
import { Dropdown } from 'core/ui';

import ContactsIconSvg from '../assets/contacts.svg?react';
import FaxIconSvg from '../assets/fax.svg?react';
import MailIconSvg from '../assets/mail.svg?react';
import PhoneIconSvg from '../assets/phone.svg?react';

const locationValueRender = (element, value) => {
  if (!value) {
    return element;
  }

  const children = (
    <span>
      {value.code} | {value.name}
    </span>
  );

  return cloneElement(element, { ...element.props }, children);
};

const locationItemRender = (li, itemProps) => {
  const { dataItem } = itemProps;

  const itemChildren = (
    <span>
      {dataItem.code} | {dataItem.name}
    </span>
  );

  return cloneElement(li, li.props, itemChildren);
};

const LocationSelection = ({ data, selectedLocation, onChange }) => {
  return (
    <>
      <Field
        component={Dropdown}
        data={data}
        filterFields={['name', 'code']}
        itemRender={locationItemRender}
        label="Search"
        name="location"
        required
        validator={FormValidatorsService.required}
        valueRender={locationValueRender}
        onChange={onChange}
      />
      {selectedLocation && (
        <>
          <StyledFaxRow>
            {selectedLocation.phone && (
              <StyledCol className="col-6">
                <PhoneIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.phone}</StyledSpanText>
              </StyledCol>
            )}
            {selectedLocation.phone2 && (
              <StyledCol className="col-6">
                <PhoneIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.phone2}</StyledSpanText>
              </StyledCol>
            )}
          </StyledFaxRow>
          <StyledFaxRow>
            {selectedLocation.fax && (
              <StyledCol className="col-6">
                <FaxIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.fax}</StyledSpanText>
              </StyledCol>
            )}
            {selectedLocation.fax2 && (
              <StyledCol className="col-6">
                <FaxIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.fax2}</StyledSpanText>
              </StyledCol>
            )}
          </StyledFaxRow>
          {selectedLocation.email && (
            <StyledRow>
              <StyledCol className="col-12">
                <MailIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.email}</StyledSpanText>
              </StyledCol>
            </StyledRow>
          )}
          {selectedLocation.email2 && (
            <StyledRow>
              <StyledCol className="col-12">
                <MailIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.email2}</StyledSpanText>
              </StyledCol>
            </StyledRow>
          )}
          {selectedLocation.user && (
            <StyledRow>
              <StyledCol className="col-12">
                <ContactsIconSvg width="16px" height="16px" />
                <StyledSpanText>{selectedLocation.user}</StyledSpanText>
              </StyledCol>
            </StyledRow>
          )}
        </>
      )}
    </>
  );
};

const StyledSpanText = styled.span`
  margin-left: ${({ theme }) => theme.space.spacing10};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledFaxRow = styled(Row)`
  //margin-top: ${({ theme }) => theme.space.spacing40};
`;

const StyledRow = styled(Row)`
  //margin-top: ${({ theme }) => theme.space.spacing30};
`;

LocationSelection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  selectedLocation: PropTypes.shape({
    code: PropTypes.number,
    email: PropTypes.string,
    email2: PropTypes.string,
    fax: PropTypes.string,
    fax2: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    phone2: PropTypes.string,
    user: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

LocationSelection.defaultProps = {
  data: [],
  selectedLocation: null,
  onChange: undefined,
};

export { LocationSelection };
