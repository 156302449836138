import { MutableRefObject, useEffect, useRef } from 'react';

import { DialogHandle } from '@progress/kendo-react-dialogs';

import { useEvent } from './use-event';

export function useOutsideClick<T extends HTMLElement | DialogHandle>(onOutsideClick: () => void): MutableRefObject<T | null> {
  const ref = useRef<T | null>(null);

  const handleClickOutside = useEvent((event: MouseEvent) => {
    if (ref.current == null || event.target == null || !(event.target instanceof Node)) {
      return;
    }

    if (isDialogHandle(ref.current)) {
      const modalEle = ref.current.element?.querySelector(':scope > .k-dialog') ?? ref.current.element?.querySelector(':scope .k-popup') ?? null;

      if (modalEle != null && !modalEle.contains(event.target)) {
        onOutsideClick();
      }
    } else if (!ref.current.contains(event.target)) {
      onOutsideClick();
    }
  });

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [handleClickOutside]);

  return ref;
}

function isDialogHandle(value: HTMLElement | DialogHandle | null | undefined): value is DialogHandle {
  return value != null && 'element' in value;
}
