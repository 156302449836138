import { ChangeEvent, FocusEvent, memo, useCallback, useMemo } from 'react';

import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';

import { Input } from './Input';
import { InputFieldProps } from './InputFieldProps';

export const InputField = memo<InputFieldProps>(
  ({ name, required, validator, type, onChange, onBlur, ...rest }) => {
    const validatorInternal = useMemo(() => {
      const newValidators: Validate<unknown, unknown>[] = [];

      if (required) newValidators.push(RhfValidators.required);
      if (validator) newValidators.push(validator);

      return newValidators.length === 0
        ? undefined
        : newValidators.length === 1
        ? newValidators[0]
        : RhfValidators.combine(newValidators);
    }, [required, validator]);

    const {
      field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
      formState,
      fieldState: { isTouched, invalid, error },
    } = useController({
      name,
      rules: {
        validate: validatorInternal, // Intentionally using a custom validator for "required" because the browser native version will allow string values with all whitespace.
      },
    });

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
          rhfOnChange(event.target.valueAsNumber);
        } else {
          rhfOnChange(event);
        }

        onChange?.(event);
      },
      [onChange, rhfOnChange, type],
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        rhfOnBlur();
        onBlur?.(event);
      },
      [onBlur, rhfOnBlur],
    );

    return (
      <Input
        {...fieldRest}
        {...rest}
        onChange={handleChange}
        onBlur={handleBlur}
        type={type}
        valid={!invalid}
        visited={formState.isSubmitted || isTouched}
        required={required}
        validationMessage={error?.message}
      />
    );
  },
);

InputField.displayName = 'InputField';
