import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  createStyledFormElement,
  maxLength,
} from 'core/forms';
import { Button, Input, Label } from 'core/ui';

import { CredentialsTabContentProps, CredentialsTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  npi: maxLength(10),
};

const CredentialsTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof CredentialsTabFormValues) => any;
  onChange: (values: CredentialsTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      npi: valueGetter('npi'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="npi">NPI</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="npi"
            editorId="npi"
            component={Input}
            validator={validators.npi}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

CredentialsTabContentInner.displayName = 'CredentialsTabContentInner';

export const CredentialsTabContent: FunctionComponent<
  CredentialsTabContentProps
> = ({ initialValues, onChange, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <CredentialsTabContentInner
          valueGetter={valueGetter}
          onChange={onChange}
        />
      )}
    />
  );
};

CredentialsTabContent.displayName = 'CredentialsTabContent';
