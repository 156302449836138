import {
  AsyncThunkPayloadCreator,
  createAsyncThunk as createAsyncThunkToolkit,
} from '@reduxjs/toolkit';

import { AppDispatch } from 'features/main/redux';

type AsyncThunkConfig = {
  dispatch?: AppDispatch;
  rejectValue?: unknown;
};

export const createAsyncThunk = <T, U>(
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  handler: AsyncThunkPayloadCreator<T | unknown, U>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  createAsyncThunkToolkit<T | unknown, U, AsyncThunkConfig>(
    type,
    async (arg, thunkApi) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dispatch = (action: any) =>
          typeof action === 'function'
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              thunkApi.dispatch(action).unwrap()
            : thunkApi.dispatch(action);

        // eslint-disable-next-line @typescript-eslint/return-await
        return await handler(arg, { ...thunkApi, dispatch });
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    },
  );
