import { normalize } from 'normalizr';

import { GroupModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { NormalizedGroupModelSchema } from 'features/group/redux/normalizr-schema';

import { ServiceRouteService } from '../services';
import { serviceEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'services/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(ServiceRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<GroupModel>;

    const normalized = normalize<GroupModel, NormalizedGroupModelSchema>(
      result,
      [serviceEntity],
    );

    return normalized.entities;
  },
);

export const ServiceActions = {
  getAll,
};
