import { forwardRef, useMemo } from 'react';

import { TextArea as KendoTextArea, TextAreaHandle as KendoTextAreaHandle, TextAreaProps as KendoTextAreaProps } from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { hasText } from 'core/utils';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { WarningMessage } from '../WarningMessage';
import { ComponentSizes } from '../constants';
import { TextAreaProps } from './TextAreaProps';

export const TextArea = forwardRef<KendoTextAreaHandle, TextAreaProps>(
  (
    {
      autoSize = true,
      isOptionalLabelShown = false,
      size = ComponentSizes.MEDIUM,
      description,
      disabled,
      hint,
      label,
      maxLength,
      name,
      id,
      required,
      valid,
      validationMessage,
      value,
      visited,
      fixedErrorHeight = false,
      warning,
      ...rest
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    const charCount = useMemo(() => {
      if (value == null || typeof value === 'string') {
        return value == null ? 0 : value.length;
      }
      return Array.isArray(value) ? value.map((v) => v.length).reduce((previousLength, itemLength) => previousLength + itemLength, 0) : 0;
    }, [value]);

    return (
      <StyledTextAreaWrapper>
        {label && (
          <Label
            description={description}
            editorId={id || name}
            editorValid={valid}
            editorDisabled={disabled}
            required={required}
            optional={isLabeledAsOptional}
          >
            {label}
          </Label>
        )}
        <StyledTextAreaBoxBase
          ref={ref}
          {...rest}
          autoSize={autoSize}
          disabled={disabled}
          maxLength={maxLength}
          name={name}
          id={id || name}
          size={size}
          valid={valid}
          value={value}
        />
        {(isHintShown || isValidationMessageShown || hasText(warning) || maxLength || fixedErrorHeight) && (
          <StyledTextAreaFooter>
            {isHintShown && <Hint id={hintId}>{hint}</Hint>}
            {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
            {hasText(warning) && <WarningMessage>{warning}</WarningMessage>}
            {maxLength && (
              <StyledCharLimitHint direction="end">
                {charCount} / {maxLength}
              </StyledCharLimitHint>
            )}
          </StyledTextAreaFooter>
        )}
      </StyledTextAreaWrapper>
    );
  },
);

TextArea.displayName = 'TextArea';

type StyledElementProps = {
  theme: DefaultTheme;
  size?: KendoTextAreaProps['size'];
  valid?: boolean;
  disabled?: boolean;
};

const resolvePalette = ({ theme, disabled, valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      borderActive: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      placeholderText: theme.colors.textDisabled,
      background: theme.colors.backgroundDisabled,
      caret: theme.colors.textDisabled,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    text: theme.colors.textPrimary,
    placeholderText: theme.colors.textSecondary,
    background: theme.colors.palette.white,
    caret: theme.colors.textPrimary,
  };

  if (!valid) {
    result.border = theme.colors.error;
    result.borderActive = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolvePlaceholderColor = (props: StyledElementProps) => {
  const { placeholderText } = resolvePalette(props);

  return placeholderText;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({ theme, valid }: StyledElementProps) => {
  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveInputCaretColor = (props: StyledElementProps) => {
  const { caret } = resolvePalette(props);

  return caret;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing20}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing40}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing40}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing40}`;
  }
};

const StyledTextAreaBoxBase = styled(KendoTextArea)<StyledElementProps>`
  && .k-input {
    caret-color: ${resolveInputCaretColor};
    color: ${resolveColor};
    font-size: ${resolveFontSize};
    line-height: ${resolveLineHeight};
    padding: ${resolvePadding};

    &::placeholder {
      color: ${resolvePlaceholderColor};
    }
  }

  &.k-textarea {
    background-color: ${resolveBackgroundColor};
    border-color: ${resolveBorderColor};
    border-radius: ${({ theme }) => theme.radii.base};
    border-width: ${({ theme }) => theme.borderWidths.base};
    min-height: 45px;
    width: 100%;

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      border-color: ${resolveActiveBorderColor};
      border-radius: ${({ theme }) => theme.radii.base};
      border-width: ${({ theme }) => theme.borderWidths.base};
    }

    &:active,
    &:focus,
    &:focus-within {
      box-shadow: ${resolveActiveBoxShadow};
    }

    & > textarea {
      ${({ autoSize }) => (autoSize ? 'height: unset !important;' : 'min-height: 45px;')}
    }
  }

  &.k-disabled {
    filter: none;
    opacity: 1;
  }
`;

const StyledTextAreaWrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

const StyledTextAreaFooter = styled.div`
  max-width: 100%;
  min-height: 17px; // Reserve space for validation error messages to avoid reflows.
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledCharLimitHint = styled(Hint)`
  white-space: nowrap;
  margin-left: auto;
`;
