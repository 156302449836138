import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class SendQueueClient {
  constructor(private httpClient: HttpClient) {
    this.getAllForKendoGrid = this.getAllForKendoGrid.bind(this);
    this.requeue = this.requeue.bind(this);
  }

  public async getAllForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/sendqueues/grid?${queryStr}`,
        options,
      ),
    );
    return result;
  }

  public async requeue(ids: number[]) {
    const options = await this.httpClient.createStandardOptions('PUT', ids);
    await this.httpClient.fetch(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/sendqueues/re-queue`,
        options,
      ),
    );
  }
}
