import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { UnitModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { UnitRouteService } from '../services';
import { NormalizeUnitModelSchema, unitEntity } from './normalizr-schema';

const add = createAsyncThunk(
  'units/add',
  async (unit: UnitModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: UnitRouteService.getBaseRoute(),
        body: unit,
      }),
    )) as unknown as ApiResponse<UnitModel>;

    const normalized = normalize<UnitModel, NormalizeUnitModelSchema>(
      { ...unit, id: result.id },
      unitEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'units/edit',
  async (unit: UnitModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: UnitRouteService.getBaseRoute(),
        body: unit,
      }),
    );

    const normalized = normalize<UnitModel, NormalizeUnitModelSchema>(
      unit,
      unitEntity,
    );

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'units/get-by-id',
  async (unitId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(UnitRouteService.getByIdRoute(unitId)),
    )) as unknown as ApiResponse<UnitModel>;

    return result;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'units-grid/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = UnitRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [unitEntity]);

    return { ...normalized, total: result.total };
  },
);

const getAll = createAsyncThunk('units/get-all', async (_, { dispatch }) => {
  const { result } = (await dispatch(
    ApiActions.get(UnitRouteService.getAllRoute()),
  )) as unknown as ApiResponse<UnitModel>;

  const normalized = normalize<UnitModel, NormalizeUnitModelSchema>(result, [
    unitEntity,
  ]);

  return normalized.entities;
});

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'units/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = UnitRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<UnitModel>;

    return result;
  },
);

export const UnitActions = {
  add,
  edit,
  getById,
  getAllForExport,
  getAllForKendoGrid,
  getAll,
};
