import { forwardRef } from 'react';

import { animated } from '@react-spring/web';
import cn from 'classnames';
import styled from 'styled-components';

import { ItemBodyAnimationDivProps } from './ItemBodyAnimationDivProps';

/** Wraps the accordion body in order to enable the expand/collapse effect. */
export const ItemBodyAnimationDiv = forwardRef<
  HTMLDivElement,
  ItemBodyAnimationDivProps
>(({ className, mode = 'vertical', ...rest }, ref) => (
  <StyledItemBodyAnimationDiv
    ref={ref}
    className={cn(className, ItemBodyAnimationDiv.displayName, {
      horizontal: mode === 'horizontal',
      vertical: mode === 'vertical',
    })}
    {...rest}
  />
));

ItemBodyAnimationDiv.displayName = 'ItemBodyAnimationDiv';

const StyledItemBodyAnimationDiv = styled(animated.div)`
  display: flex;
  overflow: hidden;
  justify-content: end; // This makes the body animation appear as if it slides in and out of the header.  Otherwise it'll be more of a reveal/curtain effect.

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
`;
