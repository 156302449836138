import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/WorklistView`;

const getByIdRoute = (id: string) => `${getBaseRoute()}/${id}`;

const getCountsRoute = (ids: string[]) =>
  `${getBaseRoute()}/status?${ids.map((id) => `&ids=${id}`).join('')}`;

export const WorklistRouteService = {
  getBaseRoute,
  getByIdRoute,
  getCountsRoute,
};
