import { memo } from 'react';

import styled from 'styled-components';

import { DropdownNgValueProps } from './DropdownNgValueProps';

export const DropdownNgValue = memo<DropdownNgValueProps>(({ className, dataItem, textResolver }) => {
  return (
    <StyledValueDiv className={className}>
      <StyledValueSpan>{dataItem == null ? 'No selection' : textResolver(dataItem)}</StyledValueSpan>
    </StyledValueDiv>
  );
});

DropdownNgValue.displayName = 'DropdownNgValue';

const StyledValueDiv = styled.div`
  flex: 1 1 0;
  display: flex;
  overflow: hidden;
  text-align: left;
  padding: ${({ theme }) => `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`};
  padding-right: 0;
`;

const StyledValueSpan = styled.span`
  flex: 1 1 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
