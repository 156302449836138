import { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { UploadExamsRoutes } from '../routes';
import { UploadExamsPage } from './UploadExamsPage';

const UPLOAD_EXAMS_ROLES = [UserRoles.ADMIN, UserRoles.CUSTOMER, UserRoles.CUSTOMER_ADMIN, UserRoles.SUPPORT];

export const UploadExams = memo(() => {
  return (
    <Routes>
      <Route
        path={UploadExamsRoutes.UPLOAD_EXAMS}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES} requireSessionLocation>
            <UploadExamsPage />
          </RouteGuard>
        }
      />
      <Route
        path={UploadExamsRoutes.UPLOAD_EXAMS_FOR_PATIENT}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES} requireSessionLocation>
            <UploadExamsPage />
          </RouteGuard>
        }
      />
      <Route
        path={UploadExamsRoutes.UPLOAD_EXAMS_FOR_EXAM}
        element={
          <RouteGuard allowedRoles={UPLOAD_EXAMS_ROLES} requireSessionLocation>
            <UploadExamsPage />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

UploadExams.displayName = 'UploadExams';
