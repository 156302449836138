import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions, createAsyncThunk } from 'core/api';

import { ImageQualityRouteService } from '../services';
import { imageQualityEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'image-quality/get-all',
  async (_, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(ImageQualityRouteService.getBaseRoute()),
    );

    const normalized = normalize(result, [imageQualityEntity]);

    return normalized.entities;
  },
);

export const ImageQualityActions = {
  getAll,
};
