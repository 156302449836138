import { FunctionComponent } from 'react';

import { Badge as KendoBadge, BadgeProps as KendoBadgeProps } from '@progress/kendo-react-indicators';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { BadgeProps } from './BadgeProps';
import { BadgeFills, BadgeTypes } from './constants';

export const Badge: FunctionComponent<BadgeProps> = ({
  rounded,
  fill = BadgeFills.SOLID,
  size = ComponentSizes.MEDIUM,
  text = '',
  type = BadgeTypes.DEFAULT,
}) => {
  return (
    <StyledBadgeBase fill={fill} rounded={rounded} size={size} $type={type}>
      {text}
    </StyledBadgeBase>
  );
};

Badge.displayName = 'Badge';

type StyledBadgeBaseProps = {
  $type: BadgeProps['type'];
  fill: KendoBadgeProps['fillMode'];
  size: KendoBadgeProps['size'];
  rounded: KendoBadgeProps['rounded'];
  theme: DefaultTheme;
};

const resolvePalette = ({ theme, fill, $type }: StyledBadgeBaseProps) => {
  let result: { text: string; background?: string; border?: string };

  switch ($type) {
    case BadgeTypes.DEFAULT: {
      result = {
        background: theme.colors.palette.grayscale[3],
        border: 'transparent',
        text: theme.colors.textPrimary,
      };
      break;
    }
    case BadgeTypes.ERROR: {
      result = {
        background: theme.colors.error,
        border: 'transparent',
        text: theme.colors.palette.white,
      };
      break;
    }
    case BadgeTypes.WARNING: {
      result = {
        background: theme.colors.warning,
        border: 'transparent',
        text: theme.colors.palette.white,
      };
      break;
    }
    case BadgeTypes.SUCCESS: {
      result = {
        background: theme.colors.success,
        border: 'transparent',
        text: theme.colors.palette.white,
      };
      break;
    }
    case BadgeTypes.PROCESSING: {
      result = {
        background: theme.colors.secondary,
        border: 'transparent',
        text: theme.colors.palette.white,
      };
      break;
    }
    case BadgeTypes.COUNT: {
      result = {
        background: theme.colors.palette.grayscale[7],
        text: theme.colors.palette.white,
      };
      return result; // TODO: Not sure why this is the only case statement that returns early.  Would be nice to have a comment here with an explanation.
    }
    default:
      result = {
        background: theme.colors.palette.grayscale[3],
        border: 'transparent',
        text: theme.colors.textPrimary,
      };
  }

  if (fill === BadgeFills.OUTLINE) {
    result = {
      border: 'background' in result ? result.background : undefined,
      background: theme.colors.palette.white,
      text: theme.colors.textPrimary,
    };
  }

  return result;
};

const resolveSizeAndSpacing = ({ theme, size }: StyledBadgeBaseProps) => {
  if (size === ComponentSizes.SMALL) {
    return {
      width: theme.sizes.xSmall,
      height: theme.sizes.xSmall,
      padding: theme.space.spacing10,
    };
  }

  return {
    width: theme.sizes.badgeWidthDefault,
    height: theme.sizes.badgeHeightDefault,
    padding: theme.space.badgePaddingDefault,
  };
};

const resolveBadgeHeight = (props: StyledBadgeBaseProps) => {
  const { height } = resolveSizeAndSpacing(props);

  return height;
};

const resolveBadgeMinWidth = (props: StyledBadgeBaseProps) => {
  const { width } = resolveSizeAndSpacing(props);

  return width;
};

const resolveBackgroundColor = (props: StyledBadgeBaseProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveTextColor = (props: StyledBadgeBaseProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolvePadding = (props: StyledBadgeBaseProps) => {
  const { padding } = resolveSizeAndSpacing(props);

  return padding;
};

const resolveBorderColor = (props: StyledBadgeBaseProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const StyledBadgeBase = styled(KendoBadge)<{
  $type: BadgeProps['type'];
  fill: KendoBadgeProps['fillMode'];
  size: KendoBadgeProps['size'];
  rounded: KendoBadgeProps['rounded'];
}>`
  &.k-badge {
    background-color: ${resolveBackgroundColor};
    border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
    border-radius: ${({ theme }) => theme.radii.scale[4]};
    color: ${resolveTextColor};
    font-size: ${({ theme }) => theme.fontSizes.footnote};
    height: ${resolveBadgeHeight};
    line-height: ${({ theme }) => theme.lineHeights.footnote};
    min-width: ${resolveBadgeMinWidth};
    padding: 0rem ${resolvePadding} !important;
    width: fit-content;
  }

  td & {
    position: relative;
    transform: none;
  }
`;
