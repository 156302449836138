/** Simple event channel to subscribe to events. */
export type IEventStreamConsumer<T> = {
  readonly subscribe: (callback: (event: T) => void) => () => void;
};

/** Simple event channel that will execute all subscriber callbacks synchronously as soon as emit() is called. */
export class EventStream<TEventType> implements IEventStreamConsumer<TEventType> {
  /** Array of current subscribers to the event channel. */
  private subscribers: ((event: TEventType) => void)[] = [];

  constructor() {
    this.subscribe = this.subscribe.bind(this);
    this.emit = this.emit.bind(this);
    this.clear = this.clear.bind(this);
  }

  /** Subscribe to events.  When events are emitted the subscriber callbacks are executed in order of who subscribed first.
   * @returns Unsubscribe callback.
   */
  public subscribe(callback: (event: TEventType) => void): () => void {
    this.subscribers.push(callback);

    return () => {
      const index = this.subscribers.findIndex((c) => c === callback);

      if (index >= 0) this.subscribers.splice(index, 1);
    };
  }

  /** Emit the event to all subscribed listeners.  When events are emitted the subscriber callbacks are executed in order of who subscribed first.
   * @returns Array of unhandled errors thrown by each subscriber callback.
   */
  public emit(event: TEventType): unknown[] {
    const errors: unknown[] = [];

    for (const subscriber of this.subscribers) {
      try {
        subscriber(event);
      } catch (ex) {
        errors.push(ex);
      }
    }

    return errors;
  }

  /** Clear the list of subscribers. */
  public clear() {
    this.subscribers = [];
  }
}
