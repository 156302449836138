import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { LocationTypeModel } from 'models';

import { LocationTypeStatus } from '../constants';
import { LocationTypeActions } from './actions';

export const locationTypeAdapter = createEntityAdapter<LocationTypeModel>();

const getReducerInitialState = () => ({
  ...locationTypeAdapter.getInitialState(),
  total: 0,
  status: LocationTypeStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(LocationTypeActions.getAll.fulfilled, (state, action) => {
    const payload = action.payload as { locationTypes: LocationTypeModel[] };

    locationTypeAdapter.upsertMany(state, payload.locationTypes);
  });
  builder.addCase(LocationTypeActions.getById.fulfilled, (state, action) => {
    const payload = action.payload as LocationTypeModel;

    locationTypeAdapter.upsertOne(state, payload);
    state.status = LocationTypeStatus.FETCHED;
  });
  builder.addCase(LocationTypeActions.add.fulfilled, (state, action) => {
    const payload = action.payload as { locationTypes: LocationTypeModel };

    locationTypeAdapter.addOne(state, payload.locationTypes);
    state.status = LocationTypeStatus.ADDED;
  });
  builder.addCase(LocationTypeActions.edit.fulfilled, (state, action) => {
    const payload = action.payload as {
      locationTypes: Record<number, LocationTypeModel>;
    };

    Object.values(payload.locationTypes).forEach((locationType) => {
      locationTypeAdapter.setOne(state, locationType);
    });

    state.status = LocationTypeStatus.UPDATED;
  });
});

export const reducerGrid = createReducer(
  getReducerInitialState(),
  (builder) => {
    builder.addCase(
      LocationTypeActions.getAllForKendoGrid.fulfilled,
      (state, action) => {
        const payload = action.payload as {
          entities: { locationTypes: LocationTypeModel[] };
          total: number;
          result: EntityId[];
        };

        locationTypeAdapter.removeAll(state);

        if (payload.entities.locationTypes) {
          locationTypeAdapter.addMany(state, payload.entities.locationTypes);
        }

        state.total = payload.total;
        state.ids = payload.result;
        state.status = LocationTypeStatus.FETCHED;
      },
    );
  },
);
