import { useCallback, useMemo } from 'react';

import { ChipListChangeEvent } from '@progress/kendo-react-buttons';
import _map from 'lodash/map';

import { Organs } from 'features/exam/constants';

import { ExamFilter } from '../types';

const ALL_ORGANS = _map(Organs, (o) => ({ name: o.name, value: o.value })).sort(
  (lhs, rhs) =>
    lhs.name.localeCompare(rhs.name, 'en-US', { sensitivity: 'base' }),
);

export function useFiltersState(
  studyTypes: string[],
  organs: number[],
  filter: ExamFilter,
  onFilterChange: (newFilter: ExamFilter) => void,
) {
  const studyTypeOptions = useMemo(
    () =>
      studyTypes
        .sort((lhs, rhs) =>
          lhs.localeCompare(rhs, 'en-US', { sensitivity: 'base' }),
        )
        .map((st) => ({ name: st, value: st })),
    [studyTypes],
  );

  const organOptions = useMemo(
    () => ALL_ORGANS.filter((o) => organs.includes(o.value)),
    [organs],
  );

  const handleStudyTypeChipChange = useCallback(
    (event: ChipListChangeEvent) => {
      const newFilter: ExamFilter = {
        studyTypes: event.value,
        organs: filter.organs,
      };
      onFilterChange(newFilter);
    },
    [filter.organs, onFilterChange],
  );

  const handleOrganChipChange = useCallback(
    (event: ChipListChangeEvent) => {
      const newFilter: ExamFilter = {
        studyTypes: filter.studyTypes,
        organs: event.value,
      };
      onFilterChange(newFilter);
    },
    [filter.studyTypes, onFilterChange],
  );

  return {
    studyTypeOptions,
    organOptions,
    handleStudyTypeChipChange,
    handleOrganChipChange,
  };
}
