import { forwardRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import styled from 'styled-components';

import { IconProps } from './IconProps';

export const Icon = forwardRef<SVGSVGElement, IconProps>(({ className, block = false, fixedWidth = true, ...rest }, ref) => {
  const classes = cn('icon-container', className, {
    'fixed-width': fixedWidth,
  });
  const Container = block ? StyledIconContainerDiv : StyledIconContainerSpan;

  return (
    <Container className={classes}>
      <FontAwesomeIcon ref={ref} fixedWidth={fixedWidth} {...rest} />
    </Container>
  );
});

Icon.displayName = 'Icon';

const StyledIconContainerSpan = styled.span`
  // We need to set the parent element's font-size to exactly 16px because Font Awesome for some reason uses the "em" unit for sizing.  This causes unpredictable
  // rendering.  So it's best to just set the font-size to 16px now and then adjust icon sizes via the built-in sizing options Font Awesome provides.
  && {
    // Need to use double ampersand specificity to override some styles that are sometimes applied from parent components.
    font-size: 16px;
  }

  // Add a standard margin when the icon is used in a button.
  .k-button > .k-button-text > &:not(.fixed-width) {
    margin-right: 8px;
  }
`;

const StyledIconContainerDiv = styled.div`
  // We need to set the parent element's font-size to exactly 16px because Font Awesome for some reason uses the "em" unit for sizing.  This causes unpredictable
  // rendering.  So it's best to just set the font-size to 16px now and then adjust icon sizes via the built-in sizing options Font Awesome provides.
  && {
    // Need to use double ampersand specificity to override some styles that are sometimes applied from parent components.
    font-size: 16px;
  }

  .k-button > .k-button-text > &:not(.fixed-width) {
    margin-right: 8px;
  }
`;
