import { useCallback, useState } from 'react';

import { WorklistEditService } from 'features/worklist/services';
import { WorklistColumnSelectionChange } from 'features/worklist/types';

export function useColumnsGridState(initialColumnsState: string | null | undefined) {
  const [columnsState, setColumnsStateInternal] = useState(() => WorklistEditService.initializeWorklistEditModalColumnsState(initialColumnsState));

  const setColumnsState = useCallback(
    (changes: WorklistColumnSelectionChange[]) => {
      const newColumnsState = { ...columnsState };

      for (const change of changes) {
        newColumnsState[change.column.field] = change.columnState;
      }

      setColumnsStateInternal(newColumnsState);
    },
    [columnsState],
  );

  return { columnsState, setColumnsState };
}
