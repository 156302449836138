import { memo, useCallback, useEffect, useMemo } from 'react';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import styled from 'styled-components';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { RHF_FULL_RESET } from 'core/forms/constants';
import {
  Button,
  ButtonVariants,
  DropdownField,
  FlatIconButton,
  Icon,
  InputField,
  Label,
} from 'core/ui';

import {
  ACCOUNT_PRICING_VALIDATORS,
  PRICING_RULE_INITIAL_VALUES,
} from '../constants';
import { AccountEditService } from '../services';
import { RuleEditorFormValues, RuleEditorProps } from '../types';

export const RuleEditor = memo<RuleEditorProps>(
  ({
    className,
    rules,
    allItems,
    allFormFields,
    selectedIndex,
    onRuleDelete,
    onRuleSave,
    onClose,
  }) => {
    const editMode =
      selectedIndex == null
        ? null
        : selectedIndex === 'new'
        ? 'new'
        : 'existing';

    const rhfContext = useForm<RuleEditorFormValues>({
      defaultValues: PRICING_RULE_INITIAL_VALUES,
    });

    const { reset } = rhfContext;

    const selectedItem = useWatch({
      name: 'item',
      control: rhfContext.control,
    });
    const selectedField = useWatch({
      name: 'field',
      control: rhfContext.control,
    });

    const itemOptions = useMemo(
      () =>
        AccountEditService.filterItemOptions(
          selectedField,
          allItems,
          rules.fields,
          editMode,
        ),
      [allItems, editMode, rules.fields, selectedField],
    );

    const fieldOptions = useMemo(
      () =>
        AccountEditService.filterFieldOptions(
          selectedItem,
          allFormFields,
          rules.fields,
          editMode,
        ),
      [allFormFields, editMode, rules.fields, selectedItem],
    );

    const handleSubmit: SubmitHandler<RuleEditorFormValues> = useCallback(
      (data) => {
        if (data.field == null || data.item == null) {
          throw new Error('Invalid form values.');
        }

        onRuleSave(data.field, data.item, data.value);
      },
      [onRuleSave],
    );

    const handleDeleteClick = useCallback(() => {
      onRuleDelete();
    }, [onRuleDelete]);

    useEffect(() => {
      const newFormValues = AccountEditService.copyRulesToForm(
        rules,
        selectedIndex,
        allItems,
        allFormFields,
      );
      reset(newFormValues, RHF_FULL_RESET);
    }, [allFormFields, allItems, reset, rules, selectedIndex]);

    if (selectedIndex == null) return null;

    return (
      <FormProvider {...rhfContext}>
        <StyledRuleEditorDiv className={className}>
          <StyledTopToolbarDiv>
            <StyledCloseButton title="Close" onClick={onClose}>
              <Icon icon={faXmark} fixedWidth block />
            </StyledCloseButton>
          </StyledTopToolbarDiv>
          <StyledForm
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            noValidate
            onSubmit={rhfContext.handleSubmit(handleSubmit)}
          >
            <GridColumn columnStart="1" isLabelColumn>
              <Label editorId="item" required>
                Item
              </Label>
            </GridColumn>
            <GridColumn columnStart="2">
              <FieldContainer $hideLabel>
                <DropdownField
                  name="item"
                  data={itemOptions}
                  dataItemKey="id"
                  textField="name"
                  required
                  disabled={editMode === 'existing'}
                />
              </FieldContainer>
            </GridColumn>

            <GridColumn columnStart="1" isLabelColumn>
              <Label editorId="field" required>
                Field
              </Label>
            </GridColumn>
            <GridColumn columnStart="2">
              <FieldContainer $hideLabel>
                <DropdownField
                  name="field"
                  data={fieldOptions}
                  dataItemKey="id"
                  textField="name"
                  required
                  disabled={editMode === 'existing'}
                />
              </FieldContainer>
            </GridColumn>

            <GridColumn columnStart="1" isLabelColumn>
              <Label editorId="item" required>
                Value
              </Label>
            </GridColumn>
            <GridColumn columnStart="2">
              <FieldContainer $hideLabel>
                <InputField
                  name="value"
                  validator={ACCOUNT_PRICING_VALIDATORS.value}
                  required
                />
              </FieldContainer>
            </GridColumn>

            <StyledButtonsContainer>
              <Button
                variant={ButtonVariants.SECONDARY}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
              <Button type="submit">Save</Button>
            </StyledButtonsContainer>
          </StyledForm>
        </StyledRuleEditorDiv>
      </FormProvider>
    );
  },
);

RuleEditor.displayName = 'RuleEditor';

const StyledRuleEditorDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const StyledTopToolbarDiv = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: end;
`;

const StyledForm = styled(createStyledRhfForm('min-content 1fr'))`
  padding: 8px 0 0 8px;
  background-color: inherit;
`;

const StyledCloseButton = styled(FlatIconButton)`
  padding: 0;
`;

const StyledButtonsContainer = styled.div`
  grid-column: 2 / span 1;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
`;
