import { datadogLogs } from '@datadog/browser-logs';

// https://w3c.github.io/window-management/
// https://github.com/w3c/window-management/blob/main/EXPLAINER.md
// https://developer.chrome.com/articles/window-management
declare global {
  // https://w3c.github.io/window-management/#screendetailed
  interface ScreenDetailed extends Screen {
    readonly availLeft: number;
    readonly availTop: number;
    readonly left: number;
    readonly top: number;
    readonly isPrimary: boolean;
    readonly isInternal: boolean;
    readonly devicePixelRatio: number;
    readonly label: string;
  }
  // https://w3c.github.io/window-management/#screendetails
  interface ScreenDetails {
    readonly screens: ScreenDetailed[];
    readonly currentScreen: ScreenDetailed;
  }
  // https://w3c.github.io/window-management/#api-extensions-to-window
  interface Window {
    getScreenDetails?: () => Promise<ScreenDetails>;
  }
  // https://w3c.github.io/window-management/#api-extensions-to-screen
  interface Screen {
    isExtended?: boolean;
  }
}

const WINDOW_MANAGEMENT_PERMISSION_NAME = 'window-management' as PermissionName; // The 'window-management' permission is only supported on Chrome/Edge (Verified on: 2023-10-14).

async function multiScreenPopup(url: string): Promise<Window[]> {
  if (!('getScreenDetails' in window)) {
    datadogLogs.logger.debug('The Multi-Screen Window Placement API is not supported.');
    return [];
  }
  // Request extended screen information.
  const screenDetails = await window.getScreenDetails?.();

  let granted = false;
  try {
    const { state } = await navigator.permissions.query({
      name: WINDOW_MANAGEMENT_PERMISSION_NAME,
    });
    granted = state === 'granted';
    datadogLogs.logger.debug(`Permission for ${WINDOW_MANAGEMENT_PERMISSION_NAME} state: ${state}.`);
  } catch {
    datadogLogs.logger.info(
      `Failed to query permission for ${WINDOW_MANAGEMENT_PERMISSION_NAME}.  This likely indicates the browser does not support the "window-management" API.`,
    );
  }

  const popupWindows: Window[] = [];
  const urls = url.split(';'); // HACK:split link into separate views

  // Detect the presence of extended screen areas.
  if (screenDetails && window.screen.isExtended && granted) {
    // Find a different screen, fill its available area with a new window.
    const otherScreens = screenDetails.screens.filter((s) => !s.isPrimary);

    const firstPopup = window.open(
      urls[0],
      'ViewFile',
      `left=${otherScreens[0].availLeft},top=${otherScreens[0].availTop},width=${otherScreens[0].availWidth},height=${otherScreens[0].availHeight}`,
    );

    if (firstPopup == null) {
      datadogLogs.logger.error('Could not open first popup - "window.open(...)" returned null.');

      return popupWindows;
    }

    popupWindows.push(firstPopup);

    // allow additional monitor in another screen to display
    if (otherScreens.length > 1 && urls.length > 1) {
      const secondPopup = window.open(
        urls[1],
        'ViewFile_1',
        `left=${otherScreens[1].availLeft},top=${otherScreens[1].availTop},width=${otherScreens[1].availWidth},height=${otherScreens[1].availHeight}`,
      );

      if (secondPopup == null) {
        datadogLogs.logger.error('Could not open second popup - "window.open(...)" returned null.');

        return popupWindows;
      }

      popupWindows.push(secondPopup);
    }
  } else {
    // open iframe in popup for multi monitor support instead of in-line
    const fallbackPopup = window.open(urls[0], 'ViewFile');

    if (fallbackPopup == null) {
      datadogLogs.logger.error('Could not open fallback popup - "window.open(...)" returned null.');

      return popupWindows;
    }

    popupWindows.push(fallbackPopup);
  }

  return popupWindows;
}

export const FileViewerService = {
  multiScreenPopup,
} as const;
