import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { faCopy, faTrash } from '@fortawesome/pro-solid-svg-icons';
import {
  CompositeFilterDescriptor,
  DataResult,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import {
  GridColumn,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';

import { QueryStudyShareResult } from 'models';

import { useEvent } from 'core/hooks';
import {
  Action,
  ActionListCell,
  ActionOnClickHandler,
  DataTable,
  DateCell,
  HeaderCell,
  TextCell,
} from 'core/ui';

import { useApiClient } from 'features/api';

import { ShareGridProps } from '../types';

const DEFAULT_DATA_STATE: State = {
  skip: 0,
  take: 10,
  filter: {
    logic: 'and',
    filters: [
      {
        logic: 'or',
        filters: [
          {
            field: 'ShareType',
            operator: 'eq',
            value: 'LINK',
          } as FilterDescriptor,
          {
            field: 'ShareType',
            operator: 'eq',
            value: 'EMAIL',
          } as FilterDescriptor,
        ],
      } as CompositeFilterDescriptor,
    ],
  } as CompositeFilterDescriptor,
};

export const ShareGrid: FunctionComponent<ShareGridProps> = ({
  className,
  patientId,
  examId,
  refreshDataTimestamp,
  onDeleteShareClick,
}) => {
  const apiClient = useApiClient();

  const [data, setData] = useState<DataResult | null>(null);
  const [dataState, setDataState] = useState(DEFAULT_DATA_STATE);

  const handleDataStateChange = useEvent(
    async (event: GridDataStateChangeEvent) => {
      setDataState(event.dataState);
    },
  );

  const handleDeleteShareClick: ActionOnClickHandler<QueryStudyShareResult> =
    useEvent((_event, dataItem) => {
      onDeleteShareClick(dataItem.id);
    });

  const handleCopyClick: ActionOnClickHandler<QueryStudyShareResult> = useEvent(
    async (_event, dataItem, _dataIndex, setTransientTitle) => {
      await navigator.clipboard.writeText(
        `${window.location.origin}/share/${dataItem.linkId}`,
      );
      setTransientTitle('Copied!');
    },
  );

  const actions: Action[] = useMemo(
    () => [
      {
        key: 'delete',
        icon: faTrash,
        title: 'Delete',
        onClick: handleDeleteShareClick,
      },
      {
        key: 'copy',
        icon: faCopy,
        title: 'Copy link',
        onClick: handleCopyClick,
      },
    ],
    [handleDeleteShareClick, handleCopyClick],
  );

  useEffect(() => {
    (async () => {
      const newData = await apiClient.studyShare.getAllSharesForKendoGrid(
        dataState,
        examId != null ? null : patientId, // The patientId and examId filters are mutually exclusive.  The API expects the patientId field to only be supplied when looking for full patient shares.  The examId filter is used when we are looking at shares for a specific exam.
        examId ?? null,
      );

      setData(newData);
    })();
  }, [apiClient, dataState, examId, patientId, refreshDataTimestamp]); // Note that we are intentionally adding refreshDataTimestamp to the dependencies list.  Because that will be the mechanism where parent components are able to programmatically make the grid refresh.

  return (
    <DataTable
      {...dataState}
      data={data}
      className={className}
      pageable
      sortable
      total={data?.total}
      onDataStateChange={handleDataStateChange}
    >
      <GridColumn
        field="action"
        headerCell={HeaderCell}
        filterable={false}
        reorderable={false}
        sortable={false}
        title="Action"
        cell={(props) => (
          <ActionListCell {...props} actions={actions} dataItemKey="id" />
        )}
      />
      <GridColumn
        field="shareType"
        headerCell={HeaderCell}
        title="Share Type"
        cell={TextCell}
      />
      <GridColumn
        field="dateCreated"
        headerCell={HeaderCell}
        title="Shared Date"
        cell={DateCell}
      />
      <GridColumn
        field="shareUser"
        headerCell={HeaderCell}
        title="Shared By"
        cell={TextCell}
      />
      <GridColumn
        field="message"
        headerCell={HeaderCell}
        title="Description"
        cell={TextCell}
      />
      <GridColumn
        field="expireOn"
        headerCell={HeaderCell}
        title="Expires After"
        cell={DateCell}
      />
    </DataTable>
  );
};

ShareGrid.displayName = 'ShareGrid';
