import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { ExamActions } from '../../exam';
import { FileActions } from './actions';

export const fileAdapter = createEntityAdapter();

export const reducer = createReducer(
  fileAdapter.getInitialState(),
  (builder) => {
    builder.addCase(FileActions.add.fulfilled, (state, action) => {
      fileAdapter.addMany(state, action.payload.files);
    });
    builder.addCase(FileActions.rotate.fulfilled, (state, action) => {
      fileAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(ExamActions.generateReport.fulfilled, (state, action) => {
      fileAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(FileActions.getFilesByExamId.pending, (state) => {
      fileAdapter.removeAll(state);
    });
    builder.addCase(FileActions.getFilesByExamId.fulfilled, (state, action) => {
      fileAdapter.addMany(state, action.payload?.files || action.payload);
    });
    builder.addCase(FileActions.deleteFile.fulfilled, (state, action) => {
      fileAdapter.removeOne(state, action.payload);
    });
    builder.addCase(FileActions.changeCategory.fulfilled, (state, action) => {
      const fileId = action.payload;
      const { categoryId } = action.meta.arg;

      fileAdapter.upsertOne(state, {
        id: fileId,
        categoryId,
      });
    });
  },
);
