import { useMemo } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import {
  Field,
  Form,
  FormElement,
  FormStates,
  FormValidatorsService, // eslint-disable-next-line import/no-unresolved
} from 'core/forms';
import { DropdownWithValuesField, Input, MASKED_DATETIME_INPUT_MODES, MaskedDateTimeInput, NumericInput } from 'core/ui';

import { PatientGenders } from '../constants';
import { PatientActions } from '../redux';
import { PatientInitialValueService } from '../services';
import { PatientFormHeader } from './PatientFormHeader';

const PatientForm = ({ formState, setFormState, patient, searchText, setCurrentPatient, setSearchText }) => {
  const dispatch = useDispatch();

  const initialState = useMemo(() => patient || PatientInitialValueService.getInitialValues(), [patient]);

  const isFormDisabled = formState === FormStates.DISABLED;
  const isFormInEditMode = formState === FormStates.EDIT;
  const isFormInAddMode = formState === FormStates.ADD;

  const handleSearchChange = (event) => {
    setSearchText(event.target?.value);
  };

  const handleSubmit = (data) => {
    if (isFormInEditMode) {
      dispatch(PatientActions.edit(data));
    } else if (isFormInAddMode) {
      dispatch(PatientActions.add(data)).then(({ payload }) => {
        // this is a easier way to do this, better way would be to keep
        // current patient in the redux store but that's too much overhead
        setCurrentPatient(Object.values(payload.patients)[0]);
      });
    }
  };

  const handleSubmitClick = (data) => {
    if (isFormDisabled) {
      setFormState(FormStates.EDIT);
    } else if (data.isValid) {
      setFormState(FormStates.DISABLED);
    }
  };

  return (
    <>
      <Input label="Search" name="search" onChange={handleSearchChange} placeholder="Enter first and last name or Patient ID" value={searchText} valid />
      {(patient || isFormInAddMode) && (
        <>
          <Form
            id="patient-form"
            initialValues={initialState}
            key={JSON.stringify(initialState)}
            onSubmit={handleSubmit}
            onSubmitClick={handleSubmitClick}
            render={({ onChange: setValue }) => {
              const handleDOBChange = (event) => {
                setValue('age', {
                  value: dayjs().diff(dayjs(event.value), 'year'),
                });
              };

              return (
                <FormElement>
                  <Field component={Input} name="patientNumber" type="hidden" />
                  <Row className="mb-2 mt-3">
                    <Col className="col-6">
                      <Field
                        component={Input}
                        disabled={isFormDisabled}
                        label="First Name"
                        name="firstName"
                        required
                        validator={FormValidatorsService.required}
                      />
                    </Col>
                    <Col className="col-6">
                      <Field
                        component={Input}
                        disabled={isFormDisabled}
                        label="Last Name"
                        name="lastName"
                        required
                        validator={FormValidatorsService.required}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2 no-gutters">
                    <Col className="col-4">
                      <Field
                        component={MaskedDateTimeInput}
                        disabled={isFormDisabled}
                        label="DOB"
                        mode={MASKED_DATETIME_INPUT_MODES.DATE}
                        name="dob"
                        onChange={handleDOBChange}
                      />
                    </Col>
                    <Col className="col-3">
                      <Field component={NumericInput} disabled={isFormDisabled} label="Age" name="age" />
                    </Col>
                    <Col className="col-5">
                      <Field
                        component={DropdownWithValuesField}
                        data={PatientGenders}
                        dataItemKey="value"
                        disabled={isFormDisabled}
                        isForPrimitiveValues
                        label="Gender"
                        name="gender"
                        valueField="value"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2 no-gutters">
                    <Col className="col-5">
                      <Field component={Input} disabled={isFormDisabled} label="MRN/Patient ID" name="patientNumber" />
                    </Col>
                    <Col className="col-4">
                      <Field component={Input} disabled={isFormDisabled} label="UNOS ID" name="unosID" />
                    </Col>
                  </Row>
                </FormElement>
              );
            }}
          />
        </>
      )}
    </>
  );
};

PatientForm.propTypes = {
  formState: PropTypes.oneOf(Object.values(FormStates)),
  setFormState: PropTypes.func,
  searchText: PropTypes.string.isRequired,
  setCurrentPatient: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  patient: PropTypes.shape({}),
};

PatientForm.defaultProps = {
  formState: FormStates.DISABLED,
  setFormState: () => {},
  patient: null,
};

export { PatientForm };
