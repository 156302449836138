// source: https://usehooks.com/useDebounce/
// NOTE: when converting to TS, visit source from the line above for the complete hook in TS
import { useEffect, useState } from 'react';

/** @deprecated This is no longer recommended.  Use `useDebounceEmitter` instead. */
export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
