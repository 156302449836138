import { DefaultTheme } from 'styled-components';

import {
  borderWidths,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
} from './tokens';

export const theme: DefaultTheme = {
  borderWidths,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
};

/** Name of classes applied globally (to the <html> element) when certain responsive design thresholds have been met. */
export const BreakpointClassNames = {
  Desktop: 'breakpoint-desktop',
  DesktopShort: 'breakpoint-desktop-short',
  Mobile: 'breakpoint-mobile',
  OrientationPortrait: 'orientation-portrait',
  OrientationLandscape: 'orientation-landscape',
  VerticalClamp: 'vertical-clamp',
} as const;

/** Globally available responsive design CSS selectors. */
export const BreakpointSelectors = {
  /** View is in desktop mode. */
  Desktop: `.${BreakpointClassNames.Desktop}`,
  /** View is wide enough to be considered in desktop mode, but the viewport is too short so a fixed height is applied to the document which will allow the user to scroll around. */
  DesktopShort: `.${BreakpointClassNames.Desktop}.${BreakpointClassNames.DesktopShort}`,
  /** View is in mobile mode. */
  Mobile: `.${BreakpointClassNames.Mobile}`,
  /** View is in portrait mode. */
  OrientationPortrait: `.${BreakpointClassNames.OrientationPortrait}`,
  /** View is in landscape mode. */
  OrientationLandscape: `.${BreakpointClassNames.OrientationLandscape}`,
  /** View is vertically contained to the height of the viewport.  There isn't an equivalent for horizontal because that is always clamped. */
  VerticalClamp: `.${BreakpointClassNames.VerticalClamp}`,
} as const;
