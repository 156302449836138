import {
  ChangeEventHandler,
  MouseEventHandler,
  forwardRef,
  useCallback,
} from 'react';

import styled from 'styled-components';

import { Button, ButtonLooks, ButtonVariants } from 'core/ui/Button';
import { Input } from 'core/ui/Input';

import { ComponentSizes } from '../constants';
import { InputWrapperProps } from './InputWrapperProps';
import TrashIcon from './assets/trash.svg?react';

export const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>(
  ({ value, index, disabled, onChange, onRemove }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        onChange?.(event, index);
      },
      [index, onChange],
    );

    const handleRemoveClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        onRemove?.(event, index);
      },
      [index, onRemove],
    );

    return (
      <StyledInputDiv>
        <Input ref={ref} onChange={handleChange} value={value} valid />
        <StyledRemoveButton
          disabled={disabled}
          onClick={handleRemoveClick}
          size={ComponentSizes.SMALL}
          variant={ButtonVariants.SECONDARY}
          look={ButtonLooks.WHITE}
        >
          <TrashIcon />
        </StyledRemoveButton>
      </StyledInputDiv>
    );

    // return <Input ref={ref} onChange={handleChange} value={value} valid />;
  },
);

InputWrapper.displayName = 'InputWrapper';

const StyledInputDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledRemoveButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing40};
`;
