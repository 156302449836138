import { FunctionComponent, useCallback } from 'react';

import { Tag } from '../Tag';
import { ComponentSizes } from '../constants';
import { ItemProps } from './ItemProps';

export const Item: FunctionComponent<ItemProps> = ({
  disabled,
  onRemove,
  size = ComponentSizes.MEDIUM,
  tagData,
  tagText,
  textField = 'name',
}) => {
  const handleRemove = useCallback(() => {
    onRemove?.(tagData.find((x) => x[textField] === tagText));
  }, [onRemove, tagData, tagText, textField]);

  return (
    <Tag
      disabled={disabled}
      removable
      size={size}
      text={tagText}
      onRemove={handleRemove}
    />
  );
};

Item.displayName = 'Item';
