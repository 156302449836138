export enum PatientFileModalFeature {
  IMAGES = 'images',
  ATTACHMENTS = 'attachments',
}

export type PatientFileViewModalProps = {
  examId: number | null;
  examStatus: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDialog: any;
  visible: boolean;
  isOPO: boolean;
  showEditBtn: boolean;
  showDeleteBtn: boolean;
  feature: PatientFileModalFeature;
  handleQrButtonClick?: (event: unknown) => Promise<void>;
  handleQrModalClose?: () => void;
  isQrModalVisible: boolean;
  linkId: string | null | undefined;
};
