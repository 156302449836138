import { FunctionComponent } from 'react';

import { UploadListItemProps } from '@progress/kendo-react-upload';
import styled from 'styled-components';

import { FileListItem } from './FileListItem';

export const FileList: FunctionComponent<UploadListItemProps> = ({
  files,
  onRemove,
}) => {
  return (
    <StyledUl>
      {files.map((file) => (
        <FileListItem file={file} onRemove={onRemove} />
      ))}
    </StyledUl>
  );
};

FileList.displayName = 'FileList';

const StyledUl = styled.ul`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
`;
