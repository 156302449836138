import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';

import {
  ExamModel,
  ShareAuthenticateRequest,
  ShareAuthenticateResult,
  ShareStudyViewer,
  StudyShareModel,
  StudySharePublicModel,
} from 'models';

import { ApiRouteService } from 'core/api';

import { ApiRequestError } from '../types/ApiRequestError';
import { HttpClient } from './HttpClient';

export class StudyShareClient {
  constructor(private httpClient: HttpClient) {
    this.getUploadedStudies = this.getUploadedStudies.bind(this);
    this.addStudyShare = this.addStudyShare.bind(this);
    this.deleteStudyShare = this.deleteStudyShare.bind(this);
    this.getAllSharesForKendoGrid = this.getAllSharesForKendoGrid.bind(this);
    this.getStudySharePublic = this.getStudySharePublic.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.getStudyShareByLinkId = this.getStudyShareByLinkId.bind(this);
  }

  public async getUploadedStudies(
    authMode: 'msal-required' | 'share-required',
  ) {
    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode,
    });

    const studies = await this.httpClient.fetchAndParse<ExamModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare/uploaded-studies`,
        options,
      ),
    );
    return studies;
  }

  public async addStudyShare(share: StudyShareModel) {
    const options = await this.httpClient.createStandardOptions('POST', share);

    await this.httpClient.fetchAndParse<StudyShareModel>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare`,
        options,
      ),
    );
  }

  public async deleteStudyShare(id: number) {
    const options = await this.httpClient.createStandardOptions('DELETE', null);
    await this.httpClient.fetch(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare/${id}`,
        options,
      ),
    );
  }

  public async getAllSharesForKendoGrid(
    dataState: DataSourceRequestState,
    patientId: number | null,
    examId: number | null,
  ) {
    const odataQuery = toDataSourceRequestString(dataState);
    const options = await this.httpClient.createStandardOptions('GET', null);

    const queryParams = new URLSearchParams();
    if (patientId != null) {
      queryParams.append('patientId', patientId.toString());
    }
    if (examId != null) {
      queryParams.append('examId', examId.toString());
    }

    const result = await this.httpClient.fetchAndParse<DataResult>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare/grid?${odataQuery}${
          Array.from(queryParams).length > 0 ? `&${queryParams.toString()}` : ''
        }`,
        options,
      ),
    );
    return result;
  }

  public async getStudySharePublic(linkId: string) {
    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode: 'noauth',
    });

    try {
      const result = await this.httpClient.fetchAndParse<StudySharePublicModel>(
        fetch(
          `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare/GetStudySharePublic/${encodeURIComponent(
            linkId,
          )}`,
          options,
        ),
      );

      return result;
    } catch (ex) {
      if (ex instanceof ApiRequestError) {
        if (ex.response.status === 404) return null;
      }

      throw ex;
    }
  }

  public async authenticate(
    linkId: string,
    password: string | null,
    hipaaConsentAccepted: boolean,
  ) {
    const request: ShareAuthenticateRequest = {
      linkId,
      password,
      hipaaConsentAccepted,
    };

    const options = await this.httpClient.createStandardOptions(
      'POST',
      request,
      {
        authMode: 'noauth',
      },
    );

    const result = await this.httpClient.fetchAndParse<ShareAuthenticateResult>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare/Authenticate`,
        options,
      ),
    );

    return result;
  }

  public async getStudyShareByLinkId(linkId: string) {
    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode: 'share-required',
    });

    const result = await this.httpClient.fetchAndParse<ShareStudyViewer[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/StudyShare/GetShareStudyViewer/${encodeURIComponent(
          linkId,
        )}`,
        options,
      ),
    );

    return result;
  }
}
