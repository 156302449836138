// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServiceDetailsFromForm = (form: any) => {
  return Object.entries(form)
    .filter(([key]) => key.startsWith('serviceDetails_'))
    .reduce((acc, [key, value]) => {
      // @ts-expect-error This assertion is necessary to handle dynamic object keys
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      acc[key] = value.map((item) => item.value).join(',');
      return acc;
    }, {});
};
