import { FunctionComponent, useCallback, useRef } from 'react';

import {
  Editor,
  EditorChangeEvent,
  EditorTools,
} from '@progress/kendo-react-editor';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { RichTextEditorProps } from './RichTextEditorProps';

const { Bold, Italic, Underline } = EditorTools;

const EDITOR_TOOLS = [[Bold, Italic, Underline]];

export const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
  className,
  description,
  disabled = false,
  hint,
  isOptionalLabelShown = false,
  label,
  onBlur,
  onChange,
  onFocus,
  // placeholder,
  required = false,
  valid = true,
  validationMessage,
  value,
  visited = false,
  name,
}) => {
  // TODO: Figure out what to do with the "name" and "disabled" properties.  The logic here was internally trying to use them, but the Kendo Editor component
  // doesn't actually have these defined.

  const editorRef = useRef<Editor | null>(null);
  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = Boolean(!isValidationMessageShown && hint);
  const hintId = isHintShown ? `${name}_hint` : '';
  const errorId = isValidationMessageShown ? `${name}_error` : '';
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

  const handleChange = useCallback(
    (event: EditorChangeEvent) => {
      onChange?.({ value: event.html });
    },
    [onChange],
  );

  return (
    <div>
      {label && (
        <Label
          description={description}
          editorId={name}
          editorValid={valid}
          editorDisabled={disabled}
          required={required}
          optional={isLabeledAsOptional}
        >
          {label}
        </Label>
      )}
      <StyledEditor
        ref={editorRef}
        className={className}
        // disabled={disabled} TODO: This property doesn't exist on the Kendo Editor component.
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        // placeholder={placeholder} TODO: This property doesn't exist on the Kendo Editor component.
        tools={EDITOR_TOOLS}
        value={value === null ? '' : value}
        $valid={valid}
      />
      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && (
        <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>
      )}
    </div>
  );
};

RichTextEditor.displayName = 'RichTextEditor';

type StyledElementProps = {
  theme: DefaultTheme;
  $valid?: boolean;
};

const resolveBorderColor = ({ theme, $valid }: StyledElementProps) => {
  if (!$valid) {
    return theme.colors.error;
  }

  return theme.colors.borderBase;
};

const StyledEditor = styled(Editor)<{ $valid?: boolean }>`
  border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
`;
