import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { IntegrationModel } from 'models';

import { IntegrationActions } from './actions';

export const integrationAdapter = createEntityAdapter<IntegrationModel>();

export const reducer = createReducer(
  integrationAdapter.getInitialState(),
  (builder) => {
    builder.addCase(IntegrationActions.getAll.fulfilled, (state, action) => {
      const payload = action.payload as { integrations: IntegrationModel[] };
      integrationAdapter.upsertMany(state, payload.integrations);
    });
  },
);
