import { combineReducers } from 'redux';

import { reducer as accounts } from 'features/account';
import { reducer as auth } from 'features/auth';
import { reducer as brandings } from 'features/branding';
import { reducer as dynamicForms } from 'features/dynamic-forms';
import { reducer as exams } from 'features/exam';
import { reducer as examReads } from 'features/exam-reading';
import { reducer as fieldCategories } from 'features/field-category';
import { reducer as files } from 'features/file';
import { reducer as formFields } from 'features/form-field';
import { reducer as groups, reducerGrid as groupsGrid } from 'features/group';
import { reducer as home } from 'features/home';
import { reducer as imageQualities } from 'features/image-quality';
import { reducer as integrations } from 'features/integration';
import { reducer as items } from 'features/item';
import {
  reducer as locations,
  reducerGrid as locationsGrid,
} from 'features/location';
import {
  reducer as locationTypes,
  reducerGrid as locationTypesGrid,
} from 'features/location-type';
import { reducer as patients } from 'features/patient';
import {
  reducer as physicians,
  reducerGrid as physiciansGrid,
} from 'features/physician';
import { reducer as resultSets } from 'features/result-set';
import { reducer as services } from 'features/service';
import { reducer as tats } from 'features/tat';
import { reducer as units, reducerGrid as unitsGrid } from 'features/unit';
import { reducer as uploads } from 'features/upload-tracker';
import { reducer as users, reducerGrid as usersGrid } from 'features/user';
import { reducer as worklist } from 'features/worklist';

export const reducer = combineReducers({
  accounts,
  auth,
  brandings,
  dynamicForms,
  exams,
  examReads,
  fieldCategories,
  files,
  formFields,
  home,
  groups,
  groupsGrid,
  imageQualities,
  items,
  locations,
  locationsGrid,
  locationTypes,
  locationTypesGrid,
  patients,
  physicians,
  physiciansGrid,
  resultSets,
  services,
  tats,
  units,
  unitsGrid,
  uploads,
  integrations,
  users,
  usersGrid,
  worklist,
});
