import { FunctionComponent } from 'react';

import { Dialog } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';

import { FileViewer } from 'features/file';

import { DonorViewFinalReportProps } from '../types/DonorViewFinalReportProps';

export const PatientViewFinalReport: FunctionComponent<DonorViewFinalReportProps> = ({ toggleDialog, visible, file }) => {
  return (
    <StyledDivPage>
      {visible && (
        <Dialog title="Donor's Final Exam Document" onClose={toggleDialog} width="80%" height="100%">
          {file && <FileViewer file={file} overrideMultiMonitor />}
        </Dialog>
      )}
    </StyledDivPage>
  );
};

const StyledDivPage = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[3]};
  display: flex;
  width: 100%;
  height: 100%;
`;
