import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Field, FieldContainer, Form, FormElement, required } from 'core/forms';
import { useEvent } from 'core/hooks';
import { Button, ButtonVariants, ComponentSizes, DropdownWithValuesField, Input } from 'core/ui';

import { SHARE_EXPIRATION_OPTIONS } from '../constants';
import { ShareLinkFormProps, ShareLinkFormValues } from '../types';
import { chevronDownIcon } from '@progress/kendo-svg-icons';

const validators = {
  description: required,
  expiration: required,
};

const ShareLinkFormInner: FunctionComponent<{
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof ShareLinkFormValues) => any;
  onChange: (values: ShareLinkFormValues) => void;
}> = ({ className, valueGetter, onChange }) => {
  const handleFormChange = useEvent(() => {
    const newValues: ShareLinkFormValues = {
      description: valueGetter('description'),
      accessCode: valueGetter('accessCode'),
      expiration: valueGetter('expiration'),
    };

    onChange(newValues);
  });

  return (
    <StyledFormElement className={className} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <FieldContainer>
        <Field component={Input} required label="Description" name="description" validator={validators.description} onChange={handleFormChange} />
      </FieldContainer>
      <FieldContainer>
        <Field component={Input} label="Access Code" name="accessCode" onChange={handleFormChange} />
      </FieldContainer>
      <FieldContainer>
        <Field
          component={DropdownWithValuesField}
          label="Expires In"
          name="expiration"
          isForPrimitiveValues
          onChange={handleFormChange}
          data={SHARE_EXPIRATION_OPTIONS}
          textField="name"
          dataItemKey="value"
          valueField="value"
          required
          validator={validators.expiration}
        />
      </FieldContainer>

      <StyledSubmitButton iconClass="fa fa-link" variant={ButtonVariants.SECONDARY} size={ComponentSizes.LARGE} type="submit">
        Generate Link
      </StyledSubmitButton>
    </StyledFormElement>
  );
};

ShareLinkFormInner.displayName = 'ShareLinkFormInner';

export const ShareLinkForm: FunctionComponent<ShareLinkFormProps> = ({ className, initialValues, onChange, onSubmit }) => {
  return (
    <Form
      initialValues={initialValues}
      ignoreModified
      onSubmit={onSubmit}
      render={({ valueGetter }) => <ShareLinkFormInner className={className} valueGetter={valueGetter} onChange={onChange} />}
    />
  );
};

ShareLinkForm.displayName = 'ShareLinkForm';

const StyledFormElement = styled(FormElement)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 23px; // This value was chosen because it makes the gap between the submit button and previous input field appear to have consistent spacing as the other input fields have with each other.
`;
