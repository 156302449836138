import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'features/main/hooks';

import ShowSidebarIcon from '../assets/show-sidebar.svg?react';
import { WorklistSelectors } from '../redux';

type WorklistSidebarCollapsedProps = {
  handleShowSidebar: () => void;
};

export const WorklistSidebarCollapsed: FunctionComponent<
  WorklistSidebarCollapsedProps
> = ({ handleShowSidebar }) => {
  const activeWorklist = useAppSelector(WorklistSelectors.getSelectedWorklist);

  return (
    <StyledWrapperDiv onClick={handleShowSidebar}>
      <StyledTitleDiv>
        WORKLIST {activeWorklist?.name ? `- ${activeWorklist?.name}` : ''}
      </StyledTitleDiv>
      <ShowSidebarIcon />
    </StyledWrapperDiv>
  );
};

const StyledTitleDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledWrapperDiv = styled.div`
  background: white;
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.spacing30};
  margin-right: ${({ theme }) => theme.space.spacing20};
  width: 250px;
  z-index: 1;
  cursor: pointer;
`;
