import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { AccountRoutes } from '../routes';
import { AccountForm } from './AccountForm';
import { AccountHome } from './AccountHome';

const HOME_ALLOWED_ROLES = [UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN];
const ADD_ALLOWED_ROLES = [UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN];
const EDIT_ALLOWED_ROLES = [UserRoles.ADMIN, UserRoles.CUSTOMER_ADMIN];

export function Account() {
  return (
    <Routes>
      <Route
        path={AccountRoutes.HOME}
        element={
          <RouteGuard allowedRoles={HOME_ALLOWED_ROLES}>
            <AccountHome />
          </RouteGuard>
        }
      />
      <Route
        path={AccountRoutes.ADD}
        element={
          <RouteGuard allowedRoles={ADD_ALLOWED_ROLES}>
            <AccountForm />
          </RouteGuard>
        }
      />
      <Route
        path={AccountRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={EDIT_ALLOWED_ROLES}>
            <AccountForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
}
