import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { PACSRoutes } from '../routes';
import { PACSHome } from './PACSHome';

export const PACS: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={PACSRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <PACSHome />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

PACS.displayName = 'PACS';
