import { memo, useCallback, useMemo, useState } from 'react';

import { TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { pickBy } from 'lodash';
import styled from 'styled-components';

import { Button, Modal, TextArea } from 'core/ui';
import { hasText } from 'core/utils';

import { BulkCancelModalProps } from '../types';

export const BulkCancelModal = memo<BulkCancelModalProps>(({ show, selectedExams, onClose, onSave }) => {
  const [reason, setReason] = useState('');

  const selectedExamIds = useMemo(() => Object.keys(pickBy(selectedExams, (isSelected) => isSelected)), [selectedExams]);

  const handleReasonChange = useCallback((event: TextAreaChangeEvent) => {
    setReason(event.value);
  }, []);

  const handleSaveClick = useCallback(() => {
    if (!hasText(reason)) {
      return;
    }

    onSave(reason);
  }, [onSave, reason]);

  return (
    <Modal show={show} title="Confirm Bulk Cancel" onHide={onClose}>
      <StyledDialogBodyDiv>
        <div>
          <TextArea label="Cancel reason" onChange={handleReasonChange} required value={reason} valid={hasText(reason)} />
        </div>

        <StyledDescriptionDiv>
          <p>
            Bulk cancel the selected
            {selectedExamIds.length === 1 ? ' exam' : ' exams'}: [{selectedExamIds.join(', ')}].
          </p>
        </StyledDescriptionDiv>

        <StyledConfirmDiv>
          <Button disabled={!hasText(reason)} onClick={handleSaveClick}>
            Confirm
          </Button>
        </StyledConfirmDiv>
      </StyledDialogBodyDiv>
    </Modal>
  );
});

BulkCancelModal.displayName = 'BulkCancelModal';

const StyledDialogBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 24px;

  p {
    margin: 0;
  }
`;

const StyledDescriptionDiv = styled.div`
  padding: 24px 0;

  p {
    margin: 0;
  }
`;

const StyledConfirmDiv = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-end;
`;
