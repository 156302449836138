import {
  CompressCompleteEvent,
  CompressErrorEvent,
  CompressProgressEvent,
  CompressStartEvent,
  FILE_COMPRESSOR_MESSAGE_TYPES,
  InboundMessageEventData,
  OutboundMessageEventData,
} from '@repo/web-workers/file-compressor';
import FileCompressorWorker from '@repo/web-workers/file-compressor-worker?worker';

import { EventStream, IEventStreamConsumer } from 'core/utils';

export class FileCompressor {
  static [Symbol.toStringTag]() {
    return 'FileCompressor';
  }

  private worker: Worker | null = null;

  private _isWorking = false;

  private _streams = {
    onStart: new EventStream<CompressStartEvent>(),
    onProgress: new EventStream<CompressProgressEvent>(),
    onComplete: new EventStream<CompressCompleteEvent>(),
    onError: new EventStream<CompressErrorEvent>(),
  };

  public get streams(): {
    onStart: IEventStreamConsumer<CompressStartEvent>;
    onProgress: IEventStreamConsumer<CompressProgressEvent>;
    onComplete: IEventStreamConsumer<CompressCompleteEvent>;
    onError: IEventStreamConsumer<CompressErrorEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.compressFile = this.compressFile.bind(this);
    this.isWorking = this.isWorking.bind(this);
  }

  public initialize() {
    if (this.worker == null) {
      this.worker = new FileCompressorWorker();
      this.worker.onmessage = this.handleMessage;
    }
  }

  /** Terminates the web worker.  Safe to call if not currently running. */
  public destroy() {
    this.worker?.terminate?.();
    this.worker = null;
    this._streams.onStart.clear();
    this._streams.onProgress.clear();
    this._streams.onComplete.clear();
    this._streams.onError.clear();
  }

  public isWorking() {
    return this._isWorking;
  }

  /** Dispatch a request to compress the specified file to the web worker. */
  public compressFile(fileId: string, file: File) {
    this.initialize();

    const msg: InboundMessageEventData = {
      type: 'compress-file',
      event: {
        fileId,
        file,
      },
    };

    this._isWorking = true;
    //this.log?.update(fileId, { state: 'compressing' });
    this.worker?.postMessage(msg);
  }

  /** Listens for events sent out of the web worker and then dispatches to the eventHub. */
  private handleMessage(event: MessageEvent<OutboundMessageEventData>) {
    if (event.data.type === FILE_COMPRESSOR_MESSAGE_TYPES.START) {
      this._streams.onStart.emit(event.data.event);
    } else if (event.data.type === FILE_COMPRESSOR_MESSAGE_TYPES.PROGRESS) {
      this._streams.onProgress.emit(event.data.event);
    } else if (event.data.type === FILE_COMPRESSOR_MESSAGE_TYPES.COMPLETE) {
      this._isWorking = false;
      this._streams.onComplete.emit(event.data.event);
      /*this.log?.update(event.data.event.fileId, {
        state: 'compressed',
        compressedSize: event.data.event.compressedSize,
      });*/
    } else if (event.data.type === FILE_COMPRESSOR_MESSAGE_TYPES.ERROR) {
      this._isWorking = false;
      this._streams.onError.emit(event.data.event);
      //this.log?.update(event.data.event.fileId, { state: 'error' });
    } else {
      throw new Error('Unknown message from file compressor.');
    }
  }
}
