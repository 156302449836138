import { FunctionComponent } from 'react';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import cn from 'classnames';
import styled, { DefaultTheme } from 'styled-components';

import { Button, ButtonVariants } from '../Button';
import { ConfirmationModalProps } from './ConfirmationModalProps';
import { ConfirmationModalTypes } from './constants';

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  cancelText = 'Cancel',
  confirmText = 'Done',
  content,
  onCancel,
  onConfirm,
  renderActions,
  title,
  type = ConfirmationModalTypes.INFO,
}) => {
  const iconClasses = cn('fa', {
    'fa-circle-check': type === ConfirmationModalTypes.SUCCESS,
    'fa-circle-exclamation': type === ConfirmationModalTypes.INFO || type === ConfirmationModalTypes.WARNING || type === ConfirmationModalTypes.DELETE,
    'fa-circle-xmark': type === ConfirmationModalTypes.ERROR,
  });

  return (
    <StyledDialogBase $type={type}>
      <StyledDivTitleContainer>
        <StyledSpanIcon className={iconClasses} $type={type} />

        <StyledSpanTitle $type={type}>{title}</StyledSpanTitle>
      </StyledDivTitleContainer>

      <StyledPContentContainer $type={type}>{content}</StyledPContentContainer>

      <DialogActionsBar>
        {renderActions ? (
          renderActions()
        ) : (
          <>
            {onCancel && (
              <Button variant={ButtonVariants.SECONDARY} onClick={onCancel}>
                {cancelText}
              </Button>
            )}

            <Button variant={ButtonVariants.PRIMARY} onClick={onConfirm}>
              {confirmText}
            </Button>
          </>
        )}
      </DialogActionsBar>
    </StyledDialogBase>
  );
};

ConfirmationModal.displayName = 'ConfirmationModal';

function resolvePalette(theme: DefaultTheme, type: ConfirmationModalTypes) {
  switch (type) {
    case ConfirmationModalTypes.DELETE:
    case ConfirmationModalTypes.WARNING: {
      return {
        background: theme.colors.palette.white,
        text: theme.colors.textPrimary,
        icon: theme.colors.palette.yellows[5],
        overlay: theme.colors.palette.grayscale[9],
      };
    }
    case ConfirmationModalTypes.INFO: {
      return {
        background: theme.colors.palette.white,
        text: theme.colors.textPrimary,
        icon: theme.colors.palette.aquas[5],
        overlay: theme.colors.palette.grayscale[9],
      };
    }
    case ConfirmationModalTypes.SUCCESS: {
      return {
        background: theme.colors.palette.white,
        text: theme.colors.textPrimary,
        icon: theme.colors.palette.greens[5],
        overlay: theme.colors.palette.grayscale[9],
      };
    }
    case ConfirmationModalTypes.ERROR: {
      return {
        background: theme.colors.palette.white,
        text: theme.colors.textPrimary,
        icon: theme.colors.palette.reds[5],
        overlay: theme.colors.palette.grayscale[9],
      };
    }
    default:
      return {
        background: theme.colors.palette.white,
        text: theme.colors.textPrimary,
        icon: theme.colors.palette.aquas[5],
        overlay: theme.colors.palette.grayscale[9],
      };
  }
}

function resolveBackgroundColor(theme: DefaultTheme, type: ConfirmationModalTypes) {
  const { background } = resolvePalette(theme, type);

  return background;
}

function resolveColor(theme: DefaultTheme, type: ConfirmationModalTypes) {
  const { text } = resolvePalette(theme, type);

  return text;
}

function resolveIconColor(theme: DefaultTheme, type: ConfirmationModalTypes) {
  const { icon } = resolvePalette(theme, type);

  return icon;
}

function resolveOverlayColor(theme: DefaultTheme, type: ConfirmationModalTypes) {
  const { overlay } = resolvePalette(theme, type);

  return overlay;
}

const StyledDialogBase = styled(Dialog)<{ $type: ConfirmationModalTypes }>`
  & .k-overlay {
    background-color: ${({ theme, $type }) => resolveOverlayColor(theme, $type)};
    opacity: 0.4;
  }

  & .k-widget {
    background-color: ${({ theme, $type }) => resolveBackgroundColor(theme, $type)};
    border-radius: ${({ theme }) => theme.radii.base};
    box-shadow:
      0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: ${({ theme }) => theme.space.spacing60} ${({ theme }) => theme.space.spacing60} ${({ theme }) => theme.space.spacing50}
      ${({ theme }) => theme.space.spacing60};
    width: ${({ theme }) => theme.sizes.modalWidth};
  }

  & .k-window-content {
    padding: 0rem;
  }

  & .k-dialog-buttongroup {
    border: none;
    gap: ${({ theme }) => theme.space.spacing20};
    grid-gap: ${({ theme }) => theme.space.spacing20};
    justify-content: flex-end;
    padding: 0rem;

    & > * {
      flex: 0 1 auto;
    }
  }
`;

const StyledPContentContainer = styled.p<{ $type: ConfirmationModalTypes }>`
  color: ${({ theme, $type }) => resolveColor(theme, $type)};
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  margin-bottom: ${({ theme }) => theme.space.spacing50};
  margin-top: ${({ theme }) => theme.space.spacing20};
  padding-left: ${({ theme }) => theme.space.spacing70};
`;

const StyledDivTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledSpanIcon = styled.span<{ $type: ConfirmationModalTypes }>`
  color: ${({ theme, $type }) => resolveIconColor(theme, $type)};
  font-size: ${({ theme }) => theme.sizes.modalIcon};
`;

const StyledSpanTitle = styled.span<{ $type: ConfirmationModalTypes }>`
  color: ${({ theme, $type }) => resolveColor(theme, $type)};
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.subheading};
`;
