import { useMemo } from 'react';

import { ShareStudyViewer } from 'models';

/** Contains logic that is common between the desktop and the mobile exam files tree components. */
export function useExamsFilesAccordionItem(
  exam: ShareStudyViewer,
  selectedFileId: number | null,
) {
  const examFiles = useMemo(
    () => exam.files.filter((f) => f.fileType.toUpperCase() === 'LINK'),
    [exam.files],
  );

  const examAttachments = useMemo(
    () => exam.files.filter((f) => f.fileType.toUpperCase() !== 'LINK'),
    [exam.files],
  );

  const isExamSelected = useMemo(
    () => exam.files.findIndex((f) => f.id === selectedFileId) >= 0,
    [exam.files, selectedFileId],
  );

  return {
    examFiles,
    examAttachments,
    isExamSelected,
  };
}
