import { memo, useContext } from 'react';

import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { MergePatientValueSelectContext } from '../contexts';
import { MergePatientValueSelectProps } from '../types';

//create react context
export const MergePatientValueSelect = memo<MergePatientValueSelectProps>(({ fieldValue, fieldName, sourceOrDest }) => {
  const mergePatientContext = useContext(MergePatientValueSelectContext);
  const onChange = useEvent((e: CheckboxChangeEvent) => {
    mergePatientContext.onValueChecked(fieldName, sourceOrDest, e.value, fieldValue);
  });

  return (
    <td>
      <StyledCheckbox
        checked={
          sourceOrDest === 'src'
            ? Object.keys(mergePatientContext.selectedSourceFields ?? {}).includes(fieldName)
            : Object.keys(mergePatientContext.selectedDestFields ?? {}).includes(fieldName)
        }
        onChange={onChange}
      />
      {fieldValue}
    </td>
  );
});

const StyledCheckbox = styled(Checkbox)`
  padding-right: ${(props) => props.theme.space.spacing20};
`;

MergePatientValueSelect.displayName = 'MergePatientValueSelect';
