import { FunctionComponent, useMemo } from 'react';

import _get from 'lodash/get';
import styled from 'styled-components';

import { HighlightText } from '../../../HighlightText';
import { useDataTableExtras } from '../../useDataTableExtras';
import { TextCellProps } from './TextCellProps';

export const TextCell: FunctionComponent<TextCellProps> = ({ className, ellipsisPlacement = 'end', valueTransform, dataItem, field }) => {
  const value = valueTransform ? valueTransform(_get(dataItem, field ?? '')) : _get(dataItem, field ?? '');

  const { search } = useDataTableExtras();

  const cellRenderResults = useMemo(
    () => (
      <StyledTdText className={className} $ellipsisPlacement={ellipsisPlacement}>
        <HighlightText searchValue={search}>{value?.toString()}</HighlightText>
        {/* Convert value to string in case it is a number, also works if value is already a string. */}
      </StyledTdText>
    ),
    [className, ellipsisPlacement, search, value],
  );

  return cellRenderResults;
};

TextCell.displayName = 'TextCell';

const resolveTextDirection = ({ $ellipsisPlacement }: { $ellipsisPlacement: 'start' | 'end' }) => {
  if ($ellipsisPlacement === 'start') {
    return 'rtl';
  }

  return 'ltr';
};

const StyledTdText = styled.td<{ $ellipsisPlacement: 'start' | 'end' }>`
  direction: ${resolveTextDirection};
`;
