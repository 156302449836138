import { ExamStatusTransitionModel } from 'models';

import { findOrThrow } from 'core/utils';

import { ExamStatusState } from '../types';

function findPredicate(value: ExamStatusState) {
  return (est: ExamStatusTransitionModel) =>
    est.name != null &&
    est.name.localeCompare(value, 'en-US', { sensitivity: 'base' }) === 0;
}

function findExamStatusType(
  allStatusTypes: ExamStatusTransitionModel[],
  value: ExamStatusState,
  throwWhenNotFound: true,
): ExamStatusTransitionModel;
function findExamStatusType(
  allStatusTypes: ExamStatusTransitionModel[],
  value: ExamStatusState,
  throwWhenNotFound?: false,
): ExamStatusTransitionModel | undefined;
function findExamStatusType(
  allStatusTypes: ExamStatusTransitionModel[],
  value: ExamStatusState,
  throwWhenNotFound?: boolean,
): ExamStatusTransitionModel | undefined;
function findExamStatusType(
  allStatusTypes: ExamStatusTransitionModel[],
  value: ExamStatusState,
  throwWhenNotFound = false,
) {
  return throwWhenNotFound
    ? findOrThrow(
        allStatusTypes,
        findPredicate(value),
        `Could not find ExamStatusType with name ${value}.`,
      )
    : allStatusTypes.find(findPredicate(value));
}

export const ExamStatusService = {
  findExamStatusType,
};
