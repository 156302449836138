import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { faLeftRight, faUpDown } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useMeasure from 'react-use-measure';
import styled, { css } from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Icon } from 'core/ui';

import { useAccessTokenSnapshot } from 'features/auth';
import { FileUrlService } from 'features/exam/services/file-url-service';

import { LOCALSTORAGE_KEY_ECG_VIEWER_WRAPPING } from '../constants';
import { AliveCorSixLeadViewerProps } from '../types';
import { CanvasAsyncSprite } from './CanvasAsyncSprite';

export const AliveCorSixLeadViewer: FunctionComponent<AliveCorSixLeadViewerProps> =
  memo(({ className, subFiles }) => {
    const { accessToken } = useAccessTokenSnapshot();
    const url =
      subFiles == null || subFiles.length === 0 || subFiles[0].url == null
        ? null
        : FileUrlService.resolveFileUrl(subFiles[0], accessToken);
    const fullImageRef = useRef<HTMLImageElement | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [imageSlices, setImageSlices] = useState<ImageSlice[]>([]);
    const [containerRef, { width: containerWidthUnrounded }] = useMeasure();
    const [isWrapped, setIsWrapped] = useState(
      () =>
        window.localStorage.getItem(LOCALSTORAGE_KEY_ECG_VIEWER_WRAPPING) ===
        'true',
    );

    const containerWidth = Math.floor(containerWidthUnrounded); // Fractional container sizes frequently happen - use the floor so we aren't doing any sub-pixel calculations.

    const sliceImage = useEvent((): ImageSlice[] => {
      if (containerWidth <= 0) return [];

      if (fullImageRef.current == null) {
        throw new Error(
          'Cannot wrap image because fullImageRef.current is null or undefined.',
        );
      }

      const fullImageWidth = fullImageRef.current.naturalWidth;
      const fullImageHeight = fullImageRef.current.naturalHeight;
      const newSlices: ImageSlice[] = [];
      const sliceCount =
        Math.floor(fullImageWidth / containerWidth) +
        (fullImageWidth % containerWidth === 0 ? 0 : 1);

      // Construct bitmaps for each image slice.
      for (let sliceIndex = 0; sliceIndex < sliceCount; sliceIndex++) {
        const remainingWidth = fullImageWidth - sliceIndex * containerWidth;
        const sliceWidth = Math.min(remainingWidth, containerWidth);

        newSlices.push({
          key: crypto.randomUUID(),
          width: sliceWidth,
          height: fullImageHeight,
          bitmap: createImageBitmap(
            fullImageRef.current,
            sliceIndex * containerWidth,
            0,
            sliceWidth,
            fullImageHeight,
          ),
        });
      }

      return newSlices;
    });

    const handleFullImageLoad = useCallback(async () => {
      setIsLoaded(true);
    }, []);

    const handleToggleWrappingClick = useEvent(() => {
      const newIsWrapped = !isWrapped;

      localStorage.setItem(
        LOCALSTORAGE_KEY_ECG_VIEWER_WRAPPING,
        newIsWrapped.toString(),
      );

      setIsWrapped((prev) => !prev);
    });

    // Reset everything when the url changes.
    useEffect(() => {
      // Note: In production scenarios this can be simplified to setIsLoaded(false).  However during development we have to keep in mind that all useEffects() are re-fired
      // when the component is "hot reloaded".  So this callback needs to be intelligent enough to only reset the "isLoaded" state when the url actually changes.
      const newIsLoaded =
        (fullImageRef.current?.complete ?? false) &&
        (fullImageRef.current?.naturalWidth ?? 0) > 0;

      const newSlices = newIsLoaded ? sliceImage() : [];

      setIsLoaded(newIsLoaded);
      setImageSlices((prev) =>
        prev.length === 0 && newSlices.length === 0 ? prev : newSlices,
      );
    }, [sliceImage, url]);

    // Reset only the image slices when the viewable width changes.
    useEffect(() => {
      if (!isLoaded) return;

      const newSlices = sliceImage();
      setImageSlices((prev) =>
        newSlices.length === 0 && prev.length === 0 ? prev : newSlices,
      );
    }, [sliceImage, containerWidth, isLoaded]);

    if (url == null || accessToken == null) return null;

    return (
      <StyledScrollContainer className={className}>
        <StyledWrapButtonContainer>
          <OverlayTrigger
            placement="bottom-start"
            overlay={ToggleButtonTooltip}
          >
            <StyledToggleButtonContainer>
              <Button type="button" onClick={handleToggleWrappingClick}>
                <Icon icon={isWrapped ? faLeftRight : faUpDown} fixedWidth />
              </Button>
            </StyledToggleButtonContainer>
          </OverlayTrigger>
        </StyledWrapButtonContainer>

        <div>
          <StyledFullImage
            ref={fullImageRef}
            className="StyledFullImage"
            src={url}
            onLoad={handleFullImageLoad}
            $visible={!isWrapped}
          />
        </div>

        <div ref={containerRef}>
          {isWrapped &&
            imageSlices.map((slice, index) => (
              <CanvasAsyncSprite
                key={index.toString()} // This is here just to suppress the React warnings about lists needing a unique key.  Practically since we have to directly render a bitmap to a canvas, all we really need is for React to render enough <canvas> elements into the dom for us to use.
                bitmap={slice.bitmap}
                width={slice.width}
                height={slice.height}
              />
            ))}
        </div>
      </StyledScrollContainer>
    );
  });

AliveCorSixLeadViewer.displayName = 'AliveCorSixLeadViewer';

const StyledToggleButtonContainer = styled.div`
  display: inline-block;
`;

const StyledScrollContainer = styled.div`
  flex: 1 1 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

const StyledWrapButtonContainer = styled.div`
  padding: 0 0 16px 0;
`;

const StyledFullImage = styled.img<{ $visible: boolean }>`
  ${({ $visible }) => ($visible ? null : PresetImageHidden)}
`;

const PresetImageHidden = css`
  display: none;
`;

type ImageSlice = {
  key: string;
  bitmap: Promise<ImageBitmap>;
  width: number;
  height: number;
};

const ToggleButtonTooltip = (
  <Tooltip>Toggle horizontal or vertical scroll mode.</Tooltip>
);
