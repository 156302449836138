import { fileAdapter } from './reducer';

const rootSelector = (state) => state.files;

const {
  selectById: getById,
  selectAll: getAll,
  selectTotal: getCount,
} = fileAdapter.getSelectors(rootSelector);

export const FileSelectors = {
  getById,
  getAll,
  getCount,
};
