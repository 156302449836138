import { ComponentType, FunctionComponent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';

import { SourceModel } from 'models/SourceModel';

import {
  ActionListCell,
  DEFAULT_DATA_TABLE_DATA_STATE,
  DEFAULT_PAGE_SIZES,
  DataTable,
  HeaderCell,
  Page,
  PageHeader,
  TextCell,
  Toolbar,
  faPen,
  styled,
} from 'core/ui';

import { apiClient } from 'features/api';
import { useCurrentUser } from 'features/auth';

export const SourceHome: FunctionComponent = () => {
  const navigate = useNavigate();

  const [sources, setSources] = useState<SourceModel[]>([]);
  const [sourcesTotal, setSourcesTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const { currentUser } = useCurrentUser(true);

  const PageableSettings = {
    pageSizes: DEFAULT_PAGE_SIZES,
  };

  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  const handleAddNewClick = useCallback(() => navigate('/source/add'), [navigate]);

  const handleEditClick = useCallback((dataItem: SourceModel) => navigate(`/source/edit/${dataItem.id}`), [navigate]);

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-source',
        title: 'Edit Source',
        icon: faPen,
        disabled: !currentUser.isSystemAdmin,
        onClick: (_: MouseEvent<HTMLButtonElement>, dataItem: SourceModel) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick, currentUser.isSystemAdmin]);

  const activeCellRender = useCallback((props: GridCellProps) => <TextCell {...props} valueTransform={(value) => (value ? 'Active' : 'Inactive')} />, []);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.sourceClient.getAllSourcesForKendoGrid(dataState);

      setSources(queryResult.data);
      setSourcesTotal(queryResult.total);
    })();
  }, [dataState]);

  return (
    <Page>
      <PageHeader title="Sources" />
      <StyledDataTable
        data={sources}
        filterable
        sortable
        pageable={PageableSettings}
        total={sourcesTotal}
        onDataStateChange={handleDataStateChange}
        actions={gridActions}
        {...dataState}
      >
        <Toolbar onAddNewClick={handleAddNewClick} />
        <GridColumn
          title="Action"
          filterable={false}
          sortable={false}
          headerCell={HeaderCell}
          cell={ActionListCell as ComponentType<GridCellProps>}
          width="80px"
        />
        <GridColumn title="Id" field="id" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={80} />
        <GridColumn title="Name" field="name" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Description" field="description" filter="text" headerCell={HeaderCell} cell={TextCell} />
        <GridColumn title="Host Name" field="hostName" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Host IP" field="hostIP" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Local IP" field="localIP" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Local Range" field="localRange" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Port" field="port" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={100} />
        <GridColumn title="Notes" field="notes" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="SendingAE" field="sendingAE" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="DestinationAE" field="DestinationAE" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Active" field="active" filter="boolean" headerCell={HeaderCell} filterable={false} cell={activeCellRender} width={80} />
      </StyledDataTable>
    </Page>
  );
};

SourceHome.displayName = 'SourceHome';

const StyledDataTable = styled(DataTable)`
  max-height: initial;
`;
