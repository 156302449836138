import React from 'react';

import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const InfoField = ({ hideIfEmpty, label, text }) => {
  if (!text && hideIfEmpty) {
    return null;
  }

  return (
    <StyledInfoCol>
      <StyledLabelDiv>{label}:</StyledLabelDiv>
      {text || '/'}
    </StyledInfoCol>
  );
};

const StyledLabelDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.body};
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
`;

const StyledInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
  white-space: pre;
`;

InfoField.propTypes = {
  hideIfEmpty: PropTypes.bool,
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
};

InfoField.defaultProps = {
  hideIfEmpty: true,
  text: '/',
};

export { InfoField };
