import { FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { Icon } from 'core/ui/Icon';

import { TextWithIconCellProps } from './TextWithIconCellProps';

export const TextWithIconCell: FunctionComponent<TextWithIconCellProps> = ({ className, ellipsisPlacement = 'end', icon, dataItem, field }) => {
  const dataItemField = field == null ? undefined : dataItem[field];

  const content = useMemo(
    () => (
      <td className={className}>
        <StyledDivContainer>
          <StyledSpanTextIcon $ellipsisPlacement={ellipsisPlacement}>{dataItemField}</StyledSpanTextIcon>
          {icon == null ? null : <Icon icon={icon} />}
        </StyledDivContainer>
      </td>
    ),
    [className, dataItemField, ellipsisPlacement, icon],
  );

  return content;
};

TextWithIconCell.displayName = 'TextWithIconCell';

const resolveTextDirection = ({ $ellipsisPlacement }: { $ellipsisPlacement: 'start' | 'end' }) => {
  return $ellipsisPlacement === 'start' ? 'rtl' : 'ltr';
};

const StyledDivContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.dataTableTextWithIconCellIconMargin};
`;

const StyledSpanTextIcon = styled.span<{ $ellipsisPlacement: 'start' | 'end' }>`
  direction: ${resolveTextDirection};
  overflow: hidden;
  text-overflow: ellipsis;
`;
