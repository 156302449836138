import { useCallback, useInsertionEffect, useRef } from 'react';

/** Similar to useCallback except that this is guaranteed to always return the same function reference.  Internally this
 * will always invoke the most recent function definition.  This is slated to become part of the official React API
 * an up coming release.
 * @see {@link https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md}
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function useEvent<T extends Function>(fn: T): T {
  // This is a shim so that we can use a hook that is slated to be introduced in a future version of React.
  // Once it gets released we can delete this definition and use the built in one.

  const ref = useRef<T | null>(null);

  useInsertionEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback((...args: unknown[]) => {
    const f = ref.current;

    if (f == null) throw new Error('Cannot invoke function that is null or undefined.');

    return f(...args);
  }, []) as unknown as T;
}
