import { ComponentType } from 'react';

import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query';
import { TextFilter } from '@progress/kendo-react-data-tools';
import { cloneDeep } from 'lodash';

export function addFiltersToDataState(dataState: State, newFilters: (CompositeFilterDescriptor | FilterDescriptor)[], logic: 'and' | 'or' = 'and'): State {
  return {
    ...cloneDeep(dataState),
    filter: {
      logic,
      filters: [...(dataState?.filter ? dataState.filter.filters : []), ...newFilters],
    },
  };
}

export function getSearchFilter<T extends { field: string; search?: boolean; filter?: ComponentType }>(
  searchText: string,
  columns: T[],
): CompositeFilterDescriptor {
  const searchColumns = columns.filter(({ search }) => search);

  const textFields = searchColumns.filter(({ filter }) => filter === (TextFilter as unknown as ComponentType));

  return {
    filters: textFields.map(({ field }) => ({
      field,
      operator: 'contains',
      value: searchText,
    })),
    logic: 'or',
  };
}
