import { FunctionComponent } from 'react';

import { useNavigate } from 'react-router-dom';

import { useInitializationEffect, useValidatedParam } from 'core/hooks';

import {
  AuthenticationScheme,
  useAuthentication,
  useRouteGuard,
} from 'features/auth';

export const ShareDefaultAuthorizationChallenge: FunctionComponent = () => {
  const navigate = useNavigate();
  const { activeScheme } = useAuthentication();
  const { allowedSchemes } = useRouteGuard();
  const linkId = useValidatedParam('linkId', 'guid', false);

  if (allowedSchemes.indexOf(AuthenticationScheme.SHARE) < 0)
    throw new Error(
      'Authorization configuration error.  <ShareDefaultAuthorizationChallenge /> requires that the "SHARE" scheme be specified as a property on the parent <RouteGuard /> component.',
    );

  useInitializationEffect(() => {
    if (activeScheme !== AuthenticationScheme.SHARE) {
      // Redirect to the share authentication page if possible.
      if (linkId != null) {
        const returnRoute = `${location.pathname}${location.search}${location.hash}`;
        navigate(`/share/${linkId}?return=${encodeURIComponent(returnRoute)}`);
      }
    }
  });

  return null;
};

ShareDefaultAuthorizationChallenge.displayName =
  'ShareDefaultAuthorizationChallenge';
