const borderWidthsScale = ['0px', '1px', '2px', '4px'];

const base = borderWidthsScale[1];

const sidebar = '3px';

export const borderWidths = {
  scale: borderWidthsScale,
  base,
  sidebar,
};

export type BorderWidths = typeof borderWidths;
