import { memo, useMemo, useRef } from 'react';

import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import type { Identifier } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Checkbox } from 'core/ui';

import { ExamsGridService } from 'features/exam/services';
import { ColumnDefinition } from 'features/exam/types';

import HandleIcon from '../assets/handle.svg?react';
import { WorklistColumnSelectionItemProps } from '../types';

export const WorklistColumnSelectionItem = memo<WorklistColumnSelectionItemProps>(({ column, columnsState, onChange }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { orderIndex, show } = useMemo(() => ExamsGridService.getColumnState(columnsState, column.field), [column.field, columnsState]);

  const handleCheckboxChange = useEvent((event: CheckboxChangeEvent) => {
    onChange([{ columnState: { show: event.value, orderIndex }, column }]);
  });

  const [, drop] = useDrop<ColumnDefinition, unknown, { handlerId: Identifier | null }>({
    accept: 'columnOrder',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      if (!ref.current) {
        return;
      }

      const { orderIndex: itemOrderIndex, show: itemShow } = ExamsGridService.getColumnState(columnsState, item.field);

      const dragIndex = itemOrderIndex ?? 1;
      const hoverIndex = orderIndex ?? 1;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      onChange([
        { columnState: { show, orderIndex: dragIndex }, column },
        { columnState: { show: itemShow, orderIndex: hoverIndex }, column: item },
      ]);
    },
  });

  const [, drag] = useDrag({
    type: 'columnOrder',
    item: () => column,
  });

  drag(drop(ref));

  return (
    <StyledWrapperDiv ref={ref}>
      <StyledCheckbox onChange={handleCheckboxChange} text={column.title} value={show} valid />
      <StyledHandleIcon />
    </StyledWrapperDiv>
  );
});

WorklistColumnSelectionItem.displayName = 'WorklistColumnSelectionItem';

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledHandleIcon = styled(HandleIcon)`
  margin-left: ${({ theme }) => theme.space.spacing30};
  cursor: pointer;
`;

const StyledWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.space.spacing20};
`;
