import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  createStyledFormElement,
  required,
} from 'core/forms';
import { Button, DropdownWithValuesField, Label } from 'core/ui';

import { Roles } from '../constants';
import { PermissionsTabContentProps, PermissionsTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  role: required,
};

const PermissionsTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof PermissionsTabFormValues) => any;
  onChange: (values: PermissionsTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      role: valueGetter('role'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="role">Role</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DropdownWithValuesField}
            data={Roles}
            dataItemKey="value"
            isForPrimitiveValues
            editorId="role"
            name="role"
            required
            validator={validators.role}
            valueField="value"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

PermissionsTabContentInner.displayName = 'PermissionsTabContentInner';

export const PermissionsTabContent: FunctionComponent<
  PermissionsTabContentProps
> = ({ initialValues, onChange, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <PermissionsTabContentInner
          valueGetter={valueGetter}
          onChange={onChange}
        />
      )}
    />
  );
};

PermissionsTabContent.displayName = 'PermissionsTabContent';
