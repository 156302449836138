import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';

import { DefaultColumnsState } from 'features/exam/constants';
import { DEFAULT_FILTER_DATA_STATE, ExamsGridService } from 'features/exam/services/exam-grid-service';
import { ColumnState } from 'features/exam/types/ColumnState';

import { SortFormValues } from '../types';

function initializeWorklistEditModalDataState(stringifiedDataState: string | null | undefined): CompositeFilterDescriptor {
  return {
    ...cloneDeep(DEFAULT_FILTER_DATA_STATE.filter),
    ...ExamsGridService.parseDataState(stringifiedDataState)?.filter,
  };
}

function initializeWorklistEditModalColumnsState(stringifiedColumnsState: string | null | undefined): Record<string, ColumnState> {
  return {
    ...(ExamsGridService.parseColumnsState(stringifiedColumnsState) ?? cloneDeep(DefaultColumnsState)),
  };
}

function initializeWorklistEditModalSort(stringifiedDataState: string | null | undefined): SortFormValues {
  return {
    ...cloneDeep(DEFAULT_FILTER_DATA_STATE.sort[0]), // Only the first sort will be populated.
    ...ExamsGridService.parseDataState(stringifiedDataState)?.sort?.[0], // Only the first sort will be populated.
  };
}

export const WorklistEditService = {
  initializeWorklistEditModalDataState,
  initializeWorklistEditModalColumnsState,
  initializeWorklistEditModalSort,
};
