import { useContext } from 'react';

import { useEvent } from 'core/hooks';

import { AccordionNgContext } from './contexts';

export function useAccordionNgItem(itemKey: string) {
  const accordion = useContext(AccordionNgContext);

  if (accordion == null) {
    throw new Error('useAccordionItem() cannot be invoked unless <AccordionNgProvider /> is a parent in the component tree.');
  }

  const isExpanded = accordion.expandedItemKeys.includes(itemKey);

  const setExpanded = useEvent(() => accordion.setExpanded(itemKey));

  const setCollapsed = useEvent(() => accordion.setCollapsed(itemKey));

  const toggleExpanded = useEvent(() => accordion.toggleExpanded(itemKey));

  return {
    isExpanded,
    toggleExpanded,
    setExpanded,
    setCollapsed,
  };
}
