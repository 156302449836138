export enum ExamStatusState {
  Initial = 'Initial',
  InProcess = 'InProcess',
  New = 'New',
  Assigned = 'Assigned',
  Sent = 'Sent',
  Accepted = 'Accepted',
  Read = 'Read',
  Complete = 'Complete',
  Canceled = 'Canceled',
  Duplicate = 'Duplicate',
  Requested = 'Requested',
  Ordered = 'Ordered',
}
