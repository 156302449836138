import { ChangeEventHandler, memo, useRef } from 'react';

import { faFile, faFileLines } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, ButtonVariants, Icon, PrimaryCard } from 'core/ui';

import { NonDicomExamUploadCardProps } from '../types';

export const NonDicomExamUploadCard = memo<NonDicomExamUploadCardProps>(({ onAdd }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = useEvent(() => {
    uploadInputRef.current?.click();
  });

  const handleNonDicomFilesChange: ChangeEventHandler<HTMLInputElement> = useEvent((event) => {
    onAdd(event.target.files == null ? [] : Array.from(event.target.files));
  });

  return (
    <StyledPrimaryCard>
      <StyledHeaderDiv>
        <Icon icon={faFile} fixedWidth />
        <StyledHeaderSpan>File Upload</StyledHeaderSpan>
      </StyledHeaderDiv>
      <StyledSubHeaderDiv>
        <Icon icon={faFileLines} fixedWidth />
        <StyledSubHeaderSpan>EKG, Pathology, Document, Image or Video Files</StyledSubHeaderSpan>
      </StyledSubHeaderDiv>
      <StyledSelectButton type="button" variant={ButtonVariants.SECONDARY} onClick={handleButtonClick}>
        Select Files
      </StyledSelectButton>
      <StyledHiddenInput ref={uploadInputRef} name="nonDicomFiles" type="file" onChange={handleNonDicomFilesChange} />
    </StyledPrimaryCard>
  );
});

NonDicomExamUploadCard.displayName = 'NonDicomExamUploadCard';

const StyledPrimaryCard = styled(PrimaryCard)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  margin: 0;

  .icon-container {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledHeaderDiv = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  display: flex;
  align-items: center;
`;

const StyledHeaderSpan = styled.span`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.palette.blues[7]};
  margin-left: 8px;
  user-select: none;
`;

const StyledSubHeaderDiv = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const StyledSubHeaderSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.palette.blues[7]};
  margin-left: 8px;
  user-select: none;
`;

const StyledSelectButton = styled(Button)`
  && {
    padding: 5px 16px;
    line-height: 22px;
    height: initial;
    margin-top: 16px;
  }

  .k-button-text {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 22px;
  }
`;

const StyledHiddenInput = styled.input`
  display: none;
`;
