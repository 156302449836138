/** Mime types and file extension constants. */
export const MIME_TYPES = {
  OCTET_STREAM: {
    extensions: [] as string[], // There is no standard file extension for 'application/octet-stream'.
    contentType: 'application/octet-stream',
  },
  JPEG: {
    extensions: ['jpg', 'jpeg'],
    contentType: 'image/jpeg',
  },
  PNG: { extensions: ['png'], contentType: 'image/png' },
  GIF: { extensions: ['gif'], contentType: 'image/gif' },
  PDF: {
    extensions: ['pdf'],
    contentType: 'application/pdf',
  },
  TIFF: {
    extensions: ['tif', 'tiff'],
    contentType: 'image/tiff',
  },
  SVG: {
    extensions: ['svg'],
    contentType: 'image/svg+xml',
  },
  MP4: { extensions: ['mp4'], contentType: 'video/mp4' },
  BMP: { extensions: ['bmp'], contentType: 'image/bmp' },
  AVI: {
    extensions: ['avi'],
    contentType: 'video/x-msvideo',
  },
  DCM: {
    extensions: ['dcm'],
    contentType: 'application/dicom',
  },
  CSV: { extensions: ['csv'], contentType: 'text/csv' },
  SVS: {
    extensions: ['svs'],
    contentType: 'application/octet-stream',
  },
  SZI: {
    extensions: ['szi'],
    contentType: 'application/octet-stream',
  },
  DOC: {
    extensions: ['doc'],
    contentType: 'application/msword',
  },
  DOCX: {
    extensions: ['docx'],
    contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  MKV: {
    extensions: ['mkv'],
    contentType: 'video/x-matroska',
  },
} satisfies Record<string, MimeTypeDefinition>;

/** Lookup MimeTypeDefinition (value) by file extension (key). */
export const MIME_TYPE_EXTENSION_LOOKUPS = new Map<string, MimeTypeDefinition>();

/** Lookup MimeTypeDefinition (value) by mime type (key). */
export const MIME_TYPE_LOOKUPS = new Map<string, MimeTypeDefinition>();

Object.entries(MIME_TYPES).forEach((mime) => {
  mime[1].extensions.forEach((extension) => {
    MIME_TYPE_EXTENSION_LOOKUPS.set(extension, mime[1]);
  });

  MIME_TYPE_LOOKUPS.set(mime[1].contentType, mime[1]);
});

export type MimeTypeDefinition = {
  /** List of possible extensions.  The first in the list is considered the 'primary'.  It is possible for there to be zero file extensions (most likely this will only be 'application/octet-stream'). */
  extensions: string[];
  /** Mime type string. */
  contentType: string;
};
