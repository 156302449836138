import { ExamModel, PatientModel } from 'models';

import { EventStream, IEventStreamConsumer, ParallelQueue } from 'core/utils';

import { apiClient } from 'features/api';

import { DicomMatcherJob, IDicomMatcher, MatchCompleteEvent } from '../types';

export class DicomMatcher implements IDicomMatcher {
  static [Symbol.toStringTag]() {
    return 'DicomMatcher';
  }

  private _queue: ParallelQueue<DicomMatcherJob>; // Note: This has to be initialized inside the constructor because it will reference a callback method that is bound to the class instance.

  private _matchedUploadGroupIds: string[] = [];

  private _fixedPatient: PatientModel | null = null;

  private _fixedExam: ExamModel | null = null;

  private _streams = {
    onMatchComplete: new EventStream<MatchCompleteEvent>(),
  };

  public get streams(): {
    onMatchComplete: IEventStreamConsumer<MatchCompleteEvent>;
  } {
    return this._streams;
  }

  constructor() {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.enqueue = this.enqueue.bind(this);
    this.run = this.run.bind(this);
    this.processJob = this.processJob.bind(this);

    this._queue = new ParallelQueue<DicomMatcherJob>({ key: 'uploadGroupId', maxRunners: 10, run: this.processJob });
  }

  public initialize() {
    // No-op.
  }

  public setFixedEntities(fixedPatient: PatientModel | null, fixedExam: ExamModel | null) {
    this._fixedPatient = fixedPatient;
    this._fixedExam = fixedExam;
  }

  public destroy() {
    this._queue.destroy();
    this._streams.onMatchComplete.clear();
    this._matchedUploadGroupIds = [];
    this._fixedPatient = null;
    this._fixedExam = null;
  }

  public enqueue(job: DicomMatcherJob) {
    // Ignore the job if the upload group has already had an attempt at matching.
    if (this._matchedUploadGroupIds.includes(job.uploadGroupId)) {
      return;
    } else {
      this._matchedUploadGroupIds.push(job.uploadGroupId);
    }

    this._queue.enqueue(job);
  }

  public run() {
    this._queue.run();
  }

  private async processJob(job: DicomMatcherJob) {
    const [patientMatch, examMatch] = await Promise.all([
      this._fixedPatient
        ? new Promise<PatientModel | null>((resolve) => resolve(this._fixedPatient))
        : apiClient.patientClient.getPatientMatch(job.patientQuery),
      this._fixedExam ? new Promise<ExamModel | null>((resolve) => resolve(this._fixedExam)) : apiClient.exams.getExamMatch(job.examQuery),
    ]);

    this._streams.onMatchComplete.emit({
      uploadGroupId: job.uploadGroupId,
      patient: patientMatch,
      exam: examMatch,
    });
  }
}
