import { memo } from 'react';

import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { DropdownWithValuesField } from 'core/ui';

import { WorklistSortSelectionProps } from '../types';

const SORTING_OPTIONS = [
  { dir: 'asc', label: 'ASC' },
  { dir: 'desc', label: 'DESC' },
];

export const WorklistSortSelection = memo<WorklistSortSelectionProps>(({ className, columns, field, dir, onChange }) => {
  const handleColumnDropdownChange = useEvent((event: DropDownListChangeEvent) => {
    onChange({
      field: event.value,
      dir: dir,
    });
  });

  const handleOrderDropdownChange = useEvent((event: DropDownListChangeEvent) => {
    onChange({
      field: field,
      dir: event.value,
    });
  });

  return (
    <div className={className}>
      <StyledLabelDiv>SORTING</StyledLabelDiv>
      <StyledColumnDropdown
        data={columns}
        dataItemKey="field"
        filterable={false}
        isForPrimitiveValues
        name="sortField"
        onChange={handleColumnDropdownChange}
        textField="title"
        valid
        value={field}
        valueField="field"
      />
      <StyledOrderDropdown
        data={SORTING_OPTIONS}
        dataItemKey="dir"
        filterable={false}
        isForPrimitiveValues
        name="sortDirection"
        onChange={handleOrderDropdownChange}
        textField="label"
        valid
        value={dir}
        valueField="dir"
      />
    </div>
  );
});

WorklistSortSelection.displayName = 'WorklistSortSelection';

const StyledColumnDropdown = styled(DropdownWithValuesField)`
  width: 120px;
`;

const StyledOrderDropdown = styled(DropdownWithValuesField)`
  margin-left: ${({ theme }) => theme.space.spacing30};
  width: 80px;
`;

const StyledLabelDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;
