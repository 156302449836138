import { useEffect, useRef } from 'react';

// src: https://overreacted.io/making-setinterval-declarative-with-react-hooks
export function useInterval<TReturnType>(
  callback: () => TReturnType,
  delay: number | null | undefined,
) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay != null && delay > 0) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  }, [delay]);
}
