import { createSelector } from '@reduxjs/toolkit';

import { ServiceModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { serviceAdapter } from './reducer';

const rootSelector = (state: RootState) => state.services;

const { selectAll: getAll, selectTotal: getCount } =
  serviceAdapter.getSelectors(rootSelector);

const getByIdOutput = (
  services: ServiceModel[],
  serviceId: number | undefined,
) => services.find((service) => service.id === serviceId);

const getById = (
  serviceId: number | undefined,
): AppSelector<ServiceModel | undefined> =>
  createSelector([getAll, () => serviceId], getByIdOutput);

export const ServiceSelectors = {
  getById,
  getAll,
  getCount,
};
