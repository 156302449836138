const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  dob: null,
  age: '',
  gender: null,
};

const getInitialValues = () => INITIAL_VALUES;

export const PatientInitialValueService = {
  getInitialValues,
};
