import {
  UserModel,
  WorkListViewUserModel,
  WorklistViewAccessModel,
  WorklistViewModel,
} from 'models';

import { equalsInsensitive, findOrThrow, hasText } from 'core/utils';

import { apiClient } from 'features/api';

import { EditFormValues } from '../types';

function copyModelToForm(
  model: WorkListViewUserModel | null,
  allAccessModes: WorklistViewAccessModel[],
  allUsers: UserModel[],
  allWorklists: WorklistViewModel[],
): EditFormValues {
  if (model == null) {
    return {
      user: null,
      access: null,
      worklist: null,
      active: true,
      default: false,
    };
  }

  const access = findOrThrow(
    allAccessModes,
    (a) => a.id === model.accessId,
    `Could not find WorklistViewAccess with id: ${model.accessId}.`,
  );

  const user = findOrThrow(
    allUsers,
    (u) => equalsInsensitive(u.email, model.user),
    `Could not find user with email address: "${model.user}".`,
  );

  const worklist = findOrThrow(
    allWorklists,
    (w) => w.id === model.worklistViewId,
    `Could not find worklist with id: ${model.worklistViewId}.`,
  );

  return {
    user,
    access,
    worklist,
    active: model.active,
    default: model.default,
  };
}

function copyFormToModel(
  original: WorkListViewUserModel | null,
  form: EditFormValues,
): WorkListViewUserModel {
  if (form.user == null) {
    throw new Error('Cannot save because user is null or undefined.');
  }
  if (!hasText(form.user.email)) {
    throw new Error(
      'Cannot save because the selected user has an empty email address.',
    );
  }
  if (form.worklist == null) {
    throw new Error('Cannot save because worklist is null or undefined.');
  }
  if (form.access == null) {
    throw new Error('Cannot save because access is null or undefined.');
  }

  return {
    id: original == null ? 0 : original.id,
    worklistViewId: form.worklist.id,
    accessId: form.access.id,
    user: form.user.email,
    active: form.active,
    default: form.default,
  };
}

async function initialize(id: number | null | undefined) {
  const modelPromise =
    id == null
      ? new Promise<null>((resolve) => {
          resolve(null);
        })
      : apiClient.workListViewClient.getWorkListViewUser(id);

  const [model, allWorklists, { data: allUsers }, allAccessModes] =
    await Promise.all([
      modelPromise,
      apiClient.workListViewClient.getViews(true),
      apiClient.users.getAllForKendoGrid({
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'email',
              operator: 'isnotempty',
            },
          ],
        },
      }), // TODO: For now we just want to get all the users.  There is definitely room to optimize this.
      apiClient.workListViewClient.getAllWorklistViewAccess(),
    ]);

  return {
    model,
    allWorklists,
    allUsers,
    allAccessModes,
    form: copyModelToForm(model, allAccessModes, allUsers, allWorklists),
  };
}

async function save(
  form: EditFormValues,
  original: WorkListViewUserModel | null,
): Promise<WorkListViewUserModel> {
  const newModel = copyFormToModel(original, form);

  if (newModel.id === 0) {
    const id = await apiClient.workListViewClient.createWorkListViewUser(
      newModel,
    );
    newModel.id = id;
  } else {
    await apiClient.workListViewClient.updateWorkListViewUser(newModel);
  }

  return newModel;
}

async function deleteAccess(id: number) {
  await apiClient.workListViewClient.deleteWorkListViewUser(id);
}

export const WorklistViewUserEditService = {
  initialize,
  copyModelToForm,
  copyFormToModel,
  save,
  deleteAccess,
};
