import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { GroupRoutes } from '../routes';
import { GroupForm } from './GroupForm';
import { GroupHome } from './GroupHome';

const GROUP_ADMIN_ROLES = [UserRoles.ADMIN];

export const Group = () => {
  return (
    <Routes>
      <Route
        path={GroupRoutes.HOME}
        element={
          <RouteGuard allowedRoles={GROUP_ADMIN_ROLES}>
            <GroupHome />
          </RouteGuard>
        }
      />
      <Route
        path={GroupRoutes.ADD}
        element={
          <RouteGuard allowedRoles={GROUP_ADMIN_ROLES}>
            <GroupForm />
          </RouteGuard>
        }
      />
      <Route
        path={GroupRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={GROUP_ADMIN_ROLES}>
            <GroupForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
