import { useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useEvent } from 'core/hooks';
import { ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, HeaderCell, Page, PageHeader, TextCell, Toolbar } from 'core/ui';

import { UnitActions, UnitSelectors } from '../redux';

export const UnitHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const units = useSelector(UnitSelectors.getAllGrid);
  const total = useSelector(UnitSelectors.getTotalGrid);

  const gridPDFExport = useRef(null);
  const gridExcelExport = useRef(null);

  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  useEffect(() => {
    dispatch(UnitActions.getAllForKendoGrid(dataState));
  }, [dispatch, dataState]);

  const handleDataStateChange = (changeEvent) => {
    setDataState(changeEvent.dataState);
  };

  const debouncedChangeDataStateHandler = useMemo(() => debounce(handleDataStateChange, 500), []);

  useEffect(() => {
    return () => debouncedChangeDataStateHandler.cancel();
  }, [debouncedChangeDataStateHandler]);

  const handleExportPDFClick = () => {
    if (gridPDFExport.current !== null) {
      gridPDFExport.current.save();
    }
  };

  const handleExportExcelClick = async () => {
    if (gridExcelExport.current !== null) {
      const res = await dispatch(
        UnitActions.getAllForExport({
          skip: 0,
          take: total,
          filter: dataState.filter,
        }),
      );

      gridExcelExport.current.save(res.payload?.data);
    }
  };

  const handleAddNewClick = () => {
    navigate('/unit/add');
  };

  const handleEditClick = useEvent((dataItem) => navigate(`/unit/edit/${dataItem.id}`));

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-unit',
        title: 'Edit Device',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  const grid = (
    <DataTable
      filterable
      sortable
      reorderable
      pageable={{ pageSizes: true }}
      total={total}
      data={units}
      actions={gridActions}
      {...dataState}
      onDataStateChange={handleDataStateChange}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPDFClick} />
      <GridColumn title="Action" filterable={false} sortable={false} headerCell={HeaderCell} cell={ActionListCell} width="80px" />
      <GridColumn cell={TextCell} field="serialNumber" filter="text" headerCell={HeaderCell} title="Serial Number" width="200px" />
      <GridColumn cell={TextCell} field="unitPCN" filter="numeric" headerCell={HeaderCell} title="Unit PCN" width="200px" />
      <GridColumn cell={TextCell} field="locationName" filter="text" headerCell={HeaderCell} title="Location Name" width="350px" />
      <GridColumn cell={TextCell} field="modelType" filter="text" headerCell={HeaderCell} title="Model Type" width="350px" />
      <GridColumn cell={TextCell} field="firmwareRevision" filter="text" headerCell={HeaderCell} title="Firmware Revision" width="250px" />
      <GridColumn cell={TextCell} field="user" filter="text" headerCell={HeaderCell} title="User" width="200px" />
      <GridColumn cell={TextCell} field="pfwAssetNumber" filter="text" headerCell={HeaderCell} title="PFW Asset Number" width="200px" />
      <GridColumn cell={TextCell} field="lastInDate" filter="date" headerCell={HeaderCell} title="Last in Date" width="200px" />
      <GridColumn cell={TextCell} field="lastOutDate" filter="date" headerCell={HeaderCell} title="Last out Date" width="200px" />
    </DataTable>
  );

  return (
    <Page>
      <PageHeader title="Devices" />
      <GridPDFExport ref={gridPDFExport}>{grid}</GridPDFExport>
      <ExcelExport ref={gridExcelExport}>{grid}</ExcelExport>
    </Page>
  );
};
