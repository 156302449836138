import { FunctionComponent, useState } from 'react';

import { faShareNodes } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Modal } from 'core/ui';
import { hasText } from 'core/utils';

import { useApiClient } from 'features/api';

import { DEFAULT_SHARE_EMAIL_FORM_VALUES, DEFAULT_SHARE_LINK_FORM_VALUES, ShareType } from '../constants';
import { ShareService } from '../services';
import { ShareEmailFormValues, ShareLinkFormValues, ShareModalProps } from '../types';
import { ShareEmailForm } from './ShareEmailForm';
import { ShareGrid } from './ShareGrid';
import { ShareLinkForm } from './ShareLinkForm';
import { ShareLinkSummary } from './ShareLinkSummary';
import { SharePatientHeader } from './SharePatientHeader';

export const ShareModal: FunctionComponent<ShareModalProps> = ({ show, patient, examId, onClose }) => {
  const isOpo = patient?.locationType === 'OPO';
  const patientVerbiage = isOpo ? 'Donor' : 'Patient';

  const apiClient = useApiClient();

  const [activeTab, setActiveTab] = useState<'link' | 'email'>('link');
  const [linkFormValues, setLinkFormValues] = useState<ShareLinkFormValues>(DEFAULT_SHARE_LINK_FORM_VALUES);
  const [emailFormValues, setEmailFormValues] = useState<ShareEmailFormValues>(DEFAULT_SHARE_EMAIL_FORM_VALUES);
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [refreshGridTimestamp, setRefreshGridTimestamp] = useState<string>();

  const handleLinkTabClick = useEvent(() => {
    setActiveTab('link');
  });

  const handleEmailTabClick = useEvent(() => {
    setActiveTab('email');
  });

  const handleLinkFormChange = useEvent((values: ShareLinkFormValues) => {
    setLinkFormValues(values);

    // Synchronize certain fields between the link and the email forms.
    setEmailFormValues({
      ...emailFormValues,
      description: values.description,
      accessCode: values.accessCode,
      expiration: values.expiration,
    });
  });

  const handleEmailFormChange = useEvent((values: ShareEmailFormValues) => {
    setEmailFormValues(values);

    // Synchronize certain fields between the link and the email forms.
    setLinkFormValues({
      ...linkFormValues,
      description: values.description,
      accessCode: values.accessCode,
      expiration: values.expiration,
    });
  });

  const handleLinkFormSubmit = useEvent(async () => {
    if (linkFormValues.expiration == null) {
      throw new Error('Cannot proceed because the expiration field is null or undefined.');
    }

    const linkId = crypto.randomUUID();
    const expiration = ShareService.calculateShareExpiration(linkFormValues.expiration)?.toISOString();

    await apiClient.studyShare.addStudyShare({
      id: 0,
      linkId,
      email: null,
      shareType: ShareType.Link,
      studyShareExams: examId == null ? [] : [{ exam_id: examId }],
      sharePassword: hasText(linkFormValues.accessCode) ? linkFormValues.accessCode : null,
      sharePatientId: examId == null ? patient.id : null, // Note that for patient-wide shares we need to populate the SharePatientId property.  For exam-specific shares however we need to OMIT the SharePatientId field.  This is for compatibility reasons with the legacy application.
      expireOn: expiration ?? null,
      dateCreated: null,
      message: linkFormValues.description,
    });

    setShareUrl(`${window.location.origin}/share/${linkId}`);
  });

  const handleEmailFormSubmit = useEvent(async () => {
    if (emailFormValues.expiration == null) {
      throw new Error('Cannot proceed because the expiration field is null or undefined.');
    }

    const linkId = crypto.randomUUID();
    const expiration = ShareService.calculateShareExpiration(emailFormValues.expiration)?.toISOString();

    await apiClient.studyShare.addStudyShare({
      id: 0,
      linkId,
      email: emailFormValues.email,
      shareType: ShareType.Email,
      studyShareExams: examId == null ? [] : [{ exam_id: examId }],
      sharePassword: hasText(emailFormValues.accessCode) ? emailFormValues.accessCode : null,
      sharePatientId: examId == null ? patient.id : null, // Note that for patient-wide shares we need to populate the SharePatientId property.  For exam-specific shares however we need to OMIT the SharePatientId field.  This is for compatibility reasons with the legacy application.
      expireOn: expiration ?? null,
      dateCreated: null,
      message: emailFormValues.description,
    });

    setShareUrl(`${window.location.origin}/share/${linkId}`);
  });

  const handleDeleteShareClick = useEvent(async (id: number) => {
    await apiClient.studyShare.deleteStudyShare(id);
    setRefreshGridTimestamp(new Date().toISOString());
  });

  const handleModalHide = useEvent(() => {
    // Reset the modal to initial state.
    setActiveTab('link');
    setLinkFormValues(DEFAULT_SHARE_LINK_FORM_VALUES);
    setEmailFormValues(DEFAULT_SHARE_EMAIL_FORM_VALUES);
    setShareUrl(null);
    setRefreshGridTimestamp(undefined);

    onClose();
  });

  return (
    <StyledModal
      show={show}
      onHide={handleModalHide}
      title={!examId ? `Share ${patientVerbiage}` : 'Share Exam'}
      beforeTitleContent={<SharePatientHeader patient={patient} />}
      titleIcon={faShareNodes}
    >
      {shareUrl == null && (
        <>
          <StyledTabsContainer>
            <StyledTabButton type="button" $selected={activeTab === 'link'} $roundedCorners="left" onClick={handleLinkTabClick}>
              Link
            </StyledTabButton>
            <StyledTabButtonSeparator />
            <StyledTabButton type="button" $selected={activeTab === 'email'} $roundedCorners="right" onClick={handleEmailTabClick}>
              Email
            </StyledTabButton>
          </StyledTabsContainer>
          <StyledFormContainer $activeTab={activeTab}>
            {activeTab === 'link' && (
              <StyledShareLinkForm initialValues={linkFormValues} onChange={handleLinkFormChange} onSubmit={handleLinkFormSubmit} $activeTab={activeTab} />
            )}
            {activeTab === 'email' && (
              <StyledShareEmailForm initialValues={emailFormValues} onChange={handleEmailFormChange} onSubmit={handleEmailFormSubmit} $activeTab={activeTab} />
            )}
          </StyledFormContainer>
        </>
      )}
      {shareUrl != null && <StyledShareLinkSummary shareUrl={shareUrl} onClose={handleModalHide} />}

      <StyledShareGridContainer>
        <ShareGrid examId={examId} patientId={patient.id} refreshDataTimestamp={refreshGridTimestamp} onDeleteShareClick={handleDeleteShareClick} />
      </StyledShareGridContainer>
    </StyledModal>
  );
};

ShareModal.displayName = 'ShareModal';

const StyledModal = styled(Modal)`
  .modal-content {
    width: 1000px;
  }

  @media (max-width: 1200px) {
    .modal-content {
      width: 100%;
    }
  }
`;

const StyledTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 24px 0 24px;
`;

const StyledTabButton = styled.button<{
  $selected: boolean;
  $roundedCorners: 'left' | 'right';
}>`
  position: relative;
  background-color: inherit;
  font-size: 14px; // Figma mockup specified 14px instead of a standard typography size.
  border-style: solid;
  padding: 5px 16px; // Figma mockup specified non-standard pixel measurements.
  border-width: ${({ theme }) => theme.borderWidths.scale[1]};
  border-color: ${({ theme, $selected }) => ($selected ? theme.colors.palette.aquas[5] : theme.colors.palette.grayscale[4])};
  color: ${({ theme, $selected }) => ($selected ? theme.colors.palette.aquas[5] : theme.colors.palette.grayscale[9])};
  z-index: ${({ $selected }) => ($selected ? '1' : '0')};

  ${({ $roundedCorners }) => ($roundedCorners === 'left' ? 'border-right: none; border-top-left-radius: 1px; border-bottom-left-radius: 1px;' : '')}

  ${({ $roundedCorners }) => ($roundedCorners === 'right' ? 'border-left: none; border-top-right-radius: 1px; border-bottom-right-radius: 1px;' : '')}
`;

const StyledTabButtonSeparator = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.palette.aquas[5]};
`;

const StyledFormContainer = styled.div<{ $activeTab: 'link' | 'email' }>`
  display: grid;
  padding: 24px;
`;

const StyledShareLinkForm = styled(ShareLinkForm)<{
  $activeTab: 'link' | 'email';
}>`
  ${({ $activeTab }) => ($activeTab === 'email' ? 'visibility: hidden;' : '')}
`;

const StyledShareEmailForm = styled(ShareEmailForm)<{
  $activeTab: 'link' | 'email';
}>`
  ${({ $activeTab }) => ($activeTab === 'link' ? 'visibility: hidden;' : '')}
`;

const StyledShareLinkSummary = styled(ShareLinkSummary)`
  padding: 24px;
`;

const StyledShareGridContainer = styled.div`
  display: flex;
  padding: 14px 24px 24px;
  overflow: hidden;
`;
