import { ApiClient } from 'core/api';
import { DataStream } from 'core/utils';

import { AuthenticationScheme } from '../constants';
import { AuthenticationManagerHandle } from '../types/AuthenticationManagerHandle';
import { IAuthenticationScheme } from '../types/IAuthenticationScheme';
import { AnonymousAuthenticationScheme } from './AnonymousAuthenticationScheme';
import { OidcAuthenticationScheme } from './OidcAuthenticationScheme';
import { ShareAuthenticationScheme } from './ShareAuthenticationScheme';

export class AuthenticationManager {
  public readonly shareScheme: ShareAuthenticationScheme;

  public readonly oidcScheme: OidcAuthenticationScheme;

  public readonly anonymousScheme: AnonymousAuthenticationScheme;

  public readonly hub: {
    activeScheme: DataStream<AuthenticationScheme | undefined>;
  };

  constructor(apiClient: ApiClient) {
    this.setActiveScheme = this.setActiveScheme.bind(this);
    this.setActiveSchemeAnonymous = this.setActiveSchemeAnonymous.bind(this);
    this.initialize = this.initialize.bind(this);
    this.frontChannelLogoutOidc = this.frontChannelLogoutOidc.bind(this);

    const managerHandle: AuthenticationManagerHandle = {
      setActiveScheme: this.setActiveScheme,
      setActiveSchemeAnonymous: this.setActiveSchemeAnonymous,
      apiClient,
    };

    this.shareScheme = new ShareAuthenticationScheme(managerHandle);
    this.oidcScheme = new OidcAuthenticationScheme(managerHandle);
    this.anonymousScheme = new AnonymousAuthenticationScheme(managerHandle);

    this.hub = {
      activeScheme: new DataStream<AuthenticationScheme | undefined>(undefined),
    };
  }

  public async initialize() {
    const oidcResult = await this.oidcScheme.initialize();
    const shareResult = await this.shareScheme.initialize();
    await this.anonymousScheme.initialize();

    let newActiveScheme: IAuthenticationScheme;
    if (shareResult) newActiveScheme = this.shareScheme;
    else if (oidcResult) newActiveScheme = this.oidcScheme;
    else newActiveScheme = this.anonymousScheme;

    newActiveScheme.setActiveScheme();
    this.hub.activeScheme.emit(
      newActiveScheme instanceof OidcAuthenticationScheme
        ? AuthenticationScheme.OIDC
        : newActiveScheme instanceof ShareAuthenticationScheme
          ? AuthenticationScheme.SHARE
          : newActiveScheme instanceof AnonymousAuthenticationScheme
            ? AuthenticationScheme.ANONYMOUS
            : undefined,
    );
  }

  public async frontChannelLogoutOidc() {
    const result = await this.oidcScheme.frontChannelLogout();

    if (result) {
      this.anonymousScheme.setActiveScheme();
      this.setActiveScheme(AuthenticationScheme.ANONYMOUS);
    }

    return result;
  }

  private setActiveScheme(newActiveScheme: AuthenticationScheme) {
    this.hub.activeScheme.emit(newActiveScheme);
  }

  private setActiveSchemeAnonymous() {
    this.hub.activeScheme.emit(AuthenticationScheme.ANONYMOUS);
  }
}
