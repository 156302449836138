import { memo, useEffect, useState } from 'react';

import { PhysicianModel } from 'models';

import { useEvent } from 'core/hooks';

import { apiClient } from 'features/api';
import { useCurrentUser } from 'features/auth';
import { BaseExamGridPage } from 'features/exam/fragments/BaseExamGridPage';

import { PhysicianGridService } from '../services/physician-grid-service';

export const PhysicianDashboardPage = memo(() => {
  const { currentUser } = useCurrentUser(true);

  const [currentPhysician, setCurrentPhysician] = useState<PhysicianModel | null>();

  const initialize = useEvent(async () => {
    const newPhysician = await apiClient.physicianClient.getPhysicianByEmailAsync(currentUser.email);

    setCurrentPhysician(newPhysician);
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (currentPhysician == null) return null;

  return (
    <BaseExamGridPage featureView="Physician" title="Physician Dashboard" selectable={false} pickDefaultWorklist={PhysicianGridService.pickDefaultWorklist} />
  );
});

PhysicianDashboardPage.displayName = 'PhysicianDashboardPage';
