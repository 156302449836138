const isSuccessful = (response: Response) => response.ok;

const isUnauthorized = (response: Response) => response.status === 401;

const isForbidden = (response: Response) => response.status === 403;

const isEntityLocked = (statusCode: number) => statusCode === 423;

export const ApiHelpers = {
  isEntityLocked,
  isForbidden,
  isSuccessful,
  isUnauthorized,
};
