import { FunctionComponent, useCallback } from 'react';

import { ChipListChangeEvent } from '@progress/kendo-react-buttons';

import { Field } from 'core/forms';
import { Accordion, ChipList, DropdownWithValuesField } from 'core/ui';

import { AliveCorRhythmCategories } from 'features/exam';
import { AliveCorAcuityDefinitions } from 'features/exam/constants';

import HeartMonitorIcon from '../assets/heart-monitor.svg?react';
import { AliveCorService } from '../services';
import { AliveCorObservationsAccordionProps } from '../types';

export const AliveCoreObservationsAccordion: FunctionComponent<
  AliveCorObservationsAccordionProps
> = ({ eventKey, isFormDisabled, valueSetter, valueGetter }) => {
  const handleRhythmChipListChange = useCallback(
    (event: ChipListChangeEvent) => {
      const secondaryRhythms =
        valueGetter('Observations.secondaryRhythms') ?? [];

      const newOverRead = AliveCorService.calculateOverReadFromRhythms(
        event.value,
        secondaryRhythms,
      );

      const maxAcuity = AliveCorService.getMaxAcuity(
        event.value,
        secondaryRhythms,
      );

      valueSetter('Observations.acuity', {
        value: maxAcuity?.acuity ?? null,
      });

      valueSetter('overRead', {
        value: newOverRead,
      });
    },
    [valueGetter, valueSetter],
  );

  const handleSecondaryRhythmsChange = useCallback(
    (event: ChipListChangeEvent) => {
      const primaryRhythm: string | null =
        valueGetter('Observations.rhythm') ?? null;

      const secondaryRhythms: string[] = event.value ?? [];

      const newOverRead = AliveCorService.calculateOverReadFromRhythms(
        primaryRhythm,
        secondaryRhythms,
      );

      const maxAcuity = AliveCorService.getMaxAcuity(
        primaryRhythm,
        secondaryRhythms,
      );

      valueSetter('Observations.acuity', {
        value: maxAcuity?.acuity ?? null,
      });

      valueSetter('overRead', {
        value: newOverRead,
      });
    },
    [valueGetter, valueSetter],
  );

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={HeartMonitorIcon} title="Observations" />
      <Accordion.Body>
        <Field
          component={ChipList}
          disabled={isFormDisabled}
          name="Observations.rhythm"
          label="Sinus Mechanism"
          data={AliveCorRhythmCategories.SINUS_MECHANISM}
          textField="abbreviation"
          valueField="rhythm"
          selection="single"
          onChange={handleRhythmChipListChange}
        />
        <Field
          component={ChipList}
          disabled={isFormDisabled}
          name="Observations.rhythm"
          label="Supraventricular"
          data={AliveCorRhythmCategories.SUPRAVENTRICULAR}
          textField="abbreviation"
          valueField="rhythm"
          selection="single"
          onChange={handleRhythmChipListChange}
        />
        <Field
          component={ChipList}
          disabled={isFormDisabled}
          name="Observations.rhythm"
          label="Ventricular"
          data={AliveCorRhythmCategories.VENTRICULAR}
          textField="abbreviation"
          valueField="rhythm"
          selection="single"
          onChange={handleRhythmChipListChange}
        />
        <Field
          component={DropdownWithValuesField}
          disabled={isFormDisabled}
          name="Observations.rhythm"
          label="Rhythm"
          data={AliveCorRhythmCategories.DROPDOWN}
          textField="abbreviation"
          valueField="rhythm"
          dataItemKey="rhythm"
          isForPrimitiveValues
          filterable={false}
        />
        <Field
          component={ChipList}
          disabled={isFormDisabled}
          name="Observations.secondaryRhythms"
          label="Secondary Observations"
          data={AliveCorRhythmCategories.SECONDARY}
          textField="abbreviation"
          valueField="rhythm"
          selection="multiple"
          onChange={handleSecondaryRhythmsChange}
        />
        <Field
          name="Observations.acuity"
          label="Acuity"
          component={DropdownWithValuesField}
          data={AliveCorAcuityDefinitions}
          textField="acuity"
          valueField="acuity"
          dataItemKey="acuity"
          isForPrimitiveValues
          filterable={false}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

AliveCoreObservationsAccordion.displayName = 'AliveCoreObservationsAccordion';
