import { FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { useDataTableActions } from '../../useDataTableActions';
import { useDataTableExtras } from '../../useDataTableExtras';
import { ActionIcon } from './ActionIcon';
import { ActionListCellProps } from './ActionListCellProps';

export const ActionListCell: FunctionComponent<ActionListCellProps> = ({ className, dataItem, dataIndex }) => {
  const actions = useDataTableActions();
  const { dataItemKey } = useDataTableExtras();

  const content = useMemo(
    () => (
      <td className={className}>
        <StyledButtonsContainer>
          {actions?.map((action) => (
            <ActionIcon
              key={action.key}
              action={action}
              dataItem={dataItem}
              dataItemIndex={dataIndex}
              dataItemKey={dataItemKey}
              testingPrefix={`${ActionListCell.displayName}_action-button_${action.key}`}
            />
          ))}
        </StyledButtonsContainer>
      </td>
    ),
    [actions, className, dataIndex, dataItem, dataItemKey],
  );

  return content;
};

ActionListCell.displayName = 'ActionListCell';

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
