import { forwardRef } from 'react';

import { animated } from '@react-spring/web';
import cn from 'classnames';
import styled from 'styled-components';

import { AccordionScrollDivProps } from './AccordionScrollDivProps';

/** The immediate parent element of the AccordionDiv element.  It provides containment along the cross axis along with a scrollbar. */
export const AccordionScrollDiv = forwardRef<
  HTMLDivElement,
  AccordionScrollDivProps
>(({ className, mode = 'vertical', ...rest }, ref) => (
  <StyledAccordionScrollDiv
    ref={ref}
    className={cn(className, AccordionScrollDiv.displayName, {
      horizontal: mode === 'horizontal',
      vertical: mode === 'vertical',
    })}
    {...rest}
  />
));

AccordionScrollDiv.displayName = 'AccordionScrollDiv';

const StyledAccordionScrollDiv = styled(animated.div)`
  flex: 1 1 0;
  display: flex;

  &.horizontal {
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &.vertical {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;
