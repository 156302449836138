import { LocationTypeModel } from 'models';

const INITIAL_VALUES: LocationTypeModel = {
  id: 0,
  name: '',
  examUploadWarning: '',
  examCompleteNotification: false,
  active: true,
};

const getInitialValues = () => INITIAL_VALUES;

export const LocationTypeInitialValueService = {
  getInitialValues,
};
