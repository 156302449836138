import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { createAction } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import {
  ChangeExamStatusModel,
  ExamModel,
  ExamStatusTransitionModel,
  FileModel,
  ReadExamModel,
  SendExamDocumentsModel,
} from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';
import { downloadBlobAsFile } from 'core/utils';

import { ExamReadingRouteService } from '../../exam-reading/services';
import { ExamRouteService } from '../services';
import { examEntity } from './normalizr-schema';

const getAllForKendoGrid = createAsyncThunk(
  'exams/get-all-for-kendo-grid',
  async (
    {
      dataState,
      quickFilter,
      includeFiles,
    }: {
      dataState: DataSourceRequestState;
      quickFilter: string;
      includeFiles: boolean;
    },
    { dispatch },
  ) => {
    const queryStr = toDataSourceRequestString(dataState);
    const quickFilterStr = quickFilter ? `&quickFilter=${quickFilter}` : '';
    const includeFilesStr = includeFiles ? `&includeFiles=true` : '';

    const baseUrl = ExamRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}${quickFilterStr}${includeFilesStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [examEntity]);

    return { ...normalized, total: result.total };
  },
);

const getById = createAsyncThunk(
  'exams/get-by-id',
  async (examId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(ExamRouteService.getByIdRoute(examId)),
    )) as unknown as ApiResponse<ExamModel>;

    return result;
  },
);

const getByIdAndLock = createAsyncThunk(
  'exams/get-by-id-and-lock',
  async (examId: number, { dispatch }) => {
    const result = (await dispatch(
      ApiActions.get(ExamRouteService.getByIdAndLockRoute(examId)),
    )) as unknown as ApiResponse<ExamModel>;
    return result;
  },
);

const sendDocuments = createAsyncThunk(
  'exams/send-documents',
  async (
    { patientFileId, sendType, sendTo, destinationId }: SendExamDocumentsModel,
    { dispatch },
  ) => {
    let queryStr = `PatientFileId=${patientFileId}&SendType=${sendType}&SendTo=${sendTo}`;
    if (destinationId) {
      queryStr += `&destinationId=${destinationId}`;
    }
    const baseUrl = ExamRouteService.getSendDocumentsRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.post(url),
    )) as unknown as ApiResponse<boolean>;

    return result;
  },
);

const generateReport = createAsyncThunk(
  'exams/generate-report',
  async (
    { patientFileId, examId }: { patientFileId: number | null; examId: number },
    { dispatch },
  ) => {
    const queryStr = `patientFileId=${patientFileId}`;
    const baseUrl = ExamRouteService.getGenerateReportRoute(examId);
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.post(url),
    )) as unknown as ApiResponse<FileModel>;

    return result;
  },
);

const bulkAssignToPhysician = createAsyncThunk(
  'exams/bulk-assign-to-physician',
  async (
    { physicianId, examIds }: { physicianId: number; examIds: number[] },
    { dispatch },
  ) => {
    const queryStr = `physicianId=${physicianId}${examIds
      .map((id) => `&examIds=${id}`)
      .join('')}`;
    const baseUrl = ExamRouteService.getBulkAssignToPhysiciansRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.post(url),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) as unknown as ApiResponse<any>;

    return result;
  },
);

const exportToCSV = createAsyncThunk(
  'exams/export-to-csv',
  async (
    {
      dataState,
      quickFilter,
    }: { dataState: DataSourceRequestState; quickFilter?: string | null },
    { dispatch },
  ) => {
    const queryStr = toDataSourceRequestString(dataState);
    const quickFilterStr = quickFilter ? `&quickFilter=${quickFilter}` : '';
    const baseUrl = ExamRouteService.getExportToCSVRoute();
    const url = `${baseUrl}?${queryStr}${quickFilterStr}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = (await dispatch(ApiActions.get(url))) as any;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const result = await response.text();

    downloadBlobAsFile(result, 'csv', 'exams');

    return {};
  },
);

const add = createAsyncThunk(
  'exams/add',
  async (exam: ExamModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: ExamRouteService.getBaseRoute(),
        body: exam,
      }),
    )) as unknown as ApiResponse<ExamModel>;

    const normalized = normalize({ ...exam, id: result.id }, examEntity);

    return normalized.entities;
  },
);

const addRead = createAsyncThunk(
  'exams/add-read',
  async (examReadData: ReadExamModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: ExamReadingRouteService.getReadRoute(examReadData.id),
        body: examReadData,
      }),
    )) as unknown as ApiResponse<ReadExamModel>;

    const normalized = normalize(
      { ...examReadData, id: result.id },
      examEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'exams/edit',
  async (exam: ExamModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: ExamRouteService.getBaseRoute(),
        body: exam,
      }),
    );

    const normalized = normalize(exam, examEntity);

    return normalized.entities;
  },
);

const changeStatus = createAsyncThunk(
  'exams/change-status',
  async (
    {
      examId,
      newStatus,
      cancelReason,
    }: { examId: number; newStatus: string; cancelReason: string },
    { dispatch },
  ) => {
    const url = ExamRouteService.getChangeStatusRoute(examId);
    const body: ChangeExamStatusModel = {
      id: examId,
      status: newStatus,
      cancelReason,
    };

    await dispatch(
      ApiActions.post({
        url,
        body,
      }),
    );

    return {};
  },
);

const bulkCancel = createAsyncThunk(
  'exams/bulk-cancel',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (body: any, { dispatch }) => {
    const url = ExamRouteService.bulkCancel();

    await dispatch(
      ApiActions.post({
        url,
        body,
      }),
    );
    return {};
  },
);

const getValidTransitions = createAsyncThunk(
  'exams/get-valid-transitions',
  async (examId: number, { dispatch }) => {
    const url = ExamRouteService.getValidTransitionsRoute(examId);

    const result = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<ExamStatusTransitionModel>;

    return result;
  },
);

const getNextExamId = createAsyncThunk(
  'exams/get-next-exam-id',
  async (
    {
      currentExamId,
      quickFilter,
      groupId,
      workListViewDataState,
    }: {
      currentExamId?: number | null;
      quickFilter?: string | null;
      groupId?: number | null;
      workListViewDataState: string | null;
    },
    { dispatch },
  ) => {
    const quickFilterStr = quickFilter ? `&quickFilter=${quickFilter}` : '';
    const groupIdStr = groupId ? `&groupId=${groupId}` : '';
    const currentExamIdStr = currentExamId
      ? `&currentExamId=${currentExamId}`
      : '';

    const baseUrl = ExamRouteService.getNextExamIdRoute();
    const url = `${baseUrl}?${workListViewDataState}${currentExamIdStr}${quickFilterStr}${groupIdStr}&checkLocking=true`;

    const result = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<number>;

    return result;
  },
);

const pushRecentlyViewedExam = createAction('exams/push-recently-viewed-queue');

const popRecentlyViewedExam = createAction('exams/pop-recently-viewed-queue');

const clearRecentlyViewedExams = createAction(
  'exams/clear-recently-viewed-queue',
);

const clearSendDocumentStatus = createAction(
  'exams/clear-send-document-status',
);

const clearExamSaveStatus = createAction('exams/clear-exam-save-status');

export const ExamActions = {
  add,
  addRead,
  bulkAssignToPhysician,
  bulkCancel,
  changeStatus,
  getValidTransitions,
  clearExamSaveStatus,
  clearRecentlyViewedExams,
  clearSendDocumentStatus,
  edit,
  exportToCSV,
  generateReport,
  getAllForKendoGrid,
  getById,
  getByIdAndLock,
  getNextExamId,
  popRecentlyViewedExam,
  pushRecentlyViewedExam,
  sendDocuments,
};
