import dayjs from 'dayjs';

import { PatientModel } from 'models';

import { unosRegex } from 'core/forms/services';
import { hasText } from 'core/utils';

import { PatientFormValues } from '../types';

function copyModelToForm(patient: PatientModel): PatientFormValues {
  return {
    id: patient.id,
    unosID: patient.unosID ?? '',
    active: patient.active,
    firstName: patient.firstName,
    lastName: patient.lastName,
    dob: patient.dob == null ? null : dayjs(patient.dob).toDate(),
    weight: patient.weight,
    height: patient.height,
    patientNumber: patient.patientNumber,
    caseID: patient.caseID ?? '',
    gender: patient.gender,
    crossClampDateTime: patient.crossClampDateTime,
    notes: patient.notes,
    hospital: patient.hospital,
    locationType: patient.locationType,
    ageRange: patient.ageRange,
    location: patient.location,
    isUnosUnavailable: patient.unosID?.toLowerCase() === 'unknown',
  };
}

function copyFormToModel({ dob, ...form }: PatientFormValues, originalModel: PatientModel): PatientModel {
  return {
    ...originalModel,
    ...form,
    dob: dob == null ? null : dob.toISOString(),
  };
}

const validateDonorForm = (patient: PatientModel | null | undefined) => {
  if (!patient) {
    return false;
  }
  let passesUnos;
  let passesCaseId;
  const unosId = patient?.unosID;
  if (unosId?.toLowerCase() === 'unknown') {
    passesUnos = true;
  } else {
    passesUnos = unosRegex.test(unosId ?? '');
  }
  const caseId = patient?.caseID;
  if (hasText(caseId)) {
    passesCaseId = true;
  }

  return passesUnos || passesCaseId;
};

export const PatientEditService = {
  copyModelToForm,
  copyFormToModel,
  validateDonorForm,
};
