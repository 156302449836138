import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  createStyledFormElement,
} from 'core/forms';
import { Button, Input, Label, MaskedInput } from 'core/ui';

import { locationFormValidators as validators } from '../services';
import { ContactTabContentProps, ContactTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const ContactTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof ContactTabFormValues) => any;
  onChange: (values: ContactTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    const emptyPhoneMask = '(___) ___-____';
    onChange({
      address1: valueGetter('address1'),
      address2: valueGetter('address2'),
      state: valueGetter('state'),
      city: valueGetter('city'),
      zip: valueGetter('zip'),
      phone:
        valueGetter('phone') === emptyPhoneMask ? '' : valueGetter('phone'),
      phone2:
        valueGetter('phone2') === emptyPhoneMask ? '' : valueGetter('phone2'),
      fax: valueGetter('fax') === emptyPhoneMask ? '' : valueGetter('fax'),
      fax2: valueGetter('fax2') === emptyPhoneMask ? '' : valueGetter('fax2'),
      email: valueGetter('email'),
      email2: valueGetter('email2'),
      mailingAddress: valueGetter('mailingAddress'),
      siteContact: valueGetter('siteContact'),
      contactTitle: valueGetter('contactTitle'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="address1">Address 1</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="address1"
            editorId="address1"
            component={Input}
            validator={validators.address1}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="address2">Address 2</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="address2"
            editorId="address2"
            component={Input}
            validator={validators.address2}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="state">State</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="state"
            editorId="state"
            component={Input}
            validator={validators.state}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="city">City</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="city"
            editorId="city"
            component={Input}
            validator={validators.city}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="zip">Zip</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="zip"
            editorId="zip"
            component={Input}
            validator={validators.zip}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="phone">Phone</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MaskedInput}
            editorId="phone"
            mask="(999) 000-0000"
            name="phone"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="phone2">Phone 2</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MaskedInput}
            editorId="phone2"
            mask="(999) 000-0000"
            name="phone2"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="fax">Fax</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MaskedInput}
            editorId="fax"
            mask="(999) 000-0000"
            name="fax"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="fax2">Fax 2</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MaskedInput}
            editorId="fax2"
            mask="(999) 000-0000"
            name="fax2"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="email">Email</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="email"
            name="email"
            validator={validators.email}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="email2">Email 2</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="email2"
            name="email2"
            validator={validators.email2}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="mailingAddress">Mailing Address</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            label="Mailing Address"
            name="mailingAddress"
            validator={validators.mailingAddress}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="siteContact">Site Contact</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="siteContact"
            name="siteContact"
            validator={validators.siteContact}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="contactTitle">Contact Title</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="contactTitle"
            name="contactTitle"
            validator={validators.contactTitle}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn>
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

ContactTabContentInner.displayName = 'ContactTabContentInner';

export const ContactTabContent: FunctionComponent<ContactTabContentProps> = ({
  initialValues,
  onSubmit,
  onChange,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <ContactTabContentInner valueGetter={valueGetter} onChange={onChange} />
      )}
    />
  );
};

ContactTabContent.displayName = 'ContactTabContent';
