import { FunctionComponent, memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { FormRoutes } from '../routes';
import { AddUpdateForm } from './AddUpdateForm';
import { FormGrid } from './FormGrid';

export const Form: FunctionComponent = memo(() => {
  return (
    <Routes>
      <Route
        path={FormRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <FormGrid />
          </RouteGuard>
        }
      />
      <Route
        path={FormRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={FormGrid}>
            <AddUpdateForm />
          </RouteGuard>
        }
      />
      <Route
        path={FormRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={FormGrid}>
            <AddUpdateForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

Form.displayName = 'Form';
