import { ShareLandingFormValues } from './types';

export const SHARE_LANDING_FORM_DEFAULTS: ShareLandingFormValues = {
  accessCode: '',
  consent: false,
};

export enum ShareAuthenticationErrorCode {
  HipaaConsentNotAccepted = 'hipaa-consent-not-accepted',
  ShareNotFound = 'share-not-found',
  ShareExpired = 'share-expired',
  PasswordValidationFailed = 'password-validation-failed',
}

export const ViewConstants = {
  thumbnailMainAxisSize: 140,
  thumbnailCrossAxisMinSize: 120,
  thumbnailWidth: 120,
  thumbnailHeight: 140,
  thumbnailBorderSize: 4,
} as const;

export const UploadStatusConstants = {
  uploading: 'uploading',
  uploaded: 'uploaded',
  failed: 'failed',
} as const;
