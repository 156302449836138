import { ExamReadsDataService } from './data-service';

const getExamInitialValues = (exam) => {
  return {
    biopsyType: exam.biopsyType,
    imageQuality: exam.imageQuality,
    internalNotes: exam.internalNotes,
    laterality: exam.laterality,
    organ: exam.organ,
    service: exam.service,
    slidePreparation: exam.slidePreparation,
    overRead: exam.overRead ?? '',
    interpretation: exam.interpretation,
  };
};

const getExamReadInitialValues = (exam) => {
  return ExamReadsDataService.mapApiDataToExam(exam.dynamicFormFields);
};

const getInitialValues = (exam) => ({
  ...getExamInitialValues(exam),
  ...getExamReadInitialValues(exam),
});

export const InitialValuesService = {
  getInitialValues,
};
