import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { NotificationsService } from 'core/notifications';
import { Button, ButtonVariants } from 'core/ui';

import { useAccessTokenSnapshot } from 'features/auth';
import { ExamStatus } from 'features/exam/constants';
import { useNextExamId } from 'features/exam/hooks';
import { FileRouteService } from 'features/file';
import { useUserSettings } from 'features/settings';
import { PDFViewer } from 'features/file/fragments/PDFViewer';

import { ExamReadingActions } from '../redux';
import { UndoAddReadMessage } from './UndoAddReadMessage';

export const Approve = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { homePageUrl } = useUserSettings(true);
  const [searchParams] = useSearchParams();
  const { accessToken } = useAccessTokenSnapshot();

  const groupId = searchParams.get('groupId');

  // get worklist filter
  const worklistView = localStorage.getItem('currentWorklistViewODataStr');
  const nextExamId = useNextExamId(
    id,
    ExamStatus.ALL.name,
    groupId,
    worklistView,
  );

  const handleClose = useCallback(() => {
    navigate(`/exam/${id}/read`);
  }, [navigate, id]);

  const handleApprove = useCallback(() => {
    dispatch(ExamReadingActions.approveRead(id)).then(() => {
      window.location = homePageUrl;
    });
  }, [dispatch, homePageUrl, id]);

  const handleUndoClick = useCallback(
    (undoExamId) => {
      navigate(`/exam/${undoExamId}/read?${searchParams}`);
    },
    [navigate, searchParams],
  );

  const handleApproveAndNext = useCallback(() => {
    dispatch(ExamReadingActions.approveRead(id)).then(() => {
      navigate(`/exam/${nextExamId}/read`);
      NotificationsService.displaySuccess(
        <UndoAddReadMessage onUndo={() => handleUndoClick(id)} />,
        {
          autoClose: 60 * 1000, // 60s
          closeOnClick: false,
          hideProgressBar: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        },
      );
    });
  }, [dispatch, id, navigate, nextExamId, handleUndoClick]);

  return (
    <StyledApprove>
      <StyledPreview>
        <PDFViewer
          url={FileRouteService.getFinalReportPreviewRoute(id, accessToken)}
        />
      </StyledPreview>
      <StyledButtonContainer>
        <Button
          variant={ButtonVariants.SECONDARY}
          type="button"
          onClick={handleClose}
        >
          Close
        </Button>
        <StyledSpacer />
        <Button type="button" onClick={handleApprove}>
          Approve
        </Button>
        <StyledSpacer />
        <Button type="button" onClick={handleApproveAndNext}>
          Approve & Next
        </Button>
      </StyledButtonContainer>
    </StyledApprove>
  );
};

const StyledApprove = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content;
`;

const StyledPreview = styled.div`
  overflow-y: scroll;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
`;

const StyledSpacer = styled.div`
  width: 16px;
`;
