import { forwardRef } from 'react';

import { MaskedTextBoxHandle as KendoMaskedTextBoxHandle, MaskedTextBoxProps as KendoMaskedTextBoxProps, MaskedTextBox } from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { MaskedInputProps } from './MaskedInputProps';

export const MaskedInput = forwardRef<KendoMaskedTextBoxHandle, MaskedInputProps>(
  (
    {
      description,
      size = ComponentSizes.MEDIUM,
      disabled,
      hint,
      isOptionalLabelShown = false,
      label,
      name,
      placeholder,
      required,
      rules,
      tabIndex,
      valid,
      validationMessage,
      value,
      visited,
      ...rest
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';

    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    return (
      <div>
        {label && (
          <Label description={description} editorId={name} editorValid={valid} editorDisabled={disabled} required={required} optional={isLabeledAsOptional}>
            {label}
          </Label>
        )}
        <StyledMaskedInput
          {...rest}
          ref={ref}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          rules={rules}
          size={size}
          valid={valid}
          value={value ?? ''}
          tabIndex={tabIndex}
        />
        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
      </div>
    );
  },
);

MaskedInput.displayName = 'MaskedInput';

type StyledElementProps = {
  theme: DefaultTheme;
  disabled?: KendoMaskedTextBoxProps['disabled'];
  valid?: KendoMaskedTextBoxProps['valid'];
  size?: KendoMaskedTextBoxProps['size'];
};

const resolvePalette = ({ theme, disabled, valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      placeholderText: theme.colors.textSecondary,
      hint: theme.colors.textSecondary,
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      borderActive: theme.colors.borderDisabled,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    text: theme.colors.textPrimary,
    placeholderText: theme.colors.textSecondary,
    hint: theme.colors.textSecondary,
    background: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[4],
    caret: theme.colors.textPrimary,
  };

  if (!valid) {
    result.border = theme.colors.error;
    result.borderActive = theme.colors.error;
  }

  return result;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolvePlaceholderColor = (props: StyledElementProps) => {
  const { placeholderText } = resolvePalette(props);

  return placeholderText;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({ theme, valid, disabled }: StyledElementProps) => {
  if (disabled) {
    return 'none';
  }

  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${size}".`);
  }
};

const StyledMaskedInput = styled(MaskedTextBox)`
  box-sizing: border-box;
  font-size: ${resolveFontSize};
  line-height: ${resolveLineHeight};
  color: ${resolveColor};
  background-color: ${resolveBackgroundColor};
  border-color: ${resolveBorderColor};
  border-radius: ${({ theme }) => theme.radii.base};
  border-width: ${({ theme }) => theme.borderWidths.base};
  color: ${resolveColor};
  font-size: ${resolveFontSize};
  height: ${resolveHeight};

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    border-color: ${resolveActiveBorderColor};
    border-radius: ${({ theme }) => theme.radii.base};
    border-width: ${({ theme }) => theme.borderWidths.base};
  }

  &:active,
  &:focus,
  &:focus-within {
    box-shadow: ${resolveActiveBoxShadow};
  }

  & .k-input-inner {
    padding: ${({ theme }) => `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`};
  }

  &::placeholder {
    color: ${resolvePlaceholderColor};
  }

  &.k-disabled {
    filter: none;
    opacity: 1;
  }

  &.k-invalid {
    border-color: ${resolveBorderColor};
  }
`;
