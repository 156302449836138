import {
  ContactTabFormValues,
  CredentialsTabFormValues,
  GeneralTabFormValues,
  IntegrationTabFormValues,
  NotificationsTabFormValues,
} from './types';

export const PhysicianStatus = {
  PENDING: 'physician-pending',
  ADDED: 'physician-created',
  UPDATED: 'physician-updated',
  FETCHED: 'physician-fetched',
  ERROR: 'physician-error',
};

export const InitialValues = {
  nonFormFields: {
    id: 0,
    fax: '',
    company: '',
    emailNotificationREGExam: false,
  },
  generalForm: {
    signature: null,
    firstName: '',
    lastName: '',
    title: '',
    specialty: '',
    notes: '',
    active: true,
  } as GeneralTabFormValues,
  contactForm: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone1: '',
    phone2: '',
    email: '',
    smsEmail: '',
  } as ContactTabFormValues,
  credentialsForm: {
    npi: '',
  } as CredentialsTabFormValues,
  integrationForm: {
    britUser: '',
  } as IntegrationTabFormValues,
  notificationsForm: {
    emailNotificationSTATExam: false,
    emailConfirmation: false,
    emailConfirmationPDF: false,
  } as NotificationsTabFormValues,
};
