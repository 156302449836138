import { sortBy, uniqBy } from 'lodash';

import { ChipPreset } from 'core/ui';

import { AliveCorAcuity, AliveCorAcuityDefinition, AliveCorRhythmDefinition, ColumnState } from './types';

export enum ExamApiStatus {
  PENDING = 'exam-pending',
  ADDED = 'exam-created',
  UPDATED = 'exam-updated',
  FETCHED = 'exam-fetched',
  ERROR = 'exam-error',
}

export enum SendDocumentStatus {
  SUCCESS = 'document-send-success',
  PENDING = 'document-send-pending',
  ERROR = 'document-send-error',
}

export enum ExamLateStatus {
  LATE_OVER_50 = 'LateOver50',
  LATE_OVER_80 = 'LateOver80',
  LATE_OVER_100 = 'LateOver100',
}

export const ExamStatus = {
  IN_PROCESS: {
    name: 'In Process',
    value: 'In Process',
    tooltip: 'Exams that are not completed or cancelled',
  },
  NEW: {
    name: 'New',
    value: 'New',
    tooltip: 'New exams. These are not yet assigned to a physician',
  },
  ASSIGNED: {
    name: 'Assigned',
    value: 'Assigned',
    tooltip: 'Assigned but have not been read by a physician',
  },
  SENT: {
    name: 'Sent',
    value: 'Sent',
    tooltip: 'Exams sent to the physician',
  },
  READ: {
    name: 'Read',
    value: 'Read',
    tooltip: 'Physician read but not sent to the customer',
  },
  COMPLETE: {
    name: 'Complete',
    value: 'Complete',
    tooltip: 'Report has been delivered to the customer',
  },
  LATE: {
    name: 'Late',
    value: 'Late',
    tooltip: 'Has or is about to exceed the TAT. 50%/80%/100%+',
  },
  DUPLICATE: {
    name: 'Duplicate',
    value: 'Duplicate',
    tooltip: 'Exams marked as duplicate',
  },
  ALL: {
    name: 'All',
    value: 'All',
    tooltip: 'All exams. Used for searching',
  },
  CANCELLED: {
    name: 'Cancelled',
    value: 'Cancelled',
    tooltip: 'Exams marked as cancelled',
  },
  AIPROCESSING: {
    name: 'AIProcessing',
    value: 'AIProcessing',
    tooltip: 'Exams being processed by AI',
  },
  READYTOASSIGN: {
    name: 'ReadyToAssign',
    value: 'ReadyToAssign',
    tooltip: 'Exam is ready to be assigned to a physician',
  },
} as const;

export const ContextMenuItems = {
  activityLogs: {
    text: 'Activity Logs',
  },
  examStatusLogs: {
    text: 'Exam Status Logs',
  },
  changeStatus: {
    text: 'Change Status',
  },
  cancelExam: {
    text: 'Cancel Exam',
  },
  activateExam: {
    text: 'Mark Exam as Active',
  },
  markDuplicate: {
    text: 'Mark Exam As Duplicate',
  },
} as const;

export const ChangeExamDropdownStatus = {
  ACTIVE: {
    name: 'Active',
    value: 'Active',
  },
  CANCELLED: {
    name: 'Cancelled',
    value: 'Cancelled',
  },
  DUPLICATE: {
    name: 'Duplicate',
    value: 'Duplicate',
  },
  RESET_READ: {
    name: 'Reset Read',
    value: 'Reset Read',
  },
};

export const ExamSources = [
  {
    name: 'Unknown',
    value: '',
  },
  {
    name: '3rd Party',
    value: '3P',
  },
  {
    name: 'BRIT',
    value: 'BRIT',
  },
  {
    name: 'CardioSoft',
    value: 'CS',
  },
  {
    name: 'EliLink',
    value: 'EL',
  },
  {
    name: 'Email',
    value: 'EM',
  },
  {
    name: 'E-Scribe',
    value: 'ES',
  },
  {
    name: 'Fax',
    value: 'FX',
  },
  {
    name: 'Muse',
    value: 'MS',
  },
  {
    name: 'SEMAnet',
    value: 'SM',
  },
  {
    name: 'Study Cast',
    value: 'SC',
  },
] as const;

export const ExamMethods = [
  {
    name: 'Unknown',
    value: '',
  },
  {
    name: 'Digital',
    value: 'DG',
  },
  {
    name: 'Analogue',
    value: 'AG',
  },
] as const;

export const ExamDevices = [
  {
    name: 'Unknown',
    value: '',
  },
  {
    name: 'AT102',
    value: 'AT102',
  },
  {
    name: 'ELI110',
    value: 'ELI110',
  },
  {
    name: 'ELI150',
    value: 'ELI150',
  },
  {
    name: 'ELI150C',
    value: 'ELI150C',
  },
  {
    name: 'ELI-250',
    value: 'ELI-250',
  },
  {
    name: 'ELI250C',
    value: 'ELI250C',
  },
  {
    name: 'MAC800',
    value: 'MAC800',
  },
  {
    name: 'MAC2000',
    value: 'MAC2000',
  },
  {
    name: 'MAC1200',
    value: 'MAC1200',
  },
] as const;

export enum FormStates {
  ADD = 'add',
  EDIT = 'edit',
  DISABLED = 'disabled',
}

export const SendDocumentMethods = {
  EMAIL: {
    name: 'Email',
    value: 'Email',
  },
  FAX: {
    name: 'Fax',
    value: 'Fax',
  },
  PHYSICIAN_EMAIL: {
    name: 'Physician Email',
    value: 'Physician Email',
  },
} as const;

export const SendDocumentMethodsWithEHR = {
  EHR: {
    name: 'EHR Integration',
    value: 'EHR Integration',
  },
} as const;

export const SendDocumentMethodsDicom = {
  DICOM: {
    name: 'Dicom',
    value: 'Dicom',
  },
} as const;

export const DocumentCategories = {
  EXAM: {
    name: 'Exam',
    value: 1,
    shorthand: 'Exam',
  },
  COVER_PAGE: {
    name: 'Cover Page',
    value: 2,
    shorthand: 'Cover Page',
  },
  FINAL_REPORT: {
    name: 'Final Report',
    value: 3,
    shorthand: 'Final Report',
  },
  KEY_IMAGE: {
    name: 'Key Image',
    value: 4,
    shorthand: 'Key Image',
  },
  ORDER: {
    name: 'Order',
    value: 5,
    shorthand: 'Order',
  },
  DATA: {
    name: 'Data',
    value: 6,
    shorthand: 'Data',
  },
  DOCUMENT: {
    name: 'Document',
    value: 7,
    shorthand: 'Document',
  },
  EXAMAI: {
    name: 'Exam AI',
    value: 8,
    shorthand: 'Exam AI',
  },
  Worksheet: {
    name: 'Worksheet',
    value: 9,
    shorthand: 'Worksheet',
  },
} as const;

export const DefaultPatientSearchDataState = {
  skip: 0,
  take: 5,
} as const;

export const AliveCorExamTypes = {
  SINGLE_LEAD: ['SINGLE LEAD', 'ECG-1L'],
  SIX_LEAD: ['6L-ECG'],
};

export const ExamTypes = {
  ALIVE_COR: [...AliveCorExamTypes.SINGLE_LEAD, ...AliveCorExamTypes.SIX_LEAD],
  EKG: ['EKG', 'MANUAL QTC'],
  PATHOLOGY: ['PATHOLOGY'],
  PULMONOLOGY: ['Pulmonology', 'ES', 'Bronchoscopy'],
  RADIOLOGY: ['Radiology', 'CR', 'DX', 'XRAY'],
  XA: ['XA', 'CATH'],
  US: ['US', 'Ultrasound'],
  USSR: ['US/SR'],
  SR: ['SR'],
  ECHO: ['Echo'],
  CT: ['CT'],
};

export const BiopsyTypes = {
  CORE_NEEDLE: {
    name: 'Core Needle',
    value: 1,
  },
  WEDGE: {
    name: 'Wedge',
    value: 2,
  },
} as const;

export const SlidePreparations = {
  FROZEN: {
    name: 'Frozen',
    value: 1,
  },
  PERMANENT: {
    name: 'Permanent',
    value: 2,
  },
  RAPID_PERMANENT: {
    name: 'Rapid Permanent',
    value: 3,
  },
} as const;

export const Organs = {
  KIDNEY: {
    name: 'Kidney',
    value: 1,
  },
  LIVER: {
    name: 'Liver',
    value: 2,
  },
  LUNGS: {
    name: 'Lungs',
    value: 3,
  },
  OTHER: {
    name: 'Other',
    value: 99,
  },
} as const;

export const CTOrganVolumes = {
  CARDIAC: {
    name: 'Cardiac',
    value: 1,
  },
  KIDNEY: {
    name: 'Kidney',
    value: 2,
  },
  LIVER: {
    name: 'Liver',
    tooltip: 'Split liver requires CT with contrast',
    value: 3,
  },
  LUNGS: {
    name: 'Lungs',
    value: 4,
  },
} as const;

export const Lateralities = {
  LEFT: {
    name: 'Left',
    value: 1,
  },
  RIGHT: {
    name: 'Right',
    value: 2,
  },
  NA: {
    name: 'NA',
    value: 3,
  },
} as const;

export const ReadingTypes = {
  GENERAL: {
    name: 'General Biopsy Read',
    value: '1',
  },
  TARGETED: {
    name: 'Targeted Biopsy Read',
    value: '2',
  },
} as const;

export const HistologyQualities = {
  GOOD: {
    name: 'Good',
    value: '1',
  },
  PARTIALLY_UNINTERPRETABLE: {
    name: 'Partially Uninterpretable',
    value: '2',
  },
  UNINTERPRETABLE: {
    name: 'Uninterpretable',
    value: '3',
  },
} as const;

export const HistologyQualitiesUnknown = 'Unknown';

export const DefaultColumnsState: Record<string, ColumnState> = {
  id: { show: true, orderIndex: 1 },
  serviceDescription: { show: true, orderIndex: 2 },
  patientNumber: { show: true, orderIndex: 3 },
  unosID: { show: true, orderIndex: 4 },
  fullName: { show: true, orderIndex: 5 },
  dob: { show: true, orderIndex: 6 },
  locationCode: { show: true, orderIndex: 7 },
  locationName: { show: true, orderIndex: 8 },
  sla: { show: true, orderIndex: 9 },
  slaDate: { show: true, orderIndex: 10 },
  slaRemaining: { show: true, orderIndex: 11 },
  tatPercent: { show: true, orderIndex: 12 },
  source: { show: true, orderIndex: 13 },
  studyDate: { show: true, orderIndex: 14 },
  uploadedDate: { show: true, orderIndex: 15 },
  requestedDate: { show: true, orderIndex: 16 },
  assignedDate: { show: true, orderIndex: 17 },
  physicianLastName: { show: true, orderIndex: 18 },
  returnedDate: { show: true, orderIndex: 19 },
  completedDate: { show: true, orderIndex: 20 },
  customerUID: { show: true, orderIndex: 21 },
  serialNumber: { show: true, orderIndex: 22 },
  cartID: { show: true, orderIndex: 23 },
  device: { show: true, orderIndex: 24 },
  oldCode: { show: true, orderIndex: 25 },
  statusType: { show: true, orderIndex: 26 },
  locationType: { show: true, orderIndex: 27 },
  organ: { show: false, orderIndex: 28 },
  laterality: { show: false, orderIndex: 29 },
  suid: { show: false, orderIndex: 30 },
  active: { show: false, orderIndex: 31 },
  imageCount: { show: false, orderIndex: 32 },
  description: { show: false, orderIndex: 33 },
  tags: { show: false, orderIndex: 34 },
  requestReportDestination: { show: false, orderIndex: 35 },
  services: { show: true, orderIndex: 36 },
  hospital: { show: false, orderIndex: 37 },
};

export const PatientColumnsState: Record<string, ColumnState> = {
  id: { show: true, orderIndex: 1 },
  serviceDescription: { show: true, orderIndex: 2 },
  organ: { show: true, orderIndex: 3 },
  laterality: { show: true, orderIndex: 4 },
  description: { show: true, orderIndex: 5 },
  customerUID: { show: true, orderIndex: 6 },
  studyDate: { show: true, orderIndex: 7 },
  uploadedDate: { show: true, orderIndex: 8 },
  requestedDate: { show: true, orderIndex: 9 },
  sla: { show: true, orderIndex: 10 },
  completedDate: { show: true, orderIndex: 11 },
  locationName: { show: true, orderIndex: 12 },
  notes: { show: true, orderIndex: 13 },
  share: { show: true, orderIndex: 14 },
};

export const AliveCorAcuityDefinitions: AliveCorAcuityDefinition[] = [
  {
    acuity: AliveCorAcuity.Stable,
    severityOrder: 0,
    statement:
      'Your cardiac rhythm is STABLE and does not require immediate evaluation. The report should, however, be forwarded to your physician for his/her review, advice and inclusion in your permanent medical record. If you have any medical symptoms or concerns, contact or see your physician promptly.',
  },
  {
    acuity: AliveCorAcuity.NeedsReview,
    severityOrder: 1,
    statement: 'FORWARD this report to your physician within 24 hours for his/her advice regarding further evaluation.',
  },
  {
    acuity: AliveCorAcuity.Serious,
    severityOrder: 3,
    statement: 'IMMEDIATE physician evaluation is strongly advised.',
  },
  {
    acuity: AliveCorAcuity.NotAcceptable,
    severityOrder: 2,
    statement: 'Baseline noise makes it difficult to do a reliable analysis; consider repeating study.',
  },
];

export const AliveCorRhythmCategories: {
  SINUS_MECHANISM: AliveCorRhythmDefinition[];
  SUPRAVENTRICULAR: AliveCorRhythmDefinition[];
  VENTRICULAR: AliveCorRhythmDefinition[];
  SECONDARY: AliveCorRhythmDefinition[];
  OTHER: AliveCorRhythmDefinition[];
  DROPDOWN: AliveCorRhythmDefinition[];
  /** Contains all of the rhythm definitions from: SINUS_MECHANISM, SUPRAVENTRICULAR, VENTRICULAR, and DROPDOWN. */
  PRIMARY: AliveCorRhythmDefinition[];
} = {
  SINUS_MECHANISM: [
    {
      rhythm: 'NSR',
      abbreviation: 'NSR',
      meaning: 'NORMAL SINUS RHYTHM',
      finding: 'The normal heart rate and rhythm of the heart.',
      acuity: AliveCorAcuity.Stable,
      primaryRhythm: null,
      dropdownOrder: 0,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: 'SN',
      abbreviation: 'SN',
      meaning: 'SINUS RHYTHM',
      finding: 'Sinus rhythm.',
      acuity: AliveCorAcuity.Stable,
      primaryRhythm: null,
      dropdownOrder: 1,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: 'SB',
      abbreviation: 'SB',
      meaning: 'SINUS BRADYCARDIA',
      finding: 'Normal heart rhythm with heart rate less than 50 beats per minute.',
      acuity: AliveCorAcuity.Stable,
      primaryRhythm: null,
      dropdownOrder: 2,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: 'ST',
      abbreviation: 'ST',
      meaning: 'SINUS TACHYCARDIA',
      finding: 'Normal heart rhythm with heart rate greater than 100 beats per minute.',
      acuity: AliveCorAcuity.Stable,
      primaryRhythm: null,
      dropdownOrder: 3,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: 'SA',
      abbreviation: 'SA',
      meaning: 'SINUS ARRHYTHMIA',
      finding: 'A variation of normal heart rhythm due to respiration.',
      acuity: AliveCorAcuity.Stable,
      primaryRhythm: null,
      dropdownOrder: 17,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: 'SSS',
      abbreviation: 'SSS',
      meaning: 'SICK SINUS SYNDROME',
      finding: "An abnormal heart rhythm due to malfunction of the heart's primary pacemaker.",
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 22,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'N/A',
      abbreviation: 'N/A',
      meaning: 'NOT APPLICABLE',
      finding: 'Artifact precludes interpretation; consider repeating.',
      acuity: AliveCorAcuity.NotAcceptable,
      primaryRhythm: null,
      dropdownOrder: 1000,
      chipPreset: ChipPreset.GREY,
    },
  ],
  SUPRAVENTRICULAR: [
    {
      rhythm: 'AF',
      abbreviation: 'AF',
      meaning: 'ATRIAL FIBRILLATION',
      finding: 'An abnormal heart rhythm due to an irregular electrical discharge in the top chamber of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 6,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'AF/RVR',
      abbreviation: 'AF/RVR',
      meaning: 'ATRIAL FIBRILLATION WITH RAPID VENTRICULAR RESPONSE',
      finding: 'An abnormal heart rhythm due to electrical discharges in the top chamber of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 8,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'AFL',
      abbreviation: 'AFL',
      meaning: 'ATRIAL FLUTTER',
      finding: 'An abnormal heart rhythm due to a regular or irregular electrical discharge in the top chamber of the heart .',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 7,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'SVT', // NOTE: There is another rhythm definition for "SVT" that is used for the secondary rhythm.  This is intentional because they have slightly different metadata depending on whether it's referenced as a primary or secondary observation.
      abbreviation: 'SVT',
      meaning: 'SUPRAVENTRICULAR TACHYCARDIA',
      finding: 'An abnormal and rapid heart rhythm arising from the top chamber of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 21,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'AT',
      abbreviation: 'AT',
      meaning: 'ATRIAL TACHYCARDIA',
      finding: 'An abnormal and rapid heart rhythm arising from the top chamber of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 4,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'JR',
      abbreviation: 'JR',
      meaning: 'JUNCTIONAL RHYTHM',
      finding: 'An abnormal heart rhythm due to an electrical discharge in the middle of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 10,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'RRO',
      abbreviation: 'RRO',
      meaning: 'REGULAR RHYTHM, UNDETERMINED ORIGIN',
      finding: 'A potentially abnormal  rhythm whereby the origin of the electrical activity cannot be determined.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 20,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'SRSA',
      abbreviation: 'SRSA',
      meaning: 'SINUS RHYTHM WITH SINGLE PACS',
      finding: 'The normal heart rate and rhythm of the heart with one or more beats originating from an electrical discharge in the top of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 19,
      chipPreset: ChipPreset.ORANGE,
    },
  ],
  VENTRICULAR: [
    {
      rhythm: 'VT',
      abbreviation: 'VT',
      meaning: 'VENTRICULAR TACHYCARDIA',
      finding: 'An abnormal and dangerous rapid heart rhythm due to electrical discharges in the bottom of the heart.',
      acuity: AliveCorAcuity.Serious,
      primaryRhythm: null,
      dropdownOrder: 13,
      chipPreset: ChipPreset.RED,
    },
    {
      rhythm: 'IVR',
      abbreviation: 'IVR',
      meaning: 'IDIOVENTRICULAR RHYTHM',
      finding: 'An abnormal and slow heart rhythm due to an electrical discharge in the bottom of the heart.',
      acuity: AliveCorAcuity.Serious,
      primaryRhythm: null,
      dropdownOrder: 12,
      chipPreset: ChipPreset.RED,
    },
    {
      rhythm: 'VBIG',
      abbreviation: 'VBIG',
      meaning: 'VENTRICULAR BIGEMINY',
      finding: 'An abnormal heart rhythm where every other beat originates from an electrical discharge in the bottom of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 15,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'VTRI',
      abbreviation: 'VTRI',
      meaning: 'VENTRICULAR TRIGEMINY',
      finding: 'An abnormal heart rhythm where every third beat originates from an electrical discharge in the bottom of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 16,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'SRSV',
      abbreviation: 'SRSV',
      meaning: 'SINUS RHYTHM WITH SINGLE PVCS',
      finding: 'The normal heart rate and rhythm of the heart with one or more beats originating from an electrical discharge in the bottom of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 18,
      chipPreset: ChipPreset.ORANGE,
    },
  ],
  SECONDARY: [
    {
      rhythm: 'PVCs',
      abbreviation: 'PVCs',
      meaning: 'Premature ventricular contractions',
      finding: 'Premature ventricular contractions also present.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: 'SN',
      dropdownOrder: null,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'PACs',
      abbreviation: 'PACs',
      meaning: 'Premature atrial contractions',
      finding: 'Premature atrial contractions also present.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.NeedsReview,
      dropdownOrder: null,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'SVT', // NOTE: There is another rhythm definition for "SVT" that is used for the primary rhythm.  This is intentional because they have slightly different metadata depending on whether it's referenced as a primary or secondary observation.
      abbreviation: 'SVT',
      meaning: 'Supraventricular tachycardia',
      finding: 'Supraventricular tachycardia also present.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.NeedsReview,
      dropdownOrder: null,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: 'WideQRS',
      abbreviation: 'Wide QRS',
      meaning: 'Wide QRS',
      finding: 'Wide QRS also present.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.NeedsReview,
      dropdownOrder: null,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: '1st_Degree',
      abbreviation: 'AV1',
      meaning: '1st Degree AV block',
      finding: 'Slightly prolonged conduction from the upper to the lower chamber of the heart, usually of no clinical significance.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.Stable,
      dropdownOrder: null,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: '2nd_Degree_Mobitz_I',
      abbreviation: 'AV2-1',
      meaning: '2nd Degree Mobitz I AV block',
      finding:
        'Progressively increasing delay in conduction from the upper to the lower chamber of the heart until the lower chamber skips a beat, usually of no clinical significance.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.Stable,
      dropdownOrder: null,
      chipPreset: ChipPreset.GREEN,
    },
    {
      rhythm: '2nd_Degree_Mobitz_II',
      abbreviation: 'AV2-2',
      meaning: '2nd Degree Mobitz II AV block',
      finding: 'Regular failure of conduction from the upper to the lower chamber. Needs prompt clinical follow-up and may be an indication for a pacemaker.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.NeedsReview,
      dropdownOrder: null,
      chipPreset: ChipPreset.ORANGE,
    },
    {
      rhythm: '3rd_Degree',
      abbreviation: 'AV3',
      meaning: '3rd Degree (Complete) AV block',
      finding:
        'Complete Heart Block. No conduction from the upper to the lower chamber. Potentially dangerous and usually requires a pacemaker. Immediate attention recommended.',
      primaryRhythm: 'SN',
      acuity: AliveCorAcuity.Serious,
      dropdownOrder: null,
      chipPreset: ChipPreset.RED,
    },
  ],
  OTHER: [
    {
      rhythm: 'MAT',
      abbreviation: 'MAT',
      meaning: 'MULTIFOCAL ATRIAL TACHYCARDIA',
      finding: 'An abnormal and rapid heart rhythm arising from multiple locations in the top chamber of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 5,
      chipPreset: null,
    },
    {
      rhythm: 'AFLRR',
      abbreviation: 'AFLRR',
      meaning: 'ATRIAL FLUTTER WITH RAPID VENTRICULAR RESPONSE',
      finding: 'An abnormal and rapid heart rhythm due to a regular or irregular electrical discharge in the top chamber of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 9,
      chipPreset: null,
    },
    {
      rhythm: 'AJR',
      abbreviation: 'AJR',
      meaning: 'ACCELERATED JUNCTIONAL RHYTHM',
      finding: 'An abnormal and rapid heart rhythm due to an electrical discharge in the middle of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 11,
      chipPreset: null,
    },
    {
      rhythm: 'ABIG',
      abbreviation: 'ABIG',
      meaning: 'ATRIAL BIGEMINY',
      finding: 'An abnormal heart rhythm where every other beat originates from an electrical discharge in the top of the heart.',
      acuity: AliveCorAcuity.NeedsReview,
      primaryRhythm: null,
      dropdownOrder: 14,
      chipPreset: null,
    },
    {
      rhythm: 'NR',
      abbreviation: 'NR',
      meaning: 'NOT READABLE',
      finding: 'Not Readable.',
      acuity: AliveCorAcuity.NotAcceptable,
      primaryRhythm: null,
      dropdownOrder: 23,
      chipPreset: null,
    },
  ],
  PRIMARY: undefined as unknown as AliveCorRhythmDefinition[], // This will be properly populated below.
  DROPDOWN: undefined as unknown as AliveCorRhythmDefinition[], // This will be properly populated below.
};

AliveCorRhythmCategories.DROPDOWN = sortBy(
  [
    ...AliveCorRhythmCategories.SINUS_MECHANISM,
    ...AliveCorRhythmCategories.SUPRAVENTRICULAR,
    ...AliveCorRhythmCategories.VENTRICULAR,
    ...AliveCorRhythmCategories.SECONDARY,
    ...AliveCorRhythmCategories.OTHER,
  ].filter((r) => r.dropdownOrder != null),
  (r) => r.dropdownOrder,
);

AliveCorRhythmCategories.PRIMARY = uniqBy(
  [
    ...AliveCorRhythmCategories.SINUS_MECHANISM,
    ...AliveCorRhythmCategories.SUPRAVENTRICULAR,
    ...AliveCorRhythmCategories.VENTRICULAR,
    ...AliveCorRhythmCategories.DROPDOWN,
  ],
  (r) => r.rhythm,
);

export const AliveCorClinicalCorrelations: { name: string; value: string }[] = [{ name: 'Advised', value: 'Clinical Correlation Advised.' }];
