import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  createStyledFormElement,
} from 'core/forms';
import { Button, Input, Label } from 'core/ui';

import { IntegrationTabContentProps, IntegrationTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const IntegrationTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof IntegrationTabFormValues) => any;
  onChange: (values: IntegrationTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      supportUrl: valueGetter('supportUrl'),
      britUser: valueGetter('britUser'),
    });
  }, [valueGetter, onChange]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="supportUrl">Support URL</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="supportUrl"
            editorId="supportUrl"
            component={Input}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="britUser">BRIT User Name</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="britUser"
            editorId="britUser"
            component={Input}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

IntegrationTabContentInner.displayName = 'IntegrationTabContentInner';

export const IntegrationTabContent: FunctionComponent<
  IntegrationTabContentProps
> = ({ initialValues, onChange, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <IntegrationTabContentInner
          valueGetter={valueGetter}
          onChange={onChange}
        />
      )}
    />
  );
};

IntegrationTabContent.displayName = 'IntegrationTabContent';
