import { FunctionComponent } from 'react';

import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

import { useEvent } from 'core/hooks';
import { Dropdown } from 'core/ui';

import { ReadingTemplateProps } from '../types/ReadingTemplateProps';

export const ReadingTemplate: FunctionComponent<ReadingTemplateProps> = ({
  template,
  fieldName,
  textField,
  dataItemKey,
  valueSetter,
}) => {
  const handleChangeDropDownList = useEvent(
    (event: DropDownListChangeEvent) => {
      valueSetter(fieldName, { value: event.value.content });
    },
  );
  return (
    <>
      <div>Template</div>
      <Dropdown
        data={template}
        textField={textField}
        dataItemKey={dataItemKey}
        onChange={handleChangeDropDownList}
        valid
      />
    </>
  );
};
