import { memo, useEffect, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { GridColumn } from '@progress/kendo-react-grid';
import styled from 'styled-components';

import { ExamStatusLogModel } from 'models';

import { DataResult } from 'core/api/types';
import { useEvent } from 'core/hooks';
import {
  ComponentSizes,
  DEFAULT_DATA_TABLE_DATA_STATE,
  DataTable,
  DataTableVariants,
  HeaderCellSecondary,
  Modal,
  TextCell,
} from 'core/ui';

import { apiClient } from 'features/api';

import { ExamStatusLogModalProps } from '../types';

const EMPTY_GRID_DATA: DataResult<ExamStatusLogModel> = {
  data: [],
  total: 0,
};

export const ExamStatusLogModal = memo<ExamStatusLogModalProps>(
  ({ show, id, onClose }) => {
    const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);
    const [gridData, setGridData] = useState(EMPTY_GRID_DATA);
    const [isLoading, setIsLoading] = useState(false);

    const refreshData = useEvent(async (newDataState?: State) => {
      if (id && show) {
        try {
          setIsLoading(true);
          setGridData(
            await apiClient.examStatusClient.getExamStatusLogs(
              newDataState ?? dataState,
              id,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      } else {
        setGridData(EMPTY_GRID_DATA);
      }
    });

    useEffect(() => {
      refreshData();
    }, [refreshData, id, show]);

    return (
      <StyledModal
        show={show}
        onHide={onClose}
        title={`Exam Status Logs (${id})`}
      >
        <StyledDataTable
          {...dataState}
          data={gridData.data}
          total={gridData.total}
          pageable
          reorderable
          size={ComponentSizes.SMALL}
          sortable
          variant={DataTableVariants.SECONDARY}
          onDataStateChange={(e) => setDataState(e.dataState)}
          isLoading={isLoading}
        >
          <GridColumn
            cell={TextCell}
            field="status"
            filterable={false}
            title="Status Type"
            width="200px"
            headerCell={HeaderCellSecondary}
          />
          <GridColumn
            cell={TextCell}
            field="userCreated"
            filterable={false}
            title="User Created"
            width="150px"
            headerCell={HeaderCellSecondary}
          />
          <GridColumn
            cell={TextCell}
            field="dateCreated"
            filterable={false}
            title="Date Created"
            width="150px"
            headerCell={HeaderCellSecondary}
          />
        </StyledDataTable>
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)`
  .modal-content {
    row-gap: 24px;
  }
`;

const StyledDataTable = styled(DataTable)`
  margin: 0;
  min-height: 150px;
  border-left: none;
  border-right: none;
`;
