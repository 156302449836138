import { ReactElement, cloneElement, memo, useCallback, useMemo } from 'react';

import {
  DropDownListChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { useAccordionButton } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LocationModel } from 'models';

import { useAccordionTabExpanded } from 'core/hooks';
import { Accordion, DropdownField } from 'core/ui';
import {
  HeaderButton,
  HeaderCollapseIcon,
  HeaderDiv,
  HeaderTitleButton,
} from 'core/ui/Accordion';

import LocationIcon from 'features/exam/assets/location.svg?react';
import { useAppSelector } from 'features/main/hooks';

import { LocationSelectors } from '../redux';
import { LocationSelectionAccordionProps } from '../types';

type LocationSelectionAccordionFormValues = {
  location: LocationModel | null;
};

const NULL_LOCATION_OPTION = { id: null, name: '' };
const FILTER_FIELDS = ['name', 'code'];

export const LocationSelectionAccordion = memo<LocationSelectionAccordionProps>(
  ({ className, eventKey, locationId, onChange }) => {
    const isExpanded = useAccordionTabExpanded(eventKey);
    const handleHeaderClick = useAccordionButton(eventKey);

    const allLocations = useAppSelector(LocationSelectors.getAll);

    const selectedLocation = useMemo(
      () =>
        (allLocations == null
          ? null
          : allLocations.find((l) => l.id === locationId)) ?? null,
      [allLocations, locationId],
    );

    const rhfContext = useForm<LocationSelectionAccordionFormValues>({
      defaultValues: { location: selectedLocation },
    });

    const handleSubmit: SubmitHandler<LocationSelectionAccordionFormValues> =
      useCallback((_values, event) => {
        event?.preventDefault();
      }, []);

    const handleLocationChange = useCallback(
      (event: DropDownListChangeEvent) => {
        onChange?.(event.value.id);
      },
      [onChange],
    );

    return (
      <Accordion.Item className={className} eventKey={eventKey}>
        <StyledHeaderDiv>
          <HeaderTitleButton type="button" onClick={handleHeaderClick}>
            <LocationIcon />
            Location
          </HeaderTitleButton>
          {!isExpanded && selectedLocation && (
            <StyledLocationNameButton type="button" onClick={handleHeaderClick}>
              <StyledLocationNameDiv>
                {selectedLocation.code} | {selectedLocation.name}
              </StyledLocationNameDiv>
            </StyledLocationNameButton>
          )}
          <StyledExpandButton type="button" onClick={handleHeaderClick}>
            <HeaderCollapseIcon eventKey={eventKey} />
          </StyledExpandButton>
        </StyledHeaderDiv>
        <Accordion.Body>
          <FormProvider {...rhfContext}>
            <form
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="none"
              spellCheck="false"
              noValidate
              onSubmit={rhfContext.handleSubmit(handleSubmit)}
            >
              <DropdownField
                name="location"
                data={allLocations}
                defaultItem={NULL_LOCATION_OPTION}
                onChange={handleLocationChange}
                required
                label="Search"
                filterFields={FILTER_FIELDS}
                valueRender={locationValueRender}
                itemRender={locationItemRender}
              />
            </form>
          </FormProvider>
        </Accordion.Body>
      </Accordion.Item>
    );
  },
);

LocationSelectionAccordion.displayName = 'LocationSelectionAccordion';

function locationValueRender(
  element: ReactElement<HTMLSpanElement>,
  value: LocationModel,
) {
  if (!value) {
    return element;
  }

  const children = (
    <span>
      {value.code} | {value.name}
    </span>
  );

  return cloneElement(element, { ...element.props }, children);
}

function locationItemRender(
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
) {
  const { dataItem } = itemProps;

  const itemChildren = (
    <span>
      {dataItem.code} | {dataItem.name}
    </span>
  );

  return cloneElement(li, li.props, itemChildren);
}

const StyledHeaderDiv = styled(HeaderDiv)`
  height: 33px;
`;

const StyledLocationNameButton = styled(HeaderTitleButton)`
  flex: 0 1 min-content;
`;

const StyledLocationNameDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-self: center;
`;

const StyledExpandButton = styled(HeaderButton)`
  padding: 0 12px 0 13px;
`;
