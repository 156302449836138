import { FunctionComponent, useMemo } from 'react';

import { SwitchChangeEvent } from '@progress/kendo-react-inputs';

import { useEvent } from 'core/hooks';

import { Switch } from '../../../Switch';
import { SwitchCellProps } from './SwitchCellProps';

export const SwitchCell: FunctionComponent<SwitchCellProps> = ({ className, dataItem, dataIndex, field, onChange }) => {
  const handleChange = useEvent((event: SwitchChangeEvent) => {
    onChange?.({
      dataItem,
      dataIndex,
      syntheticEvent: event.syntheticEvent,
      field,
      value: event.value,
    });
  });

  const dataItemField = field == null ? undefined : dataItem[field];

  const content = useMemo(
    () => (
      <td className={className}>
        <Switch value={dataItemField} onChange={handleChange} />
      </td>
    ),
    [className, dataItemField, handleChange],
  );

  return content;
};

SwitchCell.displayName = 'SwitchCell';
