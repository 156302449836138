import { memo } from 'react';

import { faCircleExclamation, faFiles, faMemoPad, faPaperclip, faSquare, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { Step as KendoStep, StepProps as KendoStepProps } from '@progress/kendo-react-layout';
import cn from 'classnames';
import styled from 'styled-components';

import { Icon } from 'core/ui';

import { WizardStepProps } from '../types';

export const WizardStep = memo<KendoStepProps>((props) => {
  if (!isWizardStepProps(props)) throw new Error('WizardStep requires a stepKey prop.');

  const isErrorDisplayed = !props.isValid && props.visited;

  return (
    <StyledStep {...props} className={cn(props.className, { visited: props.visited })}>
      <div className="k-step-indicator" aria-hidden="true">
        <Icon icon={props.index === 0 ? faFiles : props.index === 1 ? faMemoPad : props.index === 2 ? faPaperclip : faUpload} />
      </div>
      <div className="k-step-label">
        <div className="k-step-text">
          {props.label}
          <span className="status-container">
            <Icon icon={isErrorDisplayed ? faCircleExclamation : faSquare} />
          </span>
        </div>

        {props.optional === true && <span className="k-step-label-optional">(Optional)</span>}
      </div>
    </StyledStep>
  );
});

WizardStep.displayName = 'WizardStep';

function isWizardStepProps(props: KendoStepProps): props is WizardStepProps {
  return (props as WizardStepProps).stepKey != null;
}

const StyledStep = styled(KendoStep)`
  --kendo-color-primary: ${({ theme }) => theme.colors.primary};
  --kendo-color-primary-hover: ${({ theme }) => theme.colors.primary};
  --kendo-color-base-hover: ${({ theme }) => theme.colors.palette.blues[0]};

  display: flex;
  align-items: start;

  .k-step-link {
    flex: 1 1 0;
    margin-top: 0;
    max-width: min-content;
    overflow: visible;
  }

  .k-step-indicator {
    width: 50px;
    height: 50px;
  }

  &:not(.k-step-done):not(.k-step-current) .k-step-indicator {
    color: ${({ theme }) => theme.colors.primary};
  }

  .k-step-label {
    flex: 1 1 0;
    flex-wrap: nowrap;
    max-width: unset;
    min-height: 34px;
    display: grid;
    overflow: visible;
    grid-template-columns: min-content;
    grid-auto-rows: min-content;
    position: relative;
  }

  &.k-step-current .k-step-label {
    font-weight: 400;
  }

  &.k-step-error:not(.visited) .k-step-label,
  &.k-step-error:not(.visited):hover .k-step-label {
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  && .k-step-text {
    flex: 0 0 min-content;
    max-width: unset;
    white-space: nowrap;
    overflow: visible;
  }

  &&.k-step.k-disabled .k-step-text {
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  .k-step-label-optional,
  &.k-step.k-disabled .k-step-label-optional {
    flex: 1 1 0;
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  .status-container {
    position: relative;
  }

  .k-step-label .icon-container {
    position: absolute;
    top: -3px;
    margin-left: 6px;
  }

  .fa-square {
    visibility: hidden;
  }
`;
