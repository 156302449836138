import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { ExamReadingActions } from './actions';

export const examReadingAdapter = createEntityAdapter();

const getReducerInitialState = () => ({
  ...examReadingAdapter.getInitialState(),
  lastReadAddedExamId: null,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(ExamReadingActions.addRead.fulfilled, (draft, response) => {
    draft.lastReadAddedExamId = response.payload;
  });
  builder.addCase(ExamReadingActions.saveRead.fulfilled, (draft, response) => {
    draft.lastReadAddedExamId = response.payload;
  });
  builder.addCase(
    ExamReadingActions.approveRead.fulfilled,
    (draft, response) => {
      draft.lastReadAddedExamId = response.payload;
    },
  );
});
