import { memo, useCallback, useMemo, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { DynamicFormFieldModel, FormFieldModel, ItemModel } from 'models';

import { NotificationsService } from 'core/notifications';
import { Action, ActionListCell, Button, CurrencyText, DataTable, HeaderCell, TextCell } from 'core/ui';
import { equalsInsensitive } from 'core/utils';

import { apiClient } from 'features/api';
import { useCurrentUser } from 'features/auth';

import { PricingTabContentProps } from '../types';
import { RuleEditor } from './RuleEditor';

export const PricingTabContent = memo<PricingTabContentProps>(({ accountId, rules, pricingCategory, allFormFields, allItems, onRulesChange }) => {
  const { currentUser } = useCurrentUser(true);

  const [selectedIndex, setSelectedIndex] = useState<number | 'new' | null>(null);

  const handleAddClick = useCallback(() => {
    setSelectedIndex('new');
  }, []);

  const handleRuleSelected = useCallback((_event: unknown, dataItem: DynamicFormFieldModel, dataItemIndex: number) => {
    if (dataItem.fieldId == null || dataItem.itemId == null) {
      throw new Error('Cannot select to edit rule because one or both of fieldId and itemId are null or undefined.');
    }

    setSelectedIndex(dataItemIndex);
  }, []);

  const handleRuleEditorClosed = useCallback(() => {
    setSelectedIndex(null);
  }, []);

  const handleRuleEditorSave = useCallback(
    async (field: FormFieldModel, item: ItemModel, value: string) => {
      if (selectedIndex == null) {
        throw new Error('Cannot save rule because the selectedIndex is null or undefined.');
      }

      // Construct a new model for save.
      const newRules = cloneDeep(rules);

      if (typeof selectedIndex === 'number') {
        const existingRule = newRules.fields[selectedIndex];
        existingRule.value = value;
      } else {
        const newRule: DynamicFormFieldModel = {
          fieldId: field.id,
          itemId: item.id,
          name: field.name,
          label: field.label,
          categoryId: pricingCategory.id,
          category: pricingCategory.name,
          entityType: 'Account',
          value,
          fieldDescription: field.description,
          itemName: item.name,
          itemDescription: item.description,
          attribute: null,
        };
        newRules.fields.push(newRule);
      }

      // Save and refresh the page.
      await apiClient.dynamicFormsClient.setDynamicFormFor(newRules);
      NotificationsService.displaySuccess('Account pricing saved.');
      const refreshedRules = await apiClient.dynamicFormsClient.getDynamicFormFor('Account', accountId);

      onRulesChange(refreshedRules);
    },
    [accountId, onRulesChange, pricingCategory, rules, selectedIndex],
  );

  const handleRuleEditorDelete = useCallback(async () => {
    if (typeof selectedIndex !== 'number') {
      setSelectedIndex(null);
      return;
    }

    const newRules = cloneDeep(rules);
    newRules.fields.splice(selectedIndex, 1);
    await apiClient.dynamicFormsClient.setDynamicFormFor(newRules);
    NotificationsService.displaySuccess('Deleted price rule.');

    const refreshedRules = await apiClient.dynamicFormsClient.getDynamicFormFor('Account', accountId);

    onRulesChange(refreshedRules);

    setSelectedIndex(null);
  }, [accountId, onRulesChange, rules, selectedIndex]);

  const gridActions: Action[] = useMemo(() => {
    return [
      {
        key: 'edit-pricing-rule',
        title: 'Edit Rule',
        icon: faPen,
        disabled: !currentUser.isPricingAdmin,
        onClick: handleRuleSelected,
      },
    ];
  }, [handleRuleSelected, currentUser.isPricingAdmin]);

  const gridActionsRender = useCallback((props: GridCellProps) => <ActionListCell {...props} actions={gridActions} dataItemKey="id" />, [gridActions]);

  if (accountId === 0) {
    return (
      <StyledNewAccountDiv>
        <p>
          Pricing configuration can only be modified <i>after</i> the account has been created.
        </p>
      </StyledNewAccountDiv>
    );
  }

  return (
    <StyledPricingTabContainer>
      <StyledPricingHeaderDiv>
        {currentUser.isPricingAdmin && (
          <Button icon="plus" onClick={handleAddClick}>
            Add Pricing Rule
          </Button>
        )}
      </StyledPricingHeaderDiv>
      <StyledDataTableContainer>
        <DataTable data={rules.fields} actions={gridActions}>
          <GridColumn headerCell={HeaderCell} cell={gridActionsRender} width="40px" />
          <GridColumn title="Product" cell={TextCell} headerCell={HeaderCell} field="itemName" />
          <GridColumn title="Description" cell={TextCell} headerCell={HeaderCell} field="itemDescription" />
          <GridColumn title="Rule" cell={TextCell} headerCell={HeaderCell} field="name" />
          <GridColumn title="Description" cell={TextCell} headerCell={HeaderCell} field="fieldDescription" />
          <GridColumn title="Value" cell={valueRenderCell} headerCell={HeaderCell} field="value" />
        </DataTable>
      </StyledDataTableContainer>
      <StyledRuleEditor
        rules={rules}
        allItems={allItems}
        allFormFields={allFormFields}
        selectedIndex={selectedIndex}
        onRuleDelete={handleRuleEditorDelete}
        onRuleSave={handleRuleEditorSave}
        onClose={handleRuleEditorClosed}
      />
    </StyledPricingTabContainer>
  );
});

PricingTabContent.displayName = 'PricingTabContent';

const StyledPricingTabContainer = styled.div`
  display: grid;
  grid-template-columns: 900px 500px;
  grid-template-rows: min-content 1fr;
  align-items: start;
`;

const StyledPricingHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: 1 / span 1;
`;

const StyledDataTableContainer = styled.div`
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  padding-bottom: 8px;
`;

const StyledRuleEditor = styled(RuleEditor)`
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
`;

const StyledNewAccountDiv = styled.div`
  display: flex;
  overflow: hidden;
  padding: 24px;
`;

function valueRenderCell({ className, dataItem }: GridCellProps) {
  return <td className={className}>{equalsInsensitive('included items', dataItem.name) ? dataItem.value : <CurrencyText value={dataItem.value} />}</td>;
}
