import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { DestinationRouteGridModel, DestinationRouteModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class DestinationRouteClient {
  constructor(private httpClient: HttpClient) {
    this.getDestinationRouteById = this.getDestinationRouteById.bind(this);
    this.getAllDestinationRouteForKendoGrid = this.getAllDestinationRouteForKendoGrid.bind(this);
    this.createDestinationRoute = this.createDestinationRoute.bind(this);
    this.updateDestinationRoute = this.updateDestinationRoute.bind(this);
  }

  public async getDestinationRouteById(destinationRouteId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const destinationRoute = await this.httpClient.fetchAndParse<DestinationRouteModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DestinationRoute/${encodeURIComponent(destinationRouteId)}`, options),
    );
    return destinationRoute;
  }

  public async getAllDestinationRouteForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<DestinationRouteGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DestinationRoute/grid?${queryStr}`, options),
    );
    return result;
  }

  public async createDestinationRoute(source: DestinationRouteModel) {
    const options = await this.httpClient.createStandardOptions('POST', source);
    const result = await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DestinationRoute`, options));
    return result;
  }

  public async updateDestinationRoute(destinationRoute: DestinationRouteModel) {
    const options = await this.httpClient.createStandardOptions('PUT', destinationRoute);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DestinationRoute`, options));
    return result;
  }
}
