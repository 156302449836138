import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { LocationTypeModel } from 'models';

import { ApiActions, createAsyncThunk } from 'core/api';
import { ApiResponse } from 'core/api/types';

import { LocationTypeRouteService } from '../services';
import { locationTypeEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'location-types/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(LocationTypeRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<LocationTypeModel[]>;

    const normalized = normalize<
      LocationTypeModel,
      {
        locationTypes: Record<string, LocationTypeModel>;
      }
    >(result, [locationTypeEntity]);

    return normalized.entities;
  },
);

const add = createAsyncThunk<LocationTypeModel, LocationTypeModel>(
  'location-types/add',
  async (locationType, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: LocationTypeRouteService.getBaseRoute(),
        body: locationType,
      }),
    )) as unknown as ApiResponse<LocationTypeModel>;

    const normalized = normalize<
      LocationTypeModel,
      {
        locationTypes: LocationTypeModel;
      }
    >({ ...locationType, id: result.id }, locationTypeEntity);

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'location-types/edit',
  async (locationType: LocationTypeModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: LocationTypeRouteService.getBaseRoute(),
        body: locationType,
      }),
    );

    const normalized = normalize<
      LocationTypeModel,
      {
        locationTypes: LocationTypeModel;
      }
    >(locationType, locationTypeEntity);

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'location-types/get-by-id',
  async (locationTypeId: number, { dispatch }) => {
    const res = (await dispatch(
      ApiActions.get(LocationTypeRouteService.getByIdRoute(locationTypeId)),
    )) as unknown as ApiResponse<LocationTypeModel>;

    return res.result;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'location-types-grid/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = LocationTypeRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [locationTypeEntity]);

    return { ...normalized, total: result.total };
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'location-types/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = LocationTypeRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<LocationTypeModel>;

    return result;
  },
);

export const LocationTypeActions = {
  add,
  edit,
  getAll,
  getAllForExport,
  getAllForKendoGrid,
  getById,
};
