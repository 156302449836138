/* eslint-disable no-param-reassign */
// source: https://addyosmani.com/blog/faster-javascript-memoization/
/** DEPRECATED: This function is deprecated because it's complex, unnecessary, and doesn't play well with TypeScript. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function memoize(this: any, f: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...restArguments: any[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const args: any = Array.prototype.slice.call(restArguments);

    f.memoize = f.memoize || {};

    // eslint-disable-next-line no-return-assign
    return args in f.memoize
      ? f.memoize[args]
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        (f.memoize[args] = f.apply(this as unknown, args));
  };
}

export { memoize };
