import { FunctionComponent } from 'react';

import {
  FormElementProps,
  FormElement as KendoFormElement,
} from '@progress/kendo-react-form';

export const FormElement: FunctionComponent<FormElementProps> = ({
  children,
  ...rest
}) => <KendoFormElement {...rest}>{children}</KendoFormElement>;
