import { FunctionComponent, useCallback, useMemo } from 'react';

import { TextAreaBlurEvent, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';

import { TextArea } from './TextArea';
import { TextAreaFieldProps } from './TextAreaFieldProps';

export const TextAreaField: FunctionComponent<TextAreaFieldProps> = ({ name, required, validator, onChange, onBlur, ...rest }) => {
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<unknown, unknown>[] = [];

    if (required) newValidators.push(RhfValidators.required);
    if (validator) newValidators.push(validator);

    return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
  }, [required, validator]);

  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({
    name,
    rules: {
      validate: validatorInternal, // Intentionally using a custom validator for "required" because the browser native version will allow string values with all whitespace.
    },
  });

  const handleChange = useCallback(
    (event: TextAreaChangeEvent) => {
      rhfOnChange(event);
      onChange?.(event);
    },
    [onChange, rhfOnChange],
  );

  const handleBlur = useCallback(
    (event: TextAreaBlurEvent) => {
      rhfOnBlur();
      onBlur?.(event);
    },
    [onBlur, rhfOnBlur],
  );

  return (
    <TextArea
      {...fieldRest}
      {...rest}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      required={required}
      validationMessage={error?.message}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

TextAreaField.displayName = 'TextAreaField';
