import { createEntityAdapter } from '@reduxjs/toolkit';

const rootSelector = (state) => state.dynamicForms;

const { selectAll: getAll } = createEntityAdapter().getSelectors(rootSelector);

const getStatus = (state) => rootSelector(state).status;

const getTotal = (state) => rootSelector(state).total;

const getByEntityId = (entityType, entityId) => (state) =>
  getAll(state).find(
    (e) => e.entityType === entityType && e.entityId === entityId,
  );

export const DynamicFormSelectors = {
  getAll,
  getByEntityId,
  getTotal,
  getStatus,
};
