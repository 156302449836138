import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  AccountModel,
  DynamicFormModel,
  FieldCategoryModel,
  FormFieldModel,
  ItemModel,
  SettingOverrideModel,
} from 'models';

import {
  useEvent,
  useTabLocationPersistence,
  useValidatedParam,
} from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import {
  Page,
  PageHeader,
  TabContainer,
  TabContent,
  TabNav,
  TabNavContainer,
  TabNavItem,
  TabNavLink,
  TabPane,
} from 'core/ui';

import { apiClient } from 'features/api';

import { ACCOUNT_INITIAL_VALUES } from '../constants';
import { AccountEditService } from '../services';
import { GeneralTabFormValues } from '../types';
import { GeneralTabContent } from './GeneralTabContent';
import { PricingTabContent } from './PricingTabContent';
import { SettingsTabContent } from './SettingsTabContent';

const allTabKeys = ['general', 'settings', 'pricing'];

export function AccountForm() {
  const id = useValidatedParam('id', 'number', false);
  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);
  const navigate = useNavigate();
  const isFormInEditMode = id != null;

  const [account, setAccount] = useState<AccountModel | null>(null);
  const [allAccounts, setAllAccounts] = useState<AccountModel[] | null>(null);
  const [generalTab, setGeneralTab] = useState<GeneralTabFormValues>(
    ACCOUNT_INITIAL_VALUES.GENERAL,
  );
  const [settingOverrides, setSettingOverrides] = useState<
    SettingOverrideModel[]
  >(ACCOUNT_INITIAL_VALUES.SETTING_OVERRIDES);
  const [pricingCategory, setPricingCategory] =
    useState<FieldCategoryModel | null>(null);
  const [allFormFields, setAllFormFields] = useState<FormFieldModel[] | null>(
    null,
  );
  const [allItems, setAllItems] = useState<ItemModel[] | null>(null);
  const [rules, setRules] = useState<DynamicFormModel | null>(null);

  const isInitializing =
    (id != null && account == null) ||
    allAccounts == null ||
    pricingCategory == null ||
    allItems == null ||
    allFormFields == null ||
    rules == null;

  const handleSubmit = useEvent(async () => {
    if (id != null && account == null) {
      throw new Error(
        'Cannot save account because the original record was never retrieved.',
      );
    }

    const baseModel = {
      id: account?.id ?? 0,
      contractorCode: account?.contractorCode ?? null,
    };

    const newModel = AccountEditService.copyFormsToModel(
      baseModel,
      generalTab,
      settingOverrides,
    );

    if (newModel.id === 0) {
      const newId = await apiClient.accountsClient.createAccount(newModel);
      newModel.id = newId;
      setAccount(newModel);
      NotificationsService.displaySuccess(`Created ${newModel.name}.`);
      navigate('/account');
    } else {
      await apiClient.accountsClient.updateAccount(newModel);
      setAccount(newModel);
      NotificationsService.displaySuccess(`Saved ${newModel.name}.`);
      navigate('/account');
    }
  });

  useEffect(() => {
    (async () => {
      const result = await AccountEditService.initializeEditPage(id);

      setAllAccounts(result.allAccounts);
      setAccount(result.account);
      setPricingCategory(result.pricingCategory);
      setAllItems(result.allItems);
      setAllFormFields(result.allFormFields);
      setGeneralTab(result.generalTab);
      setSettingOverrides(result.settingOverrides);
      setRules(result.rules);
    })();
  }, [id]);

  if (isInitializing) return null;

  return (
    <Page>
      <PageHeader title={isFormInEditMode ? 'Edit Account' : 'New Account'} />
      <StyledTabWrapper>
        <TabContainer
          id="account-tabs"
          onSelect={onTabSelect}
          defaultActiveKey={initialTabKey}
        >
          <TabNavContainer>
            <TabNav>
              <TabNavItem>
                <TabNavLink eventKey="general">General</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="settings">Settings</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="pricing">Pricing</TabNavLink>
              </TabNavItem>
            </TabNav>
          </TabNavContainer>
          <TabContent>
            <TabPane eventKey="general">
              <GeneralTabContent
                original={account}
                values={generalTab}
                allAccounts={allAccounts}
                onChange={setGeneralTab}
                onSubmit={handleSubmit}
              />
            </TabPane>
            <TabPane eventKey="settings">
              <SettingsTabContent
                initialValues={settingOverrides}
                onChange={setSettingOverrides}
                onSubmit={handleSubmit}
              />
            </TabPane>
            <TabPane eventKey="pricing">
              <PricingTabContent
                accountId={id ?? 0}
                pricingCategory={pricingCategory}
                rules={rules}
                allFormFields={allFormFields}
                allItems={allItems}
                onRulesChange={setRules}
              />
            </TabPane>
          </TabContent>
        </TabContainer>
      </StyledTabWrapper>
    </Page>
  );
}

const StyledTabWrapper = styled.div`
  margin-top: 4px;
`;
