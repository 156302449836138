import { FunctionComponent, memo } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import { AccordionNg, SmallTextBox, useAccordionNgItem, useAccordionNgItemView } from 'core/ui';
import { hasText } from 'core/utils';

import { useExamsFilesAccordionItem } from '../hooks';
import { ShareService } from '../services';
import { DesktopAccordionItemProps } from '../types';
import { FileButton } from './FileButton';

export const DesktopAccordionItem: FunctionComponent<DesktopAccordionItemProps> = memo(({ className, exam, selectedFileId, onFileClick }) => {
  const { isExpanded, toggleExpanded } = useAccordionNgItem(exam.examId.toString());

  const { examFiles, examAttachments, isExamSelected } = useExamsFilesAccordionItem(exam, selectedFileId);

  const { accordionItemBodyRef, animationStyles, handleToggleClick } = useAccordionNgItemView('vertical', isExpanded, toggleExpanded);

  return (
    <AccordionNg.ItemDiv className={className}>
      <AccordionNg.ItemHeaderButton
        className={`testing_${DesktopAccordionItem.displayName}_expand-button`}
        data-exam-id={exam.examId}
        onClick={handleToggleClick}
        preset="desktop"
        selected={isExamSelected}
      >
        <StyledStudyDateLabel>{ShareService.formatExamStudyDate(exam.studyDate)}</StyledStudyDateLabel>
        {hasText(exam.description) && (
          <OverlayTrigger overlay={<StyledAccordionLabelTooltip>{exam.description}</StyledAccordionLabelTooltip>}>
            <StyledDescriptionLabelContainer>
              <StyledDescriptionLabel>{exam.description}</StyledDescriptionLabel>
            </StyledDescriptionLabelContainer>
          </OverlayTrigger>
        )}
        <StyledFlexSpacer />
        <AccordionNg.ItemCollapseIconDiv style={animationStyles[1]} />
      </AccordionNg.ItemHeaderButton>
      <AccordionNg.ItemBodyAnimationDiv style={animationStyles[0]}>
        <StyledAccordionBody ref={accordionItemBodyRef}>
          <StyledSelectionBar2 $selected={isExamSelected} />
          <StyledBodyContent>
            {examFiles.length > 0 && (
              <>
                <StyledSectionLabel>{`${examFiles.length === 1 ? 'Exam' : 'Exams'} (${examFiles.length})`}</StyledSectionLabel>
                <StyledExamThumbnailContainer $marginBottom={examAttachments.length > 0}>
                  {examFiles.map((file) => (
                    <FileButton key={file.id} file={file} selected={file.id === selectedFileId} onClick={onFileClick} />
                  ))}
                </StyledExamThumbnailContainer>
              </>
            )}

            {examAttachments.length > 0 && (
              <>
                <StyledSectionLabel>{`${examAttachments.length === 1 ? 'Attachment' : 'Attachments'} (${examAttachments.length})`}</StyledSectionLabel>
                <StyledAttachmentsContainer>
                  {examAttachments.map((file) => (
                    <FileButton key={file.id} file={file} selected={file.id === selectedFileId} onClick={onFileClick} />
                  ))}
                </StyledAttachmentsContainer>
              </>
            )}

            {examFiles.length === 0 && examAttachments.length === 0 && (
              <StyledEmptyExamLabel variant="disabled" block>
                No exams or attachments
              </StyledEmptyExamLabel>
            )}
          </StyledBodyContent>
        </StyledAccordionBody>
      </AccordionNg.ItemBodyAnimationDiv>
    </AccordionNg.ItemDiv>
  );
});

DesktopAccordionItem.displayName = 'DesktopAccordionItem';

const StyledStudyDateLabel = styled.div`
  flex: 0 0 min-content;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 0 8px;
`;

const StyledAccordionLabelTooltip = styled(Tooltip)`
  white-space: nowrap;

  .tooltip-inner {
    max-width: none;
  }
`;

const StyledDescriptionLabelContainer = styled.div`
  flex: 1 1 min-content;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;
  padding-left: 6px;
`;

const StyledDescriptionLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledFlexSpacer = styled.div`
  flex: 1 1 0;
`;

const StyledSelectionBar2 = styled.div<{ $selected: boolean }>`
  background-color: ${({ theme, $selected }) => ($selected ? theme.colors.palette.blues[1] : 'transparent')};
`;

const StyledAccordionBody = styled.div`
  display: grid;
  grid-template-columns: 4px 1fr;
  padding: 0;
`;

const StyledBodyContent = styled.div`
  padding: 0 4px;
`;

const StyledExamThumbnailContainer = styled.div<{ $marginBottom: boolean }>`
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? '12px' : '0')};
`;

const StyledAttachmentsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 12px;
  row-gap: 12px;
`;

const StyledSectionLabel = styled.div`
  color: ${({ theme }) => theme.colors.palette.gray};
  user-select: none;
`;

const StyledEmptyExamLabel = styled(SmallTextBox)`
  margin: 0 24px 0 22px;
  user-select: none;
  justify-self: center;
  margin-top: 6px;
`;
