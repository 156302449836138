import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { UploadTrackerStatus } from '../constants';
import { UploadTrackerActions } from './actions';

export const uploadTrackerAdapter = createEntityAdapter({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...uploadTrackerAdapter.getInitialState(),
  total: 0,
  status: UploadTrackerStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(
    UploadTrackerActions.getAllForKendoGrid.fulfilled,
    (draft, action) => {
      uploadTrackerAdapter.removeAll(draft);

      if (action.payload.entities.uploads) {
        uploadTrackerAdapter.addMany(draft, action.payload.entities.uploads);
      }

      draft.total = action.payload.total;
      draft.ids = action.payload.result;
      draft.status = UploadTrackerStatus.FETCHED;
    },
  );
});
