export function downloadBlobAsFile(
  blob: Blob,
  /** File extension.  Do not including the leading period. */
  fileExtension: string,
  fileName: string,
) {
  // convert stream to zip and show as download
  // src: https://stackoverflow.com/a/63946003/9801221
  const newBlob = new Blob([blob]);
  const url = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');

  try {
    // Creating the hyperlink and auto click it to start the download
    link.href = url;
    link.download = `${fileName}_${new Date().getTime()}.${fileExtension}`;
    link.click();
  } finally {
    // cleanup
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}
