import { FunctionComponent } from 'react';

import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import styled from 'styled-components';

import { ItemProps } from './ItemProps';

export const Item: FunctionComponent<ItemProps> = ({
  children,
  className,
  eventKey,
}) => {
  return (
    <StyledAccordionItem className={className} eventKey={eventKey}>
      {children}
    </StyledAccordionItem>
  );
};

Item.displayName = 'Accordion.Item';

const StyledAccordionItem = styled(BootstrapAccordion.Item)`
  border: none;
`;
