import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import {
  ChipListChangeEvent,
  ChipListHandle,
  ChipList as KendoChipList,
} from '@progress/kendo-react-buttons';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { Chip } from '../Chip';
import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Input } from '../Input';
import { Label } from '../Label';
import { ChipListProps } from './ChipListProps';

const EMPTY_VALUE_ARRAY: (number | string)[] = [];
const EMPTY_DATA_ARRAY: Record<string, unknown>[] = [];
const OTHER_VALUE = '🐕🐕🐕OTHER_OPTION🐕🐕🐕'; // This needs to be a unique value that will never be entered.
const NULL_VALUE_PLACEHOLDER = '🐕🐕🐕NULL_VALUE🐕🐕🐕'; // Internally the ChipList component will fallback to uncontrolled mode if the value prop is "null".  So this is a relatively impossible value to type that we will substitute to prevent this from happening.

export const ChipList: FunctionComponent<ChipListProps> = ({
  allowOtherOption = false,
  data = EMPTY_DATA_ARRAY,
  description,
  disabled,
  hint,
  isOptionalLabelShown = false,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  required,
  selection = 'multiple',
  textField = 'name',
  valid,
  validationMessage,
  value,
  defaultValue,
  valueField = 'value',
  visited,
  ...restProps
}) => {
  // Perform some basic type enforcement depending on the selection mode.  This will make everything else much easier because we can just cast to either a scalar or an array depending on the selection mode.
  if (selection === 'single' && Array.isArray(value)) {
    throw new Error(
      'Property "value" must be a scalar type, null, or undefined when selection mode is "single".',
    );
  }
  if (selection === 'multiple' && value != null && !Array.isArray(value)) {
    throw new Error(
      'Property "value" must be an array, null, or undefined when selection mode is "multiple".',
    );
  }

  const chipsRef = useRef<ChipListHandle | null>(null);

  const otherOption = useMemo(
    () => ({
      [`${textField}`]: 'Other',
      [`${valueField}`]: OTHER_VALUE,
    }),
    [textField, valueField],
  );

  const internalData = useMemo(
    () => (allowOtherOption ? [...data, otherOption] : data),
    [allowOtherOption, data, otherOption],
  );

  const { internalValue, internalOtherValue } = useMemo(
    () =>
      valueToInternal(
        value,
        internalData,
        allowOtherOption,
        valueField,
        selection,
      ),
    [allowOtherOption, internalData, selection, value, valueField],
  );

  // Controlled vs uncontrolled components: https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components
  const isControlledComponent =
    typeof value !== 'undefined' && typeof onChange !== 'undefined';
  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = Boolean(!isValidationMessageShown && hint);
  const errorId = isValidationMessageShown ? `${name}_error` : '';
  const hintId = isHintShown ? `${name}_hint` : '';
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);
  const isOtherOptionSelected =
    internalValue === OTHER_VALUE ||
    (Array.isArray(internalValue) && internalValue.indexOf(OTHER_VALUE) >= 0);

  const handleChange = useEvent((event: ChipListChangeEvent) => {
    const newValue = internalToValue(
      event.value === NULL_VALUE_PLACEHOLDER ? null : event.value,
      internalOtherValue,
      selection,
    );

    const newEvent: ChipListChangeEvent = {
      ...event,
      value: newValue,
    };

    onChange?.(newEvent);
  });

  const handleOtherChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    if (chipsRef.current == null)
      throw new Error(
        'Cannot emit onChange event because the ChipList reference is null or undefined.',
      );

    const newValue = internalToValue(
      internalValue,
      event.target.value,
      selection,
    );

    const newEvent: ChipListChangeEvent = {
      syntheticEvent: event,
      target: chipsRef.current,
      value: newValue,
    };

    onChange?.(newEvent);
  });

  const MemoizedChip: typeof Chip = useCallback(
    ({ selected, ...rest }) => (
      <Chip selected={selected} onBlur={onBlur} onFocus={onFocus} {...rest} />
    ),
    [onBlur, onFocus],
  );

  return (
    <>
      {label && (
        <Label
          description={description}
          editorDisabled={disabled}
          editorId={name}
          editorValid={valid}
          optional={isLabeledAsOptional}
          required={required}
        >
          {label}
        </Label>
      )}
      <KendoChipList
        ref={chipsRef}
        {...restProps}
        chip={MemoizedChip}
        data={internalData}
        disabled={disabled}
        key={name}
        name={name}
        onChange={handleChange}
        selection={selection}
        value={coerceNullForKendo(internalValue, selection)}
        textField={textField}
        valueField={valueField}
        defaultValue={isControlledComponent ? undefined : defaultValue}
      />
      {isOtherOptionSelected && allowOtherOption && (
        <StyledInput
          disabled={disabled}
          name={typeof name === 'undefined' ? undefined : `${name}__other`}
          onChange={handleOtherChange}
          valid
          value={internalOtherValue}
        />
      )}
      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && (
        <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>
      )}
    </>
  );
};

ChipList.displayName = 'ChipList';

const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.space.spacing20};
`;

function coerceValue(
  value: number | string | (number | string)[] | null | undefined,
  selection: 'single' | 'multiple',
) {
  if (typeof value === 'undefined') return undefined;

  // Single selection mode requires a scalar value.
  if (selection === 'single') {
    if (!Array.isArray(value)) return value;

    if (value.length > 1)
      throw new Error(
        'ChipList cannot operate in "single" selection mode when the value contains an array with multiple values.',
      );

    return value.length === 1 ? value[0] : null; // TODO: This might cause the Kendo ChipList to operate in uncontrolled mode.
  }

  // Multiple selection mode requires an array of values.  Even when there aren't any items selected.
  return value === null
    ? EMPTY_VALUE_ARRAY
    : Array.isArray(value)
    ? value
    : [value];
}

function coerceNullForKendo(
  value: number | string | (number | string)[] | null | undefined,
  selection: 'single' | 'multiple',
) {
  return value === null
    ? selection === 'single'
      ? NULL_VALUE_PLACEHOLDER
      : EMPTY_VALUE_ARRAY
    : value;
}

function valueToInternal(
  value: number | string | (number | string)[] | null | undefined,
  internalData: Record<string, unknown>[],
  allowOtherOption: boolean,
  valueField: string,
  selection: 'single' | 'multiple',
): {
  internalValue: number | string | (number | string)[] | null | undefined;
  internalOtherValue: string;
} {
  // First handle null and undefined - coerceValue() will correctly handle both.
  if (value == null) {
    return {
      internalValue: coerceValue(value, selection),
      internalOtherValue: '',
    };
  }

  // Convert scalar values into an array to make later logic only have to deal with array types.
  const valueAsArray = Array.isArray(value) ? value : [value];

  // When the allowOtherOption flag is true we need to move any values that do not exist in the "data" prop into the value for the "Other" text input field.
  if (allowOtherOption) {
    const matchedValues: (number | string)[] = [];
    const unmatchedValues: (number | string)[] =
      internalData == null ? [...valueAsArray] : [];

    valueAsArray.forEach((v) => {
      const dataItem = internalData.find((d) => d[valueField] === v);

      if (dataItem) {
        matchedValues.push(v);
      } else {
        unmatchedValues.push(v);
      }
    });

    return {
      internalValue: coerceValue(
        unmatchedValues.length > 0
          ? [...matchedValues, OTHER_VALUE]
          : matchedValues,
        selection,
      ),
      internalOtherValue: unmatchedValues.reduce(
        (accumulator, current) => `${accumulator}${current}`, // Concatenate all values that weren't found in the data into a single string.
        '', // Default to empty string if the array is empty.
      ) as string,
    };
  }

  return {
    internalValue: coerceValue(valueAsArray, selection),
    internalOtherValue: '',
  };
}

function internalToValue(
  internalValue: number | string | (number | string)[] | null | undefined,
  internalOtherValue: string,
  selection: 'single' | 'multiple',
) {
  if (typeof internalValue === 'undefined') return internalValue;

  // Replace the special `OTHER_VALUE` with the text of the Other input field.
  if (internalValue === OTHER_VALUE) {
    return coerceValue(internalOtherValue, selection);
  } else if (Array.isArray(internalValue)) {
    const otherValueIndex = internalValue.indexOf(OTHER_VALUE);
    const newValue = [...internalValue];

    if (otherValueIndex >= 0) {
      newValue[otherValueIndex] = internalOtherValue;
    }

    return coerceValue(newValue, selection);
  }

  // "Other" was not selected.  Just return the value.
  return coerceValue(internalValue, selection);
}
