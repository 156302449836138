import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { AuthRoutes } from '../routes';
import { OidcSignIn } from './OidcSignIn';
import { OidcSignOut } from './OidcSignOut';

export const Auth: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        id={AuthRoutes.OIDC_SIGNOUT}
        path={AuthRoutes.OIDC_SIGNOUT}
        Component={OidcSignOut}
      />
      <Route
        id={AuthRoutes.OIDC_SIGNIN}
        path={AuthRoutes.OIDC_SIGNIN}
        Component={OidcSignIn}
      />
    </Routes>
  );
};

Auth.displayName = 'Auth';
