import { EventStream, IEventStreamConsumer } from './EventStream';

/** Simple way to subscribe to a stateful value and future updates. */
export type IDataStreamConsumer<T> = IEventStreamConsumer<T> & {
  readonly getCurrentValue: () => T;
};

/** Adds the ability to imperatively get the most recent value that was emitted. */
export class DataStream<T> extends EventStream<T> {
  private currentValue: T;

  constructor(initialValue: T) {
    super();

    this.getCurrentValue = this.getCurrentValue.bind(this);

    this.currentValue = initialValue;
  }

  /** Get the most recent value emitted by the stream. */
  public getCurrentValue() {
    return this.currentValue;
  }

  public override emit(newValue: T) {
    this.currentValue = newValue;
    return super.emit(newValue);
  }
}
