import { findOrThrow, hasText } from 'core/utils';

import {
  AliveCorAcuityDefinitions,
  AliveCorRhythmCategories,
} from 'features/exam/constants';
import { AliveCorAcuityDefinition } from 'features/exam/types';

function getSelectedRhythms(
  primaryRhythm: string | null | undefined,
  secondaryRhythms: string[] | null | undefined,
) {
  const primaryRhythmDef =
    primaryRhythm == null
      ? null
      : findOrThrow(
          AliveCorRhythmCategories.PRIMARY,
          (r) => r.rhythm === primaryRhythm,
          `Could not find rhythm "${primaryRhythm}".`,
        );

  const secondaryRhythmDefs = AliveCorRhythmCategories.SECONDARY.filter((r) =>
    secondaryRhythms?.some((v) => v === r.rhythm),
  );

  const selectedRhythms =
    primaryRhythmDef == null
      ? [...secondaryRhythmDefs]
      : [primaryRhythmDef, ...secondaryRhythmDefs];

  return selectedRhythms;
}

function getMaxAcuity(
  primaryRhythm: string | null | undefined,
  secondaryRhythms: string[] | null | undefined,
): AliveCorAcuityDefinition | null {
  const selectedRhythms = getSelectedRhythms(primaryRhythm, secondaryRhythms);

  const selectedAcuities: AliveCorAcuityDefinition[] = [];

  selectedRhythms.forEach((rhythm) => {
    const acuityIndex = selectedAcuities.findIndex(
      (a) => a.acuity === rhythm.acuity,
    );
    if (acuityIndex >= 0) return;

    const acuityDef = findOrThrow(
      AliveCorAcuityDefinitions,
      (a) => a.acuity === rhythm.acuity,
    );
    selectedAcuities.push(acuityDef);
  });

  selectedAcuities.sort((lhs, rhs) => rhs.severityOrder - lhs.severityOrder);

  return selectedAcuities.length > 0 ? selectedAcuities[0] : null;
}

function calculateOverReadFromRhythms(
  primaryRhythm: string | null | undefined,
  secondaryRhythms: string[] | null | undefined,
) {
  const selectedRhythms = getSelectedRhythms(primaryRhythm, secondaryRhythms);

  const newOverRead = selectedRhythms.reduce(
    (agg, rhythm) =>
      hasText(rhythm.finding)
        ? `${hasText(agg) ? `${agg}\r\n` : agg}${rhythm.finding}`
        : agg,
    '',
  );

  return hasText(newOverRead) ? newOverRead : null;
}

export const AliveCorService = {
  getMaxAcuity,
  calculateOverReadFromRhythms,
} as const;
