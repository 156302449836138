import { theme } from 'core/ui';
import { findOrThrow } from 'core/utils';

export const AirwayAnatomyOptions = [
  {
    name: 'Normal',
    color: theme.colors.palette.greens[5],
  },
  {
    name: 'Abnormal',
    color: theme.colors.palette.reds[5],
  },
] as const;

export const ExamQualities = [
  {
    name: 'Good',
    value: 'Good',
  },
  {
    name: 'Fair',
    value: 'Fair',
  },
  {
    name: 'Poor',
    value: 'Poor',
  },
] as const;

export const PulmonologyObservationsColors = {
  CLEAR: {
    name: 'Clear',
    value: 'Clear',
  },
  WHITE: {
    name: 'White',
    value: 'White',
  },
  YELLOW: {
    name: 'Yellow',
    value: 'Yellow',
  },
  BLOODY: {
    name: 'Bloody',
    value: 'Bloody',
  },
} as const;

export const ConsistencyOptions = {
  THICK: {
    name: 'Thick',
    value: 'Thick',
  },
  THIN: {
    name: 'Thin',
    value: 'Thin',
  },
} as const;

export const YesNoOptions = {
  YES: {
    name: 'Yes',
    value: 'Yes',
  },
  NO: {
    name: 'No',
    value: 'No',
  },
} as const;

export const YesNoUnknownOptions = {
  YES: {
    name: 'Yes',
    value: 'Yes',
  },
  NO: {
    name: 'No',
    value: 'No',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
};

export const ComplianceOptions = {
  YES: {
    value: '1',
    label: 'Yes',
  },
  NO: {
    value: '2',
    label: 'No',
  },
  UNKNOWN: {
    value: '3',
    label: 'Unknown',
  },
} as const;

export const FibrosisOptions = {
  NO_FIBROSIS: {
    name: 'No Fibrosis',
    value: '1',
  },
  FIBROSIS_SOME_AREAS_SEPTA: {
    name: 'Fibrosis expansion of some portal areas, with or without short fibrous septa',
    value: '2',
  },
  FIBROSIS_MOST_AREAS_SEPTA: {
    name: 'Fibrosis expansion of most portal areas, with or without short fibrous septa',
    value: '3',
  },
  FIBROSIS_MOST_AREAS_BRIDGING: {
    name: 'Fibrosis expansion of most portal areas, with occasional portal to portal bridging',
    value: '4',
  },
  FIBROSIS_MARKED_BRIDGING: {
    name: 'Fibrosis expansion of portal areas, with marked bridging (portal to portal as well as portal to central)',
    value: '5',
  },
  MARKED_BRIDGING: {
    name: 'Marked bridging with occasional nodules',
    value: '6',
  },
  CIRRHOSIS: {
    name: 'Cirrhosis, probable or definite',
    value: '7',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const PortalInfiltratesOptions = {
  NONE: {
    name: 'None detected',
    value: '1',
  },
  MILD: {
    name: 'Mild, some or all portal areas',
    value: '2',
  },
  MODERATE: {
    name: 'Moderate, some or all portal areas',
    value: '3',
  },
  MODERATE_MARKED: {
    name: 'Moderate/Marked',
    value: '4',
  },
  MARKED: {
    name: 'Marked, all portal areas',
    value: '5',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const TypeVesculiarOptions = {
  ZERO: {
    name: '0%',
    value: '1',
  },
  LESS_THAN_5: {
    name: '<5%',
    value: '2',
  },
  FROM_5_TO_10: {
    name: '5-10%',
    value: '3',
  },
  FROM_11_TO_20: {
    name: '11-20%',
    value: '4',
  },
  FROM_21_TO_30: {
    name: '21-30%',
    value: '5',
  },
  FROM_31_TO_40: {
    name: '31-40%',
    value: '6',
  },
  FROM_41_TO_50: {
    name: '41-50%',
    value: '7',
  },
  MORE_THAN_50: {
    name: '>50%',
    value: '8',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const CentrilobularNecrosisOptions = {
  NONE: {
    name: 'None/Insignificant',
    value: '1',
  },
  MODERATE: {
    name: 'Moderate',
    value: '2',
  },
  MILD_SEVERE: {
    name: 'Mild Severe',
    value: '3',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const GlomeruliThrombiOptions = {
  NONE: {
    name: 'None',
    value: '1',
  },
  MILD: {
    name: 'Mild (<10%)',
    value: '2',
  },
  MODERATE: {
    name: 'Moderate (10-25%)',
    value: '3',
  },
  SEVERE: {
    name: 'Severe (>25%)',
    value: '4',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const AcuteTubularInjuryNecrosisOptions = {
  NONE: {
    name: 'None',
    value: '1',
  },
  MILD: {
    name: 'Mild',
    value: '2',
  },
  MODERATE: {
    name: 'Moderate',
    value: '3',
  },
  SEVERE: {
    name: 'Severe',
    value: '4',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const TubularAtrophyOptions = {
  ZERO: {
    name: '0%',
    value: '1',
  },
  LESS_THAN_5: {
    name: '<5%',
    value: '2',
  },
  FROM_6_TO_10: {
    name: '6-10%',
    value: '3',
  },
  FROM_11_TO_25: {
    name: '11-25%',
    value: '4',
  },
  FROM_26_TO_50: {
    name: '26-50%',
    value: '5',
  },
  GREATER_THAN_50: {
    name: '>50%',
    value: '6',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const InterstitialFibrosisOptions = {
  NONE: {
    name: 'None',
    value: '1',
  },
  MINIMAL: {
    name: 'Minimal (<5%)',
    value: '2',
  },
  MILD: {
    name: 'Mild (6-25%)',
    value: '3',
  },
  MODERATE: {
    name: 'Moderate (26-50%)',
    value: '4',
  },
  SEVERE: {
    name: 'Severe (>50%)',
    value: '5',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const InterstitialInflammationOptions = {
  ZERO: {
    name: '0%',
    value: '1',
  },
  LESS_THAN_5: {
    name: '<5%',
    value: '2',
  },
  FROM_6_TO_10: {
    name: '6-10%',
    value: '3',
  },
  FROM_11_TO_25: {
    name: '11-25%',
    value: '4',
  },
  FROM_26_TO_50: {
    name: '26-50%',
    value: '5',
  },
  GREATER_THAN_50: {
    name: '>50%',
    value: '6',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const ArterialIntimalFibrosisOptions = {
  NONE: {
    name: 'None',
    value: '1',
    severity: 0,
  },
  MINIMAL: {
    name: 'Minimal (0-10%)',
    value: '2',
    severity: 1,
  },
  MILD: {
    name: 'Mild (11-25%)',
    value: '3',
    severity: 2,
  },
  MODERATE: {
    name: 'Moderate (26-50%)',
    value: '4',
    severity: 3,
  },
  SEVERE: {
    name: 'Severe (>50%)',
    value: '5',
    severity: 4,
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const ArteriolarHyalinosisOptions = {
  NONE: {
    name: 'None',
    value: '1',
    severity: 0,
  },
  MILD: {
    name: 'Mild to Moderate (1 arteriole)',
    value: '2',
    severity: 2,
  },
  MODERATE: {
    name: 'Moderate to Severe (>1 arteriole)',
    value: '3',
    severity: 3,
  },
  SEVERE: {
    name: 'Severe (Multiple or circumferential)',
    value: '4',
    severity: 4,
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export const SATISFACTORY_SAMPLE_SIZE_OPTIONS = [
  {
    name: 'Acceptable',
    value: '1',
    requiresComments: false,
    requiresCommentsMessage: null,
  },
  {
    name: 'Sub-Optimal',
    value: '3',
    requiresComments: true,
    requiresCommentsMessage: 'Please explain why the sample is "Sub-Optimal" and possible solutions.',
  },
  {
    name: 'Non-Acceptable',
    value: '2',
    requiresComments: true,
    requiresCommentsMessage: 'Please explain why the sample is "Non-Acceptable" and possible solutions.',
  },
];

export const SatisfactorySampleSizeOptions = {
  ACCEPTABLE: findOrThrow(
    SATISFACTORY_SAMPLE_SIZE_OPTIONS,
    (option) => option.value === '1',
    `Could not find SATISFACTORY_SAMPLE_SIZE_OPTIONS entry with value "1" for "ACCEPTABLE".`,
  ),
  SUBOPTIMAL: findOrThrow(
    SATISFACTORY_SAMPLE_SIZE_OPTIONS,
    (option) => option.value === '3',
    `Could not find SATISFACTORY_SAMPLE_SIZE_OPTIONS entry with value "3" for "SUBOPTIMAL".`,
  ),
  NON_ACCEPTABLE: findOrThrow(
    SATISFACTORY_SAMPLE_SIZE_OPTIONS,
    (option) => option.value === '2',
    `Could not find SATISFACTORY_SAMPLE_SIZE_OPTIONS entry with value "2" for "NON_ACCEPTABLE".`,
  ),
};

export const KidneyVascularChangesOptions = {
  NONE: {
    name: 'None (<10%)',
    value: '1',
  },
  MILD: {
    name: 'Mild (10-25%)',
    value: '2',
  },
  MODERATE: {
    name: 'Moderate (26-50%)',
    value: '3',
  },
  SEVERE: {
    name: 'Severe (>50%)',
    value: '4',
  },
  Unknown: {
    name: 'Unknown',
    value: 'Unknown',
  },
} as const;

export enum ExamReadSubmitType {
  APPROVE = 'exam-read-submit-approve',
  APPROVE_AND_NEXT = 'exam-read-submit-approve-and-next',
  FINALIZE = 'exam-read-submit-finalize',
  SAVE = 'exam-read-submit-save',
}

export const FibrinThrombiOptions = [
  {
    name: 'Absent',
    value: 'Absent',
  },
  {
    name: '25%',
    value: '25%',
  },
  {
    name: '50%',
    value: '50%',
  },
  {
    name: '75%',
    value: '75%',
  },
  {
    name: '100%',
    value: '100%',
  },
  {
    name: 'Unknown',
    value: 'Unknown',
  },
] as const;

export const InterstitialFibrosisandTubularAtrophyOptions = [
  {
    name: '<5%',
    value: '1',
  },
  {
    name: '6-25%',
    value: '2',
  },
  {
    name: '26-50%',
    value: '3',
  },
  {
    name: '>50%',
    value: '4',
  },
  {
    name: 'Unknown',
    value: 'Unknown',
  },
] as const;

export const CorticalNecrosisOptions = [
  {
    name: 'Absent',
    value: 'Absent',
  },
  {
    name: '25%',
    value: '25%',
  },
  {
    name: '50%',
    value: '50%',
  },
  {
    name: '75%',
    value: '75%',
  },
  {
    name: '100%',
    value: '100%',
  },
  {
    name: 'Unknown',
    value: 'Unknown',
  },
] as const;

export const NodularMesangialGlomerulosclerosisOptions = [
  {
    name: 'Absent',
    value: '1',
  },
  {
    name: 'Present',
    value: '2',
  },
  {
    name: 'Unknown',
    value: 'Unknown',
  },
] as const;

export const Tags = [
  {
    tag: 'Flipped Image',
  },
  {
    tag: 'QA',
  },
] as const;
