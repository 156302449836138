import { useContext } from 'react';

import { LocationLiteModel } from 'models';

import { AppSessionLocationContext } from '../contexts';
import { AppSessionLocationContextValue } from '../types';

function useSessionLocation(required: true): UseSessionLocationResultRequired;
function useSessionLocation(required?: false): AppSessionLocationContextValue;
function useSessionLocation(required = false) {
  const context = useContext(AppSessionLocationContext);

  // Always throw if the provider is somehow not in the React tree.
  if (context == null) throw new Error('<AppSessionLocationProvider /> must be an ancestor.');

  // Conditionally throw if "required" was set to true.
  if (required && context.sessionLocationOptions == null) {
    throw new Error('The session location provider is still fetching the list of locations.');
  }

  if (required && context.sessionLocation == null) {
    throw new Error('The session location has not been selected.');
  }

  return context;
}

export { useSessionLocation };

export type UseSessionLocationResultRequired = {
  sessionLocationOptions: LocationLiteModel[];
  sessionLocation: LocationLiteModel;
  setSessionLocation: (location: LocationLiteModel | null) => void;
};
