import { normalize } from 'normalizr';

import { IntegrationModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { IntegrationRouteService } from '../services';
import {
  NormalizedIntegrationModelSchema,
  integrationEntity,
} from './normalizr-schema';

const getAll = createAsyncThunk(
  'integrations/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(IntegrationRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<IntegrationModel>;

    const normalized = normalize<
      IntegrationModel,
      NormalizedIntegrationModelSchema
    >(result, [integrationEntity]);

    return normalized.entities;
  },
);

export const IntegrationActions = {
  getAll,
};
