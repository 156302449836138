import { CompressCompleteEvent, CompressErrorEvent, CompressStartEvent } from '@repo/web-workers/file-compressor';

import { DataStream, EventStream, IDataStreamConsumer, IEventStreamConsumer, findOrThrow, getFileExtension } from 'core/utils';

import {
  CompressQueueItem,
  FileUploadMetadata,
  InitialFileContext,
  QueueFilesEvent,
  UploadCompleteEvent,
  UploadErrorEvent,
  UploadFileContext,
  UploadProgressEvent,
  UploadQueueItem,
  UploadStartEvent,
} from '../types';
import { AzureBlobUploader } from './AzureBlobUploader';
import { FileCompressor } from './FileCompressor';
import { FileScanner } from './FileScanner';

const MAX_COMPRESSION_CONCURRENCY = navigator.hardwareConcurrency; // logical processors

export class UploadManager {
  static [Symbol.toStringTag]() {
    return 'UploadManager';
  }

  /** The key is the fileId. */
  private _uploadMetadata = new Map<string, FileUploadMetadata>();

  private _compressQueue: CompressQueueItem[] = [];

  private _uploadQueue: UploadQueueItem[] = [];

  private _cleanupFunctions: (() => void)[] = [];

  private _streams = {
    onQueueFiles: new EventStream<QueueFilesEvent>(),
    pipelineLength: new DataStream<number>(0), // TODO: This would likely be better if it was a computed value that was derived from the queues.  However implementing that will require touching all of the processing functions.
    files: new DataStream<UploadFileContext[]>([]),
  };

  public get streams(): {
    onQueueFiles: IEventStreamConsumer<QueueFilesEvent>;
    /** Keeps track of how many items are in the upload pipeline (excluding the file scanner).  This counts files that are actively being processed (files are removed from the individual queues during processing). */
    pipelineLength: IDataStreamConsumer<number>;
    files: IDataStreamConsumer<UploadFileContext[]>;
  } {
    return this._streams;
  }

  constructor(
    public readonly fileScanner: FileScanner,
    public readonly blobUploader: AzureBlobUploader,
    public readonly fileCompressor: FileCompressor,
  ) {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.scanDicomFiles = this.scanDicomFiles.bind(this);
    this.scanNonDicomFiles = this.scanNonDicomFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.advanceCompressQueue = this.advanceCompressQueue.bind(this);
    this.advanceUploadQueue = this.advanceUploadQueue.bind(this);
    this.handleCompressStart = this.handleCompressStart.bind(this);
    this.handleCompressComplete = this.handleCompressComplete.bind(this);
    this.handleCompressError = this.handleCompressError.bind(this);
    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);
  }

  public initialize(getSasFn: (containerName: string) => Promise<string>) {
    this.fileScanner.initialize();
    this.fileCompressor.initialize();
    this.blobUploader.initialize(getSasFn);

    this._cleanupFunctions.push(this.fileCompressor.streams.onComplete.subscribe(this.handleCompressComplete));
    this._cleanupFunctions.push(this.fileCompressor.streams.onError.subscribe(this.handleCompressError));
    this._cleanupFunctions.push(this.blobUploader.streams.onStart.subscribe(this.handleUploadStart));
    this._cleanupFunctions.push(this.blobUploader.streams.onProgress.subscribe(this.handleUploadProgress));
    this._cleanupFunctions.push(this.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete));
    this._cleanupFunctions.push(this.blobUploader.streams.onError.subscribe(this.handleUploadError));
  }

  public destroy() {
    this._cleanupFunctions.forEach((cleanup) => cleanup());
    this._cleanupFunctions = [];
    this._uploadMetadata.clear();
    this._compressQueue = [];
    this._streams.onQueueFiles.clear();
    this._streams.pipelineLength.clear();
    this._streams.files.clear();

    this.fileScanner.destroy();
    this.fileCompressor.destroy();
    this.blobUploader.destroy();
  }

  public scanDicomFiles(files: File[]) {
    this.fileScanner.enqueueDicomFiles(
      files.map((file) => ({
        fileId: crypto.randomUUID(),
        file,
      })),
    );
  }

  public scanNonDicomFiles(files: File[]) {
    this.fileScanner.enqueueNonDicomFiles(files.map((file) => ({ fileId: crypto.randomUUID(), file })));
  }

  public uploadFiles(files: InitialFileContext[]): UploadFileContext[] {
    const newContexts: UploadFileContext[] = files.map((file) => ({
      ...file,
      fileId: file.fileId ?? crypto.randomUUID(),
      fileType: getFileExtension(file.file.name) ?? undefined,
      compress: file.compress ?? true,
      result: 'in-progress',
    }));

    for (const file of newContexts) {
      if (file.compress) {
        this._compressQueue.push({
          fileId: file.fileId,
          file: file.file,
        });
      } else {
        this.blobUploader.enqueueFile(file.fileId, file.file.name, file.compress, file.file.arrayBuffer(), file.containerName, file.metadata);
      }

      this._uploadMetadata.set(file.fileId, file.metadata);
    }

    this.advanceCompressQueue();
    this.advanceUploadQueue();

    this._streams.files.emit([...this._streams.files.getCurrentValue(), ...newContexts]);
    this._streams.pipelineLength.emit(this._streams.pipelineLength.getCurrentValue() + newContexts.length);
    this._streams.onQueueFiles.emit({ files: [...newContexts] });

    return newContexts;
  }

  private advanceCompressQueue() {
    if (this._uploadQueue.length < MAX_COMPRESSION_CONCURRENCY) {
      const queueItem = this._compressQueue.shift();

      if (queueItem == null) return;

      this.fileCompressor.compressFile(queueItem.fileId, queueItem.file);
    }
  }

  private advanceUploadQueue() {
    this.blobUploader.run();
  }

  private handleCompressStart(_event: CompressStartEvent) {
    // Does nothing for now.
  }

  private handleCompressComplete(event: CompressCompleteEvent) {
    const fileContext = findOrThrow(
      this._streams.files.getCurrentValue(),
      (f) => f.fileId === event.fileId,
      `Could not find file context for file Id: ${event.fileId}.`,
    );

    this.blobUploader.enqueueFile(event.fileId, event.fileName, true, event.buffer, fileContext.containerName, this._uploadMetadata.get(event.fileId)!);

    this.advanceCompressQueue();
    this.advanceUploadQueue();
  }

  private handleCompressError(event: CompressErrorEvent) {
    this._streams.pipelineLength.emit(this._streams.pipelineLength.getCurrentValue() - 1);
    this._uploadMetadata.delete(event.fileId);
    this.advanceCompressQueue();
  }

  private handleUploadStart(_event: UploadStartEvent) {
    // Does nothing for now.
  }

  private handleUploadProgress(_event: UploadProgressEvent) {
    // Does nothing for now.
  }

  private handleUploadComplete(event: UploadCompleteEvent) {
    this._streams.pipelineLength.emit(this._streams.pipelineLength.getCurrentValue() - 1);
    this._uploadMetadata.delete(event.fileId);
    this.advanceCompressQueue();
    this.advanceUploadQueue();
  }

  private handleUploadError(event: UploadErrorEvent) {
    this._streams.pipelineLength.emit(this._streams.pipelineLength.getCurrentValue() - 1);
    this._uploadMetadata.delete(event.fileId);
    this.advanceCompressQueue();
    this.advanceUploadQueue();
  }
}
