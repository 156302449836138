import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { HeaderProps } from './HeaderProps';

export const Header: FunctionComponent<HeaderProps> = ({
  children,
  className,
}) => {
  return <StyledDivHeader className={className}>{children}</StyledDivHeader>;
};

Header.displayName = 'Header';

const StyledDivHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.body};
  gap: ${({ theme }) => theme.space.spacing10};
  line-height: ${({ theme }) => theme.lineHeights.body};
`;
