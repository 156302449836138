import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FlatIconButton } from '../Button';
import { Icon } from '../Icon';
import { FileListItemProps } from './FileListItemProps';

export const FileListItem: FunctionComponent<FileListItemProps> = ({
  file,
  onRemove,
}) => {
  const { uid } = file;

  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  const handleRemoveClick = useCallback(() => {
    onRemove(uid);
  }, [onRemove, uid]);

  useEffect(() => {
    const rawFile = file.getRawFile?.();

    if (rawFile == null) return () => {};

    const newBlobUrl = URL.createObjectURL(rawFile);

    setBlobUrl(newBlobUrl);

    return () => {
      URL.revokeObjectURL(newBlobUrl);
    };
  }, [file]);

  return (
    <StyledLi key={file.uid}>
      <StyledSummaryDiv>
        <StyledNameLabel>{file.name}</StyledNameLabel>
        <StyledRemoveButton onClick={handleRemoveClick} title="Remove">
          <Icon icon={faCircleXmark} fixedWidth block />
        </StyledRemoveButton>
      </StyledSummaryDiv>

      <div>
        {blobUrl != null && <StyledPreviewImg src={blobUrl} alt={file.name} />}
      </div>
    </StyledLi>
  );
};

FileListItem.displayName = 'FileListItem';

const StyledLi = styled.li`
  padding: 0;
`;

const StyledSummaryDiv = styled.div`
  display: flex;
`;

const StyledNameLabel = styled.div`
  flex: 1 1 0;
`;

const StyledRemoveButton = styled(FlatIconButton)`
  flex: 0 0 min-content;
`;

const StyledPreviewImg = styled.img`
  max-width: 100%;
  max-height: 75px;
  object-fit: contain;
`;
