import { memo } from 'react';

import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

import { MergeStepResultProps } from '../types';

export const MergeStepResult = memo<MergeStepResultProps>(({ isMergeSuccessful }) => {
  switch (isMergeSuccessful) {
    case true:
      return (
        <StyledAlert key="success" variant="success">
          Merge Successful!
        </StyledAlert>
      );
    case false:
      return (
        <StyledAlert key="danger" variant="danger">
          Merge Failed!
        </StyledAlert>
      );
    default:
      return <></>;
  }
});

MergeStepResult.displayName = 'MergeStepResult';

const StyledAlert = styled(Alert)`
  margin-top: ${(props) => props.theme.space.spacing50};
`;
