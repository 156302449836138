import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../main/hooks';
import { ImageQualityActions, ImageQualitySelectors } from '../redux';

export const useImageQualities = () => {
  const dispatch = useAppDispatch();

  const imageQualities = useSelector(ImageQualitySelectors.getAll);

  useEffect(() => {
    dispatch(ImageQualityActions.getAll());
  }, [dispatch]);

  return [imageQualities];
};
