export enum ExamStatusTrigger {
  Assign = 0,
  Completed = 1,
  Read = 2,
  Send = 3,
  Cancel = 4,
  Duplicate = 5,
  New = 6,
  RequestReading = 7,
  Accept = 8,
}
