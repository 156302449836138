import { FormFieldModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class FormFieldClient {
  constructor(private httpClient: HttpClient) {
    this.getFormFieldsByCategory = this.getFormFieldsByCategory.bind(this);
  }

  public async getFormFieldsByCategory(fieldCategoryId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const fields = await this.httpClient.fetchAndParse<FormFieldModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/form-field/GetFormFieldsByCategory?fieldCategoryId=${fieldCategoryId}`,
        options,
      ),
    );
    return fields;
  }
}
