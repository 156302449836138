import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { PhysicianModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { PhysicianRouteService } from '../services';
import {
  NormalizePhysicianModelSchema,
  physicianEntity,
} from './normalizr-schema';

const add = createAsyncThunk(
  'physicians/add',
  async (physician: PhysicianModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: PhysicianRouteService.getBaseRoute(),
        body: physician,
      }),
    )) as unknown as ApiResponse<PhysicianModel>;

    const normalized = normalize<PhysicianModel, NormalizePhysicianModelSchema>(
      { ...physician, id: result.id },
      physicianEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'physicians/edit',
  async (physician: PhysicianModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: PhysicianRouteService.getBaseRoute(),
        body: physician,
      }),
    );

    const normalized = normalize<PhysicianModel, NormalizePhysicianModelSchema>(
      physician,
      physicianEntity,
    );

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'physicians/get-by-id',
  async (physicianId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(PhysicianRouteService.getByIdRoute(physicianId)),
    )) as unknown as ApiResponse<PhysicianModel>;

    return result;
  },
);

const getAll = createAsyncThunk(
  'physicians/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(PhysicianRouteService.getAllRoute()),
    )) as unknown as ApiResponse<PhysicianModel>;

    const normalized = normalize<PhysicianModel, NormalizePhysicianModelSchema>(
      result,
      [physicianEntity],
    );

    return normalized.entities;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'physicians-grid/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = PhysicianRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [physicianEntity]);

    return { ...normalized, total: result.total };
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'physicians/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = PhysicianRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<PhysicianModel>;

    return result;
  },
);

export const PhysicianActions = {
  add,
  edit,
  getAll,
  getById,
  getAllForExport,
  getAllForKendoGrid,
};
