import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { SourceModel } from 'models/SourceModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class SourceClient {
  constructor(private httpClient: HttpClient) {
    this.getSourceById = this.getSourceById.bind(this);
    this.getAllSourcesForKendoGrid = this.getAllSourcesForKendoGrid.bind(this);
    this.createSource = this.createSource.bind(this);
    this.updateSource = this.updateSource.bind(this);
  }

  public async getSourceById(sourceId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const source = await this.httpClient.fetchAndParse<SourceModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Sources/${encodeURIComponent(sourceId)}`, options),
    );
    return source;
  }

  public async getAllSourcesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<SourceModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Sources/grid?${queryStr}`, options),
    );
    return result;
  }

  public async createSource(source: SourceModel) {
    const options = await this.httpClient.createStandardOptions('POST', source);
    const result = await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Sources`, options));
    return result;
  }

  public async updateSource(source: SourceModel) {
    const options = await this.httpClient.createStandardOptions('PUT', source);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Sources`, options));
    return result;
  }
}
