import dicomParser from 'dicom-parser';

import { DicomDataDictionary } from './dicom-data-dictionary';
import { DicomTagDefinition } from './types';

export const FileTypes = {
  IMAGE: ['PNG', 'JPG', 'JPEG'],
  PDF: ['PDF'],
  XML: ['XML'],
  LINK: ['LINK'],
  ALIVE_COR_ATC: ['ATC'],
};

export enum Viewer {
  AliveCorSingleLead = 'AliveCor-SingleLead',
  AliveCorSixLead = 'AliveCor-SixLead',
  MedDream = 'meddream',
  Brit = 'brit',
  Ambra = 'ambra',
  Techcyte = 'techcyte',
  Pathozoom = 'pathozoom',
  Aiforia = 'aiforia',
}

export const LOCALSTORAGE_KEY_ECG_VIEWER_WRAPPING = 'ecg-viewer-wrapping';

export const DEFAULT_MULTI_MONITOR_ENABLED = false;

/** DICOM tag common name to encoded form lookup. */
export const DICOM_TAG_LOOKUPS = {
  /** @see https://dicom.nema.org/medical/dicom/current/output/html/part06.html#chapter_6 */
  SeriesInstanceUID: 'x0020000e',
  /** @see https://dicom.nema.org/medical/dicom/current/output/html/part05.html#sect_10.1 */
  TransferSyntaxUID: '1.2.840.10008.1.2',
} as const;

export const NORMALIZED_DATE_FORMAT = 'MM/DD/YYYY';
export const NORMALIZED_TIME_FORMAT = 'HH:mm:ss';
export const DATE_PARSE_FORMATS = ['YYYYMMDD', 'YYYY-MM-DD', 'MM/DD/YYYY']; // Each format is tried in order until a valid date is found.
export const TIME_PARSE_FORMATS = ['HHmmss', 'HH:mm:ss']; // Each format is tried in order until a valid time is found.

export const PARSE_DICOM_METADATA_OPTIONS: NonNullable<Parameters<typeof dicomParser.parseDicom>[1]> = {
  TransferSyntaxUID: DICOM_TAG_LOOKUPS.TransferSyntaxUID,
  untilTag: DICOM_TAG_LOOKUPS.SeriesInstanceUID,
};

export const PARSE_DICOM_METADATA_FILE_DATA_OPTIONS: NonNullable<Parameters<typeof dicomParser.explicitDataSetToJS>[1]> = {
  omitPrivateAttibutes: false, // Note: The dicomParser library has a typo in their option's object type definition.
  maxElementLength: 128,
};

/** DICOM tags that we need to extract from files.  The tags enumerated here are used to hydrate the parsed dicom DTO. */
export const PARSE_DICOM_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0008,0050)'], // AccessionNumber
  DicomDataDictionary['(0018,0015)'], // BodyPartExamined
  DicomDataDictionary['(0008,1030)'], // StudyDescription
  DicomDataDictionary['(0010,0030)'], // PatientBirthDate
  DicomDataDictionary['(0010,0020)'], // PatientID
  DicomDataDictionary['(0010,0040)'], // PatientSex
  DicomDataDictionary['(0010,0010)'], // PatientName
  DicomDataDictionary['(0008,0020)'], // StudyDate
  DicomDataDictionary['(0008,0030)'], // StudyTime
  DicomDataDictionary['(0020,000D)'], // StudyInstanceUID
  DicomDataDictionary['(0008,0060)'], // Modality
  DicomDataDictionary['(0020,000E)'], // SeriesInstanceUID
  DicomDataDictionary['(0008,103E)'], // SeriesDescription
  DicomDataDictionary['(0002,0010)'], // TransferSyntaxUID
];

/** DICOM tags that have caret delimited values.  These will be split on the "^" character into an array of strings. */
export const PARSE_DICOM_CARET_DELIMITED_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0010,0010)'], // PatientName
];

/** DICOM tags that need to be parsed as date-only fields.  These will be normalized to a consistent format.  Values that cannot be parsed as valid dates are logged and omitted from the parsed result. */
export const PARSE_DICOM_DATE_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0010,0030)'], // PatientBirthDate
  DicomDataDictionary['(0008,0020)'], // StudyDate
];

/** DICOM tags that need to be parsed as time-only fields.  These will be normalized to a consistent format.  Values that cannot be parsed as valid dates are logged and omitted from the parsed result. */
export const PARSE_DICOM_TIME_TAGS: DicomTagDefinition[] = [
  DicomDataDictionary['(0008,0030)'], // StudyTime
];

/** Transfer syntaxes that indicate the embedded media is uncompressed. */
export const DICOM_UNCOMPRESSED_TRANSFER_SYNTAXES = [
  '1.2.840.10008.1.2', // Implicit VR Endian: Default Transfer Syntax for DICOM
  '1.2.840.10008.1.2.1', // Explicit VR Little Endian
  '1.2.840.10008.1.2.1.99', // Deflated Explicit VR Little Endian
  '1.2.840.10008.1.2.2', // Explicit VR Big Endian
];

/** File upload current throughput will be a moving average over this amount of time in milliseconds. */
export const CURRENT_THROUGHPUT_DURATION = 30 * 1000;
