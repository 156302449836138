import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { PhysicianModel } from 'models';

import { PhysicianStatus } from '../constants';
import { PhysicianActions } from './actions';

export const physicianAdapter = createEntityAdapter<PhysicianModel>({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...physicianAdapter.getInitialState(),
  total: 0,
  status: PhysicianStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(PhysicianActions.getById.fulfilled, (draft, action) => {
    const payload = action.payload as PhysicianModel;
    physicianAdapter.setOne(draft, payload);
    draft.status = PhysicianStatus.FETCHED;
  });
  builder.addCase(PhysicianActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { physicians: PhysicianModel };
    physicianAdapter.addOne(draft, payload.physicians);
    draft.status = PhysicianStatus.ADDED;
  });
  builder.addCase(PhysicianActions.edit.fulfilled, (draft, action) => {
    const payload = action.payload as {
      physicians: Record<number, PhysicianModel>;
    };

    Object.values(payload.physicians).forEach((physician) => {
      physicianAdapter.setOne(draft, physician);
    });

    draft.status = PhysicianStatus.UPDATED;
  });
  builder.addCase(PhysicianActions.getAll.fulfilled, (draft, action) => {
    const payload = action.payload as { physicians: PhysicianModel[] };
    physicianAdapter.setMany(draft, payload.physicians);
    draft.status = PhysicianStatus.FETCHED;
  });
});

export const reducerGrid = createReducer(
  getReducerInitialState(),
  (builder) => {
    builder.addCase(
      PhysicianActions.getAllForKendoGrid.fulfilled,
      (draft, action) => {
        const payload = action.payload as {
          entities: { physicians: PhysicianModel[] };
          total: number;
          result: EntityId[];
        };

        physicianAdapter.removeAll(draft);

        if (payload.entities.physicians) {
          physicianAdapter.addMany(draft, payload.entities.physicians);
        }

        draft.total = payload.total;
        draft.ids = payload.result;
        draft.status = PhysicianStatus.FETCHED;
      },
    );
  },
);
