export enum BadgeShapes {
  CIRCLE = 'circle',
  DOT = 'dot',
}

export enum BadgeFills {
  SOLID = 'solid',
  OUTLINE = 'outline',
}

export enum BadgeTypes {
  DEFAULT = 'default',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  COUNT = 'count',
}
