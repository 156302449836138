import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { UserModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { UserRouteService } from '../services';
import { NormalizeUserModelSchema, userEntity } from './normalizr-schema';

const add = createAsyncThunk(
  'users/add',
  async (user: UserModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: UserRouteService.getBaseRoute(),
        body: user,
      }),
    )) as unknown as ApiResponse<UserModel>;

    const normalized = normalize<UserModel, NormalizeUserModelSchema>(
      { ...user, id: result.id },
      userEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'users/edit',
  async (user: UserModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: UserRouteService.getBaseRoute(),
        body: user,
      }),
    );

    const normalized = normalize<UserModel, NormalizeUserModelSchema>(
      user,
      userEntity,
    );

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'users/get-by-id',
  async (userId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(UserRouteService.getByIdRoute(userId)),
    )) as unknown as ApiResponse<UserModel>;

    return result;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'users-grid/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = UserRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [userEntity]);

    return { ...normalized, total: result.total };
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'users/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = UserRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<UserModel>;

    return result;
  },
);

export const UserActions = {
  add,
  edit,
  getById,
  getAllForExport,
  getAllForKendoGrid,
};
