import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { DCMStudyInstanceModel } from 'models/DCMStudyInstanceModel';

import {
  DEFAULT_DATA_TABLE_DATA_STATE,
  DEFAULT_PAGE_SIZES,
  DataTable,
  DateCell,
  GridColumn,
  GridDataStateChangeEvent,
  HeaderCell,
  Page,
  PageHeader,
  TextCell,
  styled,
} from 'core/ui';

import { apiClient } from 'features/api';

export const PACSHome: FunctionComponent = () => {
  const [pacsData, setPACSData] = useState<DCMStudyInstanceModel[]>([]);
  const [pacsDataTotal, setPACSDataTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const PageableSettings = {
    pageSizes: DEFAULT_PAGE_SIZES,
  };

  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.dicomClient.getAllSourcesForKendoGrid(dataState);

      setPACSData(queryResult.data);
      setPACSDataTotal(queryResult.total);
    })();
  }, [dataState]);

  return (
    <Page>
      <PageHeader title="PACS" />
      <StyledDataTable
        data={pacsData}
        filterable
        sortable
        pageable={PageableSettings}
        total={pacsDataTotal}
        onDataStateChange={handleDataStateChange}
        // actions={gridActions}
        {...dataState}
      >
        <GridColumn title="Id" field="id" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={80} />
        <GridColumn title="Accession" field="accession" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="MRN" field="mrn" filter="text" headerCell={HeaderCell} cell={TextCell} />
        <GridColumn title="SUID" field="suid" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Site ID" field="siteId" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Data" field="data" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Study Date" field="studyDate" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Study Time" field="studyTime" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={100} />
        <GridColumn title="Birth Date" field="birthDate" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Sex" field="sex" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Patient Name" field="patientName" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Study Description" field="studyDescription" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Study ID" field="studyID" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="BodyPartExamined" field="bodyPartExamined" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="StationName" field="stationName" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="InstitutionName" field="institutionName" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="InstitutionAddress" field="institutionAddress" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Referring Physician Name" field="referringPhysiciansName" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Manufacturer" field="Manufacturer" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Reason For Study RETIRED" field="reasonForStudyRETIRED" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Requesting Service" field="requestingService" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Modality" field="modality" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Manufacturer" field="manufacturer" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Exam Id" field="exam_Id" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Viewer Id" field="viewerId" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Additional Patient History" field="additionalPatientHistory" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="SourceAE" field="sourceAE" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="DestinationAE" field="destinationAE" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Source Host" field="sourceHost" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Date Created" field="dateCreated" filter="date" headerCell={HeaderCell} cell={DateCell} width={300} />
        <GridColumn title="Read" field="read" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Receive Start UTC" field="receiveStartUTC" filter="date" headerCell={HeaderCell} cell={DateCell} width={300} />
        <GridColumn title="Receive End UTC" field="receiveEndUTC" filter="date" headerCell={HeaderCell} cell={DateCell} width={300} />
        <GridColumn title="Source Id" field="sourceId" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
      </StyledDataTable>
    </Page>
  );
};

PACSHome.displayName = 'PACSHome';

const StyledDataTable = styled(DataTable)`
  max-height: initial;
`;
