import { schema } from 'normalizr';

import { ExamModel, ReadExamModel } from 'models';

export const examEntity = new schema.Entity<ExamModel>('exams');

export const examReadEntity = new schema.Entity<ReadExamModel>('examReads');

export type NormalizeExamModelSchema = {
  exams: Record<string, ExamModel>;
};

export type NormalizeReadExamModelSchema = {
  examReads: Record<string, ReadExamModel>;
};
