import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LocationModel, SettingOverrideModel } from 'models';

import { useTabLocationPersistence } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, TabContainer, TabContent, TabNav, TabNavContainer, TabNavItem, TabNavLink, TabPane } from 'core/ui';

import { AccountActions } from 'features/account';
import { apiClient } from 'features/api';
import { IntegrationActions } from 'features/integration';
import { LocationTypeActions } from 'features/location-type';
import { useAppDispatch } from 'features/main/hooks';
import { TatActions } from 'features/tat';

import { InitialValues } from '../constants';
import { LocationEditService } from '../services';
import { ContactTabContent } from './ContactTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { IntegrationTabContent } from './IntegrationTabContent';
import { NotificationsTabContent } from './NotificationsTabContent';
import { SettingsTabContent } from './SettingsTabContent';
import { TatTabContent } from './TatTabContent';

const allTabKeys = ['general', 'contact', 'integration', 'notifications', 'tat', 'settings'];

export const LocationForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [contactTabForm, setContactTabForm] = useState(InitialValues.contactTab);
  const [generalTabForm, setGeneralTabForm] = useState(InitialValues.generalTab);
  const [integrationTabForm, setIntegrationTabForm] = useState(InitialValues.integrationTab);
  const [notificationsTabForm, setNotificationsTabForm] = useState(InitialValues.notificationsTab);
  const [tatTabForm, setTatTabForm] = useState(InitialValues.tatTab);
  const [settingOverrides, setSettingOverrides] = useState<SettingOverrideModel[]>(InitialValues.settingOverrides);
  const [isFormReady, setIsFormReady] = useState(false);

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const isFormInEditMode = id !== undefined;

  const [location, setLocation] = useState<LocationModel | null>(null);

  useEffect(() => {
    (async () => {
      if (isFormInEditMode && location != null) {
        const newForms = await LocationEditService.copyModelToForms(location);

        setGeneralTabForm(newForms.generalTabForm);
        setContactTabForm(newForms.contactTabForm);
        setIntegrationTabForm(newForms.integrationTabForm);
        setNotificationsTabForm(newForms.notificationsTabForm);
        setTatTabForm(newForms.tatTabForm);
        setSettingOverrides(newForms.settingOverrides);
        setIsFormReady(true);
      }
    })();
  }, [isFormInEditMode, location]);

  useEffect(() => {
    if (!isFormInEditMode) {
      setIsFormReady(true);
    }
  }, [isFormInEditMode]);

  useEffect(() => {
    dispatch(LocationTypeActions.getAll(undefined));
    dispatch(TatActions.getAll(undefined));
    dispatch(IntegrationActions.getAll(undefined));
    dispatch(AccountActions.getAll(undefined));
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (isFormInEditMode) {
        const newLocation = await apiClient.locationClient.getLocationById(id);
        setLocation(newLocation);
      }
    })();
  }, [dispatch, id, isFormInEditMode]);

  const handleSubmit = useCallback(async () => {
    const newLocation = await LocationEditService.copyFormsToModel(
      location,
      generalTabForm,
      contactTabForm,
      integrationTabForm,
      notificationsTabForm,
      tatTabForm,
      settingOverrides,
    );

    if (isFormInEditMode) {
      await apiClient.locationClient.updateLocation(newLocation);
      NotificationsService.displaySuccess(`Saved ${newLocation.name}.`);
    } else {
      await apiClient.locationClient.createLocation(newLocation);
      NotificationsService.displaySuccess(`Created ${newLocation.name}.`);
    }

    navigate('/location');
  }, [location, generalTabForm, contactTabForm, integrationTabForm, notificationsTabForm, tatTabForm, isFormInEditMode, settingOverrides, navigate]);

  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);

  if (!isFormReady) return <></>;

  return (
    <Page>
      <PageHeader title={isFormInEditMode ? 'Edit Location' : 'New Location'} />
      <StyledTabWrapper>
        <TabContainer id="location-type-tabs" onSelect={onTabSelect} defaultActiveKey={initialTabKey}>
          <TabNavContainer>
            <TabNav>
              <TabNavItem>
                <TabNavLink eventKey="general">General</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="contact">Contact Info</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="integration">Integration</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="notifications">Notifications</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="tat">TAT Control</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="settings">Settings</TabNavLink>
              </TabNavItem>
            </TabNav>
          </TabNavContainer>
          <TabContent>
            <TabPane eventKey="general">
              <GeneralTabContent initialValues={generalTabForm} onChange={setGeneralTabForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="contact">
              <ContactTabContent initialValues={contactTabForm} onChange={setContactTabForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="integration">
              <IntegrationTabContent initialValues={integrationTabForm} onChange={setIntegrationTabForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="notifications">
              <NotificationsTabContent initialValues={notificationsTabForm} onChange={setNotificationsTabForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="tat">
              <TatTabContent initialValues={tatTabForm} onChange={setTatTabForm} onSubmit={handleSubmit} />
            </TabPane>
            <TabPane eventKey="settings">
              <SettingsTabContent initialValues={settingOverrides} onChange={setSettingOverrides} onSubmit={handleSubmit} />
            </TabPane>
          </TabContent>
        </TabContainer>
      </StyledTabWrapper>
    </Page>
  );
};

LocationForm.displayName = 'LocationForm';

const StyledTabWrapper = styled.div`
  margin-top: 4px;
`;
