import { FunctionComponent } from 'react';

import { TextFilterProps } from '@progress/kendo-react-data-tools';

import { useExamStatusTypesCache } from 'features/exam-status';

import { DropdownFilter } from './DropdownFilter';

export const ExamStatusDropdownFilter: FunctionComponent<TextFilterProps> = ({
  filter,
  onFilterChange,
}) => {
  const statuses = useExamStatusTypesCache();

  return (
    <DropdownFilter
      data={statuses}
      filter={filter}
      onFilterChange={onFilterChange}
    />
  );
};
