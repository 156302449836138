import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { filterBy } from '@progress/kendo-data-query';
import {
  MultiSelect as KendoMultiSelect,
  MultiSelectProps as KendoMultiSelectProps,
  MultiSelectFilterChangeEvent,
  MultiSelectHandle,
  TagData,
} from '@progress/kendo-react-dropdowns';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { Item } from './Item';
import { MultiSelectProps } from './MultiSelectProps';

export const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  allowCustom = false,
  dataItemKey = 'id',
  filterable = true,
  size = ComponentSizes.MEDIUM,
  itemComponent: ItemComponent = Item,
  isOptionalLabelShown = false,
  textField = 'name',
  data,
  description,
  disabled,
  filterFields,
  hint,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  tabIndex,
  valid,
  validationMessage,
  value,
  visited,
  ...rest
}) => {
  const internalRef = useRef<MultiSelectHandle>();
  const [multiSelectData, setMultiSelectData] = useState(data);

  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = Boolean(!isValidationMessageShown && hint);
  const hintId = isHintShown ? `${name}_hint` : '';
  const errorId = isValidationMessageShown ? `${name}_error` : '';
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

  const handleRemoveItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (itemToRemove: any) => {
      if (internalRef.current == null) {
        throw new Error(
          'Cannot emit onChange event because the internal reference to the Kendo MultiSelect component is null or undefined.',
        );
      }

      onChange?.({
        nativeEvent: {} as never,
        syntheticEvent: {} as never,
        target: internalRef.current,
        value:
          value != null && dataItemKey != null
            ? value.filter((x) => x[dataItemKey] !== itemToRemove[dataItemKey])
            : value == null
              ? value
              : undefined,
      });
    },
    [dataItemKey, onChange, value],
  );

  const handleFilterChange = useCallback(
    (event: MultiSelectFilterChangeEvent) => {
      // if custom filter fields are set create filters for them
      if (filterFields && filterFields.length > 0) {
        const filters = filterFields.map((fieldName) => ({
          ...event.filter,
          field: fieldName,
        }));

        setMultiSelectData(
          data == null ? [] : filterBy(data.slice(), { logic: 'or', filters }),
        );
      } else {
        setMultiSelectData(
          data == null ? [] : filterBy(data.slice(), event.filter),
        );
      }
    },
    [data, filterFields],
  );

  const ItemComponentClosure = useCallback(
    ({ data: tagData, text: tagText }: TagData) => {
      return (
        <ItemComponent
          disabled={disabled}
          key={value == null ? undefined : value.indexOf(tagData[0])}
          onRemove={handleRemoveItem}
          size={size}
          tagData={tagData}
          tagText={tagText}
          textField={textField}
        />
      );
    },
    [ItemComponent, disabled, handleRemoveItem, size, textField, value],
  );

  useEffect(() => {
    setMultiSelectData(
      data == null || textField == null
        ? []
        : data.filter(
            (item) => item[dataItemKey] != null && item[textField] != null,
          ) || [],
    );
  }, [data, dataItemKey, textField]);

  return (
    <>
      {label && (
        <Label
          description={description}
          editorId={name}
          editorValid={valid}
          editorDisabled={disabled}
          required={required}
          optional={isLabeledAsOptional}
        >
          {label}
        </Label>
      )}
      <StyledMultiSelectBase
        ref={internalRef}
        {...rest}
        allowCustom={allowCustom}
        data={multiSelectData}
        dataItemKey={dataItemKey}
        disabled={disabled}
        filterable={filterable}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFilterChange={handleFilterChange}
        onFocus={onFocus}
        placeholder={placeholder}
        size={size}
        tabIndex={tabIndex}
        tagRender={ItemComponentClosure}
        textField={textField}
        valid={valid}
        validationMessage={validationMessage}
        value={value}
      />
      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && (
        <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>
      )}
    </>
  );
};

MultiSelect.displayName = 'MultiSelect';

type StyledElementProps = {
  theme: DefaultTheme;
  disabled?: boolean;
  valid?: boolean;
  size?: KendoMultiSelectProps['size'];
};

const resolvePalette = ({ theme, disabled, valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      background: theme.colors.backgroundDisabled,
    };
  }

  if (!valid) {
    return {
      border: theme.colors.error,
      borderActive: theme.colors.error,
      background: theme.colors.palette.white,
    };
  }

  return {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    background: theme.colors.palette.white,
  };
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({ theme, valid }: StyledElementProps) => {
  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.SMALL:
      return theme.sizes.medium;
    default:
      throw new Error(`Could not resolve height for size "${size}".`);
  }
};

const resolveFontSize = ({ size, theme }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.footnote;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.footnote;
  }
};

const resolveLineHeight = ({ size, theme }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.footnote;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.footnote;
  }
};

const resolveItemMinHeight = ({ size, theme }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.xSmall;
    case ComponentSizes.MEDIUM:
      return theme.sizes.small;
    case ComponentSizes.LARGE:
      return theme.sizes.medium;
    default:
      return theme.sizes.small;
  }
};

const resolveClearIconHeight = ({ size, theme }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const StyledMultiSelectBase = styled(KendoMultiSelect)`
  min-height: ${resolveHeight};

  && .k-multiselect-wrap {
    background-color: ${resolveBackgroundColor};
    border-color: ${resolveBorderColor};
    border-width: ${({ theme }) => theme.borderWidths.base};
    border-radius: ${({ theme }) => theme.radii.base};
    padding-top: ${({ theme }) => theme.space.paddingVerticalSmall};
    padding-bottom: ${({ theme }) => theme.space.paddingVerticalSmall};

    &::before {
      height: 0;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &.k-focus {
    & .k-multiselect-wrap {
      background-color: ${resolveBackgroundColor};
      border-color: ${resolveActiveBorderColor};
    }
  }

  &:focus,
  &:active,
  &.k-focus {
    & .k-multiselect-wrap {
      box-shadow: ${resolveActiveBoxShadow};
    }
  }

  &.k-disabled {
    opacity: 1;
    filter: none;
  }

  & .k-multiselect-wrap .k-button {
    padding: 0;
    background: none;
    border: none;
    margin-left: ${({ theme }) => theme.space.multiSelectItemMarginLeft};
    margin-top: ${({ theme }) => theme.space.multiSelectItemMarginVertical};
    margin-bottom: ${({ theme }) => theme.space.multiSelectItemMarginVertical};
    min-height: ${resolveItemMinHeight};
  }

  & .k-multiselect-wrap .k-searchbar {
    font-size: ${resolveFontSize};
    line-height: ${resolveLineHeight};
    margin-top: 4px;

    & input {
      height: fit-content;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & .k-multiselect-wrap .k-clear-value {
    height: ${resolveClearIconHeight};
  }
`;
