import { FunctionComponent } from 'react';

import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { Account } from 'features/account';
import { Auth, AuthorizationProvider, RouteGuard, UserRoles } from 'features/auth';
import { Destination } from 'features/destination';
import { DestinationRoute } from 'features/destination-route';
import { Exam } from 'features/exam';
import { Form } from 'features/form';
import { Group } from 'features/group';
import { HL7Send } from 'features/hl7send';
import { Home } from 'features/home';
import { Integration } from 'features/integration';
import { Location } from 'features/location';
import { LocationFinalReportTemplate } from 'features/location-finalreport-template';
import { LocationType } from 'features/location-type';
import { PACS } from 'features/pacs';
import { Patient } from 'features/patient';
import { Physician } from 'features/physician';
import { PhysicianDashboard } from 'features/physician-dashboard';
import { SendQueue } from 'features/send-queue';
import { Service } from 'features/service';
import { Settings } from 'features/settings';
import { Share } from 'features/share';
import { Source } from 'features/source';
import { Template } from 'features/template';
import { Unit } from 'features/unit';
import { UploadExams, UploadExamsShare } from 'features/upload-exams';
import { UploadTracker } from 'features/upload-tracker';
import { User } from 'features/user';
import { WorklistViewUser } from 'features/worklistviewuser';

import { SHARE_BASE_ROUTE_ID } from '../constants';
import { MainRoutes } from '../routes';
import { App } from './App';
import { NavigationBlockerProvider } from './NavigationBlockerProvider';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <NavigationBlockerProvider>
          <AuthorizationProvider>
            <App />
          </AuthorizationProvider>
        </NavigationBlockerProvider>
      }
    >
      <Route path={MainRoutes.AUTH} element={<Auth />} />
      <Route path={MainRoutes.EXAM} element={<Exam />} />
      <Route path={MainRoutes.LOCATION} element={<Location />} />
      <Route path={MainRoutes.LOCATION_TYPE} element={<LocationType />} />
      <Route path={MainRoutes.PHYSICIAN} element={<Physician />} />
      <Route path={MainRoutes.PHYSICIAN_DASHBOARD} element={<PhysicianDashboard />} />
      <Route path={MainRoutes.UNIT} element={<Unit />} />
      <Route path={MainRoutes.GROUP} element={<Group />} />
      <Route path={MainRoutes.UPLOAD_TRACKER} element={<UploadTracker />} />
      <Route path={MainRoutes.UPLOAD_TOOL} element={<UploadExams />} />
      <Route path={MainRoutes.SHARE_EXAM} element={<UploadExamsShare />} />
      <Route path={MainRoutes.USER} element={<User />} />
      <Route path={MainRoutes.ACCOUNT} element={<Account />} />
      <Route path={MainRoutes.PATIENT} element={<Patient />} />
      <Route path={MainRoutes.PACS} element={<PACS />} />
      <Route path={MainRoutes.DESTINATION} element={<Destination />} />
      <Route path={MainRoutes.SOURCE} element={<Source />} />
      <Route path={MainRoutes.SERVICE} element={<Service />} />
      <Route path={MainRoutes.HL7SEND} element={<HL7Send />} />
      <Route path={MainRoutes.INTEGRATION} element={<Integration />} />
      <Route path={MainRoutes.FORM} element={<Form />} />
      <Route path={MainRoutes.TEMPLATE} element={<Template />} />
      <Route path={MainRoutes.SETTINGS} element={<Settings />} />
      <Route path={MainRoutes.WORKLIST_VIEW_USER} element={<WorklistViewUser />} />
      <Route path={MainRoutes.SHARE} id={SHARE_BASE_ROUTE_ID} element={<Share />} />
      <Route path={MainRoutes.DESTINATION_ROUTE} element={<DestinationRoute />} />
      <Route path={MainRoutes.LOCATIONFINALREPORT_TEMPLATE} element={<LocationFinalReportTemplate />} />
      <Route
        path={MainRoutes.SEND_QUEUE}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN]}>
            <SendQueue />
          </RouteGuard>
        }
      />
      <Route
        path={MainRoutes.HOME}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN]}>
            <Home />
          </RouteGuard>
        }
      />
      <Route element={<Navigate to={MainRoutes.HOME} replace />} />
    </Route>,
  ),
);

export const AppRouter: FunctionComponent = () => <RouterProvider router={router} />;

AppRouter.displayName = 'AppRouter';
