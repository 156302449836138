import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { FieldCategoryStatus } from '../constants';
import { FieldCategoryActions } from './actions';

export const fieldCategoryAdapter = createEntityAdapter({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...fieldCategoryAdapter.getInitialState(),
  total: 0,
  status: FieldCategoryStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(FieldCategoryActions.getById.fulfilled, (draft, action) => {
    fieldCategoryAdapter.upsertOne(draft, action.payload);
    draft.status = FieldCategoryStatus.FETCHED;
  });
  builder.addCase(FieldCategoryActions.getAll.fulfilled, (draft, action) => {
    fieldCategoryAdapter.setMany(draft, action.payload.fieldCategories);
    draft.status = FieldCategoryStatus.FETCHED;
  });
});
