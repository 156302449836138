import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { ResultSetStatus } from '../constants';
import { ResultSetActions } from './actions';

export const resultSetAdapter = createEntityAdapter();

export const reducer = createReducer(
  resultSetAdapter.getInitialState(),
  (builder) => {
    builder.addCase(ResultSetActions.getAll.fulfilled, (draft, { payload }) => {
      resultSetAdapter.removeAll(draft);

      if (payload.entities['result-sets']) {
        resultSetAdapter.addMany(draft, payload.entities['result-sets']);
      }

      draft.total = payload.total;
      draft.ids = payload.result;
      draft.status = ResultSetStatus.FETCHED;
    });
  },
);
