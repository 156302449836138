import { FunctionComponent } from 'react';

import { ProgressBar as KendoProgressBar, ProgressBarProps as KendoProgressBarProps } from '@progress/kendo-react-progressbars';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { ProgressBarLabel } from './ProgressBarLabel';
import { ProgressBarProps } from './ProgressBarProps';
import { ProgressBarType } from './constants';

export const ProgressBar: FunctionComponent<ProgressBarProps> = (props) => {
  const { size, subType, labelResolver, ...progressProps } = props;
  const { value } = props;

  return (
    <StyledDivContainer>
      <StyledProgressBarBase
        labelVisible={false}
        type="linear"
        value={0}
        {...progressProps}
        $subType={subType ?? ProgressBarType.DEFAULT}
        $size={size ?? ComponentSizes.MEDIUM}
      />
      <ProgressBarLabel size={size ?? ComponentSizes.MEDIUM} subType={subType ?? ProgressBarType.DEFAULT} value={value ?? 0} labelResolver={labelResolver} />
    </StyledDivContainer>
  );
};

ProgressBar.displayName = 'ProgressBar';

function resolvePalette(theme: DefaultTheme, subType: ProgressBarType) {
  switch (subType) {
    case ProgressBarType.DEFAULT: {
      return {
        background: theme.colors.palette.grayscale[2],
        fill: theme.colors.secondary,
      };
    }
    case ProgressBarType.SUCCESS: {
      return {
        background: theme.colors.palette.grayscale[2],
        fill: theme.colors.success,
      };
    }
    case ProgressBarType.ERROR: {
      return {
        background: theme.colors.palette.grayscale[2],
        fill: theme.colors.error,
      };
    }
    default:
      return {
        background: theme.colors.palette.grayscale[2],
        fill: theme.colors.secondary,
      };
  }
}

function resolveBackgroundColor(theme: DefaultTheme, subType: ProgressBarType) {
  const { background } = resolvePalette(theme, subType);

  return background;
}

function resolveFillColor(theme: DefaultTheme, subType: ProgressBarType) {
  const { fill } = resolvePalette(theme, subType);

  return fill;
}

function resolveHeight(theme: DefaultTheme, size?: ComponentSizes) {
  if (size === ComponentSizes.SMALL || size === ComponentSizes.MEDIUM) {
    return theme.sizes.progressBarHeightDefault;
  }

  if (size === ComponentSizes.LARGE) {
    return theme.sizes.progressBarHeightLarge;
  }

  return theme.sizes.progressBarHeightDefault;
}

const StyledDivContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledProgressBarBase = styled(KendoProgressBar)<KendoProgressBarProps & { $subType: ProgressBarType; $size: ComponentSizes }>`
  background-color: ${({ theme, $subType }) => resolveBackgroundColor(theme, $subType)};
  border-radius: ${({ theme }) => theme.radii.circle};
  height: ${({ theme, $size }) => resolveHeight(theme, $size)};

  .k-selected {
    background-color: ${({ theme, $subType }) => resolveFillColor(theme, $subType)};
  }
`;
