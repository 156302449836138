import { setAccessTokenReduxShim, setShareTokenReduxShim } from 'core/api';

import { AuthenticationManagerHandle } from '../types/AuthenticationManagerHandle';
import { IAuthenticationScheme } from '../types/IAuthenticationScheme';

export class AnonymousAuthenticationScheme implements IAuthenticationScheme {
  constructor(private manager: AuthenticationManagerHandle) {
    this.initialize = this.initialize.bind(this);
    this.setActiveScheme = this.setActiveScheme.bind(this);
  }

  public async initialize() {
    return true;
  }

  public setActiveScheme() {
    this.manager.apiClient.setAccessTokenFunction(null);
    setAccessTokenReduxShim(null);
    this.manager.apiClient.setShareTokenFunction(null);
    setShareTokenReduxShim(async () => null);

    return true;
  }
}
