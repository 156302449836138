import { FunctionComponent, useCallback } from 'react';

import styled from 'styled-components';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  combineValidators,
  createStyledFormElement,
  maxLength,
  required,
} from 'core/forms';
import {
  Button,
  Dropdown,
  DropdownWithValuesField,
  Input,
  Label,
  MultiSelect,
  Switch,
} from 'core/ui';
import { findOrThrow } from 'core/utils';

import { useCurrentUser } from 'features/auth';
import { BrandingSelectors } from 'features/branding';
import { GroupSelectors } from 'features/group';
import { useAppSelector } from 'features/main/hooks';

import { PreferMultiMonitorOptions } from '../constants';
import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement(
  'min-content 400px min-content',
);

const validators: ValidatorBag = {
  firstName: combineValidators([required, maxLength(50)]),
  lastName: combineValidators([required, maxLength(50)]),
};

const GeneralTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof GeneralTabFormValues) => any;
  valueSetter: (
    name: keyof GeneralTabFormValues,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: { value: any },
  ) => void;
  onChange: (values: GeneralTabFormValues) => void;
}> = ({ valueGetter, valueSetter, onChange }) => {
  const brandings = useAppSelector(BrandingSelectors.getAll);
  const groups = useAppSelector(GroupSelectors.getAll);
  const { currentUser } = useCurrentUser(true);
  const meIsAdministrator = currentUser.role === 'Administrator';

  const handleFormChange = useCallback(() => {
    onChange({
      firstName: valueGetter('firstName'),
      lastName: valueGetter('lastName'),
      branding: valueGetter('branding'),
      groups: valueGetter('groups'),
      isApproved: valueGetter('isApproved'),
      active: valueGetter('active'),
      verifyAccount: valueGetter('verifyAccount'),
      isRegistered: valueGetter('isRegistered'),
      isPricingAdmin: valueGetter('isPricingAdmin'),
      isSystemAdmin: valueGetter('isSystemAdmin'),
      setPassword: valueGetter('setPassword'),
      preferMultiMonitor: valueGetter('preferMultiMonitor'),
    });
  }, [onChange, valueGetter]);

  const handleGeneratePasswordClick = useCallback(() => {
    valueSetter('setPassword', {
      value: Math.random().toString(36).slice(2, 12),
    });
    handleFormChange();
  }, [valueSetter, handleFormChange]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="firstName">First Name</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            name="firstName"
            editorId="firstName"
            component={Input}
            validator={validators.firstName}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="lastName">Last Name</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            name="lastName"
            editorId="lastName"
            component={Input}
            validator={validators.lastName}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="branding">Branding</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Dropdown}
            data={brandings}
            editorId="branding"
            name="branding"
            textField="brandName"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="groups">Group</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MultiSelect}
            data={groups}
            editorId="groups"
            name="groups"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="isApproved">Approved</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="isApproved"
            editorId="isApproved"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="active">Active</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="active"
            editorId="active"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="verifyAccount">Is Verified</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="verifyAccount"
            editorId="verifyAccount"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="isRegistered">Is Registered</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="isRegistered"
            editorId="isRegistered"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="isPricingAdmin">Pricing Administrator</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="isPricingAdmin"
            editorId="isPricingAdmin"
            disabled={!meIsAdministrator}
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="isSystemAdmin">System Administrator</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="isSystemAdmin"
            editorId="isSystemAdmin"
            disabled={!meIsAdministrator}
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="preferMultiMonitor">Prefer Multi-Monitor</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="preferMultiMonitor"
            editorId="preferMultiMonitor"
            component={DropdownWithValuesField}
            data={PreferMultiMonitorOptions}
            valueField="value"
            textField="name"
            dataItemKey="value"
            isForPrimitiveValues
            defaultItem={findOrThrow(
              PreferMultiMonitorOptions,
              (item) => item.value === null,
            )}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="setPassword">Temporary Password</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="setPassword"
            editorId="setPassword"
            component={Input}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>
      <GridColumn columnStart="3">
        <StyledButtonContainer>
          <Button type="button" onClick={handleGeneratePasswordClick}>
            Generate Password
          </Button>
        </StyledButtonContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

GeneralTabContentInner.displayName = 'GeneralTabContentInner';

export const GeneralTabContent: FunctionComponent<GeneralTabContentProps> = ({
  initialValues,
  onChange,
  onSubmit,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter, onChange: valueSetter }) => (
        <GeneralTabContentInner
          valueGetter={valueGetter}
          valueSetter={valueSetter}
          onChange={onChange}
        />
      )}
    />
  );
};

GeneralTabContent.displayName = 'GeneralTabContent';

const StyledButtonContainer = styled.div`
  margin-left: 8px;
`;
