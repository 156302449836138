import { FocusEvent, memo, useMemo } from 'react';

import { Validate, useController } from 'react-hook-form';

import { RhfValidators } from 'core/forms';
import { useEvent } from 'core/hooks';

import { DropdownNg } from './DropdownNg';
import { DropdownNgChangeEvent } from './DropdownNgChangeEvent';
import { DropdownNgFieldProps } from './DropdownNgFieldProps';

export const DropdownNgField = memo<DropdownNgFieldProps>(({ name, required = false, validator, onChange, onBlur, ...rest }) => {
  const validatorInternal = useMemo(() => {
    const newValidators: Validate<Record<string, unknown>, unknown>[] = [];

    if (required) newValidators.push(RhfValidators.required);
    if (validator) newValidators.push(validator);

    return newValidators.length === 0 ? undefined : newValidators.length === 1 ? newValidators[0] : RhfValidators.combine(newValidators);
  }, [required, validator]);

  const {
    field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
    formState,
    fieldState: { isTouched, invalid, error },
  } = useController({
    name,
    rules: {
      validate: validatorInternal, // Intentionally using a custom validator for "required" because the browser native version will allow string values with all whitespace.
    },
  });

  const handleChange = useEvent((event: DropdownNgChangeEvent) => {
    rhfOnChange(event.value);
    onChange?.(event);
  });

  const handleBlur = useEvent((event: FocusEvent<HTMLElement>) => {
    rhfOnBlur();
    onBlur?.(event);
  });

  return (
    <DropdownNg
      {...fieldRest}
      {...rest}
      valid={!invalid}
      visited={formState.isSubmitted || isTouched}
      required={required}
      validationMessage={error?.message}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});

DropdownNgField.displayName = 'DropdownNgField';
