import { FunctionComponent, useCallback } from 'react';

import styled from 'styled-components';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  combineValidators,
  createStyledFormElement,
  maxLength,
  required,
} from 'core/forms';
import { Button, Input, Label, Switch, TextArea, Upload } from 'core/ui';

import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  firstName: combineValidators([required, maxLength(255)]),
  lastName: combineValidators([required, maxLength(255)]),
  title: maxLength(50),
  specialty: maxLength(50),
  notes: maxLength(500),
};

const GeneralTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof GeneralTabFormValues) => any;
  onChange: (values: GeneralTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      signature: valueGetter('signature'),
      firstName: valueGetter('firstName'),
      lastName: valueGetter('lastName'),
      title: valueGetter('title'),
      specialty: valueGetter('specialty'),
      notes: valueGetter('notes'),
      active: valueGetter('active'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="signature">Signature</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <StyledFieldContainer $hideLabel>
          <Field
            name="signature"
            editorId="signature"
            component={Upload}
            multiple={false}
            onChange={handleFormChange}
          />
        </StyledFieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="firstName" required>
          First Name
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            name="firstName"
            editorId="firstName"
            required
            validator={validators.firstName}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="lastName" required>
          Last Name
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={Input}
            editorId="lastName"
            name="lastName"
            required
            validator={validators.lastName}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="title">Title</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="title"
            editorId="title"
            component={Input}
            validator={validators.title}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="specialty">Specialty</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="specialty"
            editorId="specialty"
            component={Input}
            validator={validators.specialty}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="notes">Notes</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="notes"
            editorId="notes"
            maxLength={100}
            component={TextArea}
            validator={validators.notes}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="active">Active</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="active"
            editorId="active"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

GeneralTabContentInner.displayName = 'GeneralTabContentInner';

export const GeneralTabContent: FunctionComponent<GeneralTabContentProps> = ({
  initialValues,
  onChange,
  onSubmit,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <GeneralTabContentInner valueGetter={valueGetter} onChange={onChange} />
      )}
    />
  );
};

GeneralTabContent.displayName = 'GeneralTabContent';

const StyledFieldContainer = styled(FieldContainer)`
  .k-form-field {
    margin-top: 0;
  }
`;
