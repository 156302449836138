export const NAVIGATION_LINK_IDS = {
  HOME: 1,
  EXAMS: 2,
  PATIENTS: 3,
  UPLOAD_EXAM: 4,
  ADMIN: 5,
  ACCOUNTS: 17,
  LOCATIONS: 6,
  LOCATION_TYPES: 7,
  DESTINATIONS: 14,
  DEVICES: 8,
  PHYSICIANS: 9,
  TEMPLATES: 15,
  USERS: 10,
  GROUPS: 11,
  UPLOAD_TRACKER: 12,
  SEND_QUEUE: 13,
  WORKLIST_VIEW_USER: 16,
  BULK_UPLOAD: 18,
  PORTAL_SETTINGS: 19,
  SOURCES: 20,
  PACS: 21,
  HL7SEND: 22,
  DESTINATION_ROUTE: 23,
  LOCATIONFINALREPORT_TEMPLATE: 24,
  FORM: 25,
  SERVICE: 26,
};

export const BASE_HOME_ITEM = {
  text: 'Home',
  icon: 'fa-house',
  defaultExternalRoute: import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL,
};

export const NavigationItems = {
  SEPARATOR: {
    separator: true,
  },
  HOME: {
    text: BASE_HOME_ITEM.text,
    icon: BASE_HOME_ITEM.icon,
    id: NAVIGATION_LINK_IDS.HOME,
    externalRoute: BASE_HOME_ITEM.defaultExternalRoute,
  },
  PATIENT: {
    text: 'Patients',
    icon: 'fa-users',
    id: NAVIGATION_LINK_IDS.PATIENTS,
    route: '/patient-2/',
    externalRoute: `${import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL}/Patient`,
  },
  EXAMS: {
    text: 'Exams',
    icon: 'fa-file-lines',
    id: NAVIGATION_LINK_IDS.EXAMS,
    route: '/exam/',
    externalRoute: `${import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL}/Exams/Index2`,
  },
  UPLOAD_EXAM: {
    text: 'Upload Exam',
    icon: 'fa-upload',
    id: NAVIGATION_LINK_IDS.UPLOAD_EXAM,
    route: '/upload/',
    externalRoute: `${import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL}/DICOM/UploadDICOM`,
  },
  ADMIN: {
    text: 'Admin',
    icon: 'fa-toolbox',
    id: NAVIGATION_LINK_IDS.ADMIN,
    dataExpanded: false,
    allowedRoles: ['Administrator'],
  },
  ACCOUNTS: {
    text: 'Accounts',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.ACCOUNTS,
    parentId: 5,
    route: '/account/',
  },
  LOCATIONS: {
    text: 'Locations',
    icon: 'fa-location-pin',
    id: NAVIGATION_LINK_IDS.LOCATIONS,
    parentId: 5,
    route: '/location/',
  },
  LOCATION_TYPES: {
    text: 'Location Types',
    icon: 'fa-location-dot',
    id: NAVIGATION_LINK_IDS.LOCATION_TYPES,
    parentId: 5,
    route: '/location-type/',
  },
  DESTINATIONS: {
    text: 'Destinations',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.DESTINATIONS,
    parentId: 5,
    route: '/destination/',
  },
  SOURCES: {
    text: 'Sources',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.SOURCES,
    parentId: 5,
    route: '/source/',
  },
  DESTINATION_ROUTE: {
    text: 'Destination Routes',
    icon: 'fa-arrow-progress',
    id: NAVIGATION_LINK_IDS.DESTINATION_ROUTE,
    parentId: 5,
    route: '/destination-route/',
  },
  FORM: {
    text: 'Forms',
    icon: 'fa-file-lines',
    id: NAVIGATION_LINK_IDS.FORM,
    parentId: 5,
    route: '/form/',
  },
  LOCATIONFINALREPORT_TEMPLATE: {
    text: 'Location Final Report Template',
    icon: 'fa-file-chart-column',
    id: NAVIGATION_LINK_IDS.LOCATIONFINALREPORT_TEMPLATE,
    parentId: 5,
    route: '/location-finalreport-template/',
  },
  PACS: {
    text: 'PACS',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.PACS,
    parentId: 5,
    route: '/pacs/',
  },
  HL7SEND: {
    text: 'HL7Send',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.HL7SEND,
    parentId: 5,
    route: '/hl7send/',
  },
  SERVICE: {
    text: 'Services',
    icon: 'fa-solid fa-briefcase-medical',
    id: NAVIGATION_LINK_IDS.SERVICE,
    parentId: 5,
    route: '/service/',
  },
  DEVICES: {
    text: 'Devices',
    icon: 'fa-print',
    id: NAVIGATION_LINK_IDS.DEVICES,
    parentId: 5,
    route: '/unit/',
  },
  PHYSICIANS: {
    text: 'Physicians',
    icon: 'fa-user-doctor',
    id: NAVIGATION_LINK_IDS.PHYSICIANS,
    parentId: 5,
    route: '/physician/',
  },
  TEMPLATES: {
    text: 'Templates',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.TEMPLATES,
    parentId: 5,
    route: '/template/',
  },
  USERS: {
    text: 'Users',
    icon: 'fa-user',
    id: NAVIGATION_LINK_IDS.USERS,
    parentId: 5,
    route: '/user/',
  },
  GROUPS: {
    text: 'Groups',
    icon: 'fa-user-group',
    id: NAVIGATION_LINK_IDS.GROUPS,
    parentId: 5,
    route: '/group/',
  },
  UPLOAD_TRACKER: {
    text: 'Upload Tracker',
    icon: 'fa-cloud-arrow-up',
    id: NAVIGATION_LINK_IDS.UPLOAD_TRACKER,
    parentId: 5,
    route: '/upload-tracker/',
  },
  SEND_QUEUE: {
    text: 'Send Queue',
    icon: 'fa-clock',
    id: NAVIGATION_LINK_IDS.SEND_QUEUE,
    parentId: 5,
    route: '/send-queue/',
  },
  PORTAL_SETTINGS: {
    text: 'Portal Settings',
    icon: 'fa-toolbox',
    id: NAVIGATION_LINK_IDS.PORTAL_SETTINGS,
    parentId: 5,
    route: '/settings/',
  },
  WORKLIST_VIEW_USER: {
    text: 'Worklist Access',
    icon: 'fa-square',
    id: NAVIGATION_LINK_IDS.WORKLIST_VIEW_USER,
    parentId: 5,
    route: '/worklist-view-user/',
  },
  BULK_UPLOAD: {
    icon: 'fa-folder-arrow-up',
    id: NAVIGATION_LINK_IDS.BULK_UPLOAD,
    externalRoute: `${import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL}/Exams/BulkUpload`,
    text: 'Bulk Upload',
  },
};

export const HideNavbarPaths = [new RegExp('/exam/add', 'i'), new RegExp('\\/exam\\/+?\\d+\\/edit', 'i'), new RegExp('\\/exam\\/+?\\d+\\/read$', 'i')];

export const SHARE_BASE_ROUTE_ID = 'share_base_route';
