import { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';

import { map as _map } from 'lodash';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LocationFinalReportTemplateModel, LocationModel, ServiceModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useAsyncCallback, useEvent, useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, DropDownListChangeEvent, DropdownField, DropdownWithValuesField, InputField, Label, Page, PageHeader, SwitchField } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { apiClient } from 'features/api';
import { Organs } from 'features/exam';

import { LocationFinalReportTemplateEditService } from '../services/LocationFinalReportTemplateEditService';
import { EditFormValues } from '../types/EditFormValues';

const NullLocationOption = { id: null, name: '' };

const StyledForm = createStyledRhfForm('min-content 400px');

export const LocationFinalReportTemplateForm: FunctionComponent = memo(() => {
  const locationFinalReportTemplateId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [locationFinalReportTemplate, setLocationFinalReportTemplate] = useState<LocationFinalReportTemplateModel | null>(null);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [services, setServices] = useState<ServiceModel[]>([]);

  const [isOrganVisible, setIsOrganVisible] = useState<boolean>(false);

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...LocationFinalReportTemplateEditService.EditFormDefaults },
  });
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const [initializeData] = useAsyncCallback(async () => {
    const [newLocationFinalReportTemplate, newLocations, newServices] = await Promise.all([
      locationFinalReportTemplateId == null
        ? new Promise<LocationFinalReportTemplateModel>((resolve) => {
            resolve(LocationFinalReportTemplateEditService.createDefaultSource());
          })
        : apiClient.locationFinalReportTemplateClient.getLocationFinalReportTemplateById(locationFinalReportTemplateId),
      apiClient.locationClient.getAllLocations(),
      apiClient.servicesClient.getAllServices(),
    ]);

    setLocationFinalReportTemplate(newLocationFinalReportTemplate);
    setLocations(newLocations);
    setServices(newServices);

    initializeForm(newLocationFinalReportTemplate, newServices);
    reset(LocationFinalReportTemplateEditService.copyModelToForm(newLocationFinalReportTemplate, newLocations, newServices), {
      keepValues: false,
      keepDefaultValues: false,
    });
  });

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  function initializeForm(model: LocationFinalReportTemplateModel, paramServices: ServiceModel[]) {
    if (model) {
      const service =
        model.serviceId != null && model.serviceId != 0
          ? findOrThrow(paramServices, (t) => t.id === model.serviceId, `Could not find service with id: ${model.serviceId}.`)
          : null;
      if (service !== null) {
        if (service.description == 'PATHOLOGY') setIsOrganVisible(true);
        else setIsOrganVisible(false);
      }
    }
  }

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (locationFinalReportTemplate == null) {
        throw new Error('Cannot proceed because location final report template is null or undefined.');
      }

      values.organ = locationFinalReportTemplate.organ;
      const newLocationFinalReportTemplate: LocationFinalReportTemplateModel = LocationFinalReportTemplateEditService.copyFormToModel(
        locationFinalReportTemplate.id,
        values,
      );

      if (typeof locationFinalReportTemplateId === 'number') {
        await apiClient.locationFinalReportTemplateClient.updateLocationFinalReportTemplate(newLocationFinalReportTemplate);
      } else {
        await apiClient.locationFinalReportTemplateClient.createLocationFinalReportTemplate(newLocationFinalReportTemplate);
      }

      reset(LocationFinalReportTemplateEditService.copyModelToForm(newLocationFinalReportTemplate, locations, services), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(
        locationFinalReportTemplateId == null ? 'Location Final Report Template created.' : 'Location Final Report Template saved.',
      );
      navigate('/location-finalreport-template');
    },
    [locationFinalReportTemplate, locationFinalReportTemplateId, reset, locations, services, navigate],
  );

  const handleOrganChange = useEvent((event: DropDownListChangeEvent) => {
    // set organ property in changed value object from the value array
    if (locationFinalReportTemplate !== null) {
      setLocationFinalReportTemplate({
        ...locationFinalReportTemplate,
        organ: event.value as number,
      });
    }
  });

  const handleServiceChange = useEvent((event: DropDownListChangeEvent) => {
    const serviceItem = event.value;
    if (serviceItem !== null) {
      if (serviceItem.description == 'PATHOLOGY') setIsOrganVisible(true);
      else setIsOrganVisible(false);
    }
  });

  return (
    <Page>
      <PageHeader title={`${locationFinalReportTemplateId ? 'Edit' : 'New'} Location Final Report Template`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Template Path</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="templatePath" required />
            </FieldContainer>
          </GridColumn>
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Version</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="version" />
            </FieldContainer>
          </GridColumn>
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Location</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="location" data={locations} defaultItem={NullLocationOption} />
            </FieldContainer>
          </GridColumn>
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Service</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField
                data={services}
                label="Study Type"
                name="service"
                dataItemKey="id"
                valueField="id"
                textField="description"
                onChange={handleServiceChange}
              />
            </FieldContainer>
          </GridColumn>
          {isOrganVisible && (
            <>
              <GridColumn columnStart="1" isLabelColumn>
                <Label required>Organ</Label>
              </GridColumn>
              <GridColumn columnStart="2" columnEnd="span 1">
                <FieldContainer $hideLabel>
                  <DropdownWithValuesField
                    data={_map(Organs)}
                    name="organ"
                    dataItemKey="value"
                    valueField="value"
                    textField="name"
                    onChange={handleOrganChange}
                    isForPrimitiveValues
                    required
                    value={locationFinalReportTemplate?.organ}
                  />
                </FieldContainer>
              </GridColumn>
            </>
          )}
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <div>
              <Button disabled={isSubmitting} type="submit">
                Save
              </Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
});

LocationFinalReportTemplateForm.displayName = 'LocationFinalReportTemplateForm';
