import { memoize } from '../../utils';
import * as baseValidators from './form-validators-service';

const combineValidators = memoize(baseValidators.combineValidators);

const required = memoize(baseValidators.required);

const richEditorRequired = memoize(baseValidators.richEditorRequired);

const email = memoize(baseValidators.email);

const emailList = memoize(baseValidators.emailList);

const unos = memoize(baseValidators.unos);

const phone = memoize(baseValidators.phone);

const maxLength = memoize(baseValidators.maxLength);

const minLength = memoize(baseValidators.minLength);

const greaterThanOrEqualTo = memoize(baseValidators.greaterThanOrEqualTo);

const lowerValueThanField = memoize(baseValidators.lowerValueThanField);

const zip = memoize(baseValidators.zip);

const datetime = memoize(baseValidators.datetime);

export const FormValidatorsService = {
  combineValidators,
  datetime,
  email,
  emailList,
  unos,
  greaterThanOrEqualTo,
  lowerValueThanField,
  maxLength,
  minLength,
  phone,
  required,
  richEditorRequired,
  zip,
};
