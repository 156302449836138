import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import { FormStates } from 'core/forms';
import { Accordion } from 'core/ui';

import PatientIcon from '../assets/patient.svg?react';
import { PatientForm } from './PatientForm';
import { PatientFormHeader } from './PatientFormHeader';

const PatientFormAccordion = ({
  className,
  eventKey,
  formState,
  handleHeaderClick,
  isCollapsed,
  patient,
  searchText,
  setCurrentPatient,
  setFormState,
  setSearchText,
}) => {
  return (
    <Accordion.Item className={className} eventKey={eventKey}>
      <Accordion.Header Icon={PatientIcon} title="Patient" onClick={handleHeaderClick}>
        <PatientFormHeader isCollapsed={isCollapsed} onClick={(event) => event.stopPropagation()} patient={patient} patientFormState={formState} />
      </Accordion.Header>
      <Accordion.Body>
        <PatientForm
          formState={formState}
          patient={patient}
          searchText={searchText}
          setCurrentPatient={setCurrentPatient}
          setFormState={setFormState}
          setSearchText={setSearchText}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

PatientFormAccordion.propTypes = {
  className: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  handleHeaderClick: PropTypes.func,
  formState: PropTypes.oneOf(Object.values(FormStates)),
  setFormState: PropTypes.func,
  searchText: PropTypes.string.isRequired,
  setCurrentPatient: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

PatientFormAccordion.defaultProps = {
  className: '',
  formState: FormStates.DISABLED,
  handleHeaderClick: undefined,
  isCollapsed: false,
  patient: null,
  setFormState: () => {},
};

export { PatientFormAccordion };
