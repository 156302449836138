import { memo, useCallback } from 'react';

import {
  ChipFocusEvent,
  ChipListChangeEvent,
} from '@progress/kendo-react-buttons';
import { useController } from 'react-hook-form';

import { ChipList } from './ChipList';
import { ChipListFieldProps } from './ChipListFieldProps';

export const ChipListField = memo<ChipListFieldProps>(
  ({ name, validator, onChange, onBlur, ...rest }) => {
    const {
      field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
      formState,
      fieldState: { isTouched, invalid, error },
    } = useController({ name, rules: { validate: validator } });

    const handleChange = useCallback(
      (event: ChipListChangeEvent) => {
        rhfOnChange(event);
        onChange?.(event);
      },
      [onChange, rhfOnChange],
    );

    const handleBlur = useCallback(
      (event: ChipFocusEvent) => {
        rhfOnBlur();
        onBlur?.(event);
      },
      [onBlur, rhfOnBlur],
    );

    return (
      <ChipList
        {...fieldRest}
        {...rest}
        valid={!invalid}
        visited={formState.isSubmitted || isTouched}
        validationMessage={error?.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  },
);

ChipListField.displayName = 'ChipListField';
