import { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { DataResult, DataSourceRequestState } from '@progress/kendo-data-query';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import debounce from 'awesome-debounce-promise';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { addFiltersToDataState, getSearchFilter } from 'core/api/services/kendoMultiColumnFilter';
import { useEvent } from 'core/hooks';
import { Action, ActionListCell, Button, ButtonVariants, DataTable, Dropdown, HeaderCell, Page, PageHeader, Switch, Tooltip } from 'core/ui';

import { apiClient } from 'features/api';
import { useAppDispatch } from 'features/main/hooks';
import { useUserSettings } from 'features/settings';

import { LocationModel } from '../../../models';
import { SearchInput } from '../../exam/fragments/SearchInput';
import { LocationActions, LocationSelectors } from '../../location';
import { DEFAULT_FILTER_DATA_STATE, DEFAULT_LOCATION, DEFAULT_LOCATION_ID } from '../constants';
import { PatientGridService } from '../services';
import { MergePatientModal } from './MergePatientModal';

export const PatientHome: FunctionComponent = () => {
  const locations = useSelector(LocationSelectors.getAll);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reactPatientFormPage, legacyBaseUrl } = useUserSettings(true);

  const [dataState, setDataState] = useState<DataSourceRequestState>(DEFAULT_FILTER_DATA_STATE);
  const [donors, setDonors] = useState<DataResult>({} as DataResult);
  const [selectedDonors, setSelectedDonors] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [isMergeDonorVisible, setIsMergeDonorVisible] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const apiCall = useCallback((kendoState: DataSourceRequestState) => {
    return apiClient.patientClient.getAllForKendoGrid(kendoState, '');
  }, []);

  const debouncedApiCall = useEvent(debounce(apiCall, 275));

  const fetchDonors = useCallback(
    async (kendoState: DataSourceRequestState) => {
      try {
        setIsLoading(true);
        const response = await debouncedApiCall(kendoState);
        setDonors(response);
      } finally {
        setIsLoading(false);
      }
    },
    [debouncedApiCall],
  );

  useEffect(() => {
    dispatch(LocationActions.getAll(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (searchValue) {
      const resultingDataState = addFiltersToDataState(dataState, [getSearchFilter(searchValue, PatientGridService.getPatientColumns())]);
      // if global search, don't throttle
      fetchDonors(resultingDataState);
      // if not filtering, don't throttle
    } else {
      fetchDonors(dataState);
    }
  }, [dataState, searchValue, fetchDonors]);

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const handleEditClick = useCallback(
    (_event: unknown, patient: { id: number }) => {
      if (reactPatientFormPage) {
        navigate(`/patient-2/edit/${patient.id}`);
      } else {
        window.location.href = `${legacyBaseUrl}/patient/edit/${patient.id}`;
      }
    },
    [legacyBaseUrl, navigate, reactPatientFormPage],
  );

  const onSearch = async (searchText: string) => {
    setSearchValue(searchText);
  };

  const toggleMergeDonorModal = () => {
    setIsMergeDonorVisible(!isMergeDonorVisible);
  };

  const toggleActive = () => {
    const clonedDataState = { ...dataState };
    const newDataStateFilter = clonedDataState?.filter?.filters.filter((item) => !('field' in item) || item.field !== 'active') ?? [];

    newDataStateFilter.push({
      field: 'active',
      operator: 'eq',
      value: !isActive,
    });
    if (clonedDataState?.filter?.filters) {
      clonedDataState.filter.filters = newDataStateFilter;
      setDataState(clonedDataState);
      setIsActive(!isActive);
    }
  };

  const handleMerge = async () => {
    await fetchDonors(dataState);
    setDataState(DEFAULT_FILTER_DATA_STATE);
    setSearchValue('');
    setSelectedDonors({});
  };

  const handleLocationChange = (event: DropDownListChangeEvent) => {
    const locationVal: LocationModel = event.target.value;
    setLocation({
      value: { id: locationVal.id, name: locationVal.name },
    });
    const clonedDataState = { ...dataState };
    const newDataStateFilter = clonedDataState?.filter?.filters.filter((item) => !('field' in item) || item.field !== 'location_Id') ?? [];

    // if
    if (locationVal.id !== DEFAULT_LOCATION_ID) {
      newDataStateFilter.push({
        field: 'location_Id',
        operator: 'eq',
        value: locationVal.id,
      });
    }

    if (clonedDataState?.filter?.filters) {
      clonedDataState.filter.filters = newDataStateFilter;
      setDataState(clonedDataState);
    }
  };

  const calculateAge = (dob: string) => {
    console.log(dob);
    const date = new Date(dob);
    const diffMs = Date.now() - date.getTime();
    const ageDt = new Date(diffMs);
    return Math.abs(ageDt.getUTCFullYear() - 1970);
  };

  const getSelectedDonorsCount = () => {
    return Object.values(selectedDonors).filter((d) => d === true).length;
  };

  const formatSelectedDonorsForMerge = () => {
    return Object.entries(selectedDonors)
      .filter(([, value]) => value === true)
      .map(([key]) => Number(key));
  };

  const handleAddNewPatientClick = useEvent(() => {
    if (reactPatientFormPage) {
      navigate('/patient-2/add');
    } else {
      window.location.href = `${legacyBaseUrl}/patient/add`;
    }
  });

  const actionsBar = (
    <StyledActionsBar>
      <SearchInput value={searchValue} onChange={setSearchValue} placeholder="Search" />
      <StyledRightSideActions>
        <Tooltip text={getSelectedDonorsCount() !== 2 ? 'Select 2 donors to Merge' : ''}>
          <span>
            <Button onClick={toggleMergeDonorModal} disabled={getSelectedDonorsCount() !== 2} variant={ButtonVariants.SECONDARY}>
              Merge Donor
            </Button>
          </span>
        </Tooltip>
        <Button type="button" onClick={handleAddNewPatientClick}>
          Add New Donor
        </Button>
        <Switch label="Active" onChange={toggleActive} value={isActive} />
        <StyledLocationsDropdown
          data={locations}
          label="Location Name"
          textField="name"
          dataItemKey="id"
          defaultItem={DEFAULT_LOCATION.value}
          value={location.value}
          onChange={handleLocationChange}
          valid
        />
      </StyledRightSideActions>
    </StyledActionsBar>
  );

  const gridActions: Action[] = useMemo(
    () => [
      {
        key: 'edit-patient',
        title: 'Edit',
        icon: faUser,
        onClick: handleEditClick,
      },
    ],
    [handleEditClick],
  );

  return (
    <Page>
      <PageHeader title="Donors" />
      <StyledMainContainer>
        {actionsBar}
        <DataTable
          data={
            (donors?.data?.map((d) => ({
              ...d,
              age: calculateAge(d.dob),
            })) as unknown[]) || []
          }
          sortable
          filterable
          onDataStateChange={dataStateChange}
          selectable
          pageable={{ pageSizes: true }}
          total={donors?.total || 0}
          selectedState={selectedDonors}
          onSelectionChange={setSelectedDonors}
          isLoading={isLoading}
          actions={gridActions}
          {...dataState}
        >
          <GridColumn
            field="action"
            filterable={false}
            headerCell={HeaderCell}
            reorderable={false}
            sortable={false}
            title="Action"
            width="80px"
            cell={ActionListCell as ComponentType<GridCellProps>}
          />
          {PatientGridService.getPatientColumns().map((column) => {
            return (
              <GridColumn
                key={column.field}
                headerCell={(headerCellProps) => <HeaderCell {...headerCellProps} description={column.headerCellDescription} />}
                {...column}
                cell={(props) => <column.cell {...props} searchValue={searchValue} />}
                filter={column.columnFilter}
              />
            );
          })}
        </DataTable>
      </StyledMainContainer>
      {isMergeDonorVisible && (
        <MergePatientModal
          key={String(isMergeDonorVisible)}
          srcDonorId={formatSelectedDonorsForMerge()[1]}
          destDonorId={formatSelectedDonorsForMerge()[0]}
          toggleDialog={toggleMergeDonorModal}
          onMerge={handleMerge}
          showDonorGrid={false}
        />
      )}
    </Page>
  );
};

PatientHome.displayName = 'PatientHome';

const StyledMainContainer = styled.div`
  .k-master-row td {
    height: ${(props) => props.theme.space.spacing80};
    font-size: 0.875rem;
  }
  .k-grid-header th {
    padding: ${(props) => props.theme.space.spacing20};
  }
  .k-grid {
    @media screen and (max-width: 800px) {
      height: 80%;
    }
    padding-left: ${(props) => props.theme.space.spacing50};
  }
  .k-grid-container {
    height: 1200px;
  }
  overflow: auto;
  background-color: ${(props) => props.theme.colors.palette.white};
`;

const StyledActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.space.spacing50};
`;

const StyledRightSideActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${(props) => props.theme.space.spacing50};

  @media screen and (max-width: 585px) {
    gap: ${(props) => props.theme.space.spacing20};
  }
`;

const StyledLocationsDropdown = styled(Dropdown)`
  width: 200px;
`;
