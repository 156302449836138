import { memo } from 'react';

import { RouteGuard, UserRoles } from 'features/auth';

import { UploadExamsPage } from './UploadExamsPage';

const EXAM_SHARE_ROLES = [UserRoles.EXAM_SHARE];

export const UploadExamsShare = memo(() => {
  return (
    <RouteGuard allowedRoles={EXAM_SHARE_ROLES}>
      <UploadExamsPage />
    </RouteGuard>
  );
});

UploadExamsShare.displayName = 'UploadExamsShare';
