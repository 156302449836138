const COMPUMED_PORTAL_BASE_URL = `${
  import.meta.env.VITE_APP_COMPUMED_PORTAL_BASE_URL
}`;
const COMPUMED_API_BASE_URL = `${
  import.meta.env.VITE_APP_COMPUMED_API_BASE_URL
}`;
const COMPUMED_SSO_BASE_URL = `${
  import.meta.env.VITE_APP_COMPUMED_SSO_BASE_URL
}`;

const getCompumedPortalBaseRoute = () => COMPUMED_PORTAL_BASE_URL;
const getCompumedApiBaseRoute = () => COMPUMED_API_BASE_URL;
const getCompumedSsoBaseRoute = () => COMPUMED_SSO_BASE_URL;

export const ApiRouteService = {
  getCompumedPortalBaseRoute,
  getCompumedApiBaseRoute,
  getCompumedSsoBaseRoute,
};
