import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { TemplateRoutes } from '../routes';
import { TemplateForm } from './TemplateForm';
import { TemplateHome } from './TemplateHome';

export const Template: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={TemplateRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <TemplateHome />
          </RouteGuard>
        }
      />
      <Route
        path={TemplateRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={TemplateHome}>
            <TemplateForm />
          </RouteGuard>
        }
      />
      <Route
        path={TemplateRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={TemplateHome}>
            <TemplateForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

Template.displayName = 'Template';
