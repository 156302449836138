import { map as _map } from 'lodash';

import { LocationFinalReportTemplateModel, LocationModel, ServiceModel } from 'models';

import { findOrThrow } from 'core/utils';

import { Organs } from 'features/exam';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  active: false,
  location: null,
  service: null,
  organ: null,
  templatePath: '',
  version: '',
};

function copyModelToForm(model: LocationFinalReportTemplateModel, allLocations: LocationModel[], allServices: ServiceModel[]): EditFormValues {
  // find location
  const location =
    model.locationId != null ? findOrThrow(allLocations, (t) => t.id === model.locationId, `Could not find location with id: ${model.locationId}.`) : null;

  // find location
  const service =
    model.serviceId != null && model.serviceId != 0
      ? findOrThrow(allServices, (t) => t.id === model.serviceId, `Could not find service with id: ${model.serviceId}.`)
      : null;

  const organItem = Object.values(_map(Organs)).find((o) => o.value === model.organ);
  const organ = Number(organItem?.value);
  return {
    ...model,
    location,
    service,
    organ,
  };
}

function copyFormToModel(locationFinalReportTemplateId: number, form: EditFormValues): LocationFinalReportTemplateModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...rest } = form;
  return {
    ...rest,
    id: locationFinalReportTemplateId,
    locationId: form.location == null ? null : form.location.id,
    organ: form.organ ?? null,
    serviceId: form.service == null ? null : form.service.id,
  };
}

function createDefaultSource(): LocationFinalReportTemplateModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const LocationFinalReportTemplateEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
