import { FunctionComponent } from 'react';

import { Hint as KendoHint } from '@progress/kendo-react-labels';
import styled from 'styled-components';

import { HintProps } from './HintProps';

export const Hint: FunctionComponent<HintProps> = ({
  children,
  className,
  id,
  direction = 'start',
}) => (
  <StyledHintBase className={className} id={id} direction={direction}>
    {children}
  </StyledHintBase>
);

Hint.displayName = 'Hint';

const StyledHintBase = styled(KendoHint)`
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-top: 0;
`;
