import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { IntegrationRoutes } from '../routes';
import { IntegrationEdit } from './IntegrationEdit';
import { IntegrationHome } from './IntegrationHome';

export const Integration: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={IntegrationRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <IntegrationHome />
          </RouteGuard>
        }
      />
      <Route
        path={IntegrationRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <IntegrationEdit />
          </RouteGuard>
        }
      />
      <Route
        path={IntegrationRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <IntegrationEdit />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

Integration.displayName = 'Integration';
