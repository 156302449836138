import { memo, useMemo } from 'react';

import { IconDefinition, faCircle, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { DefaultTheme } from 'styled-components';

import { useEvent } from 'core/hooks';
import { Icon } from 'core/ui';

import { UploadGroupListItemProps } from '../types';

export const UploadGroupListItem = memo<UploadGroupListItemProps>(({ className, group, selected, onClick }) => {
  const handleClick = useEvent(() => {
    onClick(group.uploadGroupId);
  });

  let iconStatus: IconDefinition | null = null;
  let iconColor: 'error' | 'primary' = 'primary';
  let iconSize: '2xs' | '1x' = '1x';

  if (!group.patientFormState.isValid || !group.examFormState.isValid) {
    iconStatus = faCircleExclamation;
    iconColor = 'error';
    iconSize = '1x';
  } else if (group.patientFormState.isDirty || group.examFormState.isDirty || group.patient == null || group.exam == null) {
    iconStatus = faCircle;
    iconColor = 'primary';
    iconSize = '2xs';
  }

  const statusTooltip = useMemo(() => {
    if (iconStatus === faCircleExclamation) {
      return <Tooltip>Invalid information.</Tooltip>;
    } else if (iconStatus === faCircle) {
      return <Tooltip>Unsaved information.</Tooltip>;
    }

    return undefined;
  }, [iconStatus]);

  return (
    <StyledComponentButton type="button" className={cn(className, { selected })} onClick={handleClick} $color={iconColor}>
      <StyledItemCellDiv>{group.examForm.description}</StyledItemCellDiv>
      <StyledStatusCellDiv>
        {iconStatus != null && statusTooltip != null && (
          <OverlayTrigger overlay={statusTooltip}>
            <Icon icon={iconStatus} fixedWidth={false} block size={iconSize} />
          </OverlayTrigger>
        )}
      </StyledStatusCellDiv>
    </StyledComponentButton>
  );
});

UploadGroupListItem.displayName = 'UploadGroupListItem';

type StyledIconProps = {
  theme: DefaultTheme;
  $color: 'error' | 'primary';
};

function resolveColor({ theme, $color }: StyledIconProps) {
  if ($color === 'error') {
    return theme.colors.error;
  }

  return theme.colors.primary;
}

const StyledComponentButton = styled.button<{ $color: 'error' | 'primary' }>`
  // Remove global and browser default styles.
  border: none;
  padding: 0;
  background: inherit;
  font: inherit;
  appearance: none;
  color: inherit;

  grid-column: 1 / -1;
  display: grid;
  overflow: hidden;
  grid-template-columns: subgrid;
  align-items: center;
  justify-content: start;
  height: 32px;

  &:not(:hover) .icon-container {
    color: ${resolveColor};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  }

  &.selected {
    background-color: #bfccd9;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.palette.blues[3]};
    color: ${({ theme }) => theme.colors.palette.white};
  }
`;

const StyledItemCellDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding: 0 ${({ theme }) => theme.space.spacing40};
`;

const StyledStatusCellDiv = styled.div`
  padding: 0 ${({ theme }) => theme.space.spacing40};
`;
