import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/users`;

const getByIdRoute = (id: number) => `${getBaseRoute()}/${id}`;

const getForGridRoute = () => `${getBaseRoute()}/grid`;

export const UserRouteService = {
  getBaseRoute,
  getByIdRoute,
  getForGridRoute,
};
