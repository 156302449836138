import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { NotificationsService } from 'core/notifications';

import { Field } from 'core/forms';
import {
  Accordion,
  Button,
  ButtonVariants,
  Dialog,
  MultiSelect,
} from 'core/ui';

import HeartMonitorIcon from '../assets/heart-monitor.svg?react';
import { apiClient } from 'features/api';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useNavigate } from 'react-router-dom';
import { WellKnownSettingName, useUserSettings } from 'features/settings';

const TagsAccordion = ({
  eventKey,
  valueGetter,
  valueSetter,
  value,
  examId,
  locationId,
}) => {
  const selectedTags = valueGetter('tags');

  const [tags, setTags] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [enableReadRejection, setEnableReadRejection] = useState(false);
  const navigate = useNavigate();
  const { homePageUrl } = useUserSettings(true);

  // show selected and other available tags
  const availableTags = selectedTags
    ? tags
        .filter((t) => !selectedTags.find((t2) => t2.tag === t.tag))
        .concat(selectedTags)
    : tags;

  useEffect(() => {
    apiClient.settingsClient
      .getSettings(true, null, null, locationId)
      .then((res) => {
        const tagSettings =
          res
            .find((s) => s.name === WellKnownSettingName.Tags)
            ?.value?.split(',') || null;
        setTags(tagSettings.map((t) => ({ tag: t.trim() })));

        const enableReadRejectionSetting =
          res
            .find((s) => s.name === WellKnownSettingName.EnableReadRejection)
            ?.value?.toLowerCase() === 'true';
        setEnableReadRejection(enableReadRejectionSetting);
      });
  }, []);

  // Set initial tag values
  useEffect(() => {
    if (!selectedTags && value) {
      valueSetter('tags', { value });
    }
  }, [value, selectedTags, valueSetter]);

  const toggleDialog = () => {
    setDialogVisible(!dialogVisible);
  };

  const setRejected = async () => {
    const status = {
      id: examId,
      status: 'Reject',
      cancelReason: null,
    };

    try {
      await apiClient.exams.changeStatus(status);
      NotificationsService.displaySuccess('Exam rejected');
      window.location = homePageUrl;
    } catch (error) {
      NotificationsService.displayError('Status change failed');
    } finally {
      toggleDialog();
    }
  };

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={HeartMonitorIcon} title="Tags" />
      <Accordion.Body>
        <StyledRow>
          <Col className="col-8">
            <Field
              allowCustom
              component={MultiSelect}
              editorId="tags"
              textField="tag"
              dataItemKey="tag"
              data={availableTags}
              name="tags"
            />
          </Col>
          <Col className="col-4">
            {enableReadRejection && (
              <Button variant={ButtonVariants.SECONDARY} onClick={toggleDialog}>
                Reject
              </Button>
            )}
          </Col>
        </StyledRow>
      </Accordion.Body>
      {dialogVisible && (
        <Dialog title="Please Confirm" onClose={toggleDialog}>
          <span>Reject Exam</span>
          <DialogActionsBar>
            <Button onClick={toggleDialog} variant={ButtonVariants.SECONDARY}>
              No
            </Button>
            <Button onClick={() => setRejected()}>Yes</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </Accordion.Item>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

TagsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  examId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export { TagsAccordion };
