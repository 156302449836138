import { MouseEvent, memo, useMemo } from 'react';

import { Loader } from '@progress/kendo-react-indicators';
import { useAccordionButton } from 'react-bootstrap';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Accordion } from 'core/ui';
import { HeaderButton, HeaderCollapseIcon, HeaderDiv, HeaderTitleButton } from 'core/ui/Accordion';
import { findOrThrow } from 'core/utils';

import { WorklistSidebarAccordionItemProps } from '../types';

export const WorklistSidebarAccordionItem = memo<WorklistSidebarAccordionItemProps>(
  ({ className, isNested = false, selectedWorklistId, allWorklists, worklistId, allCounts, onWorklistClick }) => {
    const worklist = useMemo(
      () => findOrThrow(allWorklists, (w) => w.id === worklistId, `Could not find worklist: ${worklistId}.`),
      [allWorklists, worklistId],
    );
    const count = useMemo(() => allCounts?.[worklistId.toString()] ?? null, [allCounts, worklistId]);
    const childWorklists = useMemo(() => allWorklists.filter((w) => w.parentViewId === worklist.id), [allWorklists, worklist]);

    const handleAccordionButtonClick = useAccordionButton(worklist.id.toString());

    const handleHeaderClick = useEvent((event: MouseEvent<HTMLButtonElement>) => {
      handleAccordionButtonClick(event);
      onWorklistClick(worklist.id);
    });

    return (
      <Accordion.Item className={className} eventKey={worklist.id.toString()}>
        <StyledHeaderDiv $isSelected={selectedWorklistId === worklist.id}>
          <StyledHeaderTitleButton type="button" onClick={handleHeaderClick} $isNested={isNested}>
            {worklist.name}
          </StyledHeaderTitleButton>
          <StyledCountButton type="button" onClick={handleHeaderClick}>
            {count == null ? <Loader size="small" /> : <StyledCountDiv className="count">{count}</StyledCountDiv>}
          </StyledCountButton>
          <StyledExpandIconButton type="button" onClick={handleHeaderClick}>
            <StyledHeaderCollapseIcon eventKey={worklist.id.toString()} $hidden={childWorklists.length === 0} />
          </StyledExpandIconButton>
        </StyledHeaderDiv>
        <Accordion.Body>
          {childWorklists.map((childItem) => (
            <WorklistSidebarAccordionItem
              key={childItem.id}
              isNested
              selectedWorklistId={selectedWorklistId}
              allWorklists={allWorklists}
              allCounts={allCounts}
              worklistId={childItem.id}
              onWorklistClick={onWorklistClick}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    );
  },
);

WorklistSidebarAccordionItem.displayName = 'WorklistSidebarAccordionItem';

const StyledHeaderDiv = styled(HeaderDiv)<{
  $isSelected: boolean;
}>`
  ${({ $isSelected, theme }) => ($isSelected ? `background: ${theme.colors.palette.blues[1]};` : '')}
`;

const StyledHeaderTitleButton = styled(HeaderTitleButton)<{
  $isNested: boolean;
}>`
  & {
    padding-left: ${({ $isNested, theme }) => ($isNested ? '19px' : theme.space.spacing30)};
  }
`;

const StyledCountButton = styled(HeaderButton)``;

const StyledExpandIconButton = styled(HeaderButton)`
  padding: ${({ theme }) => `0 ${theme.space.spacing30} 0 ${theme.space.spacing40}`};
`;

const StyledCountDiv = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: #fff;
  font-size: 12px;
  margin-left: ${({ theme }) => theme.space.spacing20};
  padding: 1px 4px;
`;

const StyledHeaderCollapseIcon = styled(HeaderCollapseIcon)<{
  $hidden: boolean;
}>`
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
`;
