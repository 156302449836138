import { ReactNode, memo, useEffect, useMemo, useState } from 'react';

import { useEvent } from 'core/hooks';

import { apiClient } from 'features/api';
import { AuthenticationScheme, useAuthentication } from 'features/auth';

import { UploaderContext } from '../contexts';
import { AzureBlobUploader, FileCompressor, FileScanner, ImageDataCache, UploadManager } from '../services';

export const UploadManagerProvider = memo<{ children?: ReactNode }>(({ children }) => {
  const { activeScheme } = useAuthentication();

  const [fileCompressor] = useState(() => new FileCompressor());
  const [blobUploader] = useState(() => new AzureBlobUploader());
  const [thumbnailCache] = useState(() => new ImageDataCache());
  const [fileScanner] = useState(() => new FileScanner(thumbnailCache));
  const [uploadManager] = useState(() => new UploadManager(fileScanner, blobUploader, fileCompressor));

  const getSasFn = useEvent(async (containerName: string) => {
    const newSasUrl = await apiClient.filesClient.generateAzureBlobUploadSas(
      containerName,
      activeScheme === AuthenticationScheme.OIDC ? 'msal-required' : 'share-required',
    );
    return newSasUrl;
  });

  const initialize = useEvent(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (activeScheme == null) return () => {};

    uploadManager.initialize(getSasFn);

    return () => {
      uploadManager.destroy();
    };
  });

  useEffect(() => {
    return initialize();
  }, [initialize, activeScheme]);

  const context = useMemo(
    () => ({
      uploadManager,
      fileScanner,
      thumbnailCache,
    }),
    [uploadManager, fileScanner, thumbnailCache],
  );

  return <UploaderContext.Provider value={context}>{children}</UploaderContext.Provider>;
});

UploadManagerProvider.displayName = 'UploadManagerProvider';
