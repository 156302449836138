import styled from 'styled-components';

export const PrimaryCard = Object.assign(
  styled.div`
    background: ${({ theme }) => theme.colors.palette.blues[0]};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.palette.blues[5]};
    padding: ${({ theme }) => theme.space.spacing40};
  `,
  {
    /** Card Header must be a direct child of the `PrimaryCard` in the DOM. */
    Header: styled.div`
      line-height: ${({ theme }) => theme.lineHeights.subheading};
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      .icon-container {
        color: ${({ theme }) => theme.colors.primary};
      }
    `,
    /** Card HeaderText must be a direct child of the `PrimaryCard.Header`.  Card header icons should appear immediately before this element. */
    HeaderText: styled.span`
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      color: ${({ theme }) => theme.colors.palette.blues[7]};
      margin-left: 8px;
      user-select: none;
    `,
    /** Optional SubHeader.  Must be a direct child of the `PrimaryCard` in the DOM. */
    SubHeader: styled.div`
      line-height: ${({ theme }) => theme.lineHeights.subheading};
      display: flex;
      align-items: center;
      margin-top: 8px;
    `,
    /** Optional SubHeader text.  Must be a direct child of the `PrimaryCard.SubHeader`.  Card header icons should appear immediately before this element. */
    SubHeaderText: styled.span`
      font-size: ${({ theme }) => theme.fontSizes.subheading};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      color: ${({ theme }) => theme.colors.palette.blues[7]};
      margin-left: 8px;
      user-select: none;
    `,
    Body: styled.div<{ $noPadding?: boolean }>`
      padding: ${({ $noPadding, theme }) => ($noPadding ? '0' : theme.space.spacing40)};
    `,
  },
);

PrimaryCard.displayName = 'PrimaryCard';
PrimaryCard.Header.displayName = 'PrimaryCard.Header';
PrimaryCard.HeaderText.displayName = 'PrimaryCard.HeaderText';
