// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/upload-tracker`;

const getForGridRoute = () => `${getBaseRoute()}/grid`;

const getDownloadFileRoute = (studyInstanceUID) =>
  `${getBaseRoute()}/${studyInstanceUID}`;

export const UploadTrackerRouteService = {
  getBaseRoute,
  getDownloadFileRoute,
  getForGridRoute,
};
