/** Compare 2 strings for equality with case insensitive matching.  This handles null and undefined by "===" equality comparison. */
export function equalsInsensitive(
  lhs: string | null | undefined,
  rhs: string | null | undefined,
) {
  if (lhs === rhs) return true;
  if (lhs == null || rhs == null) return false;

  return (
    lhs.localeCompare(rhs, 'en-US', {
      sensitivity: 'base',
    }) === 0
  );
}
