import { FunctionComponent, useCallback } from 'react';

import { Field, FieldContainer, Form, GridColumn, createStyledFormElement } from 'core/forms';
import { Button, DropdownWithValuesField, Input, Label } from 'core/ui';

import { SignInMethods } from '../constants';
import { IntegrationTabContentProps, IntegrationTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const IntegrationTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof IntegrationTabFormValues) => any;
  onChange: (values: IntegrationTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      uploadURL: valueGetter('uploadURL'),
      signinMethod: valueGetter('signinMethod'),
      externalUid: valueGetter('externalUid'),
    });
  }, [valueGetter, onChange]);

  return (
    <StyledFormElement autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="uploadURL">Upload URL</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="uploadURL" editorId="uploadURL" component={Input} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="signinMethod">Signin Method</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={DropdownWithValuesField}
            data={SignInMethods}
            dataItemKey="value"
            isForPrimitiveValues
            editorId="signinMethod"
            name="signinMethod"
            valueField="value"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="externalUid" description="Group Object ID for SSO group mapping">
          External Uid
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="externalUid" editorId="externalUid" component={Input} onChange={handleFormChange} />
        </FieldContainer>
      </GridColumn>
      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

IntegrationTabContentInner.displayName = 'IntegrationTabContentInner';

export const IntegrationTabContent: FunctionComponent<IntegrationTabContentProps> = ({ initialValues, onChange, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => <IntegrationTabContentInner valueGetter={valueGetter} onChange={onChange} />}
    />
  );
};

IntegrationTabContent.displayName = 'IntegrationTabContent';
