import dayjs from 'dayjs';

import { NORMALIZED_VALUE_FORMAT } from './constants';

export function normalizeMaskedDateTimeInputValue(value: Parameters<typeof dayjs>[0], format: string, placeholder: string) {
  const parsed = dayjs(value, format, true);

  if (parsed.isValid()) {
    return parsed.format(NORMALIZED_VALUE_FORMAT);
  } else if (value === placeholder) {
    return '';
  }

  return value?.toString?.() ?? '';
}
