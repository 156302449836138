import { FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { Tag } from '../../../Tag';
import { TagCellProps } from './TagCellProps';

export const TagCell: FunctionComponent<TagCellProps> = ({ className, valueTransform, dataItem, field }) => {
  const dataItemField = field == null ? undefined : dataItem[field];

  const value = valueTransform?.(dataItemField) ?? dataItemField ?? '';

  const content = useMemo(
    () => (
      <td className={className}>
        {Array.isArray(dataItemField) ? (
          <StyledSpanTagContainer>
            {dataItemField.map((item) => (
              <Tag key={item} text={item} />
            ))}
          </StyledSpanTagContainer>
        ) : (
          <Tag text={value} />
        )}
      </td>
    ),
    [className, dataItemField, value],
  );

  return content;
};

TagCell.displayName = 'TagCell';

const StyledSpanTagContainer = styled.span`
  display: inline-flex;
  flex: 1;
  gap: ${({ theme }) => theme.space.spacing20};
`;
