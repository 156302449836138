import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiError, ValidationError } from 'core/api/types';
import FetchParams from 'core/api/types/fetch-params';

import { NotificationsService } from '../../notifications';
import { ApiHelpers } from '../helpers';
import {
  getAccessTokenReduxShim,
  getShareTokenReduxShim,
} from './redux-api-shim';

const createApiThunk = (method: string) =>
  createAsyncThunk(
    `api/${method}`,
    async (params: FetchParams | string, { rejectWithValue }) => {
      try {
        let url: string;
        let body: Record<string, unknown> | null | FormData | undefined = null;
        let headers: Headers | Record<string, never>;
        let options: Record<string, unknown> | null | undefined = null;

        if (typeof params === 'object') {
          ({ url } = params);
          ({ body } = params);
          headers = params?.headers ?? {};
          ({ options } = params);
        } else {
          url = params;
        }

        let bodyData: BodyInit | null | FormData | undefined = null;
        if (body instanceof FormData) {
          bodyData = body;
        } else {
          bodyData = body ? JSON.stringify(body) : null;
        }

        const accessToken: string | null =
          (await getShareTokenReduxShim()) ?? (await getAccessTokenReduxShim());

        const request = () => {
          return fetch(url, {
            method,
            headers: {
              ...(accessToken
                ? { Authorization: `Bearer ${accessToken}` }
                : {}),
              ...(!options?.isNotJSON && {
                'Content-Type': 'application/json',
              }),
              ...(headers || {}),
            },
            body: bodyData,
          });
        };

        const response = await request();
        const contentType = response.headers.get('content-type');

        if (ApiHelpers.isSuccessful(response)) {
          if (response.status === 204) {
            return {};
          }

          if (contentType?.includes('application/json')) {
            return await response.json();
          }

          if (contentType?.includes('application/octet-stream')) {
            return await response.blob();
          }

          return response;
        }

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw { data: await response.json(), statusCode: response.status };
      } catch (error: unknown) {
        const apiError = error as ApiError;

        // display notification if error happens
        if (apiError.responseException?.validationErrors) {
          // this is done via notification because Kendo doesn't support backend validation natively
          apiError.responseException.validationErrors.forEach(
            (property: ValidationError) =>
              NotificationsService.displayError(
                property.errorMessages.join('\r\n'),
              ),
          );
        } else if (
          !apiError.statusCode ||
          !ApiHelpers.isEntityLocked(apiError.statusCode)
        ) {
          NotificationsService.displayError('Something went wrong!');
        }

        return rejectWithValue(apiError);
      }
    },
  );

const get = createApiThunk('GET');
const post = createApiThunk('POST');
const remove = createApiThunk('DELETE');
const put = createApiThunk('PUT');
const patch = createApiThunk('PATCH');

export const ApiActions = {
  get,
  post,
  remove,
  put,
  patch,
};
