import { FunctionComponent } from 'react';

import {
  Chip as KendoChip,
  ChipProps as KendoChipProps,
} from '@progress/kendo-react-buttons';
import styled, { css } from 'styled-components';

import { hasText } from 'core/utils';

import { ChipProps } from './ChipProps';
import { ChipPreset } from './constants';

export const Chip: FunctionComponent<ChipProps> = ({ dataItem, ...rest }) => {
  return (
    <StyledChip
      $preset={
        hasText(dataItem.chipPreset) ? dataItem.chipPreset : ChipPreset.DEFAULT
      }
      dataItem={dataItem}
      {...rest}
    />
  );
};

Chip.displayName = 'Chip';

type StyledElementProps = {
  $preset: ChipPreset;
};

function resolvePreset({ $preset }: StyledElementProps) {
  switch ($preset) {
    case ChipPreset.GREEN:
      return PresetGreen;
    case ChipPreset.ORANGE:
      return PresetOrange;
    case ChipPreset.RED:
      return PresetRed;
    case ChipPreset.GREY:
      return PresetGrey;
    default:
      return PresetDefault;
  }
}

const StyledChip = styled(KendoChip)<StyledElementProps>`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.blues[4]};
  background: white;

  ${resolvePreset}

  // Override the color specified in the preset.  This should probably be removed and replaced with the "presets" pattern, but someone needs to go to the trouble of finding all places that might use this and adjust as necessary.  It might be easier once everything is converted to typescript.
  ${({ dataItem }) => ('color' in dataItem ? `color: ${dataItem.color};` : '')}
`;

const PresetDefault = css<KendoChipProps>`
  color: ${({ dataItem, theme }) =>
    dataItem.color || theme.colors.palette.blues[4]};

  &:hover {
    background: white;
  }

  &.k-focus {
    background: white;
  }

  &.k-selected {
    background-color: ${({ theme }) => theme.colors.palette.blues[4]};
    color: white;
  }
`;

const PresetGreen = css<KendoChipProps>`
  background: white;
  color: ${({ theme }) => theme.colors.palette.greens[8]};

  &:hover {
    background: white;
  }

  &.k-focus {
    background: white;
  }

  &.k-selected {
    background-color: ${({ theme }) => theme.colors.palette.greens[7]};
    color: white;
  }
`;

const PresetOrange = css<KendoChipProps>`
  background: white;
  color: ${({ theme }) => theme.colors.palette.yellows[6]};

  &:hover {
    background: white;
  }

  &.k-focus {
    background: white;
  }

  &.k-selected {
    background-color: ${({ theme }) => theme.colors.palette.yellows[6]};
    color: white;
  }
`;

const PresetRed = css<KendoChipProps>`
  background: white;
  color: ${({ theme }) => theme.colors.palette.reds[5]};

  &:hover {
    background: white;
  }

  &.k-focus {
    background: white;
  }

  &.k-selected {
    background-color: ${({ theme }) => theme.colors.palette.reds[6]};
    color: white;
  }
`;

const PresetGrey = css<KendoChipProps>`
  background: white;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};

  &:hover {
    background: white;
  }

  &.k-focus {
    background: white;
  }

  &.k-selected {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[6]};
    color: white;
  }
`;
