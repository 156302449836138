import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

import { ShareStudyViewer } from 'models';

import { equalsInsensitive } from 'core/utils';

import { ExamFilter } from '../types';

function formatExpireMessage(expiration: Dayjs | null, expireDuration: Duration | null) {
  if (expireDuration == null) return null;
  if (expireDuration.asMilliseconds() < 0) return null;

  // For formatting purposes we are going to truncate off the decimals.
  const days = Math.floor(expireDuration.asDays());
  const hours = Math.floor(expireDuration.asHours());
  const minutes = Math.floor(expireDuration.asMinutes());

  // Note that this is intentionally adding spaces at the beginning and end.  If that needs to change then make sure changes are consistently applied.
  const prefix = expiration == null ? '' : expiration.format('YYYY-MM-DD HH:mm:ss');
  if (days >= 1) return `${prefix} (${days} day${days === 1 ? '' : 's'})`;
  if (hours >= 1) return `${prefix} (${hours} hour${hours === 1 ? '' : 's'})`;
  return `${prefix} (${minutes} minute${minutes === 1 ? '' : 's'})`;
}

/** Find the file specified by the id. */
function findFile(fileId: number | null, exams: ShareStudyViewer[]) {
  if (fileId == null) return null;
  for (const [examIndex, exam] of exams.entries()) {
    for (const [fileIndex, file] of exam.files.entries()) {
      if (file.id === fileId) return { file, examIndex, fileIndex };
    }
  }
  return null;
}

function formatExamStudyDate(studyDate: string | null | undefined) {
  const parsed = dayjs(studyDate);
  return parsed.isValid() ? parsed.format('MM/DD/YY hh:mm') : null;
}

function filterExams(exams: ShareStudyViewer[], filter: ExamFilter) {
  const newFiltered =
    filter.studyTypes.length === 0 && filter.organs.length === 0
      ? exams
      : exams.filter((e) => {
          const studyPredicate = filter.studyTypes.length === 0 || filter.studyTypes.some((st) => equalsInsensitive(st, e.studyType));

          const organPredicate = filter.organs.length === 0 || filter.organs.includes(e.organ);

          return studyPredicate && organPredicate;
        });

  return newFiltered;
}

export const ShareService = {
  formatExpireMessage,
  findFile,
  formatExamStudyDate,
  filterExams,
};
