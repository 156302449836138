import { useEffect, useState } from 'react';

import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AuthenticationScheme, useCurrentUser } from 'features/auth';

import CloseDrawerIcon from '../assets/close-drawer-icon.svg?react';
import ExpandDrawerIcon from '../assets/expand-drawer-icon.svg?react';
import { NavigationSidebarCustomItem } from './NavigationSidebarCustomItem';

const SIDEBAR_WIDTH = 150;

const NavigationSidebar = ({ children, navigationItems, hideNavbarPaths, scheme }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useCurrentUser();

  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(
      navigationItems.filter((item) => {
        if (currentUser?.role && item?.allowedRoles?.length > 0) {
          return item.allowedRoles.includes(currentUser.role);
        }
        return true;
      }),
    );
  }, [currentUser, navigationItems]);

  const handleClick = () => {
    const newData = items.map((item) => {
      if (item.dataExpanded) {
        return {
          ...item,
          dataExpanded: false,
        };
      }

      return item;
    });

    setItems(newData);
    setDrawerExpanded(!drawerExpanded);
  };

  const onSelect = (event) => {
    const clickedItem = event.itemTarget.props;
    const isParent = clickedItem.dataExpanded !== undefined;

    const newData = items.map((item) => {
      const isClickedItem = clickedItem.id === item.id;

      return {
        ...item,
        drawerExpanded,
        dataExpanded: isClickedItem && isParent ? !clickedItem.dataExpanded : item.dataExpanded,
      };
    });

    if (clickedItem.route) {
      navigate(clickedItem.route);
    } else if (clickedItem.externalRoute) {
      window.location = clickedItem.externalRoute;
    }

    setItems(newData);
  };

  const data = items.map((item) => {
    const selectedItem = items.find((navItem) => navItem?.route === location.pathname);

    if (item.parentId === undefined) {
      return {
        ...item,
        selected: selectedItem?.id === item.id,
        drawerExpanded,
      };
    }

    const currentItemParent = items.find((parent) => parent.id === item.parentId);

    return {
      ...item,
      drawerExpanded,
      selected: selectedItem?.id === item.id,
      visible: currentItemParent?.dataExpanded ?? false,
    };
  });

  // @TODO refactor navbar to be more flexible
  if (hideNavbarPaths.some((pathRegex) => location.pathname.match(pathRegex))) {
    return children;
  }

  // eslint-disable-next-line no-nested-ternary
  return scheme === AuthenticationScheme.OIDC ? (
    <>
      <StyledApplicationContainer>
        <StyledDivExpandButtonWrap expanded={drawerExpanded}>
          <StyledDivDrawerExpandIcon>
            {drawerExpanded ? (
              <CloseDrawerIcon height={18} width={18} onClick={handleClick} />
            ) : (
              <ExpandDrawerIcon height={18} width={18} onClick={handleClick} />
            )}
          </StyledDivDrawerExpandIcon>
        </StyledDivExpandButtonWrap>
        <StyledDrawer expanded={drawerExpanded} item={NavigationSidebarCustomItem} items={data} mode="push" mini onSelect={onSelect} width={SIDEBAR_WIDTH}>
          <StyledDrawerContent drawerExpanded={drawerExpanded}>
            <StyledDivPage>{children}</StyledDivPage>
          </StyledDrawerContent>
        </StyledDrawer>
      </StyledApplicationContainer>
    </>
  ) : (
    <StyledDivPage $noPadding>{children}</StyledDivPage>
  );
};

const StyledApplicationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
`;

const StyledDrawer = styled(Drawer)`
  overflow: hidden;

  & .k-drawer {
    position: relative;
    top: 43px;
    background: ${({ theme }) => theme.colors.palette.blues[5]};
    border: none;
    color: ${({ theme }) => theme.colors.palette.white};
    min-height: calc(100vh - ${({ theme }) => theme.space.spacing30} - ${({ theme }) => theme.sizes.sidebarExpandIconContainerHeight});
    text-transform: capitalize;
  }

  & .k-drawer-separator {
    background-color: ${({ theme }) => theme.colors.palette.white};
  }

  & .k-drawer-item.k-selected {
    border-right: ${({ theme }) => theme.borderWidths.sidebar} solid ${({ theme }) => theme.colors.palette.white};
  }
`;

const StyledDrawerContent = styled(DrawerContent)`
  flex: 1 1 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
  height: 100%;
`;

const StyledDivPage = styled.div`
  display: grid;
  overflow: hidden;
  padding-left: ${({ theme, $noPadding }) => ($noPadding ? '' : theme.space.spacing20)};
  position: relative;
`;

const StyledDivDrawerExpandIcon = styled.div`
  cursor: pointer;
`;

const StyledDivExpandButtonWrap = styled.div`
  position: absolute;
  z-index: 1;
  height: 43px;
  background: ${({ theme }) => theme.colors.palette.blues[5]};
  border-top-right-radius: ${({ theme }) => theme.radii.scale[3]};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.palette.white};
  display: flex;
  justify-content: ${({ expanded }) => (expanded ? 'end' : 'center')};

  padding: ${({ theme }) => theme.space.spacing40};
  transition: all 200ms ease 0s;
  width: ${({ expanded, theme }) => (expanded ? `${SIDEBAR_WIDTH}px` : theme.sizes.sidebarWidthMinimized)};
`;

NavigationSidebar.propTypes = {
  children: PropTypes.node,
  hideNavbarPaths: PropTypes.arrayOf(PropTypes.instanceOf(RegExp)),
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      dataExpanded: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.number,
      parentId: PropTypes.number,
      route: PropTypes.string,
      externalRoute: PropTypes.string,
      separator: PropTypes.bool,
      text: PropTypes.string,
    }),
  ).isRequired,
  scheme: PropTypes.string,
};

NavigationSidebar.defaultProps = {
  children: undefined,
  hideNavbarPaths: [],
  scheme: undefined,
};

export { NavigationSidebar };
