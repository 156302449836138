const spaceScale = [
  '0rem',
  '0.25rem',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '3.5rem',
];

const spacing10 = spaceScale[1]; // 0.25rem
const spacing20 = spaceScale[2]; // 0.5rem
const spacing30 = spaceScale[3]; // 0.75rem
const spacing40 = spaceScale[4]; // 1rem
const spacing50 = spaceScale[5]; // 1.5rem
const spacing60 = spaceScale[6]; // 2rem
const spacing70 = spaceScale[7]; // 2.5rem
const spacing80 = spaceScale[8]; // 3.5rem

const badgePaddingDefault = '0.375rem';
const dataTableFilterIconMarginLeft = '1.25rem';
const dataTableHeaderCellIconMarginLeft = spacing10;
const dataTableHeaderCellIconMarginRight = '0.188rem';
const dataTableMarginTop = spacing10;
const dataTableRowPaddingVerticalLarge = '1.063rem';
const dataTableRowPaddingVerticalMedium = '0.813rem';
const dataTableRowPaddingVerticalSmall = '0';
const dataTableTextWithIconCellIconMargin = '0.625rem';
const multiSelectItemMarginLeft = '0.188rem';
const multiSelectItemMarginVertical = '0.125rem';
const paddingVerticalSmall = '0.063rem';
const paddingVerticalMedium = '0.313rem';
const sidebarArrowIconMargin = '0.625rem';
const sidebarItemPaddingLeft = '1rem';
const switchLabelMargin = '0.375rem';
const tagIconMarginTop = '-0.188rem';
const tagPaddingMedium = '0.125rem';

export const space = {
  scale: spaceScale,
  spacing10,
  spacing20,
  spacing30,
  spacing40,
  spacing50,
  spacing60,
  spacing70,
  spacing80,
  badgePaddingDefault,
  dataTableFilterIconMarginLeft,
  dataTableHeaderCellIconMarginLeft,
  dataTableHeaderCellIconMarginRight,
  dataTableMarginTop,
  dataTableRowPaddingVerticalLarge,
  dataTableRowPaddingVerticalMedium,
  dataTableRowPaddingVerticalSmall,
  dataTableTextWithIconCellIconMargin,
  multiSelectItemMarginLeft,
  multiSelectItemMarginVertical,
  paddingVerticalSmall,
  paddingVerticalMedium,
  sidebarArrowIconMargin,
  sidebarItemPaddingLeft,
  switchLabelMargin,
  tagIconMarginTop,
  tagPaddingMedium,
};

export type Space = typeof space;
