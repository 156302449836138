import { ComponentType, FunctionComponent } from 'react';

import { faCaretLeft, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import styled from 'styled-components';

import { useAccordionTabExpanded } from 'core/hooks';

import { Icon } from '../Icon';
import { HeaderProps } from './HeaderProps';
import arrowCollapsed from './assets/arrow-collapsed.svg';

export const Header: FunctionComponent<HeaderProps> = ({ title, onClick, children, className, Icon: IconComponent }) => {
  return (
    <StyledAccordionHeader className={className} onClick={onClick}>
      <StyledHeaderWrapperDiv>
        <StyledTitleWrapper>
          {IconComponent && <IconComponent />}
          <StyledTitleDiv className="accordion-title" $Icon={IconComponent}>
            {title}
          </StyledTitleDiv>
        </StyledTitleWrapper>
        {children}
      </StyledHeaderWrapperDiv>
    </StyledAccordionHeader>
  );
};

Header.displayName = 'Accordion.Header';

const StyledHeaderWrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;

const StyledTitleDiv = styled.div<{ $Icon?: ComponentType }>`
  margin-left: ${({ $Icon, theme }) => ($Icon ? theme.space.spacing20 : 0)};
`;

const StyledTitleWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.palette.blues[9]};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.body};
  justify-content: center;
`;

const StyledAccordionHeader = styled(BootstrapAccordion.Header)`
  && .accordion-button {
    padding: ${({ theme }) => theme.space.spacing30} ${({ theme }) => theme.space.spacing40};

    &:focus {
      box-shadow: none;
    }
  }

  && .accordion-button:not(.collapsed),
  && .accordion-button {
    background: transparent;
    box-shadow: none;

    &:after {
      background-image: url(${arrowCollapsed});
    }
  }
`;

export const HeaderCollapseIcon: FunctionComponent<{
  className?: string;
  variant?: 'primary' | 'secondary';
  eventKey: string;
  horizontal?: boolean;
  onClick?: () => void;
}> = ({ className, variant = 'secondary', eventKey, horizontal, onClick }) => {
  const isExpanded = useAccordionTabExpanded(eventKey);

  const classes = cn(className, {
    collapsed: !isExpanded,
  });

  return (
    <StyledCollapseIcon
      className={classes}
      icon={horizontal ? faCaretLeft : faCaretUp}
      color={variant === 'primary' ? 'white' : undefined}
      rotation={isExpanded ? undefined : 180}
      size="lg"
      onClick={onClick}
    />
  );
};

HeaderCollapseIcon.displayName = 'HeaderCollapseIcon';

const StyledCollapseIcon = styled(Icon)`
  svg {
    transition: transform 0.2s ease-in-out;
  }
  cursor: pointer;
`;

export const HeaderButton = styled.button`
  border: none;
  display: block;
  padding: 0;
  background: transparent;

  &:focus {
    box-shadow: none;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  min-height: 43px;
`;

export const HeaderTitleButton = styled.button`
  flex: 1 1 0;
  text-align: left;
  border: none;
  display: block;
  padding: 0;
  background: transparent;

  svg {
    margin: 0 ${({ theme }) => theme.space.spacing20} 0 ${({ theme }) => theme.space.spacing40};
  }
`;
