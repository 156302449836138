import { FunctionComponent, useCallback } from 'react';

import { Form, GridColumn, createStyledFormElement } from 'core/forms';
import { Button } from 'core/ui';

import { IntegrationTabContentProps } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const IntegrationTabContentInner: FunctionComponent = () => {
  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

IntegrationTabContentInner.displayName = 'IntegrationTabContentInner';

export const IntegrationTabContent: FunctionComponent<
  IntegrationTabContentProps
> = ({ initialValues, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={() => <IntegrationTabContentInner />}
    />
  );
};

IntegrationTabContent.displayName = 'IntegrationTabContent';
