import { IntegrationModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class IntegrationClient {
  constructor(private httpClient: HttpClient) {
    this.getAllIntegrations = this.getAllIntegrations.bind(this);
  }

  public async getAllIntegrations() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const integrations = await this.httpClient.fetchAndParse<
      IntegrationModel[]
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Integrations`,
        options,
      ),
    );
    return integrations;
  }
}
