import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions } from 'core/api';
// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from 'core/api/services';

import { FormFieldRouteService } from '../services';
import { formFieldEntity } from './normalizr-schema';

const getById = createAsyncThunk(
  'form-fields/get-by-id',
  async (formFieldId, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(FormFieldRouteService.getByIdRoute(formFieldId)),
    );

    return result;
  },
);

const getByFieldCategoryId = createAsyncThunk(
  'form-fields/get-by-field-category-id',
  async (fieldCategoryId, { dispatch }) => {
    const { result } = await dispatch(
      ApiActions.get(
        FormFieldRouteService.getFormFieldsByCategoryRoute(fieldCategoryId),
      ),
    );

    const normalized = normalize(result, [formFieldEntity]);

    return normalized.entities;
  },
);

export const FormFieldActions = {
  getById,
  getByFieldCategoryId,
};
