import { FunctionComponent } from 'react';

import { Icon } from '@progress/kendo-react-common';
import styled, { DefaultTheme } from 'styled-components';

import { MessageBoxProps } from './MessageBoxProps';

export const MessageBox: FunctionComponent<MessageBoxProps> = ({
  variant,
  className,
  message,
}) => {
  return (
    <StyledMessageBoxDiv className={className} $variant={variant}>
      <Icon
        name={
          variant === 'success'
            ? 'check-outline'
            : variant === 'warning'
            ? 'warning'
            : 'close-outline'
        }
      />
      <StyledMessageSpan>{message}</StyledMessageSpan>
    </StyledMessageBoxDiv>
  );
};

function resolveBackgroundColor({
  theme,
  $variant,
}: {
  theme: DefaultTheme;
  $variant: MessageBoxProps['variant'];
}) {
  return $variant === 'success'
    ? theme.colors.success
    : $variant === 'warning'
    ? theme.colors.warning
    : theme.colors.error;
}

const StyledMessageBoxDiv = styled.div<{
  $variant: MessageBoxProps['variant'];
}>`
  display: flex;
  border-radius: 4px;
  background-color: ${resolveBackgroundColor};
  color: ${({ theme }) => theme.colors.palette.white};
  padding: 11px 16px;
`;

const StyledMessageSpan = styled.span`
  flex: 1 1 0;
  font-size: 14px;
  margin-left: 8px;
`;
