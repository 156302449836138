import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { useEvent } from 'core/hooks';

import { useApiClient } from 'features/api';

import { AuthenticationScheme } from '../constants';
import { useAuthentication } from './useAuthentication';

/** Retrieves a snapshot for the current access token (for both share and oidc auth).  The primary usecase is to be able to place the token in the query string for an &lt;img&gt; or &lt;iframe&gt; tag. */
export function useAccessTokenSnapshot(): UseAccessTokenSnapshotResult {
  // Some points to keep in mind when reviewing/editing this hook:
  // 1. We need to encode an access token in the query string for <img> tags.
  // 2. The access tokens will routinely expire and be replaced with new ones.
  // 3. We only need to have a valid access token long enough for the <img> tag to retrieve the image from the API.
  // 4. We do NOT want to update <img> tags with the latest token if the image has already been retrieved, because that's just extra network traffic to fetch something we already have.

  const [requestTime, setRequestTime] = useState(dayjs.utc().toISOString());
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const { httpClient } = useApiClient();
  const { activeScheme } = useAuthentication();

  const refreshAccessToken = useEvent(() => {
    if (
      activeScheme === AuthenticationScheme.OIDC ||
      activeScheme === AuthenticationScheme.SHARE
    ) {
      setRequestTime(dayjs.utc().toISOString());
    } else {
      throw new Error(
        `Access token cannot be retrieved when the activeScheme is ${activeScheme?.toString()}.`,
      );
    }
  });

  useEffect(() => {
    (async () => {
      let newAccessToken: string | null;
      if (activeScheme === AuthenticationScheme.OIDC) {
        newAccessToken = (await httpClient.getAccessToken?.()) ?? null;
      } else if (activeScheme === AuthenticationScheme.SHARE) {
        newAccessToken = (await httpClient.getShareToken?.()) ?? null;
      } else {
        newAccessToken = null;
      }
      setAccessToken(newAccessToken);
    })();
  }, [activeScheme, httpClient, requestTime]);

  return {
    accessToken,
    refreshAccessToken,
    isAccessTokenReady: accessToken != null,
  } as UseAccessTokenSnapshotResult;
}

export type UseAccessTokenSnapshotResult = {
  refreshAccessToken: () => void;
} & (
  | {
      isAccessTokenReady: false;
      accessToken: null;
    }
  | {
      isAccessTokenReady: true;
      accessToken: string;
    }
);
