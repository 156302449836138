import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { hasText, isGuid } from 'core/utils';

/** Reads, parses, and validates route parameters. */
function useValidatedParam(
  paramName: string,
  type: 'string',
  required: true,
): string;
function useValidatedParam(
  paramName: string,
  type: 'string',
  required: false,
): string | undefined;
function useValidatedParam(
  paramName: string,
  type: 'guid',
  required: true,
): string;
function useValidatedParam(
  paramName: string,
  type: 'guid',
  required: false,
): string | undefined;
function useValidatedParam(
  paramName: string,
  type: 'integer',
  required: true,
): number;
function useValidatedParam(
  paramName: string,
  type: 'integer',
  required: false,
): number | undefined;
function useValidatedParam(
  paramName: string,
  type: 'number',
  required: true,
): number;
function useValidatedParam(
  paramName: string,
  type: 'number',
  required: false,
): number | undefined;
function useValidatedParam(
  paramName: string,
  type: 'boolean',
  required: true,
): boolean;
function useValidatedParam(
  paramName: string,
  type: 'boolean',
  required: false,
): boolean | undefined;
function useValidatedParam(
  name: string,
  type: 'string' | 'guid' | 'integer' | 'number' | 'boolean',
  required: boolean,
) {
  // Note: This function could probably be refactored so that it accepts an array of parameters instead of just a single parameter.

  const allParams = useParams();
  const valueRaw = allParams[name];

  const parsedParam = useMemo(() => {
    if (valueRaw == null && required) {
      throw new Error(`Missing required route parameter "${name}".`);
    } else if (valueRaw == null) {
      return valueRaw;
    } else if (type === 'string') {
      if (!hasText(valueRaw) && required)
        throw new Error(
          `Empty or whitespace string encountered for required route parameter "${name}".`,
        );
      return valueRaw;
    } else if (type === 'guid') {
      if (!isGuid(valueRaw))
        throw new Error(`Could not parse guid route parameter "${name}".`);
      return valueRaw.toLowerCase();
    } else if (type === 'integer') {
      const value = parseInt(valueRaw, 10);
      if (!Number.isSafeInteger(value))
        throw new Error(`Could not parse integer route parameter "${name}".`);
      return value;
    } else if (type === 'number') {
      const value = parseFloat(valueRaw);
      if (isNaN(value) || !isFinite(value))
        throw new Error(`Could not parse number route parameter "${name}".`);
      return value;
    } else if (type === 'boolean') {
      if (valueRaw.toLowerCase() === 'true') return true;
      else if (valueRaw.toLowerCase() === 'false') return false;
      else
        throw new Error(`Unable to parse boolean route parameter "${name}".`);
    } else {
      throw new Error(
        `Unable to parse route parameter "${name}" because the parse logic has not been implemented for type: ${
          type === null
            ? 'null'
            : typeof type === 'undefined'
              ? 'undefined'
              : type
        }.`,
      );
    }
  }, [valueRaw, required, type, name]);

  return parsedParam;
}

export { useValidatedParam };
