import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { WorklistViewUserRoutes } from '../routes';
import { WorklistViewUserForm } from './WorklistViewUserForm';
import { WorklistViewUserGridView } from './WorklistViewUserGridView';

const WORKLIST_VIEW_USER_FORM_ADMIN_ROLES = [UserRoles.ADMIN];

export const WorklistViewUser: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={WorklistViewUserRoutes.HOME}
        element={
          <RouteGuard allowedRoles={WORKLIST_VIEW_USER_FORM_ADMIN_ROLES}>
            <WorklistViewUserGridView />
          </RouteGuard>
        }
      />
      <Route
        path={WorklistViewUserRoutes.ADD}
        element={
          <RouteGuard allowedRoles={WORKLIST_VIEW_USER_FORM_ADMIN_ROLES}>
            <WorklistViewUserForm />
          </RouteGuard>
        }
      />
      <Route
        path={WorklistViewUserRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={WORKLIST_VIEW_USER_FORM_ADMIN_ROLES}>
            <WorklistViewUserForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

WorklistViewUser.displayName = 'WorklistViewUser';
