const getDisplayName = (slaInMinutes) => {
  if (slaInMinutes === null) {
    return 'No Over-read';
  }

  if (slaInMinutes === 0) {
    return '';
  }

  if (slaInMinutes <= 30) {
    return `${slaInMinutes} M`;
  }

  return `${slaInMinutes / 60} H`;
};

const formatMinutesDisplay = (slaInMinutes) => {
  if (slaInMinutes === undefined) return '';
  const hours = Math.floor(slaInMinutes / 60);
  const minutes = slaInMinutes % 60;

  return `${hours.toString().padStart(2, 0)}:${minutes.toString().padStart(2, 0)}`;
};

const formatPercentDisplay = (value) => {
  if (!value) {
    return '';
  }

  return `${value}%`;
};

export const SlaService = {
  formatMinutesDisplay,
  getDisplayName,
  formatPercentDisplay,
};
