import { useContext } from 'react';

import { RouteGuardContext } from '../contexts';

export function useRouteGuard() {
  const { isContextPresent, ...restContext } = useContext(RouteGuardContext);

  if (!isContextPresent)
    throw new Error(
      'useRouteGuard() cannot be called because <RouteGuard /> is not a parent in the React DOM.',
    );

  return { ...restContext };
}
