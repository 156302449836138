import { useRef } from 'react';

import { Accordion } from 'core/ui';

import { ReadingTypes } from '../../exam/constants';
import WriteIcon from '../assets/write.svg?react';
import { useInterpretableState } from '../hooks/useInterpretableState';
import { PathologyTargetedAccordionBody } from './PathologyTargetedAccordionBody';

type PathologyLungResultsAccordionProps = {
  eventKey: string;
  isFormVisible: boolean;
  valueGetter: (key: string) => any;
  valueSetter: (key: string, value: any) => void;
};
export const PathologyLungResultsAccordion = ({ eventKey, isFormVisible, valueGetter, valueSetter }: PathologyLungResultsAccordionProps) => {
  const readingType = valueGetter('Results.readingType');

  const accordionBodyRef = useRef(null);
  const isUninterpretable = useInterpretableState(valueGetter, valueSetter, accordionBodyRef);

  let accordionBody;

  if ((isFormVisible && readingType === ReadingTypes.TARGETED.value) || ReadingTypes.GENERAL.value) {
    accordionBody = <PathologyTargetedAccordionBody isUninterpretable={isUninterpretable} valueGetter={valueGetter} />;
  }

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={WriteIcon} title="Results" />
      <div ref={accordionBodyRef}>{accordionBody}</div>
    </Accordion.Item>
  );
};
