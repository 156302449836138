import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { BrandingModel } from 'models';

import { BrandingActions } from './actions';

export const brandingAdapter = createEntityAdapter<BrandingModel>();

export const reducer = createReducer(
  brandingAdapter.getInitialState(),
  (builder) => {
    builder.addCase(BrandingActions.getAll.fulfilled, (state, action) => {
      const payload = action.payload as { brandings: BrandingModel[] };
      brandingAdapter.upsertMany(state, payload.brandings);
    });
  },
);
