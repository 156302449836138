import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import styled from 'styled-components';

import { BulkAttachFilesModel, ExamModel, PatientModel, ServiceModel } from 'models';

import { useDataStream, useEvent, useEventStream, useInterval, useValidatedParam } from 'core/hooks';
import { Page, PageHeader } from 'core/ui';
import { findOrThrow, hasText } from 'core/utils';

import { apiClient } from 'features/api';
import { useCurrentUser } from 'features/auth';
import { UploadManagerProvider, useUploadManager } from 'features/file';
import { InitialFileContext, UploadCompleteEvent } from 'features/file/types';
import { useSessionLocation } from 'features/location';

import { FILE_ATTACH_INTERVAL, INITIAL_WIZARD_STEPS } from '../constants';
import { UploadExamsPageContext } from '../contexts';
import { ExamUploadService, UploadView } from '../services';
import { UploadExamsPageContextType, UploadGroup, UploadStateType, WizardStepKey, WizardStepProps } from '../types';
import { AttachmentsStep } from './AttachmentsStep';
import { ExamsStep } from './ExamsStep';
import { ReviewAndUploadStep } from './ReviewAndUploadStep';
import { SelectFilesStep } from './SelectFilesStep';
import { WizardStep } from './WizardStep';

const UploadExamsPageInner = memo(() => {
  const { uploadManager } = useUploadManager();
  const { currentUser } = useCurrentUser(true);
  const { sessionLocation } = useSessionLocation(true);
  const patientIdParam = useValidatedParam('patientId', 'integer', false);
  const examIdParam = useValidatedParam('examId', 'integer', false);

  const [uploadView] = useState(() => new UploadView(uploadManager));
  const [allServices, setAllServices] = useState<ServiceModel[] | null>(null);
  const [fixedPatient, setFixedPatient] = useState<PatientModel | null>(null);
  const [fixedExam, setFixedExam] = useState<ExamModel | null>(null);
  const [uploadGroups, setUploadGroups] = useState<UploadGroup[]>([]);
  const [uploadState, setUploadState] = useState(UploadStateType.SELECTION);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [wizardSteps, setWizardSteps] = useState<WizardStepProps[]>(() => {
    const initialSteps = INITIAL_WIZARD_STEPS.map((step, index) => ({
      ...step,
      disabled: currentStepIndex !== index,
    }));
    return initialSteps;
  });
  const pipelineLength = useDataStream(uploadManager.streams.pipelineLength);
  const attachRequestsRef = useRef<BulkAttachFilesModel[]>([]);

  const currentStepKey = wizardSteps[currentStepIndex].stepKey;

  const isInitialized = allServices != null && (patientIdParam == null || fixedPatient != null) && (examIdParam == null || fixedExam != null);

  const initialize = useEvent(async () => {
    const results = await Promise.all([
      apiClient.servicesClient.getAllServices(),
      patientIdParam != null ? apiClient.patientClient.getPatient(patientIdParam) : new Promise<null>((resolve) => resolve(null)),
      examIdParam != null ? apiClient.exams.getExamById(examIdParam) : new Promise<null>((resolve) => resolve(null)),
    ]);

    uploadView.initialize(sessionLocation.id, results[1], results[2], results[0]);

    setAllServices(results[0]);
    setFixedPatient(results[1] ?? null);
    setFixedExam(results[2] ?? null);
  });

  const attachFiles = useEvent(async () => {
    // Note: This function is pretty sensitive to concurrency issues.  Because we are going to be periodically attaching files that
    // have been fully uploaded while other files are still being uploaded.  So this needs to be very careful in how it accesses the
    // array of attach requests.

    if (attachRequestsRef.current.length === 0) return;

    let attachRequests: BulkAttachFilesModel[] = [];

    try {
      // Swap the current attach requests with an empty array so we don't lose any files that become fully uploaded while the attach API request is in flight.
      attachRequests = attachRequestsRef.current;
      attachRequestsRef.current = [];

      await apiClient.filesClient.attachFiles(attachRequests, 'msal-required');
    } catch (ex) {
      // If an error occurs, we need to re-add the attach requests back to the list so that they can be retried.  Keep in mind that new files to attach may have
      // been added to the list while the API request was in flight.
      attachRequestsRef.current = [...attachRequests, ...attachRequestsRef.current];
      throw ex;
    }
  });

  const validateStep = useEvent((uploadGroups: UploadGroup[], stepKey: WizardStepKey) => {
    const newWizardSteps = wizardSteps.map((step) => {
      if (stepKey !== WizardStepKey.ReviewAndUpload && step.stepKey !== stepKey) return step;

      if (step.stepKey === WizardStepKey.SelectFiles) {
        const checkedCount = uploadGroups.filter((item) => item.checked).length;

        return {
          ...step,
          isValid: checkedCount > 0,
        };
      } else if (step.stepKey === WizardStepKey.Exams) {
        let checkedCount = 0,
          validCount = 0,
          validatingCount = 0,
          dirtyCount = 0,
          selectedPatientCount = 0,
          selectedExamCount = 0;

        for (const uploadGroup of uploadGroups) {
          checkedCount += uploadGroup.checked ? 1 : 0;
          validCount += uploadGroup.patientFormState.isValid ? 1 : 0;
          validatingCount += uploadGroup.patientFormState.isValidating ? 1 : 0;
          dirtyCount += uploadGroup.patientFormState.isDirty ? 1 : 0;
          selectedPatientCount += uploadGroup.patient != null ? 1 : 0;
          selectedExamCount += uploadGroup.exam != null ? 1 : 0;
        }

        return {
          ...step,
          isValid:
            validCount === checkedCount &&
            validatingCount === 0 &&
            dirtyCount === 0 &&
            selectedPatientCount === checkedCount &&
            selectedExamCount === checkedCount,
        };
      } else if (step.stepKey === WizardStepKey.Attachments) {
        return {
          ...step,
        };
      } else if (step.stepKey === WizardStepKey.ReviewAndUpload) {
        return {
          ...step,
        };
      }

      return step;
    });

    const reviewStepIndex = newWizardSteps.findIndex((step) => step.stepKey === WizardStepKey.ReviewAndUpload);
    const validStepsCount = newWizardSteps.filter((step) => step.isValid && step.stepKey !== WizardStepKey.ReviewAndUpload).length;

    newWizardSteps[reviewStepIndex] = {
      ...newWizardSteps[reviewStepIndex],
      isValid: validStepsCount === newWizardSteps.length - 1, // Offset by 1 because the review step is not included in the count.
    };

    setWizardSteps(newWizardSteps);

    return newWizardSteps.find((step) => step.stepKey === stepKey)?.isValid ?? false;
  });

  const beginUpload = useEvent(() => {
    const uploadContexts: InitialFileContext[] = [];
    const uploadSessionId = window.crypto.randomUUID();

    // Collect all files and attachments into a single array.
    for (const uploadGroup of uploadGroups) {
      // Don't upload unchecked items.
      if (!uploadGroup.checked) continue;

      const allFiles = [...uploadGroup.files, 'attachments-marker', ...uploadGroup.attachments];
      let isAttachment = false;

      // Exam files and attachments.
      for (const file of allFiles) {
        // Skip the attachments marker.  It only exists so that we can separate the files from the attachments but still have a single loop iterating over all of them together.
        if (typeof file === 'string') {
          isAttachment = true;
          continue;
        }

        if (uploadGroup.patient == null) throw new Error(`Property "patientId" is not set for file Id: ${file.fileId}.`);

        uploadContexts.push({
          uploadGroupId: uploadGroup.uploadGroupId,
          fileId: file.fileId,
          file: file.file,
          compress: true,
          containerName: isAttachment ? 'files' : 'incoming',
          metadata: {
            uploadSessionId,
            userId: currentUser.id,
            patientId: uploadGroup.patient.id,
            examId: uploadGroup.exam!.id!,
            suid: file.dicomData?.StudyInstanceUID ?? null,
          },
        });
      }
    }

    uploadManager.uploadFiles(uploadContexts);
    setUploadState(UploadStateType.UPLOADING);
  });

  const setStep = useEvent((target: WizardStepKey | 'next' | 'previous') => {
    let newStepIndex: number;

    if (target === 'next' || target === 'previous') {
      newStepIndex = currentStepIndex + (target === 'next' ? 1 : -1);

      if (newStepIndex >= wizardSteps.length || newStepIndex < 0) throw new Error('Cannot navigate back or forward from the current step.');
    } else {
      newStepIndex = wizardSteps.findIndex((step) => step.stepKey === target);
    }

    setWizardSteps((prev) => {
      const newWizardSteps = prev.map((step, index) => ({
        ...step,
        visited: step.visited || index === currentStepIndex,
        disabled: !(step.visited || index <= newStepIndex || index === currentStepIndex),
      }));

      return newWizardSteps;
    });
    setCurrentStepIndex(newStepIndex);
  });

  const handleNextStep = useEvent(() => setStep('next'));

  const handlePreviousStep = useEvent(() => setStep('previous'));

  const handleStepChange = useEvent((event: StepperChangeEvent) => {
    setStep(wizardSteps[event.value].stepKey);
  });

  /*useEventStream(uploadManager.fileScanner.streams.onParseComplete, async (event) => {
    if (event.dicomData == null) return;

    const uploadGroupId = uploadView.getUploadGroupId(event.fileId);

    if (uploadGroupId == null) return;

    dicomMatcher.enqueue({
      uploadGroupId: uploadGroupId,
      patientQuery: {
        locationId: sessionLocation.id,
        patientNumber: event.dicomData.PatientID,
        unosId: null,
        firstName: event.dicomData.PatientName[1],
        lastName: event.dicomData.PatientName[0],
        dob: event.dicomData.PatientBirthDate,
      },
      examQuery: {
        locationId: sessionLocation.id,
      },
    });

    dicomMatcher.run();
  });*/

  useEventStream(uploadView.streams.groups, (groups) => {
    if (allServices == null) throw new Error('Cannot proceed because allServices is null.');

    // We need to navigate into the stream to get the previous value because React batches useState updates and we need to ensure that we are working with the most recent state.
    /*const prev = uploadView.streams.groups.getCurrentValue();
    const newUploadGroups: UploadGroup[] = [];

    for (const group of groups) {
      const existingUploadGroup = prev.find((item) => item.uploadGroupId === group.uploadGroupId);

      if (existingUploadGroup == null) {
        newUploadGroups.push({
          ...group,
          checked: true,
          patient: fixedPatient,
          exam: fixedExam,
          patientForm: fixedPatient ? ExamUploadService.patientModelToForm(fixedPatient) : ExamUploadService.patientDicomToForm(group.dicomData),
          examForm: fixedExam ? ExamUploadService.examModelToForm(fixedExam, allServices) : ExamUploadService.examDicomToForm(group.dicomData, allServices),
          patientFormState: { isDirty: false, isValid: fixedPatient ? true : false, isValidating: false },
          examFormState: { isDirty: false, isValid: fixedExam ? true : false, isValidating: false },
        });
      } else {
        /*newUploadGroups.push({
          ...existingUploadGroup,
          ...group,
        });
      }
    }*/

    // ... Do some stuff with uploadGroups.

    setUploadGroups((prev) => {
      const newUploadGroups: UploadGroup[] = [];

      for (const group of groups) {
        const existingUploadGroup = prev.find((item) => item.uploadGroupId === group.uploadGroupId);

        if (existingUploadGroup == null) {
          newUploadGroups.push(group);
        } else {
          newUploadGroups.push({
            ...existingUploadGroup,
            ...group,
          });
        }
      }

      return newUploadGroups;
    });
  });

  useEventStream(uploadManager.blobUploader.streams.onComplete, async (event: UploadCompleteEvent) => {
    const uploadGroupIndex = ExamUploadService.findUploadGroupIndexByFileId(event.fileId, uploadGroups);
    const file = findOrThrow(
      uploadGroups[uploadGroupIndex].files,
      (item) => item.fileId === event.fileId,
      `Unable to find file with fileId: "${event.fileId}".`,
    );

    // We do NOT want to attach files that are DICOM.  The Compumed Gateway will manage attaching those.
    if (file.dicomData != null) {
      return;
    }

    const fileUrl = new URL(event.url);
    const fileType = fileUrl.pathname.slice(fileUrl.pathname.lastIndexOf('.') + 1).toUpperCase();

    // TODO: This needs to be more robust.  Especially because it's possible for the file to NOT have a file extension.
    if (!hasText(fileType)) {
      throw new Error('Unable to determine file type from URL.');
    }

    const fileAttach: BulkAttachFilesModel = {
      fileId: event.fileId,
      fileType: fileUrl.pathname.slice(fileUrl.pathname.lastIndexOf('.') + 1).toUpperCase(),
      fileName: 'TODO', // TODO: Implement this.
      location: `${fileUrl.protocol}://${fileUrl.host}${fileUrl.pathname}`,
      fileSize: event.uploadSize,
      examId: uploadGroups[uploadGroupIndex].exam!.id!,
    };

    attachRequestsRef.current.push(fileAttach);
  });

  useEffect(() => {
    initialize();

    return () => {
      uploadView.destroy();
    };
  }, [initialize, uploadManager, uploadView]);

  useInterval(
    () => {
      attachFiles();
    },
    // TODO: This is going to cause bugs and needs to be fixed before ship to production.  The problem is that the interval is going to be halted as soon as the final file is uploaded.  Which means the final file may not be attached because the system is no longer uploading.
    pipelineLength > 0 ? FILE_ATTACH_INTERVAL : 0, // Disable the interval when not uploading.
  );

  const uploadContext: UploadExamsPageContextType = useMemo(
    () => ({
      uploadState,
      allServices: allServices!, // Non-null assertion is safe because we are not rendering any consumers of the context until AFTER we have fetched the services.
      fixedPatient,
      fixedExam,
      uploadGroups,
      uploadView,
      setUploadGroups,
      setUploadState,
      setStep,
      validateStep,
      beginUpload,
      onNextStep: handleNextStep,
      onPreviousStep: handlePreviousStep,
    }),
    [uploadState, allServices, fixedPatient, fixedExam, uploadGroups, uploadView, setStep, validateStep, beginUpload, handleNextStep, handlePreviousStep],
  );

  if (!isInitialized) return null;

  return (
    <UploadExamsPageContext.Provider value={uploadContext}>
      <Page>
        <PageHeader title="Upload Exams" showSessionLocation />
        <StyledOutletContainer>
          <StyledStepperContainer>
            <Stepper
              items={wizardSteps}
              value={currentStepIndex}
              onChange={handleStepChange}
              item={WizardStep}
              disabled={uploadState !== UploadStateType.SELECTION}
            />
          </StyledStepperContainer>
          {currentStepKey === WizardStepKey.SelectFiles ? (
            <SelectFilesStep />
          ) : currentStepKey === WizardStepKey.Exams ? (
            <ExamsStep />
          ) : currentStepKey === WizardStepKey.Attachments ? (
            <AttachmentsStep />
          ) : currentStepKey === WizardStepKey.ReviewAndUpload ? (
            <ReviewAndUploadStep />
          ) : null}
        </StyledOutletContainer>
      </Page>
    </UploadExamsPageContext.Provider>
  );
});

UploadExamsPageInner.displayName = 'UploadExamsPageInner';

export const UploadExamsPage = memo(() => {
  return (
    <UploadManagerProvider>
      <UploadExamsPageInner />
    </UploadManagerProvider>
  );
});

UploadExamsPage.displayName = 'UploadExamsPage';

const StyledOutletContainer = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;

  .k-stepper {
    user-select: none;
  }

  .k-step-link {
    cursor: pointer;
  }

  .k-step-list-horizontal ~ .k-progressbar {
    top: calc((54px + 2 * 2px) / 2 + 2px / 2);
  }

  .k-step-list-vertical ~ .k-progressbar {
    left: calc((50px + 2 * 1px + 2 * 2px) / 2);
  }
`;

const StyledStepperContainer = styled.div`
  padding: ${({ theme }) => theme.space.spacing40} 0;

  .k-progressbar {
    --kendo-color-primary: ${({ theme }) => theme.colors.primary};
  }
`;
