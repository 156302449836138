import { map as _map } from 'lodash';

import { FormModel } from 'models';

import { EditFormValues } from '../type';

const EditFormDefaults: EditFormValues = {
  name: '',
  description: null,
  ui: null,
  data: null,
  reportTemplatePath: '',
  generateReport: null,
  autoSendReport: null,
  active: null,
  formFields: null,
};

function copyModelToForm(model: FormModel): EditFormValues {
  return {
    ...model,
  };
}

function copyFormToModel(formId: number, form: EditFormValues): FormModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...rest } = form;
  return {
    ...rest,
    id: formId,
  };
}

function createDefaultSource(): FormModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const AddUpdateFormService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
