import { normalize } from 'normalizr';

import { TatModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { TatRouteService } from '../services';
import { NormalizedTatModelSchema, tatEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'tats/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(TatRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<TatModel[]>;

    const normalized = normalize<TatModel, NormalizedTatModelSchema>(result, [
      tatEntity,
    ]);

    return normalized.entities;
  },
);

export const TatActions = {
  getAll,
};
