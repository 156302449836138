/* eslint-disable @typescript-eslint/no-unsafe-call */
import { DynamicFormFieldModel, FormFieldModel, FormModel } from 'models';

import { Field } from 'core/forms';
import { Input } from 'core/ui';

export const FormAccordian: React.FunctionComponent<{
  forms: FormModel[];
  values: DynamicFormFieldModel[];
}> = ({ forms, values }) => {
  return (
    <>
      {forms?.map((form: { formFields: FormFieldModel[] | null; id: number }, i: number) => {
        return form.formFields?.map((formField, j) => {
          const fieldName = `forms[${form.id}].${formField.field.name}`;
          const fieldLabel = formField.field.label ?? formField.field.name;
          const fieldDescription = formField.field.description;
          const value = values.find((f) => f.fieldId === formField.field.id)?.value;

          return <Field key={`${form.id}-${j}`} component={Input} defaultValue={value} label={fieldLabel} name={fieldName} description={fieldDescription} />;
        });
      })}
    </>
  );
};
