import { FunctionComponent, ReactNode } from 'react';

import { ApiClient } from 'core/api';

import { ApiClientProviderContext } from '../contexts';

export const apiClient = new ApiClient();

export const ApiClientProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <ApiClientProviderContext.Provider value={apiClient}>
      {children}
    </ApiClientProviderContext.Provider>
  );
};

ApiClientProvider.displayName = 'ApiClientProvider';
