import { useEffect, useRef } from 'react';

import { UseSpringProps, config as springPresets, useSpring, useSpringRef } from '@react-spring/web';
import useMeasure from 'react-use-measure';

import { useEvent } from 'core/hooks';

export function useDrawerAnimation(show: boolean) {
  // TODO: This should be refactored into something that is more reusable.  For example it could work for accordions or different orientations.

  const [measureTargetRef, { width }] = useMeasure();
  const springApi = useSpringRef();
  const currentShowState = useRef(show);

  const [drawerStyle] = useSpring(() => {
    // Initialization.
    const config: UseSpringProps = {
      ref: springApi,
      config: {
        ...springPresets.stiff,
        clamp: true,
      },
    };

    config.to = {
      width: show ? 'initial' : 0,
      height: 'initial',
    };

    return config;
  }, []);

  const handleShowChange = useEvent(() => {
    if (currentShowState.current === show) return;

    // Initialization.
    const config: UseSpringProps = {
      ref: springApi,
      config: {
        ...springPresets.stiff,
        clamp: true,
      },
    };

    config.from = {
      width: show ? 0 : width,
    };

    config.to = [
      {
        width: show ? width : 0,
      },
      {
        width: show ? 'initial' : 0,
      },
    ];

    springApi.start(config);
    currentShowState.current = show;
  });

  useEffect(() => {
    handleShowChange();
  }, [handleShowChange, show]);

  return {
    drawerStyle,
    measureTargetRef,
    handleShowChange,
  };
}
