import { FunctionComponent, useContext } from 'react';

import { Checkbox } from '@progress/kendo-react-inputs';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

import { MergePatientValueSelectContext } from '../contexts';
import { MergeStepDonorConfirmProps } from '../types';
import { MergePatientValueSelect } from './MergePatientValueSelect';

export const MergeStepDonorConfirm: FunctionComponent<MergeStepDonorConfirmProps> = ({
  srcSelectAll,
  destSelectAll,
  selectedFields,
  dateFormat,
  onSelectAll,
}) => {
  const mergePatientContext = useContext(MergePatientValueSelectContext);
  return (
    <StyledConfirmDonorDiv>
      <StyledTable striped bordered hover>
        <thead>
          <tr>
            <th>Field</th>
            <th>
              <StyledCheckbox value={srcSelectAll} onChange={(e) => onSelectAll(e, 'src')} />
              Donor 1
            </th>
            <th>
              <StyledCheckbox value={destSelectAll} onChange={(e) => onSelectAll(e, 'dest')} />
              Donor 2
            </th>
            <th>Final</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>UNOS ID</td>
            <MergePatientValueSelect fieldName="unosID" fieldValue={mergePatientContext.srcPatient?.unosID} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="unosID" fieldValue={mergePatientContext.destPatient?.unosID} sourceOrDest="dest"></MergePatientValueSelect>

            <td>{selectedFields?.unosID}</td>
          </tr>
          <tr>
            <td>MRN</td>
            <MergePatientValueSelect
              fieldName="patientNumber"
              fieldValue={mergePatientContext.srcPatient?.patientNumber}
              sourceOrDest="src"
            ></MergePatientValueSelect>
            <MergePatientValueSelect
              fieldName="patientNumber"
              fieldValue={mergePatientContext.destPatient?.patientNumber}
              sourceOrDest="dest"
            ></MergePatientValueSelect>
            <td>{selectedFields?.patientNumber}</td>
          </tr>
          <tr>
            <td>LAST NAME</td>
            <MergePatientValueSelect fieldName="lastName" fieldValue={mergePatientContext.srcPatient?.lastName} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="lastName" fieldValue={mergePatientContext.destPatient?.lastName} sourceOrDest="dest"></MergePatientValueSelect>

            <td>{selectedFields?.lastName}</td>
          </tr>
          <tr>
            <td>FIRST NAME</td>
            <MergePatientValueSelect fieldName="firstName" fieldValue={mergePatientContext.srcPatient?.firstName} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect
              fieldName="firstName"
              fieldValue={mergePatientContext.destPatient?.firstName}
              sourceOrDest="dest"
            ></MergePatientValueSelect>

            <td>{selectedFields?.firstName}</td>
          </tr>
          <tr>
            <td>DOB</td>
            <MergePatientValueSelect
              fieldName="dob"
              fieldValue={dayjs(mergePatientContext.srcPatient?.dob)
                .utc()
                .format(dateFormat)}
              sourceOrDest="src"
            ></MergePatientValueSelect>
            <MergePatientValueSelect
              fieldName="dob"
              fieldValue={dayjs(mergePatientContext.destPatient?.dob)
                .utc()
                .format(dateFormat)}
              sourceOrDest="dest"
            ></MergePatientValueSelect>
            <td>
              {dayjs(selectedFields?.dob)
                .utc()
                .format(dateFormat)}
            </td>
          </tr>
          <tr>
            <td>GENDER</td>
            <MergePatientValueSelect fieldName="gender" fieldValue={mergePatientContext.srcPatient?.gender} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="gender" fieldValue={mergePatientContext.destPatient?.gender} sourceOrDest="dest"></MergePatientValueSelect>
            <td>{selectedFields?.gender}</td>
          </tr>
          <tr>
            <td>Case ID</td>
            <MergePatientValueSelect fieldName="caseID" fieldValue={mergePatientContext.srcPatient?.caseID} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="caseID" fieldValue={mergePatientContext.destPatient?.caseID} sourceOrDest="dest"></MergePatientValueSelect>
            <td>{selectedFields?.caseID}</td>
          </tr>
          <tr>
            <td>HEIGHT (cm)</td>
            <MergePatientValueSelect fieldName="height" fieldValue={mergePatientContext.srcPatient?.height} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="height" fieldValue={mergePatientContext.destPatient?.height} sourceOrDest="dest"></MergePatientValueSelect>
            <td>{selectedFields?.height}</td>
          </tr>
          <tr>
            <td>WEIGHT (kg)</td>
            <MergePatientValueSelect fieldName="weight" fieldValue={mergePatientContext.srcPatient?.weight} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="weight" fieldValue={mergePatientContext.destPatient?.weight} sourceOrDest="dest"></MergePatientValueSelect>
            <td>{selectedFields?.weight}</td>
          </tr>
          <tr>
            <td>Hospital</td>
            <MergePatientValueSelect fieldName="hospital" fieldValue={mergePatientContext.srcPatient?.hospital} sourceOrDest="src"></MergePatientValueSelect>
            <MergePatientValueSelect fieldName="hospital" fieldValue={mergePatientContext.destPatient?.hospital} sourceOrDest="dest"></MergePatientValueSelect>
            <td>{selectedFields?.hospital}</td>
          </tr>
          <tr>
            <td>Cross Clamp Time</td>
            <MergePatientValueSelect
              fieldName="crossClampDateTime"
              fieldValue={
                mergePatientContext.srcPatient?.crossClampDateTime &&
                dayjs(mergePatientContext.srcPatient?.crossClampDateTime)
                  .utc()
                  .format(dateFormat)
              }
              sourceOrDest="src"
            ></MergePatientValueSelect>
            <MergePatientValueSelect
              fieldName="crossClampDateTime"
              fieldValue={
                mergePatientContext.destPatient?.crossClampDateTime &&
                dayjs(mergePatientContext.destPatient?.crossClampDateTime)
                  .utc()
                  .format(dateFormat)
              }
              sourceOrDest="dest"
            ></MergePatientValueSelect>
            <td>
              {selectedFields.crossClampDateTime &&
                dayjs(selectedFields?.crossClampDateTime)
                  .utc()
                  .format(dateFormat)}
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </StyledConfirmDonorDiv>
  );
};

MergeStepDonorConfirm.displayName = 'MergeStepDonorConfirm';

const StyledConfirmDonorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;
`;

const StyledTable = styled(Table)`
  table-layout: fixed;
  .td {
    word-wrap: break-word;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding-right: ${(props) => props.theme.space.spacing20};
`;
