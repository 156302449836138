import { FunctionComponent, useEffect, useState } from 'react';

import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Upload } from '@progress/kendo-react-upload';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

import { ApiRouteService } from 'core/api';
import { Button, ButtonVariants, Dialog } from 'core/ui';

import { useApiClient } from '../../api';
import { ITransplantModalProps } from '../types/ITransplantModalProps';

export const ITransplantModal: FunctionComponent<ITransplantModalProps> = ({
  onAddDonor,
  onUpdateDonor,
  onStatusChange,
  mergeDonorData,
  donorId,
  toggleITransplantDialog,
  visible,
}) => {
  const [accessToken, setAccessToken] = useState('');
  const apiClient = useApiClient();
  const dateFormat = 'MM/DD/YYYY';

  useEffect(() => {
    const updateAccessToken = async () => {
      if (apiClient.httpClient.getAccessToken !== null) {
        setAccessToken(await apiClient.httpClient.getAccessToken());
      }
    };
    if (visible) {
      updateAccessToken();
    }
  }, [apiClient, visible]);

  const saveUrl = donorId
    ? `${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/upload/compare/${donorId}`
    : `${ApiRouteService.getCompumedApiBaseRoute()}/api/patients/upload/compare-create/4902`;

  return (
    <>
      <StyledDialog title="Upload iTransplant" onClose={toggleITransplantDialog}>
        <p>Please select your iTransplant XML file</p>
        <Upload
          batch={false}
          multiple={false}
          defaultFiles={[]}
          onStatusChange={onStatusChange}
          saveHeaders={{ Authorization: `Bearer ${accessToken}` }}
          withCredentials={false}
          saveUrl={saveUrl}
        />
        {mergeDonorData && (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Current Data</th>
                  <th>Uploaded Data</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>UNOS ID</td>
                  <td>{mergeDonorData?.currentPatient?.unosID}</td>
                  <td>{mergeDonorData?.newPatient?.unosID}</td>
                </tr>
                <tr>
                  <td>LAST NAME</td>
                  <td>{mergeDonorData?.currentPatient?.lastName}</td>
                  <td>{mergeDonorData?.newPatient?.lastName}</td>
                </tr>
                <tr>
                  <td>FIRST NAME</td>
                  <td>{mergeDonorData?.currentPatient?.firstName}</td>
                  <td>{mergeDonorData?.newPatient?.firstName}</td>
                </tr>
                <tr>
                  <td>DOB</td>
                  <td>
                    {mergeDonorData?.currentPatient?.dob
                      ? dayjs(mergeDonorData?.currentPatient?.dob)
                          .utc()
                          .format(dateFormat)
                      : ''}
                  </td>
                  <td>
                    {dayjs(mergeDonorData?.newPatient?.dob)
                      .utc()
                      .format(dateFormat)}
                  </td>
                </tr>
                <tr>
                  <td>HEIGHT (cm)</td>
                  <td>{mergeDonorData?.currentPatient?.height?.toFixed(2)}</td>
                  <td>{mergeDonorData?.newPatient?.height?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>WEIGHT (kg)</td>
                  <td>{mergeDonorData?.currentPatient?.weight?.toFixed(2)}</td>
                  <td>{mergeDonorData?.newPatient?.weight?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>GENDER</td>
                  <td>{mergeDonorData?.currentPatient?.gender}</td>
                  <td>{mergeDonorData?.newPatient?.gender}</td>
                </tr>
                <tr>
                  <td>MRN/PATIENT ID</td>
                  <td>{mergeDonorData?.currentPatient?.patientNumber}</td>
                  <td>{mergeDonorData?.newPatient?.patientNumber}</td>
                </tr>
                <tr>
                  <td>HOSPITAL</td>
                  <td>{mergeDonorData?.currentPatient?.hospital}</td>
                  <td>{mergeDonorData?.newPatient?.hospital}</td>
                </tr>
                <tr>
                  <td>CROSS CLAMP DATE</td>
                  <td>{mergeDonorData?.currentPatient?.crossClampDateTime && dayjs(mergeDonorData?.currentPatient?.crossClampDateTime).format(dateFormat)}</td>
                  <td>{mergeDonorData?.newPatient?.crossClampDateTime && dayjs(mergeDonorData?.newPatient?.crossClampDateTime).format(dateFormat)}</td>
                </tr>
              </tbody>
            </Table>
            <StyledActionDiv>
              {!donorId && (
                <Button variant={ButtonVariants.SECONDARY} onClick={onAddDonor}>
                  New Donor
                </Button>
              )}
              <Button variant={ButtonVariants.SECONDARY} onClick={onUpdateDonor}>
                Update Donor
              </Button>
            </StyledActionDiv>
          </>
        )}
        <DialogActionsBar>
          <StyledDiv>
            <Button onClick={toggleITransplantDialog} variant={ButtonVariants.SECONDARY}>
              Close
            </Button>
          </StyledDiv>
        </DialogActionsBar>
      </StyledDialog>
    </>
  );
};

const StyledActionDiv = styled.div`
  display: flex;
  justify-content: start;
  gap: ${({ theme }) => theme.space.spacing50};
`;

const StyledDialog = styled(Dialog)`
  width: 50%;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
