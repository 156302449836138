import { memo, useEffect, useMemo, useState } from 'react';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FieldSettings, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import styled from 'styled-components';

import { useBoolean, useEvent } from 'core/hooks';
import { Button, Filter, Modal } from 'core/ui';

import { FilterModalProps } from '../types';

export const FilterModal = memo<FilterModalProps>(({ show, columns, initialFilter, onClose, onSave }) => {
  const [filter, setFilter] = useState<CompositeFilterDescriptor | null>(null);
  const [isContentVisibleOverride, { setTrue: setIsContentVisibleOverrideTrue, setFalse: setIsContentVisibleOverrideFalse }] = useBoolean(false);

  const isContentVisible = show || isContentVisibleOverride;

  const reset = useEvent(() => {
    setFilter(initialFilter);
  });

  const handleChange = useEvent((event: FilterChangeEvent) => {
    setFilter(event.filter);
  });

  const handleSaveClick = useEvent(() => {
    if (filter == null) {
      throw new Error('Filter cannot be null when saving.');
    }

    onSave(filter);
  });

  const fields: FieldSettings[] = useMemo(
    () =>
      columns
        .filter((column) => column.filter)
        .map((column) => ({
          name: column.field,
          label: column.title,
          filter: column.filter,
          operators: column?.operators ?? [],
        })),
    [columns],
  );

  // Reset the state when the modal switches between visible and hidden.
  useEffect(() => {
    if (isContentVisible) {
      reset();
    } else {
      setFilter(null);
    }
  }, [isContentVisible, reset]);

  if (filter == null) return null;

  return (
    <Modal show={show} onHide={onClose} title="FILTER" onEnter={setIsContentVisibleOverrideTrue} onExited={setIsContentVisibleOverrideFalse}>
      <StyledDialogBodyDiv>
        <Filter fields={fields} onChange={handleChange} value={filter} />

        <StyledActionButtonsDiv>
          <Button onClick={handleSaveClick}>Apply Filter</Button>
        </StyledActionButtonsDiv>
      </StyledDialogBodyDiv>
    </Modal>
  );
});

FilterModal.displayName = 'FilterModal';

const StyledDialogBodyDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  width: 550px;
  padding: 24px;
`;

const StyledActionButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 0 0;
`;
