import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { LocationTypeRoutes } from '../routes';
import { LocationTypeForm } from './LocationTypeForm';
import { LocationTypeHome } from './LocationTypeHome';

const LOCATION_TYPE_ADMIN_ROLES = [UserRoles.ADMIN];

export const LocationType: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={LocationTypeRoutes.HOME}
        element={
          <RouteGuard allowedRoles={LOCATION_TYPE_ADMIN_ROLES}>
            <LocationTypeHome />
          </RouteGuard>
        }
      />
      <Route
        path={LocationTypeRoutes.ADD}
        element={
          <RouteGuard allowedRoles={LOCATION_TYPE_ADMIN_ROLES}>
            <LocationTypeForm />
          </RouteGuard>
        }
      />
      <Route
        path={LocationTypeRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={LOCATION_TYPE_ADMIN_ROLES}>
            <LocationTypeForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
