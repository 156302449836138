import { useContext } from 'react';

import { DropdownNgContext } from './contexts';

export function useDropdownNgItem() {
  const context = useContext(DropdownNgContext);

  if (context == null) {
    throw new Error('useDropdownNgItem() must be used within a <DropdownNgContext.Provider>.');
  }

  return context;
}
