import { createSelector } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';

import { Worklist } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { WorklistFilterParsingService } from '../services';
import { worklistAdapter } from './reducer';

const rootSelector = (state: RootState) => state.worklist;

const {
  selectAll,
  selectTotal: getCount,
  selectIds: getAllIds,
} = worklistAdapter.getSelectors(rootSelector);

const getStatus = (state: RootState) => rootSelector(state).status;

const getAll: AppSelector<Worklist[]> = createSelector(
  [selectAll],
  (allWorklists) => {
    // convert filter and gridState to objects since they are stored in DB as strings
    const transformedWorklists = allWorklists.map((worklist) => {
      const parsedWorklist = { ...worklist };

      if (typeof parsedWorklist.dataState === 'string') {
        parsedWorklist.dataState = JSON.parse(
          parsedWorklist.dataState,
          WorklistFilterParsingService.dateTimeParser,
        );
      }

      if (typeof parsedWorklist.columnState === 'string') {
        parsedWorklist.columnState = JSON.parse(parsedWorklist.columnState);
      }

      return parsedWorklist;
    });

    const nestedWorklists = transformedWorklists.reduce<
      Record<string, Worklist>
    >((worklists, currentWorklist) => {
      if (!currentWorklist.parentViewId) {
        worklists[currentWorklist.id!] = currentWorklist;
      }

      return worklists;
    }, {});

    transformedWorklists.forEach((worklist) => {
      const parentId = worklist.parentViewId;

      if (parentId && nestedWorklists[parentId]) {
        if (nestedWorklists[parentId].children) {
          nestedWorklists[parentId] = {
            ...nestedWorklists[parentId],
            children: _sortBy(
              [...nestedWorklists[parentId].children!, worklist],
              'ordinal',
            ),
          };
        } else {
          nestedWorklists[parentId] = {
            ...nestedWorklists[parentId],
            children: [worklist],
          };
        }
      }
    });

    return _sortBy(Object.values(nestedWorklists), 'ordinal');
  },
);

const getSelectedWorklistId = (state: RootState) =>
  rootSelector(state).selectedWorklistId;

const getSelectedWorklist: AppSelector<Worklist | undefined> = createSelector(
  [getSelectedWorklistId, selectAll],
  (selectedWorklistId, allWorklists) => {
    const selected = allWorklists.find((w) => w.id === selectedWorklistId);

    if (selected == null) return undefined;

    let selectedWorklist = selected;

    if (typeof selectedWorklist?.dataState === 'string') {
      selectedWorklist = {
        ...selectedWorklist,
        dataState: JSON.parse(
          selectedWorklist.dataState,
          WorklistFilterParsingService.dateTimeParser,
        ),
      };
    }

    if (typeof selectedWorklist?.columnState === 'string') {
      selectedWorklist = {
        ...selectedWorklist,
        columnState: JSON.parse(selectedWorklist.columnState),
      };
    }

    return selectedWorklist;
  },
);

export const WorklistSelectors = {
  getAll,
  getAllIds,
  getCount,
  getSelectedWorklist,
  getStatus,
};
