import _orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ExamTypes } from '../../exam/constants';
import { AliveCoreObservationsAccordion } from './AliveCorObservationsAccordion';
import { AliveCorResultsAccordion } from './AliveCorResultsAccordion';
import { EKGResultsAccordion } from './EKGResultsAccordion';
import { PathologyObservationsAccordion } from './PathologyObservationsAccordion';
import { PathologyResultsAccordion } from './PathologyResultsAccordion';
import { PulmonologyObservationsAccordion } from './PulmonologyObservationsAccordion';
import { PulmonologyResultsAccordion } from './PulmonologyResultsAccordion';
import { RadiologyResultsAccordion } from './RadiologyResultsAccordion';

export const ResultsAccordion = ({ exam, isResultFormVisible, valueGetter, valueSetter, template, fieldName, textField, dataItemKey }) => {
  const examService = exam?.service?.description;

  if (exam == null) {
    return null;
  }

  if (ExamTypes.PULMONOLOGY.includes(examService)) {
    return (
      <>
        <PulmonologyObservationsAccordion eventKey="5" isFormDisabled={!isResultFormVisible} />
        <PulmonologyResultsAccordion eventKey="6" isFormVisible={isResultFormVisible} reads={_orderBy(exam?.reads, 'dateCreated', 'desc')} />
      </>
    );
  }

  if (ExamTypes.PATHOLOGY.includes(examService)) {
    return (
      <>
        <PathologyObservationsAccordion eventKey="5" isFormDisabled valueGetter={valueGetter} />
        <PathologyResultsAccordion
          isFormVisible={isResultFormVisible}
          location={exam?.location}
          organ={exam?.organ}
          valueGetter={valueGetter}
          valueSetter={valueSetter}
        />
      </>
    );
  }

  if (ExamTypes.EKG.includes(examService)) {
    return <EKGResultsAccordion eventKey="5" valueGetter={valueGetter} valueSetter={valueSetter} />;
  }

  if (ExamTypes.ALIVE_COR.includes(examService)) {
    return (
      <>
        <StyledHiddenButton type="submit" disabled aria-hidden="true" />
        <AliveCoreObservationsAccordion eventKey="5" valueGetter={valueGetter} valueSetter={valueSetter} />
        <AliveCorResultsAccordion eventKey="6" valueGetter={valueGetter} valueSetter={valueSetter} />
      </>
    );
  }

  return (
    <RadiologyResultsAccordion
      eventKey="5"
      reads={_orderBy(exam?.reads, 'dateCreated', 'desc')}
      isFormVisible={isResultFormVisible}
      valueSetter={valueSetter}
      template={template}
      fieldName={fieldName}
      textField={textField}
      dataItemKey={dataItemKey}
    />
  );
};

ResultsAccordion.displayName = 'ResultsAccordion';

const StyledHiddenButton = styled.button`
  display: none;
`;

ResultsAccordion.propTypes = {
  exam: PropTypes.shape({
    interpretation: PropTypes.string,
    location: PropTypes.shape({}),
    organ: PropTypes.number,
    reads: PropTypes.arrayOf(PropTypes.shape({})),
    service: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  isResultFormVisible: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
  template: PropTypes.arrayOf(PropTypes.shape({})),
  fieldName: PropTypes.string,
  textField: PropTypes.string,
  dataItemKey: PropTypes.string,
};

ResultsAccordion.defaultProps = {
  exam: null,
  template: null,
  fieldName: '',
  textField: '',
  dataItemKey: '',
};
