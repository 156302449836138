import { FunctionComponent, useCallback, useState } from 'react';

import {
  NumericTextBox,
  NumericTextBoxBlurEvent,
  NumericTextBoxFocusEvent,
  NumericTextBoxProps,
} from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { NumericInputProps } from './NumericInputProps';

export const NumericInput: FunctionComponent<NumericInputProps> = ({
  description,
  disabled,
  hint,
  inputType = 'text',
  isOptionalLabelShown = false,
  label,
  name,
  required,
  size = ComponentSizes.MEDIUM,
  valid,
  validationMessage,
  visited,
  onBlur,
  onFocus,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = Boolean(!isValidationMessageShown && hint);
  const hintId = isHintShown ? `${name}_hint` : '';
  const errorId = isValidationMessageShown ? `${name}_error` : '';
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

  const handleFocus = useCallback(
    (event: NumericTextBoxFocusEvent) => {
      setIsFocused(true);
      onFocus?.(event);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (event: NumericTextBoxBlurEvent) => {
      setIsFocused(false);

      onBlur?.(event);
    },
    [onBlur],
  );

  return (
    <>
      {label && (
        <Label
          description={description}
          editorId={name}
          editorValid={valid}
          editorDisabled={disabled}
          required={required}
          optional={isLabeledAsOptional}
        >
          {label}
        </Label>
      )}

      <StyledNumericTextBoxBase
        disabled={disabled}
        inputType={inputType}
        name={name}
        size={size}
        valid={valid}
        spinners={isFocused}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...rest}
      />

      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && (
        <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>
      )}
    </>
  );
};

type StyledElementProps = {
  theme: DefaultTheme;
  disabled?: boolean;
  valid?: boolean;
  value?: NumericTextBoxProps['value'];
  size?: NumericTextBoxProps['size'];
};

const resolvePalette = ({
  theme,
  disabled,
  valid,
  value,
}: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      borderActive: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      background: theme.colors.backgroundDisabled,
      backgroundActive: theme.colors.palette.white,
      icon: theme.colors.palette.grayscale[6],
      buttonBorderColor: theme.colors.palette.grayscale[3],
      caret: theme.colors.textPrimary,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    text: theme.colors.textPrimary,
    background: theme.colors.palette.white,
    backgroundActive: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[6],
    caret: theme.colors.textPrimary,
    buttonBorderColor: theme.colors.palette.grayscale[3],
  };

  if (!Number.isFinite(value)) {
    result.text = theme.colors.textSecondary;
  }

  if (!valid) {
    result.border = theme.colors.error;
    result.borderActive = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveActiveBackgroundColor = (props: StyledElementProps) => {
  const { backgroundActive } = resolvePalette(props);

  return backgroundActive;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({ theme, valid }: StyledElementProps) => {
  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveArrowButtonBorderColor = (props: StyledElementProps) => {
  const { buttonBorderColor } = resolvePalette(props);

  return buttonBorderColor;
};

const resolveArrowIconColor = (props: StyledElementProps) => {
  const { icon } = resolvePalette(props);

  return icon;
};

const resolveInputCaretColor = (props: StyledElementProps) => {
  const { caret } = resolvePalette(props);

  return caret;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing20}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing40}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing40}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing40}`;
  }
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const StyledNumericTextBoxBase = styled(NumericTextBox)`
  && .k-numeric-wrap {
    background-color: ${resolveBackgroundColor};
    color: ${resolveColor};
    border-color: ${resolveBorderColor};
    border-radius: ${({ theme }) => theme.radii.base};
    height: ${resolveHeight};
    font-size: ${resolveFontSize};
    line-height: ${resolveLineHeight};

    &:hover,
    &:focus,
    &:active,
    &.k-focus {
      background-color: ${resolveActiveBackgroundColor};
      border-color: ${resolveActiveBorderColor};
    }

    &:focus,
    &:active,
    &.k-focus {
      box-shadow: ${resolveActiveBoxShadow};
    }

    &.k-disabled {
      opacity: 1;
    }
  }

  & .k-numeric-wrap input {
    height: ${resolveHeight};
    caret-color: ${resolveInputCaretColor};
    padding: ${resolvePadding};
  }

  & .k-numeric-wrap .k-select {
    background-color: inherit;
    border-color: ${resolveArrowButtonBorderColor};

    & .k-link {
      background-color: transparent;

      & .k-icon {
        color: ${resolveArrowIconColor};
      }
    }

    & .k-link.k-link-increase {
      border-bottom-width: ${({ theme }) => theme.borderWidths.base};
      border-bottom-style: solid;
      border-bottom-color: ${resolveArrowButtonBorderColor};
    }
  }
`;
