import { useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEvent, useOutsideClick } from 'core/hooks';
import { ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, DateCell, HeaderCell, Page, PageHeader, Popup, TextCell, Toolbar } from 'core/ui';

import { ActivityLogModal } from '../../activity-log';
import { LocationActions, LocationSelectors } from '../redux';

const CONTEXT_MENU_ITEMS = {
  activityLogs: {
    text: 'Activity Logs',
  },
};

export const LocationHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locations = useSelector(LocationSelectors.getAllGrid);
  const total = useSelector(LocationSelectors.getTotalGrid);

  const gridPDFExport = useRef(null);
  const gridExcelExport = useRef(null);

  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuDataItem, setContextMenuDataItem] = useState(null);
  const [popupOffset, setPopupOffset] = useState({});
  const [selectedLocations, setSelectedLocations] = useState({});

  const popupRef = useOutsideClick(() => {
    if (isContextMenuOpen) {
      setIsContextMenuOpen(false);
    }
  });

  useEffect(() => {
    dispatch(LocationActions.getAllForKendoGrid(dataState));
  }, [dispatch, dataState]);

  const handleDataStateChange = (changeEvent) => {
    setDataState(changeEvent.dataState);
  };

  const debouncedChangeDataStateHandler = useMemo(() => debounce(handleDataStateChange, 500), []);

  useEffect(() => {
    return () => debouncedChangeDataStateHandler.cancel();
  }, [debouncedChangeDataStateHandler]);

  const handleExportPDFClick = () => {
    if (gridPDFExport.current !== null) {
      gridPDFExport.current.save();
    }
  };

  const handleExportExcelClick = async () => {
    if (gridExcelExport.current !== null) {
      const res = await dispatch(
        LocationActions.getAllForExport({
          skip: 0,
          take: total,
          filter: dataState.filter,
        }),
      );

      gridExcelExport.current.save(res.payload?.data);
    }
  };

  const handleAddNewClick = () => {
    navigate('/location/add');
  };

  const handleEditClick = useEvent((dataItem) => navigate(`/location/edit/${dataItem.id}`));

  const handleOnContextMenuSelect = () => {
    setIsActivityLogModalOpen(true);
    setIsContextMenuOpen(false);
  };

  const handleContextMenuOpen = (e, selectedDataItem) => {
    setContextMenuDataItem(selectedDataItem);
    setPopupOffset({ left: e.clientX, top: e.clientY });
    setIsContextMenuOpen(true);
  };

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-location',
        title: 'Edit Location',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  const grid = (
    <DataTable
      filterable
      sortable
      pageable={{ pageSizes: true }}
      total={total}
      data={locations}
      reorderable
      {...dataState}
      setSelectedState={setSelectedLocations}
      selectedState={selectedLocations}
      onDataStateChange={handleDataStateChange}
      onContextMenuOpen={handleContextMenuOpen}
      actions={gridActions}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPDFClick} />
      <GridColumn title="Action" filterable={false} sortable={false} headerCell={HeaderCell} cell={ActionListCell} width="80px" />
      <GridColumn cell={TextCell} field="name" filter="text" headerCell={HeaderCell} title="Name" width="200px" />
      <GridColumn cell={TextCell} field="code" filter="numeric" headerCell={HeaderCell} title="Code" width="80px" />
      <GridColumn cell={TextCell} field="user" filter="text" headerCell={HeaderCell} title="User" width="80px" />
      <GridColumn cell={TextCell} field="address1" filter="text" headerCell={HeaderCell} title="Address1" width="250px" />
      <GridColumn cell={TextCell} field="address2" filter="text" headerCell={HeaderCell} title="Address2" width="250px" />
      <GridColumn cell={TextCell} field="city" filter="text" headerCell={HeaderCell} title="City" width="150px" />
      <GridColumn cell={TextCell} field="state" filter="text" headerCell={HeaderCell} title="State" width="100px" />
      <GridColumn cell={TextCell} field="zip" filter="text" headerCell={HeaderCell} title="Zip" width="100px" />
      <GridColumn cell={TextCell} field="contAmt" filter="numeric" headerCell={HeaderCell} title="Cont Amount" width="100px" />
      <GridColumn cell={TextCell} field="contractType" filter="text" headerCell={HeaderCell} title="Contract Type" width="100px" />
      <GridColumn cell={DateCell} field="contractStart" filter="date" headerCell={HeaderCell} title="Contract Start" width="120px" />
      <GridColumn cell={DateCell} field="contractStop" filter="date" headerCell={HeaderCell} title="Contract Stop" width="120px" />
      <GridColumn cell={TextCell} field="phone" filter="text" headerCell={HeaderCell} title="Phone" width="100px" />
      <GridColumn cell={TextCell} field="phone2" filter="text" headerCell={HeaderCell} title="Phone 2" width="100px" />
      <GridColumn cell={TextCell} field="fax" filter="text" headerCell={HeaderCell} title="Fax" width="100px" />
      <GridColumn cell={TextCell} field="fax2" filter="text" headerCell={HeaderCell} title="Fax 2" width="100px" />
      <GridColumn cell={TextCell} field="email" filter="text" headerCell={HeaderCell} title="Email" width="100px" />
      <GridColumn cell={TextCell} field="email2" filter="text" headerCell={HeaderCell} title="Email 2" width="100px" />
    </DataTable>
  );

  return (
    <StyledPage>
      {isActivityLogModalOpen && <ActivityLogModal entityType="Location" id={contextMenuDataItem?.id} onClose={() => setIsActivityLogModalOpen(false)} />}
      <Popup onSelect={handleOnContextMenuSelect} isOpen={isContextMenuOpen} menuItems={CONTEXT_MENU_ITEMS} offset={popupOffset} ref={popupRef} />
      <PageHeader title="Locations" />
      <GridPDFExport ref={gridPDFExport}>{grid}</GridPDFExport>
      <ExcelExport ref={gridExcelExport}>{grid}</ExcelExport>
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  grid-template-rows: 0 min-content 1fr;
`;
