import { FunctionComponent, HTMLAttributes, useCallback, useMemo } from 'react';

import { RadioGroup as KendoRadioGroup } from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { Item } from './Item';
import { RadioGroupProps } from './RadioGroupProps';

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  data,
  dataItemKey = 'value',
  textField = 'label',
  description,
  disabled,
  hint,
  isOptionalLabelShown = false,
  label,
  layout = 'horizontal',
  name,
  onChange,
  required = false,
  valid,
  validationMessage,
  value,
  visited = false,
  ...rest
}) => {
  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = Boolean(!isValidationMessageShown && hint);
  const hintId = isHintShown ? `${name}_hint` : '';
  const errorId = isValidationMessageShown ? `${name}_error` : '';
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

  const validRadioGroupData = useMemo(() => filterData(data, dataItemKey, textField), [data, dataItemKey, textField]);

  const itemComponent: FunctionComponent<HTMLAttributes<HTMLLIElement>> = useCallback(
    ({ children, ...itemRest }) => (
      /* Make sure to override the onChange handler so that it comes directly from the parent.  Not sure why, but that was the way this was originally coded. (KDE 2023-11-17) */
      <Item {...itemRest} onChange={onChange}>
        {children}
      </Item>
    ),
    [onChange],
  );

  if (!validRadioGroupData?.length) {
    return null;
  }

  return (
    <>
      <Label description={description} editorDisabled={disabled} editorId={name} editorValid={valid} optional={isLabeledAsOptional} required={required}>
        {label}
      </Label>
      <StyledRadioGroupBase
        {...rest}
        data={validRadioGroupData}
        disabled={disabled}
        item={itemComponent}
        layout={layout}
        name={name}
        valid={valid}
        value={value}
      />

      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
    </>
  );
};

RadioGroup.displayName = 'RadioGroup';

type StyledElementProps = {
  theme: DefaultTheme;
  layout?: 'horizontal' | 'vertical';
};

const resolveLayout = (props: StyledElementProps) => {
  const { layout } = props;

  if (layout === 'horizontal') {
    return {
      alignItems: 'center',
      flexDirection: 'row',
    };
  }

  if (layout === 'vertical') {
    return {
      alignItems: 'flex-start',
      flexDirection: 'column',
    };
  }

  return {
    alignItems: 'center',
    flexDirection: 'row',
  };
};

const resolveItemsLayout = (props: StyledElementProps) => {
  const { alignItems } = resolveLayout(props);

  return alignItems;
};

const resolveFlexDirection = (props: StyledElementProps) => {
  const { flexDirection } = resolveLayout(props);

  return flexDirection;
};

const StyledRadioGroupBase = styled(KendoRadioGroup)`
  align-items: ${resolveItemsLayout};
  display: flex;
  flex-direction: ${resolveFlexDirection};

  && label {
    margin: 0;
  }
`;

function filterData<T>(data: T[] | null | undefined, dataItemKey: keyof T, textField: keyof T) {
  return data == null
    ? null
    : data
        .filter((item) => item[dataItemKey] != null && item[textField] != null)
        .map((item) => ({
          ...item,
          label: item[textField],
          value: item[dataItemKey],
        }));
}
