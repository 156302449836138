import {
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';

import { TemplateModel } from 'models/TemplateModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class TemplateClient {
  constructor(private httpClient: HttpClient) {
    this.getTemplateById = this.getTemplateById.bind(this);
    this.getTemplates = this.getTemplates.bind(this);
    this.getAllTemplatesForKendoGrid =
      this.getAllTemplatesForKendoGrid.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
  }

  public async getTemplateById(templateId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const template = await this.httpClient.fetchAndParse<TemplateModel>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Template/${encodeURIComponent(
          templateId,
        )}`,
        options,
      ),
    );
    return template;
  }

  public async getTemplates(type: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const templates = await this.httpClient.fetchAndParse<TemplateModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Template/GetAllTemplates?type=${encodeURIComponent(
          type,
        )}`,
        options,
      ),
    );
    return templates;
  }

  public async getAllTemplatesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<
      DataResult<TemplateModel>
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Template/grid?${queryStr}`,
        options,
      ),
    );
    return result;
  }

  public async createTemplate(template: TemplateModel) {
    const options = await this.httpClient.createStandardOptions(
      'POST',
      template,
    );
    const result = await this.httpClient.fetchAndParse<number>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Template`,
        options,
      ),
    );
    return result;
  }

  public async updateTemplate(template: TemplateModel) {
    const options = await this.httpClient.createStandardOptions(
      'PUT',
      template,
    );
    const result = await this.httpClient.fetch(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Template`,
        options,
      ),
    );
    return result;
  }
}
