import { GridColumn } from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  ButtonCell,
  ComponentSizes,
  DataTable,
  DataTableVariants,
  DateCell,
  Dialog,
  HeaderCell,
  HeaderCellSecondary,
  TextCell,
} from 'core/ui';

import UserIcon from '../assets/user.svg?react';

const SelectPatientModal = ({
  dataState,
  onClose,
  onCreateNew,
  onPatientSelect,
  patients,
  onDataStateChange,
  total,
}) => {
  return (
    <StyledDialog
      headerActionContent={<Button onClick={onCreateNew}>Create New</Button>}
      onClose={onClose}
      title="SELECT PATIENT"
      TitleIcon={UserIcon}
    >
      <DataTable
        pageable
        reorderable
        sortable
        total={total}
        data={patients}
        variant={DataTableVariants.SECONDARY}
        size={ComponentSizes.SMALL}
        {...dataState}
        onDataStateChange={onDataStateChange}
      >
        <GridColumn
          title=""
          filterable={false}
          sortable={false}
          headerCell={HeaderCell}
          cell={(cellProps) => (
            <ButtonCell
              onClick={() => onPatientSelect(cellProps.dataItem)}
              {...cellProps}
            />
          )}
          width="90px"
        />
        <GridColumn
          cell={TextCell}
          field="patientNumber"
          filterable={false}
          title="Patient ID"
          width="120px"
          headerCell={HeaderCellSecondary}
        />
        <GridColumn
          cell={TextCell}
          field="firstName"
          filterable={false}
          title="First Name"
          variant={DataTableVariants.SECONDARY}
          width="130px"
          headerCell={HeaderCellSecondary}
        />
        <GridColumn
          cell={TextCell}
          field="lastName"
          filterable={false}
          headerCell={HeaderCellSecondary}
          title="Last Name"
          variant={DataTableVariants.SECONDARY}
          width="130px"
        />
        <GridColumn
          cell={DateCell}
          field="dob"
          filterable={false}
          format="MM/DD/YYYY"
          headerCell={HeaderCellSecondary}
          title="DOB"
          variant={DataTableVariants.SECONDARY}
          width="110px"
        />
        <GridColumn
          cell={TextCell}
          field="age"
          filterable={false}
          headerCell={HeaderCellSecondary}
          title="Age"
          variant={DataTableVariants.SECONDARY}
          width="50px"
        />
        <GridColumn
          cell={TextCell}
          field="location"
          filterable={false}
          headerCell={HeaderCellSecondary}
          title="Location"
          variant={DataTableVariants.SECONDARY}
          width="200px"
        />
      </DataTable>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  bottom: 0;
  left: 0;
  top: unset;
  transform: unset;
`;

SelectPatientModal.propTypes = {
  dataState: PropTypes.shape({
    take: PropTypes.number,
    skip: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func,
  onCreateNew: PropTypes.func,
  onPatientSelect: PropTypes.func,
  patients: PropTypes.arrayOf(PropTypes.shape({})),
  onDataStateChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

SelectPatientModal.defaultProps = {
  onClose: undefined,
  onCreateNew: () => {},
  onPatientSelect: undefined,
  patients: [],
};

export { SelectPatientModal };
