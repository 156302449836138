import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import {
  DropDownListBlurEvent,
  DropDownListChangeEvent,
  DropDownListFilterChangeEvent,
  DropDownListFocusEvent,
  DropDownListPageChangeEvent,
} from '@progress/kendo-react-dropdowns';

import { Dropdown } from './Dropdown';
import { DropdownProps } from './DropdownProps';
import { DropdownWithValuesFieldHandle } from './DropdownWithValuesFieldHandle';

/**
 * There was a breaking change for v2.0.0 (2018-09-12) in the kendo-ui-react
 * component library for DropDownList and ComboBox components.
 * This change saw the removal of the 'valueField' prop which was in use before
 * the breaking change.  They provided a HOC example to add the functionality
 * back in.  This component is a re-write of that example so that it uses a
 * functional component and typescript.
 *
 * @example const DropDownListWithValueField = withValueField(DropDown);
 *
 * @see https://www.telerik.com/kendo-react-ui/components/changelogs/ui-for-react/#release-12856530
 * @see https://www.telerik.com/kendo-react-ui/components/dropdowns/dropdownlist/common-scenarios/#toc-using-data-fields-for-values
 */
export const DropdownWithValuesField = forwardRef<
  DropdownWithValuesFieldHandle,
  DropdownProps
>(
  (
    {
      data,
      valueField,
      value,
      defaultValue,
      onBlur,
      onFocus,
      onChange,
      onPageChange,
      onFilterChange,
      ...rest
    },
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const innerRef = useRef<any | undefined>();

    const itemFromValue = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (searchValue: any | null | undefined) => {
        if (valueField == null) return undefined;

        return Array.isArray(data)
          ? data.find((item) => item[valueField] === searchValue)
          : undefined;
      },
      [data, valueField],
    );

    const valueGetter = useCallback(() => {
      return innerRef.current == null ||
        valueField == null ||
        innerRef.current.value == null
        ? undefined
        : innerRef.current.value[valueField];
    }, [valueField]);

    const transformEvent: <T>(event: T) => T = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event) => {
        return {
          ...event,
          value: valueGetter(),
          target: ref,
        };
      },
      [ref, valueGetter],
    );

    const handleBlur = useCallback(
      (event: DropDownListBlurEvent) => {
        onBlur?.(transformEvent(event));
      },
      [onBlur, transformEvent],
    );

    const handleFocus = useCallback(
      (event: DropDownListFocusEvent) => {
        onFocus?.(transformEvent(event));
      },
      [onFocus, transformEvent],
    );

    const handleChange = useCallback(
      (event: DropDownListChangeEvent) => {
        onChange?.(transformEvent(event));
      },
      [onChange, transformEvent],
    );

    const handlePageChange = useCallback(
      (event: DropDownListPageChangeEvent) => {
        onPageChange?.(transformEvent(event));
      },
      [onPageChange, transformEvent],
    );

    const handleFilterChange = useCallback(
      (event: DropDownListFilterChangeEvent) => {
        onFilterChange?.(transformEvent(event));
      },
      [onFilterChange, transformEvent],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          get value() {
            return valueGetter();
          },
          get element() {
            return innerRef.current?.element;
          },
          get actionElement() {
            return innerRef.current?.actionElement;
          },
          get name() {
            return innerRef.current?.name;
          },
          get validity() {
            return innerRef.current?.validity;
          },
          get validityStyles() {
            return innerRef.current?.validityStyles;
          },
          get required() {
            return innerRef.current?.required;
          },
          focus: () => {
            innerRef.current?.focus?.();
          },
        };
      },
      [valueGetter],
    );

    return (
      <Dropdown
        ref={innerRef}
        {...rest}
        value={itemFromValue(value)}
        defaultValue={itemFromValue(defaultValue)}
        onBlur={onBlur === undefined ? undefined : handleBlur}
        onFocus={onFocus === undefined ? undefined : handleFocus}
        onChange={onChange === undefined ? undefined : handleChange}
        onPageChange={onPageChange === undefined ? undefined : handlePageChange}
        onFilterChange={
          onFilterChange === undefined ? undefined : handleFilterChange
        }
        data={data}
        valueField={valueField}
      />
    );
  },
);

DropdownWithValuesField.displayName = 'DropdownWithValuesField';
