import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { BodyProps } from './BodyProps';

export const Body: FunctionComponent<BodyProps> = ({ children }) => {
  return <StyledDivBody>{children}</StyledDivBody>;
};

Body.displayName = 'Body';

const StyledDivBody = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
  margin-top: 0;
`;
