import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { PatientStatus } from '../constants';
import { PatientActions } from './actions';

export const patientAdapter = createEntityAdapter();

export const reducer = createReducer(
  patientAdapter.getInitialState(),
  (builder) => {
    builder.addCase(
      PatientActions.getAllForKendoGrid.fulfilled,
      (draft, action) => {
        patientAdapter.removeAll(draft);

        if (action.payload.entities.patients) {
          patientAdapter.addMany(draft, action.payload.entities.patients);
        }

        draft.total = action.payload.total;
        draft.ids = action.payload.result;
        draft.status = PatientStatus.FETCHED;
      },
    );
    builder.addCase(PatientActions.getById.fulfilled, (draft, action) => {
      patientAdapter.upsertOne(draft, action.payload);
      draft.status = PatientStatus.FETCHED;
    });
    builder.addCase(PatientActions.add.fulfilled, (draft, action) => {
      patientAdapter.addMany(draft, action.payload.patients);
      draft.status = PatientStatus.ADDED;
    });
    builder.addCase(PatientActions.edit.fulfilled, (draft, action) => {
      patientAdapter.setMany(draft, action.payload.patients);
      draft.status = PatientStatus.UPDATED;
    });
  },
);
