import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { PhysicianRoutes } from '../routes';
import { PhysicianForm } from './PhysicianForm';
import { PhysicianHome } from './PhysicianHome';

const PHYSICIAN_ADMIN_ROLES = [UserRoles.ADMIN];

export const Physician = () => {
  return (
    <Routes>
      <Route
        path={PhysicianRoutes.HOME}
        element={
          <RouteGuard allowedRoles={PHYSICIAN_ADMIN_ROLES}>
            <PhysicianHome />
          </RouteGuard>
        }
      />
      <Route
        path={PhysicianRoutes.ADD}
        element={
          <RouteGuard allowedRoles={PHYSICIAN_ADMIN_ROLES}>
            <PhysicianForm />
          </RouteGuard>
        }
      />
      <Route
        path={PhysicianRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={PHYSICIAN_ADMIN_ROLES}>
            <PhysicianForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
