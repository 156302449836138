import { createSelector } from '@reduxjs/toolkit';

import { ExamModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { examAdapter } from './reducer';

const rootSelector = (state: RootState) => state.exams;

const { selectAll: getAll, selectTotal: getCount } =
  examAdapter.getSelectors(rootSelector);

const getStatus: AppSelector<string | null> = (state) =>
  rootSelector(state).status;

const getByIdOutput = (exams: ExamModel[], id: number | string | undefined) => {
  const compareId = typeof id === 'string' ? parseInt(id, 10) : id;
  return exams.find((e) => e.id === compareId);
};

const getById = (id: number | undefined): AppSelector<ExamModel | undefined> =>
  createSelector([getAll, () => id], getByIdOutput);

const getTotal: AppSelector<number> = (state) => rootSelector(state).total;

const getSendDocumentStatus: AppSelector<string | null> = (state) =>
  rootSelector(state).sendDocumentStatus;

const getPreviousExamId: AppSelector<number | undefined> = (state) => {
  const { recentlyViewedExams } = rootSelector(state);

  return recentlyViewedExams[recentlyViewedExams.length - 1];
};

export const ExamSelectors = {
  getAll,
  getById,
  getCount,
  getPreviousExamId,
  getSendDocumentStatus,
  getStatus,
  getTotal,
};
