import { RefObject, useEffect, useState } from 'react';

import {
  HistologyQualities,
  HistologyQualitiesUnknown,
} from '../../exam/constants';

export const useInterpretableState = (
  valueGetter: (arg1: string) => string,
  valueSetter: (arg1: string, arg2: object) => void,
  accordionBodyRef: RefObject<HTMLDivElement>,
) => {
  const [isUninterpretable, setIsUninterpretable] = useState(false);

  useEffect(() => {
    if (!accordionBodyRef?.current) return;
    const selectOptions = Array.from(
      accordionBodyRef.current?.querySelectorAll('label'),
    ).map((el) => el.htmlFor);

    const hasUninterpretable = selectOptions.some(
      (value) => valueGetter(value) === HistologyQualitiesUnknown,
    );

    setIsUninterpretable(
      hasUninterpretable ||
        valueGetter('Results.histologyQuality') ===
          HistologyQualities.PARTIALLY_UNINTERPRETABLE.value ||
        valueGetter('Results.histologyQuality') ===
          HistologyQualities.UNINTERPRETABLE.value,
    );

    if (hasUninterpretable) {
      if (
        valueGetter('Results.histologyQuality') !==
          HistologyQualities.PARTIALLY_UNINTERPRETABLE.value &&
        valueGetter('Results.histologyQuality') !==
          HistologyQualities.UNINTERPRETABLE.value
      ) {
        valueSetter(
          'Results.histologyQuality',
          HistologyQualities.PARTIALLY_UNINTERPRETABLE,
        );
      }
    }
  }, [accordionBodyRef, valueGetter, valueSetter]);

  return isUninterpretable;
};
