const fontFamiliesScale = ['Open Sans'];

export const fontFamilies = {
  scale: fontFamiliesScale,
  body: fontFamiliesScale[0],
  heading: fontFamiliesScale[0],
  code: fontFamiliesScale[0],
};

const fontSizesScale = [
  '0.75rem', // 0
  '0.875rem', // 1
  '1rem', // 2
  '1.25rem', // 3
  '1.5rem', // 4
  '1.875rem', // 5
  '2.375rem', // 6
];

export const fontSizes = {
  scale: fontSizesScale,
  footnote: fontSizesScale[0],
  body: fontSizesScale[1],
  subheading: fontSizesScale[2],
  heading2: fontSizesScale[3],
  heading1: fontSizesScale[4],
  headingLarge2: fontSizesScale[5],
  headingLarge1: fontSizesScale[6],
};

const fontWeightsScale = [400, 600, 700];

export const fontWeights = {
  scale: fontWeightsScale,
  normal: fontWeightsScale[0],
  semiBold: fontWeightsScale[1],
  bold: fontWeightsScale[2],
};

const lineHeightsScale = [
  '1.25rem',
  '1.375rem',
  '1.5rem',
  '1.75rem',
  '2rem',
  '2.375rem',
  '2.875rem',
];

export const lineHeights = {
  scale: lineHeightsScale,
  footnote: lineHeightsScale[0],
  body: lineHeightsScale[1],
  subheading: lineHeightsScale[2],
  heading2: lineHeightsScale[3],
  heading1: lineHeightsScale[4],
  headingLarge2: lineHeightsScale[5],
  headingLarge1: lineHeightsScale[6],
};

export type FontFamilies = typeof fontFamilies;
export type FontSizes = typeof fontSizes;
export type FontWeights = typeof fontWeights;
export type LineHeights = typeof lineHeights;
