import { memo } from 'react';

import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { useBoolean, useEvent } from 'core/hooks';
import { Accordion, Modal } from 'core/ui';

import { useCurrentUser } from 'features/auth';
import { DefaultColumnsState } from 'features/exam/constants';
import { DEFAULT_FILTER_DATA_STATE } from 'features/exam/services/exam-grid-service';

import { WorklistAddEditModalProps } from '../types';
import { WorklistFilterAccordionItem } from './WorklistFilterAccordionItem';
import { WorklistNameInput } from './WorklistNameInput';

export const WorklistAddEditModal = memo<WorklistAddEditModalProps>(
  ({ allWorklists, show, currentDataState, currentColumnsState, onClose, onCreateWorklist, onUpdateWorklist, onDeleteWorklist }) => {
    const { currentUser } = useCurrentUser(true);

    // This is used to ensure that the content remains visible while the modal is transitioning in/out.  This is particularly important
    // because the inner forms need to reset to an initial state when the modal is opened.  By conditionally rendering the modal contents
    // we effectively achieve a reset on close/open because React will instantiate new child components.
    const [isContentVisibleOverride, { setTrue: setIsContentVisibleOverrideTrue, setFalse: setIsContentVisibleOverrideFalse }] = useBoolean(false);

    const handleCreateWorklistClick = useEvent((name: string, parentViewId?: number | null) => {
      const newDataState = cloneDeep(currentDataState ?? DEFAULT_FILTER_DATA_STATE);
      const newColumnsState = cloneDeep(currentColumnsState ?? DefaultColumnsState);

      onCreateWorklist({
        id: 0,
        user: currentUser.email,
        name,
        parentViewId: parentViewId ?? null,
        ordinal: allWorklists.length + 1,
        dataState: JSON.stringify(newDataState),
        columnState: JSON.stringify(newColumnsState),
        oDataResourceString: toDataSourceRequestString(newDataState),
      });
    });

    return (
      <Modal
        show={show}
        onHide={onClose}
        title="WORKLIST"
        titleIcon={faEye}
        onEnter={setIsContentVisibleOverrideTrue}
        onExited={setIsContentVisibleOverrideFalse}
      >
        {(show || isContentVisibleOverride) && (
          <StyledDialogBodyDiv>
            <StyledAccordion alwaysOpen>
              {allWorklists
                .filter((w) => w.parentViewId == null)
                .map((worklist) => (
                  <WorklistFilterAccordionItem
                    key={worklist.id}
                    worklist={worklist}
                    allWorklists={allWorklists}
                    isTopLevel
                    currentDataState={currentDataState}
                    currentColumnsState={currentColumnsState}
                    onCreateWorklist={handleCreateWorklistClick}
                    onUpdateWorklist={onUpdateWorklist}
                    onDeleteWorklist={onDeleteWorklist}
                  />
                ))}
            </StyledAccordion>
            <StyledWorklistNameInput onSave={handleCreateWorklistClick} saveButtonText="Create Worklist" />
          </StyledDialogBodyDiv>
        )}
      </Modal>
    );
  },
);

WorklistAddEditModal.displayName = 'WorklistAddEditModal';

const StyledWorklistNameInput = styled(WorklistNameInput)`
  margin-left: 23px;
  margin-right: 27px;
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

const StyledDialogBodyDiv = styled.div`
  width: 650px;
  padding: 24px;
`;

const StyledAccordion = styled(Accordion)`
  max-height: 60vh;
  overflow: auto;
`;
