import { ExamTypes } from '../../exam/constants';

function IsPulmonology(examService) {
  return ExamTypes.PULMONOLOGY.includes(examService);
}

function IsPathology(examService) {
  return ExamTypes.PATHOLOGY.includes(examService);
}

function IsEKG(examService) {
  return ExamTypes.EKG.includes(examService);
}

function IsRadiology(examService) {
  return ExamTypes.RADIOLOGY.includes(examService);
}

export const ExamTypeService = {
  IsPulmonology,
  IsPathology,
  IsEKG,
  IsRadiology,
};
