import { FunctionComponent, useCallback, memo, useState } from 'react';

import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import styled from 'styled-components';

import { ExamModel, PatientModel } from 'models';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { Icon, Accordion } from 'core/ui';
import { hasText } from 'core/utils';
import { ReadRequestPatientFields } from './ReadRequestPatientFields';

export const ReadRequestPatientAccordion: FunctionComponent<{
  exam: ExamModel | undefined;
  isExpanded: boolean;
}> = memo(({ exam, isExpanded }) => {
  const locationType = exam?.location?.locationType?.name;
  const isOpo: boolean = locationType === 'OPO';
  const [patient, setPatientState] = useState<PatientModel | undefined>(exam?.patient);
  const unosId = hasText(patient?.unosID);
  const patientNumber = patient?.patientNumber;

  const setPatient = useCallback((newPatient: PatientModel | undefined) => {
    setPatientState(newPatient);
  }, []);

  const identifier = () => {
    if (isOpo) {
      if (!unosId) {
        return 'UNOS ID Missing!';
      } else {
        return patient?.unosID;
      }
    }
    return patient?.patientNumber;
  };

  return (
    <>
      <Accordion.Item eventKey="0">
        <Accordion.Header Icon={PatientIcon} title={locationType ? (isOpo ? 'Donor' : 'Patient') : 'Patient'}>
          {(unosId || patientNumber) && <StyledHighlightText missingUnos={isOpo && !unosId}>{identifier()}</StyledHighlightText>}
        </Accordion.Header>
        <BootstrapAccordion.Body>
          {patient && <ReadRequestPatientFields isOpo={isOpo} patient={patient} setPatient={setPatient} isExpanded={isExpanded}></ReadRequestPatientFields>}
        </BootstrapAccordion.Body>
      </Accordion.Item>
    </>
  );
});

ReadRequestPatientAccordion.displayName = 'ReadRequestPatientAccordion';

const PatientIcon: FunctionComponent = () => {
  return <StyledIcon fixedWidth={false} icon={faUser} />;
};

const StyledHighlightText = styled.div<{ missingUnos: boolean }>`
  background: ${({ missingUnos, theme }) => (missingUnos ? theme.colors.error : theme.colors.palette.grayscale[1])};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ missingUnos, theme }) => (missingUnos ? theme.colors.palette.white : theme.colors.palette.grayscale[9])};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;
