import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { ServiceGridModel, ServiceModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class ServicesClient {
  constructor(private httpClient: HttpClient) {
    this.getAllServices = this.getAllServices.bind(this);
    this.getAllServicesForKendoGrid = this.getAllServicesForKendoGrid.bind(this);
    this.createService = this.createService.bind(this);
    this.updateService = this.updateService.bind(this);
  }

  public async getAllServices() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const services = await this.httpClient.fetchAndParse<ServiceModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services`, options));
    return services;
  }

  public async getServiceSubtypes(serviceId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const services = await this.httpClient.fetchAndParse<ServiceModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services/${serviceId}/subtypes`, options),
    );
    return services;
  }

  public async getServicesByExam(examId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const services = await this.httpClient.fetchAndParse<ServiceModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services/exam/${examId}`, options),
    );
    return services;
  }

  public async getServicesByLocation(locationId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const services = await this.httpClient.fetchAndParse<ServiceModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services/location/${locationId}`, options),
    );
    return services;
  }

  public async getAllServicesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<ServiceGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services/grid?${queryStr}`, options),
    );
    return result;
  }

  public async getServiceById(serviceId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const destinationRoute = await this.httpClient.fetchAndParse<ServiceModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services/${encodeURIComponent(serviceId)}`, options),
    );
    return destinationRoute;
  }

  public async createService(service: ServiceModel) {
    const options = await this.httpClient.createStandardOptions('POST', service);
    const result = await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services`, options));
    return result;
  }

  public async updateService(service: ServiceModel) {
    const options = await this.httpClient.createStandardOptions('PUT', service);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Services`, options));
    return result;
  }
}
