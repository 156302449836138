import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  createStyledFormElement,
} from 'core/forms';
import { Button, Label, Switch } from 'core/ui';

import {
  NotificationsTabContentProps,
  NotificationsTabFormValues,
} from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const NotificationsTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof NotificationsTabFormValues) => any;
  onChange: (values: NotificationsTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const handleFormChange = useCallback(() => {
    onChange({
      emailNotificationSTATExam: valueGetter('emailNotificationSTATExam'),
      emailConfirmation: valueGetter('emailConfirmation'),
      emailConfirmationPDF: valueGetter('emailConfirmationPDF'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="emailNotificationSTATExam">
          Get Notified for STAT Exams
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="emailNotificationSTATExam"
            editorId="emailNotificationSTATExam"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

NotificationsTabContentInner.displayName = 'NotificationsTabContentInner';

export const NotificationsTabContent: FunctionComponent<
  NotificationsTabContentProps
> = ({ initialValues, onChange, onSubmit }) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <NotificationsTabContentInner
          valueGetter={valueGetter}
          onChange={onChange}
        />
      )}
    />
  );
};

NotificationsTabContent.displayName = 'NotificationsTabContent';
