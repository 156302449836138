import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query';
import { DateFilter, Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { GridCellProps, GridColumnProps, GridFilterCellProps } from '@progress/kendo-react-grid';
import { map as _map, cloneDeep, sortBy } from 'lodash';

import { WorklistViewModel } from 'models';

import { DEFAULT_DATA_TABLE_DATA_STATE, DateCell, DropdownFilterCell, SwitchCell, TextCell } from 'core/ui';
import { findOrThrow, hasText } from 'core/utils';

import { ExamStatusDropdownFilter, LocationTypeDropdownFilter } from 'features/filter';

import { DefaultColumnsState, Lateralities, Organs, PatientColumnsState } from '../constants';
import { StyledTATPercentageCell } from '../fragments/StyledTATPercentageCell';
import { ColumnDefinition, ColumnState, ExamGridLocalStorageState, FeatureView } from '../types';
import { SlaService } from './sla-service';

const digitsRegex = new RegExp(/^\d+$/);

function hasOnlyDigits(value: string | null | undefined) {
  return value == null ? false : digitsRegex.test(value);
}

function formatOrgan(value: unknown) {
  if (value == null) return value === null ? 'null' : 'undefined';

  const valueAsNumber = typeof value === 'number' ? value : typeof value === 'string' ? parseInt(value, 10) : Number.NaN;

  if (isNaN(valueAsNumber)) {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return value.toString();
  }

  const organ = Object.values(Organs).find((o) => o.value === valueAsNumber);

  return organ == null ? '' : organ.name;
}

function formatLaterality(value: unknown) {
  if (value == null) return value === null ? 'null' : 'undefined';

  const valueAsNumber = typeof value === 'number' ? value : typeof value === 'string' ? parseInt(value, 10) : Number.NaN;

  if (isNaN(valueAsNumber)) {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return value.toString();
  }

  const laterality = Object.values(Lateralities).find((l) => l.value === valueAsNumber);

  return laterality == null ? '' : laterality.name;
}

const organFilterData = _map(Organs);
const lateralityFilterData = _map(Lateralities);
const defaultDropdownFilterItem = {
  name: 'All',
  value: null,
};

// exam grid column collections
const examColumnCollections: ColumnDefinition[] = [
  {
    cell: TextCell,
    field: 'id',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'System Generated Number',
    title: 'Exam ID',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'numeric',
    search: true,
  },
  {
    cell: TextCell,
    field: 'serviceDescription',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Type of Test Procedure',
    title: 'Exam Type',
    width: '75px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: (props: GridCellProps) => <TextCell {...props} valueTransform={formatOrgan} />,
    field: 'organ',
    data: _map(Organs),
    dataItemKey: 'value',
    valueField: 'value',
    operators: KendoOperators.numeric,
    filterCell: (props: GridFilterCellProps) => <DropdownFilterCell {...props} dropdownData={organFilterData} defaultItem={defaultDropdownFilterItem} />,
    headerCellDescription: 'Part of the body',
    title: 'Organ',
    width: '90px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: (props: GridCellProps) => <TextCell {...props} valueTransform={formatLaterality} />,
    field: 'laterality',
    headerCellDescription: 'Side of the body part',
    title: 'Laterality',
    data: _map(Lateralities),
    filterCell: (props: GridFilterCellProps) => <DropdownFilterCell {...props} dropdownData={lateralityFilterData} defaultItem={defaultDropdownFilterItem} />,
    width: '90px',
    hideFromGrid: false,
  },
  {
    cell: TextCell,
    field: 'patientNumber',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient MRN',
    title: 'Patient ID',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'unosID',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'UNOS ID',
    title: 'UNOS ID',
    width: '70px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'fullName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Full name of the Patient',
    title: 'Patient Name',
    width: '110px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: DateCell,
    field: 'dob',
    filter: DateFilter,
    operators: KendoOperators.date,
    format: 'MM/DD/YYYY',
    headerCellDescription: "Patient's Date of Birth",
    title: 'DOB',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'date',
    search: true,
  },
  {
    cell: TextCell,
    field: 'age',
    headerCellDescription: 'Age of the patient',
    title: 'Age',
    width: '60px',
    hideFromGrid: false,
    search: false,
  },
  {
    cell: TextCell,
    field: 'locationCode',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Location code of the exam',
    title: 'Location Number',
    width: '60px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'locationName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Location of the exam',
    title: 'Location Name',
    width: '150px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'sla',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'Service Level Agreement of the Study',
    title: 'SLA',
    hideFromGrid: false,
    width: '60px',
    columnFilter: 'numeric',
  },
  {
    cell: DateCell,
    field: 'slaDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Due date of SLA',
    title: 'Due Date',
    hideFromGrid: false,
    width: '100px',
    columnFilter: 'date',
  },
  {
    cell: (props: GridCellProps) => <TextCell valueTransform={SlaService.formatMinutesDisplay} {...props} />,
    field: 'slaRemaining',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'Remaining time left before due date of exam',
    title: 'Remaining Time',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'numeric',
  },
  {
    cell: (props: GridCellProps) => <StyledTATPercentageCell valueTransform={SlaService.formatPercentDisplay} {...props} />,
    field: 'tatPercent',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'Percentage of SLA',
    title: 'SLA %',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'numeric',
  },
  {
    cell: TextCell,
    field: 'source',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Origin of the Exam',
    title: 'Source',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
  },
  {
    cell: DateCell,
    field: 'studyDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date and time stamp of the study',
    title: 'Study Date',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'date',
  },
  {
    cell: DateCell,
    field: 'uploadedDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date and time stamp when study is uploaded',
    title: 'Received Date',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'date',
  },
  {
    cell: DateCell,
    field: 'requestedDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'When the read was requested.',
    title: 'Requested Date',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'date',
  },
  {
    cell: DateCell,
    field: 'assignedDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date and time when the exam was assigned',
    title: 'Assigned Date',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'date',
  },
  {
    cell: TextCell,
    field: 'physicianLastName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Name of the Physician.',
    title: 'Physician',
    hideFromGrid: false,
    width: '100px',
    columnFilter: 'text',
  },
  {
    cell: DateCell,
    field: 'returnedDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date and time when exam was returned by the Doctor.',
    title: 'Dr Return Date',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'date',
  },
  {
    cell: DateCell,
    field: 'completedDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date and time when exam was sent to the customer.',
    title: 'Sent to Customer',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'date',
  },
  {
    cell: TextCell,
    field: 'customerUID',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Customer Unique Identification',
    title: 'Customer UID',
    hideFromGrid: false,
    width: '100px',
    columnFilter: 'text',
  },
  {
    cell: TextCell,
    field: 'suid',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Study Instance UID',
    title: 'SUID',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'description',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Study Description',
    title: 'Study Description',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'imageCount',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'Image Count',
    title: 'Image Count',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'numeric',
    search: false,
  },
  {
    cell: TextCell,
    field: 'serialNumber',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Serial Number of the device that used in exam',
    title: 'Serial Number',
    hideFromGrid: false,
    width: '100px',
    columnFilter: 'text',
  },
  {
    cell: TextCell,
    field: 'cartID',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Cart ID',
    title: 'Cart ID',
    hideFromGrid: false,
    width: '60px',
    columnFilter: 'text',
  },
  {
    cell: TextCell,
    field: 'device',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Device name that used in exam',
    title: 'Device',
    hideFromGrid: false,
    width: '60px',
    columnFilter: 'text',
  },
  {
    cell: TextCell,
    field: 'oldCode',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Device code of used in the exam',
    title: 'Device Code',
    hideFromGrid: false,
    width: '60px',
    columnFilter: 'text',
  },
  {
    cell: TextCell,
    field: 'statusType',
    headerCellDescription: 'Current status of the exam',
    hideFromGrid: false,
    title: 'Status',
    width: '75px',
  },
  {
    cell: TextCell,
    field: 'locationType',
    headerCellDescription: 'Location Type of the exam',
    hideFromGrid: false,
    title: 'Location Type',
    width: '100px',
  },
  {
    cell: TextCell,
    field: 'statusTypeId',
    filter: ExamStatusDropdownFilter,
    operators: [
      { text: 'filter.eqOperator', operator: 'eq' },
      { text: 'filter.notEqOperator', operator: 'neq' },
    ],
    headerCellDescription: 'Current status of the exam',
    hideFromGrid: true,
    title: 'Status',
  },
  {
    cell: TextCell,
    field: 'locationTypeId',
    filter: LocationTypeDropdownFilter,
    operators: [
      { text: 'filter.eqOperator', operator: 'eq' },
      { text: 'filter.notEqOperator', operator: 'neq' },
    ],
    headerCellDescription: 'Location Type of the exam',
    hideFromGrid: true,
    title: 'Location Type',
  },
  {
    cell: TextCell,
    field: 'reportStatus',
    filter: TextFilter,
    headerCellDescription: 'Auto Send Final Report Status',
    title: 'Report Status',
    width: '75px',
    hideFromGrid: false,
  },
  {
    cell: SwitchCell,
    field: 'share',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Share study',
    title: 'Share',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
  },
  {
    cell: TextCell,
    field: 'notes',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Date and time stamp of the study',
    title: 'Notes',
    hideFromGrid: false,
    columnFilter: 'text',
    filterable: false,
  },
  {
    cell: TextCell,
    field: 'description',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Type of Test Procedure',
    title: 'Description',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: DateCell,
    field: 'uploadDate',
    filter: DateFilter,
    operators: KendoOperators.date,
    headerCellDescription: 'Date and time stamp when the exam is uploaded',
    title: 'Upload Date',
    hideFromGrid: false,
    width: '90px',
    columnFilter: 'date',
  },
  {
    cell: SwitchCell,
    field: 'active',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Exam is accessible',
    title: 'Active',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
    show: false,
  },
  {
    cell: TextCell,
    field: 'tags',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Tags',
    title: 'Tags',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'requestReportDestination',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Email Destinations for Report',
    title: 'Report Destination',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    filterable: false,
  },
  {
    cell: TextCell,
    field: 'hospital',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Hospital',
    title: 'Hospital',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    filterable: false,
  },
  {
    cell: TextCell,
    field: 'services',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Services',
    title: 'Services',
    width: '100px',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
];

// define columns for each features
const examColumns = [
  'id',
  'serviceDescription',
  'organ',
  'laterality',
  'patientNumber',
  'unosID',
  'fullName',
  'dob',
  'locationCode',
  'locationName',
  'sla',
  'slaDate',
  'slaRemaining',
  'tatPercent',
  'source',
  'studyDate',
  'uploadedDate',
  'requestedDate',
  'assignedDate',
  'physicianLastName',
  'returnedDate',
  'completedDate',
  'customerUID',
  'serialNumber',
  'suid',
  'description',
  'imageCount',
  'cartID',
  'device',
  'oldCode',
  'statusType',
  'locationType',
  'statusTypeId',
  'locationTypeId',
  'reportStatus',
  'active',
  'tags',
  'requestReportDestination',
  'services',
  'hospital',
];
const examColumnMergeDonor = ['id', 'serviceDescription', 'description', 'locationName', 'uploadedDate', 'studyDate', 'sla', 'returnedDate', 'notes'];
const examColumnShare = [
  'id',
  'serviceDescription',
  'organ',
  'laterality',
  'description',
  'customerUID',
  'studyDate',
  'uploadDate',
  'requestedDate',
  'sla',
  'completedDate',
  'notes',
];
const examColumnsDonor = [
  'id',
  'serviceDescription',
  'organ',
  'laterality',
  'description',
  'customerUID',
  'studyDate',
  'uploadedDate',
  'requestedDate',
  'sla',
  'completedDate',
  'locationName',
  'notes',
  'share',
];
const examColumnsPhysicianDashboard = [
  'id',
  'serviceDescription',
  'organ',
  'laterality',
  'description',
  'customerUID', // customerUID: dicomData.AccessionNumber,
  'unosID',
  'patientNumber',
  'fullName',
  'age',
  'dob',
  'locationCode',
  'locationName',
  'studyDate',
  'uploadDate',
  'sla',
  'slaRemaining',
  'tatPercent',
  // due time
  'assignedDate',
  // read time
  'notes',
];

function getColumnWidth(collection: string, item: ColumnDefinition) {
  if (collection === 'examColumnsPhysicianDashboard')
    switch (item.field) {
      case 'serviceDescription':
        return '150px';
      case 'fullName':
        return '250px';
      case 'locationCode':
        return '100px';
      case 'locationName':
        return '250px';
      case 'customerUID':
        return '120px';
    }
  return item.width;
}

// get the columns of each features
function getDefineColumn(columns: string[], collection: string) {
  const finalColumns: ColumnDefinition[] = [];

  // loop to each columns
  columns.forEach((column) => {
    // find item to the collections
    finalColumns.push(
      findOrThrow(
        examColumnCollections,
        (item) => {
          item.width = getColumnWidth(collection, item);
          return item.field === column;
        },
        `Could not find column definition for: "${column}".`,
      ),
    );
  });

  return finalColumns;
}

// assigned the column for each feature
const EXAM_COLUMNS = getDefineColumn(examColumns, 'examColumnCollections');
const EXAM_COLUMNS_MERGE_DONOR = getDefineColumn(examColumnMergeDonor, 'examColumnCollections');
const EXAM_COLUMNS_SHARE = getDefineColumn(examColumnShare, 'examColumnCollections');
const EXAM_COLUMNS_DONOR = getDefineColumn(examColumnsDonor, 'examColumnCollections');
const EXAM_COLUMNS_PHYSICIAN_DASHBOARD = getDefineColumn(examColumnsPhysicianDashboard, 'examColumnsPhysicianDashboard');

const DEFAULT_FILTER: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [],
};

export const DEFAULT_FILTER_DATA_STATE: State & { filter: NonNullable<State['filter']>; sort: { field: string; dir: 'asc' | 'desc' }[] } = {
  ...DEFAULT_DATA_TABLE_DATA_STATE,
  filter: DEFAULT_FILTER,
  skip: 0,
  sort: [{ field: 'id', dir: 'desc' }],
  take: 30,
};

function getColumns() {
  return EXAM_COLUMNS;
}

function getColumnsDonor() {
  return EXAM_COLUMNS_DONOR;
}

function getColumnsDonorShare() {
  return EXAM_COLUMNS_SHARE;
}

function getColumnsMergeDonor() {
  return EXAM_COLUMNS_MERGE_DONOR;
}

function getColumnsPhysicianDashboard() {
  return EXAM_COLUMNS_PHYSICIAN_DASHBOARD;
}

function getGridColumns() {
  return sortBy(
    EXAM_COLUMNS.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getGridColumnsDonor() {
  return sortBy(
    EXAM_COLUMNS_DONOR.filter((column) => !column.hideFromGrid),
    (column) => PatientColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getGridColumnsDonorShare() {
  return sortBy(
    EXAM_COLUMNS_SHARE.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getGridColumnsMergeDonor() {
  return sortBy(
    EXAM_COLUMNS_MERGE_DONOR.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getGridColumnsPhysicianDashboard() {
  return sortBy(
    EXAM_COLUMNS_PHYSICIAN_DASHBOARD.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getColumnState(allColumnStates: Record<string, ColumnState>, columnField: string, featureView: FeatureView = 'Exam'): ColumnState {
  let orderIndex = 0;
  let show = false;

  if (featureView === 'Patient') {
    orderIndex = allColumnStates[columnField]?.orderIndex ?? PatientColumnsState[columnField]?.orderIndex ?? 0;
    show = allColumnStates[columnField]?.show ?? PatientColumnsState[columnField]?.show ?? false;
  } else {
    orderIndex = allColumnStates[columnField]?.orderIndex ?? DefaultColumnsState[columnField]?.orderIndex ?? 0;
    show = allColumnStates[columnField]?.show ?? DefaultColumnsState[columnField]?.show ?? false;
  }
  return { orderIndex, show };
}

function updateColumnsOrder(allColumnStates: Record<string, ColumnState>, orderedColumns: GridColumnProps[]) {
  const newColumnsState = cloneDeep(allColumnStates);

  orderedColumns.forEach((column) => {
    if (column.field && newColumnsState[column.field]) {
      newColumnsState[column.field].orderIndex = column.orderIndex ?? 0;
    }
  });

  return newColumnsState;
}

/** Saves the exam grid state in localStorage.  Specifying null or undefined will delete the state. */
function saveExamGridState(gridState: ExamGridLocalStorageState | null | undefined) {
  if (gridState == null) localStorage.removeItem('examGridState');
  else localStorage.setItem('examGridState', JSON.stringify(gridState));
}

/** Retrieve the stored exam grid state from localStorage. */
function fetchExamGridState(): ExamGridLocalStorageState | null {
  const existing = localStorage.getItem('examGridState');
  return existing == null ? null : JSON.parse(existing, reviver);
}

function getSearchFilter(searchText: string | null | undefined): CompositeFilterDescriptor {
  const searchColumns = EXAM_COLUMNS.filter(({ search }) => search); // include only columns allowed in omni search
  const textFields = searchColumns.filter(({ filter }) => filter === TextFilter);
  const numericFields = searchColumns.filter(({ filter }) => filter === NumericFilter);

  if (hasOnlyDigits(searchText)) {
    return {
      filters: numericFields.map(({ field }) => ({
        field,
        operator: 'eq',
        value: searchText,
      })),
      logic: 'or',
    };
  }
  return {
    filters: textFields.map(({ field }) => ({
      field,
      operator: 'contains',
      value: searchText,
    })),
    logic: 'or',
  };
}

function addFiltersToDataState(dataState: State, newFilters: (FilterDescriptor | CompositeFilterDescriptor)[], logic: 'and' | 'or' = 'and'): State {
  return {
    ...dataState,
    filter: {
      logic,
      filters: [...(dataState?.filter ? dataState.filter.filters : []), ...newFilters],
    },
  };
}

function getColumnDefinition(field: string) {
  return findOrThrow(examColumnCollections, (column) => column.field === field, `Could not find column definition for: "${field}".`);
}

function parseDataState(stringifiedDataState: string | null | undefined): State | null {
  if (stringifiedDataState == null) return null;

  if (!hasText(stringifiedDataState)) throw new Error('Cannot parse data state because the stringified DataState is an empty string.');

  return JSON.parse(stringifiedDataState, reviver) as State;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reviver(_key: string, value: any) {
  // convert date times to date objects to prevent breaking loading date filter
  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;

  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

function parseColumnsState(stringifiedColumnsState: string | null | undefined): Record<string, ColumnState> | null {
  if (stringifiedColumnsState == null) return null;

  if (!hasText(stringifiedColumnsState)) throw new Error('Cannot parse columns state because the stringified ColumnsState is an empty string.');

  return JSON.parse(stringifiedColumnsState) as Record<string, ColumnState>;
}

function initializeExamGridState(allWorklists: WorklistViewModel[], pickDefaultWorklist?: (allWorklists: WorklistViewModel[]) => WorklistViewModel | null) {
  const defaultDataState = cloneDeep(DEFAULT_FILTER_DATA_STATE);
  const defaultColumnsState = cloneDeep(DefaultColumnsState);

  const restoredGridState = fetchExamGridState();

  let resetLocalStorage = false;
  let newSelectedWorklist: WorklistViewModel | null;
  let newDataState: State;
  let newColumnsState: Record<string, ColumnState>;
  let newSearchValue: string;

  if (restoredGridState) {
    // The user has previously customized the grid state.  Use the grid state from local storage.

    if (restoredGridState.worklistViewId != null) {
      // Re-select the previously selected worklist if possible.

      newSelectedWorklist = allWorklists.find((w) => w.id === restoredGridState.worklistViewId) ?? null;

      if (newSelectedWorklist == null) {
        // The previously selected worklist is no longer available.  Clear the grid state from local storage and start everything fresh.
        resetLocalStorage = true;
        newDataState = defaultDataState;
        newColumnsState = defaultColumnsState;
        newSearchValue = '';
      } else {
        // The previously selected worklist is still available.

        // Prefer to use the data state and column state from local storage instead of from the worklist definition because the user
        // may have customized the grid state.  The data state and columns state will only be persisted in local storage if the user
        // has customized the grid state.  They will reset to null when the user manually clicks on a different worklist.
        newDataState = restoredGridState.dataState ?? parseDataState(newSelectedWorklist?.dataState) ?? defaultDataState;
        newColumnsState = restoredGridState.columnsState ?? parseColumnsState(newSelectedWorklist?.columnState) ?? defaultColumnsState;
        newSearchValue = hasText(restoredGridState.search) ? restoredGridState.search : '';
      }
    } else {
      // The user has not selected a worklist, but they have previously modified the grid state so restore that.
      newSelectedWorklist = null;
      newDataState = restoredGridState.dataState ?? defaultDataState;
      newColumnsState = restoredGridState.columnsState ?? defaultColumnsState;
      newSearchValue = hasText(restoredGridState.search) ? restoredGridState.search : '';
    }
  } else {
    // The user has not customized the grid state at all.  Pre-select the first worklist available and then use the system defaults as a fallback.
    newSelectedWorklist = pickDefaultWorklist?.(allWorklists) ?? allWorklists.at(0) ?? null;
    newDataState = parseDataState(newSelectedWorklist?.dataState) ?? defaultDataState;
    newColumnsState = parseColumnsState(newSelectedWorklist?.columnState) ?? defaultColumnsState;
    newSearchValue = '';
  }

  if (resetLocalStorage) saveExamGridState(null);

  // Fall back in case the paging states ('skip' and 'take') are not present in the data state.
  if (newDataState.skip == null) {
    newDataState.skip = defaultDataState.skip;
  }
  if (newDataState.take == null) {
    newDataState.take = defaultDataState.take;
  }

  return {
    newSelectedWorklist,
    newDataState,
    newColumnsState,
    newSearchValue,
  };
}

function updateStateFromWorklist(newSelectedWorklistId: number | null, allWorklists: WorklistViewModel[], currentDataState: State | null) {
  const defaultDataState = cloneDeep(DEFAULT_FILTER_DATA_STATE);
  const defaultColumnsState = cloneDeep(DefaultColumnsState);

  let newDataState: State;
  let newColumnsState: Record<string, ColumnState>;
  let newSearchValue: string;

  if (newSelectedWorklistId == null) {
    // The user cleared their selection.  Reset everything to system defaults.
    newDataState = defaultDataState;
    newColumnsState = defaultColumnsState;
    newSearchValue = '';

    saveExamGridState(null);
  } else {
    // The user has selected a specific worklist.  Use the data state and column state from the worklist definition.
    const selectedWorklist = allWorklists?.find((w) => w.id === newSelectedWorklistId);

    newDataState = parseDataState(selectedWorklist?.dataState) ?? defaultDataState;
    newColumnsState = parseColumnsState(selectedWorklist?.columnState) ?? defaultColumnsState;
    newSearchValue = '';

    saveExamGridState({
      worklistViewId: newSelectedWorklistId,
      // Don't persist the data, columns, and searchValue state in localStorage because that is really only intended for ad-hoc customizing the grid state, not for
      // permanent worklist definitions.  By default we want to show the worklist as it was defined, even if it is updated by another user.
      dataState: null,
      columnsState: null,
      search: null,
    });
  }

  // Make sure to add paging options to the data state if they are not already present.
  if (newDataState.skip == null || newDataState.take == null) {
    const fallbackPagingOptions =
      currentDataState?.skip == null || currentDataState?.take == null ? defaultDataState : { skip: currentDataState.skip, take: currentDataState.take };

    newDataState.skip = fallbackPagingOptions.skip ?? undefined;
    newDataState.take = fallbackPagingOptions.take ?? undefined;
  }

  // Make sure to add sort options to the data state if they are not already present.
  if (newDataState.sort == null || newDataState.sort.length === 0) {
    newDataState.sort = defaultDataState.sort;
  }

  return {
    newDataState,
    newColumnsState,
    newSearchValue,
  };
}

/** Takes the specified data state and returns a modified copy that handles the generic search string filter as well as ensure that the results are paged. */
function getQueryDataState(dataState: State, searchValue: string) {
  const queryDataState = addFiltersToDataState(dataState, [getSearchFilter(searchValue)]);

  // In case the query somehow doesn't have paging options, add them.
  if (queryDataState.skip == null) {
    queryDataState.skip = DEFAULT_FILTER_DATA_STATE.skip;
  }
  if (queryDataState.take == null) {
    queryDataState.take = DEFAULT_FILTER_DATA_STATE.take;
  }

  return queryDataState;
}

export const ExamsGridService = {
  addFiltersToDataState,
  getColumns,
  getColumnsDonor,
  getColumnsDonorShare,
  getColumnsMergeDonor,
  getColumnsPhysicianDashboard,
  getColumnState,
  getGridColumns,
  getGridColumnsDonor,
  getGridColumnsDonorShare,
  getGridColumnsMergeDonor,
  getGridColumnsPhysicianDashboard,
  getSearchFilter,
  getQueryDataState,
  updateColumnsOrder,
  getColumnDefinition,
  saveExamGridState,
  fetchExamGridState,
  parseDataState,
  parseColumnsState,
  initializeExamGridState,
  updateStateFromWorklist,
};
