import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { ItemProps } from './ItemProps';
import { RadioButton } from './RadioButton';

export const Item: FunctionComponent<ItemProps> = ({
  onChange,
  role,
  children,
}) => {
  // The base Kendo RadioGroup component by default will populate the "children" prop to be a RadioButton component.  So we need to test
  // that and then forward on its properties.
  const buttonProps =
    children != null && typeof children === 'object' && 'props' in children
      ? children.props
      : {};

  return (
    <StyledRadioButtonWrapper role={role}>
      <RadioButton {...buttonProps} onChange={onChange} />
    </StyledRadioButtonWrapper>
  );
};

Item.displayName = 'Item';

const StyledRadioButtonWrapper = styled.li`
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;
