// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/field-category`;

const getByIdRoute = (fieldCategoryId) =>
  `${getBaseRoute()}/${fieldCategoryId}`;

const getAllRoute = () => `${getBaseRoute()}/GetAllFieldCategories`;

export const FieldCategoryRouteService = {
  getBaseRoute,
  getByIdRoute,
  getAllRoute,
};
