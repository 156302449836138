import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { FormFieldStatus } from '../constants';
import { FormFieldActions } from './actions';

export const formFieldAdapter = createEntityAdapter({ sortComparer: false });

const getReducerInitialState = () => ({
  ...formFieldAdapter.getInitialState(),
  total: 0,
  status: FormFieldStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(FormFieldActions.getById.fulfilled, (draft, action) => {
    formFieldAdapter.upsertOne(draft, action.payload);
    draft.status = FormFieldStatus.FETCHED;
  });
  builder.addCase(
    FormFieldActions.getByFieldCategoryId.fulfilled,
    (draft, action) => {
      formFieldAdapter.setMany(draft, action.payload.formFields);
      draft.status = FormFieldStatus.FETCHED;
    },
  );
});
