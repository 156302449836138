import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Tooltip } from '../Tooltip';
import { LabelInformationIconProps } from './LabelInformationIconProps';

export const LabelInformationIcon: FunctionComponent<LabelInformationIconProps> = ({ description }) => {
  return (
    <Tooltip text={description}>
      <StyledSpanInformationIcon className="fa fa-circle-info" />
    </Tooltip>
  );
};

LabelInformationIcon.displayName = 'LabelInformationIcon';

const StyledSpanInformationIcon = styled.span`
  margin-left: ${({ theme }) => theme.space.spacing10};
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
`;
