import { ComponentPropsWithoutRef, FunctionComponent, useMemo } from 'react';

import dayjs from 'dayjs';
import { styled } from 'styled-components';

import { DateCellProps } from './DateCellProps';

export const DateCell: FunctionComponent<DateCellProps> = ({
  className,
  ellipsisPlacement = 'end',
  format = 'MM/DD/YYYY HH:mm:ss',
  dataItem,
  field,
  onContextMenu,
  timeZone,
}) => {
  const content = useMemo(
    () => (
      <StyledTdText
        className={className}
        data-item={dataItem}
        data-field={field}
        onContextMenu={onContextMenu as ComponentPropsWithoutRef<'td'>['onContextMenu']}
        $ellipsisPlacement={ellipsisPlacement}
      >
        {formatCell(format, dataItem, field, timeZone)}
      </StyledTdText>
    ),
    [className, dataItem, ellipsisPlacement, field, format, onContextMenu, timeZone],
  );

  return content;
};

DateCell.displayName = 'DateCell';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatCell(format: string, dataItem: any, field?: string | null, timeZone?: string) {
  if (field == null) return null;

  if (timeZone) {
    return dataItem[field] ? dayjs.utc(dataItem[field]).tz(timeZone).format(format) : null;
  }
  return dataItem[field] ? dayjs(dataItem[field]).format(format) : null;
}

const resolveTextDirection = ({ $ellipsisPlacement }: { $ellipsisPlacement: 'start' | 'end' }) => {
  if ($ellipsisPlacement === 'start') {
    return 'rtl';
  }

  return 'ltr';
};

const StyledTdText = styled.td<{ $ellipsisPlacement: 'start' | 'end' }>`
  direction: ${resolveTextDirection};
`;
