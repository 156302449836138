import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { UserRoutes } from '../routes';
import { UserForm } from './UserForm';
import { UserHome } from './UserHome';

const USER_ADMIN_ROLES = [UserRoles.ADMIN];

export const User = () => {
  return (
    <Routes>
      <Route
        path={UserRoutes.HOME}
        element={
          <RouteGuard allowedRoles={USER_ADMIN_ROLES}>
            <UserHome />
          </RouteGuard>
        }
      />
      <Route
        path={UserRoutes.ADD}
        element={
          <RouteGuard allowedRoles={USER_ADMIN_ROLES}>
            <UserForm />
          </RouteGuard>
        }
      />
      <Route
        path={UserRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={USER_ADMIN_ROLES}>
            <UserForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
