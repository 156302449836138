import { FunctionComponent, useCallback, useMemo } from 'react';

import { FieldArray } from '@progress/kendo-react-form';
import styled from 'styled-components';

import {
  Field,
  FieldContainer,
  Form,
  FormValidatorsService,
  GridColumn,
  createStyledFormElement,
} from 'core/forms';
import { Button, Input, InputArray, Label, MultiSelect, Switch } from 'core/ui';

import { LocationSelectors } from 'features/location';
import { useAppSelector } from 'features/main/hooks';
import { ServiceSelectors } from 'features/service';

import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const GeneralTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof GeneralTabFormValues) => any;
  onChange: (values: GeneralTabFormValues) => void;
}> = ({ valueGetter, onChange }) => {
  const locations = useAppSelector(LocationSelectors.getAll);
  const services = useAppSelector(ServiceSelectors.getAll);

  const filteredServices = useMemo(
    () => services.filter((service) => service.description),
    [services],
  );

  const handleFormChange = useCallback(() => {
    onChange({
      name: valueGetter('name'),
      locations: valueGetter('locations'),
      domains: valueGetter('domains'),
      approveAutomatically: valueGetter('approveAutomatically'),
      active: valueGetter('active'),
      services: valueGetter('services'),
      pendingApprovalMessage: valueGetter('pendingApprovalMessage'),
    });
  }, [onChange, valueGetter]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="name">Name</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            component={Input}
            editorId="name"
            name="name"
            validator={FormValidatorsService.combineValidators([
              FormValidatorsService.required,
              FormValidatorsService.maxLength(255),
            ])}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="locations">Locations</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            component={MultiSelect}
            data={locations}
            editorId="locations"
            name="locations"
            onChange={handleFormChange}
          />
          <StyledSpacer />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="domains">Domains</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <FieldArray
            component={InputArray}
            editorId="domains"
            name="domains"
            onChange={handleFormChange}
          />
          <StyledSpacer />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="approveAutomatically">Approve Automatically</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="approveAutomatically"
            editorId="approveAutomatically"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="active">Active</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field name="active" editorId="active" component={Switch} />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="services">Exam Types</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="services"
            editorId="services"
            component={MultiSelect}
            data={filteredServices}
            textField="description"
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="pendingApprovalMessage">
          Pending Approval Message
        </Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="pendingApprovalMessage"
            editorId="pendingApprovalMessage"
            component={Input}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

export const GeneralTabContent: FunctionComponent<GeneralTabContentProps> = ({
  initialValues,
  onChange,
  onSubmit,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <GeneralTabContentInner valueGetter={valueGetter} onChange={onChange} />
      )}
    />
  );
};

const StyledSpacer = styled.div`
  height: 17px;
`;
