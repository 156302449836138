import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { UnitRoutes } from '../routes';
import { UnitForm } from './UnitForm';
import { UnitHome } from './UnitHome';

const UNIT_ADMIN_ROLES = [UserRoles.ADMIN];

export const Unit = () => {
  return (
    <Routes>
      <Route
        path={UnitRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UNIT_ADMIN_ROLES}>
            <UnitHome />
          </RouteGuard>
        }
      />
      <Route
        path={UnitRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UNIT_ADMIN_ROLES}>
            <UnitForm />
          </RouteGuard>
        }
      />
      <Route
        path={UnitRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UNIT_ADMIN_ROLES}>
            <UnitForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
