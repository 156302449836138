import { GatewayEventModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class GatewayEventsClient {
  constructor(private httpClient: HttpClient) {
    this.getGatewayEvents = this.getGatewayEvents.bind(this);
  }

  public async getGatewayEvents(studyInstanceUid: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const gatewayLogs = await this.httpClient.fetchAndParse<GatewayEventModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/gatewayevents/grid/${studyInstanceUid}`, options),
    );
    return gatewayLogs;
  }
}
