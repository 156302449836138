import { memo, useMemo } from 'react';

import { faFileLines } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useDataStreamMap } from 'core/hooks';
import { ComponentSizes, Icon, InformationTable, InformationTableItem, PrimaryCard, ProgressBar } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { PatientUtils } from 'features/patient';

import { useUploadExamsPageContext } from '../hooks';
import { UploadGroupProgressItemProps } from '../types';

export const UploadGroupProgressItem = memo<UploadGroupProgressItemProps>(({ className, uploadGroupId }) => {
  const { uploadGroups, uploadView } = useUploadExamsPageContext();

  const uploadGroup = useMemo(() => findOrThrow(uploadGroups, (g) => g.uploadGroupId === uploadGroupId), [uploadGroupId, uploadGroups]);

  const groupState = useDataStreamMap(uploadGroupId, uploadView.streams.groupStates);

  const infoItems = useMemo(() => {
    const newItems: (InformationTableItem | 'collapse-button')[] = [];

    newItems.push({ key: 'patient-name', label: 'Patient', value: PatientUtils.formatName(uploadGroup.patient?.firstName, uploadGroup.patient?.lastName) });
    newItems.push({ key: 'exam-description', label: 'Exam', value: uploadGroup.exam?.description });

    if (uploadGroup.dicomData != null) {
      newItems.push('collapse-button');
      newItems.push({ key: 'study-instance-uid', label: 'Study Instance UID', value: uploadGroup.dicomData.StudyInstanceUID });
      newItems.push({ key: 'study-description', label: 'Study Description', value: uploadGroup.dicomData.StudyDescription });
      newItems.push({
        key: 'patient-name',
        label: 'Patient Name',
        value: PatientUtils.formatName(uploadGroup.patient?.firstName, uploadGroup.patient?.lastName),
      });
      newItems.push({ key: 'patient-birth-date', label: 'Patient Birth Date', value: uploadGroup.dicomData.PatientBirthDate });
      newItems.push({ key: 'accession-number', label: 'Accession Number', value: uploadGroup.dicomData.AccessionNumber });
      newItems.push({ key: 'body-part-examined', label: 'Body Part Examined', value: uploadGroup.dicomData.BodyPartExamined });
      newItems.push({ key: 'modality', label: 'Modality', value: uploadGroup.dicomData.Modality });
      newItems.push({ key: 'study-date', label: 'Study Date', value: uploadGroup.dicomData.StudyDate });
      newItems.push({ key: 'study-time', label: 'Study Time', value: uploadGroup.dicomData.StudyTime });
      newItems.push({ key: 'patient-id', label: 'Patient ID', value: uploadGroup.dicomData.PatientID });
      newItems.push({ key: 'patient-sex', label: 'Patient Sex', value: uploadGroup.dicomData.PatientSex });
    }

    return newItems;
  }, [uploadGroup.dicomData, uploadGroup.exam?.description, uploadGroup.patient?.firstName, uploadGroup.patient?.lastName]);

  return (
    <StyledPrimaryCard className={className}>
      <PrimaryCard.Header>
        <Icon icon={faFileLines} block fixedWidth={false} />
        <PrimaryCard.HeaderText>{uploadGroup.exam?.description}</PrimaryCard.HeaderText>
        <StyledProgressBar value={(100 * groupState.weightedUploadSize) / groupState.totalSize} size={ComponentSizes.LARGE} labelResolver={progressText} />
      </PrimaryCard.Header>
      <StyledPrimaryCardBody>
        <InformationTable items={infoItems} />
      </StyledPrimaryCardBody>
    </StyledPrimaryCard>
  );
});

UploadGroupProgressItem.displayName = 'UploadGroupProgressItem';

function progressText(value: number | null | undefined) {
  return value == null ? '' : `${Math.floor(value)} %`;
}

const StyledPrimaryCard = styled(PrimaryCard)``;

const StyledPrimaryCardBody = styled(PrimaryCard.Body)`
  padding: 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  flex: 0 0 200px;
  margin-left: ${({ theme }) => theme.space.spacing40};
`;
