import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

import { ShareExpirationType } from '../types/ShareExpirationType';

function calculateShareExpiration(expireType: ShareExpirationType, startDate: Dayjs = dayjs.utc()) {
  if (!startDate.isUTC()) {
    throw new Error('Parameter "startDate" must be in UTC timezone.');
  }

  let duration: Duration | null;

  switch (expireType) {
    case ShareExpirationType.Never:
      duration = null;
      break;
    case ShareExpirationType.OneDay:
      duration = dayjs.duration(1, 'days');
      break;
    case ShareExpirationType.SevenDays:
      duration = dayjs.duration(7, 'days');
      break;
    case ShareExpirationType.ThirtyDays:
      duration = dayjs.duration(30, 'days');
      break;
    case ShareExpirationType.SixtyDays:
      duration = dayjs.duration(60, 'days');
      break;
    case ShareExpirationType.NinetyDays:
      duration = dayjs.duration(90, 'days');
      break;
    case ShareExpirationType.OneYear:
      duration = dayjs.duration(1, 'years');
      break;
    default:
      throw new Error('Unable to determine expiration duration.');
  }

  return duration == null ? null : startDate.add(duration).tz('America/Los_Angeles');
}

export const ShareService = {
  calculateShareExpiration,
};
