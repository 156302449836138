import { ComponentType, FunctionComponent } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { CustomIcon } from './CustomIcon';
import { LeftIconProps } from './LeftIconProps';
import { TagTypes } from './constants';

export const LeftIcon: FunctionComponent<LeftIconProps> = ({ type, icon }) => (
  <StyledSpanIconContainer $type={type} $icon={icon}>
    <CustomIcon type={type} icon={icon} />
  </StyledSpanIconContainer>
);

LeftIcon.displayName = 'LeftIcon';

type StyledElementProps = {
  theme: DefaultTheme;
  $type?: TagTypes;
  $icon?: ComponentType;
};

const resolveRightMargin = ({ theme, $type, $icon }: StyledElementProps) => {
  if (!$icon && (!$type || $type === TagTypes.CODE)) return '0';

  return theme.space.spacing20;
};

const StyledSpanIconContainer = styled.span<{
  $type?: TagTypes;
  $icon?: ComponentType;
}>`
  margin-right: ${resolveRightMargin};
  display: flex;
`;
