import { useContext } from 'react';

import { ApiClientProviderContext } from '../contexts';

export const useApiClient = () => {
  const apiClient = useContext(ApiClientProviderContext);

  if (apiClient == null)
    throw new Error(
      '<ApiClientProvider /> must be an ancestor to gain access to the API client.',
    );

  return apiClient;
};
