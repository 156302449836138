import { configureStore } from '@reduxjs/toolkit';

import { reducer } from './reducer';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type RootThunkAPI = {
  getState: () => RootState;
  dispatch: AppDispatch;
  state?: RootState;
};
export type AppSelector<T> = (state: RootState) => T;
