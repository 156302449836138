import { FunctionComponent } from 'react';

import { useInitializationEffect } from 'core/hooks';
import { hasText } from 'core/utils';

import { BASE_HOME_ITEM } from 'features/main/constants';
import { SettingsHelpers, WellKnownSettingName, useUserSettings } from 'features/settings';

import { AuthenticationScheme } from '../constants';
import { useAuthentication, useRouteGuard } from '../hooks';
import { RouteGuardErrorCode } from '../types';
import { SessionLocationRequiredModal } from './SessionLocationRequiredModal';

export const DefaultAuthorizationChallenge: FunctionComponent = () => {
  const { activeScheme, loginOidc, logoutShare } = useAuthentication();
  const { allowedSchemes, errorCodes } = useRouteGuard();
  const { userSettings } = useUserSettings(true);

  useInitializationEffect(() => {
    if (errorCodes.includes(RouteGuardErrorCode.SchemeNotAllowed)) {
      if (activeScheme === AuthenticationScheme.ANONYMOUS && allowedSchemes.includes(AuthenticationScheme.OIDC)) {
        loginOidc();
      }

      if (activeScheme === AuthenticationScheme.SHARE) {
        logoutShare();
        window.location.reload();
      }
    }

    if (errorCodes.includes(RouteGuardErrorCode.MissingRequiredRole) || errorCodes.includes(RouteGuardErrorCode.RequiresSystemAdmin)) {
      if (activeScheme === AuthenticationScheme.OIDC) {
        const homePageUrl = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.HomePageUrl, false);

        let redirectUrl: string | null = null;

        if (homePageUrl && hasText(homePageUrl.value)) {
          const absoluteUrl: string =
            homePageUrl.value.startsWith('http://') || homePageUrl.value.startsWith('https://')
              ? homePageUrl.value
              : new URL(homePageUrl.value, window.location.origin).toString();

          redirectUrl = absoluteUrl;
        } else {
          redirectUrl = BASE_HOME_ITEM.defaultExternalRoute ?? null;
        }

        if (hasText(redirectUrl)) {
          window.location.href = redirectUrl;
        }
      }
    }
  });

  if (errorCodes.includes(RouteGuardErrorCode.RequiresSessionLocation)) {
    return <SessionLocationRequiredModal />;
  }

  return null;
};

DefaultAuthorizationChallenge.displayName = 'DefaultAuthorizationChallenge';
