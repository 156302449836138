import { ToastOptions, toast } from 'react-toastify';

import ErrorIconSvg from '../assets/error-icon.svg?react';
import InfoIconSvg from '../assets/info-icon.svg?react';
import SuccessIconSvg from '../assets/success-icon.svg?react';
import WarningIconSvg from '../assets/warning-icon.svg?react';

function displaySuccess(message: string, options?: ToastOptions) {
  toast.success(message, {
    icon: <SuccessIconSvg />,
    ...options,
  });
}

function displayError(message: string, options?: ToastOptions) {
  toast.error(message, {
    icon: <ErrorIconSvg />,
    ...options,
  });
}

function displayWarning(message: string, options?: ToastOptions) {
  toast.warning(message, {
    icon: <WarningIconSvg />,
    ...options,
  });
}

function displayInfo(message: string, options?: ToastOptions) {
  toast.info(message, {
    icon: <InfoIconSvg />,
    ...options,
  });
}

function removeAll() {
  toast.dismiss();
}

export const NotificationsService = {
  displaySuccess,
  displayInfo,
  displayError,
  displayWarning,
  removeAll,
};
