import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { AccountModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { AccountRouteService } from '../services';
import {
  NormalizedAccountModelSchema,
  accountEntity,
} from './normalizr-schema';

const add = createAsyncThunk(
  'accounts/add',
  async (account: AccountModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: AccountRouteService.getBaseRoute(),
        body: account,
      }),
    )) as unknown as ApiResponse<AccountModel>;

    const normalized = normalize<AccountModel, NormalizedAccountModelSchema>(
      { ...account, id: result.id },
      accountEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'accounts/edit',
  async (account: AccountModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: AccountRouteService.getBaseRoute(),
        body: account,
      }),
    );

    const normalized = normalize<AccountModel, NormalizedAccountModelSchema>(
      account,
      accountEntity,
    );

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'accounts/get-by-id',
  async (accountId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(AccountRouteService.getByIdRoute(accountId)),
    )) as unknown as ApiResponse<AccountModel>;

    return result;
  },
);

const getAll = createAsyncThunk(
  'accounts/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(AccountRouteService.getAllRoute()),
    )) as unknown as ApiResponse<AccountModel[]>;

    const normalized = normalize<AccountModel, NormalizedAccountModelSchema>(
      result,
      [accountEntity],
    );

    return normalized.entities;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'accounts/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = AccountRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [accountEntity]);

    return { ...normalized, total: result.total };
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'locations/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = AccountRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<AccountModel>;

    return result;
  },
);

export const AccountActions = {
  add,
  edit,
  getAll,
  getAllForExport,
  getById,
  getAllForKendoGrid,
};
