import { WizardStepKey, WizardStepProps } from './types';

/** Number of milliseconds to wait and buffer the list of files to attach. */
export const FILE_ATTACH_INTERVAL = 1000;

/** Initial state of the upload exam wizard steps. */
export const INITIAL_WIZARD_STEPS: WizardStepProps[] = [
  { stepKey: WizardStepKey.SelectFiles, label: 'Select Files', isValid: false, visited: false, disabled: false },
  { stepKey: WizardStepKey.Exams, label: 'Exams', error: 'example error', isValid: false, visited: false, disabled: false },
  { stepKey: WizardStepKey.Attachments, label: 'Attachments', optional: true, isValid: true, visited: false, disabled: false },
  { stepKey: WizardStepKey.ReviewAndUpload, label: 'Review & Upload', isValid: false, visited: false, disabled: false },
];
