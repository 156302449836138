import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field } from 'core/forms';
import { Input, RadioGroup } from 'core/ui';

import { ComplianceOptions } from '../constants';

// has to be wrapped in <Form /> to work
const BiopsyIndicationForm = ({ name, fieldNamePrefix }) => {
  return (
    <StyledRow>
      <StyledTitleCol className="col-12">
        <StyledTitleDiv>{name}</StyledTitleDiv>
      </StyledTitleCol>
      <Col className="col-3">
        <Field
          component={Input}
          label="Years"
          name={`${fieldNamePrefix}Years`}
          type="number"
        />
      </Col>
      <Col className="col-9">
        <Field
          component={RadioGroup}
          data={_map(ComplianceOptions)}
          label="Compliant"
          name={`${fieldNamePrefix}Compliant`}
        />
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  background: ${({ theme }) => theme.colors.palette.white};
  border-radius: 8px;
  margin-left: ${({ theme }) => theme.space.spacing20};
  margin-top: ${({ theme }) => theme.space.spacing20};
  padding: ${({ theme }) => theme.space.spacing20};
  width: 95%;
`;

const StyledTitleCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledTitleDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

BiopsyIndicationForm.propTypes = {
  fieldNamePrefix: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

BiopsyIndicationForm.defaultProps = {};

export { BiopsyIndicationForm };
