import { memo, useState } from 'react';

import { faUpload, faWarning } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { BreakpointClassNames, BreakpointSelectors, Button, ButtonVariants, ComponentSizes, ErrorMessage, Icon, PrimaryCard } from 'core/ui';

import { useUploadExamsPageContext } from '../hooks';
import { UploadStateType, WizardStepKey } from '../types';
import { UploadGroupProgressItem } from './UploadGroupProgressItem';

export const ReviewAndUploadStep = memo(() => {
  const { uploadState, uploadGroups, beginUpload, onPreviousStep, validateStep } = useUploadExamsPageContext();
  const [stepError, setStepError] = useState<string | null>(null);

  const handleUploadClick = useEvent(async () => {
    if (!validateStep(uploadGroups, WizardStepKey.ReviewAndUpload)) {
      setStepError('Please resolve issues before proceeding.');
      return;
    }

    beginUpload();
  });

  return (
    <StyledComponentDiv className={cn({ initial: uploadState === UploadStateType.SELECTION })}>
      {uploadState === UploadStateType.SELECTION && (
        <>
          {stepError == null && (
            <StyledStartUploadCard>
              <PrimaryCard.Header>
                <Icon icon={faUpload} block fixedWidth={false} />
                <PrimaryCard.HeaderText>Ready to upload</PrimaryCard.HeaderText>
              </PrimaryCard.Header>
              <PrimaryCard.Body $noPadding className="card-body">
                <StyledBigButton size={ComponentSizes.LARGE} onClick={handleUploadClick}>
                  Upload Now
                </StyledBigButton>
              </PrimaryCard.Body>
            </StyledStartUploadCard>
          )}

          {stepError != null && (
            <StyledErrorsCard>
              <PrimaryCard.Header>
                <Icon icon={faWarning} block fixedWidth={false} />
                <PrimaryCard.HeaderText>Correct issues</PrimaryCard.HeaderText>
              </PrimaryCard.Header>
              <PrimaryCard.Body $noPadding className="card-body">
                <ErrorMessage>{stepError}</ErrorMessage>
              </PrimaryCard.Body>
            </StyledErrorsCard>
          )}
        </>
      )}

      {uploadState === UploadStateType.UPLOADING && (
        <div>
          {uploadGroups
            .filter((group) => group.checked)
            .map((group) => (
              <UploadGroupProgressItem key={group.uploadGroupId} uploadGroupId={group.uploadGroupId} />
            ))}
        </div>
      )}

      <StyledFooter>
        <StyledBigButton
          size={ComponentSizes.LARGE}
          onClick={onPreviousStep}
          variant={ButtonVariants.SECONDARY}
          disabled={uploadState !== UploadStateType.SELECTION}
        >
          Back
        </StyledBigButton>
      </StyledFooter>
    </StyledComponentDiv>
  );
});

ReviewAndUploadStep.displayName = 'ReviewAndUploadStep';

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content;
  padding-right: ${({ theme }) => theme.space.spacing40};

  ${BreakpointSelectors.Desktop}:not(.${BreakpointClassNames.DesktopShort}) & {
    padding-top: ${({ theme }) => theme.space.spacing80};
  }
`;

const StyledStartUploadCard = styled(PrimaryCard)`
  width: 400px;
  justify-self: center;
  align-self: start;

  .card-body {
    display: flex;
    overflow: hidden;
    justify-content: center;

    button {
      flex: 0 0 150px;
    }
  }
`;

const StyledErrorsCard = styled(PrimaryCard)`
  width: 200px;
  justify-self: center;
  align-self: start;

  .card-body {
    display: flex;
    overflow: hidden;
    align-items: center;

    button {
      flex: 0 0 150px;
    }
  }
`;

const StyledBigButton = styled(Button)`
  && {
    display: flex;
    width: initial;
    height: initial;
    padding: 5px 16px;
    line-height: 22px;
  }

  .k-button-text {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 22px;
  }
`;

const StyledFooter = styled.div`
  align-self: end;
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;
