import { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ServiceModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useAsyncCallback, useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, DropdownField, InputField, Label, Page, PageHeader, SwitchField } from 'core/ui';
import { findOrThrow } from 'core/utils';

// import { findOrThrow } from 'core/utils';
import { apiClient } from 'features/api';

import { ServiceEditService } from '../services';
import { EditFormValues } from '../types';

const StyledForm = createStyledRhfForm('min-content 400px');

export const ServiceForm: FunctionComponent = memo(() => {
  const serviceId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [service, setService] = useState<ServiceModel | null>(null);
  const [serviceSubTypes, setServiceSubTypes] = useState<ServiceModel[]>([]);
  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...ServiceEditService.EditFormDefaults },
  });

  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (service == null) {
        throw new Error('Cannot proceed because service is null or undefined.');
      }

      const newService: ServiceModel = ServiceEditService.copyFormToModel(service.id, values);

      if (typeof serviceId === 'number') {
        await apiClient.servicesClient.updateService(newService);
      } else {
        await apiClient.servicesClient.createService(newService);
      }

      reset(ServiceEditService.copyModelToForm(newService, serviceSubTypes), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(serviceId == null ? 'Service created.' : 'Service saved.');
      navigate('/service');
    },
    [navigate, reset, service, serviceId, serviceSubTypes],
  );

  const [initializeData] = useAsyncCallback(async () => {
    const [newService, newServiceParent] = await Promise.all([
      serviceId == null
        ? new Promise<ServiceModel>((resolve) => {
            resolve(ServiceEditService.createDefaultSource());
          })
        : apiClient.servicesClient.getServiceById(serviceId),
      apiClient.servicesClient.getAllServices(),
    ]);
    setService(newService);
    setServiceSubTypes(newServiceParent);

    initializeForm(newService, newServiceParent);

    reset(ServiceEditService.copyModelToForm(newService, newServiceParent), {
      keepValues: false,
      keepDefaultValues: false,
    });
  });

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  function initializeForm(model: ServiceModel, paramServices: ServiceModel[]) {
    if (model) {
      const subService =
        model.serviceSubTypeID != null && model.serviceSubTypeID > 0
          ? findOrThrow(paramServices, (t) => t.id === model.serviceSubTypeID, `Could not find service with id: ${model.serviceSubTypeID}.`)
          : null;
      // if (service !== null) {
      //   if (service.description == 'PATHOLOGY') setIsOrganVisible(true);
      //   else setIsOrganVisible(false);
      // }
    }
  }

  if (service == null) return null;

  return (
    <Page>
      <PageHeader title={`${serviceId ? 'Edit' : 'New'} Service`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Description</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Notes</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="notes" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Short Code</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="shortCode" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Long Code</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="longCode" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>CPT(Current Procedural Terminology)</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="cpt" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Parent Service</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              {/* <DropdownWithValuesField
                name="serviceSubTypeID"
                data={serviceSubTypes}
                defaultItem={{
                  description: '',
                  value: null,
                }}
                textField="description"
                value={service.serviceSubTypeID}
              /> */}
              <DropdownField data={serviceSubTypes} name="serviceSubType" dataItemKey="id" valueField="id" textField="description" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="2" columnEnd="span 1">
            <div>
              <Button disabled={isSubmitting} type="submit">
                Save
              </Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
});

ServiceForm.displayName = 'ServiceForm';
