import { FunctionComponent, memo, useCallback, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ContactModel, DestinationModel, DestinationRouteModel, ExamStatusTypeModel, IntegrationModel, LocationModel, PhysicianModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useValidatedParam } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, DropdownField, InputField, Label, Page, PageHeader, SwitchField } from 'core/ui';

import { apiClient } from 'features/api';

import { DestinationRouteEditService } from '../services';
import { EditFormValues } from '../types';

const StyledForm = createStyledRhfForm('min-content 400px');

const NullIntegrationOption = { id: null, name: '' };
const NullLocationOption = { id: null, name: '' };
const NullDestinationOption = { id: null, name: '' };

export const DestinationRouteForm: FunctionComponent = memo(() => {
  const destinationRouteId = useValidatedParam('id', 'integer', false);
  const navigate = useNavigate();
  const [destinationRoute, setDestinationRoute] = useState<DestinationRouteModel | null>(null);

  const [destinations, setDestinations] = useState<DestinationModel[]>([]);
  const [integrations, setIntegrations] = useState<IntegrationModel[]>([]);
  const [physicians, setPhysicians] = useState<PhysicianModel[]>([]);
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [contacts, setContacts] = useState<ContactModel[]>([]);
  const [statusTypes, setStatusTypes] = useState<ExamStatusTypeModel[]>([]);

  const rhfContext = useForm<EditFormValues>({
    defaultValues: { ...DestinationRouteEditService.EditFormDefaults },
  });
  const {
    reset,
    formState: { isSubmitting },
  } = rhfContext;

  const handleSubmit: SubmitHandler<EditFormValues> = useCallback(
    async (values, event) => {
      event?.preventDefault();

      if (destinationRoute == null) {
        throw new Error('Cannot proceed because destination route is null or undefined.');
      }

      const newDestinationRoute: DestinationRouteModel = DestinationRouteEditService.copyFormToModel(destinationRoute.id, values);

      if (typeof destinationRouteId === 'number') {
        await apiClient.destinationRouteClient.updateDestinationRoute(newDestinationRoute);
      } else {
        await apiClient.destinationRouteClient.createDestinationRoute(newDestinationRoute);
      }

      reset(DestinationRouteEditService.copyModelToForm(newDestinationRoute, destinations, physicians, locations, contacts, integrations, statusTypes), {
        keepValues: true,
        keepDefaultValues: false,
      });
      NotificationsService.displaySuccess(destinationRouteId == null ? 'Destination Route created.' : 'Destination Route saved.');
      navigate('/destination-route');
    },
    [destinationRoute, destinationRouteId, reset, destinations, physicians, locations, contacts, integrations, statusTypes, navigate],
  );

  useEffect(() => {
    (async () => {
      const [newDestinationRoute, newDestinations, newPhysicians, newLocations, newIntegrations, newContacts, newStatusTypes] = await Promise.all([
        destinationRouteId == null
          ? new Promise<DestinationRouteModel>((resolve) => {
              resolve(DestinationRouteEditService.createDefaultSource());
            })
          : apiClient.destinationRouteClient.getDestinationRouteById(destinationRouteId),
        apiClient.destinationClient.getAllDestinations(),
        apiClient.physicianClient.getAll(),
        apiClient.locationClient.getAllLocations(),
        apiClient.integrationClient.getAllIntegrations(),
        apiClient.contactClient.getAll(),
        apiClient.examStatusClient.getExamStatusTypes(),
      ]);

      setDestinationRoute(newDestinationRoute);

      setDestinations(newDestinations);
      setLocations(newLocations);
      setPhysicians(newPhysicians);
      setIntegrations(newIntegrations);
      const cons = newContacts.map((obj) => ({ ...obj, fullName: `${obj.firstName} ${obj.lastName} ${obj.degree}` }));
      setContacts(cons);
      setStatusTypes(newStatusTypes);

      reset(
        DestinationRouteEditService.copyModelToForm(newDestinationRoute, newDestinations, newPhysicians, newLocations, cons, newIntegrations, newStatusTypes),
        {
          keepValues: false,
          keepDefaultValues: false,
        },
      );
    })();
  }, [reset, destinationRouteId]);

  if (destinationRoute == null) return null;

  return (
    <Page>
      <PageHeader title={`${destinationRouteId ? 'Edit' : 'New'} Destination Route`} />
      <FormProvider {...rhfContext}>
        <StyledForm autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false" noValidate onSubmit={rhfContext.handleSubmit(handleSubmit)}>
          <GridColumn columnStart="1" isLabelColumn>
            <Label>Filter</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <InputField name="filter" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Destination</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="destination" data={destinations} defaultItem={NullDestinationOption} required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label required>Integration</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="integration" data={integrations} defaultItem={NullIntegrationOption} required />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Source Status</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="previousStatusType" data={statusTypes} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Destination Status</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="statusType" data={statusTypes} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Location</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField name="location" data={locations} defaultItem={NullLocationOption} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Contact</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField
                name="contact"
                data={contacts}
                defaultItem={{
                  fullName: 'Unassigned',
                  value: null,
                }}
                label="Contact"
                textField="fullName"
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Physician</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <DropdownField
                name="physician"
                data={physicians}
                defaultItem={{
                  fullName: 'Unassigned',
                  value: null,
                }}
                label="Physician"
                textField="fullName"
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Event Trigger</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="isEventTrigger" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label>Active</Label>
          </GridColumn>
          <GridColumn columnStart="2" columnEnd="span 1">
            <FieldContainer $hideLabel>
              <SwitchField name="active" />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="2" columnEnd="span 1">
            <div>
              <Button disabled={isSubmitting} type="submit">
                Save
              </Button>
            </div>
          </GridColumn>
        </StyledForm>
      </FormProvider>
    </Page>
  );
});

DestinationRouteForm.displayName = 'DestinationRouteForm';
