import { findExactQueryParamKey, hasText } from 'core/utils';

import { LOCAL_STORAGE_KEY_SESSION_LOCATION_ID } from '../constants';

/** Retreives the session location id from: 1) `window.location`, then 2) from `window.localStorage`.  Returns `null` if it was not found in either. */
function fetchSessionLocationId() {
  // The sessionLocationId can be set in the URL query string.  This is useful for CompuMed personnel to be able to share links to use the session location id.
  const searchParams = new URLSearchParams(window.location.search);
  const actualQueryKey = findExactQueryParamKey(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID, searchParams);

  if (actualQueryKey != null) {
    const rawSessionLocationId = searchParams.get(actualQueryKey);
    const sessionLocationId = hasText(rawSessionLocationId) ? parseInt(rawSessionLocationId, 10) : null;

    if (sessionLocationId != null && !isNaN(sessionLocationId) && Number.isSafeInteger(sessionLocationId)) {
      return sessionLocationId;
    }
  }

  const restoredId = localStorage.getItem(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID);
  return hasText(restoredId) ? parseInt(restoredId, 10) : null;
}

/** Saves the specified session location id in both the `window.location` and `window.localStorage`.  If `sessionLocationId` is `null` or `undefined` then this will remove the query parameter from `window.location` and the entry from `window.localStorage`. */
function saveSessionLocationId(sessionLocationId: number | null | undefined) {
  const newUrl = new URL(window.location.href);
  const existingQueryKey = findExactQueryParamKey(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID, newUrl.searchParams);
  const existingLocalStorageRaw = localStorage.getItem(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID);
  const existingLocalStorageValue = hasText(existingLocalStorageRaw) ? parseInt(existingLocalStorageRaw, 10) : null;

  // Remove the existing query parameter if it exists but it has different casing than the expected key.
  if (existingQueryKey != null) {
    if (sessionLocationId == null || existingQueryKey !== LOCAL_STORAGE_KEY_SESSION_LOCATION_ID) {
      newUrl.searchParams.delete(existingQueryKey);
    }
  }

  // Store the session location id in the query string.
  if (sessionLocationId != null) {
    newUrl.searchParams.set(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID, sessionLocationId.toString());
  }

  // Update the window.location if it has changed.
  if (newUrl.href !== window.location.href) {
    window.history.replaceState({}, '', newUrl);
  }

  // Remove the session location id from localStorage if it is being set to null.
  if (existingLocalStorageValue != null && sessionLocationId == null) {
    localStorage.removeItem(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID);
  }

  // Save the session location id in localStorage.
  if (sessionLocationId != existingLocalStorageValue) {
    if (sessionLocationId == null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY_SESSION_LOCATION_ID, sessionLocationId.toString());
    }
  }
}

export const SessionLocationService = {
  fetchSessionLocationId,
  saveSessionLocationId,
};
