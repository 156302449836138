import { createSelector } from '@reduxjs/toolkit';

import { BrandingModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { brandingAdapter } from './reducer';

const rootSelector = (state: RootState) => state.brandings;

const { selectAll: getAll, selectTotal: getCount } =
  brandingAdapter.getSelectors(rootSelector);

const getByIdOutput = (
  brandings: BrandingModel[],
  brandingId: number | undefined,
) => brandings.find((branding) => branding.id === brandingId);

const getById = (
  brandingId: number | undefined,
): AppSelector<BrandingModel | undefined> =>
  createSelector([getAll, () => brandingId], getByIdOutput);

export const BrandingSelectors = {
  getById,
  getAll,
  getCount,
};
