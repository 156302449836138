import { memo, useEffect, useState } from 'react';

import { faCheck, faFile, faFileJpg, faFilePdf, faFilePng, faFilesMedical, faWarning } from '@fortawesome/pro-solid-svg-icons';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { Loader } from '@progress/kendo-react-indicators';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

import { BulkAttachFilesModel, PatientModel, ShareStudyViewer } from 'models';

import { ApiRouteService } from 'core/api';
import { useEvent, useEventStream } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Icon, Page, Tooltip } from 'core/ui';

import { apiClient } from 'features/api';
import { useAccessTokenSnapshot, useParsedShareAccessToken } from 'features/auth';
import { UploadManagerProvider, useUploadManager } from 'features/file';
import { UploadFileContext } from 'features/file/types';
import { AddFilesButtons } from 'features/upload-exams/fragments/AddFilesButtons';

import { UploadStatusConstants } from '../constants';
import { ShareHeader } from './ShareHeader';

export const UploadInner = memo(() => {
  const shareToken = useParsedShareAccessToken(true);
  const { uploadManager } = useUploadManager();

  const [fileContexts, setFileContexts] = useState<UploadFileContext[]>([]);
  const [exam, setExam] = useState<ShareStudyViewer | null>(null);
  const [patient, setPatient] = useState<PatientModel | null>(null);
  const [connection, setConnection] = useState<HubConnection>();

  const isInitialized = exam != null && patient != null;
  const accessToken = useAccessTokenSnapshot().accessToken ?? '';

  useEffect(() => {
    if (accessToken) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${ApiRouteService.getCompumedApiBaseRoute()}/hub/upload`, { accessTokenFactory: () => accessToken })
        .withAutomaticReconnect()
        .build();
      setConnection(connection);
    }
  }, [accessToken]);

  useEffect(() => {
    if (connection) {
      connection.start().then(() => {
        console.log('Connection started');
      });
    }
  }, [connection]);

  const initialize = useEvent(async () => {
    const [newExams, newPatient] = await Promise.all([
      apiClient.studyShare.getStudyShareByLinkId(shareToken.shareLinkId),
      apiClient.patientClient.getPatient(shareToken.sharePatientId, 'share-required'),
    ]);

    if (newExams.length === 0) throw new Error('No exams found for the given share link ID.');

    setExam(newExams[0]); // For now we are assuming that there is exactly 1 exam in the share.  In the future we may want to support multiple exams.
    setPatient(newPatient);
  });

  const handleAddAttachmentFiles = useEvent((files: File[]) => {
    if (exam == null) throw new Error('Exam is not set.');

    if (files.length === 0) return;

    const newFileContexts = uploadManager.uploadFiles(
      files.map((file) => ({
        uploadGroupId: exam.examId.toString(),
        file,
        compress: false,
        containerName: 'files',
        metadata: {
          uploadSessionId: crypto.randomUUID(),
          examId: exam.examId,
          shareLinkId: shareToken.shareLinkId,
          patientId: shareToken.sharePatientId,
          suid: exam.suid,
        },
      })),
    );

    setFileContexts([...fileContexts, ...newFileContexts]);
  });

  useEventStream(uploadManager.blobUploader.streams.onComplete, async (event) => {
    const file = fileContexts.find((item) => item.fileId === event.fileId);
    if (file && exam?.examId) {
      try {
        const fileUrl = new URL(event.url);
        const fileAttach: BulkAttachFilesModel = {
          fileId: event.fileId,
          fileType: fileUrl.pathname.slice(fileUrl.pathname.lastIndexOf('.') + 1).toUpperCase(),
          location: `${fileUrl.protocol}://${fileUrl.host}${fileUrl.pathname}`,
          fileSize: event.uploadSize,
          examId: exam.examId,
          fileName: file.file.name,
        };
        await apiClient.filesClient.attachFiles([fileAttach], 'share-required');
        await connection?.send('updateShareUploadStatus', UploadStatusConstants.uploaded);
      } catch (error) {
        console.error(error);
      }

      file.result = 'success';
      setFileContexts([...fileContexts]);
    }
  });

  useEventStream(uploadManager.blobUploader.streams.onError, async (event) => {
    const file = fileContexts.find((item) => item.fileId === event.fileId);

    if (file) {
      file.result = 'error';
      setFileContexts([...fileContexts]);
      await connection?.send('updateShareUploadStatus', UploadStatusConstants.failed);
      NotificationsService.displayError(`There was an error uploading the file: ${file.file.name}`);
    }
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (!isInitialized) return null;

  const getFileTypeIcon = (fileType: string | undefined) => {
    switch (fileType?.toUpperCase()) {
      case 'PDF':
        return faFilePdf;
      case 'PNG':
        return faFilePng;
      case 'JPG':
        return faFileJpg;
      case 'JPEG':
        return faFileJpg;
      case 'SZI':
        return faFilesMedical;
      case 'TIF':
        return faFilesMedical;
      case 'TIFF':
        return faFilesMedical;
      default:
        return faFile;
    }
  };

  return (
    <Page>
      <ShareHeader patient={patient} />
      <div>
        <StyledCenteredContainer>
          <StyledUploadContainer>
            <StyledAddFilesButton onAddAttachmentFiles={handleAddAttachmentFiles} />
            <StyledPatientInfo>
              <Card>
                <Card.Header>Patient Information</Card.Header>
                <Card.Body>
                  {patient?.unosID && (
                    <div>
                      <strong>UNOS ID:</strong> {patient.unosID}
                    </div>
                  )}
                  <div>
                    <strong>Name:</strong> {patient.firstName ?? ''} {patient.lastName ?? ''}
                  </div>
                  <div>
                    <strong>Patient Number:</strong> {patient.patientNumber}
                  </div>
                  <div>
                    <strong>DOB:</strong> {patient.dob}
                  </div>
                  <div>
                    <strong>Gender:</strong> {patient?.gender}
                  </div>
                </Card.Body>
              </Card>
            </StyledPatientInfo>
          </StyledUploadContainer>
        </StyledCenteredContainer>
        <StyledSummaryCenteredContainer>
          {fileContexts.length > 0 && (
            <StyledSummaryContainer>
              {fileContexts
                .slice()
                .reverse()
                .map((item, index) => (
                  <Card key={index}>
                    <Card.Body>
                      <StyledCardBodyContent>
                        <Icon icon={getFileTypeIcon(item.fileType)}></Icon>
                        <div>{item.file.name}</div>
                        <Tooltip text={item.result === 'error' ? 'There was an error uploading this file' : null}>
                          <StyledResultIndicator>
                            {item.result === 'in-progress' ? (
                              <Loader></Loader>
                            ) : (
                              <StyledIcon result={item.result} icon={item.result === 'error' ? faWarning : faCheck} fixedWidth={false} />
                            )}
                          </StyledResultIndicator>
                        </Tooltip>
                      </StyledCardBodyContent>
                    </Card.Body>
                  </Card>
                ))}
            </StyledSummaryContainer>
          )}
        </StyledSummaryCenteredContainer>
      </div>
    </Page>
  );
});

UploadInner.displayName = 'UploadInner';

export const Upload = memo(() => {
  return (
    <UploadManagerProvider>
      <UploadInner />
    </UploadManagerProvider>
  );
});

Upload.displayName = 'Upload';

const StyledCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const StyledSummaryCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const StyledAddFilesButton = styled(AddFilesButtons)`
  && > button {
    height: 3rem;
    width: 20rem;
  }
`;

const StyledSummaryContainer = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 80%;
  }
`;

const StyledCardBodyContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)<{ result: 'success' | 'error' | 'in-progress' | undefined }>`
  margin-left: 10px;
  && {
    color: ${(props) =>
      props.result === 'in-progress' ? props.theme.colors.primary : props.result === 'error' ? props.theme.colors.error : props.theme.colors.success};
  }
`;

const StyledResultIndicator = styled.div`
  width: 25px;
  height: 5px;
`;

const StyledPatientInfo = styled.div`
  margin-top: 20px;
`;
