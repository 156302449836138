import { patientAdapter } from './reducer';

const rootSelector = (state) => state.patients;

const {
  selectById,
  selectAll: getAll,
  selectTotal: getCount,
} = patientAdapter.getSelectors(rootSelector);

const getById = (id) => (state) => selectById(state, id);

const getTotal = (state) => rootSelector(state).total;

export const PatientSelectors = {
  getById,
  getAll,
  getCount,
  getTotal,
};
