import { PhysicianModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class PhysicianClient {
  constructor(private httpClient: HttpClient) {
    this.getAll = this.getAll.bind(this);
    this.getPhysicianByEmailAsync = this.getPhysicianByEmailAsync.bind(this);
  }

  public async getAll() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const physicians = await this.httpClient.fetchAndParse<PhysicianModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/physicians`,
        options,
      ),
    );
    return physicians;
  }

  public async getPhysicianByEmailAsync(email: string) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const physician = await this.httpClient.fetchAndParse<PhysicianModel>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/physicians/GetPhysicianByEmail?email=${encodeURIComponent(
          email,
        )}`,
        options,
      ),
    );
    return physician;
  }
}
