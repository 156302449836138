import { EffectCallback, useEffect, useRef } from 'react';

import { useEvent } from './use-event';

/** Execute the specified callback exactly 1 time: on component initialization.  This prevents double execution during development and triggering execution when the callback function identity changes. */
export function useInitializationEffect(callback: EffectCallback) {
  const firstRun = useRef(true);

  const stableCallback = useEvent(() => callback());

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return stableCallback();
    }
  }, [stableCallback]);
}
