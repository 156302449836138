// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/form-field`;

const getByIdRoute = (formFieldId) => `${getBaseRoute()}/${formFieldId}`;

const getFormFieldsByCategoryRoute = (fieldCategoryId) =>
  `${getBaseRoute()}/GetFormFieldsByCategory?fieldCategoryId=${fieldCategoryId}`;

export const FormFieldRouteService = {
  getBaseRoute,
  getByIdRoute,
  getFormFieldsByCategoryRoute,
};
