import { SettingDefinitionModel, SettingModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class SettingsClient {
  constructor(private httpClient: HttpClient) {
    this.getSettings = this.getSettings.bind(this);
    this.getSettingDefinitions = this.getSettingDefinitions.bind(this);
    this.updateSettingDefinitions = this.updateSettingDefinitions.bind(this);
  }

  public async getSettings(
    forCurrentUser: boolean,
    userId: number | null,
    accountId: number | null,
    locationId: number | null,
  ) {
    const paramsList: [string, string][] = [];

    if (forCurrentUser) {
      paramsList.push(['forCurrentUser', 'true']);
    } else {
      paramsList.push(['forCurrentUser', 'false']);
    }
    if (userId != null) {
      paramsList.push(['userId', encodeURIComponent(userId)]);
    }
    if (accountId != null) {
      paramsList.push(['accountId', encodeURIComponent(accountId)]);
    }
    if (locationId != null) {
      paramsList.push(['locationId', encodeURIComponent(locationId)]);
    }

    const params = new URLSearchParams(paramsList).toString();

    const options = await this.httpClient.createStandardOptions('GET', null, {
      authMode: 'msal-optional',
    });

    const response = await this.httpClient.fetchAndParse<SettingModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Settings${
          params === '' ? '' : `?${params}`
        }`,
        options,
      ),
    );

    return response;
  }

  public async getSettingDefinitions() {
    const options = await this.httpClient.createStandardOptions('GET', null);

    const response = await this.httpClient.fetchAndParse<
      SettingDefinitionModel[]
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Settings/GetSettingDefinitions`,
        options,
      ),
    );

    return response;
  }

  public async updateSettingDefinitions(settings: SettingDefinitionModel[]) {
    const options = await this.httpClient.createStandardOptions(
      'PUT',
      settings,
    );

    const response = await this.httpClient.fetchAndParse<
      SettingDefinitionModel[]
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/Settings/UpdateSettingDefinitions`,
        options,
      ),
    );

    return response;
  }
}
