import { memo, useEffect, useState } from 'react';

import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

import { useBoolean, useEvent } from 'core/hooks';
import { Button, Checkbox, Modal } from 'core/ui';

import { ExamsGridService } from '../services';
import { ColumnDefinition, ColumnState, ColumnsModalProps } from '../types';

type ColumnsModalListItemProps = {
  column: ColumnDefinition;
  show: boolean;
  onChange: (event: CheckboxChangeEvent, column: ColumnDefinition) => void;
};

const ColumnsModalListItem = memo<ColumnsModalListItemProps>(({ column, show, onChange }) => {
  const handleChange = useEvent((event: CheckboxChangeEvent) => {
    onChange(event, column);
  });

  return <StyledCheckbox key={column.field} onChange={handleChange} text={column.title ?? undefined} value={show} valid />;
});

ColumnsModalListItem.displayName = 'ColumnsModalListItem';

export const ColumnsModal = memo<ColumnsModalProps>(({ className, show, columns, columnsState, onClose, onSave }) => {
  const [currentColumnsState, setCurrentColumnsState] = useState<Record<string, ColumnState> | null>(null);
  const [isContentVisibleOverride, { setTrue: setIsContentVisibleOverrideTrue, setFalse: setIsContentVisibleOverrideFalse }] = useBoolean(false);

  const isContentVisible = show || isContentVisibleOverride;

  const reset = useEvent(() => {
    setCurrentColumnsState(columnsState);
  });

  const handleChange = useEvent((event: CheckboxChangeEvent, column: ColumnDefinition) => {
    const newColumnsState: Record<string, ColumnState> = {
      ...currentColumnsState,
      [column.field]: {
        show: Boolean(event.target.value),
        orderIndex: columnsState[column.field]?.orderIndex ?? 0,
      },
    };

    setCurrentColumnsState(newColumnsState);
  });

  const handleSaveClick = useEvent(() => {
    if (currentColumnsState == null) {
      throw new Error('currentColumnsState state cannot be null when saving.');
    }

    onSave(currentColumnsState);
    onClose();
  });

  // Reset the state when the modal switches between visible and hidden.
  useEffect(() => {
    if (isContentVisible) {
      reset();
    } else {
      setCurrentColumnsState(null);
    }
  }, [reset, isContentVisible]);

  if (currentColumnsState == null) return null;

  return (
    <Modal
      show={show}
      className={className}
      onHide={onClose}
      title="COLUMNS"
      onEnter={setIsContentVisibleOverrideTrue}
      onExited={setIsContentVisibleOverrideFalse}
    >
      <StyledDialogBodyDiv>
        <StyledListDiv>
          {columns.map((column) => (
            <ColumnsModalListItem
              key={column.field}
              column={column}
              onChange={handleChange}
              show={Boolean(ExamsGridService.getColumnState(currentColumnsState, column.field).show)}
            />
          ))}
        </StyledListDiv>

        <StyledActionButtonsDiv>
          <Button onClick={handleSaveClick}>Apply Changes</Button>
        </StyledActionButtonsDiv>
      </StyledDialogBodyDiv>
    </Modal>
  );
});

ColumnsModal.displayName = 'ColumnsModal';

const StyledDialogBodyDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  padding: 24px;
`;

const StyledListDiv = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledActionButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 0 0;
`;
