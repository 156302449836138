import { FunctionComponent, memo, useEffect, useRef } from 'react';

import { CanvasAsyncSpriteProps } from '../types';

export const CanvasAsyncSprite: FunctionComponent<CanvasAsyncSpriteProps> =
  memo(({ bitmap, width, height }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
      (async () => {
        // Clear the canvas while we await the bitmap promise to fulfill.
        let renderContext = canvasRef.current?.getContext('2d');
        safariHackCanvasReset(renderContext);
        const bitmapResult = await bitmap;

        // The canvasRef will be set to null if the component is destroyed while we were awaiting the bitmap promise.  So just return early.
        if (canvasRef.current == null) return;

        renderContext = canvasRef.current.getContext('2d'); // Get a new context in case the DOM was changed while we were awaiting the bitmap promise to fulfill.
        safariHackCanvasReset(renderContext);
        renderContext?.drawImage(bitmapResult, 0, 0);
      })();

      // Need to store the canvas ref in a temp variable because DOM refs are set to null by React on unmount.
      const currentCanvasEle = canvasRef.current;

      return () => {
        const renderContext = currentCanvasEle?.getContext('2d');
        safariHackCanvasReset(renderContext);
      };
    }, [bitmap]);

    return <canvas ref={canvasRef} width={width} height={height} />;
  });

CanvasAsyncSprite.displayName = 'CanvasAsyncSprite';

function safariHackCanvasReset(
  canvasContext: CanvasRenderingContext2D | null | undefined,
) {
  // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/reset
  // https://caniuse.com/mdn-api_canvasrenderingcontext2d_reset

  // Resetting the canvas isn't super important.  So we will just skip it for browsers that don't support the reset (Safari).
  if (canvasContext != null && 'reset' in canvasContext) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (canvasContext as any | null)?.reset();
  }
}
