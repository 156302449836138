import _orderBy from 'lodash/orderBy';
import { normalize } from 'normalizr';

// eslint-disable-next-line import/no-unresolved
import { ApiActions, createAsyncThunk } from 'core/api';

import { FileRouteService } from '../services';
import { fileEntity } from './normalizr-schema';

const add = createAsyncThunk('files/add', async ({ files, shouldSplitFiles, examId }, { dispatch }) => {
  const { result } = await dispatch(
    ApiActions.post({
      url: `${FileRouteService.getBaseRoute()}?splitFiles=${shouldSplitFiles}&examId=${examId}`,
      body: files,
      headers: { Accept: '*/*' },
      options: { isNotJSON: true },
    }),
  );

  const normalized = normalize(result, [fileEntity]);

  return normalized.entities;
});

const getFilesByExamId = createAsyncThunk('files/get-by-exam-id', async (examId, { dispatch }) => {
  const queryStr = `examId=${examId}`;
  const baseUrl = FileRouteService.getBaseRoute();
  const url = `${baseUrl}?${queryStr}`;

  const { result } = await dispatch(ApiActions.get(url));

  const normalized = normalize(result, [fileEntity]);

  const sorted = _orderBy(normalized.entities.files, 'index', 'asc');
  return sorted;
});

const changeCategory = createAsyncThunk('files/change-category', async ({ fileId, categoryId }, { dispatch }) => {
  const queryStr = `categoryId=${categoryId}`;
  const baseUrl = FileRouteService.getChangeCategoryRoute(fileId);
  const url = `${baseUrl}?${queryStr}`;

  await dispatch(ApiActions.put(url));

  return fileId;
});

const deleteFile = createAsyncThunk('files/delete', async (fileId, { dispatch }) => {
  await dispatch(ApiActions.remove(FileRouteService.getByIdRoute(fileId)));

  return fileId;
});

const rotate = createAsyncThunk('files/rotate-file', async (data, { dispatch }) => {
  const { result } = await dispatch(
    ApiActions.post({
      url: FileRouteService.getRotateFileRoute(),
      body: data,
    }),
  );

  return result;
});

const generateAzureBlobUploadSas = createAsyncThunk('file/generate-azure-blob-upload-sas', async (_, { dispatch }) => {
  const url = FileRouteService.generateAzureBlobUploadSasRoute();

  return dispatch(ApiActions.get(`${url}`));
});

export const FileActions = {
  add,
  changeCategory,
  deleteFile,
  getFilesByExamId,
  rotate,
  generateAzureBlobUploadSas,
};
