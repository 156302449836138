import { FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { Button } from '../../../Button';
import { ComponentSizes } from '../../../constants';
import { ButtonCellProps } from './ButtonCellProps';

export const ButtonCell: FunctionComponent<ButtonCellProps> = ({ onClick }) => {
  const content = useMemo(
    () => (
      <StyledDivCellWrapper>
        <Button size={ComponentSizes.SMALL} onClick={onClick}>
          Select
        </Button>
      </StyledDivCellWrapper>
    ),
    [onClick],
  );

  return content;
};

ButtonCell.displayName = 'ButtonCell';

const StyledDivCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space.spacing20};
`;
