import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from 'features/main/hooks';

import { LocationTypeActions, LocationTypeSelectors } from '../redux';

export const useLocationTypes = () => {
  const dispatch = useAppDispatch();

  const locationTypes = useSelector(LocationTypeSelectors.getAll);

  useEffect(() => {
    dispatch(LocationTypeActions.getAll(undefined));
  }, [dispatch]);

  return [locationTypes];
};
