import { ColumnState } from 'features/exam/types/ColumnState';

export const DefaultColumnsState: Record<string, ColumnState> = {
  id: { show: true, orderIndex: 1 },
  description: { show: true, orderIndex: 2 },
  notes: { show: true, orderIndex: 3 },
  shortCode: { show: true, orderIndex: 4 },
  longCode: { show: true, orderIndex: 5 },
  parentService: { show: true, orderIndex: 6 },
  cpt: { show: true, orderIndex: 7 },
  active: { show: true, orderIndex: 8 },
};
