import { createContext } from 'react';

import { BreakpointContextType } from './types';

export const BreakpointContext = createContext<BreakpointContextType>({
  desktop: true,
  desktopShort: false,
  mobile: false,
  portrait: false,
  // Desktops typically operate in landscape mode.
  landscape: true,
  verticalClamp: true,
  setVerticalClampOverride:
    undefined as unknown as BreakpointContextType['setVerticalClampOverride'],
});

BreakpointContext.displayName = 'BreakpointContext';
