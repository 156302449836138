import { useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useEvent } from 'core/hooks';
import {
  ActionListCell,
  DEFAULT_DATA_TABLE_DATA_STATE,
  DataTable,
  HeaderCell,
  Page,
  PageHeader,
  SwitchCell,
  TextCell,
  Toolbar, // eslint-disable-next-line import/no-unresolved
} from 'core/ui';

// eslint-disable-next-line import/no-unresolved, import/extensions
import { GroupActions, GroupSelectors } from '../redux';

export const GroupHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const groups = useSelector(GroupSelectors.getAllGrid);
  const total = useSelector(GroupSelectors.getTotalGrid);

  const gridPDFExport = useRef(null);
  const gridExcelExport = useRef(null);

  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  useEffect(() => {
    dispatch(GroupActions.getAllForKendoGrid(dataState));
  }, [dispatch, dataState]);

  const handleDataStateChange = (changeEvent) => {
    setDataState(changeEvent.dataState);
  };

  const debouncedChangeDataStateHandler = useMemo(() => debounce(handleDataStateChange, 500), []);

  useEffect(() => {
    return () => debouncedChangeDataStateHandler.cancel();
  }, [debouncedChangeDataStateHandler]);

  const handleExportPDFClick = () => {
    if (gridPDFExport.current !== null) {
      gridPDFExport.current.save();
    }
  };

  const handleExportExcelClick = async () => {
    if (gridExcelExport.current !== null) {
      const res = await dispatch(
        GroupActions.getAllForExport({
          skip: 0,
          take: total,
          filter: dataState.filter,
        }),
      );

      gridExcelExport.current.save(res.payload?.data);
    }
  };

  const handleAddNewClick = () => {
    navigate('/group/add');
  };

  const handleEditClick = useEvent((dataItem) => navigate(`/group/edit/${dataItem.id}`));

  const gridActions = useMemo(() => {
    return [
      {
        key: 'edit-group',
        title: 'Edit Group',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  const grid = (
    <DataTable
      filterable
      pageable={{ pageSizes: true }}
      reorderable
      sortable
      total={total}
      data={groups}
      {...dataState}
      onDataStateChange={handleDataStateChange}
      actions={gridActions}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPDFClick} />
      <GridColumn title="Action" filterable={false} sortable={false} headerCell={HeaderCell} cell={ActionListCell} width="80px" />
      <GridColumn cell={TextCell} field="name" filter="text" headerCell={HeaderCell} title="Name" width="350px" />
      <GridColumn cell={TextCell} field="uploadURL" filter="numeric" headerCell={HeaderCell} title="Upload URL" width="200px" />
      <GridColumn cell={TextCell} field="approvalEmail" filter="text" headerCell={HeaderCell} title="Approval Email" width="200px" />
      <GridColumn cell={SwitchCell} field="approveAutomatically" filter="text" headerCell={HeaderCell} title="Approve Automatically" width="200px" />
      <GridColumn cell={TextCell} field="signinMethod" filter="text" headerCell={HeaderCell} title="Signin Method" width="250px" />
      <GridColumn cell={SwitchCell} field="active" filter="text" headerCell={HeaderCell} title="Active" width="150px" />
    </DataTable>
  );

  return (
    <Page>
      <PageHeader title="Group" />
      <GridPDFExport ref={gridPDFExport}>{grid}</GridPDFExport>
      <ExcelExport ref={gridExcelExport}>{grid}</ExcelExport>
    </Page>
  );
};
