import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import _forEach from 'lodash/forEach';

import { Worklist } from 'models';

import { WorklistStatus } from '../constants';
import { WorklistActions } from './actions';

export const worklistAdapter = createEntityAdapter<Worklist>();

const getReducerInitialState = () => ({
  ...worklistAdapter.getInitialState(),
  status: WorklistStatus.PENDING,
  selectedWorklistId: '',
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(WorklistActions.getAll.fulfilled, (state, action) => {
    const payload = action.payload as { worklists: Worklist[] | null };

    worklistAdapter.removeAll(state);
    worklistAdapter.addMany(state, payload.worklists ?? []);
  });
  builder.addCase(WorklistActions.getCounts.fulfilled, (state, action) => {
    const payload = action.payload as Record<string, number>;

    const worklists = state.entities;

    _forEach(worklists, (worklist) => {
      if (worklist) {
        // eslint-disable-next-line no-param-reassign
        worklist.count = payload[worklist.id!];
      }
    });
  });
  builder.addCase(WorklistActions.add.fulfilled, (state, action) => {
    const payload = action.payload as { worklists: Record<string, Worklist> };

    worklistAdapter.upsertMany(state, payload.worklists);
    state.status = WorklistStatus.ADDED;
  });
  builder.addCase(WorklistActions.edit.fulfilled, (state, action) => {
    const payload = action.payload as { worklists: Record<string, Worklist> };

    worklistAdapter.setMany(state, payload.worklists);
    state.status = WorklistStatus.UPDATED;
  });
  builder.addCase(WorklistActions.remove.fulfilled, (state, action) => {
    const payload = action.payload as Worklist;

    worklistAdapter.removeOne(state, payload.id!);
  });
  builder.addCase(WorklistActions.select, (state, action) => {
    state.selectedWorklistId = action.payload;
  });
});
