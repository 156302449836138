import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { SourceRoutes } from '../routes';
import { SourceForm } from './SourceForm';
import { SourceHome } from './SourceHome';

export const Source: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={SourceRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <SourceHome />
          </RouteGuard>
        }
      />
      <Route
        path={SourceRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={SourceHome}>
            <SourceForm />
          </RouteGuard>
        }
      />
      <Route
        path={SourceRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={SourceHome}>
            <SourceForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

Source.displayName = 'Source';
