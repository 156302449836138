import { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Form, FormElement } from 'core/forms';
import { Accordion, Button, ButtonVariants } from 'core/ui';

import { apiClient } from 'features/api';
import { TemplateType } from 'features/template';

import { CoordinatorInfoAccordion } from '../../exam';
import { PatientInfoAccordion } from '../../patient';
import { PriorExamsAccordion } from '../../prior-exam';
import { ExamReadSubmitType } from '../constants';
import { ExamTypeService, InitialValuesService } from '../services';
import { ExamDetailsAccordion } from './ExamDetailsAccordion';
import { ResultsAccordion } from './ResultsAccordion';
import { TagsAccordion } from './TagsAccordion';

const ExamReadSidebarBody = ({ exam, handleSubmit, handleNextExamClick, handlePrevExamClick }) => {
  const isPreviewFlow = useMemo(
    () => ExamTypeService.IsPathology(exam?.service?.description) || ExamTypeService.IsPulmonology(exam?.service?.description),
    [exam],
  );

  const initialState = useMemo(() => (exam ? InitialValuesService.getInitialValues(exam) : {}), [exam]);

  const [isResultsFormVisible, setIsResultsFormVisible] = useState(true);

  const handleReadSubmit = useCallback(
    (data) => {
      if (!data.isValid) {
        return;
      }

      // only show addendum form if the report is being approved
      const submitType = data.event.nativeEvent.submitter.id;
      if (submitType === ExamReadSubmitType.APPROVE || submitType === ExamReadSubmitType.APPROVE_AND_NEXT) {
        setIsResultsFormVisible(false);
      }
      handleSubmit(data);
    },
    [handleSubmit],
  );

  useEffect(() => {
    setIsResultsFormVisible(!exam?.reads?.length);
  }, [exam]);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const initModal = async () => {
      const templateResponse = await apiClient.templateClient.getTemplates(TemplateType.Read);
      setTemplates(templateResponse);
    };
    initModal();
  }, []);

  console.log('ExamReadSidebarBody', { initialState });

  return (
    <StyledBodyDiv>
      <StyledAccordion alwaysOpen defaultActiveKey={['4', '5', '6', '10']}>
        <PatientInfoAccordion eventKey="1" patient={exam?.patient} />
        <PriorExamsAccordion eventKey="2" exam={exam} />
        <CoordinatorInfoAccordion eventKey="3" coordinator={exam?.coordinator} />
        <Form
          id="exam-read-sidebar-form"
          initialValues={initialState}
          key={`${exam?.id}-read-form`}
          onSubmitClick={handleReadSubmit}
          render={({ onChange: valueSetter, valueGetter }) => {
            const studyType = valueGetter('service.description');
            return (
              <FormElement>
                <ExamDetailsAccordion eventKey="4" exam={exam} studyType={studyType} valueGetter={valueGetter} valueSetter={valueSetter} />
                <ResultsAccordion
                  exam={exam}
                  isResultFormVisible={isResultsFormVisible}
                  valueGetter={valueGetter}
                  valueSetter={valueSetter}
                  template={templates}
                  textField="name"
                  dataItemKey="id"
                  fieldName="overRead"
                />
                <TagsAccordion
                  valueGetter={valueGetter}
                  valueSetter={valueSetter}
                  // eslint-disable-next-line react/prop-types
                  value={exam?.tags}
                  eventKey="10"
                  examId={exam?.id}
                  locationId={exam?.location?.id}
                />
              </FormElement>
            );
          }}
        />
      </StyledAccordion>
      <StyledButtonsRow>
        <Col className="col-4">
          <StyledPrevButton disabled={!handlePrevExamClick} variant={ButtonVariants.SECONDARY} onClick={handlePrevExamClick}>
            {'<'}
          </StyledPrevButton>
          <StyledNextButton disabled={!handleNextExamClick} variant={ButtonVariants.SECONDARY} onClick={handleNextExamClick}>
            {'>'}
          </StyledNextButton>
        </Col>
        {!isResultsFormVisible && (
          <Col className="col-8">
            <StyledActionButton disabled={exam?.locked} onClick={() => setIsResultsFormVisible(true)}>
              Add Addendum
            </StyledActionButton>
          </Col>
        )}
        {isResultsFormVisible && (
          <>
            <Col className="col-8">
              {isPreviewFlow && (
                <>
                  <Button form="exam-read-sidebar-form" id={ExamReadSubmitType.SAVE} type="submit">
                    Save
                  </Button>
                  <StyledSubmitButton form="exam-read-sidebar-form" id={ExamReadSubmitType.FINALIZE} type="submit">
                    Finalize
                  </StyledSubmitButton>
                </>
              )}
              {!isPreviewFlow && (
                <>
                  <Button form="exam-read-sidebar-form" id={ExamReadSubmitType.APPROVE} type="submit">
                    Approve
                  </Button>
                  <StyledSubmitButton disabled={!handleNextExamClick} form="exam-read-sidebar-form" id={ExamReadSubmitType.APPROVE_AND_NEXT} type="submit">
                    Approve & Next
                  </StyledSubmitButton>
                </>
              )}
            </Col>
          </>
        )}
      </StyledButtonsRow>
    </StyledBodyDiv>
  );
};

const StyledButtonsRow = styled(Row)`
  background: white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  margin-right: 0;
  padding: ${({ theme }) => theme.space.spacing30};
`;

const StyledPrevButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing10};
`;

const StyledNextButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing30};
`;

const StyledActionButton = styled(Button)`
  width: 100% !important;
`;

const StyledSubmitButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing30};
`;

const StyledAccordion = styled(Accordion)`
  max-height: 93vh;
  overflow-y: auto;
`;

const StyledBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  max-height: 97vh;
`;

ExamReadSidebarBody.propTypes = {
  exam: PropTypes.shape({
    coordinator: PropTypes.shape({}),
    id: PropTypes.number,
    locked: PropTypes.bool,
    location: PropTypes.shape({}),
    organ: PropTypes.number,
    patient: PropTypes.shape({}),
    reads: PropTypes.arrayOf(PropTypes.shape({})),
    service: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  handleNextExamClick: PropTypes.func,
  handlePrevExamClick: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
};

ExamReadSidebarBody.defaultProps = {
  exam: undefined,
  handleNextExamClick: undefined,
  handlePrevExamClick: undefined,
};

export { ExamReadSidebarBody };
