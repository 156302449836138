import { FunctionComponent } from 'react';

import { SettingsOverrideEditor } from 'features/settings';

import { SettingsTabContentProps } from '../types';

export const SettingsTabContent: FunctionComponent<SettingsTabContentProps> = (
  props,
) => {
  return <SettingsOverrideEditor {...props} />;
};

SettingsTabContent.displayName = 'SettingsTabContent';
