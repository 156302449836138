import { memo, useCallback, useMemo } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { useEvent } from 'core/hooks';
import { Button, DropdownField, InputField, Label, SwitchField } from 'core/ui';

import { ACCOUNT_EDIT_VALIDATORS } from '../constants';
import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledRhfForm('min-content 400px');

const NULL_PARENT_OPTION = { id: null, name: '' };

export const GeneralTabContent = memo<GeneralTabContentProps>(
  ({ original, values, allAccounts, onChange, onSubmit }) => {
    const rhfContext = useForm<GeneralTabFormValues>({ defaultValues: values });

    const parentAccountOptions = useMemo(
      () =>
        allAccounts?.filter(
          (a) =>
            a.id !== original?.id &&
            (a.active || a.id === original?.parentAccountId),
        ) ?? null,
      [allAccounts, original],
    );

    const handleChange = useEvent(() => {
      onChange(rhfContext.getValues());
    });

    const handleSubmit: SubmitHandler<GeneralTabFormValues> =
      useCallback(() => {
        onSubmit();
      }, [onSubmit]);

    return (
      <FormProvider {...rhfContext}>
        <StyledFormElement
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          noValidate
          onSubmit={rhfContext.handleSubmit(handleSubmit)}
        >
          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="name" required>
              Name
            </Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <InputField
                name="name"
                required
                validator={ACCOUNT_EDIT_VALIDATORS.GENERAL.name}
                onChange={handleChange}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="quickBooksId">QuickBooks</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <InputField
                name="quickBooksId"
                validator={ACCOUNT_EDIT_VALIDATORS.GENERAL.quickBooksId}
                onChange={handleChange}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="customerKey">Customer</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <InputField
                name="customerKey"
                validator={ACCOUNT_EDIT_VALIDATORS.GENERAL.customerKey}
                onChange={handleChange}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="parentAccountId">Parent Account</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <DropdownField
                name="parentAccount"
                data={parentAccountOptions}
                dataItemKey="id"
                textField="name"
                onChange={handleChange}
                defaultItem={NULL_PARENT_OPTION}
              />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1" isLabelColumn>
            <Label editorId="active">Active</Label>
          </GridColumn>
          <GridColumn columnStart="2">
            <FieldContainer $hideLabel>
              <SwitchField name="active" onChange={handleChange} />
            </FieldContainer>
          </GridColumn>

          <GridColumn columnStart="1">
            <div>
              <Button type="submit">Save</Button>
            </div>
          </GridColumn>
        </StyledFormElement>
      </FormProvider>
    );
  },
);

GeneralTabContent.displayName = 'GeneralTabContent';
