import {
  AccountModel,
  DynamicFormFieldModel,
  DynamicFormModel,
  FormFieldModel,
  ItemModel,
  SettingOverrideModel,
} from 'models';

import { equalsInsensitive, findOrThrow, hasText } from 'core/utils';

import { apiClient } from 'features/api';

import {
  ACCOUNT_INITIAL_VALUES,
  PRICING_FIELD_CATEGORY_NAME,
  PRICING_RULE_INITIAL_VALUES,
} from '../constants';
import { GeneralTabFormValues, RuleEditorFormValues } from '../types';

async function initializeEditPage(accountId: number | undefined) {
  const accountQuery: Promise<AccountModel | null> =
    accountId == null
      ? new Promise((resolve) => {
          resolve(null);
        })
      : apiClient.accountsClient.getAccountById(accountId);

  const rulesQuery: Promise<DynamicFormModel | null> =
    accountId == null
      ? new Promise((resolve) => {
          resolve(null);
        })
      : apiClient.dynamicFormsClient.getDynamicFormFor('Account', accountId);

  const fetchResults = await Promise.all([
    apiClient.accountsClient.getAllAccounts(),
    apiClient.fieldCategoryClient.getAllFieldCategories(),
    apiClient.itemClient.getAllItems(),
    rulesQuery,
    accountQuery,
  ]);

  const newAccount = fetchResults[4];

  const newPricingCategory = findOrThrow(
    fetchResults[1],
    (c) => c.active && equalsInsensitive(c.name, PRICING_FIELD_CATEGORY_NAME),
    `Could not find active field category "${PRICING_FIELD_CATEGORY_NAME}".`,
  );

  const newAllFormFields =
    await apiClient.formFieldClient.getFormFieldsByCategory(
      newPricingCategory.id,
    );

  const newForms =
    newAccount == null
      ? {
          generalTab: ACCOUNT_INITIAL_VALUES.GENERAL,
          settingOverrides: ACCOUNT_INITIAL_VALUES.SETTING_OVERRIDES,
        }
      : AccountEditService.copyModelToForms(newAccount, fetchResults[0]);

  // Need to construct an empty pricing form if we couldn't find one.
  let newRules: DynamicFormModel;
  if (accountId == null || fetchResults[3] == null) {
    newRules = {
      entityType: 'Account',
      entityId: accountId ?? 0,
      fields: [],
    };
  } else {
    newRules = fetchResults[3];
  }

  return {
    allAccounts: fetchResults[0],
    account: newAccount,
    pricingCategory: newPricingCategory,
    allItems: fetchResults[2],
    allFormFields: newAllFormFields,
    generalTab: newForms.generalTab,
    settingOverrides: newForms.settingOverrides,
    rules: newRules,
  };
}

function copyModelToForms(
  account: AccountModel,
  allAccounts: AccountModel[],
): {
  generalTab: GeneralTabFormValues;
  settingOverrides: SettingOverrideModel[];
} {
  const parentAccount =
    account.parentAccountId == null
      ? null
      : findOrThrow(
          allAccounts,
          (a) => a.id === account.parentAccountId,
          `Could not find parent account with id ${account.parentAccountId}.`,
        );

  return {
    generalTab: {
      name: account.name,
      quickBooksId: account.quickBooksId ?? '',
      customerKey: account.customerKey ?? '',
      parentAccount,
      active: account.active,
    },
    settingOverrides: account.settingOverrides,
  };
}

function copyFormsToModel(
  baseModel: Pick<AccountModel, 'id' | 'contractorCode'>,
  generalTab: GeneralTabFormValues,
  settingOverrides: SettingOverrideModel[],
) {
  const newModel: AccountModel = {
    ...baseModel,
    name: generalTab.name.trim(),
    quickBooksId: hasText(generalTab.quickBooksId)
      ? generalTab.quickBooksId.trim()
      : null,
    customerKey: hasText(generalTab.customerKey)
      ? generalTab.customerKey.trim()
      : null,
    parentAccountId: generalTab.parentAccount?.id ?? null,
    settingOverrides,
    active: generalTab.active,
  };

  return newModel;
}

function copyRulesToForm(
  rules: DynamicFormModel,
  selectedIndex: number | 'new' | null,
  allItems: ItemModel[],
  allFields: FormFieldModel[],
): RuleEditorFormValues {
  if (typeof selectedIndex !== 'number') {
    return PRICING_RULE_INITIAL_VALUES;
  }

  const rule = rules.fields[selectedIndex];

  const field = findOrThrow(
    allFields,
    (f) => f.id === rule.fieldId,
    `Could not find field with id: ${rule.fieldId}.`,
  );

  const item = findOrThrow(
    allItems,
    (i) => i.id === rule.itemId,
    `Could not find item with id: ${rule.itemId}.`,
  );

  return {
    item,
    field,
    value: rule.value,
  };
}

function filterItemOptions(
  selectedField: FormFieldModel | null,
  allItems: ItemModel[],
  existingRules: DynamicFormFieldModel[],
  editMode: 'new' | 'existing' | null,
) {
  // We disable the item and field dropdown menus when editing an existing rule.  So we don't need to worry about filtering the dropdowns.
  if (editMode !== 'new' || selectedField == null) return allItems;

  // Find the item ids that have already been paired with the selected field id.
  const usedItemIds = existingRules
    .filter((r) => r.fieldId === selectedField.id)
    .map((r) => r.itemId);

  // Remove the items that have already been paired with the selected field id.
  const result = allItems.filter((i) => !usedItemIds.includes(i.id));
  return result;
}

function filterFieldOptions(
  selectedItem: ItemModel | null,
  allFields: FormFieldModel[],
  existingRules: DynamicFormFieldModel[],
  editMode: 'new' | 'existing' | null,
) {
  // We disable the item and field dropdown menus when editing an existing rule.  So we don't need to worry about filtering the dropdowns.
  if (editMode !== 'new' || selectedItem == null) return allFields;

  // Find the field ids that have already been paired with the selected item id.
  const usedFieldIds = existingRules
    .filter((r) => r.itemId === selectedItem.id)
    .map((r) => r.fieldId);

  // Remove the fields that have already been paired with the selected item id.
  const result = allFields.filter((f) => !usedFieldIds.includes(f.id));
  return result;
}

export const AccountEditService = {
  initializeEditPage,
  copyModelToForms,
  copyFormsToModel,
  copyRulesToForm,
  filterItemOptions,
  filterFieldOptions,
};
