import { memo, useCallback } from 'react';

import {
  ComboBoxBlurEvent,
  ComboBoxChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { useController } from 'react-hook-form';

import { ComboBox } from './ComboBox';
import { ComboBoxFieldProps } from './ComboBoxFieldProps';

export const ComboBoxField = memo<ComboBoxFieldProps>(
  ({ name, validator, onChange, onBlur, ...rest }) => {
    const {
      field: { onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldRest },
      formState,
      fieldState: { isTouched, invalid, error },
    } = useController({ name, rules: { validate: validator } });

    const handleChange = useCallback(
      (event: ComboBoxChangeEvent) => {
        rhfOnChange(event);
        onChange?.(event);
      },
      [onChange, rhfOnChange],
    );

    const handleBlur = useCallback(
      (event: ComboBoxBlurEvent) => {
        rhfOnBlur();
        onBlur?.(event);
      },
      [onBlur, rhfOnBlur],
    );

    return (
      <ComboBox
        {...fieldRest}
        {...rest}
        valid={!invalid}
        visited={formState.isSubmitted || isTouched}
        validationMessage={error?.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  },
);

ComboBoxField.displayName = 'ComboBoxField';
