import { CompressCompleteEvent, CompressErrorEvent, CompressProgressEvent, CompressStartEvent } from '@repo/web-workers/file-compressor';
import dayjs from 'dayjs';

import { ExamModel, PatientModel, ServiceModel } from 'models';

import { DataStream, DataStreamMap, IDataStreamConsumer, IDataStreamMapConsumer, IntervalMergeCalculator } from 'core/utils';

import { DicomMatcher } from 'features/file/services/DicomMatcher';
import { UploadManager } from 'features/file/services/UploadManager';
import { FileContext } from 'features/file/types/FileContext';
import { FileState } from 'features/file/types/FileState';
import { MatchCompleteEvent } from 'features/file/types/MatchCompleteEvent';
import { ParseCompleteEvent } from 'features/file/types/ParseCompleteEvent';
import { QueueFilesEvent } from 'features/file/types/QueueFilesEvent';
import { Series } from 'features/file/types/Series';
import { UploadCompleteEvent } from 'features/file/types/UploadCompleteEvent';
import { UploadErrorEvent } from 'features/file/types/UploadErrorEvent';
import { UploadFileContext } from 'features/file/types/UploadFileContext';
import { UploadProgressEvent } from 'features/file/types/UploadProgressEvent';
import { UploadStartEvent } from 'features/file/types/UploadStartEvent';

import { UploadGroup, UploadGroupState } from '../types';
import { ExamUploadService } from './exam-upload-service';

export class UploadView {
  static [Symbol.toStringTag]() {
    return 'AzureBlobUploader';
  }

  private _cleanupFunctions: (() => void)[] = [];

  /** The key is the fileId. */
  private _files = new Map<string, FileState>();

  /** The key is the `uploadGroupId`. */
  private _groupProcessingIntervals = new Map<string, IntervalMergeCalculator>();

  private _streams = {
    groupStates: new DataStreamMap<string, UploadGroupState>(),
    groups: new DataStream<UploadGroup[]>([]),
  };

  public get streams(): {
    groupStates: IDataStreamMapConsumer<string, UploadGroupState>;
    groups: IDataStreamConsumer<UploadGroup[]>;
  } {
    return this._streams;
  }

  private _fixedPatient: PatientModel | null = null;

  private _fixedExam: ExamModel | null = null;

  private _fixedLocationId: number | null = null;

  private _allServices: ServiceModel[] | null = null;

  private _dicomMatcher = new DicomMatcher();

  constructor(
    private uploadManager: UploadManager,
    initialUploadGroups: UploadFileContext[] = [],
  ) {
    this.initialize = this.initialize.bind(this);
    this.destroy = this.destroy.bind(this);
    this.getUploadGroupId = this.getUploadGroupId.bind(this);
    this.mergeFileStateChanges = this.mergeFileStateChanges.bind(this);
    this.addUploadGroups = this.addUploadGroups.bind(this);
    this.handleParseComplete = this.handleParseComplete.bind(this);
    this.handleMatchComplete = this.handleMatchComplete.bind(this);
    this.handleQueueFiles = this.handleQueueFiles.bind(this);
    this.handleCompressStart = this.handleCompressStart.bind(this);
    this.handleCompressProgress = this.handleCompressProgress.bind(this);
    this.handleCompressComplete = this.handleCompressComplete.bind(this);
    this.handleCompressError = this.handleCompressError.bind(this);
    this.handleUploadStart = this.handleUploadStart.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUploadError = this.handleUploadError.bind(this);

    const { groupStates, fileStates, groupProcessingIntervals } = this.addUploadGroups(initialUploadGroups);

    this._files = fileStates;
    this._groupProcessingIntervals = groupProcessingIntervals;
    this._streams.groupStates.emitMultiple(groupStates);
  }

  public initialize(fixedLocationId: number, fixedPatient: PatientModel | null, fixedExam: ExamModel | null, allServices: ServiceModel[]) {
    this._dicomMatcher.initialize();
    this._dicomMatcher.setFixedEntities(fixedPatient, fixedExam);

    this._fixedLocationId = fixedLocationId;
    this._fixedPatient = fixedPatient;
    this._fixedExam = fixedExam;
    this._allServices = allServices;

    this._cleanupFunctions.push(this.uploadManager.fileScanner.streams.onParseComplete.subscribe(this.handleParseComplete));
    this._cleanupFunctions.push(this._dicomMatcher.streams.onMatchComplete.subscribe(this.handleMatchComplete));
    this._cleanupFunctions.push(this.uploadManager.streams.onQueueFiles.subscribe(this.handleQueueFiles));
    this._cleanupFunctions.push(this.uploadManager.fileCompressor.streams.onStart.subscribe(this.handleCompressStart));
    this._cleanupFunctions.push(this.uploadManager.fileCompressor.streams.onProgress.subscribe(this.handleCompressProgress));
    this._cleanupFunctions.push(this.uploadManager.fileCompressor.streams.onComplete.subscribe(this.handleCompressComplete));
    this._cleanupFunctions.push(this.uploadManager.fileCompressor.streams.onError.subscribe(this.handleCompressError));
    this._cleanupFunctions.push(this.uploadManager.blobUploader.streams.onStart.subscribe(this.handleUploadStart));
    this._cleanupFunctions.push(this.uploadManager.blobUploader.streams.onProgress.subscribe(this.handleUploadProgress));
    this._cleanupFunctions.push(this.uploadManager.blobUploader.streams.onComplete.subscribe(this.handleUploadComplete));
    this._cleanupFunctions.push(this.uploadManager.blobUploader.streams.onError.subscribe(this.handleUploadError));
  }

  public destroy() {
    this._cleanupFunctions.forEach((cleanup) => cleanup());
    this._cleanupFunctions = [];
    this._streams.groupStates.clear();
    this._streams.groups.clear();
    this._groupProcessingIntervals.clear();
  }

  public getUploadGroupId(fileId: string): string | null {
    const uploadGroupId = this._files.get(fileId)?.uploadGroupId;

    return uploadGroupId ?? null;
  }

  private mergeFileStateChanges(fileId: string, changes: Partial<FileState>): FileState {
    const current = this._files.get(fileId);

    if (current == null) throw new Error('File not found.');

    const newFileState = { ...current, ...changes, fileId };
    this._files.set(fileId, newFileState);

    return newFileState;
  }

  /** Initialize the states for the specified upload items.  All metrics, durations, etc. that cannot be calculated directly from the uploadGroups parameter are going to be set to 0 or null. */
  private addUploadGroups(fileContexts: UploadFileContext[]) {
    const newGroupStates = new Map<string, UploadGroupState>(); // The key is the uploadGroupId.
    const newFileStates = new Map<string, FileState>(this._files);
    const newGroupIntervals = new Map(this._groupProcessingIntervals);

    for (const fileContext of fileContexts) {
      const existingGroupState = newGroupStates.get(fileContext.uploadGroupId) ?? this._streams.groupStates.getCurrentValue(fileContext.uploadGroupId, false);

      if (existingGroupState == null) {
        newGroupStates.set(fileContext.uploadGroupId, {
          uploadGroupId: fileContext.uploadGroupId,
          compressedFiles: 0,
          totalFiles: 1,
          uploadedFiles: 0,
          errorFiles: 0,
          duration: null,
          totalSize: fileContext.file.size,
          compressedSize: 0,
          uploadSize: 0,
          weightedUploadSize: 0,
        });
      } else {
        newGroupStates.set(fileContext.uploadGroupId, {
          ...existingGroupState,
          totalFiles: existingGroupState.totalFiles + 1,
          totalSize: existingGroupState.totalSize + fileContext.file.size,
        });
      }

      let groupIntervals = newGroupIntervals.get(fileContext.uploadGroupId);

      if (groupIntervals == null) {
        groupIntervals = new IntervalMergeCalculator();
        newGroupIntervals.set(fileContext.uploadGroupId, groupIntervals);
      }

      let fileState = newFileStates.get(fileContext.fileId);

      if (!fileState) {
        fileState = {
          uploadGroupId: fileContext.uploadGroupId,
          fileId: fileContext.fileId,
          fileName: fileContext.file.name,
          state: 'queued',
          fileSize: fileContext.file.size,
          compressedSize: null,
          uploadStart: null,
          uploadEnd: null,
          uploadSize: 0,
        };

        newFileStates.set(fileContext.fileId, fileState);
      }
    }

    return {
      groupStates: newGroupStates,
      fileStates: newFileStates,
      groupProcessingIntervals: newGroupIntervals,
    };
  }

  private handleParseComplete(event: ParseCompleteEvent) {
    if (this._allServices == null) throw new Error('Cannot proceed because allServices is null.');

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];
    const newFileContext: FileContext = {
      fileId: event.fileId,
      file: event.file,
      dicomData: event.dicomData,
    };
    const newSeriesItem: Series | null =
      event.seriesId == null
        ? null
        : {
            seriesId: event.seriesId,
            dicomData: event.dicomData,
          };

    const existingUploadGroupIndex = newUploadGroups.findIndex(
      (ui) => ui.dicomData?.StudyInstanceUID != null && ui.dicomData?.StudyInstanceUID === event.dicomData?.StudyInstanceUID,
    );
    const existingUploadGroup = existingUploadGroupIndex >= 0 ? newUploadGroups[existingUploadGroupIndex] : null;

    if (existingUploadGroup == null) {
      const uploadGroup: UploadGroup = {
        uploadGroupId: crypto.randomUUID(),
        files: [newFileContext],
        attachments: [],
        series: newSeriesItem == null ? [] : [newSeriesItem],
        dicomData: event.dicomData,
        uncompressedDicomWarning: event.uncompressedDicomWarning,
        checked: true,
        patient: this._fixedPatient,
        exam: this._fixedExam,
        patientForm: this._fixedPatient ? ExamUploadService.patientModelToForm(this._fixedPatient) : ExamUploadService.patientDicomToForm(event.dicomData),
        examForm: this._fixedExam
          ? ExamUploadService.examModelToForm(this._fixedExam, this._allServices)
          : ExamUploadService.examDicomToForm(event.dicomData, this._allServices),
        patientFormState: { isDirty: false, isValid: this._fixedPatient ? true : false, isValidating: false },
        examFormState: { isDirty: false, isValid: this._fixedExam ? true : false, isValidating: false },
      };

      newUploadGroups.push(uploadGroup);
    } else {
      // Update an existing upload group.
      const existingSeries = event.seriesId == null ? null : existingUploadGroup.series.find((s) => s.seriesId === event.seriesId);

      const uploadGroup: UploadGroup = {
        ...existingUploadGroup,
        files: [...existingUploadGroup.files, newFileContext],
        series: existingSeries == null && newSeriesItem != null ? [...existingUploadGroup.series, newSeriesItem] : existingUploadGroup.series,
        uncompressedDicomWarning: existingUploadGroup.uncompressedDicomWarning || event.uncompressedDicomWarning,
      };

      newUploadGroups[existingUploadGroupIndex] = uploadGroup;
    }

    if (existingUploadGroup == null && event.dicomData != null && this._fixedLocationId != null) {
      this._dicomMatcher.enqueue({
        uploadGroupId: newUploadGroups[newUploadGroups.length - 1].uploadGroupId,
        patientQuery: {
          locationId: this._fixedLocationId,
          patientNumber: event.dicomData.PatientID,
          unosId: null,
          firstName: event.dicomData.PatientName[1],
          lastName: event.dicomData.PatientName[0],
          dob: event.dicomData.PatientBirthDate,
        },
        examQuery: {
          locationId: this._fixedLocationId,
          suid: event.dicomData.StudyInstanceUID,
        },
      });

      this._dicomMatcher.run();
    }

    this._streams.groups.emit(newUploadGroups);
  }

  private handleMatchComplete(event: MatchCompleteEvent) {
    if (this._allServices == null) throw new Error('Cannot proceed because allServices is null.');

    const newUploadGroups = [...this._streams.groups.getCurrentValue()];

    const existingGroupIndex = newUploadGroups.findIndex((ui) => ui.uploadGroupId === event.uploadGroupId);

    if (existingGroupIndex < 0) throw new Error('Upload group id not found.');

    const existingGroup = newUploadGroups[existingGroupIndex];

    newUploadGroups[existingGroupIndex] = {
      ...existingGroup,
      patient: event.patient,
      exam: event.exam,
      patientForm: event.patient ? ExamUploadService.patientModelToForm(event.patient) : existingGroup.patientForm,
      examForm: event.exam ? ExamUploadService.examModelToForm(event.exam, this._allServices) : existingGroup.examForm,
      patientFormState: { isDirty: false, isValid: event.patient != null, isValidating: false },
      examFormState: { isDirty: false, isValid: event.exam != null, isValidating: false },
    };

    this._streams.groups.emit(newUploadGroups);
  }

  private handleQueueFiles(event: QueueFilesEvent) {
    const { groupStates, groupProcessingIntervals, fileStates } = this.addUploadGroups(event.files);

    this._files = fileStates;
    this._groupProcessingIntervals = groupProcessingIntervals;
    this._streams.groupStates.emitMultiple(groupStates);
  }

  private handleCompressStart(_event: CompressStartEvent) {
    // No-op
  }

  private getGroupByFileId(fileId: string, requireIntervals: true): { uploadGroupId: string; groupIntervals: IntervalMergeCalculator; fileState: FileState };
  private getGroupByFileId(
    fileId: string,
    requireIntervals: false,
  ): { uploadGroupId: string; groupIntervals: IntervalMergeCalculator | undefined; fileState: FileState };
  private getGroupByFileId(fileId: string, requireIntervals: boolean) {
    const fileState = this._files.get(fileId);

    if (fileState == null) throw new Error(`File state not found for file id: ${fileId}.`);

    const uploadGroupId = fileState.uploadGroupId;
    const groupIntervals = this._groupProcessingIntervals.get(uploadGroupId);

    if (groupIntervals == null && requireIntervals) throw new Error('Group processing interval calculator not found.');

    return { uploadGroupId, groupIntervals, fileState };
  }

  private handleCompressProgress(event: CompressProgressEvent) {
    const { uploadGroupId, groupIntervals } = this.getGroupByFileId(event.fileId, true);

    groupIntervals.addInterval(event.chunkStart, event.chunkEnd, undefined);

    const newUploadState = { ...this._streams.groupStates.getCurrentValue(uploadGroupId, true) };
    newUploadState.duration = dayjs.duration(groupIntervals.calculate(performance.now()).duration);

    this.mergeFileStateChanges(event.fileId, { compressedSize: event.processedSize });
    this._streams.groupStates.emit(uploadGroupId, newUploadState);
  }

  private handleCompressComplete(event: CompressCompleteEvent) {
    const { uploadGroupId } = this.getGroupByFileId(event.fileId, false);

    const newUploadState = { ...this._streams.groupStates.getCurrentValue(uploadGroupId, true) };

    newUploadState.compressedFiles++;
    newUploadState.compressedSize += event.compressedSize;

    this.mergeFileStateChanges(event.fileId, { state: 'compressed', compressedSize: event.compressedSize });
    this._streams.groupStates.emit(uploadGroupId, newUploadState);
  }

  private handleCompressError(event: CompressErrorEvent) {
    const { uploadGroupId } = this.getGroupByFileId(event.fileId, false);

    const newUploadState = { ...this._streams.groupStates.getCurrentValue(uploadGroupId, true) };
    newUploadState.errorFiles++;

    this.mergeFileStateChanges(event.fileId, { state: 'error' });
    this._streams.groupStates.emit(uploadGroupId, newUploadState);
  }

  private handleUploadStart(event: UploadStartEvent) {
    this.mergeFileStateChanges(event.fileId, { state: 'uploading', uploadStart: event.uploadStart });
  }

  private handleUploadProgress(event: UploadProgressEvent) {
    const { uploadGroupId, groupIntervals } = this.getGroupByFileId(event.fileId, true);

    groupIntervals.addInterval(event.chunkStart, event.chunkEnd, undefined);

    const newUploadState = { ...this._streams.groupStates.getCurrentValue(uploadGroupId, true) };
    newUploadState.duration = dayjs.duration(groupIntervals.calculate(performance.now()).duration);

    this.mergeFileStateChanges(event.fileId, { uploadSize: event.loadedBytes });
    this._streams.groupStates.emit(uploadGroupId, newUploadState);
  }

  private handleUploadComplete(event: UploadCompleteEvent) {
    const { uploadGroupId, fileState } = this.getGroupByFileId(event.fileId, false);

    const newUploadState = { ...this._streams.groupStates.getCurrentValue(uploadGroupId, true) };
    newUploadState.uploadedFiles++;
    newUploadState.uploadSize += event.uploadSize;

    if (fileState.compressedSize == null) {
      newUploadState.weightedUploadSize += event.uploadSize;
    } else {
      newUploadState.weightedUploadSize += (fileState.fileSize / fileState.compressedSize) * event.uploadSize;
    }

    this.mergeFileStateChanges(event.fileId, {
      state: 'complete',
      uploadEnd: event.uploadEnd,
      uploadSize: event.uploadSize,
    });
    this._streams.groupStates.emit(uploadGroupId, newUploadState);
  }

  private handleUploadError(event: UploadErrorEvent) {
    const { uploadGroupId } = this.getGroupByFileId(event.fileId, false);

    const newUploadState = { ...this._streams.groupStates.getCurrentValue(uploadGroupId, true) };
    newUploadState.errorFiles++;

    this.mergeFileStateChanges(event.fileId, { state: 'error', uploadEnd: event.uploadEnd });
    this._streams.groupStates.emit(uploadGroupId, newUploadState);
  }
}
