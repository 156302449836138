import { ChangeEvent, KeyboardEvent, memo, useState } from 'react';

import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Input } from 'core/ui';
import { hasText } from 'core/utils';

import { WorklistNameInputProps } from '../types';

export const WorklistNameInput = memo<WorklistNameInputProps>(({ className, initialValue, saveButtonText, value, onChange, onSave }) => {
  const isControlledComponent = typeof value !== 'undefined' && typeof onChange !== 'undefined';

  const [newEditedValue, setNewEditedValue] = useState(initialValue ?? '');

  const handleInputChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // TODO: Why is this necessary?  The reason should be documented.

    if (!isControlledComponent) {
      setNewEditedValue(event.currentTarget.value);
    }

    onChange?.(event.currentTarget.value);
  });

  const handleSaveClick = useEvent(() => {
    onSave?.(isControlledComponent ? value : newEditedValue);
  });

  const handleKeyDown = useEvent((event: KeyboardEvent<HTMLInputElement>) => {
    if (!hasText(newEditedValue)) {
      return;
    }

    if (event.key === 'Enter') {
      onSave?.(isControlledComponent ? value : newEditedValue);
    }
  });

  return (
    <StyledWrapperDiv className={className}>
      <StyledInput
        placeholder="Add New Worklist"
        valid
        defaultValue={initialValue}
        value={isControlledComponent ? value : newEditedValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {onSave && (
        <StyledButton type="button" onClick={handleSaveClick} disabled={!hasText(isControlledComponent ? value : newEditedValue)}>
          {saveButtonText}
        </StyledButton>
      )}
    </StyledWrapperDiv>
  );
});

WorklistNameInput.displayName = 'WorklistNameInput';

const StyledButton = styled(Button)`
  position: absolute;
  right: 10px;
`;

const StyledInput = styled(Input)`
  background: ${({ theme }) => theme.colors.palette.grayscale[2]};
  flex-grow: 1;
  height: 40px;
  padding: 1.3rem ${({ theme }) => theme.space.spacing40};
  font-size: ${({ theme }) => theme.fontSizes.subheading};
`;

const StyledWrapperDiv = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  position: relative;
`;
