import { KeyboardEvent, forwardRef, useCallback } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { InputProps } from './InputProps';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = ComponentSizes.MEDIUM,
      type = 'text',
      description,
      disabled,
      format,
      hint,
      isOptionalLabelShown,
      label,
      name,
      id,
      required,
      value,
      valid,
      validationMessage,
      visited,
      preventEnterSubmission,
      iconPrefix,
      iconPostfix,
      onKeyDown,
      ...rest
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);
    const formattedValue = format && value ? format(value) : value;

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }

        onKeyDown?.(event);
      },
      [onKeyDown],
    );

    return (
      <>
        {label && (
          <Label
            description={description}
            editorId={id || name}
            editorValid={valid}
            editorDisabled={disabled}
            required={required}
            optional={isLabeledAsOptional}
          >
            {label}
          </Label>
        )}
        <StyledInput
          ref={ref}
          type={type}
          disabled={disabled}
          name={name}
          id={id || name}
          $size={size}
          $valid={valid}
          value={formattedValue === null ? '' : formattedValue}
          required={required}
          onKeyDown={preventEnterSubmission ? handleKeyDown : onKeyDown}
          {...rest}
        />
        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
      </>
    );
  },
);

Input.displayName = 'Input';

type StyledElementProps = {
  theme: DefaultTheme;
  $size: ComponentSizes;
  $valid?: boolean;
  disabled?: boolean;
};

const resolvePalette = ({ theme, disabled, $valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      borderActive: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      placeholderText: theme.colors.textSecondary,
      hint: theme.colors.textSecondary,
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      caret: theme.colors.textDisabled,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    text: theme.colors.textPrimary,
    placeholderText: theme.colors.textSecondary,
    hint: theme.colors.textSecondary,
    background: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[4],
    caret: theme.colors.textPrimary,
  };

  if (!$valid) {
    result.border = theme.colors.error;
    result.borderActive = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolvePlaceholderColor = (props: StyledElementProps) => {
  const { placeholderText } = resolvePalette(props);

  return placeholderText;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({ theme, $valid, disabled }: StyledElementProps) => {
  if (disabled) {
    return 'none';
  }

  if (!$valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveInputCaretColor = (props: StyledElementProps) => {
  const { caret } = resolvePalette(props);

  return caret;
};

const resolveFontSize = ({ theme, $size }: StyledElementProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, $size }: StyledElementProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolvePadding = ({ theme, $size }: StyledElementProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing30}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing30}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
  }
};

const resolveHeight = ({ theme, $size }: StyledElementProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const StyledInput = styled.input<{ $valid?: boolean; $size: ComponentSizes }>`
  background-color: ${resolveBackgroundColor};
  border-radius: ${({ theme }) => theme.radii.base};
  border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
  box-sizing: border-box;
  caret-color: ${resolveInputCaretColor};
  color: ${resolveColor};
  font-size: ${resolveFontSize};
  height: ${resolveHeight};
  line-height: ${resolveLineHeight};
  outline: none;
  padding: ${resolvePadding};
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  width: 100%;

  &::placeholder {
    color: ${resolvePlaceholderColor};
  }

  &:active,
  &:hover,
  &:focus {
    border-color: ${resolveActiveBorderColor};
  }

  &:active,
  &:focus {
    box-shadow: ${resolveActiveBoxShadow};
  }

  /* Removes up and down arrows from numeric inputs */
  /* src: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
