import { FunctionComponent } from 'react';

import { PatientVerbiageProviderContext } from '../contexts';
import { PatientVerbiageProviderProps } from '../types';

export const PatientVerbiageProvider: FunctionComponent<
  PatientVerbiageProviderProps
> = ({ mode, children }) => {
  return (
    <PatientVerbiageProviderContext.Provider value={mode}>
      {children}
    </PatientVerbiageProviderContext.Provider>
  );
};
