import { TatModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class TatClient {
  constructor(private httpClient: HttpClient) {
    this.getDefaultTATs = this.getDefaultTATs.bind(this);
  }

  public async getDefaultTATs() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const tats = await this.httpClient.fetchAndParse<TatModel[]>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/tats`, options),
    );
    return tats;
  }
}
