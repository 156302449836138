import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field } from 'core/forms';
import { Accordion, ChipList, Input, TextArea } from 'core/ui';

import { OverreadHistory } from '../../exam/fragments/OverreadHistory';
import WriteIcon from '../assets/write.svg?react';
import { AirwayAnatomyOptions } from '../constants';

// has to be wrapped in <Form /> to work
const PulmonologyResultsAccordion = ({ eventKey, isFormVisible, reads }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={WriteIcon} title="Results" />
      <Accordion.Body>
        {isFormVisible && (
          <StyledFormWrapperDiv>
            <StyledRow>
              <Col className="col-12 mb-2">
                <Field
                  component={ChipList}
                  data={AirwayAnatomyOptions}
                  label="RIGHT LUNG AIRWAY ANATOMY"
                  name="Results.rightLungAirwayAnatomy"
                  selection="single"
                />
              </Col>
            </StyledRow>
            <Row>
              <Col className="col-12">
                <Field
                  component={Input}
                  name="Results.rightAirwayAnatomyDescription"
                />
              </Col>
            </Row>
            <StyledRow>
              <Col className="col-12">
                <Field
                  component={TextArea}
                  label="RIGHT LUNG COMMENTS"
                  name="Results.rightLungComments"
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col className="col-12 mb-2">
                <Field
                  component={ChipList}
                  data={AirwayAnatomyOptions}
                  label="LEFT LUNG AIRWAY ANATOMY"
                  name="Results.leftLungAirwayAnatomy"
                  selection="single"
                />
              </Col>
            </StyledRow>
            <Row>
              <Col className="col-12">
                <Field
                  component={Input}
                  name="Results.leftAirwayAnatomyDescription"
                />
              </Col>
            </Row>
            <StyledRow>
              <Col className="col-12">
                <Field
                  component={TextArea}
                  label="LEFT LUNG COMMENTS"
                  name="Results.leftLungComments"
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col className="col-12">
                <Field
                  component={TextArea}
                  label="OTHER COMMENTS"
                  name="Results.otherComments"
                />
              </Col>
            </StyledRow>
          </StyledFormWrapperDiv>
        )}
        <OverreadHistory readHistory={reads} />
      </Accordion.Body>
    </Accordion.Item>
  );
};

const StyledRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledFormWrapperDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.space.spacing40};
`;

PulmonologyResultsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  reads: PropTypes.arrayOf(PropTypes.shape({})),
};

PulmonologyResultsAccordion.defaultProps = {
  reads: [],
};

export { PulmonologyResultsAccordion };
