import { FunctionComponent, memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { LocationFinalReportTemplateRoutes } from '../routes';
import { LocationFinalReportTemplateForm } from './LocationFinalReportTemplateForm';
import { LocationFinalReportTemplateHome } from './LocationFinalReportTemplateHome';

export const LocationFinalReportTemplate: FunctionComponent = memo(() => {
  return (
    <Routes>
      <Route
        path={LocationFinalReportTemplateRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <LocationFinalReportTemplateHome />
          </RouteGuard>
        }
      />
      <Route
        path={LocationFinalReportTemplateRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={LocationFinalReportTemplateHome}>
            <LocationFinalReportTemplateForm />
          </RouteGuard>
        }
      />
      <Route
        path={LocationFinalReportTemplateRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={LocationFinalReportTemplateHome}>
            <LocationFinalReportTemplateForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

LocationFinalReportTemplate.displayName = 'LocationFinalReportTemplate';
