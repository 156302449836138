import { useCallback, useEffect, useMemo, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import { PatientModel } from 'models/PatientModel';

import { useEvent } from 'core/hooks';

import { useAppDispatch } from 'features/main/hooks';

import { hasText } from '../../../core/utils';
import {
  DefaultPatientSearchDataState,
  FormStates,
} from '../../exam/constants';
import { PatientActions, PatientSelectors } from '../redux';
import { PatientGridService } from '../services';

export const usePatientSelectSearch = (
  examPatientId?: string,
  locationId?: number,
) => {
  const dispatch = useAppDispatch();

  const searchPatients = useSelector(PatientSelectors.getAll);
  const examPatient = useSelector(PatientSelectors.getById(examPatientId));
  const totalSearchPatients = useSelector(PatientSelectors.getTotal);

  const [currentPatient, setCurrentPatient] = useState<PatientModel | null>(
    null,
  );
  const [patientFormState, setPatientFormState] = useState(FormStates.DISABLED);
  const [patientSearchText, setPatientSearchText] = useState('');
  const [showPatientsDialog, setShowPatientsDialog] = useState(false);
  const [patientSearchDataState, setPatientSearchDataState] = useState<State>(
    DefaultPatientSearchDataState,
  );

  const handlePatientsSearchDataStateChange = useCallback(
    (changeEvent: GridDataStateChangeEvent) => {
      setPatientSearchDataState(changeEvent.dataState);
      dispatch(
        PatientActions.getAllForKendoGrid({
          dataState: changeEvent.dataState,
          keyword: patientSearchText,
        }),
      );
    },
    [dispatch, patientSearchText],
  );

  const handlePatientSelect = useCallback(
    (newSelectedPatient: PatientModel) => {
      setCurrentPatient(newSelectedPatient);
      setShowPatientsDialog(false);
    },
    [],
  );

  const handleCreateNewPatientClick = useCallback(
    (defaultPatientData: PatientModel | null = null) => {
      setPatientFormState(FormStates.ADD);
      setCurrentPatient(defaultPatientData);
      setShowPatientsDialog(false);
    },
    [],
  );

  const initializePatientSearch = useEvent(() => {
    setPatientSearchDataState((prev) => ({
      ...prev,
      filter: PatientGridService.getSearchFilter(patientSearchText, locationId),
    }));
  });

  const fetchPatients = useEvent(async (searchQuery: string) => {
    if (!hasText(searchQuery)) {
      return;
    }
    setShowPatientsDialog(true);

    const newDataState: State = {
      ...patientSearchDataState,
      filter: PatientGridService.getSearchFilter(searchQuery, locationId),
    };

    await dispatch(
      PatientActions.getAllForKendoGrid({ dataState: newDataState }),
    );

    setPatientSearchDataState(newDataState);
  });

  const handlePatientSearchTextChange = useMemo(
    () =>
      debounce((searchQuery) => {
        fetchPatients(searchQuery);
      }, 750),
    [fetchPatients],
  );

  useEffect(() => {
    initializePatientSearch();
  }, [initializePatientSearch, locationId]);

  useEffect(() => {
    setCurrentPatient(examPatient);
  }, [examPatient, setCurrentPatient]);

  useEffect(() => {
    handlePatientSearchTextChange(patientSearchText);
  }, [handlePatientSearchTextChange, patientSearchText]);

  return {
    currentPatient,
    handleCreateNewPatientClick,
    handlePatientSelect,
    handlePatientsSearchDataStateChange,
    patientFormState,
    patientSearchDataState,
    patientSearchText,
    searchPatients,
    setCurrentPatient,
    setPatientFormState,
    setPatientSearchText,
    setShowPatientsDialog,
    showPatientsDialog,
    totalSearchPatients,
  };
};
