import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field } from 'core/forms';
import { Accordion, ChipList, Input, NumericInput } from 'core/ui';

import HeartMonitorIcon from '../assets/heart-monitor.svg?react';
import {
  ConsistencyOptions,
  PulmonologyObservationsColors,
  YesNoOptions,
} from '../constants';

const PulmonologyObservationsAccordion = ({ eventKey, isFormDisabled }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={HeartMonitorIcon} title="Observations" />
      <Accordion.Body>
        <StyledAccordian
          alwaysOpen
          defaultActiveKey={[
            `${eventKey}-secretions`,
            `${eventKey}-bronchioalveolar`,
            `${eventKey}-preliminary`,
          ]}
        >
          <Accordion.Item eventKey={`${eventKey}-secretions`}>
            <Accordion.Header title="SECRETIONS" />
            <Accordion.Body>
              <StyledFormWrapperDiv>
                <StyledRow>
                  <Col className="col-12 mb-2">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(PulmonologyObservationsColors)}
                      disabled={isFormDisabled}
                      label="SECRETION COLOR"
                      name="Observations.secretionsColor"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col className="col-6 mb-2">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(ConsistencyOptions)}
                      disabled={isFormDisabled}
                      label="CONSISTENCY"
                      name="Observations.consistency"
                      selection="single"
                    />
                  </Col>
                  <Col className="col-6 mb-2">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="SECRETIONS CLEANS EASILY"
                      name="Observations.secretionsCleanEasily"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col className="col-6 mb-2">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="PURULENT"
                      name="Observations.purulent"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
              </StyledFormWrapperDiv>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`${eventKey}-bronchioalveolar`}>
            <Accordion.Header title="BRONCHIOALVEOLAR LAVAGE" />
            <Accordion.Body>
              <StyledFormWrapperDiv>
                <StyledRow>
                  <Col className="col-6 mb-2">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="PERFORMED"
                      name="Observations.lavagePerformed"
                      selection="single"
                    />
                  </Col>
                  <Col className="col-6 mb-2">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="RE-ACCUMULATE AFTER SUCTIONING"
                      name="Observations.afterSuctioning"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col className="col-6">
                    <Field
                      component={NumericInput}
                      disabled={isFormDisabled}
                      label="SALINE USED (ml):"
                      name="Observations.salineUsed"
                    />
                  </Col>
                  <Col className="col-6">
                    <Field
                      allowOtherOption
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="BRONCHIAL WASHING SENT"
                      name="Observations.bronchialWashingSent"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
              </StyledFormWrapperDiv>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={`${eventKey}-preliminary`}>
            <Accordion.Header title="PRELIMINARY OBSERVATIONS" />
            <Accordion.Body>
              <StyledFormWrapperDiv>
                <StyledRow>
                  <Col className="col-12 mb-2">
                    <Field
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="ABNORMAL AIRWAY FINDINGS"
                      name="Observations.airwayNoduleMasses"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col>
                    <Field
                      component={Input}
                      placeholder="Add Comment"
                      disabled={isFormDisabled}
                      name="Observations.abnormalAirwayFindingsComment"
                    />
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col className="col-12 mb-2">
                    <Field
                      component={ChipList}
                      data={_map(YesNoOptions)}
                      disabled={isFormDisabled}
                      label="FOREIGN MATTER PRESENT OR EVIDENCE OF ASPIRATION"
                      name="Observations.foreignMatterOrAspiration"
                      selection="single"
                    />
                  </Col>
                </StyledRow>
                <StyledRow>
                  <Col>
                    <Field
                      component={Input}
                      placeholder="Add Commment"
                      disabled={isFormDisabled}
                      name="Observations.foreignMatterOrAspirationComment"
                    />
                  </Col>
                </StyledRow>
              </StyledFormWrapperDiv>
            </Accordion.Body>
          </Accordion.Item>
        </StyledAccordian>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const StyledAccordian = styled(Accordion)`
  h2 > .accordion-button {
    padding-left: 0 !important;
  }
  ,
  // selecting the accordion header
  h2 > .accordion-button > div > div > div {
    margin-left: 1px;
  }
`;

const StyledRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledFormWrapperDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.space.spacing40};
`;

PulmonologyObservationsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
};

export { PulmonologyObservationsAccordion };
