import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import _map from 'lodash/map';

import { ExamModel } from 'models';

import { ApiHelpers } from 'core/api';
import { NotificationsService } from 'core/notifications';

import { ExamApiStatus, SendDocumentStatus } from '../constants';
import { SlaService } from '../services';
import { ExamActions } from './actions';

export const examAdapter = createEntityAdapter<ExamModel>();

const getReducerInitialState = () => ({
  ...examAdapter.getInitialState(),
  total: 0,
  status: null as string | null,
  recentlyViewedExams: [] as number[],
  sendDocumentStatus: null as string | null,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(ExamActions.getAllForKendoGrid.fulfilled, (draft, action) => {
    examAdapter.removeAll(draft);

    const payload = action.payload as {
      entities: { exams: ExamModel[] };
      total: number;
      result: EntityId[];
    };

    if (payload.entities.exams) {
      const exams = _map(payload.entities.exams, (exam) => ({
        ...exam,
        sla: SlaService.getDisplayName(exam.sla),
      }));

      examAdapter.addMany(draft, exams);
    }

    draft.total = payload.total;
    draft.ids = payload.result;
    draft.status = ExamApiStatus.FETCHED;
  });
  builder.addCase(ExamActions.getById.fulfilled, (draft, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = action.payload as any;

    examAdapter.upsertOne(draft, {
      ...payload,
      sla: {
        value: payload.sla,
        name: SlaService.getDisplayName(payload.sla),
      },
    });
    draft.status = ExamApiStatus.FETCHED;
  });
  builder.addCase(ExamActions.getByIdAndLock.fulfilled, (draft, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = action.payload as any;

    examAdapter.upsertOne(draft, {
      ...payload,
      sla: {
        value: payload.sla,
        name: SlaService.getDisplayName(payload.sla),
      },
    });
    draft.status = ExamApiStatus.FETCHED;
  });
  builder.addCase(ExamActions.getByIdAndLock.rejected, (draft, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = action.payload as any;

    if (ApiHelpers.isEntityLocked(payload.statusCode)) {
      examAdapter.upsertOne(draft, {
        ...payload.data.exam,
        sla: {
          value: payload.data.exam.sla,
          name: SlaService.getDisplayName(payload.data.exam.sla),
        },
        locked: true,
      });

      NotificationsService.displayInfo(
        `The exam is being viewed by ${payload.data.lockedBy} since ${payload.data.locked} PST"`,
      );

      draft.status = ExamApiStatus.ERROR;
    }
  });
  builder.addCase(ExamActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { exams: ExamModel };
    examAdapter.addOne(draft, payload.exams);
    draft.status = ExamApiStatus.ADDED;
  });
  builder.addCase(ExamActions.edit.fulfilled, (draft, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = action.payload as any;
    examAdapter.updateOne(draft, payload.exams);
    draft.status = ExamApiStatus.UPDATED;
  });
  builder.addCase(ExamActions.clearExamSaveStatus, (draft) => {
    draft.status = null;
  });
  builder.addCase(ExamActions.sendDocuments.fulfilled, (draft) => {
    draft.sendDocumentStatus = SendDocumentStatus.SUCCESS;
  });
  builder.addCase(ExamActions.sendDocuments.rejected, (draft) => {
    draft.sendDocumentStatus = SendDocumentStatus.ERROR;
  });
  builder.addCase(ExamActions.sendDocuments.pending, (draft) => {
    draft.sendDocumentStatus = SendDocumentStatus.PENDING;
  });
  builder.addCase(ExamActions.clearSendDocumentStatus, (draft) => {
    draft.sendDocumentStatus = null;
  });
  builder.addCase(ExamActions.changeStatus.fulfilled, () => {
    NotificationsService.displaySuccess('Exam status changed successfully');
  });
  builder.addCase(ExamActions.bulkCancel.fulfilled, () => {
    NotificationsService.displaySuccess('Bulk cancel successful');
  });
  builder.addCase(ExamActions.changeStatus.rejected, () => {
    NotificationsService.displayError('Exam status change failed');
  });
  builder.addCase(ExamActions.bulkCancel.rejected, () => {
    NotificationsService.displayError('Bulk cancel failed');
  });
  builder.addCase(ExamActions.popRecentlyViewedExam, (draft) => {
    draft.recentlyViewedExams.pop();
  });
  builder.addCase(ExamActions.pushRecentlyViewedExam, (draft, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = action.payload as any;
    draft.recentlyViewedExams.push(payload);
  });
  builder.addCase(ExamActions.clearRecentlyViewedExams, (draft) => {
    draft.recentlyViewedExams = [];
  });
});
