import { createSelector } from '@reduxjs/toolkit';

import { AccountModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { accountAdapter } from './reducer';

const rootSelector = (state: RootState) => state.accounts;

const { selectAll: getAll } = accountAdapter.getSelectors(rootSelector);

const getStatus: AppSelector<string> = (state) => rootSelector(state).status;

// This needs to be a "pure" function.  So it's defined outside the scope of the calling code to avoid any accidental closure references.
const getByIdOutput = (accounts: AccountModel[], accountId: number) =>
  accounts.find((a) => a.id === accountId);

const getById = (accountId: number): AppSelector<AccountModel | undefined> =>
  createSelector([getAll, () => accountId], getByIdOutput);

const getAllActiveAccountsOutput = (
  accounts: AccountModel[],
  forceIncludeId: number | null | undefined,
  forceExcludeId: number | null | undefined,
) =>
  accounts.filter(
    (account) =>
      account.id !== forceExcludeId &&
      (account.active || account.id === forceIncludeId),
  );

const getAllActiveAccounts = (
  forceIncludeId: number | null | undefined,
  forceExcludeId: number | null | undefined,
): AppSelector<AccountModel[]> =>
  createSelector(
    [getAll, () => forceIncludeId, () => forceExcludeId],
    getAllActiveAccountsOutput,
  );

const getTotal: AppSelector<number> = (state) => rootSelector(state).total;

export const AccountSelectors = {
  getAll,
  getById,
  getTotal,
  getStatus,
  getAllActiveAccounts,
};
