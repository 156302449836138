import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  SwitchChangeEvent,
  TextAreaChangeEvent,
} from '@progress/kendo-react-inputs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { SettingDefinitionModel, SettingOverrideModel } from 'models';

import { FieldContainer, GridColumn, createStyledRhfForm } from 'core/forms';
import { RHF_FULL_RESET } from 'core/forms/constants';
import { useEvent } from 'core/hooks';
import {
  Button,
  ButtonVariants,
  FlatIconButton,
  Icon,
  Label,
  SwitchField,
  TextAreaField,
} from 'core/ui';
import { findOrThrow } from 'core/utils';

import { apiClient } from 'features/api';

import {
  OverrideEditorFormValues,
  SettingsOverrideEditorProps,
} from '../types';
import { SettingsListItem } from './SettingsListItem';

export const SettingsOverrideEditor = memo<SettingsOverrideEditorProps>(
  ({ initialValues, onChange, onSubmit }) => {
    const [definitions, setDefinitions] = useState<
      SettingDefinitionModel[] | null
    >(null);
    const [localOverrides, setLocalOverrides] = useState(initialValues);
    const [selectedDefinition, setSelectedDefinition] =
      useState<SettingDefinitionModel | null>(null);
    const selectedOverride = useMemo(
      () =>
        selectedDefinition == null
          ? null
          : localOverrides.find((l) => l.id === selectedDefinition.id) ?? null,
      [localOverrides, selectedDefinition],
    );

    const rhfContext = useForm<OverrideEditorFormValues>({
      defaultValues: {
        value: '',
        active: true,
      },
    });
    const { reset, setValue, getValues } = rhfContext;

    const handleSettingClick = useCallback(
      (definitionId: number) => {
        if (definitions == null) {
          throw new Error(
            'Cannot proceed because the definitions are null or undefined.',
          );
        }

        const newSelectedDefinition = findOrThrow(
          definitions,
          (s) => s.id === definitionId,
        );

        const newSelectedOverride = localOverrides.find(
          (s) => s.id === definitionId,
        );

        setSelectedDefinition(newSelectedDefinition);
        reset(
          {
            value:
              (newSelectedOverride == null
                ? newSelectedDefinition.value
                : newSelectedOverride.value) ?? '',
            active:
              (newSelectedOverride == null
                ? newSelectedDefinition.active
                : newSelectedOverride.active) ?? true,
          },
          RHF_FULL_RESET,
        );
      },
      [definitions, localOverrides, reset],
    );

    const getUpdatedState = useEvent(
      (newValues: { value: string | null; active: boolean }) => {
        if (selectedDefinition == null) {
          throw new Error(
            'Cannot handle value change because selectedDefinition is null or undefined.',
          );
        }

        const newLocalOverrides = [...localOverrides];
        const selectedLocalOverrideIndex = newLocalOverrides.findIndex(
          (s) => s.id === selectedDefinition.id,
        );
        let newSelectedLocalOverride: SettingOverrideModel;

        if (selectedLocalOverrideIndex < 0) {
          newSelectedLocalOverride = {
            id: selectedDefinition.id,
            value: newValues.value,
            active: newValues.active,
          };

          newLocalOverrides.push(newSelectedLocalOverride);
        } else {
          newSelectedLocalOverride = {
            ...newLocalOverrides[selectedLocalOverrideIndex],
            value: newValues.value,
            active: newValues.active,
          };

          newLocalOverrides[selectedLocalOverrideIndex] =
            newSelectedLocalOverride;
        }

        return {
          newLocalOverrides,
          newSelectedLocalOverride,
        };
      },
    );

    const handleValueChange = useCallback(
      (event: TextAreaChangeEvent) => {
        const results = getUpdatedState({
          value: event.value,
          active: getValues('active'),
        });

        setLocalOverrides(results.newLocalOverrides);

        onChange(results.newLocalOverrides);
      },
      [getUpdatedState, getValues, onChange],
    );

    const handleActiveChange = useCallback(
      (event: SwitchChangeEvent) => {
        if (selectedDefinition == null) {
          throw new Error(
            'Cannot proceed because selectedDefinition is null or undefined.',
          );
        }

        // Get the current value from localOverrides first, then fallback to the selectedDefinition.  We need to get directly from the DTOs instead of the input
        // text element because the DOM doesn't support reading value of null.  It supports setting a value of null, but it immediately converts to the empty string.
        const existingValue =
          selectedOverride != null
            ? selectedOverride.value
            : selectedDefinition.value;

        const results = getUpdatedState({
          value: existingValue,
          active: event.value,
        });

        setLocalOverrides(results.newLocalOverrides);

        onChange(results.newLocalOverrides);
      },
      [getUpdatedState, onChange, selectedDefinition, selectedOverride],
    );

    const handleSetNullClick = useCallback(() => {
      const results = getUpdatedState({
        value: null,
        active: getValues('active'),
      });

      setLocalOverrides(results.newLocalOverrides);
      setValue('value', '');

      onChange(results.newLocalOverrides);
    }, [getUpdatedState, getValues, onChange, setValue]);

    const handleSubmit: SubmitHandler<OverrideEditorFormValues> =
      useCallback(() => {
        onSubmit();
      }, [onSubmit]);

    const handleResetClick = useCallback(() => {
      if (selectedDefinition == null) {
        throw new Error(
          'Cannot reset default because the selectedDefinition is null or undefined',
        );
      }

      const newLocalValues = [...localOverrides];

      const removeIndex = newLocalValues.findIndex(
        (s) => s.id === selectedDefinition.id,
      );

      if (removeIndex >= 0) {
        newLocalValues.splice(removeIndex, 1);
      }

      setLocalOverrides(newLocalValues);
      setValue('value', selectedDefinition.value ?? '');
      setValue('active', selectedDefinition.active);

      onChange(newLocalValues);
    }, [localOverrides, onChange, selectedDefinition, setValue]);

    const handleCloseClick = useCallback(() => {
      setSelectedDefinition(null);
    }, []);

    useEffect(() => {
      (async () => {
        const newDefinitions =
          await apiClient.settingsClient.getSettingDefinitions();

        setDefinitions(newDefinitions);
      })();
    }, []);

    if (definitions == null) return null;

    return (
      <FormProvider {...rhfContext}>
        <StyledTabDiv>
          <StyledListScrollDiv>
            <StyledListDiv>
              <StyledListHeaderDiv>
                <div />
                <StyledListCol2Header>Setting</StyledListCol2Header>
                <StyledListCol3Header>Value</StyledListCol3Header>
              </StyledListHeaderDiv>
              {definitions.map((baseSetting, index) => (
                <SettingsListItem
                  key={baseSetting.id}
                  selectedSettingId={selectedDefinition?.id ?? null}
                  overrides={localOverrides}
                  definition={baseSetting}
                  isAlternateRow={index % 2 === 0}
                  onClick={handleSettingClick}
                />
              ))}
            </StyledListDiv>
          </StyledListScrollDiv>
          <StyledForm
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="false"
            noValidate
            onSubmit={rhfContext.handleSubmit(handleSubmit)}
          >
            {selectedDefinition != null && (
              <>
                <StyledCloseContainer>
                  <FlatIconButton onClick={handleCloseClick}>
                    <Icon icon={faXmark} />
                  </FlatIconButton>
                </StyledCloseContainer>

                <StyledColumn1>Setting</StyledColumn1>
                <StyledColumn2>
                  <div>{selectedDefinition.name}</div>
                </StyledColumn2>

                <StyledColumn1>Description</StyledColumn1>
                <StyledColumn2>
                  <StyledNullableText
                    $isNull={selectedDefinition.description == null}
                  >
                    {selectedDefinition.description ?? 'No description'}
                  </StyledNullableText>
                </StyledColumn2>

                <GridColumn columnStart="1" isLabelColumn>
                  <Label editorId="name">Value</Label>
                </GridColumn>
                <GridColumn columnStart="2">
                  <FieldContainer $hideLabel>
                    <TextAreaField
                      name="value"
                      onChange={handleValueChange}
                      placeholder={
                        selectedOverride?.value === null ? 'null' : undefined // We only display "null" when there IS a UserSetting value, but it has "null".
                      }
                      fixedErrorHeight
                      autoSize={false}
                    />
                  </FieldContainer>
                </GridColumn>
                <StyledNullColumn columnStart="3">
                  <Button
                    variant={ButtonVariants.SECONDARY}
                    onClick={handleSetNullClick}
                  >
                    Set null
                  </Button>
                </StyledNullColumn>

                <GridColumn columnStart="1" isLabelColumn>
                  <Label editorId="name">Active</Label>
                </GridColumn>
                <GridColumn columnStart="2">
                  <FieldContainer $hideLabel>
                    <SwitchField name="active" onChange={handleActiveChange} />
                  </FieldContainer>
                </GridColumn>

                <StyledButtonsContainer>
                  <Button
                    variant={ButtonVariants.SECONDARY}
                    onClick={handleResetClick}
                    disabled={selectedOverride == null}
                  >
                    Reset Default
                  </Button>
                  <Button type="submit">Save</Button>
                </StyledButtonsContainer>
              </>
            )}
          </StyledForm>
        </StyledTabDiv>
      </FormProvider>
    );
  },
);

SettingsOverrideEditor.displayName = 'SettingsOverrideEditor';

const StyledForm = createStyledRhfForm('min-content 700px min-content');

const StyledTabDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr;
`;

const StyledListScrollDiv = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: white;
`;

const StyledListDiv = styled.div`
  flex: 0 0 min-content;
  display: grid;
  overflow: hidden;
  grid-template-columns: min-content 1fr 1fr;
  grid-auto-rows: 32px;
  column-gap: 8px;
`;

const StyledListHeaderDiv = styled.div`
  grid-column: 1 / span 3;
  display: grid;
  overflow: hidden;
  grid-template-columns: subgrid;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: 0 8px;
`;

const StyledListCol2Header = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  user-select: none;
`;

const StyledListCol3Header = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: end;
  align-items: center;
  user-select: none;
`;

const StyledCloseContainer = styled.div`
  grid-column: 1 / span 3;
  display: flex;
  overflow: hidden;
  justify-content: end;
`;

const StyledColumn1 = styled.div`
  grid-column: 1 / span 1;
  min-height: 41px;
  display: flex;
  overflow: hidden;
  align-items: start;
  justify-content: end;
  padding: 4px 8px 19px 0;
`;

const StyledColumn2 = styled.div`
  grid-column: 2 / span 1;
  min-height: 41px;
  display: flex;
  overflow: hidden;
  align-items: start;
  padding: 4px 0 19px 0;
`;

const StyledNullColumn = styled(GridColumn)`
  padding: 0 0 0 8px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  overflow: hidden;
  grid-column: 1 / span 3;
  justify-content: space-between;
`;

const StyledNullableText = styled.span<{ $isNull: boolean }>`
  ${({ $isNull, theme }) =>
    $isNull
      ? `
  font-style: italic;
  color: ${theme.colors.textDisabled};
  user-select: none;
  `
      : ''}
`;
