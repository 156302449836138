import { FunctionComponent } from 'react';

import { DataTable, GridColumn, HeaderCell } from 'core/ui';

import { MergeStepDonorConfirm, MergeStepExamMigrate, MergeStepResult } from '.';
import { PatientGridService } from '../services';
import { MergePatientWithSelectModalProps } from '../types';

export const MergePatientWithSelectModal: FunctionComponent<MergePatientWithSelectModalProps> = ({
  step,
  dateFormat,
  destPatient,
  selectAll,
  selectedFields,
  srcPatient,
  srcSelectAll,
  destSelectAll,
  destDataState,
  gridDestData,
  gridSrcData,
  dataStateChangeDest,
  dataStateChangeSrc,
  srcDataState,
  isMergeSuccessful,
  donors,
  dataStateChange,
  selectedDonors,
  setSelectedDonors,
  isLoading,
  dataState,
  searchValue,
  calculateAge,
}) => {
  return (
    <>
      {step === 0 && (
        <>
          <DataTable
            data={
              (donors?.data?.map((d) => ({
                ...d,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                age: calculateAge(d.dob),
              })) as unknown[]) || []
            }
            sortable
            filterable
            onDataStateChange={dataStateChange}
            selectable
            pageable={{ pageSizes: true }}
            total={donors?.total || 0}
            selectedState={selectedDonors}
            onSelectionChange={setSelectedDonors}
            isLoading={isLoading}
            {...dataState}
          >
            {PatientGridService.getPatientColumns().map((column) => {
              return (
                <GridColumn
                  key={column.field}
                  headerCell={(headerCellProps) => <HeaderCell {...headerCellProps} description={column.headerCellDescription} />}
                  {...column}
                  cell={(props) => <column.cell {...props} searchValue={searchValue} />}
                  filter={column.columnFilter}
                />
              );
            })}
          </DataTable>
        </>
      )}
      {step === 1 && (
        <MergeStepDonorConfirm
          dateFormat={dateFormat}
          destPatient={destPatient}
          destSelectAll={destSelectAll}
          onSelectAll={selectAll}
          selectedFields={selectedFields}
          srcPatient={srcPatient}
          srcSelectAll={srcSelectAll}
        />
      )}
      {step === 2 && (
        <MergeStepExamMigrate
          destDataState={destDataState}
          gridDestData={gridDestData}
          gridSrcData={gridSrcData}
          dataStateChangeDest={dataStateChangeDest}
          dataStateChangeSrc={dataStateChangeSrc}
          srcDataState={srcDataState}
        />
      )}
      {step === 3 && <MergeStepResult isMergeSuccessful={isMergeSuccessful} />}
    </>
  );
};
