import { memo, useCallback } from 'react';

import styled from 'styled-components';

import { ImgProxy } from 'features/file/fragments/ImgProxy';

import ThumbnailIcon from '../../file/assets/thumbnail.svg?react';
import { ThumbnailColumnExamGridProps } from '../types';
import { Badge } from 'react-bootstrap';

export const ThumbnailColumnExamGrid = memo<ThumbnailColumnExamGridProps>(({ dataItem, width, height, onClick }) => {
  const handleClick = useCallback(() => onClick?.(dataItem), [dataItem, onClick]);

  const thumbnailFile = dataItem.files.find((file) => file.fileType === 'LINK' && file.thumbnailLocation);
  return (
    <StyledTdActionCell onClick={handleClick}>
      <StyledSpanActionContainer>
        {dataItem.imageCount !== null && dataItem.imageCount !== 0 && (
          <StyledBadge isEmptyThumbnail={!!thumbnailFile} className="float-end">
            {dataItem.imageCount}
          </StyledBadge>
        )}
        {thumbnailFile ? (
          <ImgProxy
            fileId={thumbnailFile.id}
            thumbnailUrl={thumbnailFile.thumbnailLocation ?? ''}
            dateModified={thumbnailFile.modified}
            width={width}
            height={height}
            isReadOnly
          />
        ) : (
          <StyledThumbnailIcon />
        )}
      </StyledSpanActionContainer>
    </StyledTdActionCell>
  );
});

ThumbnailColumnExamGrid.displayName = 'ThumbnailColumnExamGrid';

const StyledTdActionCell = styled.td`
  colspan: 1;
  cursor: pointer;
`;
const StyledSpanActionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.spacing20};
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledBadge = styled(Badge)<{ isEmptyThumbnail: boolean }>`
  position: absolute;
  right: ${({ isEmptyThumbnail }) => (isEmptyThumbnail ? '0' : '10px')};
  z-index: 1;
  && {
    background-color: ${({ theme }) => theme.colors.palette.blues[3]} !important;
  }
`;

const StyledThumbnailIcon = styled(ThumbnailIcon)`
  width: 70px;
  height: 70px;
`;
