// TODO service default value is hardcoded, need to find way to make it work dynamically with Kendo
const INITIAL_VALUES = {
  id: 0,
  location: null,
  patient: null,
  studySource: null,
  method: null,
  device: null,
  service: {
    id: 1,
    description: 'EKG',
  },
  studyDate: null,
  studyTime: null,
  uploadedDate: new Date(),
  uploadedTime: new Date(),
  hospital: '',
  drAcceptedMethod: null,
  drAcceptedTime: null,
  measurementUpdated: null,
};

const getInitialValues = () => INITIAL_VALUES;

export const ExamInitialValueService = {
  getInitialValues,
};
