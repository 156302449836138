import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/physicians`;

const getByIdRoute = (id: number) => `${getBaseRoute()}/${id}`;

const getForGridRoute = () => `${getBaseRoute()}/grid`;

const getAllRoute = () => getBaseRoute();

export const PhysicianRouteService = {
  getAllRoute,
  getBaseRoute,
  getByIdRoute,
  getForGridRoute,
};
