import { forwardRef } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { ItemDivProps } from './ItemDivProps';

/** Top-level element for individual accordion items. */
export const ItemDiv = forwardRef<HTMLDivElement, ItemDivProps>(
  ({ className, mode = 'vertical', ...rest }, ref) => (
    <StyledItemDiv
      ref={ref}
      className={cn(className, ItemDiv.displayName, {
        horizontal: mode === 'horizontal',
        vertical: mode === 'vertical',
      })}
      {...rest}
    />
  ),
);

ItemDiv.displayName = 'ItemDiv';

const StyledItemDiv = styled.div`
  flex: 0 0 min-content;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
`;
