import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Accordion } from 'core/ui';

import PatientIcon from '../assets/patient.svg?react';

const PatientInfoAccordion = ({ eventKey, patient }) => {
  const dobDate = dayjs(patient?.dob);
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={PatientIcon} title="Patient">
        <StyledPatientNameDiv>
          {patient?.firstName} {patient?.lastName}
        </StyledPatientNameDiv>
      </Accordion.Header>
      <Accordion.Body>
        <StyledInfoRow>
          <StyledInfoCol>
            <StyledLabelDiv>First Name:</StyledLabelDiv>
            {patient?.firstName || '/'}
          </StyledInfoCol>
          <StyledInfoCol>
            <StyledLabelDiv>Last Name:</StyledLabelDiv>
            {patient?.lastName || '/'}
          </StyledInfoCol>
        </StyledInfoRow>
        <StyledInfoRow>
          <StyledInfoCol>
            <StyledLabelDiv>Age Range:</StyledLabelDiv>
            {patient?.ageRange ? `${patient?.ageRange?.description} (${patient?.ageRange?.startRange} - ${patient?.ageRange?.endRange})` : '/'}
          </StyledInfoCol>
        </StyledInfoRow>
        <StyledInfoRow>
          <StyledInfoCol>
            <StyledLabelDiv>MRN / Patient ID:</StyledLabelDiv>
            {patient?.patientNumber || '/'}
          </StyledInfoCol>
          <StyledInfoCol>
            <StyledLabelDiv>DOB:</StyledLabelDiv>
            {dobDate.isValid() ? dobDate.format('MM/DD/YYYY') : '/'}
          </StyledInfoCol>
        </StyledInfoRow>
        <StyledInfoRow>
          <StyledInfoCol>
            <StyledLabelDiv>Sex:</StyledLabelDiv>
            {patient?.gender || '/'}
          </StyledInfoCol>
          <StyledInfoCol>
            <StyledLabelDiv>Unos ID:</StyledLabelDiv>
            {patient?.unosID || '/'}
          </StyledInfoCol>
        </StyledInfoRow>
        <StyledInfoRow>
          <StyledInfoCol>
            <StyledLabelDiv>Height:</StyledLabelDiv>
            {patient?.height || '/'}
          </StyledInfoCol>
          <StyledInfoCol>
            <StyledLabelDiv>Weight:</StyledLabelDiv>
            {patient?.weight || '/'}
          </StyledInfoCol>
        </StyledInfoRow>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const StyledPatientNameDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
`;

const StyledInfoRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const StyledLabelDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.body};
  color: ${({ theme }) => theme.colors.palette.grayscale[8]};
`;

PatientInfoAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    ageRange: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      startRange: PropTypes.number,
      endRange: PropTypes.number,
    }),
    dob: PropTypes.string,
    firstName: PropTypes.string,
    gender: PropTypes.string,
    height: PropTypes.string,
    lastName: PropTypes.string,
    patientNumber: PropTypes.string,
    unosID: PropTypes.string,
    weight: PropTypes.string,
  }),
};

PatientInfoAccordion.defaultProps = {
  patient: null,
};

export { PatientInfoAccordion };
