import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ExamStatus } from '../constants';
import { ExamActions } from '../redux';

export const useNextExamId = (
  currentExamId,
  quickFilter = ExamStatus.ALL.name,
  groupId,
  workListViewDataState,
) => {
  const dispatch = useDispatch();

  const [nextExamId, setNextExamId] = useState(null);

  useEffect(() => {
    if (currentExamId) {
      dispatch(
        ExamActions.getNextExamId({
          currentExamId,
          quickFilter,
          groupId,
          workListViewDataState,
        }),
      ).then((response) => {
        setNextExamId(response.payload.result);
      });
    }
  }, [currentExamId, dispatch, groupId, quickFilter, workListViewDataState]);

  return nextExamId;
};
