import { createSelector } from '@reduxjs/toolkit';

import { GroupModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { groupAdapter } from './reducer';

const rootSelector = (state: RootState) => state.groups;
const rootGridSelector = (state: RootState) => state.groupsGrid;

const { selectAll: getAll, selectTotal: getCount } =
  groupAdapter.getSelectors(rootSelector);

const { selectAll: getAllGrid } = groupAdapter.getSelectors(rootGridSelector);

const getByIdOutput = (groups: GroupModel[], groupId: number | undefined) =>
  groups.find((group) => group.id === groupId);

const getById = (
  groupId: number | undefined,
): AppSelector<GroupModel | undefined> =>
  createSelector([getAll, () => groupId], getByIdOutput);

const getTotal: AppSelector<number> = (state) => rootSelector(state).total;

const getTotalGrid: AppSelector<number> = (state) =>
  rootGridSelector(state).total;

export const GroupSelectors = {
  getAll,
  getAllGrid,
  getById,
  getCount,
  getTotal,
  getTotalGrid,
};
