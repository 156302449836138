export const NORMALIZED_VALUE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export enum MASKED_DATETIME_INPUT_MODES {
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
}

export const MASKED_DATETIME_INPUT_PROPS = {
  [MASKED_DATETIME_INPUT_MODES.DATE]: {
    defaultValue: '12/31/1900',
    format: 'MM/DD/YYYY',
    mask: 'AC/BC/DECC',
    rules: {
      A: /[0-1]/,
      B: /[0-3]/,
      C: /\d/,
      D: /[1-2]/,
      E: /[09]/,
    },
    placeholder: '__/__/____',
  },
  [MASKED_DATETIME_INPUT_MODES.DATETIME]: {
    defaultValue: '12/31/1900 13:00:00',
    format: 'MM/DD/YYYY HH:mm:ss',
    mask: 'AC/BC/DECC FC:GC:GC',
    rules: {
      A: /[0-1]/,
      B: /[0-3]/,
      C: /\d/,
      D: /[1-2]/,
      E: /[09]/,
      F: /[0-2]/,
      G: /[0-6]/,
    },
    placeholder: '__/__/____ __:__:__',
  },
  [MASKED_DATETIME_INPUT_MODES.TIME]: {
    defaultValue: '13:00:00',
    format: 'HH:mm:ss',
    mask: 'AC:BC:BC',
    rules: {
      A: /[0-2]/,
      B: /[0-6]/,
      C: /\d/,
    },
    placeholder: '__:__:__',
  },
};
