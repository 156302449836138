import { createContext } from 'react';

import { ExamStatusCacheContextType } from './types';

export const ExamStatusCacheContext = createContext<ExamStatusCacheContextType>(
  {
    statuses: [],
    triggerFetch: () => {
      throw new Error(
        'Cannot call triggerFetch() because <ExamStatusCacheProvider /> is a parent in the component tree.',
      );
    },
  },
);

ExamStatusCacheContext.displayName = 'ExamStatusCacheContext';
