import {
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';

import { AccountModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class AccountsClient {
  constructor(private httpClient: HttpClient) {
    this.getAllAccounts = this.getAllAccounts.bind(this);
    this.getAccountById = this.getAccountById.bind(this);
    this.getAllAccountsForKendoGrid =
      this.getAllAccountsForKendoGrid.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
  }

  public async getAllAccounts() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchAndParse<AccountModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/accounts/GetAllAccounts`,
        options,
      ),
    );
    return result;
  }

  public async getAccountById(accountId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchAndParse<AccountModel>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/accounts/${accountId}`,
        options,
      ),
    );
    return result;
  }

  public async getAllAccountsForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<
      DataResult<AccountModel>
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/accounts/grid?${queryStr}`,
        options,
      ),
    );
    return result;
  }

  public async createAccount(account: AccountModel) {
    const options = await this.httpClient.createStandardOptions(
      'POST',
      account,
    );
    const result = await this.httpClient.fetchAndParse<number>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/accounts`,
        options,
      ),
    );
    return result;
  }

  public async updateAccount(account: AccountModel) {
    const options = await this.httpClient.createStandardOptions('PUT', account);
    const result = await this.httpClient.fetch(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/accounts`,
        options,
      ),
    );
    return result;
  }

  public async deleteAccount(accountId: number) {
    const options = await this.httpClient.createStandardOptions('DELETE', null);
    const result = await this.httpClient.fetch(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/accounts/${accountId}`,
        options,
      ),
    );
    return result;
  }
}
