import { ChangeEventHandler, memo, useEffect, useRef } from 'react';

import { faFileLines, faFolder } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Icon, PrimaryCard } from 'core/ui';

import { DicomExamUploadCardProps } from '../types';

export const DicomExamUploadCard = memo<DicomExamUploadCardProps>(({ onAdd }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = useEvent(() => {
    uploadInputRef.current?.click();
  });

  const handleDicomFilesChange: ChangeEventHandler<HTMLInputElement> = useEvent((event) => {
    onAdd(event.target.files == null ? [] : Array.from(event.target.files));
  });

  // Make the folder select upload an entire directory.  This has to manually apply the attributes because
  // TypeScript doesn't define the "directory" and "webkitdirectory" props on the <input> element.
  useEffect(() => {
    if (uploadInputRef.current != null) {
      uploadInputRef.current.setAttribute('directory', '');
      uploadInputRef.current.setAttribute('webkitdirectory', '');
    }
  }, []);

  return (
    <StyledPrimaryCard>
      <StyledHeaderDiv>
        <Icon icon={faFolder} fixedWidth />
        <StyledHeaderSpan>DICOM Upload</StyledHeaderSpan>
      </StyledHeaderDiv>
      <StyledSubHeaderDiv>
        <Icon icon={faFileLines} fixedWidth />
        <StyledSubHeaderSpan>CT, XRAY, US, ECHO, MRI, NM, CATH</StyledSubHeaderSpan>
      </StyledSubHeaderDiv>
      <StyledSelectButton type="button" onClick={handleButtonClick}>
        Select Folder Or Disk
      </StyledSelectButton>
      <StyledHiddenInput ref={uploadInputRef} name="dicomFiles" multiple type="file" onChange={handleDicomFilesChange} />
    </StyledPrimaryCard>
  );
});

DicomExamUploadCard.displayName = 'DicomExamUploadCard';

const StyledPrimaryCard = styled(PrimaryCard)`
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  margin: 0;

  .icon-container {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledHeaderDiv = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  display: flex;
  align-items: center;
`;

const StyledHeaderSpan = styled.span`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.palette.blues[7]};
  margin-left: 8px;
  user-select: none;
`;

const StyledSubHeaderDiv = styled.div`
  line-height: ${({ theme }) => theme.lineHeights.subheading};
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const StyledSubHeaderSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.palette.blues[7]};
  margin-left: 8px;
  user-select: none;
`;

const StyledSelectButton = styled(Button)`
  && {
    padding: 5px 16px;
    line-height: 22px;
    height: initial;
    margin-top: 16px;
  }

  .k-button-text {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 22px;
  }
`;

const StyledHiddenInput = styled.input`
  display: none;
`;
