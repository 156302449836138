import { State } from '@progress/kendo-data-query';
import { GridSelectableSettings } from '@progress/kendo-react-grid';

export enum DataTableVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export const DEFAULT_DATA_TABLE_DATA_STATE: State = {
  skip: 0,
  take: 40,
};

export const SELECTABLE_PRESETS = {
  NoSelect: {
    enabled: false,
    drag: false,
    cell: false,
    mode: 'multiple',
  } as GridSelectableSettings,
  Selectable: {
    enabled: true,
    drag: false,
    cell: false,
    mode: 'multiple',
  } as GridSelectableSettings,
};
