import { SHARE_TOKEN_SESSION_STORAGE_KEY } from 'features/auth/constants';

let getAccessToken: null | (() => Promise<string>) = null;
let getShareToken: null | (() => Promise<string | null>) = null;

export function getAccessTokenReduxShim() {
  if (getAccessToken == null)
    throw new Error('getAccessToken has not been set.');

  return getAccessToken();
}

export function setAccessTokenReduxShim(
  newAccessTokenFunction: null | (() => Promise<string>),
) {
  getAccessToken = newAccessTokenFunction;
}

export async function getShareTokenReduxShim() {
  if (getShareToken == null) throw new Error('getShareToken has not been set.');

  return sessionStorage.getItem(SHARE_TOKEN_SESSION_STORAGE_KEY) == null
    ? null
    : getShareToken();
}

export function setShareTokenReduxShim(
  newShareTokenFunction: null | (() => Promise<string | null>),
) {
  getShareToken = newShareTokenFunction;
}
