import { forwardRef } from 'react';

import cn from 'classnames';
import { css, styled } from 'styled-components';

import { ItemHeaderButtonProps } from './ItemHeaderButtonProps';

/** Accordion item header button.  This is intended for the typical scenario where headers are a single clickable button
 *  that toggles the expand/collapse state.  More complex headers that have sub-buttons with different functionality will
 *  need to use a custom component. */
export const ItemHeaderButton = forwardRef<HTMLButtonElement, ItemHeaderButtonProps>(
  (
    { className, type = 'button', mode = 'vertical', preset = 'none', horizontalModeWidth, verticalModeHeight, selected = false, showSeparator, ...rest },
    ref,
  ) => {
    return (
      <StyledHeaderButton
        ref={ref}
        type={type}
        {...resolvePresetDimensions(preset, horizontalModeWidth, verticalModeHeight)}
        $preset={preset}
        className={cn(className, ItemHeaderButton.displayName, {
          horizontal: mode === 'horizontal',
          vertical: mode === 'vertical',
          'show-separator': showSeparator != null ? showSeparator : preset === 'mobile', // We want the mobile preset by default to show the separator.
          'preset-desktop': preset === 'desktop',
          'preset-mobile': preset === 'mobile',
          selected,
        })}
        {...rest}
      />
    );
  },
);

ItemHeaderButton.displayName = 'ItemHeaderButton';

type StyledElementProps = {
  $width: number;
  $height: number;
  $preset: 'none' | 'desktop' | 'mobile';
};

function resolvePresetDimensions(
  preset: 'none' | 'desktop' | 'mobile',
  horizontalModeWidth: number | undefined,
  verticalModeHeight: number | undefined,
): { $width: number; $height: number } {
  switch (preset) {
    case 'desktop':
      return {
        $width: horizontalModeWidth == null ? 33 : horizontalModeWidth,
        $height: verticalModeHeight == null ? 33 : verticalModeHeight,
      };
    case 'mobile':
      return {
        $width: horizontalModeWidth == null ? 128 : horizontalModeWidth,
        $height: verticalModeHeight == null ? 148 : verticalModeHeight,
      };
    default:
      return {
        $width: horizontalModeWidth == null ? 50 : horizontalModeWidth,
        $height: verticalModeHeight == null ? 50 : verticalModeHeight,
      };
  }
}

function resolvePreset(props: StyledElementProps) {
  switch (props.$preset) {
    case 'desktop':
      return PresetDesktop;
    case 'mobile':
      return PresetMobile;
    default:
      return null;
  }
}

const StyledHeaderButton = styled.button<StyledElementProps>`
  // Remove button styling applied by some global CSS and browser defaults.
  border: none;
  padding: 0;
  background: inherit;
  font: inherit;
  appearance: none;
  color: inherit;

  &:focus,
  &:focus-visible,
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
    border: none;
  }

  // Actual styling that we want to see on the button.
  display: flex;
  overflow: hidden;
  flex: 0 0 auto; // Flex basis of 'auto' will differ sizing along the main axis to the 'width' or 'height' property (directly below).

  // Set the width and height in a mutually exclusive fashion in order to apply the minimal amount of css
  // necessary to the base accordion.  For stable sizing along the flex main axis we need to establish a
  // specific size at some point, which currently is right here.
  &.horizontal {
    width: ${({ $width }) => $width}px;
  }
  &.vertical {
    height: ${({ $height }) => $height}px;
  }

  ${resolvePreset}
`;

const PresetDesktop = css<StyledElementProps>`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.palette.grayscale[3]};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  &.selected {
    background-color: ${({ theme }) => theme.colors.palette.blues[1]};
  }
`;

const PresetMobile = css<StyledElementProps>`
  flex-direction: column;

  &.horizontal.show-separator,
  &.horizontal.show-separator:focus,
  &.horizontal.show-separator:focus-visible,
  &.horizontal.show-separator:focus:not(:focus-visible) {
    border-left: 1px solid ${({ theme }) => theme.colors.palette.grayscale[6]};
  }

  &.vertical.show-separator,
  &.vertical.show-separator:focus,
  &.vertical.show-separator:focus-visible,
  &.vertical.show-separator:focus:not(:focus-visible) {
    border-top: 1px solid ${({ theme }) => theme.colors.palette.grayscale[6]};
  }
`;
