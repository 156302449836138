import { memo } from 'react';

import { GridHeaderCellProps } from '@progress/kendo-react-grid';

import { HeaderCell } from 'core/ui';

import { DestinationRouteGridService } from '../services';

export const DestinationRouteGridHeaderCell = memo<GridHeaderCellProps>(({ field, ...restProps }) => {
  if (field == null) {
    throw new Error('Cannot render DestinationRouteGridHeaderCell because the field property is null or undefined.');
  }

  const description = DestinationRouteGridService.getColumnDefinition(field).headerCellDescription;

  return <HeaderCell {...restProps} description={description} />;
});

DestinationRouteGridHeaderCell.displayName = 'DestinationRouteGridHeaderCell';
