import styled from 'styled-components';

import { isNullOrWhitespace } from 'core/utils';

export type GridColumnProps = {
  /** Column start.  Populates the CSS property 'grid-column-start'. */
  columnStart?: string | null;
  /** Column e.  Populates the CSS property 'grid-column-end'. */
  columnEnd?: string | null;
  /** Right aligns text and sets white-space to nowrap. */
  isLabelColumn?: boolean;
};

export const GridColumn = styled.div<GridColumnProps>`
  ${({ columnStart }) =>
    isNullOrWhitespace(columnStart) ? '' : `grid-column-start: ${columnStart};`}
  ${({ columnEnd }) =>
    isNullOrWhitespace(columnEnd) ? '' : `grid-column-end: ${columnEnd};`}
  ${({ isLabelColumn }) =>
    !isLabelColumn
      ? ''
      : `
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    white-space: nowrap;
    padding: 4px 8px 0 0;
  `}
`;
