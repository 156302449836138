export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonLooks {
  STANDARD = 'standard',
  AQUA = 'aqua',
  WHITE = 'white',
}
