import { FunctionComponent } from 'react';

import { Error } from '@progress/kendo-react-labels';
import styled from 'styled-components';

import { ErrorMessageProps } from './ErrorMessageProps';

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ children, className, id }) => (
  <StyledErrorBase className={className} id={id}>
    {children}
  </StyledErrorBase>
);

ErrorMessage.displayName = 'ErrorMessage';

const StyledErrorBase = styled(Error)`
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
  margin-top: 0;
  user-select: none;
`;
