import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field, FormValidatorsService } from 'core/forms';
import { Accordion, DropdownWithValuesField, TextArea } from 'core/ui';

import { OverreadHistory } from '../../exam/fragments/OverreadHistory';
import HeartMonitorIcon from '../assets/heart-monitor.svg?react';
import { ExamQualities } from '../constants';
import { ReadingTemplate } from './ReadingTemplate';

// has to be wrapped in <Form /> to work
const RadiologyResultsAccordion = ({
  eventKey,
  isFormVisible,
  reads,
  valueSetter,
  template,
  fieldName,
  textField,
  dataItemKey,
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={HeartMonitorIcon} title="Results" />
      <Accordion.Body>
        {isFormVisible && (
          <StyledFormWrapperDiv>
            <StyledRow>
              <Col className="col-12">
                <Field
                  component={DropdownWithValuesField}
                  data={ExamQualities}
                  dataItemKey="value"
                  isForPrimitiveValues
                  label="Study Quality"
                  name="studyQuality"
                  valueField="value"
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col className="col-12">
                <ReadingTemplate
                  template={template}
                  textField={textField}
                  dataItemKey={dataItemKey}
                  valueSetter={valueSetter}
                  fieldName={fieldName}
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <StyledOverreadCol className="col-12">
                <Field
                  component={TextArea}
                  name="overRead"
                  required
                  validator={FormValidatorsService.required}
                />
              </StyledOverreadCol>
            </StyledRow>
          </StyledFormWrapperDiv>
        )}
        <OverreadHistory readHistory={reads} />
      </Accordion.Body>
    </Accordion.Item>
  );
};

const StyledRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledOverreadCol = styled(Col)`
  textarea {
    min-height: 50vh;
  }
`;

const StyledFormWrapperDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.space.spacing40};
`;

RadiologyResultsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  reads: PropTypes.arrayOf(PropTypes.shape({})),
  valueSetter: PropTypes.func.isRequired,
  template: PropTypes.arrayOf(PropTypes.shape({})),
  fieldName: PropTypes.string,
  textField: PropTypes.string,
  dataItemKey: PropTypes.string,
};

RadiologyResultsAccordion.defaultProps = {
  reads: [],
  template: null,
  fieldName: '',
  textField: '',
  dataItemKey: '',
};

export { RadiologyResultsAccordion };
