import PropTypes from 'prop-types';
import Iframe from 'react-iframe';

const PDFViewer = ({ className, url }) => {
  return <Iframe className={className} height="100%" url={url} width="100%" />;
};

PDFViewer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
};

PDFViewer.defaultProps = {
  className: undefined,
};

export { PDFViewer };
