import { FunctionComponent, ReactElement, cloneElement, useState } from 'react';

import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { MultiSelect, TagData } from '@progress/kendo-react-dropdowns';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

import { IntegrationModel } from 'models/IntegrationModel';

export const IntegrationMultiSelect: FunctionComponent<{
  data: IntegrationModel[];
  value: IntegrationModel[];
  onChange: (value: { value: IntegrationModel[] }) => void;
}> = ({ value, onChange, data }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationModel>({ controlName: '' } as IntegrationModel);

  const handleDialogUpdate = () => {
    // apply new/changed integration
    const newValue = value?.map((i) => {
      if (i.id === selectedIntegration.id) {
        return selectedIntegration;
      }
      return i;
    });
    onChange({ value: newValue });
    setShowDialog(false);
  };

  const onOpenDialog = (tagData: TagData) => {
    setSelectedIntegration(tagData.data[0]);
    setShowDialog(true);
  };

  const handleControlNameChange = (event: InputChangeEvent) => {
    setSelectedIntegration({
      ...selectedIntegration,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      controlName: event.target.value.toString(),
    });
  };
  const tagRender = (tagData: TagData, li: ReactElement<HTMLLIElement>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cloneElement(li, li.props, [
      <span onClick={() => onOpenDialog(tagData)} key={tagData.data[0]?.id}>
        {tagData.text}
      </span>,
      li.props.children,
    ]);

  return (
    <StyledContainerDiv>
      {showDialog && (
        <Dialog title={`Update ${selectedIntegration.name}`} onClose={() => setShowDialog(false)}>
          <Input placeholder="Control Name:" autoFocus value={selectedIntegration.controlName} onChange={handleControlNameChange} />
          <DialogActionsBar>
            <Button onClick={handleDialogUpdate}>Apply</Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <MultiSelect data={data} textField="name" dataItemKey="id" value={value} onChange={onChange} tagRender={tagRender} />
    </StyledContainerDiv>
  );
};

const StyledContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
