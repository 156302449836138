import { DelimitedTextInternalValue } from './DelimitedTextInternalValue';
import { INPUT_DELIMITER, OUTPUT_DELIMITER } from './constants';

function splitDelimitedString(values: string | null) {
  return values === null ? [] : values.split(INPUT_DELIMITER);
}

function internalToString(internalValues: DelimitedTextInternalValue[]): string;
function internalToString(internalValues: undefined): undefined;
function internalToString(
  internalValues: DelimitedTextInternalValue[] | undefined,
): string | undefined;
function internalToString(
  internalValues: DelimitedTextInternalValue[] | undefined,
) {
  return typeof internalValues === 'undefined'
    ? undefined
    : internalValues.map((i) => i.value).join(OUTPUT_DELIMITER);
}

export const DelimitedTextService = {
  splitDelimitedString,
  internalToString,
} as const;
