import { useContext } from 'react';

import { ParsedShareAccessTokenContext } from '../contexts';
import { ParsedShareAccessToken } from '../types';

function useParsedShareAccessToken(required: true): ParsedShareAccessToken;
function useParsedShareAccessToken(
  required: false,
): ParsedShareAccessToken | null | undefined;
function useParsedShareAccessToken(required: boolean) {
  const shareToken = useContext(ParsedShareAccessTokenContext);

  if (required && shareToken == null)
    throw new Error(
      'Expected ParsedShareAccessTokenContext to contain an object value but found null or undefined.',
    );

  return shareToken;
}

export { useParsedShareAccessToken };
