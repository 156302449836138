import { FunctionComponent } from 'react';

import { NavLink, NavLinkProps } from 'react-bootstrap';
import styled from 'styled-components';

export const TabNavLink: FunctionComponent<NavLinkProps> = (props) => {
  return <StyledNavLink {...props} />;
};

const StyledNavLink = styled(NavLink)`
  cursor: pointer;
`;
