import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field } from 'core/forms';
import { Accordion, Checkbox, Label, TextArea } from 'core/ui';

import HeartMonitorIcon from '../assets/heart-monitor.svg?react';
import { BiopsyIndicationForm } from './BiopsyIndicationForm';

// has to be wrapped in <Form /> to work
const PathologyObservationsAccordion = ({ eventKey, isFormDisabled, valueGetter }) => {
  const htnValue = valueGetter('Observations.htn');
  const diabetesValue = valueGetter('Observations.diabetes');
  const peakCreatinineValue = valueGetter('Observations.peakCreatinine');
  const othersValue = valueGetter('Observations.others');
  const specimenObtainedDateTimeValue = valueGetter('Observations.specimenObtainedDateTime');

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={HeartMonitorIcon} title="Observations" />
      <Accordion.Body>
        <StyledFormWrapperDiv>
          <StyledIndicationsRow>
            <StyledSectionTitleDiv>Biopsy Indications</StyledSectionTitleDiv>
            <Field component={Checkbox} disabled={isFormDisabled} inline text="HTN" name="Observations.htn" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Diabetes" name="Observations.diabetes" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Peak Creatinine" name="Observations.peakCreatinine" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Organ Appearance" name="Observations.organAppearance" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Age" name="Observations.age" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Obesity" name="Observations.obesity" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Protienurea" name="Observations.protienurea" />
            <Field component={Checkbox} disabled={isFormDisabled} inline text="Others" name="Observations.others" />
            {htnValue && <BiopsyIndicationForm name="HTN" fieldNamePrefix="Observations.htn" />}
            {diabetesValue && <BiopsyIndicationForm name="DIABETES" fieldNamePrefix="Observations.diabetes" />}
            {peakCreatinineValue && <BiopsyIndicationForm name="PEAK CREATININE" fieldNamePrefix="Observations.peakCreatinine" />}
            {othersValue && (
              <StyledOthersRow>
                <StyledTitleDiv>OTHERS</StyledTitleDiv>
                <Field component={TextArea} name="Observations.otherNotes" />
              </StyledOthersRow>
            )}
          </StyledIndicationsRow>
        </StyledFormWrapperDiv>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const StyledTitleDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.space.spacing20};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const StyledRow = styled(Row)`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledOthersRow = styled(Row)`
  background: ${({ theme }) => theme.colors.palette.white};
  border-radius: 8px;
  margin-left: ${({ theme }) => theme.space.spacing20};
  margin-top: ${({ theme }) => theme.space.spacing20};
  padding: ${({ theme }) => theme.space.spacing20};
  width: 95%;
`;

const StyledIndicationsRow = styled(StyledRow)`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 8px;
  padding: ${({ theme }) => theme.space.spacing20};
`;

const StyledSectionTitleDiv = styled.div`
  color: ${({ theme }) => theme.colors.palette.grayscale[11]};
  font-size: ${({ theme }) => theme.fontSizes.subheading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledFormWrapperDiv = styled.div`
  //margin-bottom: ${({ theme }) => theme.space.spacing40};
`;

PathologyObservationsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  valueGetter: PropTypes.func.isRequired,
};

export { PathologyObservationsAccordion };
