import { forwardRef } from 'react';

import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup as KendoPopup } from '@progress/kendo-react-popup';
import _map from 'lodash/map';
import styled from 'styled-components';

import { PopupProps } from './PopupProps';

const MENU_STYLE = { display: 'inline-block' };

export const Popup = forwardRef<HTMLDivElement, PopupProps>(({ className, anchor, onSelect, isOpen, menuItems, offset, vertical = true }, ref) => {
  return (
    <div>
      <StyledPopup ref={ref} anchor={anchor} offset={offset} show={isOpen}>
        <Menu className={className} vertical={vertical} style={MENU_STYLE} onSelect={onSelect}>
          {_map(menuItems, (item: { text: string }) => (
            <MenuItem key={item.text} text={item.text} />
          ))}
        </Menu>
      </StyledPopup>
    </div>
  );
});

Popup.displayName = 'Popup';

const StyledPopup = styled(KendoPopup)`
  & .k-popup {
    padding: 0;
  }
`;
