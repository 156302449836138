import { PhysicianModel } from 'models';

import { FileService } from 'features/file';

import { InitialValues } from '../constants';
import {
  ContactTabFormValues,
  CredentialsTabFormValues,
  GeneralTabFormValues,
  IntegrationTabFormValues,
  NotificationsTabFormValues,
} from '../types';

async function copyModelToForms(physician: PhysicianModel): Promise<{
  generalForm: GeneralTabFormValues;
  contactForm: ContactTabFormValues;
  credentialsForm: CredentialsTabFormValues;
  integrationForm: IntegrationTabFormValues;
  notificationsForm: NotificationsTabFormValues;
}> {
  // Convert the encoded signature image into something that the Kendo Upload component can use.
  const signature = FileService.restoreDataUriToFile(
    physician.signature,
    'Signature',
  );

  return {
    generalForm: {
      signature:
        signature == null
          ? null
          : [await FileService.fileToKendoUpload(signature)],
      firstName: physician.firstName ?? '',
      lastName: physician.lastName ?? '',
      title: physician.title ?? '',
      specialty: physician.specialty ?? '',
      notes: physician.notes ?? '',
      active: physician.active,
    },
    contactForm: {
      address1: physician.address1 ?? '',
      address2: physician.address2 ?? '',
      city: physician.city ?? '',
      state: physician.state ?? '',
      zip: physician.zip ?? '',
      phone1: physician.phone1 ?? '',
      phone2: physician.phone2 ?? '',
      email: physician.email ?? '',
      smsEmail: physician.smsEmail ?? '',
    },
    credentialsForm: {
      npi: physician.npi ?? '',
    },
    integrationForm: {
      britUser: physician.britUser ?? '',
    },
    notificationsForm: {
      emailNotificationSTATExam: physician.emailNotificationSTATExam,
      emailConfirmation: false, // TODO: Should this be removed?  It's not in the PhysicianModel.
      emailConfirmationPDF: false, // TODO: Should this be removed?  It's not in the PhysicianModel.
    },
  };
}

async function copyFormsToModel(
  initialState: Partial<PhysicianModel> | null | undefined,
  generalForm: GeneralTabFormValues,
  contactForm: ContactTabFormValues,
  credentialsForm: CredentialsTabFormValues,
  integrationForm: IntegrationTabFormValues,
  notificationsForm: NotificationsTabFormValues,
): Promise<PhysicianModel> {
  let newModel: PhysicianModel;

  // Convert the image File into a Base64 encoded string.
  const encodedSignature =
    generalForm.signature != null &&
    generalForm.signature.length > 0 &&
    generalForm.signature[0].getRawFile != null
      ? await FileService.blobToDataUri(generalForm.signature[0].getRawFile())
      : null;

  if (initialState == null) {
    newModel = {
      ...InitialValues.nonFormFields,
      ...generalForm,
      ...contactForm,
      ...credentialsForm,
      ...integrationForm,
      ...notificationsForm,
      signature: encodedSignature,
    };
  } else {
    newModel = {
      ...InitialValues.nonFormFields,
      ...initialState,
      ...generalForm,
      ...contactForm,
      ...credentialsForm,
      ...integrationForm,
      ...notificationsForm,
      signature: encodedSignature,
    };
  }

  return newModel;
}

export const PhysicianEditService = {
  copyModelToForms,
  copyFormsToModel,
};
