import { Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { sortBy } from 'lodash';

import { SwitchCell, TextCell } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { ColumnDefinition } from 'features/exam/types/ColumnDefinition';
import { ColumnState } from 'features/exam/types/ColumnState';

import { DefaultColumnsState } from '../types/constants';

const destinationRouteColumnCollections: ColumnDefinition[] = [
  {
    cell: TextCell,
    field: 'id',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'System Generated Number',
    title: 'Id',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'numeric',
    search: true,
  },
  {
    cell: TextCell,
    field: 'destinationName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Destination for Destination Route',
    title: 'Destination',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'integrationName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Integration for Destination Route',
    title: 'Integration',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'statusTypeName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Destination Status of Destination Route',
    title: 'Destination Status',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'previousStatusTypeName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Source Status of Destination Route',
    title: 'Source Status',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'physicianName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Full name of physician',
    title: 'Physician',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'locationName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Location of destination route',
    title: 'Location',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'contactName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Contact of destination route',
    title: 'Contact',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'filter',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Filter destination route',
    title: 'Filter',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: SwitchCell,
    field: 'isEventTrigger',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Will trigger on matching workflow change events',
    title: 'Event Trigger',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
    show: false,
  },
  {
    cell: SwitchCell,
    field: 'active',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Destination Route is accessible',
    title: 'Active',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
    show: false,
  },
];

const destinationRouteColumns = [
  'id',
  'destinationName',
  'integrationName',
  'statusTypeName',
  'previousStatusTypeName',
  'physicianName',
  'locationName',
  'contactName',
  'filter',
  'isEventTrigger',
  'active',
];

// get the columns of each features
function getDefineColumn(columns: string[]) {
  const finalColumns: ColumnDefinition[] = [];

  // loop to each columns
  columns.forEach((column) => {
    // find item to the collections
    finalColumns.push(findOrThrow(destinationRouteColumnCollections, (item) => item.field === column, `Could not find column definition for: "${column}".`));
  });

  return finalColumns;
}
const DESTINATIONROUTE_COLUMNS = getDefineColumn(destinationRouteColumns);

function getColumns() {
  return DESTINATIONROUTE_COLUMNS;
}

function getColumnState(allColumnStates: Record<string, ColumnState>, columnField: string): ColumnState {
  const orderIndex = allColumnStates[columnField]?.orderIndex ?? DefaultColumnsState[columnField]?.orderIndex ?? 0;
  const show = allColumnStates[columnField]?.show ?? DefaultColumnsState[columnField]?.show ?? false;

  return { orderIndex, show };
}

function getGridColumns() {
  return sortBy(
    DESTINATIONROUTE_COLUMNS.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getColumnDefinition(field: string) {
  return findOrThrow(destinationRouteColumnCollections, (column) => column.field === field, `Could not find column definition for: "${field}".`);
}

export const DestinationRouteGridService = {
  getColumns,
  getColumnState,
  getGridColumns,
  getColumnDefinition,
};
