const blues = [
  '#E5EBF0', // 0
  '#B2C2D1', // 1
  '#8099B3', // 2
  '#4C7094', // 3
  '#194775', // 4
  '#2666a6', // 5
  '#002E5C', // 6
  '#002447', // 7
  '#001A33', // 8
  '#000F1F', // 9
];

const aquas = [
  '#EBF5FA', // 0
  '#C2E0F0', // 1
  '#99CCE6', // 2
  '#70B8DB', // 3
  '#47A3D1', // 4
  '#3399CC', // 5
  '#2E8AB8', // 6
  '#246B8F', // 7
  '#1A4D66', // 8
  '#0F2E3D', // 9
];

const reds = [
  '#FAE6EB', // 0
  '#F0B5C4', // 1
  '#E6849C', // 2
  '#DC5374', // 3
  '#D2224D', // 4
  '#CD0A39', // 5
  '#B90933', // 6
  '#900728', // 7
  '#67051C', // 8
  '#3E0311', // 9
];

const yellows = [
  '#FFF6E8', // 0
  '#FEE4BA', // 1
  '#FDD28C', // 2
  '#FCC15E', // 3
  '#FBAF30', // 4
  '#FAA619', // 5
  '#E19517', // 6
  '#AF7412', // 7
  '#7D530D', // 8
  '#4B3208', // 9
];

const greens = [
  '#EFFAED', // 0
  '#CEEFC8', // 1
  '#ADE4A4', // 2
  '#8CD980', // 3
  '#6BCE5B', // 4
  '#5BC949', // 5
  '#52B542', // 6
  '#48973B', // 7
  '#2D6524', // 8
  '#1B3C16', // 9
];

const grayscale = [
  '#FFFFFF', // 0
  '#FAFAFA', // 1
  '#F5F5F5', // 2
  '#F0F0F0', // 3
  '#D9D9D9', // 4
  '#BFBFBF', // 5
  '#888888', // 6
  '#595959', // 7
  '#434343', // 8
  '#262626', // 9
  '#1F1F1F', // 10
  '#141414', // 11
  '#000000', // 12
];
const white = grayscale[0];
const black = grayscale[12];
const gray = grayscale[6];

const primary = blues[5];
const secondary = aquas[5];
const success = greens[5];
const warning = yellows[5];
const error = reds[5];

const textPrimary = grayscale[9];
const textSecondary = grayscale[6];
const textDisabled = grayscale[7];
const code = grayscale[7];
const backgroundDisabled = grayscale[2];
const borderBase = grayscale[4];
const borderDisabled = grayscale[5];
const iconDisabled = grayscale[4];

const TATLow = '#faa619';
const TATMedium = '#800080';
const TATHigh = '#cd0a39';

const backgroundPrimary = 'rgba(229, 235, 240, 0.5)';

const palette = {
  blues,
  aquas,
  reds,
  yellows,
  greens,
  grayscale,
  white,
  black,
  gray,
};

export const colors = {
  palette,
  primary,
  secondary,
  success,
  warning,
  error,
  textPrimary,
  textSecondary,
  textDisabled,
  code,
  backgroundDisabled,
  borderBase,
  borderDisabled,
  iconDisabled,
  TATLow,
  TATMedium,
  TATHigh,
  backgroundPrimary,
};

export type Colors = typeof colors;
