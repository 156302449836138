import { FunctionComponent } from 'react';

import { faTag } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { Icon } from 'core/ui';
import { hasText } from 'core/utils';

import { PatientUtils } from '../services';
import { SharePatientHeaderProps } from '../types/SharePatientHeaderProps';

export const SharePatientHeader: FunctionComponent<SharePatientHeaderProps> = ({
  patient,
}) => {
  const dobDisplay = `${dayjs(patient.dob).format(
    'MM/DD/YYYY',
  )} (${PatientUtils.calculateAgeFromDob(patient.dob)})`;

  return (
    <StyledTopDiv>
      <StyledLabelDiv>
        {PatientUtils.formatName(patient.firstName, patient.lastName)}
      </StyledLabelDiv>
      <StyledLabelDiv>
        {hasText(patient.unosID) ? (
          <>
            <Icon icon={faTag} />
            Unos {patient.unosID}
          </>
        ) : undefined}
      </StyledLabelDiv>
      <StyledLabelDiv>{dobDisplay}</StyledLabelDiv>
      <StyledLabelDiv>
        {hasText(patient.patientNumber) ? (
          <>
            <Icon icon={faTag} />
            MRN {patient.patientNumber}
          </>
        ) : undefined}
      </StyledLabelDiv>
    </StyledTopDiv>
  );
};

SharePatientHeader.displayName = 'SharePatientHeader';

const StyledTopDiv = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: min-content min-content;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.palette.white};
`;

const StyledLabelDiv = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 24px;

  .icon-container {
    margin-right: 8px;
  }
`;
