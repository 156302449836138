import { ChangeEvent, memo, useRef } from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, Icon } from 'core/ui';

import { AddFilesButtonsProps } from '../types';

export const AddFilesButtons = memo<AddFilesButtonsProps>(({ className, onAddAttachmentFiles }) => {
  const attachmentsInputRef = useRef<HTMLInputElement | null>(null);

  const handleAddAttachmentsClick = useEvent(() => {
    attachmentsInputRef?.current?.click();
  });

  const handleAttachmentsInputChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files == null ? [] : Array.from(event.target.files);
    onAddAttachmentFiles(newFiles);
  });

  return (
    <StyledHeaderButtonsContainer className={className}>
      <StyledIconButton onClick={handleAddAttachmentsClick}>
        <Icon icon={faPlus} fixedWidth={false} />
        Upload File(s)
      </StyledIconButton>
      <StyledHiddenInput
        accept=".pdf,.png,.jpg,.jpeg,.tif,.tiff,.svs,.szi,.mp4"
        name="file"
        multiple
        onChange={handleAttachmentsInputChange}
        ref={attachmentsInputRef}
        type="file"
      />
    </StyledHeaderButtonsContainer>
  );
});

AddFilesButtons.displayName = 'AddFilesButtons';

const StyledHeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledIconButton = styled(Button)`
  position: relative;
  display: flex;
  align-items: center;

  && .k-button-text {
    padding-left: 20px;
  }

  .icon-container {
    position: absolute;
    left: 8px;
  }
`;

const StyledHiddenInput = styled.input`
  display: none;
`;
