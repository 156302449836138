import { FunctionComponent, ReactNode, useMemo } from 'react';

import { GridColumnMenuWrapper } from '@progress/kendo-react-grid';
import styled, { DefaultTheme } from 'styled-components';

import { Tooltip } from '../../../Tooltip';
import { DataTableVariants } from '../../constants';
import { HeaderCellProps } from './HeaderCellProps';

export const HeaderCell: FunctionComponent<HeaderCellProps> = ({
  children,
  description,
  field,
  onClick,
  title,
  variant = DataTableVariants.PRIMARY,
  columnMenuWrapperProps,
}) => {
  // only show sort icon if the column is actively sorting
  const areSortIconsVisible = Boolean(Array.isArray(children) && children[0].props.className?.includes('sort'));
  const content = useMemo(
    () => (
      <div>
        <Tooltip text={description || title}>
          <StyledSpanHeaderCell onClick={onClick} variant={variant}>
            {title || field}
            {areSortIconsVisible && <StyledSpanSortWrapper>{children}</StyledSpanSortWrapper>}
          </StyledSpanHeaderCell>
        </Tooltip>
        {columnMenuWrapperProps.columnMenu && (
          <StyledGridColumnMenu>
            <GridColumnMenuWrapper {...columnMenuWrapperProps}></GridColumnMenuWrapper>
          </StyledGridColumnMenu>
        )}
      </div>
    ),
    [areSortIconsVisible, children, description, field, onClick, title, variant],
  );

  return content;
};

HeaderCell.displayName = 'HeaderCell';

type StyledSpanHeaderCellProps = {
  readonly variant: DataTableVariants;
};

const resolveTextColor = ({ theme, variant }: { theme: DefaultTheme; variant: DataTableVariants }): string | undefined => {
  if (variant === DataTableVariants.PRIMARY) {
    return theme.colors.palette.white;
  }

  if (variant === DataTableVariants.SECONDARY) {
    return theme.colors.palette.grayscale[10];
  }

  return undefined;
};

const StyledGridColumnMenu = styled.div`
  &&& .k-grid-column-menu {
    bottom: -0.25em;
  }
`;

const StyledSpanHeaderCell = styled.span<StyledSpanHeaderCellProps>`
  border-left: ${({ theme }) => theme.borderWidths.base} solid rgba(0, 0, 0, 0.06);
  color: ${resolveTextColor};
  cursor: pointer;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.body};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: ${({ theme }) => theme.lineHeights.body};
  padding-bottom: ${({ theme }) => theme.space.spacing10};
  padding-left: 0;
  padding-top: ${({ theme }) => theme.space.spacing10};

  .fill-width & {
    text-align: left;
    padding: 0 3px;
  }

  & div {
    display: inline-block;

    & .k-icon {
      color: ${({ theme }) => theme.colors.palette.white};
      font-size: ${({ theme }) => theme.fontSizes.footnote};
      line-height: ${({ theme }) => theme.lineHeights.footnote};
      margin-left: ${({ theme }) => theme.space.dataTableHeaderCellIconMarginLeft};
    }
  }

  span.k-svg-icon {
    color: #fff;
  }

  &&& .k-grid-header-menu {
    color: red;
  }
`;

const StyledSpanSortWrapper = styled.span`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.dataTableHeaderCellIconMarginRight};
  justify-content: center;
  margin-left: ${({ theme }) => theme.space.dataTableHeaderCellIconMarginLeft};
`;
