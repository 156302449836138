import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OverreadHistoryCard } from './OverreadHistoryCard';

const OverreadHistory = ({ readHistory }) => {
  return (
    <StyledOverReadHistoryDiv>
      {readHistory.map((readHistoryEntry) => (
        <OverreadHistoryCard
          key={readHistoryEntry.id}
          overreadHistoryEntry={readHistoryEntry}
        />
      ))}
    </StyledOverReadHistoryDiv>
  );
};

const StyledOverReadHistoryDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

OverreadHistory.propTypes = {
  readHistory: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
};

OverreadHistory.defaultProps = {
  readHistory: [],
};

export { OverreadHistory };
