import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { PhysicianDashboardRoutes } from '../routes';
import { PhysicianDashboardPage } from './PhysicianDashboardPage';

export const PhysicianDashboard = () => {
  return (
    <Routes>
      <Route
        path={PhysicianDashboardRoutes.HOME}
        element={
          <RouteGuard allowedRoles={[UserRoles.ADMIN, UserRoles.PHYSICIAN]}>
            <PhysicianDashboardPage />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
