import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { AuthenticationScheme, RouteGuard } from 'features/auth';

import { ShareRoutes } from '../routes';
import { MobileFilters } from './MobileFilters';
import { ShareDefaultAuthorizationChallenge } from './ShareDefaultAuthorizationChallenge';
import { ShareLanding } from './ShareLanding';
import { ShareLandingAuthorizationChallenge } from './ShareLandingAuthorizationChallenge';
import { Upload } from './Upload';
import { Viewer } from './Viewer';

const LANDING_ALLOWED_SCHEMES = [AuthenticationScheme.ANONYMOUS, AuthenticationScheme.OIDC];

export const Share: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={ShareRoutes.HOME}
        element={
          <RouteGuard allowedSchemes={LANDING_ALLOWED_SCHEMES} validateShareLinkId challengeComponent={ShareLandingAuthorizationChallenge}>
            <ShareLanding />
          </RouteGuard>
        }
      />
      <Route
        path={ShareRoutes.VIEWER}
        element={
          <RouteGuard allowedSchemes={AuthenticationScheme.SHARE} validateShareLinkId challengeComponent={ShareDefaultAuthorizationChallenge}>
            <Viewer />
          </RouteGuard>
        }
      >
        <Route path="filters" element={<MobileFilters />} />
      </Route>
      <Route
        path={ShareRoutes.UPLOAD}
        element={
          <RouteGuard allowedSchemes={AuthenticationScheme.SHARE} validateShareLinkId challengeComponent={ShareDefaultAuthorizationChallenge}>
            <Upload />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

Share.displayName = 'Share';
