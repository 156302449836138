import { useCallback, useEffect, useMemo, useState } from 'react';

import { SvgIcon } from '@progress/kendo-react-common';
import { homeIcon } from '@progress/kendo-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useWindowSize } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Breadcrumb, CollapsibleSidebar, useBreakpoints } from 'core/ui';

import { useAccessTokenSnapshot } from 'features/auth/hooks/useAccessTokenSnapshot';
import { ExamActions, ExamSelectors } from 'features/exam';
import { FileSelectSidebar, FileViewer } from 'features/file';
import { useUserSettings } from 'features/settings';

import { ExamStatus } from '../../exam/constants';
import { useExamFiles, useNextExamId } from '../../exam/hooks';
import { ExamReadSubmitType } from '../constants';
import { ExamReadingActions } from '../redux';
import { ExamReadsDataService } from '../services';
import { ExamReadSidebarBody } from './ExamReadSidebarBody';
import { UndoAddReadMessage } from './UndoAddReadMessage';

const MOBILE_WIDTH_BREAKPOINT = 768;

export const ExamRead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { homePageUrl, fullScreenReading } = useUserSettings();

  const exam = useSelector(ExamSelectors.getById(id));
  const previousExamId = useSelector(ExamSelectors.getPreviousExamId);

  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const examFilter = searchParams.get('quickFilter') || ExamStatus.ALL.name;
  const groupId = searchParams.get('groupId');

  // get the current exam data state
  const worklistView = localStorage.getItem('currentWorklistViewODataStr');

  // get the next exam id
  const nextExamId = useNextExamId(id, examFilter, groupId, worklistView);
  const { width } = useWindowSize();
  const [popupWindows] = useState(null);

  const { accessToken } = useAccessTokenSnapshot();

  const { setVerticalClampOverride } = useBreakpoints();

  useEffect(() => {
    setVerticalClampOverride(true);

    return () => {
      setVerticalClampOverride(false);
    };
  }, [setVerticalClampOverride]);

  const { files, currentFile, setCurrentFile } = useExamFiles(id, exam?.statusType?.name, accessToken);

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'home',
        text: 'Home',
        icon: <StyledIcon icon={homeIcon} />,
        navigateToExternal: homePageUrl,
      },
    ],
    [homePageUrl],
  );

  const handleBreadcrumbClick = useCallback(
    (event) => {
      const selectedItem = breadcrumbs.find((item) => item.id === event.id);

      if (selectedItem.navigateTo) {
        navigate(selectedItem.navigateTo);

        return;
      }

      window.location = selectedItem.navigateToExternal;
    },
    [navigate],
  );

  const closeWindows = useCallback(() => {
    if (popupWindows) {
      popupWindows.forEach((window) => {
        window.close();
      });
    }
  }, [popupWindows]);

  const handleNextExamClick = useCallback(() => {
    closeWindows();
    dispatch(ExamActions.pushRecentlyViewedExam(id));
    navigate(`/exam/${nextExamId}/read?${searchParams}`);
  }, [closeWindows, dispatch, id, navigate, nextExamId, searchParams]);

  const handlePrevExamClick = () => {
    closeWindows();
    dispatch(ExamActions.popRecentlyViewedExam());
    navigate(`/exam/${previousExamId}/read?${searchParams}`);
  };

  const handleUndoClick = useCallback(
    (undoExamId) => {
      navigate(`/exam/${undoExamId}/read?${searchParams}`);
    },
    [navigate, searchParams],
  );

  const handleAddRead = useCallback(
    ({ values, event }) => {
      // TODO: show preview for For non alivecor, non-corrections radiology?

      const { examData, readData } = ExamReadsDataService.getSubmitData({
        id,
        ...values,
      });

      const submitType = event.nativeEvent.submitter.id;

      if (submitType === ExamReadSubmitType.SAVE || submitType === ExamReadSubmitType.FINALIZE) {
        dispatch(ExamReadingActions.saveRead({ ...examData, dynamicForms: readData })).then(() => {
          if (submitType === ExamReadSubmitType.SAVE) {
            NotificationsService.displaySuccess('Saved.');
          } else if (submitType === ExamReadSubmitType.FINALIZE) {
            navigate(`/exam/${id}/read/approve`);
          }
        });
      } else if (submitType === ExamReadSubmitType.APPROVE || submitType === ExamReadSubmitType.APPROVE_AND_NEXT) {
        dispatch(
          ExamReadingActions.addRead({
            ...examData,
            dynamicForms: readData,
          }),
        ).then(() => {
          if (submitType === ExamReadSubmitType.APPROVE) {
            window.location = homePageUrl;
          } else {
            NotificationsService.displaySuccess(<UndoAddReadMessage onUndo={() => handleUndoClick(id)} />, {
              autoClose: 60 * 1000, // 60s
              closeOnClick: false,
              hideProgressBar: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
            });
            handleNextExamClick();
          }
        });
      } else {
        throw new Error('TODO: Not implemented.');
      }
    },
    [dispatch, handleNextExamClick, handleUndoClick, id, navigate],
  );

  useEffect(() => {
    dispatch(ExamActions.getByIdAndLock(id));
  }, [dispatch, id]);

  return (
    <CollapsibleSidebar
      header={<Breadcrumb data={breadcrumbs} onItemSelect={handleBreadcrumbClick} onKeyDown={handleBreadcrumbClick} />}
      body={
        <ExamReadSidebarBody
          exam={exam}
          handleNextExamClick={nextExamId && nextExamId !== '0' ? handleNextExamClick : undefined}
          handlePrevExamClick={previousExamId ? handlePrevExamClick : undefined}
          handleSubmit={handleAddRead}
        />
      }
      expanded={sidebarExpanded}
      onExpandChange={setSidebarExpanded}
      width={
        fullScreenReading
          ? window.innerWidth - 120 // full screen by user setting
          : 400
      }
    >
      <StyledDivContent>{currentFile && <FileViewer file={currentFile} />}</StyledDivContent>
      {files?.length > 1 && (
        <FileSelectSidebar
          defaultCollapsed={width < MOBILE_WIDTH_BREAKPOINT}
          files={files}
          handleFileSelect={(file) => setCurrentFile(file)}
          selectedFile={currentFile}
          accessToken={accessToken}
          examId={id}
        />
      )}
    </CollapsibleSidebar>
  );
};

ExamRead.displayName = 'ExamRead';

const StyledDivContent = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  contain: strict;
`;

const StyledIcon = styled(SvgIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
