import { DestinationModel, IntegrationModel, TemplateModel } from 'models';

import { findOrThrow, trimForStorage } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  name: '',
  description: '',
  host: '',
  port: null,
  active: true,
  sourceDirectory: '',
  processingDirectory: '',
  archiveDirectory: '',
  errorDirectory: '',
  userName: '',
  password: '',
  apiKey: '',
  email: '',
  title: '',
  connectionString: '',
  queueName: '',
  template: null,
  integration: null,
};

function copyModelToForm(
  model: DestinationModel,
  allTemplates: TemplateModel[],
  allIntegrations: IntegrationModel[],
): EditFormValues {
  const template =
    model.template_Id != null
      ? findOrThrow(
          allTemplates,
          (t) => t.id === model.template_Id,
          `Could not find template with id: ${model.template_Id}.`,
        )
      : null;

  const integration =
    model.integration_Id != null
      ? findOrThrow(
          allIntegrations,
          (i) => i.id === model.integration_Id,
          `Could not find integration with id: ${model.integration_Id}.`,
        )
      : null;

  return {
    name: model.name,
    description: model.description ?? '',
    host: model.host ?? '',
    port: model.port,
    active: model.active,
    template,
    integration,
    sourceDirectory: model.sourceDirectory ?? '',
    processingDirectory: model.processingDirectory ?? '',
    archiveDirectory: model.archiveDirectory ?? '',
    errorDirectory: model.errorDirectory ?? '',
    userName: model.userName ?? '',
    password: model.password ?? '',
    apiKey: model.apiKey ?? '',
    email: model.email ?? '',
    title: model.title ?? '',
    connectionString: model.connectionString ?? '',
    queueName: model.queueName ?? '',
  };
}

function copyFormToModel(
  destinationId: number,
  form: EditFormValues,
): DestinationModel {
  return {
    id: destinationId,
    integration_Id: form.integration == null ? null : form.integration.id,
    template_Id: form.template == null ? null : form.template.id,
    name: form.name.trim(),
    description: trimForStorage(form.description),
    host: trimForStorage(form.host),
    port:
      typeof form.port === 'number' && Number.isNaN(form.port)
        ? null
        : form.port,
    active: form.active,
    sourceDirectory: trimForStorage(form.sourceDirectory),
    processingDirectory: trimForStorage(form.processingDirectory),
    archiveDirectory: trimForStorage(form.archiveDirectory),
    errorDirectory: trimForStorage(form.errorDirectory),
    userName: trimForStorage(form.userName),
    password: trimForStorage(form.password, 'password'),
    apiKey: trimForStorage(form.apiKey, 'password'),
    email: trimForStorage(form.email),
    title: trimForStorage(form.title),
    connectionString: trimForStorage(form.connectionString),
    queueName: trimForStorage(form.queueName),
  };
}

function createDefaultDestination(): DestinationModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const DestinationEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultDestination,
};
