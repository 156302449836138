import {
  DataResult,
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { normalize } from 'normalizr';

import { GroupModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { GroupRouteService } from '../services';
import { NormalizedGroupModelSchema, groupEntity } from './normalizr-schema';

const getAll = createAsyncThunk(
  'groups/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(GroupRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<GroupModel>;

    const normalized = normalize<GroupModel, NormalizedGroupModelSchema>(
      result,
      [groupEntity],
    );

    return normalized.entities;
  },
);

const add = createAsyncThunk(
  'groups/add',
  async (group: GroupModel, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.post({
        url: GroupRouteService.getBaseRoute(),
        body: group,
      }),
    )) as unknown as ApiResponse<GroupModel>;

    const normalized = normalize<GroupModel, NormalizedGroupModelSchema>(
      { ...group, id: result.id },
      groupEntity,
    );

    return normalized.entities;
  },
);

const edit = createAsyncThunk(
  'groups/edit',
  async (group: GroupModel, { dispatch }) => {
    await dispatch(
      ApiActions.put({
        url: GroupRouteService.getBaseRoute(),
        body: group,
      }),
    );

    const normalized = normalize<GroupModel, NormalizedGroupModelSchema>(
      group,
      groupEntity,
    );

    return normalized.entities;
  },
);

const getById = createAsyncThunk(
  'groups/get-by-id',
  async (groupId: number, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(GroupRouteService.getByIdRoute(groupId)),
    )) as unknown as ApiResponse<GroupModel>;

    return result;
  },
);

const getAllForKendoGrid = createAsyncThunk(
  'groups-grid/get-all-for-kendo-grid',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = GroupRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<DataResult>;

    const normalized = normalize(result.data, [groupEntity]);

    return { ...normalized, total: result.total };
  },
);

// this action should not save to store because of
// possible performance (memory) issues
const getAllForExport = createAsyncThunk(
  'groups/get-all-for-export',
  async (dataState: DataSourceRequestState, { dispatch }) => {
    const queryStr = toDataSourceRequestString(dataState);
    const baseUrl = GroupRouteService.getForGridRoute();
    const url = `${baseUrl}?${queryStr}`;

    const { result } = (await dispatch(
      ApiActions.get(url),
    )) as unknown as ApiResponse<GroupModel>;

    return result;
  },
);

export const GroupActions = {
  add,
  edit,
  getAll,
  getAllForExport,
  getAllForKendoGrid,
  getById,
};
