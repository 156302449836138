import { ResultSetModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class ResultSetClient {
  constructor(private httpClient: HttpClient) {
    this.getAllResultSets = this.getAllResultSets.bind(this);
  }

  public async getAllResultSets() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchAndParse<
      DataResult<ResultSetModel>
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/result-sets`,
        options,
      ),
    );
    return result.data;
  }
}
