import { FunctionComponent } from 'react';

import { faArrowsSpin, faCheck, faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CustomIconProps } from './CustomIconProps';
// import ErrorIconSvg from './assets/error-icon.svg';
// import AddNewIconSvg from './assets/plus-icon.svg';
// import ProcessingIconSvg from './assets/processing-icon.svg';
// import SuccessIconSvg from './assets/success-icon.svg';
import { TagTypes } from './constants';

export const CustomIcon: FunctionComponent<CustomIconProps> = ({ type, icon: Icon }) => {
  switch (type) {
    case TagTypes.ERROR:
    case TagTypes.WARNING:
      return <FontAwesomeIcon icon={faCircleXmark} />;
    case TagTypes.SUCCESS:
      return <FontAwesomeIcon icon={faCheck} />;
    case TagTypes.INFO:
      return <FontAwesomeIcon icon={faArrowsSpin} />;
    case TagTypes.ADD_NEW:
      return <FontAwesomeIcon icon={faCircleCheck} />;
    default:
      return Icon ? <Icon /> : null;
  }
};

CustomIcon.displayName = 'CustomIcon';
