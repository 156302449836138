import { memo, useMemo } from 'react';

import { useSessionLocation } from 'features/location';
import { useUserSettings } from 'features/settings';

import { AuthenticationScheme, StandardClaimNames, StandardScopes } from '../constants';
import { RouteGuardContext } from '../contexts';
import { useAuthentication, useAuthorizationClaims, useCurrentUser } from '../hooks';
import { Claim, RouteGuardContextType, RouteGuardErrorCode, RouteGuardProps } from '../types';
import { DefaultAuthorizationChallenge } from './DefaultAuthorizationChallenge';

export const RouteGuard = memo<RouteGuardProps>(
  ({
    allowedRoles,
    allowedSchemes = AuthenticationScheme.OIDC,
    challengeComponent: ChallengeComponent = DefaultAuthorizationChallenge,
    validateShareLinkId = false,
    children,
    requireSystemAdmin = false,
    requireSessionLocation = false,
  }) => {
    // Convert the allowed* properties to arrays to make logic later on simpler.
    const allowedRolesArray = useMemo(() => (allowedRoles == null ? [] : Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles]), [allowedRoles]);
    const allowedSchemesArray = useMemo(() => (Array.isArray(allowedSchemes) ? allowedSchemes : [allowedSchemes]), [allowedSchemes]);

    // Need to do a little input guarding to prevent nonsensical configurations.
    if (allowedRolesArray.length > 0 && (allowedSchemesArray.length !== 1 || !allowedSchemesArray.includes(AuthenticationScheme.OIDC))) {
      // Require that OIDC is also specified when roles are specified.  This is because the roles are only available when the user is authenticated with OIDC.  Not performing this check immediately will complicate the logic later on.
      throw new Error(
        'Authorization configuration error.  Specifying a role in the allowedRoles property requires that only the OIDC authentication scheme is allowed.',
      );
    } else if (requireSystemAdmin && (allowedSchemesArray.length !== 1 || !allowedSchemesArray.includes(AuthenticationScheme.OIDC))) {
      // Require that OIDC is also specified when system admin is required.  This is because the system admin flag is only available when the user is authenticated with OIDC.  Not performing this check immediately will complicate the logic later on.
      throw new Error('Authorization configuration error.  Requiring a system admin requires that only the OIDC authentication scheme is allowed.');
    }

    const { currentUser } = useCurrentUser();
    const { activeScheme } = useAuthentication();
    const claims = useAuthorizationClaims();
    const { userSettings } = useUserSettings(false);

    const { sessionLocation, sessionLocationOptions } = useSessionLocation();

    // Core route guard evaluation logic.  Returning an empty object indicates that the authentication system is still initializing.
    const { authState, errorCodes } = useMemo((): { authState?: 'success' | 'fail' | undefined; errorCodes?: string[] | undefined } => {
      // Perform some basic sanity checks.  If these fail, then that most likely indicates that there is a bug higher up in the stack.
      if (activeScheme === AuthenticationScheme.OIDC && currentUser === 'anonymous') {
        throw new Error(
          'Authorization system failed because the current user is "anonymous" but the authentication scheme is OIDC which by definition requires a user to exist.  This likely indicates a bug higher up in the stack.',
        );
      }

      // Authentication is still initializing.
      if (activeScheme == null || userSettings == null || (requireSessionLocation && sessionLocationOptions == null)) return {};

      // Enforce allowed authentication schemes.
      if (allowedSchemesArray.length > 0 && !allowedSchemesArray.includes(activeScheme)) {
        return { authState: 'fail', errorCodes: [RouteGuardErrorCode.SchemeNotAllowed] };
      }

      // Enforce allowed roles.
      if (allowedRolesArray.length > 0 && currentUser != null && (currentUser === 'anonymous' || !allowedRolesArray.includes(currentUser.role!))) {
        return { authState: 'fail', errorCodes: [RouteGuardErrorCode.MissingRequiredRole] };
      }

      // Enforce system admin requirement.
      if (requireSystemAdmin && currentUser != null && (currentUser === 'anonymous' || !currentUser.isSystemAdmin)) {
        return { authState: 'fail', errorCodes: [RouteGuardErrorCode.RequiresSystemAdmin] };
      }

      // Enforce share link ID validation.
      if (validateShareLinkId && activeScheme === AuthenticationScheme.SHARE) {
        const scopeClaim = claims?.find((c) => c.name === StandardClaimNames.SCOPE) as Claim<string[]> | undefined;

        if (!scopeClaim?.value?.some((s) => s === StandardScopes.SHARE_LINK_ID_MATCH)) {
          return { authState: 'fail', errorCodes: [RouteGuardErrorCode.ShareLinkIdMismatch] };
        }
      }

      // Enforce session location requirement.
      if (requireSessionLocation && sessionLocation == null && sessionLocationOptions != null) {
        return { authState: 'fail', errorCodes: [RouteGuardErrorCode.RequiresSessionLocation] };
      }

      return { authState: 'success', errorCodes: [] };
    }, [
      activeScheme,
      currentUser,
      userSettings,
      allowedSchemesArray,
      allowedRolesArray,
      requireSystemAdmin,
      validateShareLinkId,
      requireSessionLocation,
      sessionLocation,
      sessionLocationOptions,
      claims,
    ]);

    const newContext: RouteGuardContextType = useMemo(
      () => ({
        isContextPresent: true,
        allowedRoles: allowedRolesArray,
        allowedSchemes: allowedSchemesArray,
        errorCodes: errorCodes ?? [],
        requireSessionLocation,
      }),
      [allowedRolesArray, allowedSchemesArray, errorCodes, requireSessionLocation],
    );

    if (authState == null) return null;
    else if (authState === 'fail') {
      return (
        <RouteGuardContext.Provider value={newContext}>
          <ChallengeComponent />
        </RouteGuardContext.Provider>
      );
    } else return <RouteGuardContext.Provider value={newContext}>{children}</RouteGuardContext.Provider>;
  },
);

RouteGuard.displayName = 'RouteGuard';
