import { FunctionComponent, useEffect, useState } from 'react';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { hasText } from 'core/utils';

import { XMLViewerProps } from '../types/XMLViewerProps';

SyntaxHighlighter.registerLanguage('xml', xml);

export const XMLViewer: FunctionComponent<XMLViewerProps> = ({ url }) => {
  const [xmlContent, setXmlContent] = useState('');

  useEffect(() => {
    fetch(url).then((response) => {
      response.text().then((text) => {
        setXmlContent(text);
      });
    });
  }, [url]);

  if (!hasText(xmlContent)) {
    return null;
  }

  return (
    <SyntaxHighlighter language="xml" style={atomOneLight}>
      {xmlContent}
    </SyntaxHighlighter>
  );
};

XMLViewer.displayName = 'XMLViewer';
