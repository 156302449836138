import { MouseEvent, memo, useId, useMemo, useRef, useState } from 'react';

import { CompositeFilterDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { Identifier } from 'dnd-core';
import { useAccordionButton } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { WorklistViewModel } from 'models';

import { useEvent } from 'core/hooks';
import { Accordion, Button, ButtonVariants, Filter } from 'core/ui';

import { useColumnsGridState } from 'features/exam/hooks';
import { ExamsGridService } from 'features/exam/services/exam-grid-service';

import CollapseIcon from '../assets/collapse.svg?react';
import ExpandIcon from '../assets/expand.svg?react';
import HandleIcon from '../assets/handle.svg?react';
import { WorklistEditService } from '../services';
import { WorklistFilterAccordionItemProps } from '../types';
import { WorklistColumnSelection } from './WorklistColumnSelection';
import { WorklistNameInput } from './WorklistNameInput';
import { WorklistSortSelection } from './WorklistSortSelection';

const ALL_COLUMN_DEFINITIONS = ExamsGridService.getColumns();
const ALL_GRID_COLUMN_DEFINITIONS = ExamsGridService.getGridColumns();
const ALL_FIELDS = ALL_COLUMN_DEFINITIONS.filter((column) => column.filter).map((column) => ({
  filter: column.filter,
  label: column.title,
  name: column.field,
  operators: column.operators ?? [],
}));

export const WorklistFilterAccordionItem = memo<WorklistFilterAccordionItemProps>(
  ({ worklist, allWorklists, isTopLevel = false, currentDataState, currentColumnsState, onCreateWorklist, onDeleteWorklist, onUpdateWorklist }) => {
    const dndKey = useId();

    const ref = useRef<HTMLDivElement | null>(null);

    const [worklistName, setWorklistName] = useState(worklist.name);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
    const [isItemsExpanded, setIsItemsExpanded] = useState(false);
    const [filterForm, setFilterForm] = useState(() => WorklistEditService.initializeWorklistEditModalDataState(worklist.dataState));
    const { columnsState: columnsStateForm, setColumnsState: setColumnsStateForm } = useColumnsGridState(worklist.columnState);
    const [sortForm, setSortForm] = useState(() => WorklistEditService.initializeWorklistEditModalSort(worklist.dataState));

    const childWorklists = useMemo(() => allWorklists.filter((w) => w.parentViewId === worklist.id), [allWorklists, worklist]);

    const [, drop] = useDrop<WorklistViewModel, unknown, { handlerId: Identifier | null }>({
      accept: dndKey,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop(item: WorklistViewModel) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.ordinal ?? 0;
        const hoverIndex = worklist.ordinal ?? 0;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        onUpdateWorklist([
          { ...worklist, ordinal: dragIndex },
          { ...item, ordinal: hoverIndex },
        ]);
      },
    });

    const [, drag] = useDrag({
      type: dndKey,
      item: () => worklist,
    });

    const toggleFiltersAccordionClick = useAccordionButton(`${worklist.id}-filters`, () => setIsFiltersExpanded(!isFiltersExpanded));

    const toggleItemsAccordionClick = useAccordionButton(`${worklist.id}-items`, () => setIsItemsExpanded(!isItemsExpanded));

    const handleFilterChange = useEvent((event: FilterChangeEvent) => {
      const newFilterForm: CompositeFilterDescriptor = {
        ...filterForm,
        ...event.filter,
      };

      setFilterForm(newFilterForm);
    });

    const handleSaveClick = useEvent((event: MouseEvent<HTMLButtonElement>) => {
      if (sortForm.field == null) throw new Error('Sort field is required.');
      if (sortForm.dir == null) throw new Error('Sort direction is required.');

      toggleFiltersAccordionClick(event);

      const newDataState: State = {
        filter: filterForm,
        sort: [{ field: sortForm.field, dir: sortForm.dir }],
      };

      onUpdateWorklist([
        {
          ...worklist,
          name: worklistName,
          dataState: JSON.stringify(newDataState),
          columnState: JSON.stringify(columnsStateForm),
          oDataResourceString: toDataSourceRequestString(newDataState),
        },
      ]);
    });

    const handleSelfCreateWorklist = useEvent((name: string) => {
      // This callback is used to create a new worklist under the current worklist.  So
      onCreateWorklist(name, isTopLevel ? null : worklist.id);
    });

    const handleDeleteWorklistClick = useEvent(() => {
      onDeleteWorklist(worklist);
    });

    drag(drop(ref));

    return (
      <div ref={ref}>
        <StyledHeaderWrapperDiv $isExpanded={isItemsExpanded || isFiltersExpanded} $isTopLevel={isTopLevel}>
          {isTopLevel &&
            (isItemsExpanded ? <StyledCollapseIcon onClick={toggleItemsAccordionClick} /> : <StyledExpandIcon onClick={toggleItemsAccordionClick} />)}
          {isFiltersExpanded ? (
            <StyledEditWorklistHeaderDiv>
              <StyledEditNameWorklistInput initialValue={worklistName} saveButtonText="Save Name" value={worklistName} onChange={setWorklistName} />
              <StyledSaveWorklistButton type="button" onClick={handleSaveClick}>
                Save
              </StyledSaveWorklistButton>
            </StyledEditWorklistHeaderDiv>
          ) : (
            <StyledHeaderDiv>
              <div>{worklist.name}</div>
              <div>
                <>
                  <StyledEditButton onClick={toggleFiltersAccordionClick} variant={ButtonVariants.SECONDARY}>
                    Edit
                  </StyledEditButton>
                  <StyledDeleteButton onClick={handleDeleteWorklistClick} variant={ButtonVariants.SECONDARY}>
                    Delete
                  </StyledDeleteButton>
                </>
              </div>
            </StyledHeaderDiv>
          )}
          <StyledHandleIcon />
        </StyledHeaderWrapperDiv>
        <StyledFiltersAccordionCollapse eventKey={`${worklist.id}-filters`}>
          <>
            <StyledLabelDiv>FILTERS</StyledLabelDiv>
            <StyledFilter fields={ALL_FIELDS} value={filterForm} onChange={handleFilterChange} />

            <StyledColumnWrapperDiv>
              <WorklistColumnSelection columns={ALL_GRID_COLUMN_DEFINITIONS} columnsState={columnsStateForm} onChange={setColumnsStateForm} />
              <StyledWorklistSortSelection columns={ALL_GRID_COLUMN_DEFINITIONS} onChange={setSortForm} field={sortForm.field} dir={sortForm.dir} />
            </StyledColumnWrapperDiv>
          </>
        </StyledFiltersAccordionCollapse>
        <StyledItemsAccordionCollapse eventKey={`${worklist.id}-items`}>
          <>
            {childWorklists.map((childItem) => (
              <WorklistFilterAccordionItem
                key={childItem.id}
                allWorklists={allWorklists}
                worklist={childItem}
                currentDataState={currentDataState}
                currentColumnsState={currentColumnsState}
                onCreateWorklist={onCreateWorklist}
                onDeleteWorklist={onDeleteWorklist}
                onUpdateWorklist={onUpdateWorklist}
              />
            ))}
            <StyledCreateWorklistNameInput onSave={handleSelfCreateWorklist} saveButtonText="Create Worklist" />
          </>
        </StyledItemsAccordionCollapse>
      </div>
    );
  },
);

WorklistFilterAccordionItem.displayName = 'WorklistFilterAccordionItem';

const StyledHeaderWrapperDiv = styled.div<{
  $isExpanded: boolean;
  $isTopLevel: boolean;
}>`
  align-items: center;
  display: flex;
  margin-left: ${({ $isTopLevel, theme }) => ($isTopLevel ? 0 : theme.space.spacing60)};
  margin-bottom: ${({ $isExpanded, theme }) => ($isExpanded ? 0 : theme.space.spacing30)};
`;

const StyledCollapseIcon = styled(CollapseIcon)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.spacing30};
`;

const StyledExpandIcon = styled(ExpandIcon)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.spacing30};
`;

const StyledEditWorklistHeaderDiv = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.palette.grayscale[2]};
  display: flex;
  width: 100%;
  padding-top: ${({ theme }) => theme.space.spacing20};
  padding-left: ${({ theme }) => theme.space.spacing20};
`;

const StyledEditNameWorklistInput = styled(WorklistNameInput)`
  & > input {
    background: white;
  }
`;

const StyledSaveWorklistButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing50};
  margin-right: ${({ theme }) => theme.space.spacing20};
`;

const StyledHeaderDiv = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.palette.grayscale[2]};
  border-radius: ${({ theme }) => theme.radii.base};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.spacing30} ${({ theme }) => theme.space.spacing40};
  width: 100%;
`;

const StyledEditButton = styled(Button)`
  &,
  &&:focus {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    color: ${({ theme }) => theme.colors.palette.grayscale[9]};
    margin-left: ${({ theme }) => theme.space.spacing20};
  }

  &&:hover {
    background-color: white;
    border-color: ${({ theme }) => theme.colors.palette.grayscale[4]};
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    color: ${({ theme }) => theme.colors.palette.aquas[5]};
  }
`;

const StyledDeleteButton = styled(StyledEditButton)`
  &,
  &&:focus {
    border: 1px solid ${({ theme }) => theme.colors.palette.reds[4]};
    color: ${({ theme }) => theme.colors.palette.reds[4]};
  }

  &&:hover {
    border-color: ${({ theme }) => theme.colors.palette.reds[4]};
    color: ${({ theme }) => theme.colors.palette.reds[4]};
  }
`;

const StyledHandleIcon = styled(HandleIcon)`
  cursor: move;
  margin-left: ${({ theme }) => theme.space.spacing40};
`;

const StyledFiltersAccordionCollapse = styled(Accordion.Collapse)`
  background: ${({ theme }) => theme.colors.palette.grayscale[2]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  margin-bottom: ${({ theme }) => theme.space.spacing30};
  margin-right: 27px;
  margin-left: ${({ theme }) => theme.space.spacing60};
`;

const StyledLabelDiv = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
  margin-top: ${({ theme }) => theme.space.spacing20};
`;

const StyledFilter = styled(Filter)`
  & .k-toolbar {
    background: white;
  }
`;

const StyledColumnWrapperDiv = styled.div`
  display: flex;
`;

const StyledWorklistSortSelection = styled(WorklistSortSelection)`
  margin-left: ${({ theme }) => theme.space.spacing70};
`;

const StyledItemsAccordionCollapse = styled(Accordion.Collapse)`
  border-left: 1px solid ${({ theme }) => theme.colors.palette.grayscale[5]};
  margin: 0 0 0 6px;
  padding-top: ${({ theme }) => theme.space.spacing40};
  padding-bottom: ${({ theme }) => theme.space.spacing40};
  padding-right: 0;
`;

const StyledCreateWorklistNameInput = styled(WorklistNameInput)`
  margin-left: ${({ theme }) => theme.space.spacing60};
  margin-right: 27px;
`;
