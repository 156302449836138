import { useCallback, useEffect, useState } from 'react';

import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Field } from 'core/forms';
import { Accordion, Button, Icon, Label } from 'core/ui';
import { DelimitedTextAutoComplete } from 'core/ui/DelimitedText';
import { hasText } from 'core/utils';

import { apiClient } from 'features/api';

import WriteIcon from '../assets/write.svg?react';

const PreField = ({ value }) => {
  return <StyledPre>{value}</StyledPre>;
};

const StyledPre = styled.pre`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`;

PreField.propTypes = {
  value: PropTypes.string,
};

PreField.defaultProps = {
  value: undefined,
};

function copyResultSets(resultSets) {
  return resultSets.map((rs) => ({
    id: rs.id,
    description: `${rs.acronym}${
      rs.description.trim() !== '' ? ` | ${rs.description}` : ''
    }`,
    value: rs.description,
  }));
}

export const EKGResultsAccordion = ({ eventKey, valueGetter, valueSetter }) => {
  const [overReadTemplates, setOverReadTemplates] = useState([]);

  const handleCopyClick = useCallback(() => {
    const interpretation = valueGetter('interpretation');
    const overread = valueGetter('overRead');

    valueSetter('overRead', {
      value: `${hasText(overread) ? `${overread}\r\n` : ''}${
        hasText(interpretation) ? interpretation : ''
      }`,
    });
  }, [valueGetter, valueSetter]);

  useEffect(() => {
    (async () => {
      const newResultSets = await apiClient.resultSetClient.getAllResultSets();
      setOverReadTemplates(copyResultSets(newResultSets));
    })();
  }, []);

  return (
    <Accordion.Item eventKey={eventKey}>
      <button
        type="submit"
        disabled
        style={{ display: 'none' }}
        aria-hidden="true"
      />
      <Accordion.Header Icon={WriteIcon} title="Results" />
      <Accordion.Body>
        <StyledInterpretationLabel>
          Interpretation
          <StyledCopyButton
            type="button"
            title="Copy to Over-read"
            onClick={handleCopyClick}
          >
            <StyledCopyIcon icon={faCopy} />
          </StyledCopyButton>
        </StyledInterpretationLabel>
        <Field name="interpretation" component={PreField} readOnly />
        <Field
          name="overRead"
          component={DelimitedTextAutoComplete}
          label="Over-read"
          autoCompleteData={overReadTemplates}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

EKGResultsAccordion.displayName = 'EKGResultsAccordion';

const StyledInterpretationLabel = styled(Label)`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

const StyledCopyButton = styled(Button)`
  margin-left: 16px;
  padding: 3px 12px;
`;

const StyledCopyIcon = styled(Icon)`
  &&& {
    margin-right: 0;
  }
`;

EKGResultsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
};
