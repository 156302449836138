import { FunctionComponent, useCallback, useMemo } from 'react';

import { DropdownWithValuesField } from 'core/ui/Dropdown';
import { ComponentSizes } from 'core/ui/constants';

import { DropdownFilterCellProps } from './DropdownFilterCellProps';

export const DropdownFilterCell: FunctionComponent<DropdownFilterCellProps> = ({
  dropdownData,
  defaultItem,
  onChange,
  textField = 'name',
  dataItemKey = 'value',
  valueField = 'value',
}) => {
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      onChange({
        syntheticEvent: event,
        operator: event.value == null ? '' : 'eq',
        value: event.value ?? '',
      });
    },
    [onChange],
  );

  const content = useMemo(
    () => (
      <div className="k-filtercell">
        <DropdownWithValuesField
          isForPrimitiveValues
          data={dropdownData}
          textField={textField}
          dataItemKey={dataItemKey}
          valueField={valueField}
          onChange={handleChange}
          defaultItem={defaultItem}
          size={ComponentSizes.SMALL}
          valid
          filterable={false}
        />
      </div>
    ),
    [dataItemKey, defaultItem, dropdownData, handleChange, textField, valueField],
  );

  return content;
};

DropdownFilterCell.displayName = 'DropdownFilterCell';
