import { ServiceModel } from 'models';

import { findOrThrow } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  description: '',
  notes: '',
  shortCode: '',
  longCode: '',
  cpt: '',
  serviceSubType: null,
  active: false,
};

function copyModelToForm(model: ServiceModel, allSubServices: ServiceModel[]): EditFormValues {
  // find Sub Service/ Parent
  const subServiceType =
    model.serviceSubTypeID != null && model.serviceSubTypeID > 0
      ? findOrThrow(allSubServices, (d) => d.id === model.serviceSubTypeID, `Could not find Service with id: ${model.serviceSubTypeID}.`)
      : null;
  return {
    ...model,
    serviceSubType: subServiceType,
  };
}

function copyFormToModel(serviceId: number, form: EditFormValues): ServiceModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...rest } = form;
  return {
    ...rest,
    id: serviceId,
    serviceSubTypeID: form.serviceSubType == null ? null : form.serviceSubType.id,
    children: [],
  };
}

function createDefaultSource(): ServiceModel {
  return copyFormToModel(0, EditFormDefaults);
}

export const ServiceEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultSource,
};
