import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { useOutsideClick } from '../../hooks';
import { Icon } from '../Icon';
import { DialogProps } from './DialogProps';
import CloseIcon from './assets/close.svg?react';

export const Dialog: FunctionComponent<DialogProps> = ({
  children,
  className,
  closeOnOutsideClick = false,
  headerActionContent,
  onClose,
  title,
  titleIcon,
}) => {
  const modalRef = useOutsideClick<HTMLDivElement>(() => {
    if (closeOnOutsideClick && onClose) {
      onClose();
    }
  });

  return (
    <StyledDivModalWrapper className={className} ref={modalRef}>
      <StyledDivHeader>
        <StyledDivTitleWrapper>
          {titleIcon && <StyledTitleIcon icon={titleIcon} size="lg" />}
          <StyledDivTitle>{title}</StyledDivTitle>
        </StyledDivTitleWrapper>
        <StyledDivActionsWrapper>
          {headerActionContent}
          {onClose && <StyledCloseIcon onClick={onClose} />}
        </StyledDivActionsWrapper>
      </StyledDivHeader>
      {children}
    </StyledDivModalWrapper>
  );
};

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 35px;
`;

const StyledDivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.spacing40};
`;

const StyledDivTitleWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDivActionsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDivTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const StyledTitleIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledDivModalWrapper = styled.div`
  background: white;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  left: 50%;
  padding: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;
