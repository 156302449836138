import { ExamTypes } from '../constants';

const isPathology = (examType) => ExamTypes.PATHOLOGY.includes(examType);
const isXA = (examType) => ExamTypes.XA.includes(examType);
const isUS = (examType) => ExamTypes.US.includes(examType);
const isUSSR = (examType) => ExamTypes.USSR.includes(examType);
const isSR = (examType) => ExamTypes.SR.includes(examType);
const isEcho = (examType) => ExamTypes.ECHO.includes(examType);

export const ExamTypeService = {
  isPathology,
  isXA,
  isUS,
  isUSSR,
  isSR,
  isEcho,
};
