import { FunctionComponent, useMemo, useState } from 'react';

import { ExamStatusTypeModel } from 'models';

import { useEvent } from 'core/hooks';

import { apiClient } from 'features/api';

import { ExamStatusCacheContext } from '../contexts';
import { ExamStatusCacheProviderProps } from '../types';

export const ExamStatusCacheProvider: FunctionComponent<
  ExamStatusCacheProviderProps
> = ({ children }) => {
  const [isFetchCalled, setIsFetchCalled] = useState(false);
  const [statuses, setStatuses] = useState<ExamStatusTypeModel[]>([]);

  const triggerFetch = useEvent(async () => {
    if (isFetchCalled) return; // Don't do anything if we already have initiated a fetch.

    setIsFetchCalled(true);

    const newStatuses = await apiClient.examStatusClient.getExamStatusTypes();
    setStatuses(newStatuses);
  });

  const context = useMemo(
    () => ({
      statuses,
      triggerFetch,
    }),
    [statuses, triggerFetch],
  );

  return (
    <ExamStatusCacheContext.Provider value={context}>
      {children}
    </ExamStatusCacheContext.Provider>
  );
};

ExamStatusCacheProvider.displayName = 'ExamStatusCacheProvider';
