import { ChangeEvent, KeyboardEvent, memo } from 'react';

import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, ButtonLooks, ButtonVariants, Input } from 'core/ui';

import SearchIcon from '../assets/search.svg?react';
import { SearchInputProps } from '../types';

export const SearchInput = memo<SearchInputProps>(({ placeholder, value, onChange, onSubmit }) => {
  const handleSearchChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  });

  const handleKeyDown = useEvent((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit?.();
    }
  });

  return (
    <StyledSearchWrapperDiv>
      <StyledInput name="search" onChange={handleSearchChange} onKeyDown={handleKeyDown} placeholder={placeholder} value={value} valid />
      <StyledSearchButton type="button" look={ButtonLooks.WHITE} onClick={onSubmit} variant={ButtonVariants.SECONDARY}>
        <SearchIcon />
      </StyledSearchButton>
    </StyledSearchWrapperDiv>
  );
});

SearchInput.displayName = 'SearchInput';

const StyledSearchWrapperDiv = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.space.spacing20};
  flex: 0 0 auto;
`;

const StyledInput = styled(Input)`
  border-bottom-right-radius: 0;
  border-right: transparent;
  border-top-right-radius: 0;
  width: 300px;
`;

const StyledSearchButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  border-left: transparent;
`;
