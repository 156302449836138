import { createEntityAdapter } from '@reduxjs/toolkit';

const rootSelector = (state) => state.items;

const { selectById, selectAll: getAll } =
  createEntityAdapter().getSelectors(rootSelector);

const getStatus = (state) => rootSelector(state).status;

const getById = (id) => (state) => selectById(state, id);

const getAllActiveItems = (forceIncludeId, forceExcludeId) => (state) =>
  getAll(state).filter(
    (a) => a.id !== forceExcludeId && (a.active || a.id === forceIncludeId),
  );

const getTotal = (state) => rootSelector(state).total;

export const ItemSelectors = {
  getAll,
  getById,
  getTotal,
  getStatus,
  getAllActiveItems,
};
