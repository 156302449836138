import { FunctionComponent } from 'react';

import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import styled from 'styled-components';

import { CollapseProps } from './CollapseProps';

export const Collapse: FunctionComponent<CollapseProps> = ({
  children,
  className,
  eventKey,
}) => {
  return (
    <StyledAccordionCollapse className={className} eventKey={eventKey}>
      {children}
    </StyledAccordionCollapse>
  );
};

Collapse.displayName = 'Accordion.Collapse';

const StyledAccordionCollapse = styled(BootstrapAccordion.Collapse)`
  padding: ${({ theme }) => theme.space.spacing10}
    ${({ theme }) => theme.space.spacing40};
`;
