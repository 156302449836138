import { AuthStatus } from '../constants';

const root = (state) => state.auth;

const status = (state) => root(state).status;

const isAuthenticated = (state) => status(state) === AuthStatus.AUTHENTICATED;

const user = (state) => root(state).user;
const userRole = (state) => root(state).userRole;
const isPricingAdmin = (state) => root(state).isPricingAdmin;
const isSystemAdmin = (state) => root(state).isSystemAdmin;
const isAdministrator = (state) => root(state).userRole === 'Administrator';

export const AuthSelectors = {
  status,
  isAuthenticated,
  user,
  userRole,
  isPricingAdmin,
  isSystemAdmin,
  isAdministrator,
};
