import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SettingOverrideModel, UserModel } from 'models';

import { useTabLocationPersistence } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, TabContainer, TabContent, TabNav, TabNavContainer, TabNavItem, TabNavLink, TabPane } from 'core/ui';

import { AuthActions, useCurrentUser } from 'features/auth';
import { BrandingActions } from 'features/branding';
import { GroupActions } from 'features/group';
import { useAppDispatch, useAppSelector } from 'features/main/hooks';

import { InitialValues } from '../constants';
import { UserActions, UserSelectors } from '../redux';
import { ContactTabFormValues, GeneralTabFormValues, IntegrationTabFormValues, PermissionsTabFormValues } from '../types';
import { ContactTabContent } from './ContactTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { IntegrationTabContent } from './IntegrationTabContent';
import { PermissionsTabContent } from './PermissionsTabContent';
import { SettingsTabContent } from './SettingsTabContent';

const allTabKeys = ['general', 'contact', 'integration', 'permissions', 'settings'];

function copyFormsToModel(
  initialState: Partial<UserModel> | null | undefined,
  generalForm: GeneralTabFormValues,
  contactForm: ContactTabFormValues,
  integrationForm: IntegrationTabFormValues,
  permissionsForm: PermissionsTabFormValues,
  settingOverrides: SettingOverrideModel[],
): UserModel {
  if (initialState == null) {
    return {
      ...InitialValues.nonFormFields,
      ...contactForm,
      ...generalForm,
      ...integrationForm,
      ...permissionsForm,
      settingOverrides,
    };
  }

  return {
    ...InitialValues.nonFormFields,
    ...initialState,
    ...contactForm,
    ...generalForm,
    ...integrationForm,
    ...permissionsForm,
    settingOverrides,
  };
}

function copyModelToForms(user: UserModel): {
  generalForm: GeneralTabFormValues;
  contactForm: ContactTabFormValues;
  integrationForm: IntegrationTabFormValues;
  permissionsForm: PermissionsTabFormValues;
  settingOverrides: SettingOverrideModel[];
} {
  return {
    generalForm: {
      firstName: user.firstName,
      lastName: user.lastName,
      branding: user.branding,
      groups: user.groups,
      isApproved: user.isApproved,
      active: user.active,
      verifyAccount: user.verifyAccount,
      isRegistered: user.isRegistered,
      isPricingAdmin: user.isPricingAdmin,
      isSystemAdmin: user.isSystemAdmin,
      setPassword: user.setPassword ?? '',
      preferMultiMonitor: user.preferMultiMonitor,
    },
    contactForm: {
      email: user.email ?? '',
      phone: user.phone ?? '',
    },
    integrationForm: {
      supportUrl: user.supportUrl ?? '',
      britUser: user.britUser ?? '',
    },
    permissionsForm: {
      role: user.role ?? '',
    },
    settingOverrides: user.settingOverrides,
  };
}

export const UserForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const [contactForm, setContactForm] = useState(InitialValues.contactForm);
  const [generalForm, setGeneralForm] = useState(InitialValues.generalForm);
  const [integrationForm, setIntegrationForm] = useState(InitialValues.integrationForm);
  const [permissionsForm, setPermissionsForm] = useState(InitialValues.permissionsForm);
  const [settingOverrides, setSettingOverrides] = useState<SettingOverrideModel[]>(InitialValues.settingOverrides);
  const [isFormReady, setIsFormReady] = useState(false);

  const id = useMemo(() => {
    const parsedId = idParam != null ? parseInt(idParam, 10) : Number.NaN;
    return Number.isNaN(parsedId) || idParam === undefined ? undefined : parsedId;
  }, [idParam]);

  const isFormInEditMode = id !== undefined;

  const user = useAppSelector(UserSelectors.getById(id));
  const { currentUser: me } = useCurrentUser(true);

  useEffect(() => {
    if (isFormInEditMode) {
      dispatch(UserActions.getById(id));
    }
  }, [dispatch, id, isFormInEditMode]);

  useEffect(() => {
    if (isFormInEditMode && user != null) {
      const newForms = copyModelToForms(user);

      setGeneralForm(newForms.generalForm);
      setContactForm(newForms.contactForm);
      setIntegrationForm(newForms.integrationForm);
      setPermissionsForm(newForms.permissionsForm);
      setSettingOverrides(newForms.settingOverrides);
      setIsFormReady(true);
    }
  }, [isFormInEditMode, user]);

  useEffect(() => {
    if (!isFormInEditMode) {
      setIsFormReady(true);
    }
  }, [isFormInEditMode]);

  useEffect(() => {
    dispatch(BrandingActions.getAll(undefined));
    dispatch(GroupActions.getAll(undefined));
  }, [dispatch]);

  const handleSubmit = useCallback(async () => {
    const newUser = copyFormsToModel(user, generalForm, contactForm, integrationForm, permissionsForm, settingOverrides);

    if (isFormInEditMode) {
      await dispatch(UserActions.edit(newUser));
      NotificationsService.displaySuccess(`User Saved ${newUser.lastName}, ${newUser.firstName}.`);
    } else {
      await dispatch(UserActions.add(newUser));
      NotificationsService.displaySuccess(`User Created ${newUser.lastName}, ${newUser.firstName}.`);
    }

    if (user != null && me.id === user.id) {
      dispatch(
        AuthActions.storeUserInfo({
          User: user,
          UserRole: user.role,
          IsPricingAdmin: user.isPricingAdmin,
          IsSystemAdmin: user.isSystemAdmin,
        }),
      );
    }

    navigate('/user');
  }, [user, generalForm, contactForm, integrationForm, permissionsForm, settingOverrides, isFormInEditMode, me.id, navigate, dispatch]);

  const { initialTabKey, onTabSelect } = useTabLocationPersistence(allTabKeys);

  if (!isFormReady) return <></>;

  return (
    <Page>
      <PageHeader title={isFormInEditMode ? 'Edit User' : 'New User'} />
      <StyledTabWrapper>
        <TabContainer id="user-tabs" onSelect={onTabSelect} defaultActiveKey={initialTabKey}>
          <TabNavContainer>
            <TabNav>
              <TabNavItem>
                <TabNavLink eventKey="general">General</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="contact">Contact Info</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="integration">Integration</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="permissions">Permissions</TabNavLink>
              </TabNavItem>
              <TabNavItem>
                <TabNavLink eventKey="settings">Settings</TabNavLink>
              </TabNavItem>
            </TabNav>
          </TabNavContainer>
          <TabContent>
            <TabPane eventKey="general">
              <GeneralTabContent initialValues={generalForm} onSubmit={handleSubmit} onChange={setGeneralForm} />
            </TabPane>
            <TabPane eventKey="contact">
              <ContactTabContent initialValues={contactForm} onSubmit={handleSubmit} onChange={setContactForm} />
            </TabPane>
            <TabPane eventKey="integration">
              <IntegrationTabContent initialValues={integrationForm} onSubmit={handleSubmit} onChange={setIntegrationForm} />
            </TabPane>
            <TabPane eventKey="permissions">
              <PermissionsTabContent initialValues={permissionsForm} onSubmit={handleSubmit} onChange={setPermissionsForm} />
            </TabPane>
            <TabPane eventKey="settings">
              <SettingsTabContent initialValues={settingOverrides} onChange={setSettingOverrides} onSubmit={handleSubmit} />
            </TabPane>
          </TabContent>
        </TabContainer>
      </StyledTabWrapper>
    </Page>
  );
};

UserForm.displayName = 'UserForm';

const StyledTabWrapper = styled.div`
  margin-top: 4px;
`;
