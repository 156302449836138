import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { DataResult, DataSourceRequestState, SortDescriptor } from '@progress/kendo-data-query';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import debounce from 'awesome-debounce-promise';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button, ButtonVariants, DataTable, DateCell, Dialog, HeaderCell, Page, PageHeader } from 'core/ui';

import { apiClient } from 'features/api';

import { DEFAULT_FILTER_DATA_STATE } from '../../patient/constants';

export const SendQueue: FunctionComponent = () => {
  const initialSort: Array<SortDescriptor> = [{ field: 'id', dir: 'desc' }];

  const [selected, setSelected] = useState({});
  const [dataState, setDataState] = useState<DataSourceRequestState>(DEFAULT_FILTER_DATA_STATE);
  const [sendQueues, setSendQueues] = useState({} as DataResult);
  const [queueDialogVisible, setQueueDialogVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const apiCall = useCallback((kendoState: DataSourceRequestState) => {
    return apiClient.sendQueue.getAllForKendoGrid(kendoState);
  }, []);

  const debouncedApiCall = useEvent(debounce(apiCall, 275));
  const gridContentRef = useRef<HTMLDivElement>(null);

  const fetchQueues = useCallback(
    async (showLoading = true) => {
      if (showLoading) {
        setIsLoading(true);
      }
      const response = await debouncedApiCall(dataState);
      setSendQueues(response);

      if (showLoading) {
        setIsLoading(false);
      }
    },
    [dataState, debouncedApiCall],
  );

  useEffect(() => {
    fetchQueues();
  }, [dataState, fetchQueues]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQueues(false);
    }, 15000);
    return () => clearInterval(interval);
  }, [fetchQueues]);

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const toggleDialog = () => {
    setQueueDialogVisible(!queueDialogVisible);
  };

  const handleRequeueClick = () => {
    toggleDialog();
  };

  const requeue = async () => {
    await apiClient.sendQueue.requeue(Object.keys(selected).map((key) => parseInt(key, 10)));
    await fetchQueues();
    setSelected({});
    toggleDialog();
  };

  return (
    <Page>
      <PageHeader title="Send Queue" />
      <StyledMainContainer>
        <StyledActionsBar>
          <Button onClick={handleRequeueClick} disabled={!Object.values(selected).some((value) => value === true)}>
            Re-Queue
          </Button>
        </StyledActionsBar>

        <div ref={gridContentRef}>
          <DataTable
            data={sendQueues?.data || []}
            sortable
            filterable
            onDataStateChange={dataStateChange}
            selectable
            pageable={{ pageSizes: true }}
            total={sendQueues?.total || 0}
            selectedState={selected}
            onSelectionChange={setSelected}
            sort={initialSort}
            isLoading={isLoading}
            {...dataState}
          >
            <GridColumn field="id" title="ID" headerCell={HeaderCell} filter="numeric" width="150px" />
            <GridColumn field="examId" title="Exam ID" filter="numeric" headerCell={HeaderCell} width="150px" />
            <GridColumn field="status" title="Status" headerCell={HeaderCell} width="150px" />
            <GridColumn field="response" title="Response" headerCell={HeaderCell} width="150px" />
            <GridColumn field="message" title="Message" headerCell={HeaderCell} width="150px" />
            <GridColumn field="type" title="Type" headerCell={HeaderCell} width="150px" />
            <GridColumn
              field="timeToSend"
              title="Time To Send"
              filterable={false}
              headerCell={HeaderCell}
              width="150px"
              cell={(cellProps) => <td>{cellProps.dataItem.timeToSend && <DateCell ellipsisPlacement="end" format="MM/DD/YYYY" {...cellProps} />}</td>}
            />
            <GridColumn field="retryCount" title="RetryCount" filter="numeric" headerCell={HeaderCell} width="150px" />
            <GridColumn field="numberOfRetries" title="Number of Retries" filter="numeric" headerCell={HeaderCell} width="150px" />
            <GridColumn field="destinationName" title="Destination Name" headerCell={HeaderCell} width="150px" />
            <GridColumn field="transactionId" title="Transaction ID" headerCell={HeaderCell} width="150px" />
            <GridColumn field="uri" title="URI" headerCell={HeaderCell} width="150px" />
            <GridColumn field="patientFileId" title="Patient File ID" headerCell={HeaderCell} width="150px" />
          </DataTable>
        </div>
      </StyledMainContainer>
      {queueDialogVisible && (
        <Dialog title="Please confirm" onClose={toggleDialog}>
          <span>Confirm you want to re-queue the following: </span>
          {Object.entries(selected)
            .filter(([, value]) => value === true)
            .map(([item], index) => (
              <span>
                {index > 0 && ', '}
                {item}
              </span>
            ))}

          <DialogActionsBar>
            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={toggleDialog} variant={ButtonVariants.SECONDARY}>
              No
            </Button>
            <Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={requeue}>
              Yes
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </Page>
  );
};

const StyledMainContainer = styled.div`
  .k-master-row td {
    height: ${(props) => props.theme.space.spacing80};
    font-size: 0.875rem;
  }
  .k-grid-header th {
    padding: ${(props) => props.theme.space.spacing20};
  }
  .k-grid {
    @media screen and (max-width: 800px) {
      height: 80%;
    }
    padding-left: ${(props) => props.theme.space.spacing50};
  }
  .k-grid-container {
    height: 1200px;
  }
  overflow: auto;
  background-color: ${(props) => props.theme.colors.palette.white};
`;

const StyledActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.space.spacing20};
`;
