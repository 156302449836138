import { useCallback, useEffect, useState } from 'react';

import { faFileMedical } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import { Field, FormValidatorsService } from 'core/forms';
import { Accordion, Dropdown, DropdownWithValuesField, ErrorMessage, Icon, Input, Label } from 'core/ui';

import { apiClient } from 'features/api';
import { ReadRequestService } from 'features/read-request/fragments/ReadRequestService';

import { ExamTypeService, InfoField } from '../../exam';
import {
  BiopsyTypes,
  ExamTypes,
  HistologyQualities,
  HistologyQualitiesUnknown,
  Lateralities,
  Organs,
  ReadingTypes,
  SlidePreparations,
} from '../../exam/constants';
import { ImageQualityActions, ImageQualitySelectors } from '../../image-quality';
import { SatisfactorySampleSizeOptions } from '../constants';

// has to be wrapped in <Form /> to work
const ExamDetailsAccordion = ({ eventKey, exam, studyType, valueSetter, valueGetter, isReadOnly, isSidebarExpanded }) => {
  const dispatch = useDispatch();
  const [services, setServices] = useState(null);

  const imageQualities = useSelector(ImageQualitySelectors.getAll);

  //get services by location
  useEffect(() => {
    (async () => {
      const data = await apiClient.servicesClient.getServicesByLocation(exam?.location.id);
      setServices(data);
    })();
  }, [exam]);

  useEffect(() => {
    dispatch(ImageQualityActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    if (ExamTypeService.isPathology(studyType)) {
      if (!valueGetter('Results.readingType')) {
        valueSetter('Results.readingType', ReadingTypes.GENERAL);
      }
      if (!valueGetter('Results.histologyQuality')) {
        valueSetter('Results.histologyQuality', HistologyQualities.GOOD);
      }
    }
  }, [studyType, valueGetter, valueSetter]);

  const onOrganChange = useCallback(
    (event) => {
      if (event != null) {
        // change form when organ changes
        // TODO: determine best way to wire these changes
        dispatch({
          type: 'exams/get-by-id/fulfilled',
          payload: { id: exam.id, organ: event.value },
        });
      }
    },
    [dispatch, exam],
  );

  const onHistologyQualityChange = (event) => {
    const selectOptions = Array.from(document.querySelectorAll('label'))
      .map((el) => el.htmlFor)
      .filter((el) => el.includes('Results.'));

    if (event.value === HistologyQualities.GOOD.value) {
      selectOptions.forEach((value) => {
        if (value !== 'Results.histologyQuality' && value !== 'Results.readingType') {
          valueSetter(value, {
            value: '',
          });
        }
      });
      valueSetter('Results.satisfactorySampleSize', {
        value: SatisfactorySampleSizeOptions.ACCEPTABLE.value,
      });
      // if histologyQuality dropdown is set to uninterpretable, set all result panel dropdowns to uninterpretable
    } else if (event.value === HistologyQualities.UNINTERPRETABLE.value) {
      selectOptions.forEach((value) => {
        if (value !== 'Results.comments' && value !== 'Results.histologyQuality' && value !== 'Results.readingType') {
          valueSetter(value, {
            value: HistologyQualitiesUnknown,
          });
        }
      });
      valueSetter('Results.satisfactorySampleSize', {
        value: SatisfactorySampleSizeOptions.NON_ACCEPTABLE.value,
      });
    }
  };

  const isPathology = ExamTypeService.isPathology(studyType);
  const isMedication =
    ExamTypeService.isUS(studyType) || ExamTypeService.isUSSR(studyType) || ExamTypeService.isSR(studyType) || ExamTypeService.isEcho(studyType);
  const service = valueGetter('service');

  if (isReadOnly) {
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header Icon={ExamIcon} title="Study">
          {studyType && <StyledExamServiceTagDiv>{studyType}</StyledExamServiceTagDiv>}
        </Accordion.Header>
        <Accordion.Body>
          <StyledRow>
            <Col className={isSidebarExpanded ? 'col-2' : 'col-6'}>
              <Field component={Dropdown} data={services} label="Study Type" name="service" textField="description" />
            </Col>
            <StyledStudyExpandedInfo>
              {exam?.description && (
                <div>
                  <span>Study Description: </span>
                  <StyledTextOnlySpan>{exam?.description}</StyledTextOnlySpan>
                </div>
              )}
              {exam?.imageCount > 0 && (
                <div>
                  <span>Image Count: </span>
                  <StyledTextOnlySpan>{exam?.imageCount}</StyledTextOnlySpan>
                </div>
              )}
              {exam?.expectedImageCount > 0 && (
                <div>
                  <span>Expected Image Count: </span>
                  <StyledTextOnlySpan>{exam?.expectedImageCount}</StyledTextOnlySpan>
                </div>
              )}
            </StyledStudyExpandedInfo>
            <ReadRequestService service={service} valueGetter={valueGetter} valueSetter={valueSetter} examId={exam?.id} />
          </StyledRow>
          {isPathology && (
            <>
              <StyledRow>
                <Col className="col-6">
                  <Field
                    component={DropdownWithValuesField}
                    data={_map(Organs)}
                    dataItemKey="value"
                    filterable={false}
                    isForPrimitiveValues
                    label="Organ"
                    name="organ"
                    required
                    validator={FormValidatorsService.required}
                    valueField="value"
                    onChange={onOrganChange}
                  />
                </Col>
                <Col className="col-6">
                  <Field
                    component={DropdownWithValuesField}
                    data={_map(Lateralities)}
                    dataItemKey="value"
                    filterable={false}
                    isForPrimitiveValues
                    label="Laterality"
                    name="laterality"
                    required
                    validator={FormValidatorsService.required}
                    valueField="value"
                  />
                </Col>
              </StyledRow>
              <StyledRow>
                <Col className="col-6">
                  <Field
                    component={DropdownWithValuesField}
                    data={_map(BiopsyTypes)}
                    dataItemKey="value"
                    filterable={false}
                    isForPrimitiveValues
                    label="Biopsy Type"
                    name="biopsyType"
                    required
                    validator={FormValidatorsService.required}
                    valueField="value"
                  />
                </Col>
                <Col className="col-6">
                  <Field
                    component={DropdownWithValuesField}
                    data={_map(SlidePreparations)}
                    dataItemKey="value"
                    filterable={false}
                    isForPrimitiveValues
                    label="Slide Preparation"
                    name="slidePreparation"
                    required
                    validator={FormValidatorsService.required}
                    valueField="value"
                  />
                </Col>
                <Col className="col-6">
                  <Field
                    component={DropdownWithValuesField}
                    data={_map(ReadingTypes)}
                    dataItemKey="value"
                    filterable={false}
                    isForPrimitiveValues
                    label="Reading Type"
                    name="Results.readingType"
                    required
                    valueField="value"
                  />
                </Col>

                <Col className="col-12">
                  <Field component={Input} name="description" label="Study Description" text={exam?.description} />
                </Col>
                <Col className="col-12">
                  <Field component={Input} label="Study Notes" name="notes" />
                </Col>
              </StyledRow>
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  }
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header Icon={ExamIcon} title="Study">
        {studyType && <StyledExamServiceTagDiv>{studyType}</StyledExamServiceTagDiv>}
      </Accordion.Header>
      <Accordion.Body>
        <StyledRow>
          <Col className="col-6">
            <Field component={Dropdown} data={services} label="Study Type" name="service" textField="description" />
          </Col>
          <Col className="col-6">
            <Field component={Dropdown} data={imageQualities} label="Image Quality" name="imageQuality" textField="description" />
          </Col>
        </StyledRow>
        {isPathology && (
          <>
            <StyledRow>
              <Col className="col-6">
                <Field
                  component={DropdownWithValuesField}
                  data={_map(Organs)}
                  dataItemKey="value"
                  filterable={false}
                  isForPrimitiveValues
                  label="Organ"
                  name="organ"
                  required
                  validator={FormValidatorsService.required}
                  valueField="value"
                  onChange={onOrganChange}
                />
              </Col>
              <Col className="col-6">
                <Field
                  component={DropdownWithValuesField}
                  data={_map(Lateralities)}
                  dataItemKey="value"
                  filterable={false}
                  isForPrimitiveValues
                  label="Laterality"
                  name="laterality"
                  required
                  validator={FormValidatorsService.required}
                  valueField="value"
                />
              </Col>
            </StyledRow>
            <StyledRow>
              <Col className="col-6">
                <Field
                  component={DropdownWithValuesField}
                  data={_map(BiopsyTypes)}
                  dataItemKey="value"
                  filterable={false}
                  isForPrimitiveValues
                  label="Biopsy Type"
                  name="biopsyType"
                  required
                  validator={FormValidatorsService.required}
                  valueField="value"
                />
              </Col>
              <Col className="col-6">
                <Field
                  component={DropdownWithValuesField}
                  data={_map(SlidePreparations)}
                  dataItemKey="value"
                  filterable={false}
                  isForPrimitiveValues
                  label="Slide Preparation"
                  name="slidePreparation"
                  required
                  validator={FormValidatorsService.required}
                  valueField="value"
                />
              </Col>
              <Col className="col-6">
                <Field
                  component={DropdownWithValuesField}
                  data={_map(ReadingTypes)}
                  dataItemKey="value"
                  filterable={false}
                  isForPrimitiveValues
                  label="Reading Type"
                  name="Results.readingType"
                  required
                  valueField="value"
                />
              </Col>
              <Col className="col-6">
                <Field
                  component={DropdownWithValuesField}
                  data={_map(HistologyQualities)}
                  dataItemKey="value"
                  filterable={false}
                  isForPrimitiveValues
                  label="Histology Quality"
                  name="Results.histologyQuality"
                  required
                  valueField="value"
                  onChange={onHistologyQualityChange}
                />
              </Col>
            </StyledRow>
          </>
        )}
        <StyledRow>
          <Col className="col-12">
            <Field component={Input} label="Internal Notes" name="internalNotes" />
          </Col>
        </StyledRow>

        <StyledRow>
          <InfoField label="Study Description" text={exam?.description} />
        </StyledRow>

        <StyledRow>
          <InfoField label="Study Notes" text={exam?.notes} />
        </StyledRow>

        {!isMedication && (
          <StyledRow>
            <InfoField label="Medications" text={exam?.medicationIndication} />
          </StyledRow>
        )}
        {isMedication && (
          <StyledRow>
            <Col className="col-12">
              <Field component={Input} label="Medications" name="medication" required />
            </Col>
          </StyledRow>
        )}

        <StyledRow>
          <InfoField label="Exam Time" text={`${dayjs(exam?.studyDate).format('MM/DD/YYYY')} ${dayjs(exam?.studyTime).format('HH:mm:ss')}`} />
        </StyledRow>

        <StyledRow>
          <InfoField label="Body Part" text={exam?.bodyPart} />
        </StyledRow>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const ExamIcon = () => {
  return <StyledIcon fixedWidth={false} icon={faFileMedical} />;
};

const StyledExamServiceTagDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.grayscale[1]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.grayscale[4]};
  color: ${({ theme }) => theme.colors.palette.grayscale[9]};
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  padding: 1px 8px;
`;

const StyledRow = styled(Row)`
  .col:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space.spacing20};
  }

  label.k-label {
    margin-bottom: 0;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledStudyExpandedInfo = styled.div`
  padding-bottom: ${({ theme }) => theme.space.spacing20};
`;

const StyledTextOnlySpan = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

ExamDetailsAccordion.propTypes = {
  eventKey: PropTypes.string.isRequired,
  exam: PropTypes.shape({
    id: PropTypes.number,
    bodyPart: PropTypes.string,
    description: PropTypes.string,
    medicationIndication: PropTypes.string,
    notes: PropTypes.string,
    studyDate: PropTypes.string,
    studyTime: PropTypes.string,
    service: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  studyType: PropTypes.string,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isSidebarExpanded: PropTypes.bool,
};

ExamDetailsAccordion.defaultProps = {
  exam: null,
  studyType: undefined,
  isReadOnly: false,
  isSidebarExpanded: false,
};

export { ExamDetailsAccordion };
