/** Split a string at the specified index.
 * @example
 * const myStr = 'foobar';
 * console.log(splitAt(4, myStr)); // Output: ['foob', 'ar']
 */
export function splitAt(
  index: number,
  str: string,
): [stringBeforeIndex: string, stringAfterIndex: string] {
  return [str.slice(0, index), str.slice(index)];
}
