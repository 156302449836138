import { useEffect, useMemo, useState } from 'react';

import _find from 'lodash/find';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Button, ComponentSizes, DropdownWithValuesField, Input, Tooltip } from 'core/ui';

import { SettingsHelpers, WellKnownSettingName, useUserSettings } from 'features/settings';

import { FileActions } from '../../file';
import RotateLeftIcon from '../assets/rotate-left.svg?react';
import RotateRightIcon from '../assets/rotate-right.svg?react';
import TrashIcon from '../assets/trash.svg?react';
import { DocumentCategories, SendDocumentMethods, SendDocumentMethodsDicom, SendDocumentMethodsWithEHR, SendDocumentStatus } from '../constants';
import { ExamActions, ExamSelectors } from '../redux';

const ROTATE_LEFT_DEGREES = -90;
const ROTATE_RIGHT_DEGREES = 90;

const ExamProcessingHeader = ({
  email,
  examId,
  fax,
  file,
  hasReadHistory,
  locationHasIntegrations,
  locationHasDicomIntegration,
  physicianEmail,
  sidebarExpanded,
  hasSuid,
  integrations,
}) => {
  const { userSettings } = useUserSettings(true);
  const ShowGenerateReport = SettingsHelpers.findSettingByName(userSettings, WellKnownSettingName.ShowGenerateReport, false)?.value == 'true';

  const dispatch = useDispatch();

  const [contact, setContact] = useState('');
  const [contactType, setContactType] = useState(null);

  const sendDocumentStatus = useSelector(ExamSelectors.getSendDocumentStatus);

  const [isSending, setIsSending] = useState(false);

  // button is disabled if there is no contact or contact type or when the sending is in progress
  // for EHR integration button is not disabled
  const sendButtonDisabled =
    (contactType?.value === 'EHR Integration' || typeof contactType?.value === 'number' ? false : !contact || !contactType) ||
    sendDocumentStatus === SendDocumentStatus.PENDING;

  const defaultContactDropdownValue = useMemo(() => {
    if (file?.categoryId === DocumentCategories.EXAM.value) {
      setContact(physicianEmail);

      return SendDocumentMethods.PHYSICIAN_EMAIL;
    }

    if (file?.categoryId === DocumentCategories.FINAL_REPORT.value) {
      if (email) {
        setContact(email);

        return SendDocumentMethods.EMAIL;
      }

      setContact(fax);

      return SendDocumentMethods.FAX;
    }

    return { value: null, name: 'Send Document Via' };
  }, [email, fax, file, physicianEmail]);

  const handleContactDropdownChange = (item) => {
    if (item.value === 'Email') {
      setContact(email);
    } else if (item.value === 'Physician Email') {
      setContact(physicianEmail);
    } else if (item.value === 'Fax') {
      setContact(fax);
    } else if (item.value === 'EHR Integration') {
      setContact('');
    } else if (item.value === 'Dicom') {
      setContact('');
    }

    setContactType(item);
  };

  const handleContactInputChange = (event) => {
    setContact(event.target?.value);
  };

  const handleDeleteFile = () => {
    dispatch(FileActions.deleteFile(file?.id));
  };

  const handleCategoryChange = (item) => {
    if (!item.value) {
      return;
    }

    dispatch(
      FileActions.changeCategory({
        fileId: file?.id,
        categoryId: item.value,
      }),
    );
  };

  const handleSendClick = useEvent(async () => {
    try {
      setIsSending(true);

      // eslint-disable-next-line @typescript-eslint/await-thenable
      await dispatch(
        ExamActions.sendDocuments({
          sendTo: contact,
          sendType: typeof contactType.value === 'string' ? contactType.value : 'Destination',
          patientFileId: file?.id,
          destinationId: typeof contactType.value === 'number' ? contactType.value : null,
        }),
      );
    } finally {
      setIsSending(false);
    }
  });

  const handleGenerateReport = () => {
    dispatch(
      ExamActions.generateReport({
        patientFileId: file?.id,
        examId,
      }),
    );
  };

  const handleRotate = (degrees) => {
    dispatch(
      FileActions.rotate({
        file,
        degrees,
      }),
    );
  };

  useEffect(() => {
    setContactType(defaultContactDropdownValue);
  }, [defaultContactDropdownValue]);

  useEffect(() => {
    if (sendDocumentStatus === SendDocumentStatus.SUCCESS) {
      NotificationsService.displaySuccess('Document sent successfully');
    } else if (sendDocumentStatus === SendDocumentStatus.ERROR) {
      NotificationsService.displayError('Document failed to send');
    }

    return () => {
      dispatch(ExamActions.clearSendDocumentStatus());
    };
  }, [dispatch, sendDocumentStatus]);

  const getSendMethods = () => {
    let dropdownValues = SendDocumentMethods;
    if (locationHasIntegrations) {
      dropdownValues = { ...SendDocumentMethodsWithEHR, ...dropdownValues };
    }
    if (locationHasDicomIntegration) {
      dropdownValues = { ...SendDocumentMethodsDicom, ...dropdownValues };
    }
    const newVals = _map(dropdownValues);
    const newOptions = newVals.concat(integrations.map((i) => ({ name: i.name, value: i.destinationId })));
    return newOptions;
  };

  return (
    <StyledDivHeaderWrapper $sidebarExpanded={sidebarExpanded}>
      <StyledDivSendContainer>
        <StyledDropdown
          data={_map(DocumentCategories)}
          defaultItem={{ name: 'Document Category' }}
          dataItemKey="value"
          filterable={false}
          isForPrimitiveValues
          onChange={handleCategoryChange}
          valid
          value={_find(DocumentCategories, (category) => category.value === file?.categoryId)?.value}
          valueField="value"
        />
        <StyledRotateButtonDiv onClick={() => handleRotate(ROTATE_LEFT_DEGREES)}>
          <RotateLeftIcon height="18px" width="18px" />
        </StyledRotateButtonDiv>
        <StyledRotateButtonDiv onClick={() => handleRotate(ROTATE_RIGHT_DEGREES)}>
          <RotateRightIcon height="18px" width="18px" />
        </StyledRotateButtonDiv>
        <StyledDropdown
          data={getSendMethods()}
          dataItemKey="value"
          defaultItem={{ name: 'Send Document Via' }}
          filterable={false}
          isForPrimitiveValues
          onChange={handleContactDropdownChange}
          valid
          value={contactType?.value}
          valueField="value"
        />
        <StyledInput
          disabled={contactType?.value === 'EHR Integration' || contactType?.value === 'Dicom'}
          name="contact"
          onChange={handleContactInputChange}
          placeholder="Enter contact Here"
          valid
          value={contact}
        />

        <Tooltip text={contactType?.value === 'Dicom' && !hasSuid ? 'Exam missing SUID' : ''}>
          <span>
            <StyledSendButton disabled={sendButtonDisabled || isSending} onClick={handleSendClick} size={ComponentSizes.SMALL}>
              Send
            </StyledSendButton>
          </span>
        </Tooltip>
        {ShowGenerateReport && (
          <Button disabled={!file || !hasReadHistory} onClick={handleGenerateReport} size={ComponentSizes.SMALL}>
            Generate Report
          </Button>
        )}
      </StyledDivSendContainer>
      <StyledTrashButtonDiv onClick={handleDeleteFile}>
        <TrashIcon height="20px" width="20px" />
      </StyledTrashButtonDiv>
    </StyledDivHeaderWrapper>
  );
};

const StyledDivHeaderWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-grow: 1;
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  justify-content: space-between;
  max-height: 60px;
  margin-left: ${({ $sidebarExpanded, theme }) => ($sidebarExpanded ? 0 : theme.space.spacing70)};
  padding: ${({ theme }) => theme.space.spacing20};
`;

const StyledDivSendContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledDropdown = styled(DropdownWithValuesField)`
  margin-right: ${({ theme }) => theme.space.spacing10};
  width: 165px;
`;

const StyledInput = styled(Input)`
  margin-left: ${({ theme }) => theme.space.spacing20};
  margin-right: ${({ theme }) => theme.space.spacing10};
  width: unset;
`;

const StyledSendButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.spacing20};
  margin-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledRotateButtonDiv = styled.div`
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.palette.blues[5]};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.space.spacing10};
  margin-right: ${({ theme }) => theme.space.spacing20};
  padding: ${({ theme }) => theme.space.spacing10};
`;

const StyledTrashButtonDiv = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.reds[5]};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.space.spacing30};
  margin-right: ${({ theme }) => theme.space.spacing20};
  padding: ${({ theme }) => theme.space.spacing10};
`;

ExamProcessingHeader.propTypes = {
  email: PropTypes.string,
  examId: PropTypes.number,
  fax: PropTypes.string,
  file: PropTypes.shape({
    id: PropTypes.number,
    categoryId: PropTypes.number,
  }),
  hasReadHistory: PropTypes.bool,
  locationHasIntegrations: PropTypes.bool,
  locationHasDicomIntegration: PropTypes.bool,
  hasSuid: PropTypes.bool,
  physicianEmail: PropTypes.string,
  sidebarExpanded: PropTypes.bool,
  integrations: PropTypes.arrayOf(PropTypes.shape({})),
};

ExamProcessingHeader.defaultProps = {
  email: '',
  examId: undefined,
  fax: '',
  file: null,
  hasReadHistory: false,
  locationHasIntegrations: false,
  locationHasDicomIntegration: false,
  hasSuid: false,
  physicianEmail: '',
  sidebarExpanded: true,
  integrations: [],
};

export { ExamProcessingHeader };
