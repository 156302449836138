import { createAction } from '@reduxjs/toolkit';

import { AuthSelectors } from 'features/auth';

const storeMessage = createAction('home/store-message');

const getMessage = () => async (dispatch, getState) => {
  try {
    const accessToken = AuthSelectors.accessToken(getState());

    const response = await fetch(
      `${import.meta.env.VITE_APP_COMPUMED_API_BASE_URL}/api/values/test`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok)
      throw new Error(`An error has occured: ${response.status}`);

    const json = await response.json();

    dispatch(storeMessage(json));
  } catch (error) {
    // console.log(error);
  }
};

export const HomeActions = {
  storeMessage,
  getMessage,
};
