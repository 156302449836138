import styled from 'styled-components';

import { FormElement } from './FormElement';

export const createStyledFormElement = (gridTemplateColumns: string) => {
  return styled(FormElement)`
    background-color: ${({ theme }) => theme.colors.palette.white};
    display: grid;
    grid-template-columns: ${gridTemplateColumns};
    padding: 8px;
  `;
};

export const createStyledRhfForm = (gridTemplateColumns: string) => {
  return styled.form`
    background-color: ${({ theme }) => theme.colors.palette.white};
    display: grid;
    overflow: hidden;
    grid-template-columns: ${gridTemplateColumns};
    grid-auto-rows: min-content;
    padding: 8px;
  `;
};
