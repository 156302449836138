import { FunctionComponent, ReactNode, useMemo } from 'react';

import { AccordionNgContextType } from './AccordionNgContextType';
import { AccordionNgContext } from './contexts';

export const AccordionNgProvider: FunctionComponent<
  AccordionNgContextType & { children?: ReactNode }
> = ({
  expandedItemKeys,
  setExpandedItemKeys,
  setExpanded,
  setCollapsed,
  toggleExpanded,
  children,
}) => {
  const accordionContext = useMemo(
    () => ({
      expandedItemKeys,
      setExpandedItemKeys,
      setExpanded,
      setCollapsed,
      toggleExpanded,
    }),
    [
      expandedItemKeys,
      setCollapsed,
      setExpanded,
      setExpandedItemKeys,
      toggleExpanded,
    ],
  );

  return (
    <AccordionNgContext.Provider value={accordionContext}>
      {children}
    </AccordionNgContext.Provider>
  );
};

AccordionNgProvider.displayName = 'AccordionNgProvider';
