import { memo } from 'react';

import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@progress/kendo-react-buttons';
import { ListGroup } from 'react-bootstrap';
import styled from 'styled-components';

import { AzureBlobLink } from '../AzureBlobLink';
import { Icon } from '../Icon';
import { Modal } from '../Modal';
import { HelpModalProps } from './HelpModalProps';

export const HelpModal = memo<HelpModalProps>(({ className, show, onClose }) => {
  const handleReadRequestClick = async () => {
    const url = 'https://www.youtube.com/watch?v=HerBbuYA_Lg';
    window.open(url, '_blank');
  };

  return (
    <Modal className={className} show={show} onHide={onClose} title="SUPPORT">
      <StyledDialogBodyDiv>
        <StyledHeader>Documentation</StyledHeader>
        <ListGroup>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="PathoZoom Scan User Guide, R 1.0.pdf">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>PathoZoom Scan User Guide</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="CompuMed Portal and the HIPAA Security Rule.pdf">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>CompuMed Portal and the HIPAA Security Rule</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledHeader>Video Guides</StyledHeader>
          <StyledListGroupItem>
            <Button fillMode="link" onClick={handleReadRequestClick}>
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Read Request Page Guide</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </Button>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Active%20and%20Inactive%20Donors%204.2020.mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Active and Inactive Donors</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Add%20a%20Donor%20by%20Uploading%20a%20Dicom%20Exam%204.2020.mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Add a Donor by Uploading a Dicom Exam</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Add%20an%20File%20%204.2020.mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Add a File</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Delete%20an%20Exam%204.2020.mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Delete an Exam</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Requesting%20an%20Interpretation%20if%20a%20Study%204.2020.mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Requesting an Interpretation of a Study</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Sharing%20Donor%20Studies%204.2020.mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Sharing Donor Studies</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
          <StyledListGroupItem>
            <AzureBlobLink containerName="portal-files" blobName="Working%20in%20the%20Donor%20Record%204.2020..mp4">
              <StyledViewHelpDiv>
                <StyledPreviewTextSpan>Working in the Donor Record</StyledPreviewTextSpan>
                <StyledIcon icon={faEye}></StyledIcon>
              </StyledViewHelpDiv>
            </AzureBlobLink>
          </StyledListGroupItem>
        </ListGroup>
        <hr />
        <StyledCallUsDiv>
          <StyledPreviewTextSpan>Give us a call</StyledPreviewTextSpan>
          <span>(310) 258-5055</span>
        </StyledCallUsDiv>
      </StyledDialogBodyDiv>
    </Modal>
  );
});

HelpModal.displayName = 'HelpModal';

const StyledDialogBodyDiv = styled.div`
  padding: 24px;
`;

const StyledHeader = styled.h5`
  padding-bottom: ${({ theme }) => theme.space.spacing20};
  padding-top: ${({ theme }) => theme.space.spacing50};
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledListGroupItem = styled(ListGroup.Item)`
  margin: 0;
  border: 0;
  padding: 5px 0;

  & > button {
    display: block;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`;

const StyledCallUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledViewHelpDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPreviewTextSpan = styled.span`
  padding-right: 5px;
`;

const StyledIcon = styled(Icon)`
  svg {
    padding-bottom: 2px;
  }
`;
