import { useContext, useEffect } from 'react';

import { AuthenticationScheme, useAuthentication } from 'features/auth';

import { ExamStatusCacheContext } from '../contexts';

export function useExamStatusTypesCache() {
  const { statuses, triggerFetch } = useContext(ExamStatusCacheContext);

  const { activeScheme } = useAuthentication();

  useEffect(() => {
    if (activeScheme !== AuthenticationScheme.OIDC) return; // For now the API endpoint requires a fully authenticated user in order to retrieve the status type list.

    triggerFetch();
  }, [triggerFetch, activeScheme]);

  return statuses;
}
