import styled from 'styled-components';

import { BreakpointSelectors } from '../theme';

export const Page = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  row-gap: ${({ theme }) => theme.space.spacing10};
  overflow-x: hidden;
  ${BreakpointSelectors.VerticalClamp} & {
    overflow-y: hidden;
  }
`;
