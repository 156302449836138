import { forwardRef } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { ProgressSpinner } from './ProgressSpinner';
import { ProgressSpinnerOverlayProps } from './ProgressSpinnerOverlayProps';

/** Display a loading indicator.  This is rendered as `position: absolute` and stretches to fill all available space in the containing element.  This will always render in the dom with user-visibility that is controlled by CSS. */
export const ProgressSpinnerOverlay = forwardRef<
  HTMLDivElement,
  ProgressSpinnerOverlayProps
>(({ className, show }, ref) => (
  <StyledDiv
    ref={ref}
    className={cn(className, 'progress-spinner-overlay', {
      'spinner-visible': show,
    })}
  >
    <ProgressSpinner />
  </StyledDiv>
));

ProgressSpinnerOverlay.displayName = 'ProgressSpinnerOverlay';

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 90px 0 0 0;
  overflow: hidden;

  &.spinner-visible {
    z-index: 100;
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 0;
    transition: opacity 0.25s linear;
  }

  &.spinner-visible:before {
    opacity: 0.7;
  }

  & .progress-spinner {
    opacity: 0;
    transition: opacity 0.25s ease-in;
  }

  &.spinner-visible .progress-spinner {
    opacity: 1;
  }
`;
