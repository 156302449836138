import { useContext, useMemo } from 'react';

import { AccordionContext } from 'react-bootstrap';

/** Determine if the specified tab (eventKey) is currently expanded (true). */
export function useAccordionTabExpanded(eventKey: string) {
  const { activeEventKey } = useContext(AccordionContext);

  const isExpanded = useMemo(() => {
    return activeEventKey == null || typeof activeEventKey === 'string'
      ? activeEventKey === eventKey
      : activeEventKey.includes(eventKey);
  }, [activeEventKey, eventKey]);

  return isExpanded;
}
