import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@progress/kendo-react-common';
import { DrawerItem } from '@progress/kendo-react-layout';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon as CoreIcon } from 'core/ui';
import { Tooltip } from '@progress/kendo-react-tooltip';

export const NavigationSidebarCustomItem = ({ dataExpanded, drawerExpanded, icon, selected, text, visible, ...otherProps }) => {
  return (
    <>
      {visible === false ? null : (
        <Tooltip position="right" parentTitle={true}>
          <div title={text}>
            <StyledDrawerItem dataExpanded={dataExpanded} selected={selected} {...otherProps}>
              <Icon className={`item-icon fa ${icon}`} />
              <StyledSpanItemText drawerExpanded={drawerExpanded} selected={selected}>
                {text}
              </StyledSpanItemText>
              {dataExpanded !== undefined && drawerExpanded && <CoreIcon icon={dataExpanded ? faAngleUp : faAngleDown} />}
            </StyledDrawerItem>
          </div>
        </Tooltip>
      )}
    </>
  );
};

const resolveBorderLeft = ({ drawerExpanded, selected, theme }) =>
  selected && !drawerExpanded ? `${theme.borderWidths.sidebar} solid ${theme.colors.palette.white}` : 'none';

const resolvePaddingLeft = ({ parentId, theme }) => (parentId !== undefined ? `${theme.space.spacing60}` : `${theme.space.spacing40}`);

const StyledSpanArrow = styled.span`
  position: absolute;
  right: ${({ theme }) => theme.space.sidebarArrowIconMargin};
`;

const StyledDrawerItem = styled(DrawerItem)`
  padding-left: ${resolvePaddingLeft};
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.palette.blues[4]};
    color: ${({ theme }) => theme.colors.palette.white};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.palette.blues[5]} !important;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.palette.white} !important;
  }

  k-drawer-container:not(.k-drawer-expanded) .k-drawer-item {
    width: 50px;
  }

  &.k-selected {
    background: ${({ theme }) => theme.colors.palette.blues[5]} !important;
  }

  .k-drawer-item {
  }

  .item-icon {
    height: 16px;
    width: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
    flex: 0 0 auto;
  }
`;

const StyledSpanItemText = styled.span`
  flex: 1 1 0;
  overflow: hidden;
  align-items: center;
  border-left: ${resolveBorderLeft};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.body};
  line-height: ${({ theme }) => theme.lineHeights.body};
  margin-left: ${({ theme }) => theme.space.spacing20};
  padding-left: ${({ theme }) => theme.space.sidebarItemPaddingLeft};
  text-transform: capitalize;
`;

NavigationSidebarCustomItem.propTypes = {
  dataExpanded: PropTypes.bool,
  drawerExpanded: PropTypes.bool,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  text: PropTypes.string,
  visible: PropTypes.bool,
};

NavigationSidebarCustomItem.defaultProps = {
  dataExpanded: undefined,
  drawerExpanded: undefined,
  icon: '',
  selected: false,
  text: undefined,
  visible: undefined,
};
