import { ChangeEvent, KeyboardEvent, forwardRef, useCallback } from 'react';

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { Input } from '../Input';
import { InputLineProps } from './InputLineProps';

export const InputLine = forwardRef<HTMLInputElement, InputLineProps>(
  (
    {
      namePrefix,
      value,
      itemKey,
      renderIndex,
      disabled = false,
      readOnly = false,
      onDelete,
      onChange,
      onBlur,
      onFocus,
      onEnterKeyDown,
    },
    ref,
  ) => {
    const handleDeleteClick = useCallback(() => {
      onDelete(itemKey);
    }, [itemKey, onDelete]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, itemKey);
      },
      [itemKey, onChange],
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;

        // TODO: Why are we preventing default here?  If it's to avoid form submission then it would be best to instead propagate the keydown event to the parent component to decide what to do.
        event.preventDefault();

        // Use the end caret position when the user has highlighted a range of text.  This is an arbitrary decision and is open to re-evaluation.
        const caretIndex = event.currentTarget.selectionEnd;

        if (!readOnly && !disabled) {
          setTimeout(() => {
            onEnterKeyDown(caretIndex, itemKey);
          });
        }
      },
      [disabled, itemKey, onEnterKeyDown, readOnly],
    );

    return (
      <StyledLine>
        <Input
          ref={ref}
          name={namePrefix == null ? undefined : `${namePrefix}_${renderIndex}`}
          className={`testing_${InputLine.displayName}_input`}
          data-line-item-index={renderIndex}
          value={value}
          valid
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <StyledCloseButton
          className={`testing_${InputLine.displayName}_delete-button`}
          data-line-item-index={renderIndex}
          type="button"
          onClick={handleDeleteClick}
          disabled={disabled}
          title="Remove"
        >
          <Icon icon={faCircleXmark} />
        </StyledCloseButton>
      </StyledLine>
    );
  },
);

InputLine.displayName = 'InputLine';

const StyledLine = styled.div`
  display: flex;
`;

const StyledCloseButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 7px;
`;
