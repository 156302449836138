import {
  DataSourceRequestState,
  toDataSourceRequestString,
} from '@progress/kendo-data-query';

import { ChangeLogModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class ChangeLogsClient {
  constructor(private httpClient: HttpClient) {
    this.getLogs = this.getLogs.bind(this);
  }

  public async getLogs(
    dataState: DataSourceRequestState,
    entityType: string,
    id: number,
  ) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<
      DataResult<ChangeLogModel>
    >(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/ChangeLogs/${encodeURIComponent(
          entityType,
        )}/${id}?${queryStr}`,
        options,
      ),
    );
    return result;
  }
}
