import { createEntityAdapter } from '@reduxjs/toolkit';

const rootSelector = (state) => state.uploads;

const { selectAll: getAll } = createEntityAdapter().getSelectors(rootSelector);

const getStatus = (state) => rootSelector(state).status;

const getTotal = (state) => rootSelector(state).total;

export const UploadTrackerSelectors = {
  getAll,
  getTotal,
  getStatus,
};
