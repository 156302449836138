import { useRef, useState } from 'react';

import { useEvent } from './use-event';

const ABORT_TOKEN = Symbol('useAsyncCallback() Abort Token');

export type UseAsyncCallbackResult<TParams extends unknown[] = never[], TReturn = unknown> = [
  wrappedCallback: (...args: TParams) => Promise<TReturn | undefined>,
  isLoading: boolean,
];

/** Wraps an async callback with an AbortController that automatically ensures that the callback only executes a single time concurrently.  Executing the returned callback will immediately abort any pending operations that make use of the supplied `AbortSignal` parameter. */
export function useAsyncCallback<TParams extends unknown[] = never[], TReturn = unknown>(
  callback: (signal: AbortSignal, ...args: TParams) => Promise<TReturn>,
): UseAsyncCallbackResult<TParams, TReturn> {
  const abortController = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const wrappedCallback = useEvent(async (...args: TParams) => {
    const currentController = new AbortController();

    try {
      if (abortController.current) {
        const previous = abortController.current;
        abortController.current = null;
        previous.abort(ABORT_TOKEN);
      }

      abortController.current = currentController;

      setIsLoading(true);

      return await callback(currentController.signal, ...args);
    } catch (ex) {
      // Silently eat the exception if it's an abort.

      if (ex === ABORT_TOKEN) {
        // This happens when the executing callback manually invokes signal.throwIfAborted().
        return;
      } else if (ex instanceof DOMException && ex.name === 'AbortError') {
        // This happens when a window.fetch() request is aborted.
        return;
      } else {
        // Re-throw the exception if it's not an abort.
        throw ex;
      }
    } finally {
      if (abortController.current === currentController) {
        abortController.current = null;
      }

      setIsLoading(false);
    }
  });

  return [wrappedCallback, isLoading];
}
