const radiiScale = [
  '0rem',
  '0.125rem',
  '0.25rem',
  '0.5rem',
  '0.625rem',
  '1rem',
  '6.25rem',
];

const base = radiiScale[2];
const circle = radiiScale[6];

export const radii = {
  scale: radiiScale,
  base,
  circle,
};

export type Radii = typeof radii;
