import { DynamicFormModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class DynamicFormsClient {
  constructor(private httpClient: HttpClient) {
    this.getDynamicFormFor = this.getDynamicFormFor.bind(this);
    this.setDynamicFormFor = this.setDynamicFormFor.bind(this);
  }

  public async getDynamicFormFor(entityType: string, entityId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const form = await this.httpClient.fetchAndParse<DynamicFormModel>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/dynamic-forms/${encodeURIComponent(
          entityType,
        )}/${entityId}`,
        options,
      ),
    );
    return form;
  }

  public async setDynamicFormFor(model: DynamicFormModel) {
    const options = await this.httpClient.createStandardOptions('POST', model);
    await this.httpClient.fetch(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/dynamic-forms/${encodeURIComponent(
          model.entityType,
        )}/${model.entityId}`,
        options,
      ),
    );
  }
}
