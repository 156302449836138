import { FunctionComponent, memo, useState } from 'react';

import { Window } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';

import { PatientAgeRangeModel, PatientModel } from 'models';

import { SubmitHandler } from 'core/forms';
import { Card, Dialog } from 'core/ui';

import { PatientEditService } from '../services';
import { PatientFormValues } from '../types';
import { PatientFormContent } from './PatientFormContent';

export const PatientEditWindow: FunctionComponent<{
  isOpo: boolean | undefined;
  ageRanges: PatientAgeRangeModel[] | undefined;
  handleSubmit: SubmitHandler<PatientFormValues> | undefined;
  patient: PatientModel | undefined | null;
  show: boolean;
  onClosed: () => void;
  isRequestMode?: boolean;
  showRequiredError?: boolean;
}> = memo(({ isOpo, isRequestMode, ageRanges, onClosed, handleSubmit, patient, show, showRequiredError }) => {
  return (
    <>
      {show && (
        <StyledWindow title={`Edit ${isOpo ? 'Donor' : 'Patient'}`} onClose={onClosed} initialHeight={375} initialWidth={1100}>
          <Card>
            <PatientFormContent
              ageRanges={ageRanges}
              isEditMode
              isFormDisabled={false}
              onSubmit={handleSubmit}
              initialValues={PatientEditService.copyModelToForm(patient ?? ({} as PatientModel))}
              isOpo={isOpo}
              isRequestMode={isRequestMode}
              showRequiredError={showRequiredError}
            ></PatientFormContent>
          </Card>
        </StyledWindow>
      )}
    </>
  );
});

PatientEditWindow.displayName = 'PatientEditWindow';

const mediaMaxWidth = '1024px';
const StyledWindow = styled(Window)`
  @media (max-width: ${mediaMaxWidth}) {
    &&.k-window {
      width: 100% !important;
      height: 75% !important;
    }
  }
`;
