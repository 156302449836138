import { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { SettingsRoutes } from '../routes';
import { PortalSettingsPage } from './PortalSettingsPage';

const SETTINGS_ADMIN_ROLES = [UserRoles.ADMIN];

export const Settings = memo(() => {
  return (
    <Routes>
      <Route
        path={SettingsRoutes.HOME}
        element={
          <RouteGuard allowedRoles={SETTINGS_ADMIN_ROLES} requireSystemAdmin>
            <PortalSettingsPage />
          </RouteGuard>
        }
      />
    </Routes>
  );
});

Settings.displayName = 'Settings';
