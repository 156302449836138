import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { ItemStatus } from '../constants';
import { ItemActions } from './actions';

export const itemAdapter = createEntityAdapter({ sortComparer: false });

const getReducerInitialState = () => ({
  ...itemAdapter.getInitialState(),
  total: 0,
  status: ItemStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(ItemActions.getById.fulfilled, (draft, action) => {
    itemAdapter.upsertOne(draft, action.payload);
    draft.status = ItemStatus.FETCHED;
  });
  builder.addCase(ItemActions.getAll.fulfilled, (draft, action) => {
    itemAdapter.setMany(draft, action.payload.items);
    draft.status = ItemStatus.FETCHED;
  });
});
