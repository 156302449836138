import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Field, Form, FormElement, FormValidatorsService } from 'core/forms';
import { useEvent } from 'core/hooks';
import {
  Accordion,
  Button,
  DatePicker,
  DateTimePicker,
  Dropdown,
  DropdownWithValuesField,
  Input,
  Label,
  MASKED_DATETIME_INPUT_MODES,
  MaskedDateTimeInput,
  TextArea,
} from 'core/ui';
import { hasText } from 'core/utils';

import { apiClient } from 'features/api';
import { FormAccordian } from 'features/form';
import { ReadRequestService } from 'features/read-request';

import { ImageQualityActions, useImageQualities } from '../../image-quality';
import { LocationActions, LocationSelectionAccordion, LocationSelectors } from '../../location';
import { PatientFormAccordion } from '../../patient';
import { PhysicianActions, PhysicianSelectors } from '../../physician';
import { ServiceActions, useServices } from '../../service';
import { TatActions, TatSelectors } from '../../tat';
import ExamIcon from '../assets/exam.svg?react';
import { BiopsyTypes, ExamApiStatus, ExamDevices, ExamMethods, ExamSources, Lateralities, Organs, SlidePreparations } from '../constants';
import { ExamSelectors } from '../redux';
import { ExamInitialValueService, ExamTypeService } from '../services';
import { OverreadHistory } from './OverreadHistory';

const PATIENT_ACCORDION_KEY = '1';
const LOCATION_ACCORDION_KEY = '2';
const EXAM_ACCORDION_KEY = '3';

const EDIT_EXAM_ACTIVE_KEYS = [LOCATION_ACCORDION_KEY, EXAM_ACCORDION_KEY];
const ADD_EXAM_ACTIVE_KEYS = [PATIENT_ACCORDION_KEY, LOCATION_ACCORDION_KEY, EXAM_ACCORDION_KEY];

const ExamProcessingSidebarBody = ({
  currentPatient,
  exam,
  handleSubmit,
  isFormInEditMode,
  patientFormState,
  patientSearchText,
  setCurrentPatient,
  setPatientFormState,
  setPatientSearchText,
  onLocationIdChange,
}) => {
  const dispatch = useDispatch();

  const [forms, setForms] = useState(null);

  const defaultTats = useSelector(TatSelectors.getAll);
  const locations = useSelector(LocationSelectors.getAll);
  const physicians = useSelector(PhysicianSelectors.getAll);
  const examSaveStatus = useSelector(ExamSelectors.getStatus);

  const [isPatientAccordionCollapsed, setIsPatientAccordionCollapsed] = useState(!!isFormInEditMode);

  const [imageQualities] = useImageQualities();
  const services = useServices();

  const initialState = useMemo(() => exam || ExamInitialValueService.getInitialValues(), [exam]);

  const handleLocationChange = useCallback(
    (newLocationId, setValue, valueGetter) => {
      // https://compumed.atlassian.net/browse/DEV-4202
      const selectedTat = valueGetter('sla');
      const location = locations.find((l) => l.id === newLocationId) ?? null;
      setValue('location', { value: location });
      onLocationIdChange(newLocationId);

      if (selectedTat !== undefined) {
        // check if regular exam:
        if (selectedTat.value == null || selectedTat.value >= 1440) {
          // select default physician
          const defaultPhysician = physicians.findIndex((e) => e.id === location.defaultPhysician_Id);
          if (defaultPhysician) {
            setValue('physician', {
              value: physicians[defaultPhysician],
            });
          }

          // select default TAT
          const defaultTAT = defaultTats.findIndex((e) => e.value === location.defaultTAT);
          if (defaultTAT) {
            setValue('sla', {
              value: defaultTats[defaultTAT],
            });
          }
        }
      }
    },
    [locations, onLocationIdChange, physicians, defaultTats],
  );

  // Show forms based on services selected
  const handleServicesChange = useEvent(async (event) => {
    var ids = event.value.map((e) => e.id);
    const formValues = await apiClient.formClient.findServices(ids);
    setForms(formValues);
  });

  useEffect(() => {
    dispatch(ServiceActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ImageQualityActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(TatActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LocationActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(PhysicianActions.getAll());
  }, [dispatch]);

  useEffect(() => {
    const initModal = async () => {
      if (exam?.id && exam?.serviceId) {
        const formValues = await apiClient.formClient.find(exam?.id, exam?.serviceId);
        setForms(formValues);
      }
    };
    initModal();
  }, [exam?.id, exam?.serviceId]);

  return (
    <Form
      initialValues={initialState}
      key={isFormInEditMode ? JSON.stringify(initialState) : 'exam-new'}
      onSubmitClick={handleSubmit}
      render={({ onChange: setValue, valueGetter }) => {
        const studyType = valueGetter('service.description');
        const selectedPhysician = valueGetter('physician');
        const selectedLocation = valueGetter('location');
        const selectedTat = valueGetter('sla');
        const selectedService = valueGetter('service');
        const selectedRequestDate = dayjs(valueGetter('requestedDate'));
        const selectedRequestTime = dayjs(valueGetter('requestTime'));
        const isPathology = ExamTypeService.isPathology(studyType);

        const tatDate = dayjs(selectedRequestDate)
          .hour(selectedRequestTime.hour())
          .minute(selectedRequestTime.minute())
          .second(selectedRequestTime.second())
          .add(selectedTat?.value, 'minute');

        const handleTatChange = () => {
          setValue('requestedDate', {
            value: dayjs().utc().tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss'),
          });
          setValue('requestTime', {
            value: dayjs().utc().tz('America/Los_Angeles').format('YYYY-MM-DDTHH:mm:ss'),
          });
        };

        return (
          <StyledAccordion alwaysOpen defaultActiveKey={isFormInEditMode ? EDIT_EXAM_ACTIVE_KEYS : ADD_EXAM_ACTIVE_KEYS}>
            {locations.length > 0 && (
              <LocationSelectionAccordion
                eventKey={LOCATION_ACCORDION_KEY}
                locationId={selectedLocation?.id}
                onChange={(newLocationId) => handleLocationChange(newLocationId, setValue, valueGetter)}
              />
            )}

            <PatientFormAccordion
              eventKey={PATIENT_ACCORDION_KEY}
              formState={patientFormState}
              isCollapsed={isPatientAccordionCollapsed}
              handleHeaderClick={() => setIsPatientAccordionCollapsed((oldState) => !oldState)}
              patient={currentPatient}
              searchText={patientSearchText}
              setCurrentPatient={setCurrentPatient}
              setFormState={setPatientFormState}
              setSearchText={setPatientSearchText}
            />

            <FormElement>
              <Accordion.Item eventKey={EXAM_ACCORDION_KEY}>
                {currentPatient && (
                  <>
                    <Accordion.Header Icon={ExamIcon} title="Study" />
                    <Accordion.Body>
                      <Row>
                        {hasText(exam?.statusType?.name) && hasText(exam?.statusType?.description) && (
                          <Label description={exam.statusType.description}>Status: {exam.statusType.name}</Label>
                        )}

                        <Col className="col-6">
                          <Field
                            component={DropdownWithValuesField}
                            data={ExamSources}
                            dataItemKey="value"
                            isForPrimitiveValues
                            label="Study Source"
                            name="source"
                            required
                            validator={FormValidatorsService.required}
                            valueField="value"
                          />
                        </Col>
                        <Col className="col-6">
                          <Field
                            component={DropdownWithValuesField}
                            data={ExamDevices}
                            isForPrimitiveValues
                            dataItemKey="value"
                            label="Device"
                            name="device"
                            valueField="value"
                          />
                        </Col>
                      </Row>
                      <StyledRow>
                        <Col className="col-6">
                          <Field
                            component={DropdownWithValuesField}
                            data={ExamMethods}
                            isForPrimitiveValues
                            dataItemKey="value"
                            label="Method"
                            name="transmissionMethod"
                            valueField="value"
                          />
                        </Col>
                        <Col className="col-6">
                          <Field component={Dropdown} data={imageQualities} label="Image Quality" name="imageQuality" textField="description" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={Dropdown} data={services} label="Study Type" name="service" textField="description" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-6">
                          <Field
                            component={MaskedDateTimeInput}
                            label="Study Date"
                            mode={MASKED_DATETIME_INPUT_MODES.DATE}
                            name="studyDate"
                            required
                            validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                          />
                        </Col>
                        <Col className="col-6">
                          <Field
                            component={MaskedDateTimeInput}
                            mode={MASKED_DATETIME_INPUT_MODES.TIME}
                            label="Study Time"
                            name="studyTime"
                            required
                            validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                          />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-6">
                          <Field
                            component={DateTimePicker}
                            label="Received Date Time"
                            name="uploadedDate"
                            required
                            format="MM/dd/yy HH:mm:ss"
                            validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                          />
                        </Col>
                        <Col className="col-6">
                          <Field component={Input} description="Customer UID or Accession Number" label="Accession Number" name="customerUID" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={Input} description="Hospital" label="Hospital" name="hospital" />
                        </Col>
                      </StyledRow>
                      {isPathology && (
                        <>
                          <StyledRow>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(Organs)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Organ"
                                name="organ"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(Lateralities)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Laterality"
                                name="laterality"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(BiopsyTypes)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Biopsy Type"
                                name="biopsyType"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                            <Col className="col-6">
                              <Field
                                component={DropdownWithValuesField}
                                data={_map(SlidePreparations)}
                                dataItemKey="value"
                                filterable={false}
                                isForPrimitiveValues
                                label="Slide Preparation"
                                name="slidePreparation"
                                required
                                validator={FormValidatorsService.required}
                                valueField="value"
                              />
                            </Col>
                          </StyledRow>
                        </>
                      )}
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={Input} label="Description" name="description" description="Study Description for the exam or service" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={TextArea} label="Study Notes" name="notes" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={TextArea} label="Internal Notes" name="internalNotes" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={TextArea} label="Interpretation" name="interpretation" />
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col className="col-12">
                          <Field component={Input} label="Image URL" name="imageURL" />
                        </Col>
                      </StyledRow>
                      {exam && exam?.imageCount > 0 && (
                        <Label description="How many DICOM image files have been received.">Image Count: {exam.imageCount}</Label>
                      )}
                      {exam && exam?.suid != '' && <Label description="Study Instance UID.">SUID: {exam.suid}</Label>}
                      <StyledRow>
                        <Col className="col-5">
                          <Field
                            component={Dropdown}
                            data={!Array.isArray(selectedLocation?.taTs) || !selectedLocation?.taTs.length ? defaultTats : selectedLocation?.taTs}
                            dataItemKey="value"
                            defaultItem={{ name: 'No Over-read', value: null }}
                            label="TAT"
                            name="sla"
                            onChange={handleTatChange}
                          />
                        </Col>
                        {selectedTat?.value > 0 && (
                          <Col className="col-7">
                            <DatePicker
                              disabled
                              format="MM/dd/yy HH:mm:ss"
                              name="tatDate"
                              placeholder="Enter Date"
                              value={tatDate.isValid() ? tatDate.format() : null}
                            />
                          </Col>
                        )}
                      </StyledRow>
                      {selectedTat?.value > 0 && (
                        <>
                          <Row>
                            <Col className="col-12">
                              <ReadRequestService
                                service={selectedService}
                                valueGetter={valueGetter}
                                valueSetter={setValue}
                                examId={exam?.id}
                                fieldOnly
                                onChange={handleServicesChange}
                              />
                            </Col>
                            {exam !== null && <FormAccordian forms={forms} values={exam.dynamicFormFields} />}
                          </Row>
                          <StyledRow>
                            <Col className="col-6">
                              <Field
                                component={MaskedDateTimeInput}
                                mode={MASKED_DATETIME_INPUT_MODES.DATE}
                                label="Requested Date"
                                name="requestedDate"
                                required
                                validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                              />
                            </Col>
                            <Col className="col-6">
                              <Field
                                component={MaskedDateTimeInput}
                                mode={MASKED_DATETIME_INPUT_MODES.TIME}
                                label="Requested Time"
                                name="requestTime"
                                required
                                validator={FormValidatorsService.combineValidators([FormValidatorsService.required, FormValidatorsService.datetime])}
                              />
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col className="col-12">
                              {hasText(exam?.statusType?.name) === 'AIProcessing' ? (
                                <div className={'k-messagebox k-messagebox-error'}>Please wait for AI processing to complete before assigning the exam.</div>
                              ) : (
                                <Field
                                  component={Dropdown}
                                  data={physicians}
                                  defaultItem={{
                                    fullName: 'Unassigned',
                                    value: null,
                                  }}
                                  label="Physician"
                                  name="physician"
                                  textField="fullName"
                                />
                              )}
                            </Col>
                          </StyledRow>
                        </>
                      )}
                      {selectedPhysician && hasText(exam?.statusType?.name) !== 'AIProcessing' && (
                        <>
                          <StyledRow>
                            <Col className="col-12">
                              <Field component={TextArea} label="Over-read" name="overRead" rows="5" />
                              {exam?.reads && <OverreadHistory readHistory={exam?.reads} />}
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col className="col-12">
                              <Field component={Input} label="Confirmed by" name="confirmedBy" />
                            </Col>
                          </StyledRow>
                        </>
                      )}
                    </Accordion.Body>
                  </>
                )}
              </Accordion.Item>
              <StyledRowButton>
                <Col className="col-12">
                  <Button disabled={examSaveStatus === ExamApiStatus.PENDING} type="submit">
                    Save
                  </Button>
                </Col>
              </StyledRowButton>
            </FormElement>
          </StyledAccordion>
        );
      }}
    />
  );
};

const StyledAccordion = styled(Accordion)`
  max-height: 93vh;
  overflow-y: auto;
`;

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

const StyledRowButton = styled(Row)`
  background: white;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  margin-right: 0;
  padding: ${({ theme }) => theme.space.spacing30};
`;

ExamProcessingSidebarBody.propTypes = {
  currentPatient: PropTypes.shape({}),
  exam: PropTypes.shape({
    reads: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  handleSubmit: PropTypes.func,
  isFormInEditMode: PropTypes.bool,
  patientFormState: PropTypes.string.isRequired,
  patientSearchText: PropTypes.string,
  setCurrentPatient: PropTypes.func,
  setPatientFormState: PropTypes.func.isRequired,
  setPatientSearchText: PropTypes.func,
  onLocationIdChange: PropTypes.func,
};

ExamProcessingSidebarBody.defaultProps = {
  currentPatient: null,
  exam: null,
  handleSubmit: () => {},
  isFormInEditMode: false,
  patientSearchText: '',
  setCurrentPatient: () => {},
  setPatientSearchText: '',
  onLocationIdChange: () => {},
};

export { ExamProcessingSidebarBody };
