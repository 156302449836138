import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Body } from './Body';
import { CardProps } from './CardProps';
import { Header } from './Header';

export const Card: FunctionComponent<CardProps> & {
  Body: typeof Body;
  Header: typeof Header;
} = ({ className, onClick, children }) => (
  <StyledDivCard className={className} onClick={onClick}>
    {children}
  </StyledDivCard>
);

Card.displayName = 'Card';
Card.Body = Body;
Card.Header = Header;

const StyledDivCard = styled.div`
  background: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => theme.radii.scale[3]};
  display: inline-block;
  padding: ${({ theme }) => theme.space.spacing20}
    ${({ theme }) => theme.space.spacing30};
`;
