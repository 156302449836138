import { FunctionComponent } from 'react';

import styled from 'styled-components';

// idea source: https://www.telerik.com/account/support-center/view-ticket/1552779
// code source: https://stackblitz.com/edit/react-gphcup-5izgt6?file=app%2Fmain.tsx
export const RecursiveHighlight: FunctionComponent<{
  value: string;
  filter: string;
}> = ({ value, filter }) => {
  const index = value.toLocaleLowerCase().indexOf(filter);

  if (index >= 0) {
    const left = value.substring(0, index);
    const right = value.substring(index + filter.length, value.length);

    return (
      <>
        {left}
        <StyledHighlightSpan>
          {value.substring(index, index + filter.length)}
        </StyledHighlightSpan>
        <RecursiveHighlight value={right} filter={filter} />
      </>
    );
  }

  return <>{value}</>;
};

RecursiveHighlight.displayName = 'RecursiveHighlight';

const StyledHighlightSpan = styled.span`
  background: ${({ theme }) => theme.colors.palette.yellows[5]};
`;
