import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { AccountModel } from 'models';

import { AccountStatus } from '../constants';
import { AccountActions } from './actions';

export const accountAdapter = createEntityAdapter<AccountModel>({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...accountAdapter.getInitialState(),
  total: 0,
  status: AccountStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(
    AccountActions.getAllForKendoGrid.fulfilled,
    (draft, action) => {
      const payload = action.payload as {
        entities: { accounts: AccountModel[] };
        total: number;
        result: EntityId[];
      };

      accountAdapter.removeAll(draft);

      if (payload.entities.accounts) {
        accountAdapter.addMany(draft, payload.entities.accounts);
      }

      draft.total = payload.total;
      draft.ids = payload.result;
      draft.status = AccountStatus.FETCHED;
    },
  );
  builder.addCase(AccountActions.getById.fulfilled, (draft, action) => {
    const payload = action.payload as AccountModel;
    accountAdapter.upsertOne(draft, payload);
    draft.status = AccountStatus.FETCHED;
  });
  builder.addCase(AccountActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { accounts: AccountModel };
    accountAdapter.addOne(draft, payload.accounts);
    draft.status = AccountStatus.ADDED;
  });
  builder.addCase(AccountActions.edit.fulfilled, (draft, action) => {
    const payload = action.payload as { accounts: AccountModel };
    accountAdapter.setOne(draft, payload.accounts);
    draft.status = AccountStatus.UPDATED;
  });
  builder.addCase(AccountActions.getAll.fulfilled, (draft, action) => {
    const payload = action.payload as { accounts: AccountModel[] };
    accountAdapter.setMany(draft, payload.accounts);
    draft.status = AccountStatus.FETCHED;
  });
});
