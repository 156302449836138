import { FunctionComponent, useState } from 'react';

import {
  Drawer,
  DrawerContent,
  DrawerNavigation,
} from '@progress/kendo-react-layout';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { CollapsibleSidebarProps } from './CollapsibleSidebarProps';
import CollapseDrawerIcon from './assets/close-drawer-icon.svg?react';
import ExpandDrawerIcon from './assets/expand-drawer-icon.svg?react';

export const CollapsibleSidebar: FunctionComponent<CollapsibleSidebarProps> = ({
  body,
  children,
  expanded,
  header,
  width = 300,
  isExpandedByDefault = true,
  onExpandChange,
}) => {
  // if expanded prop is defined then read sidebar expand state from that prop -> controlled mode
  // if it is not defined that means that sidebar controls its own state from inside -> uncontrolled mode
  const [internalDrawerExpanded, setInternalDrawerExpanded] =
    useState(isExpandedByDefault);

  const handleClick = useEvent(() => {
    if (onExpandChange) {
      onExpandChange(!expanded);
    } else {
      setInternalDrawerExpanded((prev) => !prev);
    }
  });

  const isDrawerExpanded =
    expanded === undefined ? internalDrawerExpanded : expanded;

  return (
    <StyledDivSidebarWrapper>
      {!isDrawerExpanded && (
        <StyledDivDrawerExpandIcon>
          <ExpandDrawerIcon
            height={16}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            width={16}
          />
        </StyledDivDrawerExpandIcon>
      )}
      <StyledDrawer expanded={isDrawerExpanded} mode="push" width={width}>
        <StyledDrawerNavigation>
          <StyledDivHeaderWrapper>
            {header}
            <StyledDivCollapseButtonWrap>
              <StyledDivDrawerCollapseIcon>
                <CollapseDrawerIcon
                  height={18}
                  onClick={handleClick}
                  style={{ cursor: 'pointer' }}
                  width={18}
                />
              </StyledDivDrawerCollapseIcon>
            </StyledDivCollapseButtonWrap>
          </StyledDivHeaderWrapper>
          {body}
        </StyledDrawerNavigation>
        <StyledDrawerContent>{children}</StyledDrawerContent>
      </StyledDrawer>
    </StyledDivSidebarWrapper>
  );
};

CollapsibleSidebar.displayName = 'CollapsibleSidebar';

const StyledDivSidebarWrapper = styled.div`
  display: flex;
`;

const StyledDivDrawerExpandIcon = styled.div`
  background: ${({ theme }) => theme.colors.palette.blues[5]};
  border-radius: 0 4px 4px 0;
  display: inline-block;
  padding: 12px 7px;
  position: absolute;
  z-index: 10;
`;

const StyledDrawer = styled(Drawer)`
  flex: 1 1 0;
  align-items: stretch;
`;

const StyledDrawerNavigation = styled(DrawerNavigation)`
  background: ${({ theme }) => theme.colors.palette.grayscale[2]};
  border: none;
  height: 100vh;

  & .k-drawer-wrapper {
    background: ${({ theme }) => theme.colors.palette.white};
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const StyledDivHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDivCollapseButtonWrap = styled.div`
  padding-right: 20px;
`;

const StyledDivDrawerCollapseIcon = styled.div`
  display: inline-block;
`;

const StyledDrawerContent = styled(DrawerContent)`
  flex: 1 1 0;
  display: flex;
  position: relative;
`;
