import { SettingModel } from 'models';

import { equalsInsensitive, findOrThrow } from 'core/utils';

import { WellKnownSettingName } from '../types';

function findSettingByName(
  allSettings: SettingModel[],
  name: WellKnownSettingName | string,
  required: true,
): SettingModel;
function findSettingByName(
  allSettings: SettingModel[],
  name: WellKnownSettingName | string,
  required: false,
): SettingModel | null;
function findSettingByName(
  allSettings: SettingModel[],
  name: WellKnownSettingName | string,
  required: boolean,
): SettingModel | null {
  if (required) {
    const setting = findOrThrow(
      allSettings,
      (s) => equalsInsensitive(name, s.name),
      `Unable to find setting with name ${
        name === null
          ? 'null'
          : typeof name === 'undefined'
          ? 'undefined'
          : `"${name}"`
      }.`,
    );

    return setting;
  }

  return allSettings.find((s) => equalsInsensitive(name, s.name)) ?? null;
}

export const SettingsHelpers = {
  findSettingByName,
};
