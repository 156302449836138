import { GeneralTabFormValues, IntegrationTabFormValues } from './types';

export const UnitStatus = {
  PENDING: 'unit-pending',
  ADDED: 'unit-created',
  UPDATED: 'unit-updated',
  FETCHED: 'unit-fetched',
  ERROR: 'unit-error',
};

export const InitialValues = {
  nonFormFields: {
    id: 0,
    locationCode: 0,
    locationName: '',
  },
  generalForm: {
    serialNumber: '',
    unitPCN: 0,
    location: null,
    modelType: '',
    firmwareRevision: '',
    user: '',
    lastInDate: '',
    lastOutDate: '',
    expectedInDate: '',
    updated: '',
    pfwAssetNumber: '',
    active: true,
  } as GeneralTabFormValues,
  integrationForm: {} as IntegrationTabFormValues,
};
