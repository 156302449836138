import { memo, useCallback, useRef, useState } from 'react';

import { faRectangleVertical } from '@fortawesome/pro-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';

import { Icon } from '../../../Icon';
import { ActionIconProps } from './ActionIconProps';

export const ActionIcon = memo<ActionIconProps>(({ action, dataItem, dataItemIndex, dataItemKey, testingPrefix }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [transientTitle, setTransientTitleInner] = useState<string | null>(null);

  const deferredTooltipEnteredRef = useRef<null | (() => void)>(null);
  const deferredTooltipExitedRef = useRef<null | (() => void)>(null);

  const setTransientTitle = useCallback(
    (newTitle: string) => {
      if (showTooltip) {
        // If the tooltip is already being displayed then we want to animate the tooltip closed with the action.title displayed.  Then we re-open
        // it with the transient title visible.
        setShowTooltip(false);

        deferredTooltipExitedRef.current = () => {
          setShowTooltip(true);
          setTransientTitleInner(newTitle);
        };
      } else {
        // The tooltip is not currently being displayed.  So we can immediately display it.
        setShowTooltip(true);
        setTransientTitleInner(newTitle);
      }
    },
    [showTooltip],
  );

  const handleTooltipToggle = useEvent((nextShow: boolean) => {
    setShowTooltip(nextShow);

    if (!nextShow && transientTitle != null) {
      deferredTooltipEnteredRef.current = null;
      deferredTooltipExitedRef.current = () => {
        setTransientTitleInner(null);
      };
    }
  });

  const handleTooltipEntered = useEvent(() => {
    if (deferredTooltipEnteredRef.current) {
      const enteredHandler = deferredTooltipEnteredRef.current;
      deferredTooltipEnteredRef.current = null;
      enteredHandler();
    }
  });

  const handleTooltipExited = useEvent(() => {
    if (deferredTooltipExitedRef.current) {
      const exitedHandler = deferredTooltipExitedRef.current;
      deferredTooltipExitedRef.current = null;
      exitedHandler();
    }
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const effectiveTitle = transientTitle ?? typeof action.title === 'function' ? action.title(dataItem) : action.title;

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{effectiveTitle}</Tooltip>}
      show={showTooltip}
      onToggle={handleTooltipToggle}
      onEntered={handleTooltipEntered}
      onExited={handleTooltipExited}
      rootClose={transientTitle != null}
      trigger={transientTitle == null ? undefined : 'click'}
    >
      <StyledButton
        id={`${testingPrefix}_${dataItem[dataItemKey]}`}
        className={`${testingPrefix}_${action.key}`}
        data-key={dataItem[dataItemKey]}
        type="button"
        disabled={typeof action.disabled === 'function' ? action.disabled(dataItem) : action.disabled}
        onClick={action.onClick != null ? (event) => action.onClick?.(event, dataItem, dataItemIndex, setTransientTitle) : undefined}
      >
        <Icon icon={action.icon ?? faRectangleVertical} />
      </StyledButton>
    </OverlayTrigger>
  );
});

ActionIcon.displayName = 'ActionIcon';

const StyledButton = styled.button`
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.palette.blues[1] : theme.colors.palette.blues[5])};
  height: 20px;

  && {
    font-size: ${({ theme }) => theme.space.spacing30};
  }

  .k-master-row:hover & {
    background-color: inherit;
    color: #ffffff;
  }
`;
