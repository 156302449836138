const UTC_DATETIME_REGEX =
  /\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z\b/;

// helper method for JSON.parse which parses utc date strings into date objects,
// Kendo Filter throws error if it receives string instead of Date object
const dateTimeParser = (_: string, value: string | number) => {
  if (typeof value === 'string') {
    if (UTC_DATETIME_REGEX.test(value)) {
      return new Date(value);
    }
  }

  return value;
};
export const WorklistFilterParsingService = {
  dateTimeParser,
};
