import { forwardRef } from 'react';

import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { AutoCompleteHandle } from '../AutoComplete';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { AutoCompleteLine } from './AutoCompleteLine';
import { DelimitedTextAutoCompleteProps } from './DelimitedTextAutoCompleteProps';
import { DelimitedTextHandle } from './DelimitedTextHandle';
import { useDelimitedTextCommon } from './useDelimitedTextCommon';

export const DelimitedTextAutoComplete = forwardRef<DelimitedTextHandle, DelimitedTextAutoCompleteProps>(
  (
    { className, value, name, readOnly, disabled, addButtonTitle, label, autoCompleteData, valid, visited, onChange, onBlur, onFocus, buttonSlotRender },
    ref,
  ) => {
    const { textInputRefs, internalValues, handleAddClick, handleChange, handleDelete, handleNextLineRequested } = useDelimitedTextCommon<AutoCompleteHandle>(
      ref,
      value,
      onChange,
    );

    return (
      <div className={className}>
        {label != null && <Label>{label}</Label>}
        <StyledLineItemContainer>
          {internalValues?.map?.((internalValue, renderIndex) => (
            <AutoCompleteLine
              key={internalValue.key}
              ref={(node) => {
                if (node) {
                  textInputRefs.current.set(internalValue.key, node);
                } else {
                  textInputRefs.current.delete(internalValue.key);
                }
              }}
              namePrefix={name}
              value={internalValue.value}
              itemKey={internalValue.key}
              renderIndex={renderIndex}
              autoCompleteData={autoCompleteData}
              readOnly={readOnly}
              disabled={disabled}
              valid={valid}
              visited={visited}
              onChange={handleChange}
              onDelete={handleDelete}
              onBlur={onBlur}
              onFocus={onFocus}
              onEnterKeyDown={handleNextLineRequested}
            />
          ))}
        </StyledLineItemContainer>
        <StyledButtonContainer>
          <Button type="button" onClick={handleAddClick} title={addButtonTitle}>
            <StyledAddIcon icon={faPlus} fixedWidth />
          </Button>
          {buttonSlotRender?.(internalValues?.length ?? 0)}
        </StyledButtonContainer>
      </div>
    );
  },
);

DelimitedTextAutoComplete.displayName = 'DelimitedTextAutoComplete';

const StyledLineItemContainer = styled.div`
  display: grid;
  row-gap: 6px;
`;

const StyledAddIcon = styled(Icon)`
  &&& {
    margin-right: 0;
  }
`;

const StyledButtonContainer = styled.div`
  padding: 6px 0 0 0;
`;
