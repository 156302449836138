import { FunctionComponent } from 'react';

import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { AccordionNg, ChipList, Icon, Label } from 'core/ui';

import { useDrawerAnimation, useFiltersState } from '../hooks';
import { DesktopSideBarProps } from '../types';
import { DesktopAccordionItem } from './DesktopAccordionItem';

export const DesktopSideBar: FunctionComponent<DesktopSideBarProps> = ({
  className,
  studyTypes,
  organs,
  filter,
  exams,
  selectedFileId,
  show,
  onFilterChange,
  onFileClick,
}) => {
  const { studyTypeOptions, handleStudyTypeChipChange, organOptions, handleOrganChipChange } = useFiltersState(studyTypes, organs, filter, onFilterChange);

  const { drawerStyle, measureTargetRef } = useDrawerAnimation(show);

  return (
    <StyledExamsSidebar className={className} style={drawerStyle}>
      <StyledDesktopAccordionDiv ref={measureTargetRef}>
        <StyledFilterDiv className={className}>
          <StyledHeaderTitle>
            <Icon icon={faFilter} fixedWidth block />
            Filters
          </StyledHeaderTitle>

          <StyledBody>
            <StyledLabel>Study Types:</StyledLabel>
            <ChipList
              selection="multiple"
              data={studyTypeOptions}
              textField="name"
              valueField="value"
              value={filter.studyTypes}
              onChange={handleStudyTypeChipChange}
              allowOtherOption={false}
            />

            <StyledLabel>Organs:</StyledLabel>
            <ChipList
              selection="multiple"
              data={organOptions}
              textField="name"
              valueField="value"
              value={filter.organs}
              onChange={handleOrganChipChange}
              allowOtherOption={false}
            />
          </StyledBody>
        </StyledFilterDiv>

        <StyledAccordionScrollDiv mode="vertical">
          {exams.map((exam) => (
            <StyledAccordionItem key={exam.examId} exam={exam} selectedFileId={selectedFileId} onFileClick={onFileClick} />
          ))}
        </StyledAccordionScrollDiv>
      </StyledDesktopAccordionDiv>
    </StyledExamsSidebar>
  );
};

const StyledExamsSidebar = styled(animated.div)`
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: white;
  justify-content: end;
`;

const StyledDesktopAccordionDiv = styled(AccordionNg.AccordionDiv)`
  width: 300px;
  overflow: hidden;
  contain: strict;
  border-right: 1px solid ${({ theme }) => theme.colors.palette.grayscale[3]};
`;

const StyledFilterDiv = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  overflow: hidden;
  overflow: clip; // Progressive enhancement: overflow clip and overflow-clip-margin are not supported in all browsers yet (as of February 2024).
  overflow-clip-margin: 5px;
`;

const StyledHeaderTitle = styled.h3`
  flex: 1 1 0;
  align-self: center;
  text-align: start;
  padding: ${({ theme }) => theme.space.spacing20} 12px;
  user-select: none;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: ${({ theme }) => theme.lineHeights.heading2};

  .icon-container {
    margin-right: 8px;
  }
`;

const StyledBody = styled.div`
  display: grid;
  overflow: hidden;

  grid-template-columns: min-content 1fr;
  padding: 9px;
  row-gap: 20px;
  column-gap: 9px;
`;

const StyledLabel = styled(Label)`
  white-space: nowrap;
  user-select: none;

  && {
    margin: 0;
  }
`;

const StyledAccordionScrollDiv = styled(AccordionNg.AccordionScrollDiv)`
  flex: 1 1 0;
  padding: 6px;
  row-gap: 6px;

  && {
    overflow-y: auto;
  }
`;

const StyledAccordionItem = styled(DesktopAccordionItem)`
  flex: 0 0 min-content;
`;
