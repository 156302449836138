import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { State } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportData } from '@progress/kendo-react-excel-export';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LocationTypeModel } from 'models';

import { useEvent } from 'core/hooks';
import { Action, ActionListCell, DEFAULT_DATA_TABLE_DATA_STATE, DataTable, HeaderCell, Page, PageHeader, TextCell, Toolbar } from 'core/ui';

import { useAppDispatch } from '../../main/hooks';
import { LocationTypeActions, LocationTypeSelectors } from '../redux';

export const LocationTypeHome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const locationTypes = useSelector(LocationTypeSelectors.getAllGrid);
  const total = useSelector(LocationTypeSelectors.getTotalGrid);

  const gridPDFExport = useRef<GridPDFExport>(null);
  const gridExcelExport = useRef<ExcelExport>(null);

  const [dataState, setDataState] = useState<State>(DEFAULT_DATA_TABLE_DATA_STATE);

  const handleDataStateChange = (changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  };

  const debouncedChangeDataStateHandler = useMemo(() => debounce(handleDataStateChange, 500), []);

  useEffect(() => {
    return () => debouncedChangeDataStateHandler.cancel();
  }, [debouncedChangeDataStateHandler]);

  useEffect(() => {
    dispatch(LocationTypeActions.getAllForKendoGrid(dataState));
  }, [dispatch, dataState]);

  const handleExportPDFClick = () => {
    if (gridPDFExport.current !== null) {
      gridPDFExport.current.save();
    }
  };

  const handleExportExcelClick = async () => {
    if (gridExcelExport.current !== null) {
      const res = await dispatch(
        LocationTypeActions.getAllForExport({
          skip: 0,
          take: total,
          filter: dataState.filter,
        }),
      );

      const payload = res.payload as ExcelExportData;

      gridExcelExport.current.save(payload?.data);
    }
  };

  const handleAddNewClick = () => {
    navigate('/location-type/add');
  };

  const handleEditClick = useEvent((dataItem: LocationTypeModel) => navigate(`/location-type/edit/${dataItem.id}`));

  const gridActions: Action[] = useMemo(() => {
    return [
      {
        key: 'edit-location-type',
        title: 'Edit Location Type',
        icon: faPen,
        onClick: (_, dataItem) => handleEditClick(dataItem),
      },
    ];
  }, [handleEditClick]);

  const grid = (
    <DataTable
      filterable
      sortable
      reorderable
      pageable={{ pageSizes: true }}
      total={total}
      data={locationTypes}
      {...dataState}
      onDataStateChange={handleDataStateChange}
      actions={gridActions}
    >
      <Toolbar onAddNewClick={handleAddNewClick} onExportExcelClick={handleExportExcelClick} onExportPDFClick={handleExportPDFClick} />
      <GridColumn
        title="Action"
        filterable={false}
        sortable={false}
        headerCell={HeaderCell}
        cell={ActionListCell as ComponentType<GridCellProps>}
        width="80px"
      />
      <GridColumn cell={TextCell} field="name" filter="text" headerCell={HeaderCell} title="Name" width="350px" />
    </DataTable>
  );

  return (
    <Page>
      <PageHeader title="Location Types" />
      <GridPDFExport ref={gridPDFExport}>{grid}</GridPDFExport>
      <ExcelExport data={locationTypes} ref={gridExcelExport}>
        {grid}
      </ExcelExport>
    </Page>
  );
};
