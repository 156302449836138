import { ChangeEvent, memo, useRef } from 'react';

import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { Button } from 'core/ui';

import { AddAttachmentFilesCardProps } from '../types';

export const AddAttachmentFilesCard = memo<AddAttachmentFilesCardProps>(({ className, onAddAttachmentFiles }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileButtonClick = useEvent(() => {
    inputRef?.current?.click();
  });

  const handleFileInputChange = useEvent((event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files == null ? [] : Array.from(event.target.files);
    onAddAttachmentFiles(newFiles);
  });

  return (
    <StyledComponentDiv className={className}>
      <Button onClick={handleFileButtonClick}>Select Files</Button>
      <Button>Mobile QR Upload</Button>

      <StyledHiddenInput
        accept=".pdf,.png,.jpg,.jpeg,.tif,.tiff,.svs,.szi,.mp4"
        name="file"
        multiple
        onChange={handleFileInputChange}
        ref={inputRef}
        type="file"
      />
    </StyledComponentDiv>
  );

  /*return (
    <StyledWrapperDiv className={className}>
      <div>
        <UploadImage className="upload-icon" />
      </div>
      <div>Upload Additional Files To Selected Study</div>

      <StyledButton onClick={handleFileButtonClick} size={ComponentSizes.LARGE}>
        Click To Select Files
      </StyledButton>
      <StyledButton size={ComponentSizes.LARGE}>Mobile QR Upload</StyledButton>
      <StyledHiddenInput
        accept=".pdf,.png,.jpg,.jpeg,.tif,.tiff,.svs,.szi,.mp4"
        name="file"
        multiple
        onChange={handleFileInputChange}
        ref={inputRef}
        type="file"
      />
    </StyledWrapperDiv>
  );*/
});

AddAttachmentFilesCard.displayName = 'AddAttachmentFilesCard';

const StyledComponentDiv = styled.div`
  display: flex;
  overflow: hidden;
  column-gap: 8px;
`;

const StyledHiddenInput = styled.input`
  display: none;
`;

/*const StyledWrapperDiv = styled.div`
  background: ${({ theme }) => theme.colors.palette.blues[0]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.palette.blues[5]};
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: ${({ theme }) => theme.space.spacing80} ${({ theme }) => theme.space.spacing20};
  //width: 120px;
  //height: 150px;

  svg.upload-icon {
    width:;
  }
`;

const StyledButton = styled(Button)`
  &&& {
    font-size: 14px;
  }
`;*/
