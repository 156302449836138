import { LocationLiteModel, LocationModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class LocationClient {
  constructor(private httpClient: HttpClient) {
    this.getAllLocations = this.getAllLocations.bind(this);
    this.getLocationById = this.getLocationById.bind(this);
    this.createLocation = this.createLocation.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.getAllLocationsLite = this.getAllLocationsLite.bind(this);
  }

  public async getAllLocationsLite(authMode: 'msal-required' | 'share-required') {
    const options = await this.httpClient.createStandardOptions('GET', null, { authMode });
    const result = await this.httpClient.fetchAndParse<LocationLiteModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Locations/Lite`, options));
    return result;
  }

  public async getAllLocations() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchAndParse<LocationModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Locations`, options));
    return result;
  }

  public async getLocationById(locationId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const result = await this.httpClient.fetchAndParse<LocationModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Locations/${locationId}`, options),
    );
    return result;
  }

  public async createLocation(location: LocationModel) {
    const options = await this.httpClient.createStandardOptions('POST', location);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Locations`, options));
    return result;
  }

  public async updateLocation(location: LocationModel) {
    const options = await this.httpClient.createStandardOptions('PUT', location);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Locations`, options));
    return result;
  }
}
