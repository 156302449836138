import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { AccordionNg, useAccordionNgItem, useAccordionNgItemView, useBreakpoints } from 'core/ui';

import { useExamsFilesAccordionItem } from '../hooks';
import { ShareService } from '../services';
import { MobileAccordionItemProps } from '../types';
import { FileButton } from './FileButton';

export const MobileAccordionItem: FunctionComponent<MobileAccordionItemProps> = ({ className, exam, selectedFileId, onFileClick }) => {
  const { isExpanded, toggleExpanded } = useAccordionNgItem(exam.examId.toString());

  const { examFiles, examAttachments } = useExamsFilesAccordionItem(exam, selectedFileId);

  const { portrait } = useBreakpoints();

  const { accordionItemBodyRef, animationStyles, handleToggleClick } = useAccordionNgItemView(portrait ? 'horizontal' : 'vertical', isExpanded, toggleExpanded);

  return (
    <AccordionNg.ItemDiv className={className} mode={portrait ? 'horizontal' : 'vertical'}>
      <StyledHeaderButton
        onClick={handleToggleClick}
        className={`testing_${MobileAccordionItem.displayName}_expand-button`}
        data-exam-id={exam.examId}
        mode={portrait ? 'horizontal' : 'vertical'}
        preset="mobile"
      >
        <span className="study-type">{exam.studyType}</span>
        <span className="study-date">{ShareService.formatExamStudyDate(exam.studyDate)}</span>
        <span className="study-description">{exam.description}</span>
      </StyledHeaderButton>
      <AccordionNg.ItemBodyAnimationDiv style={animationStyles[0]} mode={portrait ? 'horizontal' : 'vertical'}>
        <StyledAccordionBody ref={accordionItemBodyRef} mode={portrait ? 'horizontal' : 'vertical'}>
          {examFiles.map((file) => (
            <FileButton key={file.id} file={file} selected={file.id === selectedFileId} onClick={onFileClick} />
          ))}
          {examAttachments.map((file) => (
            <FileButton key={file.id} file={file} selected={file.id === selectedFileId} onClick={onFileClick} />
          ))}
        </StyledAccordionBody>
      </AccordionNg.ItemBodyAnimationDiv>
    </AccordionNg.ItemDiv>
  );
};

MobileAccordionItem.displayName = 'MobileAccordionItem';

const StyledHeaderButton = styled(AccordionNg.ItemHeaderButton)`
  align-items: stretch;
  justify-content: start;
  row-gap: 16px;
  padding: 8px;
  overflow: hidden;

  .study-type {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .study-date {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .study-description {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.palette.grayscale[5]};
  }
`;

const StyledAccordionBody = styled(AccordionNg.ItemBodyDiv)`
  display: flex;
  overflow: hidden;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
`;
