import { MouseEventHandler, ReactNode, cloneElement } from 'react';

import { GridToolbar as KendoGridToolbar } from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonVariants } from '../../Button';

/* HACKS: This component has some hacks to workaround Kendo Grid quirks.  There's more information in the
 * comments inside the Toolbar class definition.  Basically we can't wrap the Kendo GridToolbar in a higher
 * order component without more drastic refactoring in the application.
 *
 * There is a new API (as of November 2023) called GridHelper that may be useful if the need arises to refactor
 * our Toolbar component structure.  Using this however will likely result in additional refactoring in the application.
 *
 * See: https://www.telerik.com/kendo-react-ui/components/grid/gridhelper/
 *
 */

declare module '@progress/kendo-react-grid' {
  interface GridToolbarProps {
    customElements?: ReactNode;
    onExportPDFClick?: MouseEventHandler<HTMLButtonElement>;
    onExportExcelClick?: MouseEventHandler<HTMLButtonElement>;
    onAddNewClick?: MouseEventHandler<HTMLButtonElement>;
  }
}

const propTypes = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/forbid-foreign-prop-types
  ...(KendoGridToolbar as unknown as any).propTypes, // Make sure to use the same propTypes as Kendo for the base properties.
  children: PropTypes.node,
  customElements: PropTypes.node,
  onExportPDFClick: PropTypes.func,
  onExportExcelClick: PropTypes.func,
  onAddNewClick: PropTypes.func,
};

const defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(KendoGridToolbar as unknown as any).defaultProps, // Make sure to use the same property defaults as Kendo for the base properties.
  children: null,
  customElements: undefined,
  onExportPDFClick: undefined,
  onExportExcelClick: undefined,
  onAddNewClick: undefined,
};

export class Toolbar extends KendoGridToolbar {
  /* !!IMPORTANT!!: There are some very unintuitive quirks with this component.  Simple and seemingly innocuous
   * changes will break things, or make the toolbar disappear entirely from the DOM.
   *
   * Here are the key hacks that have to be in place for this to
   * continue to work as expected:
   *  1) Needs to be a class-based component that inherits from the Kendo GridToolbar.
   *  2) Do NOT hydrate the "displayName" static property.  Leave it completely omitted.
   *
   */

  static propTypes = propTypes;

  static defaultProps = defaultProps;

  render() {
    const {
      customElements,
      onExportPDFClick,
      onExportExcelClick,
      onAddNewClick,
      children,
      ...rest
    } = this.props;

    return cloneElement(
      super.render(),
      rest,
      <StyledDivToolbarContainer>
        {customElements}
        {onExportPDFClick && (
          <Button onClick={onExportPDFClick} variant={ButtonVariants.SECONDARY}>
            Export as PDF
          </Button>
        )}
        {onExportExcelClick && (
          <Button
            onClick={onExportExcelClick}
            variant={ButtonVariants.SECONDARY}
          >
            Export as Excel
          </Button>
        )}
        {onAddNewClick && <Button onClick={onAddNewClick}>+ Add New</Button>}
        {children}
      </StyledDivToolbarContainer>,
    );
  }
}

const StyledDivToolbarContainer = styled.div`
  flex: 1;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.space.spacing20};
`;
