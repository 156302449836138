import { FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { LinkCellProps } from './LinkCellProps';

export const LinkCell: FunctionComponent<LinkCellProps> = ({ className, ellipsisPlacement = 'end', url, dataItem, field }) => {
  const dataItemField = field == null ? undefined : dataItem[field];

  const content = useMemo(
    () => (
      <StyledTdLink className={className} $ellipsisPlacement={ellipsisPlacement}>
        {dataItemField != null && (
          <a href={url} target="_blank" rel="noreferrer">
            {dataItemField}
          </a>
        )}
      </StyledTdLink>
    ),
    [className, dataItemField, ellipsisPlacement, url],
  );

  return content;
};

LinkCell.displayName = 'LinkCell';

const resolveTextDirection = ({ $ellipsisPlacement }: { $ellipsisPlacement: 'start' | 'end' }) => {
  if ($ellipsisPlacement === 'start') {
    return 'rtl';
  }

  return 'ltr';
};

const StyledTdLink = styled.td<{ $ellipsisPlacement: 'start' | 'end' }>`
  color: ${({ theme }) => theme.colors.secondary};
  direction: ${resolveTextDirection};

  & a:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
