import { memo } from 'react';

import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import _map from 'lodash/map';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { LocationLiteModel } from 'models';

import { useBoolean, useEvent } from 'core/hooks';

import { useAuthentication } from 'features/auth/hooks/useAuthentication';
import { useCurrentUser } from 'features/auth/hooks/useCurrentUser';
import { useRouteGuard } from 'features/auth/hooks/useRouteGuard';
import { useSessionLocation } from 'features/location/hooks/useSessionLocation';

import { Card } from '../Card';
import { DropdownButton } from '../DropdownButton';
import { DropdownNgChangeEvent, DropdownNgField } from '../DropdownNg';
import { Icon } from '../Icon';
import { HelpModal } from './HelpModal';
import { PageHeaderProps } from './PageHeaderProps';
import ProfileIcon from './assets/profile.svg';

const MENU_ITEMS = {
  logout: {
    disabled: false,
    label: 'Logout',
    value: 'logout',
  },
};
const MENU_ITEMS_ARRAY = _map(MENU_ITEMS);
const ALL_LOCATIONS_OPTION = { id: null, name: 'All locations' };

type FormValuesType = { location: LocationLiteModel | null };

export const PageHeader = memo<PageHeaderProps>(({ className, children, title, showSessionLocation = false }) => {
  const { currentUser } = useCurrentUser();
  const { logoutOidc } = useAuthentication();
  const { sessionLocation, sessionLocationOptions, setSessionLocation } = useSessionLocation();
  const { requireSessionLocation } = useRouteGuard();

  const [isHelpModalOpen, { setTrue: openHelpModal, setFalse: closeHelpModal }] = useBoolean(false);

  const rhfContext = useForm<FormValuesType>({
    defaultValues: { location: sessionLocation },
  });

  const handleSessionLocationChange = useEvent((event: DropdownNgChangeEvent) => {
    setSessionLocation(event.value as LocationLiteModel | null);
  });

  const handleItemClick = useEvent((event: DropDownButtonItemClickEvent) => {
    if (event.item.value === MENU_ITEMS.logout.value) {
      logoutOidc();
    }
  });

  return (
    <FormProvider {...rhfContext}>
      <StyledDivHeader className={className}>
        <HelpModal show={isHelpModalOpen} onClose={closeHelpModal} />
        <StyledDivLeftContent>
          <StyledCardTitle>
            <StyledCardHeaderTitle>{title ?? 'CompuMed'}</StyledCardHeaderTitle>
          </StyledCardTitle>
          {children}
        </StyledDivLeftContent>
        <StyledDivRightContent>
          {showSessionLocation && sessionLocationOptions != null && (
            <StyledLocationDiv>
              <StyledDropdownNgField
                name="location"
                label="Location"
                data={sessionLocationOptions}
                nullItem={requireSessionLocation && sessionLocation != null ? undefined : ALL_LOCATIONS_OPTION}
                textResolver={resolveLocationText as (dataItem: Record<string, unknown>) => string}
                filterDescriptorResolver={filterLocations}
                onChange={handleSessionLocationChange as (value: Record<string, unknown> | null) => void}
              />
            </StyledLocationDiv>
          )}

          {currentUser != null && currentUser !== 'anonymous' && (
            <StyledDropdownButton
              imageUrl={ProfileIcon}
              items={MENU_ITEMS_ARRAY}
              onItemClick={handleItemClick}
              text={`${currentUser.firstName} ${currentUser.lastName}`}
            />
          )}
          <StyledHelpButton type="button" onClick={openHelpModal}>
            <Icon icon={faCircleQuestion} fixedWidth block size="2x" />
          </StyledHelpButton>
        </StyledDivRightContent>
      </StyledDivHeader>
    </FormProvider>
  );
});

PageHeader.displayName = 'PageHeader';

function filterLocations(filterText: string): CompositeFilterDescriptor {
  return {
    logic: 'or',
    filters: [
      { field: 'code', operator: 'contains', value: filterText },
      { field: 'name', operator: 'contains', value: filterText },
    ],
  };
}

function resolveLocationText(location: LocationLiteModel): string {
  return location.id == null ? location.name : `${location.code} | ${location.name}`;
}

const StyledDivHeader = styled.div`
  display: grid;
  overflow: hidden;
  overflow: clip;
  overflow-clip-margin: 50px;
  justify-content: space-between;
  grid-template-columns: min-content min-content;
  grid-template-rows: 1fr;
  height: 43px;
`;

const StyledDivLeftContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.spacing30};
`;

const StyledDivRightContent = styled.div`
  display: grid;
  overflow: hidden;
  overflow: clip;
  overflow-clip-margin: 50px;
  grid-template-columns: repeat(3, min-content);
  align-items: center;
  column-gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledLocationDiv = styled.div`
  padding-right: ${({ theme }) => theme.space.spacing40};

  && label {
    margin-bottom: 0;
  }
`;

const StyledDropdownNgField = styled(DropdownNgField)`
  width: 200px;
  align-self: end;
`;

const StyledCardTitle = styled(Card)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: calc(${({ theme }) => theme.radii.base} * 2);
  color: ${({ theme }) => theme.colors.palette.white};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.heading2};
  padding: ${({ theme }) => theme.space.spacing40};
`;

const StyledCardHeaderTitle = styled(Card.Header)`
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  user-select: none;
  white-space: nowrap;
`;

const StyledDropdownButton = styled(DropdownButton)`
  flex: 0 0 min-content;
  height: 43px;

  & > .k-button {
    height: unset;
    padding: 0 ${({ theme }) => theme.space.spacing40};
  }
`;

const StyledHelpButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.palette.grayscale[7]};
  padding: 0 ${({ theme }) => theme.space.spacing20};
`;
