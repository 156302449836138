import { FunctionComponent, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserSettings } from 'features/settings/hooks';

import { AuthenticationScheme } from '../constants';
import { useAuthentication } from '../hooks';

export const OidcSignIn: FunctionComponent = () => {
  const { activeScheme, oidcScheme } = useAuthentication();

  const navigate = useNavigate();
  const { homePageUrl } = useUserSettings();

  useEffect(() => {
    if (activeScheme !== AuthenticationScheme.OIDC || homePageUrl == null) {
      return;
    }

    // TODO: It would be a better user experience to use the react-router "navigate" function here instead of performing
    // a full redirect with window.location.href.  However we have to support the legacy portal for now which prevents
    // us from doing this.
    window.location.href = oidcScheme.getRedirectState() ?? homePageUrl;
  }, [activeScheme, oidcScheme, homePageUrl, navigate]);

  return null;
};

OidcSignIn.displayName = 'OidcSignIn';
