import { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { WorklistViewAccessModel } from 'models';
import { WorkListViewUserGridModel } from 'models/WorkListViewUserGridModel';

import { DataResult } from 'core/api/types';
import { useEvent } from 'core/hooks';
import {
  Action,
  ActionListCell,
  ActionOnClickHandler,
  Button,
  DEFAULT_DATA_TABLE_DATA_STATE,
  DEFAULT_PAGE_SIZES,
  DataTable,
  DropdownFilterCell,
  HeaderCell,
  LookupCell,
  Page,
  PageHeader,
  TextCell,
  Toolbar,
} from 'core/ui';

import { apiClient } from 'features/api';

// set default page size of the grid.
const PAGEABLE_SETTINGS = { pageSizes: DEFAULT_PAGE_SIZES };

export const WorklistViewUserGridView: FunctionComponent = () => {
  const navigate = useNavigate();

  // State variables
  const [worklistViewUserGridviewData, setWorklistViewUserGridviewData] = useState<DataResult<WorkListViewUserGridModel> | null>(null);

  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  // set the event for data state change
  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  const [allAccessModes, setAllAccessModes] = useState<WorklistViewAccessModel[] | null>();

  // Event Handlers
  const handleEditWorkListViewUser: ActionOnClickHandler<WorkListViewUserGridModel> = useCallback(
    (_event, dataItem) => {
      navigate(`/worklist-view-user/edit/${dataItem.id}`);
    },
    [navigate],
  );

  // click event for add new button.
  const handleAddClick = useCallback(() => {
    navigate('/worklist-view-user/add');
  }, [navigate]);

  // set grid action
  const gridActions: Action[] = useMemo(() => {
    return [
      {
        key: 'edit-worklistviewuser',
        title: 'Edit WorkListViewUser',
        icon: faPen,
        onClick: handleEditWorkListViewUser,
      },
    ];
  }, [handleEditWorkListViewUser]);

  const accessRender = useCallback(
    (props: GridCellProps) => {
      if (allAccessModes == null) return null;

      return <LookupCell {...props} lookups={allAccessModes} lookupItemKey="id" textField="name" />;
    },
    [allAccessModes],
  );

  const initialize = useEvent(async () => {
    const fetchResults = await Promise.all([
      apiClient.workListViewClient.getAllWorklistViewAccess(),
      apiClient.workListViewClient.getAllWorkListUsersForKendoGrid(dataState),
    ]);

    setAllAccessModes(fetchResults[0]);
    setWorklistViewUserGridviewData(fetchResults[1]);
  });

  const refreshData = useEvent(async () => {
    const newData = await apiClient.workListViewClient.getAllWorkListUsersForKendoGrid(dataState);
    setWorklistViewUserGridviewData(newData);
  });

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    refreshData();
  }, [refreshData, dataState]);

  if (allAccessModes == null) return null;

  // display the grid
  return (
    <Page>
      <PageHeader title="Worklist Access" />
      <StyledDataTable
        data={worklistViewUserGridviewData}
        total={worklistViewUserGridviewData?.total ?? 0}
        pageable={PAGEABLE_SETTINGS}
        {...dataState}
        onDataStateChange={handleDataStateChange}
        filterable
        sortable
        reorderable
        actions={gridActions}
      >
        <Toolbar
          customElements={
            <StyledToolbarRightDiv>
              <StyledAddNewButton onClick={handleAddClick}>+ Add New</StyledAddNewButton>
            </StyledToolbarRightDiv>
          }
        />
        <GridColumn title="Action" headerCell={HeaderCell} cell={ActionListCell as ComponentType<GridCellProps>} width="50px" filterable={false} />
        <GridColumn title="ID" cell={TextCell} headerCell={HeaderCell} field="id" width="100px" filter="numeric" />
        <GridColumn title="Worklist" cell={TextCell} headerCell={HeaderCell} field="worklistViewName" width="300px" filterable filter="text" />
        <GridColumn
          title="Access"
          cell={accessRender}
          headerCell={HeaderCell}
          field="accessId"
          width="100px"
          filterCell={(props) => (
            <DropdownFilterCell
              {...props}
              dropdownData={allAccessModes.map((d) => ({
                name: d.name,
                value: d.id,
              }))}
              defaultItem={{ name: 'All', value: null }}
            />
          )}
        />
        <GridColumn title="User" cell={TextCell} headerCell={HeaderCell} field="user" filterable />
        <GridColumn title="Active" cell={TextCell} headerCell={HeaderCell} field="active" width="100px" filter="boolean" />
      </StyledDataTable>
    </Page>
  );
};

const StyledToolbarRightDiv = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const StyledDataTable = styled(DataTable)`
  &&& .k-grid-header th {
    text-align: left;
  }
`;

const StyledAddNewButton = styled(Button)``;

WorklistViewUserGridView.displayName = 'WorklistViewUserGridView';
