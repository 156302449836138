import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

/** Parse the current URL to retrieve the specified query parameter.  This will optionally decode using the native window.decodeURIComponent() function.  Will return undefined if the query parameter does not exist in the URL. */
export function useQueryParam(name: string, decodeValue: boolean) {
  const nameLower = name.toLowerCase();
  const [queryParams] = useSearchParams();

  const paramValue = useMemo(() => {
    for (const entry of queryParams.entries()) {
      if (entry[0].toLowerCase() === nameLower)
        return decodeValue ? decodeURIComponent(entry[1]) : entry[1];
    }

    return undefined;
  }, [queryParams, nameLower, decodeValue]);

  return paramValue;
}
