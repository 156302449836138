import { ItemModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class ItemClient {
  constructor(private httpClient: HttpClient) {
    this.getAllItems = this.getAllItems.bind(this);
  }

  public async getAllItems() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const items = await this.httpClient.fetchAndParse<ItemModel[]>(
      fetch(
        `${ApiRouteService.getCompumedApiBaseRoute()}/api/item/GetAllItems`,
        options,
      ),
    );
    return items;
  }
}
