import { memo } from 'react';

import styled from 'styled-components';

import { SettingsListProps } from '../types';
import { SettingsListItem } from './SettingsListItem';

export const SettingsList = memo<SettingsListProps>(
  ({
    className,
    definitions,
    selectedDefinition,
    localOverrides,
    onSettingClick,
  }) => {
    return (
      <StyledListDiv className={className}>
        <StyledListHeaderDiv>
          <div />
          <StyledListCol2Header>Setting</StyledListCol2Header>
          <StyledListCol3Header>Value</StyledListCol3Header>
        </StyledListHeaderDiv>
        <StyledListScrollDiv>
          {definitions.map((baseSetting, index) => (
            <SettingsListItem
              key={baseSetting.id}
              selectedSettingId={selectedDefinition?.id ?? null}
              overrides={localOverrides}
              definition={baseSetting}
              isAlternateRow={index % 2 === 0}
              onClick={onSettingClick}
            />
          ))}
        </StyledListScrollDiv>
      </StyledListDiv>
    );
  },
);

SettingsList.displayName = 'SettingsList';

const StyledListDiv = styled.div`
  flex: 0 0 min-content;
  display: grid;
  overflow: hidden;
  grid-template-columns: min-content 1fr 1fr;
  grid-template-rows: min-content 1fr;
  column-gap: 8px;
  background-color: white;
`;

const StyledListHeaderDiv = styled.div`
  grid-column: 1 / span 3;
  display: grid;
  overflow: hidden;
  grid-template-columns: subgrid;
  grid-template-rows: 32px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledListScrollDiv = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / span 3;
  grid-auto-rows: 32px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const StyledListCol2Header = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  user-select: none;
`;

const StyledListCol3Header = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: end;
  align-items: center;
  user-select: none;
  padding: 0 8px 0 0;
  scrollbar-gutter: stable;
`;
