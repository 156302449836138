import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { HL7SendModel } from 'models/HL7SendModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class HL7SendClient {
  constructor(private httpClient: HttpClient) {
    this.getAllSourcesForKendoGrid = this.getAllSourcesForKendoGrid.bind(this);
  }

  public async getAllSourcesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<HL7SendModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/hl7send/grid?${queryStr}`, options),
    );
    return result;
  }
}
