export enum RouteGuardErrorCode {
  /** Indicates that the current active authentication scheme is SHARE, but the current route contains a :linkId parameter that does not correspond to the issued share JWT. */
  ShareLinkIdMismatch = 'ShareLinkIdMismatch',
  /** Indicates that the current active authentication scheme is not allowed to access the current route. */
  SchemeNotAllowed = 'SchemeNotAllowed',
  /** Indicates that the current user does not have the required role assignment to access the route. */
  MissingRequiredRole = 'MissingRequiredRole',
  /** Indicates that the current route requires a system administrator, but the ADUser.IsSystemAdmin flag is not set to `true`. */
  RequiresSystemAdmin = 'RequiresSystemAdmin',
  /** Indicates that the current route requires that a location be selected for the session. */
  RequiresSessionLocation = 'RequiresSessionLocation',
}
