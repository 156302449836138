import { ContactModel } from 'models';

import { ApiRouteService } from '../services';
import { HttpClient } from './HttpClient';

export class ContactClient {
  constructor(private httpClient: HttpClient) {
    this.getAll = this.getAll.bind(this);
  }

  public async getAll() {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const contacts = await this.httpClient.fetchAndParse<ContactModel[]>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/Contact`, options));
    return contacts;
  }
}
