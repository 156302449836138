import { memo, useEffect, useMemo, useRef, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { isEqual, sortBy, uniq } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ChangeExamStatusModel, ExamModel, WorklistViewModel } from 'models';

import { DataResult } from 'core/api';
import { useAsyncCallback, useDebounceEmitter, useEvent, useInterval } from 'core/hooks';
import { NotificationsService } from 'core/notifications';
import { Page, PageHeader, SelectedState } from 'core/ui';
import { downloadBlobAsFile, hasText } from 'core/utils';

import { apiClient } from 'features/api';
import { useUserSettings } from 'features/settings';
import { WorklistSidebar } from 'features/worklist';
import { WorklistSidebarCollapsed } from 'features/worklist/fragments/WorklistSidebarCollapsed';

import { ExamStatus } from '../constants';
import { ExamsGridService } from '../services';
import { BaseExamGridPageProps, ColumnState, ExamGridColumnsStateChangeEvent, ExamGridDataStateChangeEvent } from '../types';
import { ExamGrid } from './ExamGrid';

const AUTO_REFRESH_INTERVAL = 60 * 1000; // 60 sec

export const BaseExamGridPage = memo<BaseExamGridPageProps>(({ featureView, title, selectable = true, showExamToolbar = false, pickDefaultWorklist }) => {
  const navigate = useNavigate();
  const { reactPatientFormPage, legacyBaseUrl } = useUserSettings(true);

  const gridExcelExport = useRef<ExcelExport | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [exams, setExams] = useState<null | DataResult<ExamModel>>(null);
  const [selectedExams, setSelectedExams] = useState<Record<number, boolean>>({});
  const [allWorklists, setAllWorklists] = useState<WorklistViewModel[] | null>(null);
  const [selectedWorklistId, setSelectedWorklistId] = useState<number | null>(null);
  const [showWorklistSidebar, setShowWorklistSidebar] = useState(true);
  const [dataState, setDataState] = useState<State | null>(null);
  const [columnsState, setColumnsState] = useState<Record<string, ColumnState> | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [allCounts, setAllCounts] = useState<Record<string, number>>({});

  const [fetchExams] = useAsyncCallback(async (signal, showLoadingSpinner: boolean, dataStateOverride?: State) => {
    if (dataStateOverride == null && dataState == null) {
      throw new Error('Cannot fetch exams because the dataState is null and a dataStateOverride was not specified.');
    }

    const queryDataState = hasText(searchValue)
      ? ExamsGridService.getQueryDataState(dataStateOverride ?? dataState!, searchValue) // Non-null assertion because TS doesn't seem to be able to infer that both dataStateOverride and dataState cannot both be null.
      : dataStateOverride ?? dataState!;

    try {
      if (showLoadingSpinner) {
        setIsLoading(true);
      }

      const newExams = await apiClient.exams.getAllForKendoGrid(queryDataState, signal);

      setExams(newExams);
    } finally {
      if (showLoadingSpinner) {
        setIsLoading(false);
      }
    }
  });

  const [fetchCounts] = useAsyncCallback(async (signal, clearExistingCounts: boolean, worklistIdsOverride?: number[]) => {
    if (clearExistingCounts) setAllCounts({});

    const worklistIds = worklistIdsOverride ?? allWorklists?.map((w) => w.id) ?? [];

    if (worklistIds.length === 0) {
      setAllCounts({});
      return {};
    }

    const results = await apiClient.workListViewClient.getExamStatusCount(worklistIds, signal);
    setAllCounts(results);
    return results;
  });

  const initialize = useEvent(async () => {
    const newWorklists = await apiClient.workListViewClient.getViews();

    const { newSelectedWorklist, newDataState, newColumnsState, newSearchValue } = ExamsGridService.initializeExamGridState(newWorklists, pickDefaultWorklist);

    setAllWorklists(newWorklists);
    setSelectedWorklistId(newSelectedWorklist?.id ?? null);
    setDataState(newDataState);
    setColumnsState(newColumnsState);
    setSearchValue(newSearchValue);

    // Immediately start fetching exams and counts.  We do not need to await for them to complete.
    fetchExams(true, newDataState);
    fetchCounts(
      true,
      newWorklists.map((w) => w.id),
    );
  });

  const { emitDebounce, clearDebounce } = useDebounceEmitter(() => {
    fetchExams(true);
  }, 500);

  const handleReadClick = useEvent(async (dataItem: ExamModel) => {
    // open exam edit in new window for dual screen work and to not lose grid state
    window.open(`/exam/${dataItem.id}/read`, 'ReadExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleAddNewClick = useEvent(() => {
    // open exam add in new window for dual screen work and to not lose grid state
    window.open(`/exam/add`, 'AddExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleEditClick = useEvent((dataItem: ExamModel) => {
    // open exam edit in new window for dual screen work and to not lose grid state
    window.open(`/exam/${dataItem.id}/edit`, 'EditExam', 'menubar=0,status=0,fullscreen=1');
  });

  const handleReadRequestClick = useEvent((dataItem: ExamModel) => {
    if (featureView === 'Exam') {
      navigate(`/exam/${dataItem.id}/read-request`);
    } else {
      throw new Error(`handleReadRequestClick() is not implemented for featureView: "${featureView as string}".`);
    }
  });

  const handleManagePatientClick = useEvent((dataItem: ExamModel) => {
    if (reactPatientFormPage) {
      navigate(`/patient-2/edit/${dataItem.patientId}`);
    } else {
      window.location.href = `${legacyBaseUrl}/patient/edit/${dataItem.patientId}`;
    }
  });

  const handleRowDoubleClick = useEvent((dataItem: ExamModel) => {
    if (featureView === 'Exam') {
      handleEditClick(dataItem);
    } else if (featureView === 'Physician') {
      handleReadClick(dataItem);
    } else {
      throw new Error(`handleRowDoubleClick() is not implemented for featureView: "${featureView as string}".`);
    }
  });

  const handleDataStateChange = useEvent((event: ExamGridDataStateChangeEvent) => {
    setDataState(event.dataState);

    if (event.refreshExpectation === 'debounce') {
      emitDebounce();
    } else if (event.refreshExpectation === 'now') {
      clearDebounce();
      fetchExams(true, event.dataState);
    }

    if (['browser-only', 'browser-and-worklist'].includes(event.saveExpectation)) {
      ExamsGridService.saveExamGridState({
        worklistViewId: selectedWorklistId,
        dataState: event.dataState,
        columnsState,
        search: hasText(searchValue) ? searchValue.trim() : null,
      });
    }

    // TODO: Implement this.
    if (event.saveExpectation === 'browser-and-worklist') {
      throw new Error('TODO: Implement saving data state to worklist.');
    }
  });

  const handleColumnsStateChange = useEvent(async (event: ExamGridColumnsStateChangeEvent) => {
    setColumnsState(event.columnsState);

    if (['browser-only', 'browser-and-worklist'].includes(event.saveExpectation)) {
      ExamsGridService.saveExamGridState({
        worklistViewId: selectedWorklistId,
        dataState,
        columnsState: event.columnsState,
        search: hasText(searchValue) ? searchValue.trim() : null,
      });
    }

    // TODO: Implement this.
    if (event.saveExpectation === 'browser-and-worklist') {
      throw new Error('TODO: Implement saving columns state to worklist.');
    }
  });

  const handleExportCSVClick = useEvent(async () => {
    if (dataState == null) {
      throw new Error('Cannot export CSV because the dataState is null.');
    }

    const csvBlob = await apiClient.exams.exportExamsForKendoGrid(dataState, ExamStatus.ALL.name);
    downloadBlobAsFile(csvBlob, 'csv', 'Exams');
  });

  const handleBulkCancel = useEvent(async (data: SelectedState, cancelReason: string | null) => {
    const changes: ChangeExamStatusModel[] = Object.keys(data)
      .filter((key) => data[key])
      .map((key) => ({
        id: parseInt(key, 10),
        status: null,
        cancelReason: hasText(cancelReason) ? cancelReason.trim() : null,
      }));

    await apiClient.exams.bulkCancel(changes);

    NotificationsService.displaySuccess(`${changes.length === 1 ? 'Exam' : 'Exams'} cancelled successfully.`);
    clearDebounce();
    fetchExams(true);
    fetchCounts(false);
  });

  const handleBulkAssignSubmit = useEvent(async (physicianId: number) => {
    const examIds = Object.entries(selectedExams)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_key, isSelected]) => isSelected)
      .map(([key]) => parseInt(key, 10));

    await apiClient.exams.bulkAssignDoctor(examIds, physicianId);
    NotificationsService.displaySuccess(`${examIds.length === 1 ? 'Exam' : 'Exams'} assigned successfully.`);

    clearDebounce();
    fetchExams(true);
    fetchCounts(false);
  });

  const handleChangeStatusSubmit = useEvent(async (change: ChangeExamStatusModel) => {
    await apiClient.exams.changeStatus(change);

    NotificationsService.displaySuccess('Exam status changed successfully.');
    clearDebounce();
    fetchExams(true);
    fetchCounts(false);
  });

  const handleSearchSubmit = useEvent(async () => {
    ExamsGridService.saveExamGridState({
      worklistViewId: selectedWorklistId,
      dataState,
      columnsState,
      search: hasText(searchValue) ? searchValue.trim() : null,
    });

    clearDebounce();
    fetchExams(true);
  });

  const handleWorklistsRefreshClick = useEvent(async () => {
    // For better responsiveness we are going to immediately update the worklist counts, but we also need to refresh the lists of worklists in case a different user added one.
    // We do not need to await for the fetchCounts() to complete to go ahead and fetch the new worklists because they are independent operations.
    fetchCounts(true);

    const newWorklists = await apiClient.workListViewClient.getViews();

    setAllWorklists(newWorklists);

    // Check if any worklists were added or removed.  Because we will have to refresh the counts an additional time to make sure we have counts for the new worklists.
    const oldWorklistIds = sortBy(uniq(allWorklists?.map((w) => w.id) ?? []));
    const newWorklistIds = sortBy(uniq(newWorklists.map((w) => w.id)));

    if (!isEqual(oldWorklistIds, newWorklistIds)) {
      fetchCounts(
        true,
        newWorklists.map((w) => w.id),
      );
    }
  });

  const handleSelectedWorklistIdChange = useEvent((worklistId: number | null) => {
    if (allWorklists == null) throw new Error('Cannot change selected worklist because the array of worklists is null or undefined.');

    const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(worklistId, allWorklists, dataState);

    setDataState(newDataState);
    setColumnsState(newColumnsState);
    setSearchValue(newSearchValue);
    setSelectedWorklistId(worklistId);
    setSelectedExams({});

    clearDebounce();
    fetchExams(true, newDataState);
  });

  const handleCreateWorklist = useEvent(async (model: WorklistViewModel) => {
    if (allWorklists == null) return; // Don't do anything if the worklists haven't been loaded yet.

    const newWorklist = await apiClient.workListViewClient.createView(model);

    const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(newWorklist.id, [newWorklist], null);

    setSelectedWorklistId(newWorklist.id);
    setDataState(newDataState);
    setColumnsState(newColumnsState);
    setSearchValue(newSearchValue);

    clearDebounce();
    fetchCounts(false, [...allWorklists.map((w) => w.id), newWorklist.id]);
    fetchExams(true, newDataState); // We don't need to await for the fetch to complete because refreshing the list of worklists and performing the grid query are independent operations.

    // Refresh the list of worklists.
    const newWorklists = await apiClient.workListViewClient.getViews();
    setAllWorklists(newWorklists);
  });

  const handleUpdateWorklist = useEvent(async (worklists: WorklistViewModel[]) => {
    await Promise.all(worklists.map((w) => apiClient.workListViewClient.updateView(w)));

    // Refresh the exams grid if the selected worklist was modified.
    const selectedWorklist = worklists.find((w) => w.id === selectedWorklistId);

    if (selectedWorklist != null) {
      const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(selectedWorklist.id, [selectedWorklist], null);

      setDataState(newDataState);
      setColumnsState(newColumnsState);
      setSearchValue(newSearchValue);

      clearDebounce();
      fetchExams(true, newDataState); // We don't need to await for the fetch to complete because refreshing the list of worklists and performing the grid query are independent operations.
    }

    // Refresh the worklists.
    const newWorklists = await apiClient.workListViewClient.getViews();

    setAllWorklists(newWorklists);

    fetchCounts(
      false,
      newWorklists.map((w) => w.id),
    ); // We don't need to await for the fetch counts to complete.
  });

  const handleDeleteWorklist = useEvent(async (worklist: WorklistViewModel) => {
    await apiClient.workListViewClient.deleteView(worklist.id);

    if (selectedWorklistId === worklist.id) {
      // The user deleted the currently selected worklist.  Reset the grid state.
      const { newDataState, newColumnsState, newSearchValue } = ExamsGridService.updateStateFromWorklist(null, [], null);

      ExamsGridService.saveExamGridState(null);
      setSelectedWorklistId(null);
      setDataState(newDataState);
      setColumnsState(newColumnsState);
      setSearchValue(newSearchValue);

      clearDebounce();
      fetchExams(true, newDataState); // We don't need to await for the fetch to complete because refreshing the list of worklists and performing the grid query are independent operations.
    }

    setAllWorklists((prev) => prev?.filter((w) => w.id !== worklist.id) ?? []);
  });

  const SlotToolbarPrefix = useMemo(() => <WorklistSidebarCollapsed handleShowSidebar={() => setShowWorklistSidebar(true)} />, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useInterval(() => {
    clearDebounce();
    fetchExams(false);
    fetchCounts(false);
  }, AUTO_REFRESH_INTERVAL);

  if (dataState == null || columnsState == null) {
    return null;
  }

  return (
    <Page>
      <PageHeader title={title} />
      <StyledPageContentDiv>
        <WorklistSidebar
          autoRefreshInterval={AUTO_REFRESH_INTERVAL}
          allWorklists={allWorklists}
          allCounts={allCounts}
          showSidebar={showWorklistSidebar}
          selectedWorklistId={selectedWorklistId}
          currentDataState={dataState}
          currentColumnsState={columnsState}
          onShowSidebarChange={setShowWorklistSidebar}
          onSelectedWorklistIdChange={handleSelectedWorklistIdChange}
          onRefreshClick={handleWorklistsRefreshClick}
          onCreateWorklist={handleCreateWorklist}
          onUpdateWorklist={handleUpdateWorklist}
          onDeleteWorklist={handleDeleteWorklist}
        />
        <ExcelExport data={exams?.data} ref={gridExcelExport} />
        <ExamGrid
          featureView={featureView}
          columnsState={columnsState}
          dataState={dataState}
          data={exams}
          showActionColumn
          showExamToolbar={showExamToolbar}
          isLoading={isLoading}
          slotToolbarPrefix={showWorklistSidebar ? undefined : SlotToolbarPrefix}
          selectable={selectable}
          selectedExams={selectedExams}
          filterSearch={searchValue}
          onExamReadClick={handleReadClick}
          onDataStateChange={handleDataStateChange}
          onColumnsStateChange={handleColumnsStateChange}
          onAddNewClick={handleAddNewClick}
          onSelectedExamsChange={setSelectedExams}
          onExamEditClick={handleEditClick}
          onExamReadRequestClick={handleReadRequestClick}
          onExamManagePatientClick={handleManagePatientClick}
          onExamDoubleClick={handleRowDoubleClick}
          onExportCsvClick={handleExportCSVClick}
          onBulkCancelSubmit={handleBulkCancel}
          onBulkChangeStatusSubmit={handleChangeStatusSubmit}
          onBulkAssignSubmit={handleBulkAssignSubmit}
          onFilterSearchChange={setSearchValue}
          onFilterSearchSubmit={handleSearchSubmit}
        />
      </StyledPageContentDiv>
    </Page>
  );
});

BaseExamGridPage.displayName = 'BaseExamGridPage';

const StyledPageContentDiv = styled.div`
  display: flex;
  overflow: hidden;
`;
