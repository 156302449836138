import { forwardRef, memo, useCallback } from 'react';

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { AutoComplete, AutoCompleteHandle } from '../AutoComplete';
import { Icon } from '../Icon';
import { AutoCompleteLineProps } from './AutoCompleteLineProps';

export const AutoCompleteLine = memo(
  forwardRef<AutoCompleteHandle, AutoCompleteLineProps>(
    (
      {
        value,
        itemKey,
        renderIndex,
        disabled,
        autoCompleteData,
        valid,
        visited,
        onChange,
        onDelete,
        onBlur,
        onFocus,
        onEnterKeyDown,
      },
      ref,
    ) => {
      const handleDeleteClick = useCallback(() => {
        onDelete(itemKey);
      }, [itemKey, onDelete]);

      const handleChange = useCallback(
        (newValue: string) => {
          onChange(newValue, itemKey);
        },
        [itemKey, onChange],
      );

      const handleEnterKeyDown = useCallback(
        (caretIndex: number | null) => {
          onEnterKeyDown(caretIndex, itemKey);
        },
        [itemKey, onEnterKeyDown],
      );

      return (
        <StyledLine>
          <AutoComplete
            ref={ref}
            value={value}
            autoCompleteData={autoCompleteData}
            valid={valid}
            visited={visited}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onEnterKeyDown={handleEnterKeyDown}
          />
          <StyledCloseButton
            className={`testing_${AutoCompleteLine.displayName}_delete-button`}
            data-line-item-index={renderIndex}
            type="button"
            onClick={handleDeleteClick}
            disabled={disabled}
            title="Remove"
            tabIndex={-1}
          >
            <Icon icon={faCircleXmark} />
          </StyledCloseButton>
        </StyledLine>
      );
    },
  ),
);

AutoCompleteLine.displayName = 'AutoCompleteLine';

const StyledLine = styled.div`
  display: flex;
`;

const StyledCloseButton = styled.button`
  all: unset;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 7px;
`;
