import { FunctionComponent, useCallback, useMemo } from 'react';

import { FieldWrapper } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import {
  Upload as KendoUpload,
  UploadFileRestrictions,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import styled from 'styled-components';

import { Hint } from '../Hint';
import { Label } from '../Label';
import { FileList } from './FileList';
import { UploadProps } from './UploadProps';

export const Upload: FunctionComponent<UploadProps> = ({
  className,
  description,
  disabled = false,
  hidden = false,
  hint,
  isLabeledAsOptional = false,
  label,
  name,
  onChange,
  required = false,
  valid = false,
  validationMessage,
  value,
  visited = false,
  allowedExtensions = '.jpg .png .svg',
  ...rest
}) => {
  const isValidationMessageShown = Boolean(visited && validationMessage);
  const isHintShown = !isValidationMessageShown && hint;
  const hintId = isHintShown ? `${name}_hint` : '';
  const errorId = isValidationMessageShown ? `${name}_error` : '';

  const handleAdd = useCallback(
    (event: UploadOnAddEvent) => {
      onChange({
        value: event.newState,
      });
    },
    [onChange],
  );

  const handleRemove = useCallback(
    (event: UploadOnRemoveEvent) => {
      onChange({
        value: event.newState,
      });
    },
    [onChange],
  );

  const uploadRestrictions: UploadFileRestrictions = useMemo(
    () => ({
      allowedExtensions: allowedExtensions.split(' '),
    }),
    [allowedExtensions],
  );

  return (
    <StyledFieldWrapper className={className}>
      {label && (
        <Label
          description={description}
          editorId={name}
          editorValid={valid}
          editorDisabled={disabled}
          required={required}
          optional={isLabeledAsOptional}
        >
          {label}
        </Label>
      )}
      <StyledKendoUpload
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={name}
        autoUpload={false}
        disabled={disabled}
        files={value}
        $hidden={hidden}
        onAdd={handleAdd}
        onRemove={handleRemove}
        restrictions={uploadRestrictions}
        showActionButtons={false}
        listItemUI={FileList}
        {...rest}
      />
      {isHintShown && <Hint id={hintId}>{hint}</Hint>}
      {isValidationMessageShown && (
        <Error id={errorId}>{validationMessage}</Error>
      )}
      {!isValidationMessageShown && <StyledSpacer />}
    </StyledFieldWrapper>
  );
};

Upload.displayName = 'Upload';

const StyledFieldWrapper = styled(FieldWrapper)`
  &.k-form-field {
    margin-top: 0;
  }
`;

const StyledKendoUpload = styled(KendoUpload)<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};

  // Hide the progress bar because we don't use the Upload component directly to transfer files to the API.
  .k-progressbar {
    display: none;
  }
`;

const StyledSpacer = styled.div`
  height: 17px;
`;
