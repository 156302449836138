import { useContext } from 'react';

import { CurrentUserContext } from '../contexts';
import { UseCurrentUserRequiredResult, UseCurrentUserResult } from '../types';

/** Get the currently logged in user.  Optionally can pass `true` as the parameter which will perform the necessary type checks to ensure that the returned `currentUser` property will contain a valid UserModel object. */
function useCurrentUser(required: true): UseCurrentUserRequiredResult;
function useCurrentUser(required?: false): UseCurrentUserResult;
function useCurrentUser(required: boolean): UseCurrentUserResult;
function useCurrentUser(required = false): UseCurrentUserResult {
  const context = useContext(CurrentUserContext);

  if (required && context.user === 'anonymous')
    throw new Error('Anonymous user.');

  if (required && context.user == null)
    throw new Error('Current user has not yet been retrieved.');

  return {
    currentUser: context.user,
    fetchCurrentUser: context.fetchProfile,
    setCurrentUser: context.setUser,
  };
}

export { useCurrentUser };
