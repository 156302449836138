import styled from 'styled-components';

import { TagCell } from 'core/ui';

export const StyledTATPercentageCell = styled(TagCell)`
  .k-chip {
    border-radius: 10px;
    border-width: 0px;
    ${({ theme, dataItem }) => {
      if (dataItem.tatPercent >= 51 && dataItem.tatPercent <= 80) {
        return `background-color: ${theme.colors.TATLow}; color: #fff`;
      }

      if (dataItem.tatPercent >= 81 && dataItem.tatPercent <= 100) {
        return `background-color: ${theme.colors.TATMedium}; color: #fff`;
      }

      if (dataItem.tatPercent > 100) {
        return `background-color: ${theme.colors.TATHigh}; color: #fff`;
      }

      return 'display: none;';
    }};
  }
`;
