import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { HomeActions } from '../redux';

export const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HomeActions.getMessage());
  }, [dispatch]);

  return <div>Welcome to CompuMed</div>;
};
