import { createAction } from '@reduxjs/toolkit';

import { UserModel } from 'models';

const storeUserInfo = createAction<{
  User: UserModel;
  UserRole: string | null;
  IsPricingAdmin: boolean;
  IsSystemAdmin: boolean;
}>('auth/store-user-info');

export const AuthActions = {
  storeUserInfo,
};
