import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { ServiceModel } from 'models';

import { ServiceActions } from './actions';

export const serviceAdapter = createEntityAdapter<ServiceModel>();

export const reducer = createReducer(
  serviceAdapter.getInitialState(),
  (builder) => {
    builder.addCase(ServiceActions.getAll.fulfilled, (state, action) => {
      const payload = action.payload as { services: ServiceModel[] };
      serviceAdapter.upsertMany(state, payload.services);
    });
  },
);
