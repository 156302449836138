import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { LocationRoutes } from '../routes';
import { LocationForm } from './LocationForm';
import { LocationHome } from './LocationHome';

const LOCATION_ADMIN_ROLES = [UserRoles.ADMIN];

export const Location = () => {
  return (
    <Routes>
      <Route
        path={LocationRoutes.HOME}
        element={
          <RouteGuard allowedRoles={LOCATION_ADMIN_ROLES}>
            <LocationHome />
          </RouteGuard>
        }
      />
      <Route
        path={LocationRoutes.ADD}
        element={
          <RouteGuard allowedRoles={LOCATION_ADMIN_ROLES}>
            <LocationForm />
          </RouteGuard>
        }
      />
      <Route
        path={LocationRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={LOCATION_ADMIN_ROLES}>
            <LocationForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};
