import { FunctionComponent, useCallback } from 'react';

import {
  Field,
  FieldContainer,
  Form,
  GridColumn,
  ValidatorBag,
  combineValidators,
  createStyledFormElement,
  maxLength,
  required,
} from 'core/forms';
import { Button, Input, Label, Switch } from 'core/ui';

import { GeneralTabContentProps, GeneralTabFormValues } from '../types';

const StyledFormElement = createStyledFormElement('min-content 400px');

const validators: ValidatorBag = {
  name: combineValidators([required, maxLength(255)]),
};

const GeneralTabContentInner: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof GeneralTabFormValues) => any;
  onChange: (values: GeneralTabFormValues) => void;
}> = ({ onChange, valueGetter }) => {
  const handleFormChange = useCallback(() => {
    const newValues: GeneralTabFormValues = {
      name: valueGetter('name'),
      active: valueGetter('active'),
    };
    onChange(newValues);
  }, [valueGetter, onChange]);

  return (
    <StyledFormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="name">Name</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            required
            name="name"
            editorId="name"
            component={Input}
            validator={validators.name}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1" isLabelColumn>
        <Label editorId="active">Active</Label>
      </GridColumn>
      <GridColumn columnStart="2">
        <FieldContainer $hideLabel>
          <Field
            name="active"
            editorId="active"
            component={Switch}
            onChange={handleFormChange}
          />
        </FieldContainer>
      </GridColumn>

      <GridColumn columnStart="1">
        <div>
          <Button type="submit">Save</Button>
        </div>
      </GridColumn>
    </StyledFormElement>
  );
};

GeneralTabContentInner.displayName = 'GeneralTabContentInner';

export const GeneralTabContent: FunctionComponent<GeneralTabContentProps> = ({
  initialValues,
  onSubmit,
  onChange,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      ignoreModified
      render={({ valueGetter }) => (
        <GeneralTabContentInner onChange={onChange} valueGetter={valueGetter} />
      )}
    />
  );
};

GeneralTabContent.displayName = 'GeneralTabContent';
