import { memo, useEffect, useState } from 'react';

import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import styled from 'styled-components';

import { ExamStatusTransitionModel, ExamStatusTrigger } from 'models';

import { useEvent } from 'core/hooks';
import { Button, DropdownWithValuesField, Modal, TextArea } from 'core/ui';
import { hasText } from 'core/utils';

import { apiClient } from 'features/api';

import { ContextMenuItems } from '../constants';
import { ExamStatusService } from '../services';
import { ChangeStatusModalProps, ExamStatusState } from '../types';

type StatusDropdownOptionType = ExamStatusTransitionModel | (Omit<ExamStatusTransitionModel, 'value'> & { value: 'active' });

const ACTIVE_OPTION: StatusDropdownOptionType = {
  value: 'active',
  name: 'Active',
  warning: null,
};

export const ChangeStatusModal = memo<ChangeStatusModalProps>(({ show, examId, onClose, statusChangeOption, onSubmit }) => {
  const [selectedStatus, setSelectedStatus] = useState<ExamStatusTrigger | 'active' | null>(null);
  const [cancelReason, setCancelReason] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState<StatusDropdownOptionType[]>([]);

  const handleStatusChange = useEvent((event: DropDownListChangeEvent) => {
    setSelectedStatus(event.value);
  });

  const reset = useEvent(() => {
    setDropdownOptions([]);
    setSelectedStatus(null);
    setCancelReason('');
  });

  const initialize = useEvent(async () => {
    // Reset if there isn't anything selected.
    if (!examId) {
      throw new Error('Cannot initialize ChangeStatusModal because the examId is invalid.');
    }

    const validOptions = await apiClient.exams.getValidStatuses(examId);
    let newDropdownOptions: StatusDropdownOptionType[];
    let newSelectedStatus: ExamStatusTrigger | 'active' | null;

    if (statusChangeOption === ContextMenuItems.cancelExam.text) {
      const cancelOption = ExamStatusService.findExamStatusType(validOptions, ExamStatusState.Canceled);

      newDropdownOptions = cancelOption ? [cancelOption] : [];
      newSelectedStatus = cancelOption ? ExamStatusTrigger.Cancel : null;
    } else if (statusChangeOption === ContextMenuItems.markDuplicate.text) {
      const duplicateOption = ExamStatusService.findExamStatusType(validOptions, ExamStatusState.Duplicate);
      newDropdownOptions = duplicateOption ? [duplicateOption] : [];
      newSelectedStatus = duplicateOption ? ExamStatusTrigger.Duplicate : null;
    } else if (statusChangeOption === ContextMenuItems.activateExam.text) {
      newDropdownOptions = [ACTIVE_OPTION];
      newSelectedStatus = 'active';
    } else {
      newDropdownOptions = validOptions;
      newSelectedStatus = null;
    }

    setDropdownOptions(newDropdownOptions);
    setSelectedStatus(newSelectedStatus);
    setCancelReason('');
  });

  const handleSaveClick = useEvent(() => {
    if (examId == null) throw new Error('Cannot submit status change when the examId property is null or undefined.');

    if (selectedStatus == null) throw new Error('Cannot submit status change when the selectedStatus is null or undefined.');

    onSubmit({
      id: examId,
      status: selectedStatus.toString().trim(),
      cancelReason: hasText(cancelReason) ? cancelReason.trim() : null,
    });
  });

  useEffect(() => {
    if (show && examId) {
      reset();
      initialize();
    } else {
      reset();
    }
  }, [initialize, reset, examId, statusChangeOption, show]);

  const warningLabel = dropdownOptions.find((d) => d.value === selectedStatus)?.warning ?? null;

  return (
    <Modal show={show} onHide={onClose} title={`Change status (${examId})`}>
      <StyledDialogBodyDiv>
        <StyledDropdown
          data={dropdownOptions}
          dataItemKey="value"
          label="New Status"
          filterable={false}
          isForPrimitiveValues
          onChange={handleStatusChange}
          required
          valid
          value={selectedStatus}
          valueField="value"
        />
        <TextArea
          label={statusChangeOption === ContextMenuItems.cancelExam.text ? 'Cancel reason' : 'Change status reason'}
          onChange={(event) => setCancelReason(event.value)}
          required
          value={cancelReason}
          valid={hasText(cancelReason)}
        />
        <StyledButton disabled={selectedStatus === null || !cancelReason.length} onClick={handleSaveClick}>
          Save
        </StyledButton>
        <StyledLabel>{warningLabel}</StyledLabel>
      </StyledDialogBodyDiv>
    </Modal>
  );
});

ChangeStatusModal.displayName = 'ChangeStatusModal';

const StyledDialogBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  padding: 24px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.space.spacing50};
  max-width: 40%;
`;

const StyledDropdown = styled(DropdownWithValuesField)`
  margin-right: ${({ theme }) => theme.space.spacing40};
  margin-bottom: ${({ theme }) => theme.space.spacing20};
  width: 250px;
`;

const StyledLabel = styled(Label)`
  margin-top: ${({ theme }) => theme.space.spacing20};
  color: ${({ theme }) => theme.colors.warning};
`;
