import { CompositeFilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';
import { DateFilter, Operators as KendoOperators, TextFilter } from '@progress/kendo-react-data-tools';

import { DateCell, TextCell } from 'core/ui';

import { PatientColumn } from '../types/PatientColumn';

const PATIENT_COLUMNS: PatientColumn[] = [
  {
    cell: TextCell,
    field: 'unosID',
    filter: TextFilter,
    headerCellDescription: 'Patient UNOS ID',
    title: 'Unos ID',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'caseID',
    filter: TextFilter,
    headerCellDescription: 'Patient Case ID',
    title: 'Case ID',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'patientNumber',
    filter: TextFilter,
    headerCellDescription: 'Patient Number',
    title: 'MRN',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'lastName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Last Name',
    title: 'Last Name',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'firstName',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient First Name',
    title: 'First Name',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: DateCell,
    field: 'dob',
    filter: DateFilter,
    operators: KendoOperators.date,
    format: 'MM/DD/YYYY',
    headerCellDescription: "Patient's Date of Birth",
    title: 'DOB',
    width: '150px',
    columnFilter: 'date',
  },
  {
    cell: TextCell,
    field: 'age',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Age',
    title: 'Age',
    width: '150px',
    columnFilter: 'text',
    filterable: false,
  },
  {
    cell: TextCell,
    field: 'gender',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Gender',
    title: 'Gender',
    width: '150px',
    columnFilter: 'text',
  },
  {
    cell: DateCell,
    field: 'dateCreated',
    filter: DateFilter,
    operators: KendoOperators.date,
    format: 'MM/DD/YYYY',
    headerCellDescription: 'Date Patient Record was Created',
    title: 'Date Created',
    width: '150px',
    columnFilter: 'date',
  },
  {
    cell: TextCell,
    field: 'hospital',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Hospital',
    title: 'Hospital',
    width: '150px',
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'notes',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Patient Notes',
    title: 'Notes',
    columnFilter: 'text',
  },
];

const getPatientColumns = () => {
  return PATIENT_COLUMNS.filter((column) => !column.hideFromGrid);
};

const getSearchFilter = (searchText: string, locationId: number | null | undefined): CompositeFilterDescriptor => {
  const searchFields = ['patientNumber', 'firstName', 'lastName'];

  const orFilters: CompositeFilterDescriptor = {
    filters: searchFields.map((field) => ({
      field,
      operator: 'contains',
      value: searchText,
    })),
    logic: 'or',
  };

  if (locationId != null) {
    return {
      filters: [
        {
          field: 'Location_Id',
          operator: 'eq',
          value: locationId,
        },
        orFilters,
      ],
      logic: 'and',
    };
  }

  return orFilters;
};

const getDefaultPatientExamDataState = (patientId: number): DataSourceRequestState => {
  const filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [
      {
        field: 'patientId',
        operator: 'eq',
        value: patientId as string | number | boolean,
      },
      {
        field: 'active',
        operator: 'eq',
        value: true as string | number | boolean,
      },
    ],
  };

  return {
    filter,
    skip: 0,
    sort: [{ field: 'id', dir: 'desc' }],
    take: 30,
  };
};

export const PatientGridService = {
  getSearchFilter,
  getPatientColumns,
  getDefaultPatientExamDataState,
};
