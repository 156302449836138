import { createSelector } from '@reduxjs/toolkit';

import { LocationModel } from 'models';

import { AppSelector, RootState } from 'features/main/redux';

import { locationAdapter } from './reducer';

const rootSelector = (state: RootState) => state.locations;
const rootGridSelector = (state: RootState) => state.locationsGrid;

const { selectAll: getAll } = locationAdapter.getSelectors(rootSelector);

const { selectAll: getAllGrid } =
  locationAdapter.getSelectors(rootGridSelector);

const getTotalGrid: AppSelector<number> = (state) =>
  rootGridSelector(state).total;

const getByIdOutput = (locations: LocationModel[], id: number | undefined) =>
  locations.find((l) => l.id === id);

const getById = (
  id: number | undefined,
): AppSelector<LocationModel | undefined> =>
  createSelector([getAll, () => id], getByIdOutput);

const getTotal: AppSelector<number> = (state) => rootSelector(state).total;

export const LocationSelectors = {
  getAll,
  getAllGrid,
  getById,
  getTotal,
  getTotalGrid,
};
