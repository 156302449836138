import { EntityId, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { UserModel } from 'models';

import { UserStatus } from '../constants';
import { UserActions } from './actions';

export const userAdapter = createEntityAdapter<UserModel>({
  sortComparer: false,
});

const getReducerInitialState = () => ({
  ...userAdapter.getInitialState(),
  total: 0,
  status: UserStatus.PENDING,
});

export const reducer = createReducer(getReducerInitialState(), (builder) => {
  builder.addCase(UserActions.getById.fulfilled, (draft, action) => {
    const payload = action.payload as UserModel;
    userAdapter.setOne(draft, payload);
    draft.status = UserStatus.FETCHED;
  });
  builder.addCase(UserActions.add.fulfilled, (draft, action) => {
    const payload = action.payload as { users: UserModel };
    userAdapter.addOne(draft, payload.users);
    draft.status = UserStatus.ADDED;
  });
  builder.addCase(UserActions.edit.fulfilled, (draft, action) => {
    const payload = action.payload as {
      users: Record<number, UserModel>;
    };

    Object.values(payload.users).forEach((user) => {
      userAdapter.setOne(draft, user);
    });

    draft.status = UserStatus.UPDATED;
  });
});

export const reducerGrid = createReducer(
  getReducerInitialState(),
  (builder) => {
    builder.addCase(
      UserActions.getAllForKendoGrid.fulfilled,
      (draft, action) => {
        const payload = action.payload as {
          entities: { users: UserModel[] };
          total: number;
          result: EntityId[];
        };

        userAdapter.removeAll(draft);

        if (payload.entities.users) {
          userAdapter.addMany(draft, payload.entities.users);
        }

        draft.total = payload.total;
        draft.ids = payload.result;
        draft.status = UserStatus.FETCHED;
      },
    );
  },
);
