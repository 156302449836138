import { createContext } from 'react';

import { MergeDonorModel } from 'models/MergeDonorModel';

export const PatientVerbiageProviderContext = createContext<'Patient' | 'Donor'>('Patient');

PatientVerbiageProviderContext.displayName = 'PatientVerbiageProviderContext';

export const MergePatientValueSelectContext = createContext<{
  srcPatient: MergeDonorModel | null;
  destPatient: MergeDonorModel | null;
  selectedSourceFields: Partial<MergeDonorModel> | null;
  selectedDestFields: Partial<MergeDonorModel> | null;
  onValueChecked: (fieldName: string, sourceOrDest: string, checked: boolean, fieldValue: string | number | null | undefined) => void;
}>({
  srcPatient: null,
  destPatient: null,
  selectedSourceFields: {},
  selectedDestFields: {},
  onValueChecked: () => {
    throw new Error('MergePatientValueSelectContext.Provider is required in the react tree.');
  },
});

MergePatientValueSelectContext.displayName = 'MergePatientValueSelectContext';
