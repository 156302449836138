import { CompositeFilterDescriptor, DataSourceRequestState } from '@progress/kendo-data-query';

import { FileModel } from 'models';

import { ShareEmailFormValues, ShareLinkFormValues } from './types';
import { PatientFormValues } from './types/PatientFormValues';
import { ShareExpirationType } from './types/ShareExpirationType';

export const PatientStatus = {
  PENDING: 'patient-pending',
  ADDED: 'patient-created',
  UPDATED: 'patient-updated',
  FETCHED: 'patient-fetched',
  ERROR: 'patient-error',
};

export const PatientGenders = [
  {
    name: 'Unknown',
    value: '',
  },
  {
    name: 'Male',
    value: 'M',
  },
  {
    name: 'Female',
    value: 'F',
  },
];

export const InitialValues: {
  donorForm: PatientFormValues;
} = {
  donorForm: {
    id: 0,
    firstName: '',
    lastName: '',
    dob: null,
    gender: null,
    patientNumber: '',
    unosID: '',
    locationType: '',
    active: true,
    caseID: '',
    height: null,
    weight: null,
    crossClampDateTime: null,
    notes: null,
    hospital: null,
    ageRange: null,
    location: null,
    isUnosUnavailable: false,
  },
};

export const DEFAULT_FILTER: CompositeFilterDescriptor = {
  logic: 'and',
  filters: [
    {
      field: 'active',
      operator: 'eq',
      value: true,
    },
  ],
};
export const DEFAULT_FILTER_DATA_STATE: DataSourceRequestState = {
  filter: DEFAULT_FILTER,
  skip: 0,
  sort: [{ field: 'id', dir: 'desc' }],
  take: 30,
};

export const DEFAULT_LOCATION_ID = 0;

export const DEFAULT_LOCATION = {
  value: {
    id: DEFAULT_LOCATION_ID,
    name: 'All Locations',
  },
};

export const SHARE_EXPIRATION_OPTIONS = [
  { name: 'Never', value: ShareExpirationType.Never },
  { name: '1 Day', value: ShareExpirationType.OneDay },
  { name: '7 Days', value: ShareExpirationType.SevenDays },
  { name: '30 Days', value: ShareExpirationType.ThirtyDays },
  { name: '60 Days', value: ShareExpirationType.SixtyDays },
  { name: '90 Days', value: ShareExpirationType.NinetyDays },
  { name: '1 Year', value: ShareExpirationType.OneYear },
];

export enum ShareType {
  Link = 'LINK',
  Email = 'EMAIL',
}

export const DEFAULT_SHARE_LINK_FORM_VALUES: ShareLinkFormValues = {
  description: '',
  accessCode: '',
  expiration: null,
};

export const DEFAULT_SHARE_EMAIL_FORM_VALUES: ShareEmailFormValues = {
  description: '',
  email: '',
  accessCode: '',
  expiration: null,
};

export const DEFAULT_FINAL_REPORT_VALUES: FileModel = {
  id: 0,
  created: '',
  modified: null,
  url: null,
  thumbnailUrl: null,
  fileType: '',
  fileName: '',
  file: null,
  categoryId: null,
  examId: null,
  user: null,
  location: '',
  thumbnailLocation: null,
  patient_id: null,
  index: null,
  fileId: null,
  fileSize: null,
  externalSource: null,
  source: null,
  viewerId: null,
  subFiles: [],
};
