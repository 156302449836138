import { memo } from 'react';

import { GridHeaderCellProps } from '@progress/kendo-react-grid';

import { HeaderCell } from 'core/ui';

import { ServiceGridService } from '../services';

export const ServiceGridHeaderCell = memo<GridHeaderCellProps>(({ field, ...restProps }) => {
  if (field == null) {
    throw new Error('Cannot render ServiceGridHeaderCell because the field property is null or undefined.');
  }

  const description = ServiceGridService.getColumnDefinition(field).headerCellDescription;

  return <HeaderCell {...restProps} description={description} />;
});

ServiceGridHeaderCell.displayName = 'ServiceGridHeaderCell';
