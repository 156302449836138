import { Fragment, memo, useMemo } from 'react';

import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import styled from 'styled-components';

import { useBoolean } from 'core/hooks';

import { Icon } from '../Icon';
import { InformationTableItem } from './InformationTableItem';
import { InformationTableProps } from './InformationTableProps';

export const InformationTable = memo<InformationTableProps>(({ className, items, disabled = false }) => {
  const [isTableExpanded, { toggle: toggleTableExpanded }] = useBoolean(false);

  const { visibleItems, collapsibleItems } = useMemo(() => {
    const newVisibleItems: InformationTableItem[] = [];
    const newCollapsibleItems: InformationTableItem[] = [];
    let collapseButtonFound = false;

    for (const item of items) {
      if (item === 'collapse-button') {
        collapseButtonFound = true;
        continue;
      }

      if (collapseButtonFound) {
        newCollapsibleItems.push(item);
      } else {
        newVisibleItems.push(item);
      }
    }

    return { visibleItems: newVisibleItems, collapsibleItems: newCollapsibleItems };
  }, [items]);

  return (
    <StyledComponentDiv className={cn(className, { disabled: disabled, 'is-expanded': isTableExpanded })}>
      {visibleItems.map((item) => (
        <Fragment key={item.key}>
          <StyledInfoLabel>{item.label}</StyledInfoLabel>
          <StyledInfoValue>{item.value}</StyledInfoValue>
        </Fragment>
      ))}
      {collapsibleItems.length > 0 && (
        <>
          <StyledCollapsibleDiv>
            {collapsibleItems.map((item) => (
              <Fragment key={item.key}>
                <StyledInfoLabel>{item.label}</StyledInfoLabel>
                <StyledInfoValue>{item.value}</StyledInfoValue>
              </Fragment>
            ))}
          </StyledCollapsibleDiv>

          <StyledDataExpandButton onClick={toggleTableExpanded}>
            <span className="expand-text">
              {isTableExpanded ? 'Less' : 'More'}
              <Icon icon={isTableExpanded ? faArrowUp : faArrowDown} />
            </span>
          </StyledDataExpandButton>
        </>
      )}
    </StyledComponentDiv>
  );
});

InformationTable.displayName = 'InformationTable';

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: min-content 1fr;
  //grid-auto-rows: calc(1.5 * ${({ theme }) => theme.lineHeights.body});
  column-gap: 8px;
  align-items: start;
  line-height: calc(1.5 * ${({ theme }) => theme.lineHeights.body});
`;

const StyledInfoLabel = styled.div`
  white-space: nowrap;
  user-select: none;
`;

const StyledInfoValue = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: all;
`;

const StyledCollapsibleDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  line-height: calc(1.5 * ${({ theme }) => theme.lineHeights.body});
  //grid-auto-rows: calc(1.5 * ${({ theme }) => theme.lineHeights.body});
  height: 0;

  .is-expanded & {
    height: initial;
  }
`;

const StyledDataExpandButton = styled.button`
  all: unset; // Remove global styling.
  position: relative;
  top: -50%;
  grid-column: 1 / -1;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(0.5 * ${({ theme }) => theme.lineHeights.body});
  user-select: none;

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.palette.blues[0]};
  }

  .disabled &::before {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[3]};
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    z-index: 2;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .disabled &::after {
    background-color: ${({ theme }) => theme.colors.borderDisabled};
  }

  & .expand-text {
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.palette.blues[0]};
    color: ${({ theme }) => theme.colors.primary};
    line-height: ${({ theme }) => theme.lineHeights.body};
    font-size: ${({ theme }) => theme.fontSizes.subheading};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    display: flex;
    align-items: center;
    padding: 0 4px 0 7px;
  }

  .disabled & .expand-text {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[3]};
    color: ${({ theme }) => theme.colors.palette.grayscale[6]};
  }

  .icon-container {
    margin-left: 3px;
    color: ${({ theme }) => theme.colors.primary};
  }

  .disabled & .icon-container {
    color: ${({ theme }) => theme.colors.palette.grayscale[6]};
  }
`;
