import { forwardRef, useCallback, useLayoutEffect, useRef } from 'react';

import {
  ComboBoxHandle,
  ComboBox as KendoComboBox,
  ComboBoxProps as KendoComboBoxProps,
} from '@progress/kendo-react-dropdowns';
import { chevronDownIcon } from '@progress/kendo-svg-icons';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { ComboBoxProps } from './ComboBoxProps';

export const ComboBox = forwardRef<ComboBoxHandle, ComboBoxProps>(
  (
    {
      size = ComponentSizes.MEDIUM,
      description,
      disabled,
      hint,
      isOptionalLabelShown,
      label,
      name,
      required,
      value,
      valid,
      validationMessage,
      visited,
      onEnterKeyDown,
      ...rest
    },
    ref,
  ) => {
    const comboBoxRef = useRef<ComboBoxHandle>();

    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const hintId = isHintShown ? `${name}_hint` : '';
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          onEnterKeyDown?.(
            event,
            comboBoxRef.current?.props?.opened ??
              comboBoxRef.current?.state?.opened ??
              false,
          );
        }
      },
      [onEnterKeyDown],
    );

    // We need to manually attach event handlers to the internal <input /> element because the Kendo component doesn't expose an onKeyDown prop.
    // The useLayoutEffect() hook is intended to be used in such cases because it is executed immediately after the DOM has been updated.
    useLayoutEffect(() => {
      if (comboBoxRef.current == null) return () => {};

      const input =
        comboBoxRef.current.element?.querySelector<'input'>('input');

      input?.addEventListener('keydown', handleKeyDown);

      return () => {
        input?.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);

    return (
      <>
        {label && (
          <Label
            description={description}
            editorId={name}
            editorValid={valid}
            editorDisabled={disabled}
            required={required}
            optional={isLabeledAsOptional}
          >
            {label}
          </Label>
        )}

        <StyledKendoAutoComplete
          ref={(node) => {
            comboBoxRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref != null) {
              // eslint-disable-next-line no-param-reassign
              ref.current = node;
            }
          }}
          disabled={disabled}
          name={name}
          size={size}
          valid={valid}
          value={value}
          required={required}
          iconClassName="use-svg-icon" // This needs to be a non-empty string value to force the Kendo component to use what was specified in the svgIcon prop.
          svgIcon={chevronDownIcon}
          {...rest}
        />
        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && (
          <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>
        )}
      </>
    );
  },
);

ComboBox.displayName = 'ComboBox';

type StyledElementProps = {
  theme: DefaultTheme;
  size?: KendoComboBoxProps['size'];
  valid?: boolean;
  disabled?: boolean;
};

const resolvePalette = ({ theme, disabled, valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      borderActive: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      placeholderText: theme.colors.textSecondary,
      hint: theme.colors.textSecondary,
      background: theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
      caret: theme.colors.textDisabled,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    borderActive: theme.colors.palette.aquas[4],
    text: theme.colors.textPrimary,
    placeholderText: theme.colors.textSecondary,
    hint: theme.colors.textSecondary,
    background: theme.colors.palette.white,
    icon: theme.colors.palette.grayscale[4],
    caret: theme.colors.textPrimary,
  };

  if (!valid) {
    result.border = theme.colors.error;
    result.borderActive = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolvePlaceholderColor = (props: StyledElementProps) => {
  const { placeholderText } = resolvePalette(props);

  return placeholderText;
};

const resolveActiveBorderColor = (props: StyledElementProps) => {
  const { borderActive } = resolvePalette(props);

  return borderActive;
};

const resolveActiveBoxShadow = ({
  theme,
  valid,
  disabled,
}: StyledElementProps) => {
  if (disabled) {
    return 'none';
  }

  if (!valid) {
    return theme.shadows.formControlsActiveError;
  }

  return theme.shadows.formControlsActive;
};

const resolveInputCaretColor = (props: StyledElementProps) => {
  const { caret } = resolvePalette(props);

  return caret;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing30}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing30}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`;
  }
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const StyledKendoAutoComplete = styled(KendoComboBox)<{
  valid?: boolean;
}>`
  background-color: ${resolveBackgroundColor};
  border-radius: ${({ theme }) => theme.radii.base};
  border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
  box-sizing: border-box;
  caret-color: ${resolveInputCaretColor};
  color: ${resolveColor};
  font-size: ${resolveFontSize};
  height: ${resolveHeight};
  line-height: ${resolveLineHeight};
  outline: none;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;

  input {
    padding: ${resolvePadding};
  }

  &::placeholder {
    color: ${resolvePlaceholderColor};
  }

  &:active,
  &:hover,
  &:focus {
    border-color: ${resolveActiveBorderColor};
  }

  &:active,
  &:focus {
    box-shadow: ${resolveActiveBoxShadow};
  }

  .k-input-button {
    background: none;
    border: none;
  }
`;
