import {
  ForwardedRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { cloneDeep } from 'lodash';

import { splitAt } from 'core/utils';

import { AutoCompleteHandle } from '../AutoComplete';
import { DelimitedTextHandle } from './DelimitedTextHandle';
import { DelimitedTextInternalValue } from './DelimitedTextInternalValue';
import { DelimitedTextService } from './DelimitedTextService';

/** Common internal logic for the DelimitedText variants. */
export function useDelimitedTextCommon<
  TInputHandle extends HTMLInputElement | AutoCompleteHandle,
>(
  ref: ForwardedRef<DelimitedTextHandle>,
  value: string | null | undefined,
  onChange: (event: { target?: unknown; value: string }) => void,
) {
  const textInputRefs = useRef(new Map<string, TInputHandle>());

  const [internalValues, setInternalValues] =
    useState<DelimitedTextInternalValue[]>(); // TODO: Document why we are allowing this to be undefined.

  const handleChange = useCallback(
    (lineItemValue: string, itemKey: string) => {
      if (typeof internalValues === 'undefined') return;

      const newItems = cloneDeep(internalValues);
      const changedItem = newItems.find((item) => item.key === itemKey);

      if (changedItem) {
        changedItem.value = lineItemValue;
      }

      setInternalValues(newItems);
      onChange({
        target: {},
        value: DelimitedTextService.internalToString(newItems),
      });
    },
    [internalValues, onChange],
  );

  const handleDelete = useCallback(
    (itemKey: string) => {
      if (typeof internalValues === 'undefined') return;

      const newItems = cloneDeep(internalValues);
      const deletedIndex = newItems.findIndex((i) => i.key === itemKey);

      if (deletedIndex >= 0) {
        newItems.splice(deletedIndex, 1);
      }

      setInternalValues(newItems);
      onChange({
        target: {},
        value: DelimitedTextService.internalToString(newItems),
      });
    },
    [internalValues, onChange],
  );

  const handleAddClick = useCallback(() => {
    if (typeof internalValues === 'undefined') return;

    const newItems = cloneDeep(internalValues);
    const newItem = {
      key: crypto.randomUUID(),
      value: '',
    };
    newItems.push(newItem);

    setInternalValues(newItems);
    onChange({
      target: {},
      value: DelimitedTextService.internalToString(newItems),
    });

    // Set focus to the newly added line item.
    setTimeout(() => {
      textInputRefs.current.get(newItem.key)?.focus();
    });
  }, [internalValues, onChange]);

  const handleNextLineRequested = useCallback(
    (caretIndex: number | null, itemKey: string) => {
      if (typeof internalValues === 'undefined') return;

      const newItems = cloneDeep(internalValues) ?? [];
      const itemIndex = newItems.findIndex((l) => l.key === itemKey);

      if (itemIndex == null || itemIndex < 0) return;

      const splitNewValues =
        caretIndex == null
          ? [newItems[itemIndex].value, '']
          : splitAt(caretIndex, newItems[itemIndex].value);

      newItems[itemIndex].value = splitNewValues[0];

      const newItem = {
        key: crypto.randomUUID(),
        value: splitNewValues[1],
      };

      newItems.splice(itemIndex + 1, 0, newItem);

      setInternalValues(newItems);
      onChange({
        target: {},
        value: DelimitedTextService.internalToString(newItems),
      });
      // Set focus to the newly added line item.
      setTimeout(() => {
        textInputRefs.current.get(newItem.key)?.focus();
      });
    },
    [internalValues, onChange],
  );

  useEffect(() => {
    if (typeof value === 'undefined') {
      setInternalValues(undefined);
      return;
    }

    setInternalValues((prev) => {
      if (value !== DelimitedTextService.internalToString(prev)) {
        const splitValues = DelimitedTextService.splitDelimitedString(value);
        const newInternalValues = splitValues?.map<DelimitedTextInternalValue>(
          (v) => ({
            key: crypto.randomUUID(),
            value: v,
          }),
        );
        return newInternalValues;
      }

      return prev;
    });
  }, [value]);

  useImperativeHandle(
    ref,
    () => ({
      get value() {
        return DelimitedTextService.internalToString(internalValues) ?? '';
      },
      focus: () => {
        if (typeof internalValues === 'undefined') return;

        if (internalValues.length > 0) {
          textInputRefs.current.get(internalValues[0].key)?.focus();
        }
      },
    }),
    [internalValues],
  );

  return {
    textInputRefs,
    internalValues,
    setInternalValues,
    handleChange,
    handleDelete,
    handleAddClick,
    handleNextLineRequested,
  };
}
