import { SettingOverrideModel } from 'models';

import { RhfValidators } from 'core/forms';

import { GeneralTabFormValues, RuleEditorFormValues } from './types';

export const AccountStatus = {
  PENDING: 'account-pending',
  ADDED: 'account-created',
  UPDATED: 'account-updated',
  FETCHED: 'account-fetched',
  ERROR: 'account-error',
};

export const ACCOUNT_INITIAL_VALUES = {
  GENERAL: {
    parentAccount: null,
    name: '',
    quickBooksId: '',
    customerKey: '',
    active: true,
  } satisfies GeneralTabFormValues,
  SETTING_OVERRIDES: [] as SettingOverrideModel[],
};

export const ACCOUNT_EDIT_VALIDATORS = {
  GENERAL: {
    name: RhfValidators.maxLength(255),
    quickBooksId: RhfValidators.maxLength(255),
    customerKey: RhfValidators.maxLength(255),
    parentAccount: undefined,
    active: undefined,
  },
};

export const PRICING_RULE_INITIAL_VALUES: RuleEditorFormValues = {
  field: null,
  item: null,
  value: '',
};

export const PRICING_FIELD_CATEGORY_NAME = 'Pricing';

export const ACCOUNT_PRICING_VALIDATORS = {
  value: RhfValidators.maxLength(255),
};
