import { forwardRef } from 'react';

import { CheckboxHandle, Checkbox as KendoCheckbox } from '@progress/kendo-react-inputs';
import styled, { DefaultTheme } from 'styled-components';

import { ErrorMessage } from '../ErrorMessage';
import { Hint } from '../Hint';
import { Label } from '../Label';
import { CheckboxProps } from './CheckboxProps';

// TODO: Kendo uses svg as a background image for the checkmark icon
// so we can't style it.
// We need to check with the designer what to do with this problem.
export const Checkbox = forwardRef<CheckboxHandle, CheckboxProps>(
  (
    {
      className,
      inline = false,
      label,
      description,
      disabled,
      hint,
      isOptionalLabelShown,
      required,
      text,
      valid = true,
      validationMessage,
      visited,
      name,
      value,
      ...restProps
    },
    ref,
  ) => {
    const isValidationMessageShown = Boolean(visited && validationMessage);
    const isHintShown = Boolean(!isValidationMessageShown && hint);
    const errorId = isValidationMessageShown ? `${name}_error` : '';
    const hintId = isHintShown ? `${name}_hint` : '';
    const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

    return (
      <StyledWrapperDiv className={className} $inline={inline}>
        {label && (
          <StyledLabel
            $inline={inline}
            description={description}
            editorDisabled={disabled}
            editorId={name}
            editorValid={valid}
            optional={isLabeledAsOptional}
            required={required}
          >
            {label}
          </StyledLabel>
        )}
        <StyledCheckboxBase
          ref={ref}
          $inline={inline}
          disabled={disabled}
          label={text}
          required={required}
          valid={valid}
          name={name}
          checked={Boolean(value)}
          value={value}
          {...restProps}
        />
        {isHintShown && <Hint id={hintId}>{hint}</Hint>}
        {isValidationMessageShown && <ErrorMessage id={errorId}>{validationMessage}</ErrorMessage>}
      </StyledWrapperDiv>
    );
  },
);

Checkbox.displayName = 'Checkbox';

type StyledElementProps = {
  $inline: boolean;
  disabled?: boolean;
  checked?: boolean | null;
  valid?: boolean;
  theme: DefaultTheme;
};

const resolvePalette = ({ theme, disabled, checked, valid }: StyledElementProps) => {
  if (disabled) {
    return {
      border: theme.colors.borderDisabled,
      text: theme.colors.textDisabled,
      background: checked ? theme.colors.palette.grayscale[5] : theme.colors.backgroundDisabled,
      icon: theme.colors.iconDisabled,
    };
  }

  if (checked) {
    return {
      border: theme.colors.secondary,
      text: theme.colors.textPrimary,
      background: theme.colors.secondary,
      icon: theme.colors.palette.white,
    };
  }

  const result = {
    border: theme.colors.borderBase,
    text: theme.colors.textPrimary,
    background: theme.colors.palette.white,
    icon: theme.colors.secondary,
  };

  if (!valid) {
    result.border = theme.colors.error;
  }

  return result;
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);
  return background;
};

const resolveSize = (props: StyledElementProps) => {
  const { theme } = props;
  return theme.sizes.small;
};

const resolveCheckmarkColor = (props: StyledElementProps) => {
  const { icon } = resolvePalette(props);
  return icon;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);
  return border;
};

const resolveTextColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);
  return text;
};

const StyledCheckboxBase = styled(KendoCheckbox)<{
  $inline: boolean;
  disabled?: boolean;
  checked?: boolean | null;
  valid?: boolean;
}>`
  align-items: center;
  display: inline-flex;
  opacity: 1;
  order: ${({ $inline }) => ($inline ? 1 : 2)};

  & .k-checkbox {
    background-color: ${resolveBackgroundColor};
    cursor: pointer;
    height: ${resolveSize};
    width: ${resolveSize};
    border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
    border-radius: ${({ theme }) => theme.radii.base};

    &:focus {
      box-shadow: none;
      border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
    }
  }

  & .k-checkbox-label {
    color: ${resolveTextColor};
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};
    margin-left: ${({ theme }) => theme.space.spacing20};
  }

  & .k-checkbox:checked {
    background-color: ${resolveBackgroundColor};
    border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
  }

  & .k-state-indeterminate {
    background-color: ${resolveBackgroundColor};
  }

  & .k-state-indeterminate:before {
    background-image: none;
    color: ${resolveCheckmarkColor};
    height: 50%;
    width: 50%;
  }

  & .k-checkbox.k-invalid + .k-checkbox-label,
  .k-checkbox.k-invalid + .k-checkbox-label {
    color: ${resolveTextColor};
  }
`;

const StyledWrapperDiv = styled.div<{
  $inline: boolean;
  disabled?: boolean;
  checked?: boolean;
  valid?: boolean;
}>`
  ${({ $inline, theme }) =>
    $inline &&
    `
     align-items: center;
     display: flex;
     justify-content: center;
     width: unset;
     margin-bottom: ${theme.space.spacing20}
  `}
`;

const StyledLabel = styled(Label)<{
  $inline: boolean;
  disabled?: boolean;
  checked?: boolean;
  valid?: boolean;
}>`
  margin-bottom: 0 !important;
  order: ${({ $inline }) => ($inline ? 2 : 1)};
  ${({ $inline, theme }) => $inline && `margin-left: ${theme.space.spacing20}`}
`;
