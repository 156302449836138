import { FunctionComponent } from 'react';

import { Route, Routes } from 'react-router-dom';

import { RouteGuard, UserRoles } from 'features/auth';

import { DestinationRoutes } from '../routes';
import { DestinationForm } from './DestinationForm';
import { DestinationHome } from './DestinationHome';

export const Destination: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={DestinationRoutes.HOME}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN}>
            <DestinationHome />
          </RouteGuard>
        }
      />
      <Route
        path={DestinationRoutes.ADD}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={DestinationHome}>
            <DestinationForm />
          </RouteGuard>
        }
      />
      <Route
        path={DestinationRoutes.EDIT}
        element={
          <RouteGuard allowedRoles={UserRoles.ADMIN} requireSystemAdmin challengeComponent={DestinationHome}>
            <DestinationForm />
          </RouteGuard>
        }
      />
    </Routes>
  );
};

Destination.displayName = 'Destination';
