import {
  ChangeEvent,
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  useMemo,
} from 'react';

import { useEvent } from 'core/hooks';

import { Button, ButtonVariants } from '../Button';
import { Label } from '../Label';
import { ComponentSizes } from '../constants';
import { InputArrayProps } from './InputArrayProps';
import { InputWrapper } from './InputWrapper';

// TODO: This component likely needs a fair amount of enhancements so that it can integrate fully with the Kendo forms system.  In particular
// it should return a ref that implements all of the required functions and properties.  There may be more things as well.
export const InputArray: FunctionComponent<InputArrayProps> = ({
  description,
  disabled = false,
  isOptionalLabelShown = false,
  label,
  name,
  onPush,
  onRemove,
  onReplace,
  onChange,
  required = false,
  valid = false, // TODO: Why is this defaulting to false?  Similar components that have a "valid" property tend to NOT set a default valid state.  Document the reason for differing behavior.
  value,
}) => {
  const isLabeledAsOptional = Boolean(!required && isOptionalLabelShown);

  const dataWithIndexes: { inputValue: string; formDataIndex: number }[] =
    useMemo(
      () =>
        value == null
          ? []
          : value.map((valueItem, index) => {
              return { inputValue: valueItem, formDataIndex: index };
            }),
      [value],
    );

  const handleAdd: MouseEventHandler<HTMLButtonElement> = useEvent((event) => {
    event.preventDefault(); // TODO: Document why this is calling preventDefault().  It isn't immediately obvious.

    onPush?.({
      value: '',
    });

    // TODO: Should this emit an onChange() event?  If we actually don't want that to happen then we should document why.  Because this looks like it should.
  });

  const handleRemove = useEvent(
    (_event: MouseEvent<HTMLButtonElement>, index: number) => {
      // Note: This seems odd that it would block the ability to remove items when there are exactly 1 item in the array.  That
      // behavior seems like it should be controlled by the parent component.
      if (value != null && value.length > 1) {
        onRemove?.({ index });

        const newValues: string[] = value == null ? [] : [...value];
        newValues.splice(index, 1);
        onChange?.(newValues);
      }
    },
  );

  const handleChange = useEvent(
    (event: ChangeEvent<HTMLInputElement>, index: number) => {
      const newValue = event.target.value;

      onReplace?.({
        index,
        value: newValue,
      });

      const newValues: string[] = value == null ? [] : [...value];
      newValues[index] = newValue;
      onChange?.(newValues);
    },
  );

  return (
    <>
      {label && (
        <Label
          description={description}
          editorId={name}
          editorValid={valid}
          editorDisabled={disabled}
          required={required}
          optional={isLabeledAsOptional}
        >
          {label}
        </Label>
      )}
      {dataWithIndexes.map(({ formDataIndex, inputValue }) => (
        <InputWrapper
          key={formDataIndex}
          value={inputValue}
          index={formDataIndex}
          onChange={handleChange}
          onRemove={handleRemove}
        />
        /* <StyledInputDiv key={formDataIndex}>
          <InputWrapper
            value={inputValue}
            index={formDataIndex}
            onChange={handleChange}
          />
          <Input
            onChange={(event) => handleChange(event, formDataIndex)}
            value={inputValue}
            valid
          />
          <StyledRemoveButton
            disabled={dataWithIndexes && dataWithIndexes.length <= 1}
            onClick={() => handleRemove(formDataIndex)}
            size={ComponentSizes.SMALL}
            variant={ButtonVariants.SECONDARY}
            look={ButtonLooks.WHITE}
          >
            <TrashIcon />
          </StyledRemoveButton>
        </StyledInputDiv> */
      ))}
      <Button
        onClick={handleAdd}
        size={ComponentSizes.SMALL}
        variant={ButtonVariants.SECONDARY}
      >
        Add new
      </Button>
    </>
  );
};
