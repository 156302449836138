import { FunctionComponent } from 'react';

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { Modal as BootstrapModal } from 'react-bootstrap';
import styled from 'styled-components';

import { Icon } from '../Icon';
import { ModalProps } from './ModalProps';

export const Modal: FunctionComponent<ModalProps> = ({ children, beforeTitleContent, title, titleIcon, backdrop = true, keyboard = true, onHide, ...rest }) => {
  /* Notes: This component is using the Bootstrap modal instead of the Kendo dialog component.  This is because the Kendo component has some problematic behaviors:
    1. The Kendo modal is vertically centered on screen.  For simple dialogs this is fine, but anytime the height of the contents changes after the initial render will make the whole dialog jump up and down.
    2. The Kendo modal does not support the ability to dismiss when clicking outside without custom logic.  Bootstrap handles this natively.
  */

  const showCloseButton = backdrop !== 'static' && keyboard;

  return (
    <StyledBootstrapModal onHide={onHide} {...rest}>
      {beforeTitleContent && <>{beforeTitleContent}</>}
      {title != null && (
        <StyledTitleContainer>
          <StyledTitle>
            {titleIcon != null && <StyledTitleIcon icon={titleIcon} size="lg" />}
            {title}
          </StyledTitle>
          {showCloseButton && (
            <StyledTitleCloseButton type="button" onClick={onHide}>
              <Icon icon={faCircleXmark} size="lg" />
            </StyledTitleCloseButton>
          )}
        </StyledTitleContainer>
      )}
      {children}
    </StyledBootstrapModal>
  );
};

Modal.displayName = 'Modal';

const StyledBootstrapModal = styled(BootstrapModal)`
  &.modal {
    display: grid !important;
    grid-template-rows: 1fr;
    justify-content: center;
    overflow-y: hidden;
    padding-top: 44px; // Make the top of the dialog line up vertically with the expand/collapse button on the main navigation toolbar.
    padding-bottom: 21px;
  }

  .modal-dialog {
    max-width: none !important;
    margin: 0 !important;
    display: grid;
    grid-template-rows: fit-content(100%);
    align-content: start;
    overflow-y: hidden;
  }

  .modal-content {
    border: none;
    overflow-y: hidden;
  }
`;

const StyledTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 24px 24px 0 24px;
`;

const StyledTitle = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.palette.blues[5]};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 16px; // Figma mockup had a size of 16px instead of a standard size.
  user-select: none;
`;

const StyledTitleIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.space.spacing40};
`;

const StyledTitleCloseButton = styled.button`
  grid-column: 2 / span 1;
  border: none;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.palette.blues[5]};
  padding: 0;
  margin-left: ${({ theme }) => theme.space.spacing40};

  :hover,
  :focus {
    border: none;
  }
`;
