import { memo, useCallback, useMemo } from 'react';

import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

import { Icon } from 'core/ui';

import { OverrideEditorListItemProps } from '../types';

export const SettingsListItem = memo<OverrideEditorListItemProps>(
  ({
    className,
    selectedSettingId,
    overrides,
    definition,
    isAlternateRow,
    onClick,
  }) => {
    const current = useMemo(
      () => overrides?.find((s) => s.id === definition.id) ?? null,
      [definition.id, overrides],
    );

    const effectiveValue = current == null ? definition.value : current.value;

    const handleClick = useCallback(() => {
      onClick(definition.id);
    }, [definition.id, onClick]);

    const classNames = cn(className, {
      selected: selectedSettingId === definition.id,
      'has-null-value': effectiveValue == null,
      'has-override': current != null,
      'is-alternate-row': isAlternateRow,
    });

    return (
      <StyledItemButton
        className={classNames}
        type="button"
        onClick={handleClick}
      >
        <span className="override-indicator">
          <OverlayTrigger
            overlay={
              <Tooltip>Setting has been overridden for this user.</Tooltip>
            }
          >
            <Icon icon={faCircle} fixedWidth size="2xs" />
          </OverlayTrigger>
        </span>
        <span className="setting-name">{definition.name}</span>
        <span className="setting-value">
          {effectiveValue === null
            ? 'null'
            : typeof effectiveValue === 'undefined'
              ? 'undefined'
              : effectiveValue}
        </span>
      </StyledItemButton>
    );
  },
);

SettingsListItem.displayName = 'SettingsListItem';

const StyledItemButton = styled.button`
  all: unset; // Remove native browser styling.

  grid-column: 1 / span 3;
  display: grid;
  grid-template-columns: subgrid;
  overflow: hidden;
  align-items: center;

  &.is-alternate-row {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[2]};
  }

  &.selected {
    background-color: #bfccd9;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.palette.blues[3]};
    color: ${({ theme }) => theme.colors.palette.white};
  }

  .override-indicator {
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 0 0 8px;
  }
  &:not(.has-override) .override-indicator {
    visibility: hidden;
  }
  &:hover .override-indicator,
  &.selected:hover .override-indicator {
    color: white;
  }
  &.selected .override-indicator {
    color: ${({ theme }) => theme.colors.primary};
  }

  .setting-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .setting-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    padding: 0 8px 0 0;
  }
  &.has-null-value .setting-value {
    font-style: italic;
    color: ${({ theme }) => theme.colors.textDisabled};
  }
  &:hover .setting-value {
    color: ${({ theme }) => theme.colors.palette.white};
  }
`;
