import {
  FunctionComponent,
  ReactElement,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {
  DropDownListChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { Col, Row, useAccordionButton } from 'react-bootstrap';
import styled from 'styled-components';

import { LocationModel } from 'models';

import { Field, Form, FormElement, required } from 'core/forms';
import { useEvent } from 'core/hooks';
import { Accordion, Dropdown, SmallTextBox } from 'core/ui';
import {
  HeaderButton,
  HeaderCollapseIcon,
  HeaderDiv,
  HeaderTitleButton,
} from 'core/ui/Accordion';

import ExamIcon from 'features/exam/assets/exam.svg?react';

export type LocationAccordionProps = {
  eventKey: string;
  className?: string;
  onChange: (locationId: number | null) => void;
  onValidChange: (valid: boolean) => void;
  value: number | null;
  locations: LocationModel[];
  forceShowValidation: boolean;
  disabled: boolean;
};

const filterFields = ['name', 'code'];
const VISITED_SUBMIT_BUTTON_ID = 'LOCATION_ACCORDION_VISITED_SUBMIT_BUTTON';

const LocationAccordionInner: FunctionComponent<
  LocationAccordionProps & {
    isFormValid: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueSetter: (name: 'location', options: { value: any }) => void;
  }
> = ({
  eventKey,
  className,
  onChange,
  onValidChange,
  value,
  isFormValid,
  valueSetter,
  locations,
  forceShowValidation,
  disabled,
}) => {
  const visitedSubmitButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleHeaderClick = useAccordionButton(eventKey);

  const stableOnValidChange = useEvent(onValidChange);

  const handleLocationChange = useCallback(
    (event: DropDownListChangeEvent) => {
      onChange(event.value?.id ?? null);
    },
    [onChange],
  );

  useEffect(() => {
    valueSetter('location', {
      value: locations.find((l) => l.id === value) ?? null,
    });
  }, [value, locations, valueSetter]);

  useEffect(() => {
    stableOnValidChange(isFormValid);
  }, [isFormValid, stableOnValidChange]);

  useEffect(() => {
    if (forceShowValidation) {
      visitedSubmitButtonRef.current?.click();
    }
  }, [forceShowValidation]);

  return (
    <FormElement
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    >
      <HiddenButton
        ref={visitedSubmitButtonRef}
        type="submit"
        id={VISITED_SUBMIT_BUTTON_ID}
      />
      <Accordion.Item eventKey={eventKey} className={className}>
        <HeaderDiv>
          <HeaderTitleButton type="button" onClick={handleHeaderClick}>
            <ExamIcon />
            Location
            <StyledTextBox variant="success" isRendered={isFormValid}>
              Completed
            </StyledTextBox>
            <StyledTextBox variant="warning" isRendered={!isFormValid}>
              Incomplete Data
            </StyledTextBox>
          </HeaderTitleButton>
          <HeaderButton type="button" onClick={handleHeaderClick}>
            <HeaderCollapseIcon eventKey={eventKey} />
          </HeaderButton>
        </HeaderDiv>
        <Accordion.Body>
          <StyledRow>
            <Col className="col-12">
              <Field
                component={Dropdown}
                data={locations}
                filterFields={filterFields}
                itemRender={LocationItemRender}
                label="Search"
                name="location"
                required
                validator={required}
                valueRender={LocationValueRender}
                onChange={handleLocationChange}
                disabled={disabled}
              />
            </Col>
          </StyledRow>
        </Accordion.Body>
      </Accordion.Item>
    </FormElement>
  );
};

LocationAccordionInner.displayName = 'LocationAccordionInner';

export const LocationAccordion: FunctionComponent<LocationAccordionProps> = ({
  eventKey,
  className,
  onChange,
  value,
  locations,
  onValidChange,
  forceShowValidation,
  disabled,
}) => {
  const initialValues = useMemo(() => {
    const location = locations.find((l) => l.id === value);

    return {
      location: location ?? null,
    };
  }, [value, locations]);

  return (
    <Form
      key={value}
      initialValues={initialValues}
      ignoreModified
      render={({ onChange: valueSetter, valid }) => (
        <LocationAccordionInner
          eventKey={eventKey}
          className={className}
          onChange={onChange}
          onValidChange={onValidChange}
          isFormValid={valid}
          valueSetter={valueSetter}
          value={value}
          locations={locations}
          forceShowValidation={forceShowValidation}
          disabled={disabled}
        />
      )}
    />
  );
};

LocationAccordion.displayName = 'LocationAccordion';

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.space.spacing30};
`;

const HiddenButton = styled.button`
  display: none;
`;

const StyledTextBox = styled(SmallTextBox)`
  margin-left: ${({ theme }) => theme.space.spacing30};
`;

function LocationItemRender(
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
) {
  const dataItem = itemProps.dataItem as LocationModel;

  const itemChildren = (
    <span>
      {dataItem.code} | {dataItem.name}
    </span>
  );

  return cloneElement(li, li.props, itemChildren);
}

function LocationValueRender(
  element: ReactElement<HTMLSpanElement>,
  value: LocationModel,
) {
  if (!value) {
    return element;
  }

  const children = (
    <span>
      {value.code} | {value.name}
    </span>
  );

  return cloneElement(element, { ...element.props }, children);
}
