import { Operators as KendoOperators, NumericFilter, TextFilter } from '@progress/kendo-react-data-tools';
import { sortBy } from 'lodash';

import { SwitchCell, TextCell } from 'core/ui';
import { findOrThrow } from 'core/utils';

import { ColumnDefinition, ColumnState } from 'features/exam';

import { DefaultColumnsState } from '../types/constants';

const serviceColumns = ['id', 'description', 'notes', 'shortCode', 'longCode', 'parentService', 'cpt', 'active'];

const serviceColumnCollections: ColumnDefinition[] = [
  {
    cell: TextCell,
    field: 'id',
    filter: NumericFilter,
    operators: KendoOperators.numeric,
    headerCellDescription: 'System Generated Number',
    title: 'Id',
    width: '80px',
    hideFromGrid: false,
    columnFilter: 'numeric',
    search: true,
  },
  {
    cell: TextCell,
    field: 'description',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Description of the Service',
    title: 'Description',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'notes',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Notes of the service',
    title: 'Notes',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'shortCode',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'ShortCode of the Service',
    title: 'Short Code',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'longCode',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'LongCode of the Service',
    title: 'Long Code',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'cpt',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'CPT of the Service',
    title: 'CPT Code',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: TextCell,
    field: 'parentService',
    filter: TextFilter,
    operators: KendoOperators.text,
    headerCellDescription: 'Parent Name of the Service',
    title: 'Parent Service Name',
    hideFromGrid: false,
    columnFilter: 'text',
    search: true,
  },
  {
    cell: SwitchCell,
    field: 'active',
    filter: Boolean,
    operators: KendoOperators.boolean,
    headerCellDescription: 'Service is being use',
    title: 'Active',
    hideFromGrid: false,
    width: '80px',
    columnFilter: 'text',
    show: false,
  },
];
// get the columns of each features
function getDefineColumn(columns: string[]) {
  const finalColumns: ColumnDefinition[] = [];

  // loop to each columns
  columns.forEach((column) => {
    // find item to the collections
    finalColumns.push(findOrThrow(serviceColumnCollections, (item) => item.field === column, `Could not find column definition for: "${column}".`));
  });

  return finalColumns;
}

const SERVICE_COLUMNS = getDefineColumn(serviceColumns);

function getColumns() {
  return SERVICE_COLUMNS;
}

function getColumnState(allColumnStates: Record<string, ColumnState>, columnField: string): ColumnState {
  const orderIndex = allColumnStates[columnField]?.orderIndex ?? DefaultColumnsState[columnField]?.orderIndex ?? 0;
  const show = allColumnStates[columnField]?.show ?? DefaultColumnsState[columnField]?.show ?? false;

  return { orderIndex, show };
}

function getGridColumns() {
  return sortBy(
    SERVICE_COLUMNS.filter((column) => !column.hideFromGrid),
    (column) => DefaultColumnsState[column.field]?.orderIndex ?? 0,
  );
}

function getColumnDefinition(field: string) {
  return findOrThrow(serviceColumnCollections, (column) => column.field === field, `Could not find column definition for: "${field}".`);
}

export const ServiceGridService = {
  getColumns,
  getColumnState,
  getGridColumns,
  getColumnDefinition,
};
