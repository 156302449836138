import { FunctionComponent, ReactNode, useMemo } from 'react';

import { useMatches } from 'react-router-dom';

import { AuthenticationScheme } from '../constants';
import { AuthorizationClaimsContext } from '../contexts';
import {
  useAuthentication,
  useCurrentUser,
  useParsedShareAccessToken,
} from '../hooks';
import { authorizationService } from '../services';
import { Claim } from '../types';

const emptyClaimArray: Claim[] = []; // Use a constant for empty array so that we don't unncessarily trigger change detection when nothing has actually changed.

export const AuthorizationProvider: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  const { activeScheme } = useAuthentication();
  const shareToken = useParsedShareAccessToken(false);
  const { currentUser } = useCurrentUser();
  const routeMatches = useMatches();

  // Get claims for SHARE scheme.
  const shareClaims = useMemo(() => {
    if (activeScheme !== AuthenticationScheme.SHARE) return emptyClaimArray;

    if (shareToken == null)
      throw new Error(
        'Unexpected null or undefined share token.  It should always contain a value when the active authentication scheme is "SHARE".',
      );

    const newClaims: Claim[] = [];
    for (const claim of Object.entries(shareToken)) {
      newClaims.push({
        name: claim[0],
        value: claim[1],
      });
    }

    return newClaims;
  }, [activeScheme, shareToken]);

  const authenticationClaims: Claim[] =
    activeScheme === AuthenticationScheme.SHARE ? shareClaims : emptyClaimArray;

  const claimsTransformerBaseContext = useMemo(
    () => ({
      activeScheme,
      shareToken,
      currentUser,
      routeMatches,
    }),
    [activeScheme, currentUser, routeMatches, shareToken],
  );

  const allClaims = useMemo(() => {
    let newClaims = authenticationClaims;

    newClaims = authorizationService.shareLinkIdMatch({
      ...claimsTransformerBaseContext,
      claims: newClaims,
    });

    return newClaims;
  }, [authenticationClaims, claimsTransformerBaseContext]);

  return (
    <AuthorizationClaimsContext.Provider value={allClaims}>
      {children}
    </AuthorizationClaimsContext.Provider>
  );
};

AuthorizationProvider.displayName = 'AuthorizationProvider';
