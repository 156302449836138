import { FunctionComponent } from 'react';

import {
  Chip as KendoChip,
  ChipProps as KendoChipProps,
} from '@progress/kendo-react-buttons';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { LeftIcon } from './LeftIcon';
import { TagProps } from './TagProps';
import { TagTypes } from './constants';

export const Tag: FunctionComponent<TagProps> = ({
  className,
  disabled,
  icon,
  id,
  isIconShown = false,
  onClick,
  onRemove,
  removable,
  size = ComponentSizes.MEDIUM,
  text,
  type,
}) => (
  <StyledChipBase
    className={className}
    disabled={disabled}
    id={id}
    $type={type}
    onClick={onClick}
    onRemove={onRemove}
    removable={removable}
    removeIcon="k-i-close"
    size={size}
    text={text}
  >
    {isIconShown && <LeftIcon type={type} icon={icon} />}
    <StyledSpanText $size={size}>{text}</StyledSpanText>
  </StyledChipBase>
);

Tag.displayName = 'Tag';

type StyledTagProps = {
  theme: DefaultTheme;
  size?: KendoChipProps['size'];
  disabled?: boolean;
  $type?: TagTypes;
};

type StyledSpanProps = {
  theme: DefaultTheme;
  $size?: KendoChipProps['size'];
};

const resolvePalette = ({ theme, $type, disabled }: StyledTagProps) => {
  if (disabled) {
    return {
      background: theme.colors.backgroundDisabled,
      border: theme.colors.palette.grayscale[2],
      text: theme.colors.textDisabled,
      button: theme.colors.palette.grayscale[4],
    };
  }

  switch ($type) {
    case TagTypes.ERROR:
      return {
        background: theme.colors.palette.reds[0],
        border: theme.colors.palette.reds[2],
      };
    case TagTypes.SUCCESS:
      return {
        background: theme.colors.palette.greens[0],
        border: theme.colors.palette.greens[2],
      };
    case TagTypes.WARNING:
      return {
        background: theme.colors.palette.yellows[0],
        border: theme.colors.palette.yellows[2],
      };
    case TagTypes.INFO:
      return {
        background: theme.colors.palette.aquas[0],
        border: theme.colors.palette.aquas[2],
        text: theme.colors.palette.aquas[5],
      };
    case TagTypes.CODE:
      return {
        background: theme.colors.palette.grayscale[2],
        border: theme.colors.palette.grayscale[3],
        text: theme.colors.textPrimary,
      };
    case TagTypes.ADD_NEW:
      return {
        background: theme.colors.palette.white,
        border: theme.colors.palette.grayscale[4],
        text: theme.colors.textPrimary,
      };
    default:
      return {
        background: theme.colors.palette.grayscale[1],
        border: theme.colors.borderBase,
        button: theme.colors.palette.grayscale[6],
      };
  }
};

const resolvePaddingForSpan = (props: StyledSpanProps) => {
  const { $size, theme } = props;

  switch ($size) {
    case ComponentSizes.SMALL:
      return `${theme.space.spacing20} 0rem`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.spacing20} ${theme.space.tagPaddingMedium}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing10}`;
    default:
      return `${theme.space.spacing20} ${theme.space.tagPaddingMedium}`;
  }
};

const resolveFontSizeForSpan = ({ $size, theme }: StyledSpanProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.footnote;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.footnote;
  }
};

const resolveTagHeight = ({ size, theme }: StyledTagProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.xSmall;
    case ComponentSizes.MEDIUM:
      return theme.sizes.small;
    case ComponentSizes.LARGE:
      return theme.sizes.medium;
    default:
      return theme.sizes.small;
  }
};

const resolveLineHeightForSpan = ({ $size, theme }: StyledSpanProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.footnote;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.footnote;
  }
};

const resolveBorderColor = (props: StyledTagProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveTextColor = (props: StyledTagProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveBackgroundColor = (props: StyledTagProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveCloseButtonColor = (props: StyledTagProps) => {
  const { button } = resolvePalette(props);

  return button;
};

const resolveBorderStyle = ({ $type }: StyledTagProps) => {
  if ($type === TagTypes.ADD_NEW) {
    return 'dashed';
  }

  return 'solid';
};

const resolveBoxShadow = ({ theme, $type }: StyledTagProps) => {
  if ($type === TagTypes.ADD_NEW) {
    return theme.shadows.secondary;
  }

  return 'none';
};

const resolveActiveBoxShadow = ({ theme, $type }: StyledTagProps) => {
  if ($type === TagTypes.ADD_NEW) {
    return theme.shadows.secondary;
  }

  return 'none';
};

const StyledChipBase = styled(KendoChip)<{ $type?: TagTypes }>`
  && {
    height: ${resolveTagHeight};
    border: ${({ theme }) => theme.borderWidths.base} ${resolveBorderStyle}
      ${resolveBorderColor};
    color: ${resolveTextColor};
    background-color: ${resolveBackgroundColor};
    border-radius: ${({ theme }) => theme.radii.base};
    box-shadow: ${resolveBoxShadow};
    opacity: 1;

    &:focus {
      box-shadow: ${resolveActiveBoxShadow};
    }
  }

  & .k-chip-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  & .k-i-close:before {
    content: '\\2715';
    font-size: ${({ theme }) => theme.sizes.tagIcon};
    color: ${resolveCloseButtonColor};
    margin-top: ${({ theme }) => theme.space.tagIconMarginTop};
  }
`;

const StyledSpanText = styled.span<{ $size: KendoChipProps['size'] }>`
  font-size: ${resolveFontSizeForSpan};
  line-height: ${resolveLineHeightForSpan};
  padding: ${resolvePaddingForSpan};
`;
