import { forwardRef } from 'react';

import {
  AccordionProps,
  Accordion as BootstrapAccordion,
} from 'react-bootstrap';

import { Body } from './Body';
import { Collapse } from './Collapse';
import { Header } from './Header';
import { Item } from './Item';

export const Accordion = Object.assign(
  forwardRef<HTMLDivElement, AccordionProps>((props, ref) => {
    return <BootstrapAccordion ref={ref} {...props} />;
  }),
  {
    Body,
    Header,
    Item,
    Collapse,
  },
);

Accordion.displayName = 'Accordion';
