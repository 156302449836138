import { SHARE_BASE_ROUTE_ID } from 'features/main/constants';

import { AuthenticationScheme, StandardClaimNames, StandardScopes } from '../constants';
import { Claim } from '../types/Claim';
import { ClaimsTransformer } from '../types/ClaimsTransformer';

const whitespaceRegex = new RegExp(/\S+/g);

function copyScopeClaimValues(values: string | string[] | null | undefined): string[] {
  if (Array.isArray(values)) return [...values];
  if (values == null) return [];
  return values.match(whitespaceRegex) ?? [];
}

const shareLinkIdMatch: ClaimsTransformer = ({ claims, activeScheme, shareToken, routeMatches }) => {
  // Validate that the route linkId param matches the linkId specified in the share JWT.

  if (activeScheme !== AuthenticationScheme.SHARE) return claims;
  if (shareToken == null) return claims;

  const shareBaseRoute = routeMatches.find((m) => m.id === SHARE_BASE_ROUTE_ID);
  const routeLinkIdParam = shareBaseRoute?.params?.linkId?.toLowerCase() ?? undefined;

  if (routeLinkIdParam == null) return claims;
  if (routeLinkIdParam !== shareToken.shareLinkId) return claims;

  // The route linkId param matches the JWT.  Append to the scopes claim StandardScopes.SHARE_LINK_ID_MATCH.
  const newClaims = [...claims];
  const scopesClaimIndex = newClaims.findIndex((c) => c.name === StandardClaimNames.SCOPE);
  const newScopesClaim: Claim<string[]> = {
    name: StandardClaimNames.SCOPE,
    value: scopesClaimIndex >= 0 ? copyScopeClaimValues(newClaims[scopesClaimIndex].value as string | string[]) : [],
  };
  newScopesClaim.value.push(StandardScopes.SHARE_LINK_ID_MATCH);

  if (scopesClaimIndex >= 0) {
    newClaims[scopesClaimIndex] = newScopesClaim;
  } else {
    newClaims.push(newScopesClaim);
  }

  return newClaims;
};

export const authorizationService = {
  shareLinkIdMatch,
};
