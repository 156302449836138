import { ComponentType, memo, useEffect, useMemo, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import styled from 'styled-components';

import { ExamModel } from 'models';

import { DataResult } from 'core/api';
import { useAsyncCallback, useBoolean, useDebounceEmitter, useEvent } from 'core/hooks';
import { Action, ActionButtonListCell, Button, DEFAULT_PAGE_SIZES, DataTable, DateCell, HeaderCell, Modal, TextCell } from 'core/ui';

import { apiClient } from 'features/api';
import { useSessionLocation } from 'features/location';

import { ExamSelectModalProps } from '../types';

const PAGEABLE_SETTINGS = { pageSizes: DEFAULT_PAGE_SIZES };

const DEFAULT_DATA_STATE = {
  skip: 0,
  take: 10,
} satisfies State;

export const ExamSelectModal = memo<ExamSelectModalProps>(({ show, selectedExamId, onClose, onExamFormSelected }) => {
  const { sessionLocation } = useSessionLocation(true);

  const [exams, setExams] = useState<DataResult<ExamModel> | null>(null);
  const [dataState, setDataState] = useState<State>(DEFAULT_DATA_STATE);
  const [isContentVisibleOverride, { setTrue: setIsContentVisibleOverrideTrue, setFalse: setIsContentVisibleOverrideFalse }] = useBoolean(false);

  const isContentVisible = show || isContentVisibleOverride;

  const [fetchExams, isLoading] = useAsyncCallback(async (signal, dataStateOverride?: State) => {
    console.log('fetchExams', { sessionLocation });

    const queryDataState = {
      ...(dataStateOverride ?? dataState),
    } satisfies State;
    const originalFilter = queryDataState.filter;

    queryDataState.filter = {
      logic: 'and',
      filters: [
        {
          field: 'location_Id',
          operator: 'eq',
          value: sessionLocation.id,
        },
      ],
    };

    if (originalFilter != null) {
      queryDataState.filter.filters.push(originalFilter);
    }

    const result = await apiClient.exams.getAllForKendoGrid(queryDataState, signal);
    setExams(result);
  });

  const { emitDebounce, clearDebounce } = useDebounceEmitter(() => {
    fetchExams();
  }, 500);

  const initialize = useEvent(() => {
    const newDataState: State = DEFAULT_DATA_STATE;

    setExams(null);
    setDataState(newDataState);
    clearDebounce();
    fetchExams(newDataState);
  });

  const reset = useEvent(() => {
    setExams(null);
    setDataState(DEFAULT_DATA_STATE);
    clearDebounce();
  });

  const handleDataStateChange = useEvent((event: GridDataStateChangeEvent) => {
    let isEventFromPager = false;
    let isEventFromSortClick = false;

    // Determine if the event originates within the pager.
    if (event.syntheticEvent.target instanceof Element) {
      isEventFromPager = event.syntheticEvent.target.closest('.k-pager') != null;
    }

    // Determine if the event is the result of the user clicking a sortable header cell.
    if (event.syntheticEvent.type === 'click' && event.syntheticEvent.target instanceof Element) {
      // We have to make sure we are within the separate <table> element for the headers, but we also have to make sure we aren't in the filter row.
      isEventFromSortClick =
        event.syntheticEvent.target.closest('.k-grid-header-table') != null && event.syntheticEvent.target.closest('.k-filter-row') == null;
    }

    setDataState(event.dataState);

    if (isEventFromPager || isEventFromSortClick) {
      clearDebounce();
      fetchExams(event.dataState);
    } else {
      emitDebounce();
    }
  });

  const [handleExamSelect] = useAsyncCallback(async (signal, _event: unknown, exam: ExamModel) => {
    // Need to fetch the exam from the server because the grid only returns a subset of the data.
    const newExam = await apiClient.exams.getExamById(exam.id!);

    signal.throwIfAborted();

    onClose();
    onExamFormSelected(newExam);
  });

  const selectedState = useMemo(
    () =>
      selectedExamId != null
        ? {
            [selectedExamId]: true,
          }
        : {},
    [selectedExamId],
  );

  const gridActions: Action[] = useMemo(
    () => [
      {
        key: 'select',
        text: 'Select',
        onClick: handleExamSelect,
      },
    ],
    [handleExamSelect],
  );

  // Initialize/reset when the modal switches between visible and hidden.
  useEffect(() => {
    if (isContentVisible) {
      initialize();
    } else {
      reset();
    }
  }, [initialize, reset, isContentVisible]);

  return (
    <StyledModal
      show={show}
      onHide={onClose}
      title="Select Existing Exam"
      onEnter={setIsContentVisibleOverrideTrue}
      onExited={setIsContentVisibleOverrideFalse}
    >
      <StyledDialogBodyDiv>
        <StyledDataTable
          isLoading={isLoading}
          data={exams}
          actions={gridActions}
          onDataStateChange={handleDataStateChange}
          filterable
          sortable
          pageable={PAGEABLE_SETTINGS}
          selectedState={selectedState}
          {...dataState}
        >
          <GridColumn
            filterable={false}
            reorderable={false}
            sortable={false}
            headerCell={HeaderCell}
            cell={ActionButtonListCell as ComponentType<GridCellProps>}
            width="65px"
          />
          <GridColumn field="serviceDescription" title="Study Type" headerCell={HeaderCell} cell={TextCell} width="110px" />
          <GridColumn field="customerUID" title="Customer UID" headerCell={HeaderCell} cell={TextCell} width="110px" />
          <GridColumn field="studyDate" title="Study Date" headerCell={HeaderCell} cell={DateCell} width="110px" format="MM/DD/YYYY" />
          <GridColumn field="studyTime" title="Study Time" headerCell={HeaderCell} cell={DateCell} width="50px" format="HH:mm:ss" />
          <GridColumn field="description" title="Description" headerCell={HeaderCell} cell={TextCell} />
        </StyledDataTable>
      </StyledDialogBodyDiv>
      <StyledActionButtonsDiv>
        <Button onClick={onClose}>Close</Button>
      </StyledActionButtonsDiv>
    </StyledModal>
  );
});

ExamSelectModal.displayName = 'ExamSelectModal';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 800px;
  }
`;

const StyledDialogBodyDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;

const StyledDataTable = styled(DataTable)`
  height: 430px;
`;

const StyledActionButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 0 0;
`;
