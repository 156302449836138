import { FunctionComponent, useCallback } from 'react';

import { ButtonsPopupSettings, DropDownButtonCloseEvent, DropDownButtonOpenEvent, DropDownButton as KendoDropdownButton } from '@progress/kendo-react-buttons';
import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import './DropdownButton.scss';
import { DropdownButtonItem } from './DropdownButtonItem';
import { DropdownButtonProps } from './DropdownButtonProps';

const DefaultPopupSettings: ButtonsPopupSettings = {
  popupClass: 'dropdown-button-popup',
};

export const DropdownButton: FunctionComponent<DropdownButtonProps> = ({
  itemRender = DropdownButtonItem,
  popupSettings = DefaultPopupSettings,
  size = ComponentSizes.MEDIUM,
  onClose,
  onOpen,
  ...rest
}) => {
  const handleClose = useCallback(
    (event: DropDownButtonCloseEvent) => {
      // TODO: This needs documentation on why the event handler needs to call stopPropagation().
      event.syntheticEvent.stopPropagation();
      onClose?.(event);
    },
    [onClose],
  );

  const handleOpen = useCallback(
    (event: DropDownButtonOpenEvent) => {
      // TODO: This needs documentation on why the event handler needs to call stopPropagation().
      event.syntheticEvent.stopPropagation();
      onOpen?.(event);
    },
    [onOpen],
  );

  return <StyledDropdownButton itemRender={itemRender} popupSettings={popupSettings} size={size} onClose={handleClose} onOpen={handleOpen} {...rest} />;
};

DropdownButton.displayName = 'DropdownButton';

type StyledElementProps = {
  theme: DefaultTheme;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large' | null;
};

const resolvePalette = ({ theme, disabled }: StyledElementProps) => {
  if (disabled) {
    return {
      background: theme.colors.backgroundDisabled,
      border: theme.colors.borderDisabled,
      textHover: theme.colors.textDisabled,
      text: theme.colors.textDisabled,
    };
  }

  return {
    background: theme.colors.palette.white,
    border: theme.colors.borderBase,
    textHover: theme.colors.palette.aquas[5],
    text: theme.colors.textPrimary,
  };
};

const resolveBackgroundColor = (props: StyledElementProps) => {
  const { background } = resolvePalette(props);

  return background;
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveHoverColor = (props: StyledElementProps) => {
  const { textHover } = resolvePalette(props);

  return textHover;
};

const resolveBorderColor = (props: StyledElementProps) => {
  const { border } = resolvePalette(props);

  return border;
};

const resolveFontSize = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const resolvePadding = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return `${theme.space.paddingVerticalSmall} ${theme.space.spacing20}`;
    case ComponentSizes.MEDIUM:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing40}`;
    case ComponentSizes.LARGE:
      return `${theme.space.spacing20} ${theme.space.spacing40}`;
    default:
      return `${theme.space.paddingVerticalMedium} ${theme.space.spacing40}`;
  }
};

const resolveHeight = ({ theme, size }: StyledElementProps) => {
  switch (size) {
    case ComponentSizes.SMALL:
      return theme.sizes.small;
    case ComponentSizes.MEDIUM:
      return theme.sizes.medium;
    case ComponentSizes.LARGE:
      return theme.sizes.large;
    default:
      return theme.sizes.medium;
  }
};

const StyledDropdownButton = styled(KendoDropdownButton)`
  & .k-button {
    background: ${resolveBackgroundColor};
    border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
    box-shadow: ${({ theme }) => theme.shadows.secondary};
    border-radius: ${({ theme }) => theme.radii.scale[3]};
    color: ${resolveColor};
    font-size: ${resolveFontSize};
    height: ${resolveHeight};
    line-height: ${resolveLineHeight};
    padding: ${resolvePadding};

    &:hover {
      background: ${resolveBackgroundColor};
      border: ${({ theme }) => theme.borderWidths.base} solid ${resolveBorderColor};
      box-shadow: ${({ theme }) => theme.shadows.secondary};
      border-radius: ${({ theme }) => theme.radii.scale[3]};
      color: ${resolveHoverColor};
      font-size: ${resolveFontSize};
      line-height: ${resolveLineHeight};
    }

    &:focus {
      box-shadow: none;
    }

    & .k-icon {
      order: 2;
    }
  }
`;
