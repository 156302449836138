import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { formatCurrency } from 'core/utils';

import { CurrencyTextProps } from './CurrencyTextProps';

export const CurrencyText: FunctionComponent<CurrencyTextProps> = ({
  value,
}) => {
  const formattedValue = value == null ? '' : formatCurrency(value);

  return (
    <>
      {formattedValue
        .split('') // Split over nothing converts the string into an array of characters so that we may call map() on the next line.
        .map((currentChar) =>
          currentChar === ',' ? (
            <StyledDigitGroupSeparator $separatorCharacter="," />
          ) : (
            <>{currentChar}</>
          ),
        )}
    </>
  );
};

const StyledDigitGroupSeparator = styled.span<{
  $separatorCharacter: string;
}>`
  ::before {
    content: '${({ $separatorCharacter }) => $separatorCharacter}';
  }
`;
