import { KeepStateOptions } from 'react-hook-form';

export enum FormStates {
  ADD = 'add',
  EDIT = 'edit',
  DISABLED = 'disabled',
}

export const RHF_FULL_RESET: KeepStateOptions = {
  keepDefaultValues: false,
  keepDirty: false,
  keepDirtyValues: false,
  keepErrors: false,
  keepIsSubmitted: false,
  keepIsValid: false,
  keepSubmitCount: false,
  keepTouched: false,
  keepValues: false,
};

export const RHF_DEFAULTS_CHANGE: KeepStateOptions = {
  keepValues: true,
  keepDefaultValues: false,
};
