import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { LocationFinalReportTemplateGridModel, LocationFinalReportTemplateModel } from 'models';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class LocationFinalReportTemplateClient {
  constructor(private httpClient: HttpClient) {
    this.getLocationFinalReportTemplateById = this.getLocationFinalReportTemplateById.bind(this);
    this.getAllLocationFinalReportTemplateForKendoGrid = this.getAllLocationFinalReportTemplateForKendoGrid.bind(this);
    this.createLocationFinalReportTemplate = this.createLocationFinalReportTemplate.bind(this);
    this.updateLocationFinalReportTemplate = this.updateLocationFinalReportTemplate.bind(this);
  }

  public async getLocationFinalReportTemplateById(locationFinalReportTemplateId: number) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const locationFinalReportTemplate = await this.httpClient.fetchAndParse<LocationFinalReportTemplateModel>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/LocationFinalReportTemplate/${encodeURIComponent(locationFinalReportTemplateId)}`, options),
    );
    return locationFinalReportTemplate;
  }

  public async getAllLocationFinalReportTemplateForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<LocationFinalReportTemplateGridModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/LocationFinalReportTemplate/grid?${queryStr}`, options),
    );
    return result;
  }

  public async createLocationFinalReportTemplate(source: LocationFinalReportTemplateModel) {
    const options = await this.httpClient.createStandardOptions('POST', source);
    const result = await this.httpClient.fetchAndParse<number>(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/LocationFinalReportTemplate`, options));
    return result;
  }

  public async updateLocationFinalReportTemplate(destinationRoute: LocationFinalReportTemplateModel) {
    const options = await this.httpClient.createStandardOptions('PUT', destinationRoute);
    const result = await this.httpClient.fetch(fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/LocationFinalReportTemplate`, options));
    return result;
  }
}
