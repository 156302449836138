import { TemplateModel } from 'models';

import { hasText } from 'core/utils';

import { EditFormValues } from '../types';

const EditFormDefaults: EditFormValues = {
  type: '',
  name: '',
  description: '',
  content: '',
  active: true,
};

function copyModelToForm(model: TemplateModel): EditFormValues {
  return {
    type: model.type,
    name: model.name,
    description: model.description ?? '',
    content: model.content,
    active: model.active,
  };
}

function copyFormToModel(
  templateId: number,
  formValues: EditFormValues,
): TemplateModel {
  return {
    id: templateId,
    type: formValues.type.trim(),
    name: formValues.name.trim(),
    description: hasText(formValues.description)
      ? formValues.description.trim()
      : null,
    content: formValues.content, // Don't trim this because it's possible that users want to actually have blank space at the beginning or end of the template content.
    active: formValues.active,
  };
}

function createDefaultTemplate(): TemplateModel {
  return {
    id: 0,
    type: '',
    name: '',
    description: null,
    content: '',
    active: true,
  };
}

export const TemplateEditService = {
  EditFormDefaults,
  copyModelToForm,
  copyFormToModel,
  createDefaultTemplate,
};
