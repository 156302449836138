import { FunctionComponent, useCallback } from 'react';

import { faArrowLeft, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import {
  BreakpointSelectors,
  Button,
  Card,
  ChipList,
  Icon,
  Label,
  Page,
} from 'core/ui';

import { useFiltersState } from '../hooks';
import { MobileFiltersContext } from '../types';

export const MobileFilters: FunctionComponent = () => {
  const navigate = useNavigate();

  const { studyTypes, organs, filter, onFilterChange } =
    useOutletContext<MobileFiltersContext>();

  const {
    studyTypeOptions,
    handleStudyTypeChipChange,
    organOptions,
    handleOrganChipChange,
  } = useFiltersState(studyTypes, organs, filter, onFilterChange);

  const handleBackClick = useCallback(() => {
    navigate('..');
  }, [navigate]);

  return (
    <StyledPage>
      <StyledCard>
        <h2>
          <Icon icon={faFilter} />
          Filters
        </h2>
        <hr />
        <StyledBody>
          <StyledLabel>Study Types:</StyledLabel>
          <ChipList
            selection="multiple"
            data={studyTypeOptions}
            textField="name"
            valueField="value"
            value={filter.studyTypes}
            onChange={handleStudyTypeChipChange}
            allowOtherOption={false}
          />

          <StyledLabel>Organs:</StyledLabel>
          <ChipList
            selection="multiple"
            data={organOptions}
            textField="name"
            valueField="value"
            value={filter.organs}
            onChange={handleOrganChipChange}
            allowOtherOption={false}
          />
        </StyledBody>
        <hr />
        <StyledButtonContainer>
          <Button
            type="button"
            onClick={handleBackClick}
            className={`testing_${MobileFilters.displayName}_back-button`}
          >
            <Icon icon={faArrowLeft} />
            Back
          </Button>
        </StyledButtonContainer>
      </StyledCard>
    </StyledPage>
  );
};

MobileFilters.displayName = 'MobileFilters';

const StyledPage = styled(Page)`
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: start;

  ${BreakpointSelectors.Desktop} & {
    grid-template-columns: 500px;
    padding: 32px 0 0 0;
  }

  ${BreakpointSelectors.Mobile} & {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled(Card)`
  padding: 0;

  ${BreakpointSelectors.Mobile} & {
    border-radius: 0;
  }

  h2 {
    margin: 0;
    font-size: 20px;
    line-height: 32px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    user-select: none;
    padding: 6px 9px;
  }

  hr {
    background-color: ${({ theme }) => theme.colors.palette.grayscale[5]};
    margin: 0;
  }
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 9px;
  row-gap: 20px;
  column-gap: 9px;
`;

const StyledLabel = styled(Label)`
  white-space: nowrap;

  && {
    margin: 0;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  padding: 9px;

  button {
    flex: 1 1 0;
  }
`;
