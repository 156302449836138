import { resultSetAdapter } from './reducer';

const rootSelector = (state) => state.resultSets;

const {
  selectById: getById,
  selectAll: getAll,
  selectTotal: getCount,
} = resultSetAdapter.getSelectors(rootSelector);

export const ResultSetSelectors = {
  getById,
  getAll,
  getCount,
};
