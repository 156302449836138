import {
  ValidatorBag,
  combineValidators,
  email,
  emailList,
  maxLength,
  minLength,
  required,
} from 'core/forms';

export const locationFormValidators: ValidatorBag = {
  name: combineValidators([required, maxLength(255)]),
  code: required,
  user: maxLength(50),
  address1: maxLength(255),
  address2: maxLength(255),
  city: maxLength(50),
  state: maxLength(15),
  zip: combineValidators([minLength(5), maxLength(10)]),
  contAmount: required,
  contractType: maxLength(50),
  pfwClass: maxLength(50),
  email: emailList(';'),
  email2: email,
  serial: maxLength(255),
  magicPfwImport: maxLength(20),
  mailingAddress: maxLength(255),
  siteContact: maxLength(255),
  contactTitle: maxLength(20),
  comments: maxLength(500),
  allTxmtEcgOverread: maxLength(20),
  overreadRequire: combineValidators([required, maxLength(20)]),
  overreadResultsMethod: maxLength(20),
  overreadInstruction: maxLength(255),
  alias: maxLength(255),
  aliasShort: maxLength(255),
  customerAlias: maxLength(255),
  customerAliasShort: maxLength(255),
  timeZone: maxLength(50),
  facilityId: maxLength(255),
  aeTitle: maxLength(50),
};
