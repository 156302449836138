import { memo } from 'react';

import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { MaskedTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { PatientModel } from 'models';

import { FieldContainer, useFieldValueChangeHandler, useFormStateEmitter } from 'core/forms';
import { RHF_DEFAULTS_CHANGE, RHF_FULL_RESET } from 'core/forms/constants';
import { useBoolean, useEvent } from 'core/hooks';
import { Button, ErrorMessage, Icon, InputField, MASKED_DATETIME_INPUT_MODES, MaskedDateTimeInputField, PrimaryCard } from 'core/ui';
import { DropdownNgField } from 'core/ui/DropdownNg';
import { isPromise } from 'core/utils';

import { useSessionLocation } from 'features/location';
import { PatientUtils, usePatientVerbiage } from 'features/patient';
import { PatientGenders } from 'features/patient/constants';
import { PatientSelectModal } from 'features/patient/fragments/PatientSelectModal';

import { ExamUploadService } from '../services';
import { PatientFormProps, PatientFormValues } from '../types';

type PatientFormValuesInternal = PatientFormValues & {
  age: string;
};

export const PatientForm = memo<PatientFormProps>(
  ({ className, patient, initialValues, showDirtyError, isFixedPatient, onSave, onChange, onFormStateChange, onPatientModelSelected }) => {
    const patientVerbiage = usePatientVerbiage();
    const { sessionLocation } = useSessionLocation(true);
    const [isSelectPatientModalOpen, { setTrue: openSelectPatientModal, setFalse: closeSelectPatientModal }] = useBoolean(false);

    const rhfContext = useForm<PatientFormValuesInternal>({
      defaultValues: {
        ...initialValues,
        age: PatientUtils.calculateAgeFromDob(initialValues.dob),
      },
    });

    const handleFieldChange = useFieldValueChangeHandler(onChange, rhfContext);

    useFormStateEmitter(onFormStateChange, rhfContext);

    const handleCreateNewPatientClick = useEvent(() => {
      onPatientModelSelected(null);
    });

    const handlePatientFormSelected = useEvent(async (newPatient: PatientModel | null) => {
      const newFormValues = ExamUploadService.patientModelToForm(newPatient);

      rhfContext.reset(
        {
          ...newFormValues,
          age: PatientUtils.calculateAgeFromDob(newPatient?.dob),
        },
        RHF_FULL_RESET,
      );

      onChange(newFormValues);
      onPatientModelSelected(newPatient);
    });

    const handleDobChange = useEvent((event: MaskedTextBoxChangeEvent) => {
      rhfContext.setValue('age', PatientUtils.calculateAgeFromDob(event.value));
      handleFieldChange(event);
    });

    const handleSubmit: SubmitHandler<PatientFormValuesInternal> = useEvent(async (form) => {
      const saveResult = onSave?.();

      if (isPromise(saveResult)) {
        await saveResult;
      }

      rhfContext.reset(form, RHF_DEFAULTS_CHANGE);
    });

    return (
      <>
        <PatientSelectModal
          show={isSelectPatientModalOpen}
          selectedPatientId={null}
          locationId={sessionLocation.id}
          onClose={closeSelectPatientModal}
          onPatientFormSelected={handlePatientFormSelected}
        />
        <FormProvider {...rhfContext}>
          <PrimaryCard>
            <PrimaryCard.Header>
              <Icon icon={faUser} block fixedWidth={false} />
              <PrimaryCard.HeaderText>{patientVerbiage}</PrimaryCard.HeaderText>
            </PrimaryCard.Header>
            <PrimaryCard.Body $noPadding>
              <StyledForm
                className={className}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="false"
                noValidate
                onSubmit={rhfContext.handleSubmit(handleSubmit)}
              >
                {!isFixedPatient && (
                  <StyledPatientSelectionDiv>
                    <Button id={`${PatientForm.displayName}_search-patients-button`} onClick={openSelectPatientModal}>
                      Select Existing {patientVerbiage}
                    </Button>
                    <Button id={`${PatientForm.displayName}_create-new-patient-button`} onClick={handleCreateNewPatientClick}>
                      Create New {patientVerbiage}
                    </Button>
                  </StyledPatientSelectionDiv>
                )}

                <FieldContainer>
                  <InputField name="firstName" required label="First Name" onChange={handleFieldChange} />
                </FieldContainer>

                <FieldContainer>
                  <InputField name="lastName" required label="Last Name" onChange={handleFieldChange} />
                </FieldContainer>

                <FieldContainer>
                  <MaskedDateTimeInputField name="dob" label="DOB" required mode={MASKED_DATETIME_INPUT_MODES.DATE} onChange={handleDobChange} />
                </FieldContainer>

                <FieldContainer>
                  <InputField name="age" required label="Age" readOnly onChange={handleFieldChange} />
                </FieldContainer>

                <FieldContainer>
                  <DropdownNgField name="gender" label="Gender" dataItemKey="value" data={PatientGenders} onChange={handleFieldChange} />
                </FieldContainer>

                <FieldContainer>
                  <InputField name="patientNumber" label={`MRN/${patientVerbiage} ID`} onChange={handleFieldChange} />
                </FieldContainer>

                <FieldContainer>
                  <InputField name="unosID" label="UNOS ID" onChange={handleFieldChange} />
                </FieldContainer>

                <StyledButtonsDiv>
                  <Button type="submit" disabled={!(rhfContext.formState.isDirty || patient == null)}>
                    Save {patientVerbiage}
                  </Button>
                </StyledButtonsDiv>

                <div>
                  {showDirtyError && patient == null && <ErrorMessage>{patientVerbiage} has not been created.</ErrorMessage>}
                  {showDirtyError && patient != null && rhfContext.formState.isDirty && <ErrorMessage>Unsaved changes.</ErrorMessage>}
                </div>
              </StyledForm>
            </PrimaryCard.Body>
          </PrimaryCard>
        </FormProvider>
      </>
    );
  },
);

PatientForm.displayName = 'PatientForm';

const StyledForm = styled.form`
  display: grid;
  column-gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledPatientSelectionDiv = styled.div`
  padding-bottom: 8px;

  button + button {
    margin-left: 8px;
  }
`;

const StyledButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
