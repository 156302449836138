import { normalize } from 'normalizr';

import { BrandingModel } from 'models';

import { ApiActions, ApiResponse, createAsyncThunk } from 'core/api';

import { BrandingRouteService } from '../services';
import {
  NormalizeBrandingModelSchema,
  brandingEntity,
} from './normalizr-schema';

const getAll = createAsyncThunk(
  'brandings/get-all',
  async (_: unknown, { dispatch }) => {
    const { result } = (await dispatch(
      ApiActions.get(BrandingRouteService.getBaseRoute()),
    )) as unknown as ApiResponse<BrandingModel>;

    const normalized = normalize<BrandingModel, NormalizeBrandingModelSchema>(
      result,
      [brandingEntity],
    );

    return normalized.entities;
  },
);

export const BrandingActions = {
  getAll,
};
