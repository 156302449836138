import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';

import { HL7SendModel } from 'models/HL7SendModel';

import { DEFAULT_DATA_TABLE_DATA_STATE, DEFAULT_PAGE_SIZES, DataTable, HeaderCell, Page, PageHeader, TextCell, styled } from 'core/ui';

import { apiClient } from 'features/api';

export const HL7SendHome: FunctionComponent = () => {
  const [hl7SendData, setHL7SendData] = useState<HL7SendModel[]>([]);
  const [hl7SendTotal, setHL7SendTotal] = useState(0);
  const [dataState, setDataState] = useState(DEFAULT_DATA_TABLE_DATA_STATE);

  useEffect(() => {
    (async () => {
      const queryResult = await apiClient.hl7SendClient.getAllSourcesForKendoGrid(dataState);

      setHL7SendData(queryResult.data);
      setHL7SendTotal(queryResult.total);
    })();
  }, [dataState]);

  const PageableSettings = {
    pageSizes: DEFAULT_PAGE_SIZES,
  };

  const handleDataStateChange = useCallback((changeEvent: GridDataStateChangeEvent) => {
    setDataState(changeEvent.dataState);
  }, []);

  return (
    <Page>
      <PageHeader title="HL7Send" />
      <StyledDataTable
        data={hl7SendData}
        filterable
        sortable
        pageable={PageableSettings}
        total={hl7SendTotal}
        onDataStateChange={handleDataStateChange}
        {...dataState}
      >
        <GridColumn title="Id" field="id" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={80} />
        <GridColumn title="Control Id" field="controlId" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Integration" field="integraiton" filter="text" headerCell={HeaderCell} cell={TextCell} />
        <GridColumn title="Message" field="message" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Exam Id" field="examId" filter="numeric" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Date Sent" field="dateSent" filter="date" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Response" field="response" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Date Created" field="dateCreated" filter="date" headerCell={HeaderCell} cell={TextCell} width={100} />
        <GridColumn title="User Created" field="userCreated" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="Date Modified" field="dateModified" filter="date" headerCell={HeaderCell} cell={TextCell} width={300} />
        <GridColumn title="User Modified" field="User Modified" filter="text" headerCell={HeaderCell} cell={TextCell} width={300} />
      </StyledDataTable>
    </Page>
  );
};

HL7SendHome.displayName = 'HL7SendHome';

const StyledDataTable = styled(DataTable)`
  max-height: initial;
`;
