import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';

import { DCMStudyInstanceModel } from 'models/DCMStudyInstanceModel';

import { ApiRouteService } from '../services';
import { DataResult } from '../types';
import { HttpClient } from './HttpClient';

export class DICOMClient {
  constructor(private httpClient: HttpClient) {
    this.getAllSourcesForKendoGrid = this.getAllSourcesForKendoGrid.bind(this);
  }
  public async getAllSourcesForKendoGrid(dataState: DataSourceRequestState) {
    const options = await this.httpClient.createStandardOptions('GET', null);
    const queryStr = toDataSourceRequestString(dataState);
    const result = await this.httpClient.fetchAndParse<DataResult<DCMStudyInstanceModel>>(
      fetch(`${ApiRouteService.getCompumedApiBaseRoute()}/api/DICOM/grid?${queryStr}`, options),
    );
    return result;
  }
}
