import { FunctionComponent } from 'react';

import _get from 'lodash/get';
import styled from 'styled-components';

import { HighlightText } from '../../../HighlightText';
import { TextCellProps } from './TextCellProps';

export const NumberCell: FunctionComponent<TextCellProps> = ({ className, ellipsisPlacement = 'end', searchValue = '', valueTransform, dataItem, field }) => {
  const value = valueTransform ? valueTransform(_get(dataItem, field || '')) : _get(dataItem, field || '');

  // convert value to string in case it is a number,
  // also works if value is already a string
  return (
    <StyledTdText className={className} $ellipsisPlacement={ellipsisPlacement}>
      <HighlightText searchValue={searchValue}>{value?.toString()}</HighlightText>
    </StyledTdText>
  );
};

NumberCell.displayName = 'NumberCell';

const resolveTextDirection = ({ $ellipsisPlacement }: { $ellipsisPlacement: 'start' | 'end' }) => {
  if ($ellipsisPlacement === 'start') {
    return 'rtl';
  }

  return 'ltr';
};

const StyledTdText = styled.td<{ $ellipsisPlacement: 'start' | 'end' }>`
  direction: ${resolveTextDirection};

  &&& {
    text-align: right;
    padding-right: 1em;
  }
`;
