import styled from 'styled-components';

export const FieldContainer = styled.div<{
  /** Sets "display: none;" on the .k-label element.  Useful when the label needs to be placed outside the <Field> component.  Defaults to false. */
  $hideLabel?: boolean;
  /** Adjusts the minimum size to be more appropriate for a checkbox. */
  $forCheckbox?: boolean;
}>`
  min-height: ${({ $hideLabel, $forCheckbox }) => ($forCheckbox ? '35' : $hideLabel ? '43' : '63')}px;
  padding-bottom: 0;

  ${({ $hideLabel = false }) => ($hideLabel ? '.k-label { display: none; }' : '')}
`;
