const sizesScale = ['0rem', '1rem', '1.5rem', '2rem', '2.5rem'];

const xSmall = sizesScale[1];
const small = sizesScale[2];
const medium = sizesScale[3];
const large = sizesScale[4];

const badgeDotHeight = '0.375rem';
const badgeDotWidth = '0.375rem';
const badgeHeightDefault = '1.25rem';
const badgeWidthDefault = '1.25rem';
const dataTableHeaderCellIcon = '0.313rem';
const dataTableRowHeightLarge = '3.5rem';
const dataTableRowHeightMedium = '3rem';
const dataTableRowHeightSmall = '1rem';
const modalIcon = '1.375rem';
const modalWidth = '26rem';
const progressBarHeightDefault = '0.375rem';
const progressBarHeightLarge = '0.5rem';
const progressBarLabelIconDefault = '0.625rem';
const progressBarLabelIconLarge = '0.875rem';
const sidebarExpandIconContainerHeight = '3.5rem';
const sidebarWidthMinimized = '48px';
const statusHeight = '0.375rem';
const statusWidth = '0.375rem';
const tagIcon = '0.813rem';
const examThumbnailMinWidth = '120px';
const examThumbnailMinHeight = '120px';
const examThumbnailButtonBorderSize = '4px';

export const sizes = {
  scale: sizesScale,
  xSmall,
  small,
  medium,
  large,
  badgeDotHeight,
  badgeDotWidth,
  badgeHeightDefault,
  badgeWidthDefault,
  dataTableHeaderCellIcon,
  dataTableRowHeightLarge,
  dataTableRowHeightMedium,
  dataTableRowHeightSmall,
  modalIcon,
  modalWidth,
  progressBarHeightDefault,
  progressBarHeightLarge,
  progressBarLabelIconDefault,
  progressBarLabelIconLarge,
  sidebarExpandIconContainerHeight,
  sidebarWidthMinimized,
  statusHeight,
  statusWidth,
  tagIcon,
  examThumbnailMinWidth,
  examThumbnailMinHeight,
  examThumbnailButtonBorderSize,
} as const;

export type Sizes = typeof sizes;
