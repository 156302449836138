import { FunctionComponent } from 'react';

import { TextFilterProps } from '@progress/kendo-react-data-tools';

import { DropdownWithValuesField } from 'core/ui';

type DropdownFilterProps = {
  data: any[];
} & TextFilterProps;

export const DropdownFilter: FunctionComponent<DropdownFilterProps> = ({
  data,
  filter,
  onFilterChange,
}) => {
  const onChange = (event: { value: string | number }) => {
    onFilterChange({
      nextFilter: {
        ...filter,
        operator: filter.operator,
        value: event.value,
      },
    });
  };

  return (
    <DropdownWithValuesField
      data={data}
      dataItemKey="id"
      filterable={false}
      isForPrimitiveValues
      onChange={onChange}
      textField="name"
      value={filter.value}
      valueField="id"
      valid
    />
  );
};
