import { FunctionComponent } from 'react';

import styled, { DefaultTheme } from 'styled-components';

import { ComponentSizes } from '../constants';
import { DropdownButtonItemProps } from './DropdownButtonItemProps';

export const DropdownButtonItem: FunctionComponent<DropdownButtonItemProps> = ({
  item,
  size = ComponentSizes.MEDIUM,
}) => {
  return (
    <StyledDivWrapper $disabled={item.disabled} $size={size}>
      {item.label}
    </StyledDivWrapper>
  );
};

DropdownButtonItem.displayName = 'DropdownButtonItem';

type StyledElementProps = {
  theme: DefaultTheme;
  $disabled?: boolean;
  $size?: ComponentSizes;
};

const resolvePalette = ({ theme, $disabled }: StyledElementProps) => {
  if ($disabled) {
    return {
      background: theme.colors.palette.white,
      backgroundHover: theme.colors.palette.white,
      text: theme.colors.textDisabled,
    };
  }

  return {
    background: theme.colors.palette.white,
    backgroundHover: theme.colors.palette.grayscale[3],
    text: theme.colors.textPrimary,
  };
};

const resolveColor = (props: StyledElementProps) => {
  const { text } = resolvePalette(props);

  return text;
};

const resolveFontSize = ({ theme, $size }: StyledElementProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.fontSizes.body;
    case ComponentSizes.LARGE:
      return theme.fontSizes.subheading;
    default:
      return theme.fontSizes.body;
  }
};

const resolveLineHeight = ({ theme, $size }: StyledElementProps) => {
  switch ($size) {
    case ComponentSizes.SMALL:
    case ComponentSizes.MEDIUM:
      return theme.lineHeights.body;
    case ComponentSizes.LARGE:
      return theme.lineHeights.subheading;
    default:
      return theme.lineHeights.body;
  }
};

const StyledDivWrapper = styled.div<{
  $disabled?: boolean;
  $size?: ComponentSizes;
}>`
  background: transparent;
  color: ${resolveColor};
  font-size: ${resolveFontSize};
  line-height: ${resolveLineHeight};
  padding: ${({ theme }) =>
    `${theme.space.paddingVerticalMedium} ${theme.space.spacing30}`};
  width: 100%;
  text-transform: capitalize;

  &:hover {
    background: transparent;
  }
`;
