import PropTypes from 'prop-types';

import { Organs } from '../../exam/constants';
import { PathologyKidneyResultsAccordion } from './PathologyKidneyResultsAccordionOPTN';
import { PathologyLiverResultsAccordion } from './PathologyLiverResultsAccordion';
import { PathologyLungResultsAccordion } from './PathologyLungResultsAccordion';
import { PathologyOtherResultsAccordion } from './PathologyOtherResultsAccordion';

const PathologyResultsAccordion = ({
  isFormVisible,
  organ,
  valueGetter,
  valueSetter,
}) => {
  if (organ === Organs.LIVER.value) {
    return (
      <PathologyLiverResultsAccordion
        eventKey="6"
        isFormVisible={isFormVisible}
        valueGetter={valueGetter}
        valueSetter={valueSetter}
      />
    );
  }

  if (organ === Organs.KIDNEY.value) {
    return (
      <PathologyKidneyResultsAccordion
        eventKey="6"
        isFormVisible={isFormVisible}
        valueGetter={valueGetter}
        valueSetter={valueSetter}
      />
    );
  }

  if (organ === Organs.LUNGS.value) {
    return (
      <PathologyLungResultsAccordion
        eventKey="6"
        isFormVisible={isFormVisible}
        valueGetter={valueGetter}
        valueSetter={valueSetter}
      />
    );
  }

  if (organ === Organs.OTHER.value) {
    return (
      <PathologyOtherResultsAccordion
        eventKey="6"
        isFormVisible={isFormVisible}
        valueGetter={valueGetter}
        valueSetter={valueSetter}
      />
    );
  }

  return null;
};

PathologyResultsAccordion.propTypes = {
  isFormVisible: PropTypes.bool.isRequired,
  location: PropTypes.shape({ name: PropTypes.string }),
  organ: PropTypes.number.isRequired,
  valueGetter: PropTypes.func.isRequired,
  valueSetter: PropTypes.func.isRequired,
};

PathologyResultsAccordion.defaultProps = {
  location: null,
};

export { PathologyResultsAccordion };
