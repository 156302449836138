import { useEffect } from 'react';

import { IEventStreamConsumer } from 'core/utils';

import { useEvent } from './use-event';

/** Subscribes to the specified event stream and executes the handler any time an event is emitted. */
export function useEventStream<TEventType>(stream: IEventStreamConsumer<TEventType>, handlerCallback: (event: TEventType) => void) {
  const stableHandler = useEvent((event: TEventType) => {
    handlerCallback(event);
  });

  useEffect(() => {
    const unsub = stream.subscribe(stableHandler);
    return () => unsub();
  }, [stableHandler, stream]);
}
