import { memo } from 'react';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Card } from 'core/ui';

import ExternalLinkIcon from '../assets/external-link.svg?react';
import { PriorExamCardProps } from '../types';

export const PriorExamCard = memo<PriorExamCardProps>(({ exam, read }) => {
  return (
    <StyledPriorExamCard>
      <StyledCardHeader>
        {exam?.service?.description} - {exam?.description}
        <Link target="_blank" to={`/exam/${exam?.id}/read`}>
          <ExternalLinkIcon />
        </Link>
      </StyledCardHeader>
      {read && (
        <Card.Body>
          {read?.overRead}
          <StyledCardAuthorDiv>
            {read?.userCreated} -{' '}
            {dayjs(read?.dateCreated).format('MM/DD/YYYY')}
          </StyledCardAuthorDiv>
        </Card.Body>
      )}
    </StyledPriorExamCard>
  );
});

PriorExamCard.displayName = 'PriorExamCard';

const StyledPriorExamCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.palette.grayscale[1]};
  width: 100%;
  white-space: pre;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space.spacing30};
  }
`;

const StyledCardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
`;

const StyledCardAuthorDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.footnote};
  color: ${({ theme }) => theme.colors.palette.grayscale[5]};
`;
