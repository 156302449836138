// eslint-disable-next-line import/no-unresolved
import { ApiRouteService } from 'core/api';

const getBaseRoute = () =>
  `${ApiRouteService.getCompumedApiBaseRoute()}/api/item`;

const getByIdRoute = (id) => `${getBaseRoute()}/${id}`;

const getAllRoute = () => `${getBaseRoute()}/GetAllItems`;

export const ItemRouteService = {
  getBaseRoute,
  getByIdRoute,
  getAllRoute,
};
