import { FunctionComponent } from 'react';

import { Breadcrumb as KendoBreadcrumb } from '@progress/kendo-react-layout';
import styled from 'styled-components';

import { BreadcrumbProps } from './BreadcrumbProps';

const DefaultDelimiterComponent: FunctionComponent = () => <>/</>;
DefaultDelimiterComponent.displayName = 'DefaultDelimiterComponent';

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ breadcrumbDelimiter = DefaultDelimiterComponent, ...restProps }) => (
  <StyledBreadcrumb breadcrumbDelimiter={breadcrumbDelimiter} {...restProps} />
);

Breadcrumb.displayName = 'Breadcrumb';

const StyledBreadcrumb = styled(KendoBreadcrumb)`
  background-color: transparent;

  & .k-breadcrumb-root-link {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSizes.footnote};
    line-height: ${({ theme }) => theme.lineHeights.footnote};
    text-decoration: none;
    margin-inline-end: 0;

    &:focus {
      box-shadow: none;
    }
  }

  & .k-breadcrumb-item {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSizes.body};
    line-height: ${({ theme }) => theme.lineHeights.body};

    &:last-child .k-breadcrumb-link {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
`;
