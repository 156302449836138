import { memo, useState } from 'react';

import cn from 'classnames';
import styled from 'styled-components';

import { useEvent } from 'core/hooks';
import { BreakpointSelectors, Button, ComponentSizes, ErrorMessage } from 'core/ui';

import { useUploadManager } from 'features/file';

import { useUploadExamsPageContext } from '../hooks';
import { WizardStepKey } from '../types';
import { DicomExamUploadCard } from './DicomExamUploadCard';
import { NonDicomExamUploadCard } from './NonDicomExamUploadCard';
import { SelectFilesExamCard } from './SelectFilesExamCard';

export const SelectFilesStep = memo(() => {
  const { uploadGroups, allServices, setUploadGroups, onNextStep, validateStep } = useUploadExamsPageContext();
  const { uploadManager } = useUploadManager();

  const [stepError, setStepError] = useState<string | null>(null);

  const handleAddDicomFolder = useEvent(async (files: File[]) => {
    if (allServices == null) throw new Error('Cannot proceed because allServices is null.');

    if (files.length === 0) {
      return;
    }

    uploadManager.scanDicomFiles(files);

    setStepError(null);
  });

  const handleAddNonDicomFiles = useEvent((files: File[]) => {
    if (allServices == null) throw new Error('Cannot proceed because allServices is null.');

    if (files.length === 0) {
      return;
    }

    uploadManager.scanNonDicomFiles(files);

    setStepError(null);
  });

  const handleNextClick = useEvent(() => {
    if (validateStep(uploadGroups, WizardStepKey.SelectFiles)) {
      setStepError(null);
      onNextStep();
    } else {
      setStepError('Please resolve issues before proceeding.');
    }
  });

  const handleUploadGroupCheckedChange = useEvent((uploadGroupId: string, checked: boolean) => {
    setUploadGroups((prev) => {
      const newUploadGroups = [...prev];
      const uploadGroupIndex = newUploadGroups.findIndex((item) => item.uploadGroupId === uploadGroupId);

      if (uploadGroupIndex === -1) {
        return prev;
      }

      newUploadGroups[uploadGroupIndex] = {
        ...newUploadGroups[uploadGroupIndex],
        checked,
      };

      return newUploadGroups;
    });
  });

  return (
    <StyledComponentDiv className={cn({ initial: uploadGroups.length === 0 })}>
      <StyledLeftPanel>
        <DicomExamUploadCard onAdd={handleAddDicomFolder} />
        <NonDicomExamUploadCard onAdd={handleAddNonDicomFiles} />
      </StyledLeftPanel>

      {uploadGroups.length > 0 && (
        <StyledRightPanel>
          {uploadGroups.map((item) => (
            <SelectFilesExamCard key={item.uploadGroupId} uploadGroup={item} onUploadGroupCheckedChange={handleUploadGroupCheckedChange} />
          ))}
        </StyledRightPanel>
      )}

      <StyledFooter>
        <StyledNextButtonContainer>
          {stepError != null && <ErrorMessage>{stepError}</ErrorMessage>}
          <StyledBigButton size={ComponentSizes.LARGE} onClick={handleNextClick}>
            Next
          </StyledBigButton>
        </StyledNextButtonContainer>
      </StyledFooter>
    </StyledComponentDiv>
  );
});

SelectFilesStep.displayName = 'SelectFilesStep';

const StyledComponentDiv = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr min-content;
  padding-right: ${({ theme }) => theme.space.spacing40};

  &:not(.initial) {
    column-gap: ${({ theme }) => theme.space.spacing20};
  }

  &.initial {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  ${BreakpointSelectors.Desktop}:not(${BreakpointSelectors.DesktopShort}) &.initial {
    padding-top: ${({ theme }) => theme.space.spacing80};
  }
`;

const StyledLeftPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  row-gap: ${({ theme }) => theme.space.spacing40};
`;

const StyledRightPanel = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  overflow-x: hidden;
  overflow-y: auto;
  row-gap: ${({ theme }) => theme.space.spacing20};
`;

const StyledBigButton = styled(Button)`
  && {
    display: flex;
    width: initial;
    height: initial;
    padding: 5px 16px;
    line-height: 22px;
    margin-left: ${({ theme }) => theme.space.spacing40};
  }

  .k-button-text {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 22px;
  }
`;

const StyledFooter = styled.div`
  align-self: end;
  justify-self: stretch;
  grid-column: 1 / span 2;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.space.spacing40} 0;
`;

const StyledNextButtonContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;
