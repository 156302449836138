import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageViewer = ({ className, url }) => {
  return <StyledImg className={className} alt="file" src={url} />;
};

const StyledImg = styled.img`
  && {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
`;

ImageViewer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
};

ImageViewer.defaultProps = {
  className: undefined,
};

export { ImageViewer };
