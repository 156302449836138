import { ComponentType, memo, useEffect, useMemo, useState } from 'react';

import { State } from '@progress/kendo-data-query';
import { NumericFilter } from '@progress/kendo-react-data-tools';
import { GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import styled from 'styled-components';

import { PatientModel } from 'models';

import { DataResult } from 'core/api';
import { useAsyncCallback, useBoolean, useDebounceEmitter, useEvent } from 'core/hooks';
import {
  Action,
  ActionButtonListCell,
  ActionListCell,
  Button,
  DEFAULT_PAGE_SIZES,
  DataTable,
  DataTableVariants,
  DateCell,
  HeaderCell,
  HeaderCellSecondary,
  Modal,
  TextCell,
} from 'core/ui';

import { apiClient } from 'features/api';

import { PatientSelectModalProps } from '../types';

const PAGEABLE_SETTINGS = { pageSizes: DEFAULT_PAGE_SIZES };

const DEFAULT_DATA_STATE = {
  skip: 0,
  take: 10,
} satisfies State;

export const PatientSelectModal = memo<PatientSelectModalProps>(({ show, selectedPatientId, locationId, onClose, onPatientFormSelected }) => {
  const [patients, setPatients] = useState<DataResult<PatientModel> | null>(null);
  const [dataState, setDataState] = useState<State>(DEFAULT_DATA_STATE);
  const [isContentVisibleOverride, { setTrue: setIsContentVisibleOverrideTrue, setFalse: setIsContentVisibleOverrideFalse }] = useBoolean(false);

  const isContentVisible = show || isContentVisibleOverride;

  const [fetchPatients, isLoading] = useAsyncCallback(async (signal, dataStateOverride?: State) => {
    const queryDataState = {
      ...(dataStateOverride ?? dataState),
    } satisfies State;
    const originalFilter = queryDataState.filter;

    queryDataState.filter = {
      logic: 'and',
      filters: [
        {
          field: 'location_Id',
          operator: 'eq',
          value: locationId,
        },
      ],
    };

    if (originalFilter != null) {
      queryDataState.filter.filters.push(originalFilter);
    }

    const newPatients = await apiClient.patientClient.getAllForKendoGrid(queryDataState, null, signal);
    setPatients(newPatients);
  });

  const { emitDebounce, clearDebounce } = useDebounceEmitter(() => {
    fetchPatients();
  }, 500);

  const initialize = useEvent(() => {
    const newDataState: State = DEFAULT_DATA_STATE;

    setPatients(null);
    setDataState(newDataState);
    clearDebounce();
    fetchPatients(newDataState);
  });

  const reset = useEvent(() => {
    setPatients(null);
    setDataState(DEFAULT_DATA_STATE);
    clearDebounce();
  });

  const handleDataStateChange = useEvent((event: GridDataStateChangeEvent) => {
    let isEventFromPager = false;
    let isEventFromSortClick = false;

    // Determine if the event originates within the pager.
    if (event.syntheticEvent.target instanceof Element) {
      isEventFromPager = event.syntheticEvent.target.closest('.k-pager') != null;
    }

    // Determine if the event is the result of the user clicking a sortable header cell.
    if (event.syntheticEvent.type === 'click' && event.syntheticEvent.target instanceof Element) {
      // We have to make sure we are within the separate <table> element for the headers, but we also have to make sure we aren't in the filter row.
      isEventFromSortClick =
        event.syntheticEvent.target.closest('.k-grid-header-table') != null && event.syntheticEvent.target.closest('.k-filter-row') == null;
    }

    setDataState(event.dataState);

    if (isEventFromPager || isEventFromSortClick) {
      clearDebounce();
      fetchPatients(event.dataState);
    } else {
      emitDebounce();
    }
  });

  const [handlePatientSelect, isPatientFetching] = useAsyncCallback(async (signal, _event: unknown, patient: PatientModel) => {
    // Need to fetch the exam from the server because the grid only returns a subset of the data.
    const newPatient = await apiClient.patientClient.getPatient(patient.id);

    signal.throwIfAborted();

    onClose();
    onPatientFormSelected(newPatient);
  });

  const selectedState = useMemo(
    () =>
      selectedPatientId != null
        ? {
            [selectedPatientId]: true,
          }
        : {},
    [selectedPatientId],
  );

  const gridActions: Action[] = useMemo(
    () => [
      {
        key: 'select',
        text: 'Select',
        onClick: handlePatientSelect,
      },
    ],
    [handlePatientSelect],
  );

  // Initialize/reset when the modal switches between visible and hidden.
  useEffect(() => {
    if (isContentVisible) {
      initialize();
    } else {
      reset();
    }
  }, [initialize, reset, isContentVisible]);

  return (
    <StyledModal
      show={show}
      onHide={onClose}
      title="Select Existing Patient"
      onEnter={setIsContentVisibleOverrideTrue}
      onExited={setIsContentVisibleOverrideFalse}
    >
      <StyledDialogBodyDiv>
        <StyledDataTable
          isLoading={isLoading}
          data={patients}
          actions={gridActions}
          onDataStateChange={handleDataStateChange}
          filterable
          sortable
          pageable={PAGEABLE_SETTINGS}
          selectedState={selectedState}
          {...dataState}
        >
          <GridColumn
            filterable={false}
            reorderable={false}
            sortable={false}
            headerCell={HeaderCell}
            cell={ActionButtonListCell as ComponentType<GridCellProps>}
            width="65px"
          />
          <GridColumn field="patientNumber" title="Patient ID" headerCell={HeaderCell} cell={TextCell} width="120px" />
          <GridColumn field="firstName" title="First Name" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn field="lastName" title="Last Name" headerCell={HeaderCell} cell={TextCell} />
          <GridColumn field="dob" title="DOB" headerCell={HeaderCell} cell={DateCell} width="110px" format="MM/DD/YYYY" />
          <GridColumn field="age" title="Age" headerCell={HeaderCell} cell={TextCell} width="50px" />
        </StyledDataTable>
      </StyledDialogBodyDiv>
      <StyledActionButtonsDiv>
        <Button onClick={onClose}>Close</Button>
      </StyledActionButtonsDiv>
    </StyledModal>
  );
});

PatientSelectModal.displayName = 'PatientSelectModal';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 800px;
  }
`;

const StyledDialogBodyDiv = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;

const StyledDataTable = styled(DataTable)`
  height: 430px;
`;

const StyledActionButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px 24px;
`;
