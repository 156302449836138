import { FunctionComponent } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

export const AlertProvider: FunctionComponent = () => {
  return <StyledContainer hideProgressBar />;
};

const StyledContainer = styled(ToastContainer)`
  .Toastify {
    &__toast {
      box-shadow: none;
      color: ${({ theme }) => theme.colors.textPrimary};
      font-family: ${({ theme }) => theme.fontFamilies.body};

      &--success {
        background: ${({ theme }) => theme.colors.palette.greens[0]};
        border: ${({ theme }) => theme.borderWidths.base} solid
          ${({ theme }) => theme.colors.palette.greens[2]};
      }

      &--info {
        background: ${({ theme }) => theme.colors.palette.aquas[0]};
        border: ${({ theme }) => theme.borderWidths.base} solid
          ${({ theme }) => theme.colors.palette.aquas[2]};
      }

      &--warning {
        background: ${({ theme }) => theme.colors.palette.yellows[0]};
        border: ${({ theme }) => theme.borderWidths.base} solid
          ${({ theme }) => theme.colors.palette.yellows[2]};
      }

      &--error {
        background: ${({ theme }) => theme.colors.palette.reds[0]};
        border: ${({ theme }) => theme.borderWidths.base} solid
          ${({ theme }) => theme.colors.palette.reds[1]};
      }
    }

    &__toast-body {
      white-space: pre-line;
    }

    &__toast-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
