import { ColumnState } from 'features/exam/types/ColumnState';

export const DefaultColumnsState: Record<string, ColumnState> = {
  id: { show: true, orderIndex: 1 },
  templatePath: { show: true, orderIndex: 2 },
  locationName: { show: true, orderIndex: 3 },
  version: { show: true, orderIndex: 4 },
  organ: { show: true, orderIndex: 5 },
  service: { show: true, orderIndex: 6 },
  active: { show: true, orderIndex: 7 },
};
