import { createEntityAdapter } from '@reduxjs/toolkit';

const rootSelector = (state) => state.formFields;

const { selectById, selectAll: getAll } =
  createEntityAdapter().getSelectors(rootSelector);

const getStatus = (state) => rootSelector(state).status;

const getById = (id) => (state) => selectById(state, id);

const getAllByFieldCategoryId = (fieldCategoryId) => (state) =>
  getAll(state).filter((ff) => ff.categoryId === fieldCategoryId);

const getTotal = (state) => rootSelector(state).total;

export const FormFieldSelectors = {
  getAll,
  getById,
  getTotal,
  getStatus,
  getAllByFieldCategoryId,
};
