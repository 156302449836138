import {
  BrowserUtils,
  Configuration,
  EventMessage,
  EventType,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  LogLevel,
  ProtocolMode,
  PublicClientApplication,
} from '@azure/msal-browser';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { AnonymousUserError, ApiRouteService, setAccessTokenReduxShim, setShareTokenReduxShim } from 'core/api';

import { ACCESS_TOKEN_SCOPES, AuthenticationScheme } from '../constants';
import { AuthenticationManagerHandle } from '../types/AuthenticationManagerHandle';
import { IAuthenticationScheme } from '../types/IAuthenticationScheme';

export class OidcAuthenticationScheme implements IAuthenticationScheme {
  public readonly pca: IPublicClientApplication;

  private reactAppUrl: URL;

  private redirectState: string | undefined;

  private readonly isConsoleLoggingEnabled = false;

  constructor(private manager: AuthenticationManagerHandle) {
    this.initialize = this.initialize.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setActiveScheme = this.setActiveScheme.bind(this);
    this.accessTokenGetter = this.accessTokenGetter.bind(this);
    this.handleMsalEvent = this.handleMsalEvent.bind(this);
    this.frontChannelLogout = this.frontChannelLogout.bind(this);
    this.getRedirectState = this.getRedirectState.bind(this);
    this.msalLogMarshaller = this.msalLogMarshaller.bind(this);

    // Note that OIDC is extremely picky when it comes to matching URLs to expected values.  Some places expect a trailing slash, others don't.  That's why this configuration constructs each URL in a very particular format.
    let reactAppUrl = new URL(window.location.href);
    reactAppUrl = new URL(`${reactAppUrl.protocol}//${reactAppUrl.host}`);
    this.reactAppUrl = reactAppUrl;
    const ssoUrl = new URL(ApiRouteService.getCompumedSsoBaseRoute());

    const pcaConfig: Configuration = {
      auth: {
        clientId: 'CompumedReact',
        authority: `${ssoUrl.protocol}//${ssoUrl.host}`,
        knownAuthorities: [ssoUrl.host],
        redirectUri: `${reactAppUrl.protocol}//${reactAppUrl.host}/auth/oidc-signin`,
        navigateToLoginRequestUrl: true,
        protocolMode: ProtocolMode.OIDC,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
      system: {
        loggerOptions: {
          loggerCallback: this.msalLogMarshaller,
        },
        allowRedirectInIframe: true,
      },
    };

    this.pca = new PublicClientApplication(pcaConfig);
  }

  public async initialize() {
    this.pca.addEventCallback(this.handleMsalEvent);

    await this.pca.initialize();

    // Stop immediately if we are on the front channel logout page.  That page has different initialization requirements that can interfere with the handleRedirectPromise() call below.
    if (window.location.pathname.toLocaleLowerCase('en-US').startsWith('/auth/oidc-signout')) {
      return false;
    }

    const redirectResult = await this.pca.handleRedirectPromise();

    if (redirectResult != null) {
      this.pca.setActiveAccount(redirectResult.account);
      this.redirectState = redirectResult.state; // The state is the root relative URL that the user was trying to access before being redirected to the login page.
      return true;
    } else {
      const activeAccount = this.pca.getActiveAccount();
      return activeAccount != null;
    }
  }

  public async frontChannelLogout() {
    datadogRum.clearUser();
    datadogLogs.clearUser();
    datadogRum.clearGlobalContext();
    datadogLogs.clearGlobalContext();
    datadogRum.stopSession();

    const activeAccount = this.pca.getActiveAccount();

    if (activeAccount != null) {
      this.pca.logoutRedirect({
        account: activeAccount,
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });
    }

    return true;
  }

  public setActiveScheme() {
    const newAccessTokenGetter = this.accessTokenGetter;

    this.manager.apiClient.setAccessTokenFunction(newAccessTokenGetter);
    setAccessTokenReduxShim(newAccessTokenGetter);
    this.manager.apiClient.setShareTokenFunction(null);
    setShareTokenReduxShim(async () => null);

    const oidcUser = this.pca.getActiveAccount();
    const datadogUser = {
      id: oidcUser?.homeAccountId,
      email: (oidcUser?.idTokenClaims?.email as string | null | undefined) ?? undefined,
      scheme: 'oidc',
    };
    datadogRum.setUser(datadogUser);
    datadogLogs.setUser(datadogUser);

    return true;
  }

  public login() {
    const activeAccount = this.pca.getActiveAccount();

    if (activeAccount == null) {
      const rootRelativeUrl = window.location.pathname + window.location.search + window.location.hash;

      this.pca.loginRedirect({
        scopes: ACCESS_TOKEN_SCOPES,
        redirectUri: `${this.reactAppUrl.protocol}//${this.reactAppUrl.host}/auth/oidc-signin`,
        state: rootRelativeUrl, // Save the root relative URL that the user was trying to access before being redirected to the login page.
      });
    } else {
      // User is already logged in.  Switch to OIDC scheme if we are using something else (most likely the user is switching from a share auth page to OIDC).
      this.manager.setActiveScheme(AuthenticationScheme.OIDC);
    }
  }

  public logout() {
    datadogRum.clearUser();
    datadogLogs.clearUser();
    datadogRum.clearGlobalContext();
    datadogLogs.clearGlobalContext();
    datadogRum.stopSession();

    this.pca.logoutRedirect();

    // We don't need to worry about notifying the rest of the application because the redirect will cause an immediate reset for the entire application state.
  }

  public getRedirectState() {
    return this.redirectState;
  }

  private handleMsalEvent(event: EventMessage) {
    if (this.isConsoleLoggingEnabled) {
      // eslint-disable-next-line no-console
      console.log('handleMsalEvent', event);
    }

    if (
      event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
      event.error instanceof InteractionRequiredAuthError &&
      event.error.errorCode === 'login_required'
    ) {
      const activeAccount = this.pca.getActiveAccount();
      if (activeAccount != null) {
        this.pca.logoutRedirect({
          account: activeAccount,
          onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        });
      }
      this.login();
    }
  }

  private async accessTokenGetter() {
    const account = this.pca.getActiveAccount();
    if (account == null) {
      throw new AnonymousUserError('Cannot acquire JWT access token because the user is not logged in.');
    } else {
      return (
        await this.pca.acquireTokenSilent({
          scopes: ACCESS_TOKEN_SCOPES,
          account,
        })
      ).accessToken;
    }
  }

  private msalLogMarshaller(level: LogLevel, message: string, containsPii: boolean) {
    if (containsPii) {
      return;
    }

    // eslint-disable-next-line no-console
    if (level === LogLevel.Trace) console.trace(message);
    else if (level === LogLevel.Error) datadogLogs.logger.error(message);
    else if (level === LogLevel.Info) datadogLogs.logger.info(message);
    else if (level === LogLevel.Verbose) datadogLogs.logger.debug(message);
    else if (level === LogLevel.Warning) datadogLogs.logger.warn(message);

    if (this.isConsoleLoggingEnabled) {
      // eslint-disable-next-line no-console
      if (level === LogLevel.Trace) console.trace(message);
      // eslint-disable-next-line no-console
      else if (level === LogLevel.Error) console.error(message);
      // eslint-disable-next-line no-console
      else if (level === LogLevel.Info) console.info(message);
      // eslint-disable-next-line no-console
      else if (level === LogLevel.Verbose) console.debug(message);
      // eslint-disable-next-line no-console
      else if (level === LogLevel.Warning) console.warn(message);
    }
  }
}
