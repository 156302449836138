import { forwardRef, memo, useCallback } from 'react';

import cn from 'classnames';
import { areEqual } from 'react-window';
import styled from 'styled-components';

import { ItemProps } from './ItemProps';

export const Item = memo(
  forwardRef<HTMLButtonElement, ItemProps>(
    ({ item, highlightItemId, style, onClick }, ref) => {
      const { id } = item;

      const handleItemClick = useCallback(() => {
        onClick(id);
      }, [id, onClick]);

      return (
        <StyledItemButton
          ref={ref}
          type="button"
          style={style}
          className={cn({ highlight: id === highlightItemId })}
          data-item-id={id}
          onClick={handleItemClick}
          tabIndex={-1}
        >
          <StyledItemDescription>{item.description}</StyledItemDescription>
        </StyledItemButton>
      );
    },
  ),
  areEqual,
);

Item.displayName = 'Item';

const StyledItemButton = styled.button`
  appearance: none;
  border: none;
  background-color: inherit;
  flex: 0 0 24px;
  color: #212529;
  display: block;
  padding: 3px 12px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;

  &:focus,
  &:focus-visible,
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
    border: none;
  }

  &:hover {
    background-color: #e9ecef;
  }

  &.highlight,
  &.highlight:focus,
  &.highlight:focus-visible,
  &.highlight:focus:not(:focus-visible) {
    box-shadow: inset 0 0 0 3px rgba(33, 37, 41, 0.15);
  }
`;

const StyledItemDescription = styled.span`
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`;
