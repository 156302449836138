import { ForwardedRef, forwardRef, useMemo } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled, { DefaultTheme } from 'styled-components';

import { hasText } from 'core/utils';

import { SmallTextBoxProps } from './SmallTextBoxProps';

export const SmallTextBox = forwardRef<
  HTMLDivElement | HTMLSpanElement,
  SmallTextBoxProps
>(
  (
    { variant, className, children, isRendered = true, block = false, title },
    ref,
  ) => {
    const tooltip = useMemo(
      () => (hasText(title) ? <Tooltip>{title}</Tooltip> : undefined),
      [title],
    );

    if (!isRendered) return null;

    const renderContent = block ? (
      <StyledDiv
        ref={ref as ForwardedRef<HTMLDivElement>}
        $variant={variant}
        className={className}
      >
        {children}
      </StyledDiv>
    ) : (
      <StyledSpan
        ref={ref as ForwardedRef<HTMLSpanElement>}
        $variant={variant}
        className={className}
      >
        {children}
      </StyledSpan>
    );

    if (tooltip) {
      return (
        <OverlayTrigger placement="bottom-start" overlay={tooltip}>
          {renderContent}
        </OverlayTrigger>
      );
    } else {
      return renderContent;
    }
  },
);

SmallTextBox.displayName = 'SmallTextBox';

function resolveColor({
  $variant,
  theme,
}: {
  $variant: SmallTextBoxProps['variant'];
  theme: DefaultTheme;
}) {
  if ($variant === 'success') return theme.colors.success;
  else if ($variant === 'warning') return theme.colors.warning;
  else if ($variant === 'error') return theme.colors.error;
  else if ($variant === 'disabled') return theme.colors.textDisabled;
  else return theme.colors.textPrimary;
}

function resolveBorderRadius({ theme }: { theme: DefaultTheme }) {
  return theme.radii.base;
}

function resolveBorderWidth({ theme }: { theme: DefaultTheme }) {
  return theme.borderWidths.base;
}

function resolveBorderColor({
  theme,
  $variant,
}: {
  $variant: SmallTextBoxProps['variant'];
  theme: DefaultTheme;
}) {
  if ($variant === 'success') return theme.colors.success;
  else if ($variant === 'warning') return theme.colors.warning;
  else if ($variant === 'error') return theme.colors.error;
  else if ($variant === 'disabled') return theme.colors.borderDisabled;
  else return theme.colors.textPrimary;
}

function resolveLineHeight({ theme }: { theme: DefaultTheme }) {
  return theme.lineHeights.body;
}

function resolvePadding({ theme }: { theme: DefaultTheme }) {
  return `${theme.space.spacing10} ${theme.space.spacing20}`;
}

const StyledSpan = styled.span<{
  $variant: SmallTextBoxProps['variant'];
}>`
  color: ${resolveColor};
  border-radius: ${resolveBorderRadius};
  border: ${resolveBorderWidth} solid ${resolveBorderColor};
  line-height: ${resolveLineHeight};
  padding: ${resolvePadding};
`;

const StyledDiv = styled.div<{
  $variant: SmallTextBoxProps['variant'];
}>`
  color: ${resolveColor};
  border-radius: ${resolveBorderRadius};
  border: ${resolveBorderWidth} solid ${resolveBorderColor};
  line-height: ${resolveLineHeight};
  padding: ${resolvePadding};
`;
