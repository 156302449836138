import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../main/hooks';
import { ServiceActions, ServiceSelectors } from '../redux';

export const useServices = () => {
  const dispatch = useAppDispatch();

  const services = useSelector(ServiceSelectors.getAll);

  useEffect(() => {
    dispatch(ServiceActions.getAll());
  }, [dispatch]);

  return services;
};
