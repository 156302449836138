import { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Field, FieldContainer, Form, FormElement, combineValidators, email, required } from 'core/forms';
import { useEvent } from 'core/hooks';
import { Button, DropdownWithValuesField, Input } from 'core/ui';

import { SHARE_EXPIRATION_OPTIONS } from '../constants';
import { ShareEmailFormProps } from '../types/ShareEmailFormProps';
import { ShareEmailFormValues } from '../types/ShareEmailFormValues';

const validators = {
  description: required,
  email: combineValidators([required, email]),
  expiration: required,
};

const ShareEmailFormInner: FunctionComponent<{
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueGetter: (name: keyof ShareEmailFormValues) => any;
  onChange: (values: ShareEmailFormValues) => void;
}> = ({ className, valueGetter, onChange }) => {
  const handleFormChange = useEvent(() => {
    const newValues: ShareEmailFormValues = {
      description: valueGetter('description'),
      email: valueGetter('email'),
      accessCode: valueGetter('accessCode'),
      expiration: valueGetter('expiration'),
    };

    onChange(newValues);
  });

  return (
    <StyledFormElement className={className} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck="false">
      <FieldContainer>
        <Field component={Input} label="Description" name="description" required validator={validators.description} onChange={handleFormChange} />
      </FieldContainer>
      <FieldContainer>
        <Field component={Input} label="Email" name="email" required validator={validators.email} onChange={handleFormChange} />
      </FieldContainer>
      <FieldContainer>
        <Field component={Input} label="Access Code" name="accessCode" onChange={handleFormChange} />
      </FieldContainer>
      <FieldContainer>
        <Field
          component={DropdownWithValuesField}
          label="Expires In"
          name="expiration"
          isForPrimitiveValues
          onChange={handleFormChange}
          data={SHARE_EXPIRATION_OPTIONS}
          textField="name"
          dataItemKey="value"
          valueField="value"
          required
          validator={validators.expiration}
        />
      </FieldContainer>
      <FieldContainer>
        <Field component={Input} label="Message" name="message" onChange={handleFormChange} />
      </FieldContainer>

      <StyledSubmitButton type="submit">Send Email</StyledSubmitButton>
    </StyledFormElement>
  );
};

ShareEmailFormInner.displayName = 'ShareEmailFormInner';

export const ShareEmailForm: FunctionComponent<ShareEmailFormProps> = ({ className, initialValues, onChange, onSubmit }) => {
  return (
    <Form
      initialValues={initialValues}
      ignoreModified
      onSubmit={onSubmit}
      render={({ valueGetter }) => <ShareEmailFormInner className={className} valueGetter={valueGetter} onChange={onChange} />}
    />
  );
};

ShareEmailForm.displayName = 'ShareEmailForm';

const StyledFormElement = styled(FormElement)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 23px; // This value was chosen because it makes the gap between the submit button and previous input field appear to have consistent spacing as the other input fields have with each other.
`;
